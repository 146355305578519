import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import Dropdown from "../../DropDowns/DropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatInputDate } from '../../../Components/dateFormate'

const AddNotice = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});




    const [Bill, setBill] = useState({
        Type: "",
        Title: "",
        URL: "",
        Attachment: "",
        Description: ""
    });



    // useEffect(() => {
    //     if (userData && userData.Org_Id) {
    //         fetchData();
    //     }
    // }, [userData]);


    // const fetchData = async () => {
    //     try {
    //         showLoader();

    //         if (userData && userData.Org_Id && userData?.Head_Id) {
    //             showLoader();
    //             const std = await axios.get(
    //                 `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
    //             );


    //             const getStd = std.data.map((category) => ({
    //                 label: category.Std_Name,
    //                 value: category.Std_Id,
    //             }));
    //             setStdOptions(getStd);
    //             //console.log(getStd);
    //         }

    //         if (userData && userData.Org_Id && Bill?.Std_Id) {

    //             const batch = await axios.get(
    //                 `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Std_Id}`
    //             );

    //             const getBatch = batch.data.map((category) => ({
    //                 label: category.Batch_Name,
    //                 value: category.Batch_Id,
    //             }));
    //             setBatchOptions(getBatch);
    //             // console.log(getBatch);


    //             const sub = await axios.get(
    //                 `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Std_Id}`
    //             );

    //             const getSub = sub.data.map((category) => ({
    //                 label: category.Sub_Name,
    //                 value: category.Sub_Id,
    //             }));
    //             setSubjectOption(getSub);
    //         }
    //         // console.log(res.data);
    //         hideLoader();
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         hideLoader();
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        const formdata = new FormData();
        formdata.append("User_Id", updateBill.User_Id);
        formdata.append("Org_Id", updateBill.Org_Id);
        formdata.append("Org_Name", updateBill.Org_Name);
        formdata.append("User_Role", updateBill.User_Role);
        formdata.append("LoginUsername", updateBill.LoginUsername);
        formdata.append("Display_Name", updateBill.Display_Name);
        formdata.append("Head_Id", updateBill.Head_Id);
        formdata.append("Head_Name", updateBill.Head_Name ? updateBill.Head_Name : "");
        formdata.append("Title", Bill.Title);
        formdata.append("Type", Bill.Type);
        formdata.append("Attachment", Bill.Attachment);
        formdata.append("Description", Bill.Description);

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addPublications`, formdata)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Publications Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewPublications");
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };



    const customStyles = {
        control: (base) => ({
            ...base,
            height: 33,
            minHeight: 33,
        }),
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">
                    App Publication
                    </div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div></div>
                </div>

            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-4">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Type">
                                                        Type<span style={{ color: "Red" }}>*</span>
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Type"
                                                        name="Type"
                                                        required
                                                        onChange={handleChange}
                                                    >
                                                        <option selected value="">
                                                            --Select--
                                                        </option>
                                                        <option value="Notes">
                                                        Notes
                                                        </option>
                                                        <option value="Events">
                                                        Events
                                                        </option>
                                                        <option value="Features">
                                                        Features
                                                        </option>
                                                        <option value="Results">
                                                        Results
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Title">
                                            Title<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Title"
                                                id="Title"
                                                required
                                                value={Bill.Title}
                                                placeholder="Enter Title"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                  
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Attachment">
                                                Attatchment<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="file"
                                                name="Attachment"
                                                id="Attachment"
                                                required
                                                accept=".bmp, .png, .jpg, .jpeg, .pdf"
                                                onChange={(e) =>
                                                    setBill({
                                                        ...Bill,
                                                        Attachment: e.target.files[0],
                                                    })
                                                }
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                       
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>


                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNotice;


