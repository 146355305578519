import React from 'react'
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { useUser } from "../../Components/UserContext";
import Calendar from './StaffFullCalendar';
const ViewLectureSchedule = () => {
    const { userData } = useUser();

  return (
    <div
    className="card shadow-none border border-300 my-4"
    data-component-card="data-component-card"
  >
    <div className="card-body p-0">
      <div className="p-4 code-to-copy">
        <div className="row">
          <div className="col-md-6">
            <h5>View Lecture Schedule</h5>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown
                name="Head"
                id="Head"
                value={userData?.Head_Id}
              />
            </div>
          </div>
       <>   <Calendar/></>
        </div></div></div></div>
  )
}

export default ViewLectureSchedule