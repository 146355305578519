import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../Components/dateFormate'
import { FaEye } from "react-icons/fa";


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Date: updateData.Date,
        Title: updateData.Title,
        Filepath: updateData.Filepath,
        Description: updateData.Description
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,

            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};
        const formdata = new FormData();
        formdata.append("User_Id", updateBill.User_Id);
        formdata.append("Org_Id", updateBill.Org_Id);
        formdata.append("Org_Name", updateBill.Org_Name);
        formdata.append("User_Role", updateBill.User_Role);
        formdata.append("LoginUsername", updateBill.LoginUsername);
        formdata.append("Display_Name", updateBill.Display_Name);
        formdata.append("Head_Id", updateBill.Head_Id);
        formdata.append("Head_Name", updateBill.Head_Name ? updateBill.Head_Name : "");
        formdata.append("Date", Bill.Date);
        formdata.append("Filepath", Bill.Filepath);
        formdata.append("Description", Bill.Description);
        formdata.append("Title", Bill.Title);


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateNotes/${updateData?.Sr_No}`, formdata)
                .then((res) => {
                    Swal.fire("Success!", "Notes Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Title">
                                            Title<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Title"
                                                id="Title"
                                                required
                                                value={Bill.Title}
                                                placeholder="Enter Title"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label" htmlFor="Date">
                                    Date<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="Date"
                                    id="Date"
                                    required
                                    value={formatInputDate(Bill.Date)}
                                    //placeholder="Enter Bill No"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className={Bill.Filepath ? 'mb-3 col-md-3' : 'mb-3 col-md-4'}>
                                <label className="form-label" htmlFor="Filepath">
                                    Attatchment<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="file"
                                    name="Filepath"
                                    id="Filepath"
                                    accept=".bmp, .png, .jpg, .jpeg, .pdf"
                                    onChange={(e) =>
                                        setBill({
                                            ...Bill,
                                            Filepath: e.target.files[0],
                                        })
                                    }
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            {Bill.Filepath ? (<div className="mb-3 col-md-1 mt-4">

                                <Link
                                    to={`${apiUrl}/viewNotes/${Bill.Filepath}`}
                                    target="_blank" rel="noopener noreferrer"
                                    className="cursor-pointer text-primary"
                                    style={{ textDecoration: "none", marginLeft: "7px" }}
                                >
                                    <FaEye />

                                </Link>
                            </div>
                            ) : (
                                <></>
                                )}


                            <div className="mb-3 col-md-12">
                                <label className="form-label" htmlFor="Description">
                                    Description<span style={{ color: "Red" }}>*</span>
                                </label>
                                <textarea
                                    className="form-control"
                                    id="Description"
                                    name="Description"
                                    required
                                    rows={2}
                                    value={Bill.Description}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
