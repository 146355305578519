import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";
import { formatInputDate } from "../../../../Components/dateFormate";
const columnWidthMap = {
    'All Rights': 2,
    'Select Features': 10,
    Admissions: 7,
    'Staff Management': 5,
    'Accountant Master': 12,
    Announcements: 12,
    'Study Materials': 8,
    'App Promo': 4,
    Students: 5,
    Lectures: 7,
    Tests: 4,
    'Mock Tests': 4,
    'Daily Quiz': 4,
    Inventory: 12,
    Whatsapp: 12,
    Reports: 12,
    'Organization Settings': 12,
    'Staff Payroll': 6,
    'User Settings': 6,
    'Manage App': 12,
};
const AddAdminStaff = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const [isValidMobile, setIsValidMobile] = useState({});
    const [isValidMobile1, setIsValidMobile1] = useState({});
    const [next, setIsNext] = useState(false);
    const [rightsData, setRightsData] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    console.log(selectedTasks)
    const [Bill, setBill] = useState({
        txtHead_Id: "All",
        txtHead_Name: "All Branches",
        Initial: "",
        First_Name: "",
        Middle_Name: "",
        Last_Name: "",
        txtDisplay_Name: "",
        Gender: "",
        DOB: "",
        Email: "",
        Telephone: "",
        Mobile: "",
        Address: "",
        Active_Status: "",
        Role: "",
        Username: "",
        Password: "",
        Photo: "",
        Is_Owner: 0,
        Task_Ids: ""
    });
    console.log(Bill);

    useEffect(() => {
        const taskIdsString = Object.keys(selectedTasks)
            .filter(taskNo => selectedTasks[taskNo] && taskNo !== "1")
            .join(',');
        setBill({
            ...Bill,
            Task_Ids: taskIdsString
        })
    }, [selectedTasks]);

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData]);

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(`${apiUrl}/getDropdownMenus?Org_Id=${userData.Org_Id}`);
            const groupedTasks = res.data?.reduce((acc, task) => {
                const taskObj = {
                    Task_No: task.Task_No,
                    Task_Name: task.Task_Name,
                };
                if (!acc[task.Group_Name]) {
                    acc[task.Group_Name] = [];
                }
                acc[task.Group_Name].push(taskObj);
                return acc;
            }, {});

            const initialCheckedStatus = Object.values(groupedTasks).flat().reduce((acc, task) => {
                // Initially check all tasks except those with Task_Name === 'All'
                if (task.Task_Name !== 'All') {
                    acc[task.Task_No] = true;
                }
                return acc;
            }, {});

            // Set group-level checkboxes based on individual task statuses
            Object.keys(groupedTasks).forEach((groupName) => {
                const allGroupTasksChecked = groupedTasks[groupName]
                    .filter((task) => task.Task_Name !== 'All')
                    .every((task) => initialCheckedStatus[task.Task_No]);

                if (allGroupTasksChecked) {
                    initialCheckedStatus[groupName] = true;
                }
            });

            setRightsData(groupedTasks);
            setSelectedTasks(initialCheckedStatus);
            setSelectAll(Object.keys(initialCheckedStatus).length > 0);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            hideLoader();
        }
    };

    const handleCheckAll = (e) => {
        const isChecked = e.target.checked;
        const newCheckedStatus = Object.keys(selectedTasks).reduce((acc, taskNo) => {
            acc[taskNo] = isChecked;
            return acc;
        }, {});

        setSelectedTasks(newCheckedStatus);
        setSelectAll(isChecked);
    };

    const handleGroupCheckboxChange = (e, groupName) => {
        const isChecked = e.target.checked;

        // Update the selectedTasks state to check/uncheck all tasks within the group
        setSelectedTasks((prevSelectedTasks) => {
            const updatedTasks = { ...prevSelectedTasks };

            rightsData[groupName].forEach((task) => {
                if (task.Task_Name !== 'All') {
                    updatedTasks[task.Task_No] = isChecked;
                }
            });

            // Update group-level checkbox
            updatedTasks[groupName] = isChecked;

            // Update selectAll based on whether all tasks are checked
            setSelectAll(Object.values(updatedTasks).every((value) => value));

            return updatedTasks;
        });
    };


    const handleCheckboxChange = (e) => {
        const taskNo = e.target.id;

        setSelectedTasks((prevSelectedTasks) => {
            const updatedTasks = {
                ...prevSelectedTasks,
                [taskNo]: e.target.checked,
            };

            // Check if all non-'All' tasks in the group are selected or not
            Object.entries(rightsData).forEach(([groupName, tasks]) => {
                const allGroupTasksChecked = tasks
                    .filter((task) => task.Task_Name !== 'All')
                    .every((task) => updatedTasks[task.Task_No]);

                // If all tasks except 'All' in the group are selected, set the group checkbox as true
                if (allGroupTasksChecked) {
                    updatedTasks[groupName] = true;
                } else {
                    updatedTasks[groupName] = false;
                }
            });

            const allChecked = Object.values(updatedTasks).every((value) => value);
            setSelectAll(allChecked);

            return updatedTasks;
        });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleNext = (e) => {
        const errors = {};

        if (!Bill.Initial) {
            errors.Initial = "Select Initial of Name";
        }
        if (!Bill.First_Name) {
            errors.First_Name = "Enter First Name";
        }
        if (!Bill.Mobile) {
            errors.Mobile = "Enter Mobile No";
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        } else {
            setErrors({});
            setIsNext(e);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        const formdata = new FormData();
        formdata.append("User_Id", updateBill.User_Id);
        formdata.append("Org_Id", updateBill.Org_Id);
        formdata.append("Org_Name", updateBill.Org_Name);
        formdata.append("User_Role", updateBill.User_Role);
        formdata.append("LoginUsername", updateBill.LoginUsername);
        formdata.append("Display_Name", updateBill.Display_Name);
        formdata.append("Head_Id", updateBill.Head_Id);
        formdata.append("Head_Name", updateBill.Head_Name);
        formdata.append("txtHead_Id", Bill.txtHead_Id);
        formdata.append("txtHead_Name", Bill.txtHead_Name);
        formdata.append("Initial", Bill.Initial);
        formdata.append("First_Name", Bill.First_Name);
        formdata.append("Middle_Name", Bill.Middle_Name);
        formdata.append("Last_Name", Bill.Last_Name);
        formdata.append("Gender", Bill.Gender);
        formdata.append("Mobile", Bill.Mobile);
        formdata.append("Email", Bill.Email);
        formdata.append("Telephone", Bill.Telephone);
        formdata.append("DOB", Bill.DPB ? Bill.DOB : "");
        formdata.append("Address", Bill.Address);
        formdata.append("Photo", Bill.Photo);
        formdata.append("Is_Owner", Bill.Is_Owner);
        formdata.append("Task_Ids", Bill.Task_Ids);

        if (Object.keys(errors).length === 0) {
            showLoader();
            await axios
                .post(`${apiUrl}/addAdminStaffData`, formdata)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "New Admin Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewAdminSatff");
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                        Swal.fire({
                            title: "Error",
                            text: err.response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else if (err.response && err.response.status === 409) {
                        Swal.fire({
                            title: "Error",
                            text: err.response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        console.error(err);
                        Swal.fire(
                            "Server Timeout",
                            "Server is Busy!!!, Please try again later.",
                            "error"
                        );
                    }

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">Admin Details</div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center d-none">
                        <div className="col-md-5">
                            <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
                        </div>
                    </div>
                </div>
            </h4>
            {next === false ? (
                <div className="row g-4">
                    <div className="col-12 col-xl-12 order-1 order-xl-0">
                        <div
                            className="card shadow-none border border-300 my-4"
                            data-component-card="data-component-card"
                        >
                            <div className="card-body p-0">
                                <div className="p-4 code-to-copy">
                                    <form onSubmit={""}>
                                        <div className="row">
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Branch">
                                                    Branch
                                                </label>

                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Branch"
                                                    id="Branch"
                                                    value={Bill?.txtHead_Id}
                                                    onChange={(e) => {
                                                        const selectedIndex = e.target.selectedIndex;
                                                        const selectedOption = e.target[selectedIndex];
                                                        setBill({
                                                            ...Bill,
                                                            txtHead_Id: selectedOption.value,
                                                            txtHead_Name: selectedOption.label,
                                                        });
                                                    }}
                                                >
                                                    <option selected value={"All"}>
                                                        All Branches
                                                    </option>
                                                    <option value={userData?.Head_Id}>
                                                        {userData?.Head_Name}
                                                    </option>
                                                </select>
                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Branch">
                                                    Initial<span style={{ color: "Red" }}>*</span>
                                                </label>

                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Branch"
                                                    id="Branch"
                                                    required
                                                    value={Bill?.Initial}
                                                    onChange={(e) => {
                                                        setBill({
                                                            ...Bill,
                                                            Initial: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option selected value={""}>
                                                        --Select--
                                                    </option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Miss.">Miss.</option>
                                                    <option value="Master.">Master.</option>
                                                </select>
                                                <span style={{ color: "Red" }}>{errors.Initial}</span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="First_Name">
                                                    First Name<span style={{ color: "Red" }}>*</span>
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name="First_Name"
                                                    id="First_Name"
                                                    required
                                                    value={Bill.First_Name}
                                                    placeholder="Enter First Name"
                                                    onChange={handleChange}
                                                />
                                                <span style={{ color: "Red" }}>
                                                    {errors.First_Name}
                                                </span>
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Middle_Name">
                                                    Middle Name
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name="Middle_Name"
                                                    id="Middle_Name"
                                                    value={Bill.Middle_Name}
                                                    placeholder="Enter Middle Name"
                                                    onChange={handleChange}
                                                />
                                                <span style={{ color: "Red" }}></span>
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Last_Name">
                                                    Last Name
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name="Last_Name"
                                                    id="Last_Name"
                                                    value={Bill.Last_Name}
                                                    placeholder="Enter Last Name"
                                                    onChange={handleChange}
                                                />
                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Gender">
                                                    Gender
                                                </label>

                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Gender"
                                                    id="Gender"
                                                    value={Bill?.Gender}
                                                    onChange={(e) => {
                                                        setBill({
                                                            ...Bill,
                                                            Gender: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option selected value={""}>
                                                        --Select--
                                                    </option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className={"mb-3 col-md-3"}>
                                                <label className="form-label" htmlFor="DOB">
                                                    DOB
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="date"
                                                    name="DOB"
                                                    id="DOB"
                                                    value={formatInputDate(Bill.DOB)}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Mobile">
                                                    Mobile<span style={{ color: "Red" }}>*</span>(For SMS)
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${isValidMobile ? "" : "is-invalid"
                                                        }`}
                                                    type="text"
                                                    name="Mobile"
                                                    id="Mobile"
                                                    value={Bill.Mobile}
                                                    required
                                                    maxLength={10}
                                                    placeholder="Enter Mobile"
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const numericInput = input.replace(/[^0-9]/g, "");
                                                        const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                            numericInput
                                                        );
                                                        setIsValidMobile(isValid);
                                                        if (isValid || numericInput === "") {
                                                            setBill({
                                                                ...Bill,
                                                                Mobile: numericInput,
                                                            });
                                                        }
                                                    }}
                                                />
                                                {!isValidMobile && (
                                                    <div className="invalid-feedback">
                                                        Invalid mobile number.
                                                    </div>
                                                )}
                                                <span style={{ color: "Red" }}>{errors.Mobile}</span>
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Telephone">
                                                    Alternate Mobile
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${isValidMobile1 ? "" : "is-invalid"
                                                        }`}
                                                    type="text"
                                                    name="Telephone"
                                                    id="Telephone"
                                                    value={Bill.Telephone}
                                                    maxLength={10}
                                                    placeholder="Enter Alternate Mobile"
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const numericInput = input.replace(/[^0-9]/g, "");
                                                        const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                            numericInput
                                                        );
                                                        setIsValidMobile1(isValid);
                                                        if (isValid || numericInput === "") {
                                                            setBill({
                                                                ...Bill,
                                                                Telephone: numericInput,
                                                            });
                                                        }
                                                    }}
                                                />
                                                {!isValidMobile1 && (
                                                    <div className="invalid-feedback">
                                                        Invalid mobile number.
                                                    </div>
                                                )}
                                            </div>
                                            <div className={"mb-3 col-md-3"}>
                                                <label className="form-label" htmlFor="Email">
                                                    Email
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="email"
                                                    name="Email"
                                                    id="Email"
                                                    value={Bill.Email}
                                                    placeholder="Enter Email"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Photo">
                                                    Photo
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="file"
                                                    name="Photo"
                                                    id="Photo"
                                                    accept=".bmp, .png, .jpg, .jpeg"
                                                    onChange={(e) =>
                                                        setBill({
                                                            ...Bill,
                                                            Photo: e.target.files[0],
                                                        })
                                                    }
                                                />
                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Is_Owner">
                                                    Owner Rights
                                                </label>

                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Is_Owner"
                                                    id="Is_Owner"
                                                    value={Bill?.Is_Owner}
                                                    onChange={(e) => {
                                                        setBill({
                                                            ...Bill,
                                                            Is_Owner: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value={0}>No</option>
                                                    <option value={1}>Yes</option>
                                                </select>
                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-12">
                                                <label className="form-label" htmlFor="Address">
                                                    Address
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    id="Address"
                                                    name="Address"
                                                    rows={2}
                                                    value={Bill.Address}
                                                    placeholder="Address..."
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row g-4">
                    <div className="col-12 col-xl-12 order-1 order-xl-0">
                        <div className="card shadow-none border border-300 my-4" data-component-card="data-component-card">
                            <div className="card-body p-0">
                                <div className="p-4 code-to-copy">

                                    <div className="row">
                                        {Object.entries(rightsData).map(([groupName, tasks], idx) => (
                                            <div key={idx} className={`col-md-${columnWidthMap[groupName] || 12}`}>
                                                <fieldset className="my-fieldset">

                                                    {tasks[0].Task_Name === 'All' && (
                                                        <>
                                                            <legend className="my-legend">{groupName}</legend>
                                                            <span className="my-span">
                                                                <input
                                                                    id={`${tasks[0].Task_No}`}
                                                                    type="checkbox"
                                                                    name={`${tasks[0].Task_Name}`}
                                                                    checked={selectAll}
                                                                    onChange={handleCheckAll}
                                                                />
                                                                <label htmlFor={`${tasks[0].Task_No}`} className="lbl ml-2">
                                                                    &nbsp;{tasks[0].Task_Name}
                                                                </label>
                                                            </span></>

                                                    )}
                                                    {tasks.map((task) => (
                                                        task.Task_Name !== 'All' && (
                                                            <>
                                                                <legend className="my-legend">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`group-${groupName}`}
                                                                        checked={selectedTasks[groupName] || false}
                                                                        onChange={(e) => handleGroupCheckboxChange(e, groupName)}
                                                                    />
                                                                    <label htmlFor={`group-${groupName}`} className="lbl ml-2">
                                                                        &nbsp;{groupName}
                                                                    </label>
                                                                </legend>
                                                                <span key={task.Task_No} className="my-span">
                                                                    <input
                                                                        id={`${task.Task_No}`}
                                                                        type="checkbox"
                                                                        name={`${task.Task_No}`}
                                                                        checked={selectedTasks[`${task.Task_No}`] || false}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                    <label htmlFor={`${task.Task_No}`} className="lbl ml-2">
                                                                        &nbsp;{task.Task_Name}
                                                                    </label>
                                                                </span></>

                                                        )
                                                    ))}
                                                </fieldset>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-12 text-center mt-3">
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                            handleNext(next === false ? true : false);
                        }}
                    >
                        {!next ? "Next" : "Previous"}
                    </button>{" "}
                    &nbsp; &nbsp;
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className={next === false ? "d-none" : "btn btn-sm btn-primary"}
                    // disabled={}
                    >
                        Submit
                    </button>
                </div>
            </div>
            {loader}
        </div>
    );
};
export default AddAdminStaff;
