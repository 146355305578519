import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BankDropdoun from "../DropDowns/BankDropdoun";
import BillCategory from "../DropDowns/ExCategory";
import YearDropDown from "../DropDowns/YearDropDown";
import { formatDate } from '../../Components/dateFormate'
const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [installmentLog, setInstallment] = useState([]);
    const [nextInstallment, setNextInstallment] = useState();
    const [paidFeesDetails, setPaidFeesDetails] = useState();
    const [feesDetails, setFeesDetails] = useState();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Cheque_Status: "Honoured"
    });

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();
                    const result = await axios.get(
                        `${apiUrl}/getNextInstallment?ID=${updateData?.ID}&Org_Id=${userData?.Org_Id}`
                    );
                    setNextInstallment(result.data);
                    const installment = await axios.get(
                        `${apiUrl}/BindPaymentDetails?Installment_Id=${updateData?.Installment_Id}&OrgId=${userData?.Org_Id}`
                    );
                    setInstallment(installment.data);
                    const Fees = await axios.get(
                        `${apiUrl}/getFeesDetails?Installment_Id=${updateData?.Installment_Id}&OrgId=${userData?.Org_Id}`
                    );
                    setFeesDetails(Fees.data);
                    const paidFees = await axios.get(
                        `${apiUrl}/getPaidFeesDetails?ID=${updateData?.ID}&OrgId=${userData?.Org_Id}`
                    );
                    setPaidFeesDetails(paidFees.data);
                    console.log(result.data);
                    console.log(paidFees.data);
                    console.log(Fees.data);

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, updateData]);

    console.log(Bill);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,
            Installment_Id: updateData.Installment_Id,
            Bank_Name: updateData.Bank_Name,
            Payment_Date: updateData.Payment_Date,
            Cheque_No: updateData.Cheque_No,
            Cheque_Date: updateData.Cheque_Date,
            Late_Fee_Amt: feesDetails.Late_Fee_Amt,
            T_Amt: feesDetails.T_Amt,
            BookFee: feesDetails.BookFee,
            Bal_SaleOfBooks: paidFeesDetails.Bal_SaleOfBooks,
            Paid_SaleOfBooks: paidFeesDetails.Paid_SaleOfBooks,
            Total_SaleOfBooks: paidFeesDetails.Total_SaleOfBooks,
            Total_Paid: paidFeesDetails.Total_Paid,
            Late_Fees_Paid:paidFeesDetails.Late_Fees_Paid,
            T_Paid: paidFeesDetails.T_Paid,
            D_Paid: paidFeesDetails.D_Paid,
            T_Bal: paidFeesDetails.T_Bal,
            D_Bal: paidFeesDetails.D_Bal,
            Final_T_Fees: paidFeesDetails.Final_T_Fees,
            Final_D_Fees: paidFeesDetails.Final_D_Fees,
            NextInstallmentDate: nextInstallment.NextInstallmentDate || "",
            NextInstallmentAmt: nextInstallment.NextInstallmentAmt || "",
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateChequesStatus/${updateData?.ID}`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Status Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View/Update Cheque Payments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div>
                            <table
                                align="Center"
                                rules="cols"
                                className="table table-bordered border-primary table-hover table-responsive"
                                style={{ border: "1px solid var(--phoenix-gray-200)" }}
                            >
                                <thead>

                                    <tr
                                        align="center"
                                    >
                                        <th scope="col">Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Late Fees</th>
                                        <th scope="col">Total Amount</th>
                                        <th scope="col">Payment Status</th>
                                        <th scope="col">Cheque Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {installmentLog?.map((installment, index) => (
                                        <tr key={index} align="center" style={{ backgroundColor: 'White' }}>
                                            <td>{formatDate(installment.Payment_Date)}</td>
                                            <td>{parseFloat(installment.T_Amt).toFixed(2)}</td>
                                            <td>{parseFloat(installment.Late_Fee_Amt).toFixed(2)}</td>
                                            <td>{parseFloat(installment.Amt_Recieved).toFixed(2)}</td>
                                            <td>{installment.Status}</td>
                                            <td>{installment.Cheque_Status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                    <h5>Cheque Details</h5>
                    <hr />

                    <div className="row">
                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Bill_No">
                                Bank Name :

                            </label>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                disabled
                                value={updateData.Bank_Name}

                            />
                            <span style={{ color: "Red" }}></span>
                        </div>
                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Bill_Ref_No">
                                Cheque No :
                            </label>
                            <input
                                className="form-control form-control-sm"
                                disabled
                                type="text"
                                value={updateData.Cheque_No}
                            />
                            <span style={{ color: "Red" }}></span>
                        </div>
                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Bill_Date">
                                Date :
                            </label>
                            <input
                                className="form-control form-control-sm"
                                disabled
                                type="date"
                                value={
                                    updateData.Cheque_Date
                                        ? dayjs(updateData.Cheque_Date).format("YYYY-MM-DD")
                                        : ""
                                }
                            />
                            <span style={{ color: "Red" }}></span>
                        </div>

                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Title">
                                Amount :
                            </label>
                            <input
                                className="form-control form-control-sm"
                                disabled
                                type="text"
                                value={updateData.Tuition_Amount}
                            />
                            <span style={{ color: "Red" }}></span>
                        </div>
                    </div>



                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="Cheque_Status">
                                Cheque Status<span style={{ color: "Red" }}>*</span>
                            </label>
                            <select
                                className="form-select form-select-sm"
                                name="Cheque_Status"
                                id="Cheque_Status"
                                onChange={handleChange}
                            >
                                <option selected value="Honoured">Honoured</option>
                                <option value="Dishonoured">Dishonoured</option>
                            </select>
                            <span style={{ color: "Red" }}></span>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
