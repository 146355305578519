import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { apiUrl } from "../../../Components/apiConfig";
import { useUser } from "../../../Components/UserContext";
import useFullPageLoader from "../../../Components/useFullPageLoader";

const SendMessage = ({ Sr_No , onFormSubmit}) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [studentData, setStudentData] = useState([]);

    const [messageData, setMessageData] = useState({
        Message: '',
        StudentMob: false,
        FatherMob: false,
        MotherMob: false
    });
    useEffect(() => {
        fetchData();
    }, [userData, Sr_No]);

    const fetchData = async () => {
        if (userData && userData.Org_Id && Sr_No) {
            try {
                showLoader();
                const result = await axios.get(
                    `${apiUrl}/getStudentData/${Sr_No}/${userData?.Org_Id}`
                );
                setStudentData(result.data);
                console.log(result.data);
                hideLoader();
            } catch (error) {
                console.error("Error fetching headmaster data:", error);
                hideLoader();
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Check if at least one checkbox is checked
        if (!messageData.StudentMob && !messageData.FatherMob && !messageData.MotherMob) {
            Swal.fire(
                "Error!",
                "Please select at least one mobile no.",
                "error"
            );
            return;
        }
    
        const updateStudent = {
            ...messageData,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            Username: userData.Username,
            Name: userData.Org_Name,
            UserName: userData.UserName
        };
    
        showLoader();
        axios
            .post(`${apiUrl}/addEnquiry`, updateStudent)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Message sent Successfully!!",
                    "success"
                );
                onFormSubmit();
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };
    

    return (
        <div className="mt-7">
            {studentData.Mobile_For_SMS && (
                <div className="form-check">
                    <input
                        className="form-check-input"
                        id="flexCheckDefault0"
                        type="checkbox"
                        checked={messageData.StudentMob}
                        onChange={(e) => {
                            setMessageData({
                                ...messageData,
                                StudentMob: e.target.checked,

                            });
                        }}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault0">
                        <h6>{`Student's Mobile ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${studentData.First_Name} ${studentData.Last_Name} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${studentData.Mobile_For_SMS}`}</h6>
                    </label>
                </div>)}
            {studentData.Father_Mobile && (
                <div className="form-check">
                    <input
                        className="form-check-input"
                        id="flexCheckDefault1"
                        type="checkbox"
                        checked={messageData.FatherMob}
                        onChange={(e) => {
                            setMessageData({
                                ...messageData,
                                FatherMob: e.target.checked,

                            });
                        }}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault1">
                        <h6>{`Father's Mobile ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${studentData.Father_Name} ${studentData.Last_Name} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${studentData.Father_Mobile}`}</h6>
                    </label>
                </div>)}
            {studentData.Mother_Mobile && (
                <div className="form-check">
                    <input
                        className="form-check-input"
                        id="flexCheckDefault2"
                        type="checkbox"
                        checked={messageData.MotherMob}
                        onChange={(e) => {
                            setMessageData({
                                ...messageData,
                                MotherMob: e.target.checked,

                            });
                        }}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                        <h6>{`Mother's Mobile ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${studentData.Mother_Name} ${studentData.Last_Name} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${studentData.Mother_Mobile}`}</h6>

                    </label>
                </div>)}
            <form onSubmit={handleSubmit}>
                <div className="col-md-12 mt-3">
                    <label className="form-label" htmlFor="Message">
                        Message<span style={{ color: "Red" }}>*</span>
                    </label>
                    <textarea
                        className="form-control form-control-sm"
                        type="text"
                        name="Message"
                        id="Message"
                        required
                        rows={4}
                        placeholder="Message..."
                        value={messageData.message}
                        onChange={(e) => {
                            setMessageData({
                                ...messageData,
                                Message: e.target.value,
                            });
                        }}
                    />
                    <span style={{ color: "Red" }}></span>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mt-3">
                        <button type="submit" className="btn btn-sm btn-primary">
                            Send Message
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SendMessage
