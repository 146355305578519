import React, { useState, useEffect } from "react";
import axios from "../../../Components/apiConfig";
import { apiUrl } from '../../../Components/apiConfig'
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useParams, useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDate } from "../../../Components/dateFormate";
import { useUser } from "../../../Components/UserContext";
import html2pdf from "html2pdf.js";

const hrStyle = {
  border: 'none',
  borderTop: '1px dashed #000',
  height: '1px',
  width: '100%',
};

const FeesReceipts = ({ID}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { Sr_No } = useParams();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [studentData, setStudentData] = useState({});
  const [feesData, setFeesData] = useState([]);
  const [InstallmentData, setInstallmentData] = useState([]);
  const [FeesSummary, setFeesSummary] = useState({});
  const [headData, setHeadData] = useState({});

  useEffect(() => {
    loadData();
  }, [userData, Sr_No]);

  const generateInvoiceAndSavePDF = async () => {
    const element = document.getElementById("printablediv");
    const DebitNoteElement = document.getElementById("printablediv");

    const opt = {
      margin: 10,
      filename: "TaxInvoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const opt1 = {
      margin: 10,
      filename: "DebitNote.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {

      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");
      const pdfBlob1 = await html2pdf().from(DebitNoteElement).set(opt1).outputPdf("blob");
      showLoader();
      const formData = new FormData();
      formData.append("TaxInvoice", pdfBlob, "TaxInvoice.pdf");
      formData.append("DebitNote", pdfBlob1, "DebitNote.pdf");


      const response = await axios.post(`/downLoadInvoice`, formData);

      if (response?.data?.taxInvoiceURL) {
        await axios.get(`/downloadInvoicePDF/${response.data.taxInvoiceURL}`, {
          responseType: 'blob' // Ensure response is treated as blob
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'TaxInvoice.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }

      if (response?.data?.debitNoteURL) {
        await axios.get(`/downloadInvoicePDF/${response.data.debitNoteURL}`, {
          responseType: 'blob' // Ensure response is treated as blob
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'DebitNote.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
      hideLoader();
      console.log("PDFs downloaded successfully.");
    } catch (error) {
      console.error("Error saving or downloading PDFs:", error);
      hideLoader();
    }
  };

  useEffect(() => {
    if(feesData){
      generateAndSavePDF();
    }
  
  }, [feesData, ID]);

  const generateAndSavePDF = async () => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: `MasterFeesReceipt_${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = `MasterFeesReceipt_${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`;
      link.click();

    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const loadData = async () => {
    showLoader();
    try {
      if (userData?.Org_Id && userData?.Head_Id) {
        const headadata = await axios.get(
          `/getHeadMasterByHeadId?OrgId=${userData?.Org_Id}&Head_Id=${userData?.Head_Id}`
        );
        console.log(headadata.data);
        setHeadData(headadata.data);
      }


      if (Sr_No && userData?.Org_Id) {
        const studentDetails = await axios.get(
          `/getStudentProfile?OrgId=${userData?.Org_Id}&ID=${Sr_No}`
        );
        console.log(studentDetails.data.studentData);
        setStudentData(studentDetails.data.studentData);
        const feesdata = await axios.get(
          `/getStudentFeesPaymentLog?OrgId=${userData?.Org_Id}&ID=${Sr_No}`
        );
        console.log(feesdata.data[0]);
        setFeesData([feesdata.data[0]]);
        const result = await axios.get(
          `/getFeesInstallment?ID=${Sr_No}&OrgId=${userData?.Org_Id}`
        );
        setInstallmentData(result.data);
        console.log(result.data);

        const FeesSummary = await axios.get(
          `/getStudentFeesSummaryForMasterFeesReceipt?ID=${Sr_No}&OrgId=${userData?.Org_Id}`);
          setFeesSummary(FeesSummary.data);
          console.log(FeesSummary.data);
        
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };


  // const handleSubmit = async () => {
  //   const postPaymentDetails = {
  //     Enq_Id: Enq_Id,
  //     logId: logId,
  //     Rpayment_Id: Payment_Id,
  //     Installment_Id: Installment_Id,
  //     Org_Id: "",
  //     User_Role: "Customer",
  //     UserName: userData?.Username,
  //     Added_By_Id: userData?.UserId,
  //     Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
  //   };


  //   const validationErrors = {};

  //   if (Object.keys(validationErrors).length === 0) {
  //     showLoader();
  //     await axios
  //       .put(`/onlinePayment`, postPaymentDetails)
  //       .then((res) => {
  //         navigate(`/invoice/${Enq_Id}`);
  //         window.location.reload();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         Swal.fire({
  //           title: "Error",
  //           text: "Failed to payment. Please try again later.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //         hideLoader();
  //       });
  //   }
  // };


  function number2text(value) {
    var fraction = Math.round(frac(value) * 100);
    var f_text = "";

    if (fraction > 0) {
      f_text = "AND " + convert_number(fraction) + " PAISE";
    }

    return convert_number(value) + " RUPEES " + f_text + " ONLY";
  }

  function frac(f) {
    return f % 1;
  }

  function convert_number(number) {
    if (number < 0 || number > 999999999) {
      return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    var kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    var Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    var Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    var tn = Math.floor(number / 10);
    var one = Math.floor(number % 10);
    var res = "";

    if (Gn > 0) {
      res += convert_number(Gn) + " CRORE";
    }
    if (kn > 0) {
      res += (res === "" ? "" : " ") + convert_number(kn) + " LAKH";
    }
    if (Hn > 0) {
      res += (res === "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
    }

    if (Dn) {
      res += (res === "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
    }

    var ones = [
      "",
      "ONE",
      "TWO",
      "THREE",
      "FOUR",
      "FIVE",
      "SIX",
      "SEVEN",
      "EIGHT",
      "NINE",
      "TEN",
      "ELEVEN",
      "TWELVE",
      "THIRTEEN",
      "FOURTEEN",
      "FIFTEEN",
      "SIXTEEN",
      "SEVENTEEN",
      "EIGHTEEN",
      "NINETEEN",
    ];
    var tens = [
      "",
      "",
      "TWENTY",
      "THIRTY",
      "FORTY",
      "FIFTY",
      "SIXTY",
      "SEVENTY",
      "EIGHTY",
      "NINETY",
    ];

    if (tn > 0 || one > 0) {
      if (!(res === "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += "-" + ones[one];
        }
      }
    }

    if (res === "") {
      res = "ZERO";
    }
    return res;
  }

  const createCustomInvoicePDF = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    // Print the document
    window.print();

    // Restore the original contents
    document.body.innerHTML = originalContents;
  };


  return (
    <div className="content">

      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">

                <div className="row mb-4">
                  <button className="btn btn-sm btn-primary" onClick={() => {
                    createCustomInvoicePDF();
                    window.location.reload();
                  }}>Print</button>
                </div>

              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="container">

                  <div className="div" style={{ fontSize: "12px" }} id="printablediv">
                    <div className="row mb-1">
                      <div className="col-md-4 col-sm-4">
                        <img
                          src={`/CustomerLogo/${userData?.Web_Logo}`}
                          alt="logo"
                          style={{
                            maxWidth: "330px",
                            height: "auto",
                            width: "100%",
                            paddingLeft: 0,
                          }}
                        />
                      </div>
                      <div className="col-md-8 col-sm-8 text-center">
                        <h4 className="mb-1">{userData?.Org_Name}</h4>
                        <p style={{ marginBottom: 0, color: "#333" }}>
                          {headData?.Head_Address}
                        </p>
                      </div>
                    </div>
                    <hr
                      className="mb-2"
                      style={{ borderWidth: "1px", margin: 0 }}
                    />
                    <div
                      className="row justify-content-between m-3 p-1 span8 well invoice-body"
                      style={{ border: '1px solid #e3e6ed', color: "#333", fontSize: "12px" }}
                    >
                      <div className="col-md-2 col-sm-2">
                        <div className="avatar-group avatar-group-dense">
                          <div className="avatar avatar-m  rounded-circle" style={{ height: "110px", width: "89px", marginTop: "5px" }}>
                            <img className="square-avatar" src={`https://masyseducare.com/Documents/Student/Photos/${studentData?.Photo}`}
                              onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop
                                e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                              }} alt="student" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-sm-5">
                        <table className="table-responsive">
                          <tbody>
                            <tr>
                              <td
                                className="pull-left"
                                style={{
                                  paddingRight: "8px",
                                  textAlign: "left",
                                }}
                              >
                                <strong>NAME</strong>
                              </td>
                              <td>:&nbsp;{feesData[0]?.Name}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-left"
                                style={{
                                  paddingRight: "8px",
                                  textAlign: "left",
                                }}
                              >
                                <strong>STUDENT ID</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Student_Id}</td>

                            </tr>

                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>MOBILE</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Mobile_For_SMS}</td>
                            </tr>

                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>STD</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Std_Name}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>ADMISSION DATE</strong>
                              </td>
                              <td>:&nbsp;{formatDate(studentData?.Date_Added)}</td>
                            </tr>

                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>COURSE</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Course_Name}</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-5 col-sm-5">
                        <table className="table-responsive">
                          <tbody>
                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>FATHER</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Father_Name}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>MOBILE</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Father_Mobile}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>MOTHER</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Mother_Name}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>MOBILE</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Mother_Mobile}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-left"
                                style={{ paddingRight: "8px", textAlign: "left" }}
                              >
                                <strong>BATCH</strong>
                              </td>
                              <td>:&nbsp;{studentData?.Batch_Name}</td>
                            </tr>


                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-12 col-sm-12 table-responsive ">
                        <table className="table  table-bordered table-bordered-dark mb-1">
                          <thead>
                            <tr>
                              <th
                                className="text-left"
                                style={{ minWidth: "500px", color: "#333", paddingLeft: "10px" }}
                              >
                                COURSE ENROLLED
                              </th>
                              <th className="text-left" style={{ color: "#333" }}>
                                FEES
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                           
                                <tr>
                                  <td className="text-left" style={{ paddingLeft: "10px", padding: 5, color: "#333" }}><strong>PCMB :</strong></td>
                                  <td className="text-left" style={{ padding: 5 }}>{parseFloat(FeesSummary.Tuition_Fees).toFixed(2)}</td>
                                </tr><tr>

                                  <td className="text-left" style={{ paddingLeft: "10px", padding: 5, color: "#333" }}><strong>DISCOUNT :</strong></td>
                                  <td className="text-left" style={{ padding: 5 }}>{parseFloat(FeesSummary.T_Discount).toFixed(2)}</td>
                                </tr><tr>
                                  <td className="text-left" style={{ paddingLeft: "10px", padding: 5, color: "#333" }}><strong>GRAND TOTAL :</strong></td>
                                  <td className="text-left" style={{ padding: 5 }}>{parseFloat(FeesSummary.Final_T_Fees).toFixed(2)}</td>
                                </tr>
                          
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row mt-2 col-sm-4">
                      <div className="span8">
                        <h6>
                          <strong>
                            PAYMENT DETAILS :
                          </strong>
                        </h6>
                      </div>
                    </div>
                    <div className="row mb-1 mt-2">
                      <div className="span8 well invoice-body">
                        <div className="col-md-12 col-sm-12 table-responsive">
                          <table className="table productTable display table-bordered mb-0"  style={{fontSize: "12px"}}>
                            <thead>
                              <tr>
                                <th
                                  className="text-left"
                                  style={{ color: "#333", paddingLeft: "10px" }}
                                >
                                  #
                                </th>
                                <th className="text-left" style={{ color: "#333" }}>
                                INSTALMENT DATE
                                </th>
                                <th className="text-left" style={{ color: "#333" }}>
                                AMOUNT
                                </th>
                                <th className="text-left" style={{ color: "#333" }}>
                                STATUS
                                </th>
                                <th className="text-left" style={{ color: "#333" }}>
                                MODE
                                </th>
                                <th className="text-left" style={{ color: "#333" }}>
                                DETAILS
                                </th>
                                <th className="text-left" style={{ color: "#333" }}>
                                RECEIPT NO
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {InstallmentData.map((pro, index) => (
                                  <tr key={index}>
                                    <td style={{ padding: 5 }}>{index+1}</td>
                                    <td className="text-left" style={{ padding: 5 }}>
                                    {formatDate(pro.Date_Added)}
                                    </td>
                                    <td className="text-left" style={{ padding: 5 }}>
                                      {parseFloat(pro.Amount).toFixed(2)}
                                    </td>
                                    <td className="text-left" style={{ padding: 5 }}>
                                    {pro.Payment_Status}
                                      </td>
                                      <td className="text-left" style={{ padding: 5 }}>
                                      {pro.Mode}
                                      </td>
                                      <td className="text-left" style={{ padding: 5 }}>
                                      {pro.Details}
                                      </td>
                                      <td className="text-left" style={{ padding: 5 }}>
                                      {pro.Acknowledgement_No}
                                      </td>
                                  </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
             
                    <div className="row" style={{fontSize: "12px"}}>
                      <p style={{ color: "#333" }}>
                        <strong>1. This receipt is subject to realisation of cheque.<br />
                          2. This receipt should be carefully preserved and must be produced on demand.<br />
                          3. Fees once paid are not refundable/transferable in any circumstances.
                          {/* <br/>4. If Cheque dishonoured by bank Rs.250/- charges will be applicable. */}
                        </strong>
                      </p>
                      <div className="text-center" style={{ color: "#333" }}>
                        {/* <h6>SUBJECT TO NAVI MUMBAI JURISDICTION</h6> */}
                        <p>*This is a Computer Generated Receipt*</p>
                      </div>
                    </div>
                  </div>
                  {/* <hr style={hrStyle}/> */}
                </div>
              </div>
              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeesReceipts;
