import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import $ from "jquery";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import { debounce } from "lodash";
import { Pagination } from "react-bootstrap";
import AddQuestionModal from "./AddQuestionModal";
import UploadQuestion from "./UploadQuestion";


const ViewQuestionBank = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { ID } = useParams();
  const [errors, setErrors] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [Questions, setQuestions] = useState([]);
  const [TopicId, setTopicId] = useState("");
  const [Topics, setTopics] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [ClassId, setClassId] = useState("");
  const [SubjectId, setSubjectId] = useState("");
  const [ClassName, setClassName] = useState("");
  const [SubjectName, setSubjectName] = useState("");
  const [QuestionLevel, setQuestionLevel] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;

  // Debounced renderMath function to prevent excessive calls
  const debouncedRenderMath = useCallback(
    debounce(() => {
      if (window.MathJax) {
        window.MathJax.typesetPromise().catch((err) =>
          console.error("MathJax rendering error:", err)
        );
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (TopicId) {
      renderMath();
    }
    fetchData();
  }, [TopicId]);

  useEffect(() => {
    fetchData();
  }, [userData, ClassId, SubjectId, TopicId]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData?.Org_Id) {
        const AppClass = await axios.get(
          `${apiUrl}/getAppClass?OrgId=${userData?.Org_Id}`
        );
        const AppClassOptions = AppClass.data.map((category) => ({
          label: category.Class_Name,
          value: category.Class_Id,
        }));
        setBatchOptions(AppClassOptions);
      }

      if (ClassId) {
        const AppSubject = await axios.get(
          `${apiUrl}/getAppSubject?Class_Id=${ClassId}`
        );
        const AppSubjectOptions = AppSubject.data.map((category) => ({
          label: category.Sub_Name,
          value: category.Sub_Id,
        }));
        setSubjectOption(AppSubjectOptions);
      }

      if (ClassId && SubjectId) {
        const getTopic = await axios.get(
          `${apiUrl}/getTopicsOfQuestions?Std_Id=${ClassId}&Sub_Id=${SubjectId}`
        );
        setTopics(getTopic.data);
      }

      if (TopicId) {
        const getQuestions = await axios.get(
          `${apiUrl}/getQuestionsTopicWise?Topic_Id=${TopicId}`
        );
        setQuestions(getQuestions.data);
        renderMath();
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const renderMath = () => {
    debouncedRenderMath();
  };

  const showModal = () => {
    setIsModal(true);
  };

  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const showModal1 = (e) => {
    setIndexValue(e);
    setIsModal1(true);
  };

  const closed1 = () => {
    setIsModal1(false);
    fetchData();
  };

  const showModal2 = () => {
    setIsModal2(true);
  };

  const closed2 = () => {
    setIsModal2(false);
    fetchData();
  };

  const cleanHTML = (htmlString) => {
    const cleanedHTML = htmlString.replace(/<br\s*\/?>/gi, "");
    return cleanedHTML;
  };

  // Pagination logic
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = Questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteUser = (Id, Name) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
    }).then((result) => {
        if (result.value) {
            handleDelete(Id, Name);
        }
    });
};
async function handleDelete(Id, Name) {
    showLoader();

    const obj = {
        User_Id: userData?.UserId,
        Org_Id: userData?.Org_Id,
        Org_Name: userData?.Org_Name,
        Head_Id: userData?.Head_Id,
        Head_Name: userData?.Head_Name,
        User_Role: userData?.Role,
        LoginUsername: userData?.Username,
        User_Name: userData?.UserName,
        Name: Name,

    };

    try {
        await axios.delete(`${apiUrl}/deleteQuestionsTopicWise/${Id}`, { data: obj });
        Swal.fire("Deleted!", "Question has been deleted.", "success");
        fetchData();
    } catch (error) {
        console.error("Error while deleting Question record:", error);
        Swal.fire("Error", "Failed to delete Question record.", "error");
    } finally {
        hideLoader();
    }
}


  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">Create Test</div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5 d-none">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="ClassRoom_Name">
                          Question Level
                        </label>
                        <select
                          className="form-select"
                          id="ClassRoom_Name"
                          value={QuestionLevel}
                          onChange={(e) => {
                            setQuestionLevel(e.target.value);
                          }}
                        >
                          <option selected disabled value="" label="--Select--"></option>
                          <option value="Easy">Easy</option>
                          <option value="Moderate">Moderate</option>
                          <option value="Difficult">Difficult</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Class">
                          Class
                        </label>
                        <select
                          className="form-select"
                          id="Class"
                          value={ClassId}
                          onChange={(e) => {
                            const selectedIndex = e.target.selectedIndex;
                            const selectedOption = e.target[selectedIndex];
                            setClassId(e.target.value);
                            setClassName(selectedOption.label);
                          }}
                        >
                          <option selected disabled value="" label="--Select--"></option>
                          {BatchOptions?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Subject">
                          Subject
                        </label>
                        <select
                          className="form-select"
                          id="Subject"
                          value={SubjectId}
                          disabled={ClassId === ""}
                          onChange={(e) => {
                            const selectedIndex = e.target.selectedIndex;
                            const selectedOption = e.target[selectedIndex];
                            setSubjectId(e.target.value);
                            setSubjectName(selectedOption.label);
                          }}
                        >
                          <option selected disabled value="" label="--Select--"></option>
                          {SubjectOption?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Topics?.length > 0 && (
            <div className="row">
              <div
                className="col-md-3"
                style={{
                  color: "#005c9b",
                  maxHeight: "1000px",
                  overflowY: "scroll",
                }}
              >
                {Topics.map((topic, index) => (
                  <div
                    className="card pb-1"
                    key={index}
                    style={{ marginBottom: "0.6rem", height: "80px" }}
                  >
                    <div className="sidebar mt-1" style={{ textAlign: "left" }}>
                      <Link
                        onClick={() => {
                          setTopicId(topic.Topic_Id);
                        }}
                        style={{
                          fontSize: "18px",
                          paddingBottom: "15px",
                          marginLeft: "10px",
                          display: "block",
                          height: "100%",
                        }}
                      >
                        <div className="row p-2">
                          <div
                            className="col-md-12"
                            style={{
                              overflow: "hidden",
                              maxHeight: "40px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span>{topic.Topic_Name}</span>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px", textAlign: "right" }}
                          >
                            <span>Questions: {topic.questions}</span>
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-9">
                <div className="card px-3 pb-3 mt-1">
                  <div className="row">
                    {Questions?.length > 0 ? (
                      <div className="col-md-12">
                        <div
                          className="row"
                          style={{ border: "2px solid #005c9b" }}
                        >
                          <div className="col-md-12 mb-2">
                            <div className="row">
                              <div className="col-md-6">
                                <h6 className="mt-2">
                                  <span style={{ fontSize: "25px" }}>
                                    {Questions[0]?.Topic_Name}
                                  </span>
                                </h6>
                              </div>
                              <div className="col-md-1">
                                <span></span>
                              </div>
                              <div
                                className="col-md-5"
                                style={{ textAlign: "right" }}
                              >
                                <Link
                                  className="btn btn-sm btn-primary mt-2 mr-2"
                                  onClick={() => {
                                    showModal2();
                                  }}
                                >
                                  Upload Questions
                                </Link>{" "}
                                &nbsp;
                                <Link
                                  onClick={() => {
                                    showModal();
                                  }}
                                  className="btn btn-sm btn-primary mt-2"
                                  style={{ float: "right" }}
                                >
                                  Add Question
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{
                              padding: "0",
                              maxHeight: "840px",
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {currentQuestions.map((question, index) => (
                              <div
                                className="row"
                                key={question.Question_Id}
                                style={{
                                  borderTop: "1px solid #005c9b",
                                  paddingTop: "0",
                                  margin: "0 0.1rem",
                                }}
                              >
                                <div
                                  className="col-12"
                                  style={{ padding: "0" }}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <span>
                                      Q. {index + 1 + indexOfFirstQuestion}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      maxWidth: "calc(100% - 50px)",
                                      wordWrap: "break-word",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    &nbsp;
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: question.Question_in_Text,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  className="row option"
                                  style={{
                                    margin: "40px",
                                    marginTop: "15px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <span
                                    className="aspNetDisabled"
                                    style={{ display: "block" }}
                                  >
                                    <input
                                      id={`Option1-${question.Question_Id}`}
                                      type="radio"
                                      name={`Option-${question.Question_Id}`}
                                      checked={question.Correct_ans ===question.Ans_1}
                                      disabled={true}
                                    />
                                    &nbsp;
                                    <span style={{display:"inline-block"}}
                                      dangerouslySetInnerHTML={{
                                        __html: cleanHTML(question.Ans_1),
                                      }}
                                    ></span>
                                  </span>
                                  <span
                                    className="aspNetDisabled"
                                    style={{ display: "block" }}
                                  >
                                    <input
                                      id={`Option2-${question.Question_Id}`}
                                      type="radio"
                                      name={`Option-${question.Question_Id}`}
                                      checked={question.Correct_ans ===question.Ans_2}
                                      disabled={true}
                                    />
                                    &nbsp;
                                    <span style={{display:"inline-block"}}
                                      dangerouslySetInnerHTML={{
                                        __html: cleanHTML(question.Ans_2),
                                      }}
                                    ></span>
                                  </span>
                                  <span
                                    className="aspNetDisabled"
                                    style={{ display: "block" }}
                                  >
                                    <input
                                      id={`Option3-${question.Question_Id}`}
                                      type="radio"
                                      name={`Option-${question.Question_Id}`}
                                      checked={question.Correct_ans ===question.Ans_3}
                                      disabled={true}
                                    />
                                    &nbsp;
                                    <span style={{display:"inline-block"}}
                                      dangerouslySetInnerHTML={{
                                        __html: cleanHTML(question.Ans_3),
                                      }}
                                    ></span>
                                  </span>
                                  <span
                                    className="aspNetDisabled"
                                    style={{ display: "block" }}
                                  >
                                    <input
                                      id={`Option4-${question.Question_Id}`}
                                      type="radio"
                                      name={`Option-${question.Question_Id}`}
                                      checked={question.Correct_ans ===question.Ans_4}
                                      disabled={true}
                                    />
                                    &nbsp;
                                    <span style={{display:"inline-block"}}
                                      dangerouslySetInnerHTML={{
                                        __html: cleanHTML(question.Ans_4),
                                      }}
                                    ></span>
                                  </span>
                                </div>
                                <div style={{ padding: "0 0 20px 40px" }}>
                                  <span className="easy">
                                    {question.Question_Level}
                                  </span>{" "}
                                  &nbsp;
                                  <span className="easy">+{question.Mark}</span>
                                  <Link
                                    className="btn btn-outline-info"
                                    style={{
                                      padding: "7px 13px",
                                      marginBottom: "3px",
                                      marginRight: "10px",
                                    }}
                                    onClick={()=>{
                                      showModal1(question);
                                    }}
                                  >
                                    <i className="fa fa-pen-to-square"></i>
                                  </Link>
                                  <Link
                                    className="btn btn-outline-danger"
                                    style={{
                                      padding: "7px 13px",
                                      marginBottom: "3px",
                                      marginRight: "10px",
                                    }}
                                    onClick={()=>{
                                      deleteUser(question.Question_Id, ` ${question.Class_Name} ${question.Sub_Name}`);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {Array.from(
                            {
                              length: Math.ceil(
                                Questions.length / questionsPerPage
                              ),
                            },
                            (_, index) => (
                              <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={
                              currentPage ===
                              Math.ceil(Questions.length / questionsPerPage)
                            }
                          />
                        </Pagination>
                      </div>
                    ) : (
                      <div className="col-md-12" style={{ padding: 0 }}>
                        <div>
                          <div className="row">
                            <div
                              style={{
                                textAlign: "center",
                                padding: "10px 0 30px",
                              }}
                            >
                              <h2>Create Questions</h2>
                              <img
                                src="/assets/img/CreateQuestion.gif"
                                style={{ height: "200px", width: "200px" }}
                                alt="Create Question"
                              />
                              <br />
                              <Link
                                className="btn btn-primary mt-2"
                                onClick={() => {
                                    showModal2();
                                  }}
                              >
                                Upload Questions
                              </Link>
                              &nbsp;
                              <Link
                                onClick={() => {
                                  showModal();
                                }}
                                className="btn btn-primary mt-2"
                              >
                                Add Question
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModal && (
        <AddQuestionModal
          open={isModal}
          onClose={closed}
          QuestionLevel={QuestionLevel}
          TopicOptions={Topics}
          Subject={{label:SubjectName, value:SubjectId}}
          Class={{label:ClassName, value:ClassId}}
opration="Submit"
        />
      )}
      {isModal1 && <AddQuestionModal open={isModal1} onClose={closed1} modalData={indexValue} QuestionLevel={QuestionLevel}
          TopicOptions={Topics}
          Subject={{label:SubjectName, value:SubjectId}}
          Class={{label:ClassName, value:ClassId}}
opration="Update"

          />}
          {isModal2 && <UploadQuestion open={isModal2} onClose={closed2}
          TopicOptions={Topics} QuestionLevel={QuestionLevel}
          Subject={{label:SubjectName, value:SubjectId}}
          Class={{label:ClassName, value:ClassId}}
          />}
      {loader}
    </div>
  );
};

export default ViewQuestionBank;
