import React, { useState, useEffect } from "react";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";
import Select from "react-select";


const BoardDropDown = ({ isClearable, value, disabled, name, id, onChange, required }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [headOptions, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();
                    const result = await axios.get(
                        `${apiUrl}/getBoard?OrgId=${userData.Org_Id}`
                    );

                    const op = result.data.map((category) => ({
                        label: category.Board_Name,
                        value: category.Board_Name,
                    }));
                    setData(op);

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData]);

    console.log(headOptions);

    return (
        <>
        
        <Select
    name={name}
    id={id}
    value={headOptions.find(option => option.value === value)} // Ensure the correct option is selected based on the value
    onChange={onChange}
    options={headOptions}
    placeholder="--Select--"
    isDisabled={disabled}
    isClearable={isClearable}
    required={required}
/>
            {loader}
        </>
    );
};


export default BoardDropDown;
