import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import Swal from "sweetalert2";

const UploadQuestionsImage = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [copiedIndex, setCopiedIndex] = useState(-1);


  useEffect(() => {
  
  }, []);

  const handleButtonClick = () => {
    document.getElementById("ContentPlaceHolder1_fileupPhoto").click();
  };

  const copyText = (url, index) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(-1);
      }, 2000);
    });
  };

  const handleSubmit = async (e) => {
    const selectedFiles = e.target.files;

   
    // if (selectedFiles.length > 4) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Too many images",
    //     text: "Please select a maximum of 4 images.",
    //   });
    //   return;
    // }

   
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].size > 1000000) { 
                Swal.fire({
          icon: "error",
          title: "File size exceeded",
          text: "Please select images that are less than 1MB in size.",
        });
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("QuestionsImage", selectedFiles[i]);
    }

    showLoader();

    try {
      // Send POST request to upload images
      const response = await axios.post(`${apiUrl}/uploadQuestionsImage`, formData);
      Swal.fire({
        title: "Success!",
        text: "Question Image Added Successfully!!",
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setImages(response.data);
        }
      });

      // Hide loader
      hideLoader();
    } catch (error) {
      console.error("Error uploading images:", error);
      Swal.fire({
        icon: "error",
        title: "Server Error",
        text: "Server is busy, please try again later.",
      });

      // Hide loader
      hideLoader();
    }
  };

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6"> Image Upload</div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5 d-none">
              {/* Add your HeadMasterDropDown component here */}
            </div>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <div className="row">
                  <div style={{ marginBottom: "20px" }}>
                    <input
                      type="file"
                      multiple
                      name="fileupPhoto"
                      id="ContentPlaceHolder1_fileupPhoto"
                      className="fileupload"
                      accept=".bmp, .png, .jpg, .jpeg"
                      onChange={handleSubmit}
                      style={{ display: "none" }}
                    />
                    <button
                      onClick={handleButtonClick}
                      id="ContentPlaceHolder1_btnsetProfile"
                      className="btn btn-sm btn-warning mr-4 btnsetProfile"
                      style={{ marginRight: "0 !important" }}
                    >
                      Choose Images..
                    </button>
                    <span
                      id="ContentPlaceHolder1_lb"
                      style={{ fontSize: "13px", marginLeft: "10px" }}
                    >
                      (Note: Image should be less than 1MB.)
                    </span>
                  </div>
                  {images.length > 0 && (
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table
                          className="table table-hover hidden-action"
                          cellSpacing="0"
                          cellPadding="2"
                          align="center"
                          style={{
                            color: "Black",
                            backgroundColor: "White",
                            borderColor: "#DEDFDE",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">&nbsp;Name</th>
                              <th scope="col">LINK</th>
                              <th scope="col">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {images.map((image, index) => (
                              <tr key={index}>
                                <td style={{ width: "20%", wordWrap: "break-word" }}>
                                  <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                                    {image.name}
                                  </span>
                                </td>
                                <td style={{ width: "70%", wordWrap: "break-word" }}>
                                  <span style={{ fontSize: "13px" }}>
                                    {image.url}
                                  </span>
                                </td>
                                <td align="left" style={{ width: "10%" }}>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-default btncopylink"
                                    onClick={() => copyText(image.url, index)}
                                  >
                                    {copiedIndex === index ? (
                                      <span className="text-success" style={{ fontSize: "13px" }}>
                                        Link Copied
                                      </span>
                                    ) : (
                                      "Copy link"
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default UploadQuestionsImage;
