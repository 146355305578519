import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../Components/dateFormate'
import { FaEye } from "react-icons/fa";


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Type: updateData.Type,
        Title: updateData.Title,
        Attachment: updateData.Attachment
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,

            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};
        const formdata = new FormData();
        formdata.append("User_Id", updateBill.User_Id);
        formdata.append("Org_Id", updateBill.Org_Id);
        formdata.append("Org_Name", updateBill.Org_Name);
        formdata.append("User_Role", updateBill.User_Role);
        formdata.append("LoginUsername", updateBill.LoginUsername);
        formdata.append("Display_Name", updateBill.Display_Name);
        formdata.append("Head_Id", updateBill.Head_Id);
        formdata.append("Head_Name", updateBill.Head_Name ? updateBill.Head_Name : "");
        formdata.append("Title", Bill.Title);
        formdata.append("Attachment", Bill.Attachment);
        formdata.append("Type", Bill.Type);


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updatePublications/${updateData?.Sr_No}`, formdata)
                .then((res) => {
                    Swal.fire("Success!", "Publications Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Publications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-4">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Type">
                                                        Type<span style={{ color: "Red" }}>*</span>
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Type"
                                                        name="Type"
                                                        required
                                                        value={Bill.Type}
                                                        onChange={handleChange}
                                                    >
                                                        <option selected value="">
                                                            --Select--
                                                        </option>
                                                        <option value="Notes">
                                                        Notes
                                                        </option>
                                                        <option value="Events">
                                                        Events
                                                        </option>
                                                        <option value="Features">
                                                        Features
                                                        </option>
                                                        <option value="Results">
                                                        Results
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Title">
                                            Title<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Title"
                                                id="Title"
                                                required
                                                value={Bill.Title}
                                                placeholder="Enter Title"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                  
                                        <div className={Bill.Attachment ? 'mb-3 col-md-3' : 'mb-3 col-md-4'}>
                                <label className="form-label" htmlFor="Attachment">
                                    Attatchment<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="file"
                                    name="Attachment"
                                    id="Attachment"
                                    accept=".bmp, .png, .jpg, .jpeg, .pdf"
                                    onChange={(e) =>
                                        setBill({
                                            ...Bill,
                                            Attachment: e.target.files[0],
                                        })
                                    }
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            {Bill.Attachment ? (<div className="mb-3 col-md-1 mt-4">

                                <Link
                                    to={`${apiUrl}/viewPublication/${Bill.Attachment}`}
                                    target="_blank" rel="noopener noreferrer"
                                    className="cursor-pointer text-primary"
                                    style={{ textDecoration: "none", marginLeft: "7px" }}
                                >
                                    <FaEye />

                                </Link>
                            </div>
                            ) : (
                                <></>
                                )}


                                       
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                            Update
                                            </button>
                                        </div>
                                    </div>
                                </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
