import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import dayjs from "dayjs";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import "../Students/toggle.css";
import { useParams } from "react-router-dom";
import AgGridTable from "./ReportAgGridTable";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
  } from "chart.js";
  import { Line, Bar } from "react-chartjs-2";
  ChartJS.register(BarElement, CategoryScale, LinearScale, Legend);

const years = [];
for (let i = 2021; i <= 2030; i++) {
  years.push(i);
}


const monthChartOptions = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            //text: "Student Monthwise Performance",
        },
        legend: {
            display: true,
            position: "top", // Changed to "top" for better visibility
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            max: 1969, // Adjust this if necessary
            ticks: {
                stepSize: 10,
                callback: (value) => `${value}`, 
            },
            title: {
                display: true,
                //text: "Counts",
            },
        },
        x: {
            title: {
                display: true,
                //text: "Months",
            },
        },
    },
};


const getFreshColor = (index) => {
  const colors = [
    "rgba(255, 99, 132, 0.5)",  
    "rgba(54, 162, 235, 0.5)",  
    "rgba(255, 206, 86, 0.5)",  
    "rgba(75, 192, 192, 0.5)",  
    "rgba(153, 102, 255, 0.5)", 
    "rgba(255, 159, 64, 0.5)",  
    "rgba(255, 99, 132, 0.5)",  
    "rgba(54, 162, 235, 0.5)",  
    "rgba(255, 206, 86, 0.5)",  
    "rgba(75, 192, 192, 0.5)",  
    "rgba(153, 102, 255, 0.5)", 
    "rgba(255, 159, 64, 0.5)",  
  ];
  return colors[index % colors.length];
};


const BiometricAttendanceCount = () => {
    const currentYear = new Date().getFullYear();
  const { StdId, BatchId } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [Year, setYear] = useState(currentYear);
  const [data, setStudentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [monthChartData, setMonthChartData] = useState();

  useEffect(() => {
        fetchMonthWise();
  }, [userData, Year]);

  const fetchMonthWise = async () => {
    try {
        showLoader();
        if (Year && userData?.Org_Id) {
            const response = await axios.get(`${apiUrl}/getBiometricAttendanceCount?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}&Year=${Year}`);
            //console.log(response.data);

            setFilteredData(response.data || []);
            setStudentData(response.data || []);

            if (response.data && response.data.length > 0) {
                const monthNames = response.data.map(item => item.MonthName);
                const deviceLogCounts = response.data.map(item => item.DeviceLogCount);

                //console.log("Month Names: ", monthNames); // Log month names
                //console.log("Device Log Counts: ", deviceLogCounts); // Log device log counts

                const colors = deviceLogCounts.map((_, index) => getFreshColor(index));

                const monthChartData = {
                    labels: monthNames,
                    datasets: [
                        {
                            label: `Total Counts ${Year}`,
                            data: deviceLogCounts,
                            fill: false,
                            backgroundColor: colors,  // Array of colors
                            borderColor: colors,       // Array of border colors
                            borderWidth: 1,
                        },
                    ],
                };

                setMonthChartData(monthChartData);
            } else {
               // console.log("No data available for the selected year.");
                setMonthChartData(null);
            }
        }
    } catch (error) {
        console.error("Error fetching month-wise chart data:", error);
    } finally {
        hideLoader();
    }
};

  useEffect(() => {
    const newAgcolumns = [
      { field: "SrNo" },
      { field: "Month" },
      { field: "Count" },
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData?.map((row, index) => ({
        SrNo: index + 1,
        Month: row.MonthName,
        Count: row.DeviceLogCount,
      }));
  
      setAgrows(newAgrows);
     // console.log("Agrows: ", newAgrows);
    }
  }, [filteredData]);

  


  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Biometric Attendance Count", 20, 10);

    const columns = ["Sr No", "Month", "Count"];

    const rows = filteredData.map((row, index) => [
      index + 1,
      row.MonthName,
      row.DeviceLogCount,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Biometric Attendance Count.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sr No",  "Month", "Count"];
    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([index + 1,  row.Month, row.Count]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Biometric Attendance Count");

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Biometric Attendance Count.xlsx");
  };



  return (
    <div className="content">
        <div className="container-fluid">
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4
                className="col-md-6 mb-0"
                data-anchor="data-anchor"
                id="basic-form"
              >
         Biometric Attendance Count
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  href="#basic-form"
                  style={{ paddingLeft: "0.375em" }}
                ></a>
              </h4>
              <div className="card h-100 hover-actions-trigger  mt-4">
              <div className="card-body" style={{ padding: "15px" }}>
           <div className="row">
           <div className="col-md-6">
           {monthChartData ? (
    <Bar options={monthChartOptions} data={monthChartData} />
) : (
    <p className="text-center">No data available for the selected year.</p>
)}
 
           </div>
           <div className="col-md-6">
           <div className="col-md-12 text-center mt-3">
          

          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props}>Export to PDF</Tooltip>
            )}
            placement="bottom"
          >
            <Button
              variant=""
              onClick={() => DownloadPDF()}
              style={{ float: "right", padding: "5px 10px 5px 5px" }}
            >
              <i
                className="fas fa-file-pdf"
                style={{ fontSize: "25px" }}
              ></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props}>Export to Excel</Tooltip>
            )}
            placement="bottom"
          >
            <Button
              variant=""
              onClick={() => DownloadExcel()}
              style={{ float: "right", padding: "5px 5px 5px 10px" }}
            >
              <i
                className="fas fa-file-excel"
                style={{ fontSize: "25px" }}
              ></i>
            </Button>
          </OverlayTrigger>
          <div className="col-4 mb-2">
      <select
        className="form-select form-select-sm"
        id="example-select"
        onChange={(e)=>{
          setYear(e.target.value)
        }}
        value={Year}
      >
         {years?.map((year) => (
<option key={year} value={year}>
  {year}
</option>
))}
      </select>
    </div>
        </div>
            <div className="row">
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                {filteredData.length === 0 && (<h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
              </div>
          
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  {Agrows.length > 0 && (
                    <AgGridTable row={Agrows} column={Agcolumns} />
                  )}
                </div>

            </div>
           </div>
            </div>
            </div>
            </div>
            </div>
     
          </div>
        </div>
        {loader}
      </div>

  );
};

export default BiometricAttendanceCount;
