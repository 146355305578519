import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import BankDropdoun from "../DropDowns/BankDropdoun";
import VoucherCategory from "../DropDowns/ExCategory";
import YearDropDown from "../DropDowns/YearDropDown";
import { formatDate } from "../../Components/dateFormate";

const CalenderModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const {Sr_No}=useParams();
  const [eventData, setEventData] = useState([]);
    const [show, setShow] = useState(open);
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData)


    const loadEvents = async () => {
        showLoader();
        try {
          const result = await axios.get(
            `${apiUrl}/getLectureSchedule?Org_Id=${userData?.Org_Id}&Teacher_Id=${Sr_No}&dateTxt=${formatDate(updateData?.Lecture_Date)}`
          );
          setEventData(result.data);
          console.log(result.data);
        } catch (error) {
          console.error("Error fetching events:", error);
        } finally {
          hideLoader();
        }
      };
    
      useEffect(() => {
        if (userData && userData.Org_Id) {
          loadEvents();
        }
      }, [userData]);
    




    return (<div>
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title> 
                Lecture Details - {formatDate(updateData.Lecture_Date)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               
            <div className="row mb-1 mt-4">
                      <div className="span8 well invoice-body">
                        <div className="col-md-12 col-sm-12 table-responsive">
                          <table className="table productTable display table-bordered mb-0"  style={{fontSize: "12px"}}>
                            <thead>
                              <tr>
                                <th
                                  className="text-center"
                                  style={{ color: "#333", paddingLeft: "10px" }}
                                >
                                  #
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Standard
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Subject
                                </th>
                            
                                <th className="text-center" style={{ color: "#333" }}>
                                Lecture
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                              Time
                                </th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              {eventData?.map((pro, index) => (
                                  <tr key={index}>
                                    <td className="text-center" style={{ padding: 5 }}>{index+1}</td>
                                    <td className="text-center" style={{ padding: 5 }}>
                                    {pro.Std_Name}
                                    </td>
                                    <td className="text-center" style={{ padding: 5 }}>
                                      {pro.Subject_Name}
                                    </td>
                                   
                                      <td className="text-center" style={{ padding: 5 }}>
                                      {pro.Lecture_Test}
                                      </td>
                                      <td className="text-center" style={{ padding: 5 }}>
                                      {`${pro?.From_Time} to ${pro?.To_Time}`}
                                      </td>
                                    
                                  </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
               
                {loader}
            </Modal.Body>
        </Modal>

    </div>
    )
}

export default CalenderModal