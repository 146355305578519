import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, Outlet, useNavigate } from "react-router-dom";
//import ChangePasswordModal from "./ChangePasswordModal";
import { useUser } from "./UserContext";
import { apiUrl } from "./apiConfig";
import useFullPageLoader from "./useFullPageLoader";
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Dashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { setData } = useUser();
  const { setFilterData } = useUser();
  const [userData, setUserData] = useState(null);
  const [MenuData, setMenuData] = useState([]);
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const res = await axios.get(`${apiUrl}/dashboard`);

        if (res.data.Status === "Success") {
          const userData = {
            UserId: res.data.UserId,
            Role: res.data.Role,
            First_Name: res.data.First_Name,
            Middle_Name: res.data.Middle_Name,
            Last_Name: res.data.Last_Name,
            UserName: res.data.Display_Name,
            Photo: res.data.Photo,
            Head_Name: res.data.Head_Name,
            Head_Id: res.data.Head_Id,
            Subhead_Id: res.data.Subhead_Id,
            Subhead_Name: res.data.Subhead_Name,
            Org_Name: res.data.Name,
            Web_Logo: res.data.Web_Logo,
            Email: res.data.Email,
            Org_Id: res.data.Org_Id,
            Username: res.data.Username,
            Password: res.data.Password,
          };

          setUserData(userData);
          console.log(userData);

          

          if (userData.Head_Id !== "All") {
            setData(userData);
            setFilterData((prev) => ({
              ...prev,
              filterToggle: false,
              dataView: 'Table View',
            }));
          }

          const menuRes = await axios.get(`${apiUrl}/getDashboardMenu`, {
            params: {
              OrgId: userData.Org_Id,
              UserId: userData.UserId,
              Role: userData.Role,
            },
          });

          setMenuData(menuRes.data);
          console.log(menuRes.data);

        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);


  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    axios
      .get(`${apiUrl}/logout`)
      .then((res) => {
        setData(null);
        setUserData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  const selectedGroup2 = MenuData.find(group => group.Group_Id === 2);

  // Check if the group and tasks are present
  const hasDashboard = selectedGroup2?.tasks.some(task => task.Task_Name === 'DashBoard');
  const hasEnquiry = selectedGroup2?.tasks.some(task => task.Task_Name === 'Enquiry');

  const selectedGroup3 = MenuData.find(group => group.Group_Id === 3);

  // Check for the presence of each task
  const hasAddNewStudent = selectedGroup3?.tasks.some(task => task.Task_Name === 'Add New Student');
  const hasViewActiveStudent = selectedGroup3?.tasks.some(task => task.Task_Name === 'View Active Student');
  const hasViewInactiveStudent = selectedGroup3?.tasks.some(task => task.Task_Name === 'View Inactive Student');
  const hasViewStudentByBatch = selectedGroup3?.tasks.some(task => task.Task_Name === 'View Student By Batch');
  const hasAdmissionCollection = selectedGroup3?.tasks.some(task => task.Task_Name === 'Admission Collection/Refund');
  const hasUploadBulkStudent = selectedGroup3?.tasks.some(task => task.Task_Name === 'Upload/Import Bulk Student');
  const hasPromoteStudents = selectedGroup3?.tasks.some(task => task.Task_Name === 'Promote Students');

  const selectedGroup4 = MenuData.find(group => group.Group_Id === 4);

  // Check for the presence of each task
  const hasAddViewStaff = selectedGroup4?.tasks.some(task => task.Task_Name === 'Add/View Staff');
  const hasStaffAttendance = selectedGroup4?.tasks.some(task => task.Task_Name === 'Staff Attendance');

  const selectedGroup5 = MenuData.find(group => group.Group_Id === 5);

  // Check for the presence of each task
  const hasBills = selectedGroup5?.tasks.some(task => task.Task_Name === 'Bills');
  const hasVouchers = selectedGroup5?.tasks.some(task => task.Task_Name === 'Vouchers');
  const hasExpenseSummary = selectedGroup5?.tasks.some(task => task.Task_Name === 'Expense Summary');
  const hasPaymentUpdates = selectedGroup5?.tasks.some(task => task.Task_Name === 'Payment Updates');
  const hasFeesSummary = selectedGroup5?.tasks.some(task => task.Task_Name === 'Fees Summary');
  const hasFeesOverview = selectedGroup5?.tasks.some(task => task.Task_Name === 'Fees Overview');

  const selectedGroup6 = MenuData.find(group => group.Group_Id === 6);

  // Check for the presence of each task
  const hasDailyThoughts = selectedGroup6?.tasks.some(task => task.Task_Name === 'Daily Thoughts');
  const hasHolidayCalendar = selectedGroup6?.tasks.some(task => task.Task_Name === 'Holiday Calendar');
  const hasNotice = selectedGroup6?.tasks.some(task => task.Task_Name === 'Notice');
  const hasEmergencyCircular = selectedGroup6?.tasks.some(task => task.Task_Name === 'Emergency Circular');
  const hasSendComplaint = selectedGroup6?.tasks.some(task => task.Task_Name === 'Send Complaint/Remarks');
  const hasViewComplaints = selectedGroup6?.tasks.some(task => task.Task_Name === 'View Complaint/Remarks');
  const hasGallery = selectedGroup6?.tasks.some(task => task.Task_Name === 'Gallery');

  const selectedGroup7 = MenuData.find(group => group.Group_Id === 7);

  // Check for the presence of each task
  const hasHomeWork = selectedGroup7?.tasks.some(task => task.Task_Name === 'Home Work');
  const hasClassWork = selectedGroup7?.tasks.some(task => task.Task_Name === 'Class Work');
  const hasNotes = selectedGroup7?.tasks.some(task => task.Task_Name === 'Notes');
  const hasDPP = selectedGroup7?.tasks.some(task => task.Task_Name === 'DPP');
  const hasVideoLinks = selectedGroup7?.tasks.some(task => task.Task_Name === 'Video Links');
  const hasELearning = selectedGroup7?.tasks.some(task => task.Task_Name === 'E-Learning');

  const selectedGroup8 = MenuData.find(group => group.Group_Id === 8);

  // Check for the presence of each task
  const hasPublications = selectedGroup8?.tasks.some(task => task.Task_Name === 'Publications');
  const hasBannerImages = selectedGroup8?.tasks.some(task => task.Task_Name === 'Banner Images');

  const selectedGroup9 = MenuData.find(group => group.Group_Id === 9);

  // Check for the presence of each task
  const hasSuggestionsMessages = selectedGroup9?.tasks.some(task => task.Task_Name === 'Suggestions/Messages');
  const hasFacultyNotice = selectedGroup9?.tasks.some(task => task.Task_Name === 'Faculty Notice');
  const hasStudentDoubts = selectedGroup9?.tasks.some(task => task.Task_Name === 'Student Doubts');

  const selectedGroup10 = MenuData.find(group => group.Group_Id === 10);

  // Check for the presence of each task
  const hasNewLecture = selectedGroup10?.tasks.some(task => task.Task_Name === 'New Lecture');
  const hasViewLectureSchedule = selectedGroup10?.tasks.some(task => task.Task_Name === 'View Lecture Schedule');
  const hasLectureReportStandard = selectedGroup10?.tasks.some(task => task.Task_Name === 'Lecture Report Standard');
  const hasLectureReportFaculty = selectedGroup10?.tasks.some(task => task.Task_Name === 'Lecture Report Faculty');

  const selectedGroup11 = MenuData.find(group => group.Group_Id === 11);

  // Check for the presence of each task
  const hasTest = selectedGroup11?.tasks.some(task => task.Task_Name === 'Test');
  const hasExams = selectedGroup11?.tasks.some(task => task.Task_Name === 'Exams');
  const hasResultSummary = selectedGroup11?.tasks.some(task => task.Task_Name === 'Result Summary');

  const mockTestsGroup12 = MenuData.find(group => group.Group_Id === 12);
  const dailyQuizGroup13 = MenuData.find(group => group.Group_Id === 13);

  const selectedGroup14 = MenuData.find(group => group.Group_Id === 14);

  //Check for the presence of each task
  const hasManageClasses = selectedGroup14?.tasks.some(task => task.Task_Name === 'Manage Classes');
  const hasManageSubjects = selectedGroup14?.tasks.some(task => task.Task_Name === 'Manage Subjects');
  const hasManageTopicsManageApp = selectedGroup14?.tasks.some(task => task.Task_Name === 'Manage Topics Manage App');
  const hasManageCBSEClasses = selectedGroup14?.tasks.some(task => task.Task_Name === 'Manage CBSE Classes');
  const hasManageCBSESubjects = selectedGroup14?.tasks.some(task => task.Task_Name === 'Manage CBSE Subjects');



  // Check for the presence of each task
  const hasAddViewTestSet = mockTestsGroup12?.tasks.some(task => task.Task_Name === 'Add/View Test Set');
  const hasQuestionBank = mockTestsGroup12?.tasks.some(task => task.Task_Name === 'Question Bank');
  const hasDailyQuiz = dailyQuizGroup13?.tasks.some(task => task.Task_Name === 'Daily Quiz');

  const inventoryGroup = MenuData.find(group => group.Group_Id === 15);

  // Check for the presence of each task
  const hasManageVendors = inventoryGroup?.tasks.some(task => task.Task_Name === 'Manage Vendors');
  const hasManageProductCategory = inventoryGroup?.tasks.some(task => task.Task_Name === 'Manage Product Category');
  const hasManageBills = inventoryGroup?.tasks.some(task => task.Task_Name === 'Manage Bills');
  const hasManageStock = inventoryGroup?.tasks.some(task => task.Task_Name === 'Manage Stock');
  const hasVendorPayments = inventoryGroup?.tasks.some(task => task.Task_Name === 'Vendor Payments');
  const hasVendorLedger = inventoryGroup?.tasks.some(task => task.Task_Name === 'Vendor Ledger');

  const whatsappGroup = MenuData.find(group => group.Group_Id === 16);

  // Check for the presence of each task
  const hasSendWhatsappMessage = whatsappGroup?.tasks.some(task => task.Task_Name === 'Send Whatsapp Message');
  const hasPendingFees = whatsappGroup?.tasks.some(task => task.Task_Name === 'Pending Fees');
  const hasUpcomingFees = whatsappGroup?.tasks.some(task => task.Task_Name === 'Upcoming Fees');
  const hasAbsentStudents = whatsappGroup?.tasks.some(task => task.Task_Name === 'Absent Students');
  const hasBirthdays = whatsappGroup?.tasks.some(task => task.Task_Name === 'Birthdays');

  const reportsGroup = MenuData.find(group => group.Group_Id === 17);

  // Check for the presence of each task
  const hasStudentBiometricAttendance = reportsGroup?.tasks.some(task => task.Task_Name === 'Student Biometric Attendance');
  const hasFacultyBiometricAttendance = reportsGroup?.tasks.some(task => task.Task_Name === 'Faculty Biometric Attendance');
  const hasFacultyCollectiveReport = reportsGroup?.tasks.some(task => task.Task_Name === 'Monthly Biometric - Faculty');
  const hasBiometricAttendanceCount = reportsGroup?.tasks.some(task => task.Task_Name === 'Biometric Attendance Count');
  const hasFeesReport = reportsGroup?.tasks.some(task => task.Task_Name === 'Fees Report');
  const hasLectureReport = reportsGroup?.tasks.some(task => task.Task_Name === 'Lecture Report');
  const hasTopicWiseLectureReport = reportsGroup?.tasks.some(task => task.Task_Name === 'Topic Wise Lecture Report');
  const hasFeesInstallmentSummary = reportsGroup?.tasks.some(task => task.Task_Name === 'Fees Installment Summary');
  const hasReportCard = reportsGroup?.tasks.some(task => task.Task_Name === 'Report Card');
  const hasIDCard = reportsGroup?.tasks.some(task => task.Task_Name === 'ID Card');
  const hasIncomeExpenses = reportsGroup?.tasks.some(task => task.Task_Name === 'Income & Expenses');
  const hasStockReport = reportsGroup?.tasks.some(task => task.Task_Name === 'Stock Report');
  const hasAdmissionCancellation = reportsGroup?.tasks.some(task => task.Task_Name === 'Admission Cancellation/Refund');

  const orgSettingsGroup = MenuData.find(group => group.Group_Id === 18);
  const staffPayrollGroup = MenuData.find(group => group.Group_Id === 19);
  const userSettingsGroup = MenuData.find(group => group.Group_Id === 20);

  // Check for the presence of each task
  const hasSettings = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Settings');
  const hasBranches = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Branches');
  const hasFinancialYear = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Financial Year');
  const hasCourses = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Courses');
  const hasStandard = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Standard');
  const hasBatch = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Batch');
  const hasSubject = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Subject');
  const hasTopic = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Topic');
  const hasExam = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Exams');
  const hasSchoolCollege = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View School/College');
  const hasBoard = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Board');
  const hasBank = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Bank');
  const hasClassroom = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Add/View Class Room');
  const hasSMSSettings = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'SMS Settings');
  const hasExpenseTypeCategory = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Expense Type/Category');
  const hasHallTicketSettings = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Hall Ticket Settings');
  const hasStudentIdPrefix = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Student Id Prefix');
  const hasReceiptPrefix = orgSettingsGroup?.tasks.some(task => task.Task_Name === 'Receipt Prefix');

  const hasStaffHoliday = staffPayrollGroup?.tasks.some(task => task.Task_Name === 'Staff/Faculty Holiday');
  const hasLateHalfDay = staffPayrollGroup?.tasks.some(task => task.Task_Name === 'Late/HalfDay');

  const hasAdminStaff = userSettingsGroup?.tasks.some(task => task.Task_Name === 'Admin/Staff');
  const hasMasterAdmin = userSettingsGroup?.tasks.some(task => task.Task_Name === 'Master Admin');


  return (
    <div>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <nav className="navbar navbar-top navbar-expand-lg" id="navbarTopNew">
            <div className="navbar-logo">
              <button
                className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToplCollapse"
                aria-controls="navbarToplCollapse"
                aria-expanded="false"
                aria-label="Toggle Navigation"
              >
                <span className="navbar-toggle-icon">
                  <span className="toggle-line"></span>
                </span>
              </button>
              <Link className="navbar-brand me-1 me-sm-3" to="/">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <img
                      id="logoimg"
                      className="brand-logo"
                      src={`/CustomerLogo/${userData?.Web_Logo}`}
                      style={{ height: '57px', width: '236px' }}
                    />

                  </div>
                </div>
              </Link>
            </div>
            <div
              className="collapse navbar-collapse navbar-top-collapse order-1 order-lg-0 justify-content-center"
              id="navbarToplCollapse"
            >
              <ul className="navbar-nav dropdown" data-top-nav-dropdowns="data-top-nav-dropdowns ">
                {hasDashboard && (
                  <li className="nav-item dropdown" style={{ marginTop: '4px' }}>
                    <Link className="nav-link lh-1" to="" id="dashboards">
                      Home
                    </Link>
                  </li>
                )}
                {(hasEnquiry || selectedGroup3) && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Students" id="students-dropdown">

                      {hasEnquiry && (
                        <>
                          <NavDropdown.Header>ENQUIRY</NavDropdown.Header>
                          <NavDropdown.Item as={Link} to="/addStudentEnquiry">Add New Enquiry</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/viewEnquiry">View Enquiry Details</NavDropdown.Item>
                        </>

                      )}

                      {selectedGroup3 && (
                        <>

                          {(hasAddNewStudent || hasViewActiveStudent || hasViewInactiveStudent || hasViewStudentByBatch || hasAdmissionCollection || hasUploadBulkStudent || hasPromoteStudents) && (
                            <>
                              <NavDropdown.Divider />
                              <NavDropdown.Header>ADMISSION</NavDropdown.Header>
                            </>
                          )}

                          {hasAddNewStudent && (
                            <NavDropdown.Item as={Link} to="/studentRegistration">Add New Student</NavDropdown.Item>
                          )}
                          {hasViewActiveStudent && (
                            <NavDropdown.Item as={Link} to="/viewActiveStudent">View Active Student</NavDropdown.Item>
                          )}
                          {hasViewInactiveStudent && (
                            <NavDropdown.Item as={Link} to="/viewInactiveStudent">View Inactive Student</NavDropdown.Item>
                          )}
                          {hasViewStudentByBatch && (
                            <NavDropdown.Item as={Link} to="/BatchWiseStudentView">View Student By Batch</NavDropdown.Item>
                          )}
                          {hasAdmissionCollection && (
                            <NavDropdown.Item as={Link} to="/AdmissionCancelationReport">Admission Collection/Refund</NavDropdown.Item>
                          )}
                          {hasUploadBulkStudent && (
                            <NavDropdown.Item as={Link} to="/uploadBulkStudent">Upload/Import Bulk Student</NavDropdown.Item>
                          )}
                          {hasPromoteStudents && (
                            <NavDropdown.Item as={Link} to="/promoteStudents">Promote Students</NavDropdown.Item>
                          )}
                        </>

                      )}

                    </NavDropdown>

                  </li>)}
                {selectedGroup4 && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Staff" id="staff-dropdown">
                      <NavDropdown.Header>STAFF DETAILS</NavDropdown.Header>
                      {hasAddViewStaff && (
                        <NavDropdown.Item as={Link} to="/viewStaff">Add/View Staff</NavDropdown.Item>
                      )}
                      {hasStaffAttendance && (
                        <NavDropdown.Item as={Link} to="/viewStaffAttendance">Staff Attendance</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </li>
                )}
                {selectedGroup5 && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Account" id="account-dropdown">
                      {(hasBills || hasVouchers || hasExpenseSummary) && (
                        <NavDropdown.Header>EXPENSES</NavDropdown.Header>
                      )}
                      {hasBills && (
                        <NavDropdown.Item as={Link} to="/viewBill">Bills</NavDropdown.Item>
                      )}
                      {hasVouchers && (
                        <NavDropdown.Item as={Link} to="/viewVoucher">Vouchers</NavDropdown.Item>
                      )}
                      {hasExpenseSummary && (
                        <NavDropdown.Item as={Link} to="/expenseSummary">Expense Summary</NavDropdown.Item>
                      )}
                      <NavDropdown.Divider />
                      {(hasPaymentUpdates || hasFeesSummary || hasFeesOverview) && (
                        <NavDropdown.Header>CHEQUES/FEES</NavDropdown.Header>
                      )}
                      {hasPaymentUpdates && (
                        <NavDropdown.Item as={Link} to="/updateChequePayments">Payment Updates</NavDropdown.Item>
                      )}
                      {hasFeesSummary && (
                        <NavDropdown.Item as={Link} to="/FeesSummary">Fees Summary</NavDropdown.Item>
                      )}
                      {hasFeesOverview && (
                        <NavDropdown.Item as={Link} to="/FeesOverview">Fees Overview</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </li>
                )}
                {(selectedGroup6 || selectedGroup7 || selectedGroup8 || selectedGroup9) && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Educare" id="educare-dropdown">
                      <div style={{ maxHeight: '500px', overflowY: 'auto', WebkitOverflowScrolling: 'touch', }}>
                        {selectedGroup6 && (
                          <>
                            <NavDropdown.Header>ANNOUNCEMENTS</NavDropdown.Header>
                            {hasDailyThoughts && (
                              <NavDropdown.Item as={Link} to="/viewTOD">Daily Thoughts</NavDropdown.Item>
                            )}
                            {hasHolidayCalendar && (
                              <NavDropdown.Item as={Link} to="/viewHoliday">Holiday Calendar</NavDropdown.Item>
                            )}
                            {hasNotice && (
                              <NavDropdown.Item as={Link} to="/viewNotice">Notice</NavDropdown.Item>
                            )}
                            {hasEmergencyCircular && (
                              <NavDropdown.Item as={Link} to="/viewEmergencyCircular">Emergency Circular</NavDropdown.Item>
                            )}
                            {hasSendComplaint && (
                              <NavDropdown.Item as={Link} to="/StudentRemarks">Send Complaint/Remarks</NavDropdown.Item>
                            )}
                            {hasViewComplaints && (
                              <NavDropdown.Item as={Link} to="/viewComplaints">View Complaint/Remarks</NavDropdown.Item>
                            )}
                            {hasGallery && (
                              <NavDropdown.Item as={Link} to="/ViewGallery">Gallery</NavDropdown.Item>
                            )}
                          </>

                        )}

                        {selectedGroup7 && (
                          <>
                            <NavDropdown.Divider />
                            <NavDropdown.Header>STUDY MATERIALS</NavDropdown.Header>
                            {hasHomeWork && (
                              <NavDropdown.Item as={Link} to="/viewHomework">Home Work</NavDropdown.Item>
                            )}
                            {hasClassWork && (
                              <NavDropdown.Item as={Link} to="/viewClasswork">Class Work</NavDropdown.Item>
                            )}
                            {hasNotes && (
                              <NavDropdown.Item as={Link} to="/viewNotes">Notes</NavDropdown.Item>
                            )}
                            {hasDPP && (
                              <NavDropdown.Item as={Link} to="/viewDPP">DPP</NavDropdown.Item>
                            )}
                            {hasVideoLinks && (
                              <NavDropdown.Item as={Link} to="/viewVideoLink">Video Links</NavDropdown.Item>
                            )}
                            {hasELearning && (
                              <NavDropdown.Item as={Link} to="/viewELearning">E-Learning</NavDropdown.Item>
                            )}
                          </>

                        )}

                        {selectedGroup8 && (
                          <>
                            <NavDropdown.Divider />
                            <NavDropdown.Header>APP PROMO</NavDropdown.Header>
                            {hasPublications && (
                              <NavDropdown.Item as={Link} to="/viewPublications">Publications</NavDropdown.Item>
                            )}
                            {hasBannerImages && (
                              <NavDropdown.Item as={Link} to="/viewBannerImages">Banner Images</NavDropdown.Item>
                            )}
                          </>

                        )}

                        {selectedGroup9 && (
                          <>

                            <NavDropdown.Divider />
                            <NavDropdown.Header>STUDENTS</NavDropdown.Header>
                            {hasSuggestionsMessages && (
                              <NavDropdown.Item as={Link} to="/viewComplaints">Suggestions/Messages</NavDropdown.Item>
                            )}
                            {hasFacultyNotice && (
                              <NavDropdown.Item as={Link} to="/viewNoticeForFaculty">Faculty Notice</NavDropdown.Item>
                            )}
                            {hasStudentDoubts && (
                              <NavDropdown.Item as={Link} to="/viewStudentDoubts">Student Doubts</NavDropdown.Item>
                            )}
                          </>

                        )}
                      </div>
                    </NavDropdown>

                  </li>)}
                {selectedGroup10 && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Lectures" id="lectures-dropdown">
                      <NavDropdown.Header>LECTURES</NavDropdown.Header>
                      {hasNewLecture && (
                        <NavDropdown.Item as={Link} to="/addLectures">New Lecture</NavDropdown.Item>
                      )}
                      {hasViewLectureSchedule && (
                        <NavDropdown.Item as={Link} to="/viewLectures">View Lecture Schedule</NavDropdown.Item>
                      )}
                      {hasLectureReportStandard && (
                        <NavDropdown.Item as={Link} to="/viewLectureReportStandard">Lecture Report Standard</NavDropdown.Item>
                      )}
                      {hasLectureReportFaculty && (
                        <NavDropdown.Item as={Link} to="/viewLectureReportFaculty">Lecture Report Faculty</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </li>
                )}
                {(selectedGroup11 || mockTestsGroup12 || dailyQuizGroup13) && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Tests" id="tests-dropdown">
                      {selectedGroup11 && (
                        <>
                          <NavDropdown.Header>TEST</NavDropdown.Header>
                          {hasTest && (
                            <NavDropdown.Item as={Link} to="/viewTest">Test</NavDropdown.Item>
                          )}
                          {hasExams && (
                            <NavDropdown.Item as={Link} to="/Edit_Examination">Exams</NavDropdown.Item>
                          )}
                          {hasResultSummary && (
                            <NavDropdown.Item as={Link} to="/resultSummary">Result Summary</NavDropdown.Item>
                          )}
                        </>

                      )}
                      {mockTestsGroup12 && (
                        <>
                          <NavDropdown.Divider />

                          <NavDropdown.Header>MOCK TESTS</NavDropdown.Header>
                          {hasAddViewTestSet && (
                            <NavDropdown.Item as={Link} to="/ViewNewTest">Add/View Test Set</NavDropdown.Item>
                          )}
                          {hasQuestionBank && (
                            <NavDropdown.Item as={Link} to="/viewQuestionPaper">Question Bank</NavDropdown.Item>
                          )}
                        </>

                      )}


                      {dailyQuizGroup13 && (
                        <>
                          <NavDropdown.Divider />

                          <NavDropdown.Header>DAILY QUIZ</NavDropdown.Header>
                          {hasDailyQuiz && (
                            <NavDropdown.Item as={Link} to="/ViewDailyQuiz">Daily Quiz</NavDropdown.Item>
                          )}
                        </>

                      )}

                    </NavDropdown>
                  </li>)}
                {selectedGroup14 && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Manage App" id="manage-dropdown">
                      <NavDropdown.Header>MANAGE APP</NavDropdown.Header>
                      {hasManageClasses && (
                        <NavDropdown.Item as={Link} to="/ManageClasses">Manage Classes</NavDropdown.Item>
                      )}
                      {hasManageSubjects && (
                        <NavDropdown.Item as={Link} to="/">Manage Subjects</NavDropdown.Item>
                      )}
                      {hasManageTopicsManageApp && (
                        <NavDropdown.Item as={Link} to="/">Manage Topics Manage App</NavDropdown.Item>
                      )}
                      {hasManageCBSEClasses && (
                        <NavDropdown.Item as={Link} to="/">Manage CBSE Classes</NavDropdown.Item>
                      )}
                      {hasManageCBSESubjects && (
                        <NavDropdown.Item as={Link} to="/">Manage CBSE Subjects</NavDropdown.Item>
                      )}


                    </NavDropdown>
                  </li>)}
                {inventoryGroup && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Inventory" id="inventory-dropdown">
                      <NavDropdown.Header>INVENTORY</NavDropdown.Header>
                      {hasManageVendors && (
                        <NavDropdown.Item as={Link} to="/viewVendors">Manage Vendors</NavDropdown.Item>
                      )}
                      {hasManageProductCategory && (
                        <NavDropdown.Item as={Link} to="/ViewProductCategory">Manage Product Category</NavDropdown.Item>
                      )}
                      {hasManageBills && (
                        <NavDropdown.Item as={Link} to="/viewInventoryBills">Manage Bills</NavDropdown.Item>
                      )}
                      {hasManageStock && (
                        <NavDropdown.Item as={Link} to="/ManageStock">Manage Stock</NavDropdown.Item>
                      )}
                      {hasVendorPayments && (
                        <NavDropdown.Item as={Link} to="/ViewVendorPayments">Vendor Payments</NavDropdown.Item>
                      )}
                      {hasVendorLedger && (
                        <NavDropdown.Item as={Link} to="/InventoryVendorLedger">Vendor Ledger</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </li>
                )}
                {whatsappGroup && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Whatsapp" id="whatsapp-dropdown">
                      <NavDropdown.Header>WHATSAPP MESSAGE</NavDropdown.Header>
                      {hasSendWhatsappMessage && (
                        <NavDropdown.Item as={Link} to="/sendWhatsapp">Send Whatsapp Message</NavDropdown.Item>
                      )}
                      {hasPendingFees && (
                        <NavDropdown.Item as={Link} to="/studentBalanceFeesMessage">Pending Fees</NavDropdown.Item>
                      )}
                      {hasUpcomingFees && (
                        <NavDropdown.Item as={Link} to="/studentUpcomingFeesMessage">Upcoming Fees</NavDropdown.Item>
                      )}
                      {hasAbsentStudents && (
                        <NavDropdown.Item as={Link} to="/studentAttandanceMessage">Absent Students</NavDropdown.Item>
                      )}
                      {hasBirthdays && (
                        <NavDropdown.Item as={Link} to="/studentBirthDayMessage">Birthdays</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </li>
                )}
                {reportsGroup && (
                  <li className="nav-item dropdown">
                   
                    <NavDropdown title="Reports" id="reports-dropdown">
                    <div style={{ maxHeight: '500px', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>
                      {(hasStudentBiometricAttendance||hasFacultyBiometricAttendance||hasFacultyCollectiveReport||hasBiometricAttendanceCount) && (
                        <NavDropdown.Header>BIOMETRIC</NavDropdown.Header>
                      )}
                      {hasStudentBiometricAttendance && (
                        <NavDropdown.Item as={Link} to="/StudentBiometricAtt">Student Biometric Attendance</NavDropdown.Item>
                      )}
                       {hasFacultyBiometricAttendance && (
                        <NavDropdown.Item as={Link} to="/viewStaffAttendance">Faculty Biometric Attendance</NavDropdown.Item>
                      )}
                           {hasFacultyCollectiveReport && (
                        <NavDropdown.Item as={Link} to="/facultyCollectiveReport">Monthly Biometric - Faculty</NavDropdown.Item>
                      )}
                            {hasBiometricAttendanceCount && (
                        <NavDropdown.Item as={Link} to="/biometricAttendanceCount">Biometric Attendance Count</NavDropdown.Item>
                      )}
                      

                      {hasFeesReport && (
                        <NavDropdown.Header>FEES REPORT</NavDropdown.Header>
                      )}
                      {hasFeesReport && (
                        <NavDropdown.Item as={Link} to="/FeesReport">Fees Report</NavDropdown.Item>
                      )}
                      {(hasLectureReport || hasTopicWiseLectureReport) && (
                        <NavDropdown.Header>LECTURE REPORT</NavDropdown.Header>
                      )}
                      {hasLectureReport && (
                        <NavDropdown.Item as={Link} to="/LectureReport">Lecture Report</NavDropdown.Item>
                      )}
                      {hasTopicWiseLectureReport && (
                        <NavDropdown.Item as={Link} to="/TopicWiseLecture">Topic Wise Lecture Report</NavDropdown.Item>
                      )}
                      {hasFeesInstallmentSummary && (
                        <NavDropdown.Header>FEES INSTALLMENT SUMMARY</NavDropdown.Header>
                      )}
                      {hasFeesInstallmentSummary && (
                        <NavDropdown.Item as={Link} to="/StudentFeesReport">Fees Installment Summary</NavDropdown.Item>
                      )}
                      {hasReportCard && (
                        <NavDropdown.Header>Report card</NavDropdown.Header>
                      )}
                      {hasReportCard && (
                        <NavDropdown.Item as={Link} to="/ReportCard">Report Card</NavDropdown.Item>
                      )}
                      {hasIDCard && (
                        <NavDropdown.Header>ID CARD/HALL TICKET</NavDropdown.Header>
                      )}
                      {hasIDCard && (
                        <NavDropdown.Item as={Link} to="/IDCard">ID Card</NavDropdown.Item>
                      )}
                      {hasIncomeExpenses && (
                        <NavDropdown.Header>INCOME & EXPENSES REPORT</NavDropdown.Header>
                      )}
                      {hasIncomeExpenses && (
                        <NavDropdown.Item as={Link} to="/IncomeExpenseReport">Income & Expenses</NavDropdown.Item>
                      )}
                      {hasStockReport && (
                        <NavDropdown.Header>STOCK REPORT</NavDropdown.Header>
                      )}
                      {hasStockReport && (
                        <NavDropdown.Item as={Link} to="/InventoryStockReport">Stock Report</NavDropdown.Item>
                      )}
                      {hasAdmissionCancellation && (
                        <NavDropdown.Header>ADMISSION CANCELLATION REPORT</NavDropdown.Header>
                      )}
                      {hasAdmissionCancellation && (
                        <NavDropdown.Item as={Link} to="/ViewCanceledAdmissions">Admission Cancellation/Refund</NavDropdown.Item>
                      )} 
                      </div>
                    </NavDropdown>
                   
                  </li>
                )}
                {(orgSettingsGroup || staffPayrollGroup || userSettingsGroup) && (
                  <li className="nav-item dropdown">
                    <NavDropdown title="Settings" id="settings-dropdown">
                      <div style={{ maxHeight: '500px', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>
                        {orgSettingsGroup && (
                          <>
                            <NavDropdown.Header>ORGANIZATION</NavDropdown.Header>
                            {hasSettings && <NavDropdown.Item as={Link} to="/whatsappSetting">Settings</NavDropdown.Item>}
                            {hasBranches && <NavDropdown.Item as={Link} to="/viewBranches">Branches</NavDropdown.Item>}
                            {hasFinancialYear && <NavDropdown.Item as={Link} to="/viewFinancialYear">Financial Year</NavDropdown.Item>}
                            {hasCourses && <NavDropdown.Item as={Link} to="/viewCourses">Courses</NavDropdown.Item>}
                            {hasStandard && <NavDropdown.Item as={Link} to="/viewStandard">Standard</NavDropdown.Item>}
                            {hasBatch && <NavDropdown.Item as={Link} to="/viewBatch">Batch</NavDropdown.Item>}
                            {hasSubject && <NavDropdown.Item as={Link} to="/viewSubject">Subject</NavDropdown.Item>}
                            {hasTopic && <NavDropdown.Item as={Link} to="/viewTopic">Topic</NavDropdown.Item>}
                            {hasExam && <NavDropdown.Item as={Link} to="/viewExamTitle">Exams</NavDropdown.Item>}
                            {hasSchoolCollege && <NavDropdown.Item as={Link} to="/viewSchoolCollege">School/College</NavDropdown.Item>}
                            {hasBoard && <NavDropdown.Item as={Link} to="/viewBoard">Board</NavDropdown.Item>}
                            {hasBank && <NavDropdown.Item as={Link} to="/viewBank">Bank</NavDropdown.Item>}
                            {hasClassroom && <NavDropdown.Item as={Link} to="/viewClassroom">Class Room</NavDropdown.Item>}
                            {hasSMSSettings && <NavDropdown.Item as={Link} to="/viewSMS">SMS Settings</NavDropdown.Item>}
                            {hasExpenseTypeCategory && <NavDropdown.Item as={Link} to="/viewVoucherCategory">Expense Type/Category</NavDropdown.Item>}
                            {hasHallTicketSettings && <NavDropdown.Item as={Link} to="/viewHallticketSettings">Hall Ticket Settings</NavDropdown.Item>}
                            {hasStudentIdPrefix && <NavDropdown.Item as={Link} to="/viewStudentIdPrefixSettings">Student Id Prefix</NavDropdown.Item>}
                            {hasReceiptPrefix && <NavDropdown.Item as={Link} to="/viewReceiptSettingsPrefix">Receipt Prefix</NavDropdown.Item>}
                          </>
                        )}
                        {staffPayrollGroup && (
                          <>
                            <NavDropdown.Divider />

                            <NavDropdown.Header>STAFF PAYROLL</NavDropdown.Header>
                            {hasStaffHoliday && <NavDropdown.Item as={Link} to="/viewFacultyHoliday">Staff/Faculty Holiday</NavDropdown.Item>}
                            {hasLateHalfDay && <NavDropdown.Item as={Link} to="/viewLateHalfDay">Late/HalfDay</NavDropdown.Item>}
                          </>
                        )}
                        {userSettingsGroup && (
                          <>
                            <NavDropdown.Divider />

                            <NavDropdown.Header>USER</NavDropdown.Header>
                            {hasAdminStaff && <NavDropdown.Item as={Link} to="/viewAdminSatff">Admin/Staff</NavDropdown.Item>}
                            {hasMasterAdmin && <NavDropdown.Item as={Link} to="/viewMasterAdmin">Master Admin</NavDropdown.Item>}
                          </>
                        )}
                      </div>
                    </NavDropdown>
                  </li>
                )}
              </ul>


            </div>
            <ul className="navbar-nav navbar-nav-icons flex-row">

              <li className="nav-item dropdown">
                <Dropdown align="end">
                  <Dropdown.Toggle
                    variant="link"
                    id="navbarDropdownUser"
                    className="avtarToggle"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="d-flex align-items-center">
                    <div className="avatar avatar-xl">
                    {userData?.Photo? (
                              <img
                              className="rounded-circle"
                              src={`${apiUrl}/Documents/Staff/${userData?.Photo}`}
                              alt="img"
                            />):(<img
                              className="rounded-circle"
                              src="/assets/img/team/avatar.png"
                              alt="img"
                            />)}
                    </div>
               
                      <div className="text-center ms-2 mt-2">
                        <h6 className="text-black">{userData?.UserName}</h6>
                        <h6 className="text-black">({userData?.Role})</h6>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end navbar-dropdown-caret py-0 mt-1 dropdown-profile shadow border border-300">
                    <div className="card position-relative border-0">
                      <div className="card-body p-0">
                        <div className="text-center pt-4 pb-3">
                          <div className="avatar avatar-xl">
                            {userData?.Photo? (
                              <img
                              className="rounded-circle"
                              src={`${apiUrl}/Documents/Staff/${userData?.Photo}`}
                              alt="img"
                            />):(<img
                              className="rounded-circle"
                              src="/assets/img/team/avatar.png"
                              alt="img"
                            />)}
                          </div>
                          <h6 className="mt-2 text-black">
                            {userData?.UserName}
                          </h6>
                          <h6 className="mt-2 text-black">
                            ({userData?.Role})
                          </h6>
                          <h6 className="mt-2 text-black">
                            {userData?.Head_Name}
                          </h6>
                        </div>
                      </div>

                      <div className="card-footer p-0 border-top">
                        <ul className="nav d-flex flex-column my-3">
                          <li className="nav-item">
                            <Link className="nav-link px-3" to="/switchBranch">
                              <i className="fas fa-code-branch"></i>
                              <span style={{ marginLeft: "5px" }}>
                                Switch Branch
                              </span>
                            </Link>
                          </li>
                        </ul>
                        <hr />
                        <div className="px-3 pt-3">
                          <Link
                            className="btn btn-phoenix-secondary d-flex flex-center w-100"
                            onClick={handleLogout}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16px"
                              height="16px"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-log-out me-2"
                            >
                              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                              <polyline points="16 17 21 12 16 7"></polyline>
                              <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            Sign out
                          </Link>
                        </div>
                        <div className="my-2 text-center fw-bold fs--2 text-600">
                          <Link className="text-600 me-1" to="/">
                            Change Password
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </nav>
        </div>
        {loader}
      </main>
      <Outlet />
    </div>
  );
};

export default Dashboard;
