import React, { useState, useEffect } from "react";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import { useUser } from "../../Components/UserContext";
import Swal from "sweetalert2";
import Select from 'react-select'

const ManualWhatsapp = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [BatchOptions, setBatchOptions] = useState([]);

    const [StdOptions, setStdOptions] = useState([]);

    const [studentData, setStudentData] = useState([]);
    const [parentData, setParentData] = useState([]);
    const [staffData, setStaffData] = useState([]);

    const [filter, setFilter] = useState({
        Type: "",
        Parent: "",
        Std_Id: "",
        Std_Name: "",
        Batch_Id: "",
        Batch_Name: ""
    });

    const [checkedStatus, setCheckedStatus] = useState({});
    const [checkAll, setCheckAll] = useState(true);
    const [rawInput, setRawInput] = useState("");
    const [selectedMobiles, setSelectedMobiles] = useState([]);

    const handleInputChange = (e) => {
        setRawInput(e.target.value);
    };

    const handleInputBlur = () => {
        const cleanedInput = rawInput.replace(/[^0-9]/g, ""); // Remove non-digit characters
        const mobileNumbers = [];

        // Split the cleaned input into chunks of 10 digits each
        for (let i = 0; i < cleanedInput.length; i += 10) {
            const chunk = cleanedInput.substring(i, i + 10); // Extract a chunk of 10 digits
            if (chunk.length === 10) {
                mobileNumbers.push({ Mobile: chunk }); // Map to the desired object format
            }
        }

        setSelectedMobiles(mobileNumbers);
        setRawInput(mobileNumbers.map((obj) => obj.Mobile).join(", "));
    };



    console.log(selectedMobiles)
    console.log(studentData)
    console.log(checkedStatus)
    console.log(parentData)
    console.log(staffData)





    const [message, setMessage] = useState({
        MobileData: [],
        Message: "",
        ImgAttachment: "",
        DocAttachment: ""

    });
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 500 * 1024; // 500 KB
    
        if (file && file.size > maxSizeInBytes) {
          Swal.fire({
            icon: "error",
            title: "File size exceeds the limit",
            text: "Please select a file smaller than 500KB.",
          });
          e.target.value = "";
        } else {
          setMessage({
            ...message,
            ImgAttachment: file,
          });
        }
      };
    
      const handleDocumentChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 500 * 1024; // 500 KB
    
        if (file && file.size > maxSizeInBytes) {
          Swal.fire({
            icon: "error",
            title: "File size exceeds the limit",
            text: "Please select a file smaller than 500KB.",
          });
          e.target.value = "";
        } else {
          setMessage({
            ...message,
            DocAttachment: file,
          });
        }
      };
    
      const submitHandler = async (event) => {
        event.preventDefault();
        const updatedMessage = {
          ...message,
          MobileData: selectedMobiles,
          Head_Id: userData?.Head_Id,
          Head_Name: userData?.Head_Name,
          User_Id: userData.UserId,
          Org_Id: userData.Org_Id,
          Org_Name: userData.Org_Name,
          User_Role: userData.Role,
          LoginUsername: userData.Username,
          Display_Name: userData.UserName,
        };
    
        const formdata = new FormData();
        formdata.append("MobileData", JSON.stringify(updatedMessage.MobileData));
        formdata.append("Message", message.Message);
        formdata.append("ImgAttachment", message.ImgAttachment);
        formdata.append("DocAttachment", message.DocAttachment);
        formdata.append("Head_Id", updatedMessage.Head_Id);
        formdata.append("Head_Name", updatedMessage.Head_Name);
        formdata.append("User_Id", updatedMessage.User_Id);
        formdata.append("Org_Id", updatedMessage.Org_Id);
        formdata.append("Org_Name", updatedMessage.Org_Name);
        formdata.append("User_Role", updatedMessage.User_Role);
        formdata.append("LoginUsername", updatedMessage.LoginUsername);
        formdata.append("Display_Name", updatedMessage.Display_Name);
    
        showLoader();
    
        await axios
          .post(`${apiUrl}/manualWhatsappMessage`, formdata)
          .then((res) => {
            Swal.fire("Success!", "Message Sent Successfully!!", "success");
            setMessage({ Mobile: [], Message: "", ImgAttachment: "", DocAttachment: "" });
            setSelectedMobiles([]);
            setRawInput("");
            setCheckedStatus({});
            if (filter?.Type === "Student" || filter?.Type === "Parent") {
                loadStdBatchData();
            }
            if (filter?.Type === "Student") {
                loadStudentData();
            }
            if (filter?.Type === "Parent") {
                loadParentData();
            }
            if (filter?.Type === "Teacher") {
                loadStaffData();
            }
            setCheckAll(true);
            hideLoader();
          })
          .catch((err) => {
            console.error(err);
            Swal.fire("Server Timeout", "Server is Busy!!! Please try again later.", "error");
            hideLoader();
          });
      };

    const loadStaffData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/staffWhatsAppData?OrgId=${userData.Org_Id}`
            );
            console.log(result.data);
            const newCheckedStatus = result.data.reduce((acc, student) => {
                acc[student.ID] = true;
                return acc;
            }, {});
            setStaffData(result.data);
            setCheckedStatus(newCheckedStatus);
            setSelectedMobiles(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    const loadStdBatchData = async () => {
        showLoader();
        try {
            if (userData && userData.Org_Id && userData.Head_Id) {
                const std = await axios.get(
                    `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
                );
                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
            }

            if (userData && userData.Org_Id && filter?.Std_Id) {

                const batch = await axios.get(
                    `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${filter.Std_Id}`
                );

                const getBatch = batch.data.map((category) => ({
                    label: category.Batch_Name,
                    value: category.Batch_Id,
                }));
                setBatchOptions(getBatch);
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Plan data: ", error);
        }
    };

    const loadStudentData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/studentsWhatsAppData?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}&StdId=${filter.Std_Id}&BatchId=${filter.Batch_Id}`
            );
            console.log(result.data);
            const newCheckedStatus = result.data.reduce((acc, student) => {
                acc[student.ID] = true;
                return acc;
            }, {});
            setStudentData(result.data);
            setCheckedStatus(newCheckedStatus);
            setSelectedMobiles(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Student Data : ", error);
        }
    };



    const loadParentData = async () => {
        showLoader();
        ///orgId, headId, stdId, batchId, parentType
        try {
            const result = await axios.get(
                `${apiUrl}/parentsWhatsAppData?orgId=${userData.Org_Id}&headId=${userData.Head_Id}&stdId=${filter.Std_Id}&batchId=${filter.Batch_Id}&&parentType=${filter.Parent}`
            );
            console.log(result.data);

            const students = result.data.map((student, index) => ({
                ...student,
                Id: index + 1

            }));
            const newCheckedStatus = students.reduce((acc, student) => {
                acc[student.Id] = true;
                return acc;
            }, {});
            setParentData(students);
            setCheckedStatus(newCheckedStatus);
            setSelectedMobiles(students);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Student Data : ", error);
        }
    };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            if (filter?.Type === "Student" || filter?.Type === "Parent") {
                loadStdBatchData();
            }
            if (filter?.Type === "Student") {
                loadStudentData();
            }
            if (filter?.Type === "Parent") {
                loadParentData();
            }
            if (filter?.Type === "Teacher") {
                loadStaffData();
            }
        }

    }, [userData, filter.Type, filter.Std_Id, filter.Batch_Id]);

    const handleCheckboxChange = (e, index, id) => {
        const newCheckedStatus = {
            ...checkedStatus,
            [id]: !checkedStatus[id]
        };

        const newSelectedMobiles = newCheckedStatus[id]
            ? [...selectedMobiles, studentData.find(student => student.ID === id)]
            : selectedMobiles.filter(student => student.ID !== id);

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(newSelectedMobiles);
        setCheckAll(Object.values(newCheckedStatus).every(status => status));
    };


    const handleCheckboxTeacherChange = (e, index, id) => {
        const newCheckedStatus = {
            ...checkedStatus,
            [id]: !checkedStatus[id]
        };

        const newSelectedMobiles = newCheckedStatus[id]
            ? [...selectedMobiles, staffData.find(student => student.ID === id)]
            : selectedMobiles.filter(student => student.ID !== id);

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(newSelectedMobiles);
        setCheckAll(Object.values(newCheckedStatus).every(status => status));
    };

    const handleCheckboxParentChange = (e, index, id) => {
        const newCheckedStatus = {
            ...checkedStatus,
            [id]: !checkedStatus[id]
        };

        const newSelectedMobiles = newCheckedStatus[id]
            ? [...selectedMobiles, parentData.find(student => student.Id === id)]
            : selectedMobiles.filter(student => student.Id !== id);

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(newSelectedMobiles);
        setCheckAll(Object.values(newCheckedStatus).every(status => status));
    };


    const handleCheckAll = (e) => {
        const newCheckedStatus = studentData.reduce((acc, student) => {
            acc[student.ID] = e.target.checked;
            return acc;
        }, {});

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(e.target.checked ? studentData : []);
        setCheckAll(e.target.checked);
    };

    const handleParentCheckAll = (e) => {
        const newCheckedStatus = parentData.reduce((acc, student) => {
            acc[student.Id] = e.target.checked;
            return acc;
        }, {});

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(e.target.checked ? parentData : []);
        setCheckAll(e.target.checked);
    };


    const handleTeacherCheckAll = (e) => {
        const newCheckedStatus = staffData.reduce((acc, student) => {
            acc[student.ID] = e.target.checked;
            return acc;
        }, {});

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(e.target.checked ? staffData : []);
        setCheckAll(e.target.checked);
    };

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                            <h4 className="page-title">Send Whatsapp Message</h4>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="row justify-content-center mt-2">
                                    <div className={filter.Type === 'Parent' ? "col-3" : "col-4"}>
                                        <label htmlFor="example-select" className="form-label">
                                            Receipient Type :
                                        </label>
                                        <select className="form-select" id="example-select"
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    Type: e.target.value,
                                                });
                                                setSelectedMobiles([]);
                                            }}>
                                            <option selected value={""}>--- Select ---</option>
                                            <option value="Student">Student</option>
                                            <option value="Parent">Parent</option>
                                            <option value="Teacher">Teacher</option>
                                            <option value="Custom">Custom</option>
                                        </select>
                                    </div>
                                    {(filter.Type !== "" && filter.Type !== "Teacher" && filter.Type !== "Custom") && (
                                        <>
                                            <div className={filter.Type === 'Parent' ? "col-3" : "col-4"}>
                                                <label htmlFor="example-select" className="form-label">
                                                    Std
                                                </label>
                                                <Select
                                                    id="Standard"
                                                    value={filter.Std_Id ? { label: filter.Std_Name, value: filter.Std_Id } : null}
                                                    options={StdOptions}
                                                    required
                                                    placeholder="--All--"
                                                    isClearable
                                                    onChange={(e) => {
                                                        setFilter({
                                                            ...filter,
                                                            Std_Id: e ? e.value : "",
                                                            Std_Name: e ? e.label : "",
                                                            Batch_Id: "",
                                                            Batch_Name: "",
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className={filter.Type === 'Parent' ? "col-3" : "col-4"}>
                                                <label htmlFor="example-select" className="form-label">
                                                    Batch
                                                </label>

                                                <Select
                                                    id="Batch"
                                                    value={filter.Batch_Id ? { label: filter.Batch_Name, value: filter.Batch_Id } : null}
                                                    options={BatchOptions}
                                                    required
                                                    placeholder="--All--"
                                                    isClearable
                                                    isDisabled={filter.Std_Id === ""}
                                                    onChange={(e) => {
                                                        setFilter({
                                                            ...filter,
                                                            Batch_Id: e ? e.value : "",
                                                            Batch_Name: e ? e.label : ""
                                                        });
                                                    }}
                                                />
                                            </div>

                                            <div className={filter.Type === 'Parent' ? "col-3" : "d-none"}>
                                                <label htmlFor="example-select" className="form-label">
                                                    Parent :
                                                </label>
                                                <select className="form-select" id="example-select"
                                                    onChange={(e) => {
                                                        setFilter({
                                                            ...filter,
                                                            Parent: e.target.value,
                                                        });

                                                    }}>
                                                    <option value={""}>--- Select ---</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                </select>
                                            </div>
                                        </>)


                                    }
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        {filter.Type === "Teacher" && (
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <div className=" table-responsive">
                                                        <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" style={{ paddingLeft: "17px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={handleTeacherCheckAll}
                                                                            checked={checkAll}
                                                                        />
                                                                    </th>


                                                                    <th className="text-left">Name</th>
                                                                    <th className="text-center">Whatsapp No.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {staffData.length !== 0 ? (
                                                                    staffData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                                    <input type="checkbox" id={`employeeCheck${index}`} checked={checkedStatus[item.ID] || false} onChange={(e) => handleCheckboxTeacherChange(e, index, item.ID)} />
                                                                                </td>


                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Name}
                                                                                </td>
                                                                                <td className="text-center" style={{ width: "" }}>
                                                                                    {item.Mobile}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td className="text-center text-danger" colSpan={7}>
                                                                            No Data Found !!
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(filter.Type === "Parent") &&
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <div className=" table-responsive">
                                                        <table id="dataTableHover" className="table table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" style={{ paddingLeft: "17px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={checkAll}
                                                                            onChange={handleParentCheckAll}
                                                                        />
                                                                    </th>

                                                                    <th className="text-left">Standard</th>
                                                                    <th className="text-left">Batch</th>
                                                                    <th className="text-left">Student Name</th>
                                                                    <th className="text-left">Name</th>
                                                                    <th className="text-left">Whatsapp No.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parentData.length > 0 ? (
                                                                    parentData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                                    <input type="checkbox" id={`studentCheck${index}`} checked={checkedStatus[item.Id] || false} onChange={(e) => handleCheckboxParentChange(e, index, item.Id)} />
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Std_Name}
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Batch_Name}{" "}{item.Last_Name}
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.StudentName}
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Name}
                                                                                </td>
                                                                                <td className="text-center" style={{ width: "" }}>
                                                                                    {item.Mobile}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td className="text-center text-danger" colSpan={7}>
                                                                            No Data Found !!
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {(filter.Type === "Student") &&
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <div className=" table-responsive">
                                                        <table id="dataTableHover" className="table table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" style={{ paddingLeft: "17px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={checkAll}
                                                                            onChange={handleCheckAll}
                                                                        />
                                                                    </th>

                                                                    <th className="text-left">Standard</th>
                                                                    <th className="text-left">Batch</th>
                                                                    <th className="text-left">Name</th>
                                                                    <th className="text-left">Whatsapp No.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {studentData.length > 0 ? (
                                                                    studentData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                                    <input type="checkbox" id={`studentCheck${index}`} checked={checkedStatus[item.ID] || false} onChange={(e) => handleCheckboxChange(e, index, item.ID)} />
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Std_Name}
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Batch_Name}{" "}{item.Last_Name}
                                                                                </td>
                                                                                <td className="text-left" style={{ width: "" }}>
                                                                                    {item.Name}
                                                                                </td>
                                                                                <td className="text-center" style={{ width: "" }}>
                                                                                    {item.Mobile}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td className="text-center text-danger" colSpan={7}>
                                                                            No Data Found !!
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {filter.Type === "Custom" && (
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <textarea className="form-control" rows={7}
                                                        placeholder="(eg. 9999999999, 8888888888,...)"
                                                        value={rawInput}
                                                        required={true}
                                                        onChange={handleInputChange}
                                                        onBlur={handleInputBlur}

                                                    />

                                                </div>
                                            </div>
                                        )

                                        }
                                    </div>
                                    {(filter.Type !== "") && (
                                        <div className="col-6 mt-3">
                                            <h6 style={{ padding: "10px", backgroundColor: "#37546c12" }}>All Receipients Count : {selectedMobiles?selectedMobiles.length:0}</h6>
                                            <form onSubmit={submitHandler}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="example-select" className="form-label" >
                                                                Message :
                                                            </label>
                                                            <textarea className="form-control" rows={6}
                                                                placeholder="Message..."
                                                                value={message.Message}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    setMessage({
                                                                        ...message,
                                                                        Message: e.target.value,
                                                                    });
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="ImgAttachment">
                                                                Image Attachment :
                                                            </label>
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="ImgAttachment"
                                                                    name="ImgAttachment"
                                                                    accept=".png,.jpg,.jpeg,.gif,.ico,.bmp"
                                                                    onChange={handleImageChange}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="DocAttachment">
                                                                Document Attachment  :
                                                            </label>
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="DocAttachment"
                                                                    name="DocAttachment"
                                                                    accept=".docx,.xlsx,.pptx,.doc,.xls,.ppt,.pdf,.txt"
                                                                    onChange={handleDocumentChange}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12 text-center">
                                                        <button
                                                            className="btn btn-primary mb-1"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader}
            </div>
        </div>

    );
}

export default ManualWhatsapp;
