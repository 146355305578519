import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import SetInstallment from "./SetInstallment";
import FeesPayment from "./FeesPayment";
import ViewReceipts from "./ViewReceipts";
import UpdateInstallment from "./UpdateInstallment";


const MasterPage = ({ loadData, hideLoader, showLoader, studentData, feesData }) => {

    const [key, setKey] = React.useState(1);

    const handleTabSelect = (selectedKey) => {
        setKey(parseInt(selectedKey));
    };


    return (
        <div>
            <Tab.Container id="controlled-tab-example"
                defaultActiveKey={1}
                onSelect={handleTabSelect}
                activeKey={key}                                    >
                <Nav variant="tabs">
                    <Nav.Item className="nav-item-divider">
                        <Nav.Link eventKey="1">
                            {studentData?.Installment_Set === 'Yes' ? 'Set Installment' : 'Fees Payment'}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item-divider">
                        <Nav.Link eventKey="2">&nbsp;&nbsp;Modify Installment</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="3">&nbsp;&nbsp;View Receipt</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="mt-3 mb-2">
                    <Tab.Pane eventKey={1}>
                        {key === 1 && studentData.Insatallment_Set === 'Yes' ? (
                            <FeesPayment feesData={feesData} showLoader={showLoader} hideLoader={hideLoader} loadData={loadData} />
                        ) : (
                            <SetInstallment studentData={studentData} feesData={feesData} showLoader={showLoader} hideLoader={hideLoader} loadData={loadData} />
                        )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                    <UpdateInstallment   studentData={studentData}  showLoader={showLoader} hideLoader={hideLoader} loadData={loadData}/>

                    </Tab.Pane>
                    <Tab.Pane eventKey={3}>
                        <ViewReceipts showLoader={showLoader} hideLoader={hideLoader} loadData={loadData}/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
};

export default MasterPage;
