import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";

const UpdateExamModal = ({ open, onClose, updateData }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [SubjectOption, setSubjectOption] = useState([]);
  const handleClose = () => {
    setShow(false);
    onClose();
  };
console.log(updateData)

  const [Test, setTest] = useState({
    Std_Id: updateData?.Std_Id,
    Subject_Id: parseInt(updateData?.Subject_Id),
    Subjects: updateData?.Subjects,
    Duration: updateData?.Duration,
    Min_Marks: parseFloat(updateData?.Min_Marks).toFixed(2),
    Total_Marks: parseFloat(updateData?.Total_Marks).toFixed(2),
    Date: updateData?.Date,
    Day: updateData?.Day,
    Timing: updateData?.Timing,
    Sr_No: updateData?.Sr_No,
    Syllabus:updateData?.Syllabus,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();

        if (userData && userData.Org_Id && Test?.Std_Id) {
          const subRes = await axios.get(
            `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Test.Std_Id}`
          );
          const subOptions = subRes.data.map((category) => ({
            label: category.Sub_Name,
            value: category.Sub_Id,
          }));
          setSubjectOption(subOptions);
          console.log(subOptions);

        }

        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData, Test.Std_Id]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateTest = {
      ...Test,
      Time_From: new Date(Test.Time_From).toLocaleTimeString("en-GB"),
      Time_To: new Date(Test.Time_To).toLocaleTimeString("en-GB"),
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateExam/${updateData?.Sr_No}`, updateTest)
        .then((res) => {
          Swal.fire("Success!", "Test Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                handleClose();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );
          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Date">
                  Date<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="Date"
                  name="Date"
                  required
                  value={Test.Date || ''}
                  onChange={(e) => {
                    setTest({
                      ...Test,
                      Date: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Day">
                  Day<span style={{ color: "Red" }}>*</span>
                </label>
                <select
                  id="Day"
                  name="Day"
                  className="form-select form-select-sm"
                  required
                  value={Test.Day || ''}
                  onChange={(e) => setTest({
                      ...Test,
                      Day: e.target.value,
                    })
                  }
                >
                  <option value="">Select a day</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Timing">
                  Time<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="Timing"
                  name="Timing"
                  required
                  value={Test.Timing || ''}
                  onChange={(e) => {
                    setTest({
                      ...Test,
                      Timing: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Duration">
                  Duration<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="Duration"
                  name="Duration"
                  required
                  value={Test.Duration || ''}
                  onChange={(e) => {
                    setTest({
                      ...Test,
                      Duration: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Subject">
                  Subject<span style={{ color: "Red" }}>*</span>
                </label>
                <Select
                  name="Subject"
                  id="Subject"
                  required
                  options={SubjectOption}
                  value={
                    Test.Subject_Id
                      ? {
                          value: Test.Subject_Id,
                          label: Test.Subjects,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    setTest({
                      ...Test,
                      Subject_Id: selectedOption.value,
                      Subjects: selectedOption.label,
                    });
                  }}
                />
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Min_Marks">
                  Min Marks<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="Min_Marks"
                  name="Min_Marks"
                  required
                  value={Test.Min_Marks || ''}
                  onChange={(e) => {
                    setTest({
                      ...Test,
                      Min_Marks: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Total_Marks">
                  Total Marks<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="Total_Marks"
                  name="Total_Marks"
                  required
                  value={Test.Total_Marks || ''}
                  onChange={(e) => {
                    setTest({
                      ...Test,
                      Total_Marks: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="Syllabus">
                  Syllabus<span style={{ color: "Red" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="Syllabus"
                  name="Syllabus"
                  required
                  value={Test.Syllabus || ""}
                  onChange={(e) => {
                    setTest({
                      ...Test,
                      Syllabus: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="col-md-12 text-center mt-3">
                <button type="submit" className="btn btn-sm btn-primary">
                  Update
                </button>
              </div>
            </div>
          </form>

          {loader}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateExamModal;
