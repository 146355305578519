import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BankDropdoun from "../DropDowns/BankDropdoun";
import VoucherCategory from "../DropDowns/ExCategory";
import YearDropDown from "../DropDowns/YearDropDown";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";

const AddUpdateVoucher = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const { opration, Sr_No } = useParams();

    const [Voucher, setVoucher] = useState({
        Voucher_No: "",
        Voucher_Ref_No: "",
        Head_Id: "",
        Head_Name: "",
        Subhead_Id: "",
        Subhead_Name: "",
        Financial_Year: "",
        Receiver_Name: "",
        Particulars: "",
        Voucher_Date: "",
        Amount: "",
        Mode: "Cash",
        Bank_Name: "",
        Chq_No: "",
        Chq_Date: "",
        Issued_By_Id: "",
        Issued_By_Name: "",
        Issued_On: "",
        Issued_By_Role: "",
        Updated_By_Id: "",
        Updated_On: "",
        Updated_By_Name: "",
        Updated_By_Role: "",
        Status: "Paid",
        Category_ID: "",
        Category: "",
        Transaction_Id: "",
        Org_Id: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();

                    if (
                        userData &&
                        userData.Org_Id &&
                        userData.Head_Id && userData?.Head_Name
                    ) {
                        const res = await axios.get(
                            `${apiUrl}/getNewVoucherNo?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}`
                        );
                        setVoucher((prevVoucher) => ({
                            ...prevVoucher,
                            Voucher_No: res.data,
                            Voucher_Ref_No: `${userData?.Head_Name.charAt(0)}-${res.data}`,
                        }));
                    }

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, opration, Sr_No]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVoucher((prevVoucher) => ({
            ...prevVoucher,
            [name]: value,
        }));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateVoucher = {
            ...Voucher,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addVoucher`, updateVoucher)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Voucher Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewVoucher");
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };



    const customStyles = {
        control: (base) => ({
            ...base,
            height: 33,
            minHeight: 33,
        }),
    };

    return (
        <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
        >
            <div className="card-body p-0">
                <div className="p-4 code-to-copy">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Voucher_No">
                                    Serial No
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Voucher_No"
                                    id="Voucher_No"
                                    disabled
                                    value={Voucher.Voucher_No}
                                    placeholder="Enter Voucher No"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Voucher_Ref_No">
                                    Voucher/Ref No
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Voucher_Ref_No"
                                    id="Voucher_Ref_No"
                                    
                                    value={Voucher.Voucher_Ref_No}
                                    placeholder="Enter Voucher/Ref No"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Voucher_Date">
                                    Voucher Date<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="Voucher_Date"
                                    id="Voucher_Date"
                                    required
                                    
                                    value={
                                        Voucher.Voucher_Date
                                            ? dayjs(Voucher.Voucher_Date).format("YYYY-MM-DD")
                                            : ""
                                    }
                                    placeholder="Enter Voucher Date"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Financial_Year">
                                    Financial Year<span style={{ color: "Red" }}>*</span>
                                </label>
                                <YearDropDown
                                    label="Financial_Year"
                                    name="Financial_Year"
                                    id="Financial_Year"
                                    value={Voucher.Financial_Year}
                                    isClearable
                                    required={true}
                                    onChange={(selectedOption) => {
                                    
                                        setVoucher({
                                            ...Voucher,
                                            //Assigned_To: selectedOption.value,
                                            Financial_Year: selectedOption? selectedOption.label:"",
                                        });
                                    }}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>


                            <div className="mb-3 col-md-4">
                                <label className="form-label" htmlFor="Issued_By_Name ">
                                    Issued By
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Issued_By_Name"
                                    value={userData?.UserName}
                                    id="Issued_By_Name"
                                    disabled
                                    placeholder="Enter Issued By Name"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label" htmlFor="Receiver_Name">
                                    Receiver Name<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Receiver_Name"
                                    value={Voucher.Receiver_Name}
                                    id="Receiver_Name"
                                    
                                    placeholder="Enter Receiver Name"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label" htmlFor="Amount">
                                    Amount<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className={`form-control form-control-sm`}
                                    type="text"
                                    name="Amount"
                                    id="Amount"
                                    required
                                    value={Voucher.Amount}
                                    
                                    placeholder="Enter Amount"
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const decimalInput = input.replace(/[^0-9.]/g, "");
                                        const amount = decimalInput.replace(
                                            /^(\d*\.\d*)\..*$/,
                                            "$1"
                                        );
                                        setVoucher({
                                            ...Voucher,
                                            Amount: amount,
                                        });

                                    }}
                                />
                            </div>

                            <div className="mb-3 col-md-3">
                                <VoucherCategory
                                    label="Category"
                                    name="Category"
                                    id="Category"
                                    value={
                                        Voucher.Category_ID
                                            ? {
                                                value: Voucher.Category_ID,
                                                label: Voucher.Category,
                                            }
                                            : null
                                    }
                                    required={true}
                                    is
                                    onChange={(e) => {
                                        setVoucher({
                                            ...Voucher,
                                            Category_ID: e.value,
                                            Category: e.label,
                                        });
                                    }}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Particulars">
                                    Particulars<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={Voucher.Particulars}
                                    
                                    name="Particulars"
                                    id="Particulars"
                                    placeholder="Enter Particulars..."
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Status">
                                    Payment Status<span style={{ color: "Red" }}>*</span>
                                </label>
                                <select
                                    className="form-select form-select-sm"
                                    required
                                    name="Status"
                                    id="Status"
                                    value={Voucher.Status}
                                    onChange={handleChange}
                                >
                                    <option selected value="Paid">
                                        Paid
                                    </option>
                                    <option value="Unpaid">Unpaid</option>
                                </select>
                                <span style={{ color: "Red" }}></span>
                            </div>

                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Mode">
                                    Payment Mode<span style={{ color: "Red" }}>*</span>
                                </label>
                                <select
                                    className="form-select form-select-sm"
                                    required
                                    name="Mode"
                                    id="Mode"
                                    value={Voucher.Mode}
                                    onChange={handleChange}
                                >
                                    <option selected value="Cash">Cash</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Online">Online</option>
                                </select>
                                <span style={{ color: "Red" }}></span>
                            </div>

                        </div>
                        {Voucher?.Mode === 'Cheque' && (
                            <div className="row">
                                <div className="mb-3 col-md-3">
                                    <label className="form-label" htmlFor="Bank_Name">
                                        Bank Name<span style={{ color: "Red" }}>*</span>
                                    </label>
                                    <BankDropdoun
                                        name="Bank_Name"
                                        id="Bank_Name"
                                        value={
                                            Voucher.Bank_Name
                                                ? {
                                                    value: Voucher.Bank_Name,
                                                    label: Voucher.Bank_Name,
                                                }
                                                : null
                                        }
                                        required={Voucher.Mode === 'Cheque'}
                                        is
                                        onChange={(e) => {
                                            setVoucher({
                                                ...Voucher,
                                                Bank_Name: e.value,
                                                //Category: e.label,
                                            });
                                        }}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label" htmlFor="Chq_No">
                                        Cheque No<span style={{ color: "Red" }}>*</span>
                                    </label>
                                    <input
                                        className={`form-control form-control-sm`}
                                        type="text"
                                        name="Chq_No"
                                        id="Chq_No"
                                        required={Voucher.Mode === 'Cheque'}
                                        value={Voucher.Chq_No}
                                        
                                        placeholder="Enter Cheque No"
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9]/g, "");
                                            setVoucher({
                                                ...Voucher,
                                                Chq_No: decimalInput,
                                            });

                                        }}
                                    />
                                </div>

                                <div className="mb-3 col-md-3">
                                    <label className="form-label" htmlFor="Chq_Date">
                                        Cheque Date<span style={{ color: "Red" }}>*</span>
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="date"
                                        name="Chq_Date"
                                        id="Chq_Date"
                                        required={Voucher.Mode === 'Cheque'}
                                        
                                        value={
                                            Voucher.Chq_Date
                                                ? dayjs(Voucher.Chq_Date).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        placeholder="Enter Cheque Date"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                            </div>)}

                        {Voucher?.Mode === 'Online' && (
                            <div className="row">
                                <div className="mb-3 col-md-12">
                                    <label className="form-label" htmlFor="Transaction_Id">
                                        Transaction ID<span style={{ color: "Red" }}>*</span>
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        value={Voucher.Transaction_Id}
                                        
                                        name="Transaction_Id"
                                        id="Transaction_Id"
                                        placeholder="Enter Transaction ID"
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                            </div>)}

                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                </div>


                {loader}
            </div>
        </div>
    );
};

export default AddUpdateVoucher;


