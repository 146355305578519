import React, { useState, useEffect } from "react";
import { apiUrl } from "../../../Components/apiConfig";
import { formatDate } from "../../../Components/dateFormate";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import axios from "axios";
import dayjs from "dayjs";
import { BiSolidEditAlt } from "react-icons/bi";
import { IoMdMore } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import Swal from "sweetalert2";
import PaymentModal from "./PaymentModal";
import Dropdown from 'react-bootstrap/Dropdown';

const FeesPayment = ({ feesData, showLoader, hideLoader, loadData }) => {
  const { Sr_No } = useParams();
  const { userData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [FeesData, setFeesData] = useState([]);

  console.log(feesData)
  const showModal = (index) => {
    console.log(index);
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
    loadData();
  };

  const showEditModal = (index) => {
    console.log(index);
    setIndex(index);
    setIsEditModal(true);
  };

  const Editclosed = () => {
    setIsModal(false);
    fetchData();
    loadData();
  };

  useEffect(() => {
    fetchData();
  }, [userData, Sr_No]);

  const fetchData = async () => {
    if (userData && Sr_No) {
      try {
        showLoader();
        const result = await axios.get(
          `${apiUrl}/getFeesInstallment?ID=${Sr_No}&OrgId=${userData?.Org_Id}`
        );

        console.log(result.data);
        setFeesData(result.data);
        hideLoader();
      } catch (error) {
        console.error("Error fetching headmaster data:", error);
        hideLoader();
      }
    }
  };

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };
  async function handleDelete(Id) {
    showLoader();
  //  await axios.delete(`${apiUrl}/deleteEnquiry/${Id}`);
    Swal.fire("Deleted!", "Enquiry record has been Deleted.", "success");
    fetchData();
    loadData();
    hideLoader();
  }

  return (
    <div
      className="card shadow-none border border-300 my-4"
      data-component-card="data-component-card"
    >
      <div className="card-body">
        <h5>Fees Payment</h5>
        <hr />
        <div className="row p-2 g-3">

          <>
            <table
              cellspacing="0"
              cellpadding="4"
              align="Center"
              rules="cols"
              className="table table-bordered border-primary table-hover table-responsive"
              style={{ border: "1px solid var(--phoenix-gray-200)" }}
            >
              <thead>
                <tr align="center">
                  <th align="center" scope="col" style={{ width: "30px" }}>
                    #
                  </th>
                  <th align="center" scope="col">
                    Installment Date
                  </th>
                  <th align="center" scope="col">
                    Amount
                  </th>
                  <th align="center" scope="col">
                    Status
                  </th>
                  <th align="center" scope="col">
                    Mode
                  </th>
                  <th align="center" scope="col">
                    Details
                  </th>
                  <th align="center" scope="col">
                    Chq Status
                  </th>
                  <th align="center" scope="col">
                    Payment
                  </th>
                  <th align="center" scope="col">
                    Rec No
                  </th>
                  <th scope="col" style={{ width: "30px" }}></th>
                </tr>
              </thead>
              <tbody>
                {FeesData?.map((fees, index) => (
                  <tr key={index} style={{ backgroundColor: "White" }}>
                    <td align="center" style={{ width: "30px" }}>
                      <span>{index + 1}</span>
                    </td>
                    <td align="center">{formatDate(fees.Due_Date)}</td>
                    <td align="center">{fees.Amount}</td>
                    <td align="center">{fees.Payment_Status}</td>
                    <td align="center">{fees.Mode}</td>
                    <td align="center">{fees.Details}</td>
                    <td align="center">{fees.Chq_Status}</td>
                    <td align="center">
                      <button
                        className="btn btn-sm btn-success"
                        style={{ cursor: fees.Payment_Status === "Paid" ? "not-allowed" : "pointer" }}
                        disabled={fees.Payment_Status !== "Unpaid"}
                        onClick={(e) => {
                          e.preventDefault();
                          showModal(index);
                        }}
                      >
                        Pay
                      </button>
                    </td>
                    <td align="center">{fees.Acknowledgement_No}</td>
                    <td className="text-center">
                      {fees?.Payment_Status !== "Unpaid" && (

                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic" className="icon-toggle" >
                            <i style={{ fontSize: "20px", color: "gray" }}><IoMdMore /></i>
                          </Dropdown.Toggle>


                          <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); showEditModal(index); }}>
                              <i style={{ color: "blue", fontSize: "14px" }}><BiSolidEditAlt /></i>
                              <span> Edit</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { deleteUser('fees.Sr_No') }}>
                              <i style={{ color: "red", fontSize: "14px" }}><FaTrashCan /></i>
                              <span> Delete</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )} </td>
                  </tr>
                ))}
              </tbody>
              {/* <tfoot>
                                        <tr align="center" style={{ fontWeight: 'bold' }}>
                                            <td>&nbsp;</td>
                                            <td align='right'>
                                                <span>Total Amount : </span>
                                            </td>
                                            <td>
                                          
                                               <span>{installments?.reduce((total, installment) => total + parseFloat(installment.Amount), 0).toFixed(2)}</span> 
                                            </td>
                                        </tr>
                                    </tfoot> */}
            </table>
          </>

        </div>
        {console.log(FeesData[indexValue])}
        {isModal && (
          <PaymentModal
            open={isModal}
            onClose={closed}
            FeesData={FeesData[indexValue]}
            T_Paid={parseFloat(feesData?.T_Paid).toFixed(2)}
            Type='Submit'
          />
        )}
        {isEditModal && (
          <PaymentModal
            open={isEditModal}
            onClose={Editclosed}
            FeesData={FeesData[indexValue]}
            Type='Update'
          />
        )}
      </div>
    </div>
  );
};

export default FeesPayment;
