import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { useUser } from "../../Components/UserContext";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Select from "react-select";
import Swal from "sweetalert2";
import { formatDate } from "../../Components/dateFormate";
import { values } from "lodash";

const Status = [
  { label: "Present", value: "Present" },
  { label: "Absent", value: "Absent" },
  { label: "Late", value: "Late" },
  { label: "Half Day", value: "Half Day" },
  { label: "Week off", value: "Week off" },
  { label: "Holiday", value: "Holiday" },
  { label: "Leave", value: "Leave" },
];

const Modal = ({ id, date, onClose, showModal }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  //console.log(id, date, onClose, showModal )


  const [dataDoc, setDataDoc] = useState({
    AdjType: '',
    Faculty_Id: '',
    Date: '',
    Minute: 0,
    Adjustment: '',
    Remark: '',
  });



  useEffect(() => {
  
if(id && date){
  fetchData();
}
  }, [id, date]);

  const fetchData = async () => {
    showLoader();
    try {
      const res = await axios.get(`${apiUrl}/getAdjustmentss`, {
        params: {
          Faculty_Id: id,
          Date: date,
        },
      });
        setDataDoc(res.data);
        if (res?.data?.Adjustment) {
        setIsDataAvailable(true);
      } else {
        setIsDataAvailable(false);
      }

      console.log(res.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      hideLoader();
    }
  };


  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const updatedDataDoc = {
      ...dataDoc,
      Faculty_Id: id,
      Date: date,
      AdjType: "S",
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
    };
  
    const errors = {}; // Add your validation logic here if needed
  
    if (Object.keys(errors).length === 0) {
      try {
        const endpoint = isDataAvailable
          ? `${apiUrl}/updateAdjustment`
          : `${apiUrl}/addAdjustment`;
  
        const requestMethod = isDataAvailable ? axios.put : axios.post;
  
        showLoader(); // Show loader while the request is in progress
  
      await requestMethod(endpoint, updatedDataDoc);
        hideLoader(); 
        onClose();
        Swal.fire(
          "Success!",
          isDataAvailable ? "Punch Status has been Updated." : "Punch Status has been Added.",
          "success"
        );

      } catch (error) {
        hideLoader(); // Hide loader if there's an error
        console.error(error);
        Swal.fire(
          "Error",
          "There was an issue with your request. Please try again later.",
          "error"
        );
      }
    } else {
      setErrors(errors);
      // Optionally display the errors to the user
    }
  };
  

  let show = showModal;

  function close() {
    onClose();
  }
  const modalClasses = `${show ? "show" : "hide"}`;
  const modalDisplay = show ? "block" : "none";
  // console.log(showModal, id, date);

  const deleteData = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete();
      }
    });
  };

  async function handleDelete() {
    const obj = {
      Date:date,
      Faculty_Id:id,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      User_Name: userData.UserName
  };
    await axios.delete(`${apiUrl}/deleteAdjustment`, {
      data: obj
  });
    Swal.fire("Deleted!", "Adjustment has been deleted.", "success");
    onClose();
  }

  return (
    <>
      <div
        class={`modal fade ${modalClasses}`}
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
        style={{ display: modalDisplay, backgroundColor: "#040404a1" }}
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ boxShadow: "none" }}
        >
          <div
            class="modal-content"
            style={{ boxShadow: "0 2px 5px 0 rgba(27, 23, 30, 0.3)" }}
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel">
                Adjust Punch Status - {formatDate(date)}
              </h5>
              <button type="button" class="btn-close" onClick={close}></button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="Status" className="form-label">
                    Status*
                  </label>
                  <Select
                    name="Status"
                    required
                    id="Status"
                    value={dataDoc.Adjustment?{label:dataDoc.Adjustment, value:dataDoc.Adjustment}:null}
                    options={Status}
        
                    onChange={(e) =>
                      setDataDoc({
                        ...dataDoc,
                        Adjustment: e.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="FeedBack" className="form-label">
                    Remark*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="FeedBack"
                    required
                    value={dataDoc.Remark}
                    placeholder="Adjustments Remark"
                    id="FeedBack"
                    onChange={(e) =>
                      setDataDoc({
                        ...dataDoc,
                        Remark: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    {!isDataAvailable ? (
                      <Button size="small" type="submit" variant="contained">
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Button  size="small" type="submit" variant="contained">
                          Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button  size="small" onClick={deleteData} variant="contained">
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {loader}
        </div>
      </div>
    </>
  );
};
export default Modal;
