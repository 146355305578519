import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const AddFacultyHoliday = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [file, setFile] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setShow(open);
    }, [open]);

const handleFileUploadChange = (event) => {
    const reader = new FileReader();
    reader.readAsBinaryString(event.target.files[0]);
    reader.onload = () => {
        const data = reader.result;
        const arrayBuffer = stringToArrayBuffer(data);
        const workBook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];
        let parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false, cellDates: true });
        
        // Preprocess date strings to ensure consistency
        parsedData = parsedData.map(item => ({
            ...item,
            Date: preprocessDate(item.Date)
        }));
        
        setFile(parsedData);
    };
};

function stringToArrayBuffer(binaryString) {
    const length = binaryString.length;
    const arrayBuffer = new ArrayBuffer(length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < length; i++) {
        uintArray[i] = binaryString.charCodeAt(i) & 0xff;
    }

    return arrayBuffer;
}

function preprocessDate(dateString) {
    // Example preprocessing: Assuming all dates are in 'MM/DD/YYYY' format
    const parts = dateString.split('/');
    const year = parts[2].length === 2 ? '20' + parts[2] : parts[2];
    const month = parts[0].padStart(2, '0');
    const day = parts[1].padStart(2, '0');
    return `${month}/${day}/${year}`;
}

    
    const DownloadExcel = () => {
        const columns = ["Date", "Holiday"];
        const rows = [columns];

        rows.push(["08/11/2024", "Diwali"]);

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Holiday");

        XLSX.writeFile(workBook, "DemoHoliday.xlsx");
    };

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const [Bill, setBill] = useState({
        Type: "Single",
        Date: "",
        Holiday: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        // Add validation logic if needed
        //  if (!updateBill.Date) errors.Date = "Date is required";
        //  if (!updateBill.Holiday) errors.Holiday = "Holiday is required";


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addFacultyHoliday`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Holiday Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                Date: "",
                                Holiday: "",
                            });
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!! Please try again later.",
                        "error"
                    );
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    console.log(file);

    const handleMultiSubmit = (event) => {
        event.preventDefault();

        const updateBills = file.map((item) => ({
            ...item,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        }));

        const errors = {};

        // Add validation logic if needed
        // if (!file.length) errors.File = "File is required";

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addMultiFacultyHoliday`, updateBills)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Holiday Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                Date: "",
                                Holiday: "",
                            });
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!! Please try again later.",
                        "error"
                    );
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Staff/Faculty Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={Bill?.Type === 'Multiple' ? handleMultiSubmit : handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <Tooltip className={Bill.Type === 'Single' ? "d-none" : ""} title="Demo Excel Formate">
                                    <IconButton
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={DownloadExcel}
                                    >
                                        <h6>Demo Excel Format</h6>: <i className="fas fa-file-excel"></i>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                <div className="col-md-5">
                                    <label className="form-label" htmlFor="Type">Type</label>
                                    <select
                                        className="form-select form-select-sm"
                                        id="Type"
                                        name="Type"
                                        value={Bill.Type}
                                        onChange={handleChange}
                                    >
                                        <option value="Single">Single</option>
                                        <option value="Multiple">Multiple</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={Bill.Type === 'Multiple' ? "d-none" : "mb-3 col-md-6"}>
                                <label className="form-label" htmlFor="Date">
                                    Date<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="Date"
                                    id="Date"
                                    required={Bill?.Type === 'Single'}
                                    value={formatInputDate(Bill?.Date)}
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}>{errors.Date}</span>
                            </div>
                            <div className={Bill.Type === 'Multiple' ? "d-none" : "mb-3 col-md-6"}>
                                <label className="form-label" htmlFor="Holiday">
                                    Holiday<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Holiday"
                                    id="Holiday"
                                    required={Bill?.Type === 'Single'}
                                    value={Bill.Holiday}
                                    placeholder="Enter Holiday Description..."
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}>{errors.Holiday}</span>
                            </div>
                            <div className={Bill.Type === 'Single' ? "d-none" : "mb-3 col-md-12"}>
                                <label className="form-label" htmlFor="File">
                                    File<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="file"
                                    name="File"
                                    id="File"
                                    required={Bill?.Type === 'Multiple'}
                                    onChange={handleFileUploadChange}
                                    accept=".xlsx, .xls"
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddFacultyHoliday;
