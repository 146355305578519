import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { useUser } from "../../Components/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatDate, formatInputDate } from "../../Components/dateFormate";

const AddUpdateLectures = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { ID } = useParams();
  const [errors, setErrors] = useState({});
  const [classRoomOption, setClassRoomOption] = useState([]);
  const [facultyStaff, setFacultyStaff] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [TopicOption, setTopicOption] = useState([]);




  const [Lecture, setLecture] = useState({
    Std_Id: "",
    Std_Name: "",
    Batch_Id: "",
    Batch_Name: "",
    Subject_Id: "",
    Subject_Name: "",
    Type: "",
    Teacher_Id: "",
    Teacher_Name: "",
    Lecture_Date: "",
    From_Time: "",
    To_Time: "",
    ClassRoom_Id: "",
    ClassRoom_Name: "",
    Description: "",
    Attendance_Marked: "",
    Topic_Id: "",
    Topic_Name: "",
    Link: "",
    Meeting_Id: "",
    Password: "",
    Lec_Type: "OFFLINE"
  });

  console.log(Lecture)

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateLecture = {
      ...Lecture,
      From_Time: new Date(Lecture.From_Time).toLocaleTimeString("en-GB"),
      To_Time: new Date(Lecture.To_Time).toLocaleTimeString("en-GB"),
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addLectures`, updateLecture)
        .then((res) => {
          Swal.fire("Success!", "Lecture Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/viewLectures");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateLecture = {
      ...Lecture,
      From_Time: new Date(Lecture.From_Time).toLocaleTimeString("en-GB"),
      To_Time: new Date(Lecture.To_Time).toLocaleTimeString("en-GB"),
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateLecture/${ID}`, updateLecture)
        .then((res) => {
          Swal.fire("Success!", "Lecture Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/viewLectures");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();

        if (ID && userData?.Org_Id) {
          const ByID = await axios.get(
            `${apiUrl}/getLecturesScheduleById/${userData.Org_Id}/${ID}`
          );
          setLecture(ByID.data);
          console.log(ByID.data);

        }

        if (userData?.Org_Id && userData?.Head_Id) {
          const [classroomRes, facultyStaffRes, stdRes] = await Promise.all([
            axios.get(`${apiUrl}/getClassRoom?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}`),
            axios.get(`${apiUrl}/binddropTeacher?OrgId=${userData.Org_Id}`),
            axios.get(`${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`),
          ]);
          setClassRoomOption(classroomRes.data);
          const facultyStaffOptions = facultyStaffRes.data.map((category) => ({
            label: category.Name,
            value: category.User_Id,
          }));
          setFacultyStaff(facultyStaffOptions);
          const stdOptions = stdRes.data.map((category) => ({
            label: category.Std_Name,
            value: category.Std_Id,
          }));
          setStdOptions(stdOptions);
        }

        if (userData && userData.Org_Id && Lecture?.Std_Id) {
          const [batchRes, subRes] = await Promise.all([
            axios.get(`${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Lecture.Std_Id}`),
            axios.get(`${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Lecture.Std_Id}`),
          ]);
          const batchOptions = batchRes.data.map((category) => ({
            label: category.Batch_Name,
            value: category.Batch_Id,
          }));
          setBatchOptions(batchOptions);
          const subOptions = subRes.data.map((category) => ({
            label: category.Sub_Name,
            value: category.Sub_Id,
          }));
          setSubjectOption(subOptions);
        }

        if (userData && userData.Org_Id && Lecture?.Subject_Id) {
          const result = await axios.get(`${apiUrl}/getTopic?Sub_Id=${Lecture.Subject_Id}&Org_Id=${userData.Org_Id}`);
          const topicOptions = result.data.map((category) => ({
            label: category.Topic_Name,
            value: category.Topic_Id,
          }));
          setTopicOption(topicOptions);
        }

        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData, ID, Lecture.Std_Id, Lecture.Subject_Id]);


  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            {ID ? "Update Lecture Schedule" : "Publish Lecture Schedule"}
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
            {!ID && (
              <Link
                to={"/addMultipleLectures"}
                style={{ float: "right", marginLeft: "10px" }}
              >
                <button className="btn btn-primary btn-sm text-center">
                  Add Multiple Lectures
                </button>
              </Link>
            )}
            <Link
              to={"/viewLectures"}
              style={{ float: "right", marginLeft: "10px" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                View Lectures
              </button>
            </Link>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={ID ? handleUpdate : handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Standard">
                        Standard<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Standard"
                        id="Standard"
                        required
                        options={StdOptions}
                        //isDisabled={ID}
                        value={
                          Lecture.Std_Id
                            ? {
                              value: Lecture.Std_Id,
                              label: Lecture.Std_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Std_Id: selectedOption.value,
                            Std_Name: selectedOption.label,
                            Batch_Id: "",
                            Batch_Name: "",
                            Subject_Id: "",
                            Subject_Name: "",
                            Topic_Id: "",
                            Topic_Name: "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Batch">
                        Batch<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Lecture.Std_Id === "" ? true : false}
                        name="Batch"
                        id="Batch"
                        required
                        options={BatchOptions}
                        value={
                          Lecture.Batch_Id
                            ? {
                              value: Lecture.Batch_Id,
                              label: Lecture.Batch_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Batch_Id: selectedOption.value,
                            Batch_Name: selectedOption.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Lecture_Date">
                        Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="Lecture_Date"
                        name="Lecture_Date"
                        required
                        value={formatInputDate(Lecture.Lecture_Date)}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            Lecture_Date: e.target.value,
                          });
                        }}
                      />

                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Subject">
                        Subject<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Lecture.Std_Id === "" ? true : false}
                        name="Subject"
                        id="Subject"
                        required
                        options={SubjectOption}
                        value={
                          Lecture.Subject_Id
                            ? {
                              value: Lecture.Subject_Id,
                              label: Lecture.Subject_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Subject_Id: selectedOption.value,
                            Subject_Name: selectedOption.label,
                            Topic_Id: "",
                            Topic_Name: "",
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Topic">
                        Topic<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Lecture.Subject_Id === "" ? true : false}
                        name="Topic"
                        id="Topic"
                        required
                        options={TopicOption}
                        value={
                          Lecture.Topic_Id
                            ? {
                              value: Lecture.Topic_Id,
                              label: Lecture.Topic_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Topic_Id: selectedOption.value,
                            Topic_Name: selectedOption.label,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="starttime">
                        Start Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Lecture.From_Time ? dayjs(Lecture.From_Time, 'h:mmA') : null}
                          className="form-control"
                          slotProps={{
                            textField: { size: "small", required: true },
                          }}
                          renderInput={(props) => (
                            <input
                              {...props}
                              type="text"
                              className="form-control"
                              style={{ width: "100%" }}
                              size="small"
                              placeholder=""
                              required
                            />
                          )}
                          onChange={(startTime) =>
                            setLecture({ ...Lecture, From_Time: startTime })
                          }
                        />
                      </LocalizationProvider>

                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Time_To">
                        End Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Lecture.To_Time ? dayjs(Lecture.To_Time, 'h:mmA') : null}
                          required
                          className="form-control"
                          slotProps={{
                            textField: { size: "small", required: true },
                          }}
                          onChange={(startTime) =>
                            setLecture({ ...Lecture, To_Time: startTime })
                          }
                        />
                      </LocalizationProvider>
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="ClassRoom_Name"
                          >
                            Class Room :
                          </label>
                          <select
                            className="form-select"
                            id="ClassRoom_Name"
                            value={Lecture.ClassRoom_Id}
                            onChange={(e) => {
                              const selectedIndex = e.target.selectedIndex;
                              const selectedOption = e.target[selectedIndex];
                              setLecture({
                                ...Lecture,
                                ClassRoom_Id: selectedOption.value,
                                ClassRoom_Name: selectedOption.label,
                              });
                            }}
                          >
                            <option
                              selected
                              value=""
                              label="--Select--"
                            ></option>
                            {classRoomOption?.map((option) => (
                              <option
                                key={option.ClassRoom_Id}
                                value={option.ClassRoom_Id}
                                label={option.ClassRoom_Name}
                              >
                                {option.ClassRoom_Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Faculty">
                        Faculty<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Faculty"
                        id="Faculty"
                        required
                        options={facultyStaff}
                        value={
                          Lecture.Teacher_Id
                            ? {
                              value: Lecture.Teacher_Id,
                              label: Lecture.Teacher_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Teacher_Id: selectedOption.value,
                            Teacher_Name: selectedOption.label,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Lec_Type">
                            Lecture Type :
                          </label>
                          <select
                            className="form-select"
                            id="Lec_Type"
                            name="Lec_Type"
                            value={Lecture.Lec_Type}

                            onChange={(e) => {
                              setLecture({
                                ...Lecture,
                                Lec_Type: e.target.value,
                              });
                            }}
                          >
                            <option selected value="OFFLINE">
                              Offline
                            </option>
                            <option value="ONLINE">Online</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {Lecture.Lec_Type === "ONLINE" && (
                      <>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Link">
                            Lecture Link
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="Link"
                            name="Link"
                            placeholder="Enter Lecture Link"
                            onChange={(e) => {
                              setLecture({
                                ...Lecture,
                                Link: e.target.value,
                              });
                            }}
                            value={Lecture.Link}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Meeting_Id">
                            Meeting Id
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="Meeting_Id"
                            name="Meeting_Id"
                            value={Lecture.Meeting_Id}

                            placeholder="Enter Meeting ID"
                            onChange={(e) => {
                              setLecture({
                                ...Lecture,
                                Meeting_Id: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Password">
                            Password
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="Password"
                            name="Password"
                            value={Lecture.Password}

                            placeholder="Enter Meeting Password"
                            onChange={(e) => {
                              setLecture({
                                ...Lecture,
                                Password: e.target.value,
                              });
                            }}

                          />
                        </div>{" "}
                      </>
                    )}
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Description">
                        Description(Optional)
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="Description"
                        value={Lecture.Description}
                        onChange={(e) => {
                          setLecture({
                            ...Lecture,
                            Description: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {ID ? 'Update' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUpdateLectures;
