import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import { formatDate, formatISOTimeTo12Hour, formatISOTime} from '../../Components/dateFormate'


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [Log, setLog] = useState([]);
    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(updateData);


    useEffect(() => {
        const fetchData = async () => {
          try {
            showLoader();
    
            if (userData && updateData?.Topic_Id && updateData?.Std_Id && updateData?.Sub_Id) {
         
              const result = await axios.get(
                `${apiUrl}/getTopicwiseLectureScheduleDetails?orgid=${userData.Org_Id}&stdid=${updateData?.Std_Id}&batchid=&headid=${userData?.Head_Id}&topicid=${updateData?.Topic_Id}&subid=${updateData?.Sub_Id}&fromdate=&todate=`
              );
              setLog(result.data);
              console.log(result.data);

            }
    
            hideLoader();
          } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
          }
        };
    
        fetchData();
      }, [userData, updateData]);





    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Lectures Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
      <div className="col-md-12">
        <div className="row">
       
          <div className="col-md-12">
            <div className="table-responsive" style={{ padding: '20px 0px' }}>
              <div>
                <table
                  cellSpacing="0"
                  align="center"
                  rules="all"
                  className="display table table-bordered table-hover basic-datatables"
                  border="1"
                  id="ContentPlaceHolder1_gvProducts"
                  style={{ borderCollapse: 'collapse', border: '1px solid rgba(33, 33, 33, 0.1) !important' }}
                >
                  <thead>
                    <tr align="center">
                      <th align="center" scope="col">#</th>
                      <th align="center" scope="col">Lecture Date</th>
                      <th align="center" scope="col">Form Time</th>
                      <th align="center" scope="col">To Time</th>
                      <th align="center" scope="col">Teacher Name</th>
                      <th align="center" scope="col">Duration</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Log.map((dt, index) => (
                    <tr key={index} align="center">
                      <td align="center">
                        <span>{index+1}</span>
                      </td>
                      <td>
                        <span>{formatDate(dt.Lecture_Date)}</span>
                      </td>
                      <td align="center">
                        <span>{formatISOTimeTo12Hour(dt.From_Time)}</span>
                      </td>
                      <td align="center"> 
                        <span>{formatISOTimeTo12Hour(dt.To_Time)}</span>
                      </td>
                      <td align="center">
                        <span>{dt.Teacher_Name}</span>
                      </td>
                      <td align="center">
                        <span>{formatISOTime(dt.Duration)}</span>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
