import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useUser } from "../../../Components/UserContext";
import mammoth from "mammoth";
import cheerio from 'cheerio'; 

const UploadQuestion = ({ open, onClose, TopicOptions, QuestionLevel, Subject, Class }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [questions, setQuestions] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");
  const [htmlContent1, setHtmlContent1] = useState("");
  const [isImageStored, setIsImageStored] = useState(false);
  const [resImgTag, setResImgTag] = useState([]);
  const [uploadedImageCount, setUploadedImageCount] = useState(0);
  const [totalImages, setTotalImages] = useState(0);

  const [Bill, setBill] = useState({
    Class_Id: Class.value,
    Class_Name: Class.label,
    Sub_Id: Subject.value,
    Sub_Name: Subject.label,
    Topic_Id: "",
    Topic_Name: "",
    Question_Type: "",
    Question_Level: QuestionLevel,
    Mark: "",
    Time: "",
    Negative_Marking: "",
    Incorrect_Negative: "",
    Unattempted_Negative: "",
    Question_in_Text: "",
    Question_in_Image: "",
    Ans_1: "",
    Ans_2: "",
    Ans_3: "",
    Ans_4: "",
    Ans_5: "",
    Ans_6: "",
    Correct_ans: "",
    Explanation: "",
    Is_Practice_Paper: "",
  });

  useEffect(() => {
    setShow(open);
  }, [open]);

  useEffect(() => {
    if (isImageStored) {
      showLoader();
      const updatedContent = replaceBase64Images(htmlContent, resImgTag);
      setHtmlContent1(updatedContent);
      hideLoader();
    }
  }, [isImageStored, resImgTag, htmlContent, showLoader, hideLoader]);

  useEffect(() => {
    const $ = cheerio.load(htmlContent1);
    const extractedQuestions = [];

    $('table').each((index, table) => {
      const questionData = {};
      const $table = $(table);

      const question = $table.find('tr').eq(0).find('td').eq(1).html();
      questionData.Question = question;

      const options = [];
      $table.find('tr').slice(1, -2).each((i, row) => {
        const option = $(row).find('td').eq(1).html();
        options.push(option);
      });
      questionData.Options = options;

      const answer = $table.find('tr').last().prev().find('td').eq(1).html();
      questionData.Answer = answer;

      const solution = $table.find('tr').last().find('td').eq(1).html();
      questionData.Solution = solution;

      extractedQuestions.push(questionData);
    });

    setQuestions(extractedQuestions);
  }, [htmlContent1]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleFiles = async (files) => {
    if (!files || files.length === 0) {
      console.error("No files provided.");
      return;
    }

    showLoader();
    const file = files[0];

    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      setHtmlContent(result.value);
      console.log(result.value);

      extractAndUploadImages(result.value);
    } catch (error) {
      console.error("Error converting .docx to HTML:", error);
      Swal.fire("Error", "Failed to convert .docx file. Please try again.", "error");
    } finally {
      hideLoader();
    }
  };

  const replaceBase64Images = (htmlContent, resImgTag) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const imgElements = doc.querySelectorAll('img');

    imgElements.forEach((imgElement, index) => {
      if (resImgTag[index]) {
        imgElement.src = resImgTag[index].url;
      }
    });

    return doc.body.innerHTML;
  };

  const extractAndUploadImages = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const images = doc.body.getElementsByTagName("img");

    setTotalImages(images.length);

    const uploadPromises = Array.from(images).map(async (img, index) => {
      const src = img.getAttribute("src");
      if (src.startsWith("data:image")) {
        const base64Data = src.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
        const blob = base64ToBlob(base64Data);
        const filename = `image_${index + 1}.png`; // Adding index to the filename
        return uploadImage(blob, filename, index); // Pass the index to the uploadImage function
      }
    });

    await Promise.all(uploadPromises);
  };

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/png" });
  };

  const uploadImage = async (blob, filename, index) => {
    const formData = new FormData();
    formData.append("QuestionsImage", blob, filename);

    try {
      const response = await axios.post(`${apiUrl}/uploadQuestionsImage`, formData);
      const imageTag = response.data[0];
      setResImgTag((prevData) => {
        const newData = [...prevData];
        newData[index] = imageTag; // Ensuring the order is maintained
        return newData;
      });
      console.log(response.data);

      setUploadedImageCount(prevCount => prevCount + 1);

      if (uploadedImageCount  === totalImages) { // Adjust condition
        setIsImageStored(true);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire("Error", "Failed to upload image. Please try again.", "error");
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    await handleFiles([file]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!questions.length) {
      Swal.fire("Error", "No questions extracted from the document", "error");
      return;
    }

    showLoader();

    try {
      const transformedQuestions = questions.map((question) => ({
        Question_in_Text: question.Question,
        Ans_1: question.Options[0] || "",
        Ans_2: question.Options[1] || "",
        Ans_3: question.Options[2] || "",
        Ans_4: question.Options[3] || "",
        Ans_5: question.Options[4] || "",
        Ans_6: question.Options[5] || "",
        Correct_ans: 
        question.Answer === "<p>1</p>"
        ? question.Options[0]
        : question.Answer === "<p>2</p>"
        ? question.Options[1]
        : question.Answer === "<p>3</p>"
        ? question.Options[2]
        : question.Options[3],
        Explanation: question.Solution,
      }));

      
      await axios.post(`${apiUrl}/bulkUploadQuestionsTopicWise`, {
        ...Bill,
        questions:transformedQuestions,
        Head_Id: userData?.Head_Id,
        Head_Name: userData?.Head_Name,
        User_Id: userData.UserId,
        Org_Id: userData.Org_Id,
        Org_Name: userData.Org_Name,
        User_Role: userData.Role,
        LoginUsername: userData.Username,
        Display_Name: userData.UserName,
      });

      Swal.fire("Success!", "Questions Added Successfully!", "success").then((result) => {
        if (result.isConfirmed) {
          handleClose();
        }
      });
    } catch (error) {
      console.error("Error uploading questions", error);
      Swal.fire("Server Timeout", "Server is Busy! Please try again later.", "error");
    } finally {
      hideLoader();
    }
  };

  const renderHTML = () => {
    return { __html: htmlContent1 };
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>Upload Questions</Modal.Header>
        <Modal.Body>
          <div>
            {/* <div dangerouslySetInnerHTML={renderHTML()} /> */}
            <div style={{ textAlign: "center" }} className="p-1 pt-3">
            {/*${apiUrl}public/assets/img/QuestionDocumentFormat.docx/Documents/QuestionDocumentFormat.docx */}
              <a href={`/assets/img/QuestionDocumentFormat.docx`} download>
                <div style={{ paddingBottom: "10px" }}>
                  <img
                    src="/assets/img/downloaddoc.png"
                    style={{ height: "70px" }}
                    alt="Download Document"
                  />
                </div>
                <span
                  className="text-bold-500"
                  style={{ color: "#7A54D8", fontSize: "1.3rem" }}
                >
                  Download Sample Document
                </span>
              </a>
              <p
                className="text-secondary text-bold-400"
                style={{ fontSize: "0.80rem", marginTop: "10px" }}
              >
                To Upload multiple students download sample Document file here.
                <br />
                Create your document in the same format and upload by selecting the upload option.
              </p>
            </div>
            <div
              className="row justify-content-center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-md-4 text-left">
                <label className="form-label">Topic</label>
                <span style={{ color: "red" }}>*</span>
                <select
                  className="form-select"
                  style={{ height: "39.5px" }}
                  id="Topic"
                  name="Topic"
                  value={Bill.Topic_Id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedOption = e.target[selectedIndex];
                    setBill({
                      ...Bill,
                      Topic_Id: selectedOption.value,
                      Topic_Name: selectedOption.label,
                    });
                  }}
                >
                  <option selected disabled value="" label="--Select--"></option>
                  {TopicOptions?.map((option) => (
                    <option key={option.Topic_Id} value={option.Topic_Id}>
                      {option.Topic_Name}
                    </option>
                  ))}
                </select>
                <span style={{ display: "none" }}></span>
              </div>
              <div className="col-md-4 text-left">
                <label className="form-label">
                  Question Document<span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileChange}
                  accept=".doc, .docx"
                  name="QuestionDocument"
                />
              </div>
              <div className="col-md-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  style={{ marginTop: "23px" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {loader}
          {/* {questions.length > 0 && (
            <div>
              <h4>Extracted Questions</h4>
              <pre>{JSON.stringify(questions, null, 2)}</pre>
            </div>
          )} */}
        
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadQuestion;
