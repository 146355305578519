import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  return (
    <UserContext.Provider value={{ userData, setData, filterData, setFilterData}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

