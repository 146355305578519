import React, { useEffect, useState } from "react";
import $ from "jquery";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../DropDowns/DropDown";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";
import DropStudent from "../DropDowns/StudentDropdown";
import DropProductcat from "../DropDowns/ProductCategoryDrop";
import AddExamModal from "../Test/AddExamModal";

const Examination = () => {
  const [table, setTable] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setBillData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateDataId, setId] = useState("");
  const [CategoryOptions, setCategoryOptions] = useState([]);
  const [selectedstatus, setSelectedstatus] = useState("Uncleared");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [examOptions, setexamOptions] = useState([]);
  const [Sr_No, setSrNo] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  //   const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  //   const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  const [Bill, setBill] = useState({
    Std_Id: "",
    Batch_ID: "",
    Sr_No: "",
    Std_Name: "",
    Batch_Name: "",
    Exam: "",
  });
  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Bill.Std_Id]);

  const fetchData = async () => {
    try {
      showLoader();
      const Exam = await axios.get(
        `${apiUrl}/examTitles?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
      );

      const getExamination = Exam.data.map((category) => ({
        label: category.Exam,
        value: category.Sr_No,
      }));
      setexamOptions(getExamination);
      console.log(getExamination);

      if (userData && userData.Org_Id && userData?.Head_Id) {
        showLoader();
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
        console.log(getStd);
      }

      if (userData && userData.Org_Id && Bill.Std_Id) {
        const batch = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Std_Id}`
        );

        const getBatch = batch.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(getBatch);
        // console.log(getBatch);
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (data.length > 0) {
      const newFilteredData = data.filter(
        (row) =>
          row?.Day.toLowerCase().includes(searchTerm) ||
          row?.Subjects.toLowerCase().includes(searchTerm) ||
          row?.Timing.toLowerCase().includes(searchTerm) ||
          formatDate(row?.Date)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          row?.Min_Marks.toLowerCase().includes(searchTerm) ||
          row?.Total_Marks.toLowerCase().includes(searchTerm) ||
          row?.Syllabus.toLowerCase().includes(searchTerm)
      );
      setFilteredData(newFilteredData);
    }
  }, [searchTerm, data]);

  async function editBills(data) {
    showLoader();

    console.log(data);
    setBill({
      newHead_Name: data.Head_Name,
      Head_GST_No: data.Head_GST_No,
      Head_Address: data.Head_Address,
    });
    setEditingItem(data);
    hideLoader();
  }

  useEffect(() => {
    const options = (props) => {
      const { ID } = props.data;
      return (
        <div className="font-sans-serif">
          <div>
            <Link
              onClick={(e) => {
                e.preventDefault();
                showModal(props.data);
              }}
              className="dropdown-item cursor-pointer"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <i
                className="fas fa-plus"
                style={{ color: "blue", fontSize: "14px" }}
              ></i>
              <span> Insert</span>
            </Link>
          </div>
        </div>
      );
    };

    const newAgcolumns = [
      { field: "ExamNo" },
      { field: "Date" },
      { field: "Day" },
      { field: "Time" },
      { field: "Duration" },
      { field: "Subject" },
      { field: "MinMarks" },
      { field: "Total" },
      { field: "Syllabus" },
      {
        headerName: "",
        field: "",
        cellRenderer: options,
        width: 50,
        maxWidth: 70,
      },
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    let newAgrows;
    if (filteredData.length > 0) {
      newAgrows = filteredData.map((row) => ({
        ExamNo: row.Exam_No,
        Date: row.Date,
        Day: row.Day,
        Time: row.Timing,
        Duration: row.Duration,
        Subject: row.Subjects,
        MinMarks: row.Min_Marks,
        Total: row.Total_Marks,
        Syllabus: row.Syllabus,
      }));
    } else {
      newAgrows = [
        {
          ExamNo: "",
          Date: "",
          Day: "",
          Time: "",
          Duration: "",
          Subject: "",
          MinMarks: "",
          Total: "",
          Syllabus: "",
        },
      ];
    }
    setAgrows(newAgrows);
  }, [filteredData]);

  const handleDateRangeFilter = async () => {
    showLoader();
    fetchData();
    hideLoader();
  };

  useEffect(() => {
    if (table) {
      const initializeDataTable = () => {
        var dTable = $("#data-table").DataTable({
          paginate: true,
          responsive: true,
          pageLength: 10,
          ordering: true,
          searching: true,
        });

        setDataTable(dTable);
      };
      if (!dataTable) {
        initializeDataTable();
      }

      return () => {
        if (dataTable) {
          dataTable.destroy(true);
        }
      };
    }
    setTable(false);
  }, [table]);

  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF();
    doc.text("Exam", 20, 10);

    const columns = [
      "Sr No",
      "Date",
      "Day",
      "Time",
      "Duration",
      "Subject",
      "Min Marks",
      "Total",
      "Syllabus",
    ];

    const rows = filteredData.map((row, index) => [
      row.Exam_No,
      row.Date,
      row.Day,
      row.Timing,
      row.Duration,
      row.Subjects,
      row.Min_Marks,
      row.Total_Marks,
      row.Syllabus,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Exam.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Sr No",
      "Date",
      "Day",
      "Time",
      "Duration",
      "Subject",
      "Min Marks",
      "Total",
      "Syllabus",
    ];
    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([
        row.Exam_No,
        row.Date,
        row.Day,
        row.Timing,
        row.Duration,
        row.Subjects,
        row.Min_Marks,
        row.Total_Marks,
        row.Syllabus,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Exam Result");

    XLSX.writeFile(workBook, "Exam Result.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
  };

  const toggle = () => {
    setDesign({
      ...design,
      filterToggle: design.filterToggle ? false : true,
    });
    setFilterData({
      ...filterData,
      filterToggle: design.filterToggle ? false : true,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      showLoader();
      const response = await axios.get(
        `${apiUrl}/PublishExam?Std_Id=${Bill.Std_Id}&Batch_Id=${Bill.Batch_ID}&Sr_No=${Bill.Sr_No}&Head_Id=${userData.Head_Id}&Org_Id=${userData.Org_Id}`
      );
      console.log(response.data);
      setBillData(response.data);
      setFilteredData(response.data);
      setTable(true);
    } catch (error) {
      // If there's an error, log it and show an error message
      console.error(error);
      Swal.fire(
        "Server Timeout",
        "Server is Busy!!!, Please try again later.",
        "error"
      );
    } finally {
      // Always hide the loader at the end
      hideLoader();
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="profiledetail lh-1 mb-1"
                  style={{ fontSize: "13px" }}
                >
                  Exam No :{" "}
                  <span className="ms-1 text-1100">{row.Exam_No}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="profiledetail lh-1 mb-1"
                  style={{ fontSize: "13px" }}
                >
                  Date : <span className="ms-1 text-1100">{row.Date}</span>
                </p>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Day : <span className="ms-1 text-1100">{row.Day}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Time : <span className="ms-1 text-1100">{row.Timing}</span>
                </p>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Duration :{" "}
                  <span className="ms-1 text-1100">{row.Duration}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Subject :{" "}
                  <span className="ms-1 text-1100">{row.Subjects}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Min Marks :{" "}
                  <span className="ms-1 text-1100">{row.Min_Marks}</span>
                </p>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Total :{" "}
                  <span className="ms-1 text-1100">{row.Total_Marks}</span>
                </p>
              </div>
            </div>
            <div className="col-md-1">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Syllabus :{" "}
                  <span className="ms-1 text-1100">{row.Syllabus}</span>
                </p>
              </div>
            </div>

            <div className="col-md-1">
              <div className="font-sans-serif">
                <button
                  style={{ padding: 0 }}
                  className="btn btn-link"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={{ fontSize: "14px", color: "gray" }}
                  ></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <div>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        showModal(row.indexValue);
                      }}
                      className="dropdown-item cursor-pointer"
                      style={{ paddingTop: 10, paddingBottom: 6 }}
                    >
                      <i
                        className="fas fa-plus"
                        style={{ color: "blue", fontSize: "14px" }}
                      ></i>
                      <span> Insert</span>
                    </Link>

                    {/* <div className="dropdown-divider"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex align-items-center">
                    <p className="mb-0 profiledetail text-truncate lh-1">
                      Exam No :{" "}
                      <span className="fw-semi-bold ms-1"> {row.Exam_No}</span>
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      style={{ padding: 0 }}
                      className="btn btn-link"
                      id="dropdownMenuLink"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        className="fas fa-ellipsis-v"
                        style={{ fontSize: "14px", color: "gray" }}
                      ></i>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end py-0"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <div>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            showModal(indexValue);
                          }}
                          className="dropdown-item cursor-pointer"
                          style={{ paddingTop: 10, paddingBottom: 6 }}
                        >
                          <i
                            className="fas fa-plus"
                            style={{ color: "blue", fontSize: "14px" }}
                          ></i>
                          <span> Insert</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail text-truncate lh-1">
                    Date :{" "}
                    <span className="fw-semi-bold ms-1"> {row.Date}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Day : <span className="ms-1 text-1100">{row.Day}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Time : <span className="ms-1 text-1100">{row.Timing}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Duration :{" "}
                    <span className="ms-1 text-1100">{row.Duration}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Subject :{" "}
                    <span className="ms-1 text-1100">{row.Subjects}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Min Marks :{" "}
                    <span className="ms-1 text-1100">{row.Min_Marks}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Total :{" "}
                    <span className="ms-1 text-1100">{row.Total_Marks}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                    Syllabus :{" "}
                    <span className="ms-1 text-1100">{row.Syllabus}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="content">
      <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
        <div className="container-fluid">
          {/* Sidebar */}
          <div id="sidebar-wrapper">
            <div className="row g-4">
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-2"
                  data-component-card="data-component-card"
                >
                  <div className="row justify-content-center d-flex p-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Branch">
                            Branch
                          </label>
                          <HeadMasterDropDown
                            label="Branch"
                            name="Branch"
                            id="Branch"
                            //onChange={(e) => setBranch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            From Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            //value={fromDate?.format("YYYY-MM-DD")}
                            onChange={(date) =>
                              setFromDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            To Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            // value={toDate?.format("YYYY-MM-DD")}
                            onChange={(date) =>
                              setToDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center p-2">
                    <div className="col-md-4 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-center"
                        onClick={handleDateRangeFilter}
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                  <div className="col-md-6">Exam Schedule</div>

                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="col-md-5">
                      <Link to={"/Edit_Examination"} style={{ float: "right" }}>
                        <button className="btn btn-primary btn-sm text-center">
                          View Exams
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </h4>

              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-4"
                  data-component-card="data-component-card"
                >
                  <div className="card-body p-0">
                    <div className="p-4 code-to-copy">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Branch ">
                              BRANCH
                            </label>
                            <HeadMasterDropDown
                              label="Branch"
                              name="Branch"
                              id="Branch"
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Standard">
                              Standard
                            </label>
                            <Dropdown
                              options={StdOptions}
                              name="Standard"
                              required={true}
                              id="Batch"
                              value={Bill.Std_Id}
                              //disabled={Sr_No && opration === 'update' && !isEditing}

                              onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                const selectedOption = e.target[selectedIndex];
                                // console.log(selectedOption.value, selectedOption.label);
                                setBill({
                                  ...Bill,
                                  Std_Id: selectedOption.value,
                                  Std_Name: selectedOption.label,
                                });
                              }}
                            />
                            <span style={{ color: "Red" }}></span>
                          </div>
                          <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Batch">
                              Batch
                            </label>
                            <Dropdown
                              options={BatchOptions}
                              name="Batch"
                              required={true}
                              id="Batch"
                              value={Bill.Batch_ID}
                              //disabled={Sr_No && opration === 'update' && !isEditing}

                              onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                const selectedOption = e.target[selectedIndex];
                                // console.log(selectedOption.value, selectedOption.label);
                                setBill({
                                  ...Bill,
                                  Batch_ID: selectedOption.value,
                                  Batch_Name: selectedOption.label,
                                });
                              }}
                            />
                            <span style={{ color: "Red" }}></span>
                          </div>
                          <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Examination">
                              Examination
                            </label>
                            <Dropdown
                              options={examOptions}
                              name="Examination"
                              required={true}
                              id="Batch"
                              value={Bill.Sr_No}
                              //disabled={Sr_No && opration === 'update' && !isEditing}

                              onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                const selectedOption = e.target[selectedIndex];
                                // console.log(selectedOption.value, selectedOption.label);
                                setBill({
                                  ...Bill,
                                  Sr_No: selectedOption.value,
                                  Exam: selectedOption.label,
                                });
                              }}
                            />
                            <span style={{ color: "Red" }}></span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                            >
                              Publish Schedule
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  style={{
                    float: "left",
                    display: "inline-block",
                    width: "200px",
                  }}
                ></input>
                <Link
                  to={"/addBranches"}
                  style={{ float: "right", display: "none" }}
                >
                  <button className="btn btn-primary btn-sm text-center">
                    Add
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn bg-200 filterButton collapsed mr-2 d-none"
                  data-toggle="collapse"
                  onClick={toggle}
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  style={{ float: "right", marginRight: "10px" }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className="form-control dropdown-toggle viewButton  mb-2"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "auto",
                    padding: "4px 10px",
                  }}
                >
                  <>
                    {design.dataView === "Table View" && (
                      <TableChartOutlinedIcon />
                    )}
                  </>
                  <>
                    {design.dataView === "List View" && (
                      <FormatListBulletedIcon />
                    )}
                  </>
                  <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={(e) => {
                      setDesign({
                        ...design,
                        dataView: "Table View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Table View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <TableChartOutlinedIcon />
                    <span> Table View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "List View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "List View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <FormatListBulletedIcon />
                    <span> List View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "Tile View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Tile View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <GridViewIcon />
                    <span> Tile View</span>
                  </Link>
                </div>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to PDF</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadPDF()}
                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                  >
                    <i
                      className="fas fa-file-pdf"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to Excel</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadExcel()}
                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row">
              {design.dataView === "Table View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  {Agrows.length > 0 && (
                    <AgGridTable row={Agrows} column={Agcolumns} />
                  )}
                </div>
              )}
              {design.dataView === "Tile View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">{displayData}</div>
                </div>
              )}
              {design.dataView === "List View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">
                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                      <div className="card h-100 hover-actions-trigger">
                        <div
                          className="card-body"
                          style={{ paddingTop: "5px" }}
                        >
                          {displayData}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(design.dataView === "List View" ||
                design.dataView === "Tile View") && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageCount={pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  breakAriaLabels={{
                    forward: "Jump forward",
                    backward: "Jump backward",
                  }}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                  previousLinkClassName={"btn btn-outline-primary me-2"}
                  nextLinkClassName={"btn btn-outline-primary ms-2"}
                  activeLinkClassName={"customclass ms-2 me-2"}
                  pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                />
              )}
            </div>
          </div>
        </div>
        {isModal && (
          <AddExamModal open={isModal} onClose={closed} updateData={Bill}  length={data?.length}/>
        )}
        {loader}
      </div>
    </div>
  );
};

export default Examination;
