import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../Components/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatDate, formatInputDate } from "../../../Components/dateFormate";
import DropVendor from "../../DropDowns/VendorDropdown";
import BankDropdoun from "../../DropDowns/BankDropdoun";

const VendorPayment_Entry = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { V_Id } = useParams();
  const [errors, setErrors] = useState({});

  const [Vendor, setVendor] = useState({
    Org_Id: "",
    ReceiptNo_Txt: "",
    ReceiptNo: "",
    Payment_Type: "",
    Payment_Id: "",
    Name: "",
    Amount: "",
    Transaction_Id: "",
    Transaction_Date: "",
    Cheq_No: "",
    Cheq_Date: "",
    Pay_Status: "",
    Payment_Mode: "Cash",
    Remarks: "",
    Cheq_Status: "",
    Bank_Name: "",
    AddedBy_Id: "",
    AddedBy_Role: "",
    AddedOn: "",
  });

  console.log(Vendor);

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;

    const updateVendor = {
      ...Vendor,

      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addVendorsPayments`, updateVendor)
        .then((res) => {
          Swal.fire("Success!", "Vendor Payments Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/ViewVendorPayments");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateVendor = {
      ...Vendor,

      Org_Id: userData.Org_Id,
      ReceiptNo_Txt: userData.ReceiptNo_Txt,
      ReceiptNo: userData.ReceiptNo,
      Payment_Type: userData.Payment_Type,
      Payment_Id: userData.Payment_Id,
      Name: userData.Name,
      Amount: userData.Amount,
      Transaction_Id: userData.Transaction_Id,
      Transaction_Date: userData.Transaction_Date,
      Cheq_No: userData.Cheq_No,
      Cheq_Date: userData.Cheq_Date,
      Pay_Status: userData.Pay_Status,
      Payment_Mode: userData.Payment_Mode,
      Remarks: userData.Remarks,
      Cheq_Status: userData.Cheq_Status,
      Bank_Name: userData.Bank_Name,
      AddedBy_Id: userData.AddedBy_Id,
      AddedBy_Role: userData.AddedBy_Role,
      AddedOn: userData.AddedOn,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateVendor/${V_Id}`, updateVendor)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Vendor Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/viewVendors");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const result = await axios.get(
          `${apiUrl}/getTopic?Sub_Id=${""}&Org_Id=${""}`
        );

        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendor((prevVendor) => ({
      ...prevVendor,
      [name]: value,
    }));
  };
  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">Add Vendor Payments</div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
            {/* {!V_Id && (
              <Link
                to={"/addMultipleVendors"}
                style={{ float: "right", marginLeft: "10px" }}
              >
                <button className="btn btn-primary btn-sm text-center">
                  Add Multiple Vendors
                </button>
              </Link>
            )} */}
            <Link
              to={"/ViewVendorPayments"}
              style={{ float: "right", marginLeft: "10px" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                View Payments
              </button>
            </Link>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-3">
                      <DropVendor
                        label="Vendor"
                        name="Vendor"
                        id="Vendor"
                        value={
                          Vendor.Payment_Id
                            ? {
                              value: Vendor.Payment_Id,
                              label: Vendor.Name,
                            }
                            : null
                        }
                        required={true}
                        is
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Payment_Id: e.value,
                            Name: e.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    {/* <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Company_Name">
                      Organization Name<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Company_Name"
                        name="Company_Name"
                        required
                        value={Vendor.Company_Name}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Company_Name: e.target.value,
                          });
                        }}
                      />

                    </div> */}
                    {/* <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Company_Email">
                        Organization Email
                        <span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Company_Email"
                        name="Company_Email"
                        required
                        value={Vendor.Company_Email}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Company_Email: e.target.value,
                          });
                        }}
                      />
                    </div> */}
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Payment_Mode">
                        Payment Mode<span style={{ color: "Red" }}>*</span>
                      </label>
                      <select
                        className="form-select form-select-sm"
                        required
                        name="Payment_Mode"
                        id="Payment_Mode"
                        value={Vendor.Payment_Mode}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Payment_Mode: e.target.value,
                            Transaction_Id:"",
                            Cheq_No:"",
                            Cheq_Date:"",
                            Cheq_Status:"",
                            Bank_Name:""

                          })
                        }}
                      >
                        <option selected value="Cash">
                          Cash
                        </option>
                        <option value="Cheque">Cheque</option>
                        <option value="Online">Online</option>
                      </select>
                      <span style={{ color: "Red" }}></span>
                    </div>

                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Amount">
                        Payment Amount
                        <span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Amount"
                        name="Amount"
                        placeholder="Amount"
                        required
                        value={Vendor.Amount}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Amount: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Transaction_Date">
                        Payment Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        name="Transaction_Date"
                        id="Transaction_Date"
                        required
                        value={
                          Vendor.Transaction_Date
                            ? dayjs(Vendor.Transaction_Date).format(
                              "YYYY-MM-DD"
                            )
                            : ""
                        }
                        placeholder="Enter Payment Date"
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                  </div>

                  {Vendor?.Payment_Mode === "Cheque" && (
                    <div className="row">
                      <div className="mb-3 col-md-3">
                        <label className="form-label" htmlFor="Bank_Name">
                          Bank Name<span style={{ color: "Red" }}>*</span>
                        </label>
                        <BankDropdoun
                          name="Bank_Name"
                          id="Bank_Name"
                          value={
                            Vendor.Bank_Name
                              ? {
                                value: Vendor.Bank_Name,
                                label: Vendor.Bank_Name,
                              }
                              : null
                          }
                          required={Vendor.Payment_Mode === "Cheque"}
                          onChange={(e) => {
                            setVendor({
                              ...Vendor,
                              Bank_Name: e.label,
                              //Category: e.label,
                            });
                          }}
                        />
                        <span style={{ color: "Red" }}></span>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label" htmlFor="Cheq_No">
                          Cheque No<span style={{ color: "Red" }}>*</span>
                        </label>
                        <input
                          className={`form-control form-control-sm`}
                          type="text"
                          name="Cheq_No"
                          id="Cheq_No"
                          required={Vendor.Payment_Mode === "Cheque"}
                          value={Vendor.Cheq_No}
                          placeholder="Enter Cheque No"
                          onChange={(e) => {
                            const input = e.target.value;
                            const decimalInput = input.replace(/[^0-9]/g, "");
                            setVendor({
                              ...Vendor,
                              Cheq_No: decimalInput,
                            });
                          }}
                        />
                      </div>

                      <div className="mb-3 col-md-3">
                        <label className="form-label" htmlFor="Cheq_Date">
                          Cheque Date<span style={{ color: "Red" }}>*</span>
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          name="Cheq_Date"
                          id="Cheq_Date"
                          required={Vendor.Payment_Mode === "Cheque"}
                          value={
                            Vendor.Cheq_Date
                              ? dayjs(Vendor.Cheq_Date).format("YYYY-MM-DD")
                              : ""
                          }
                          placeholder="Enter Cheque Date"
                          onChange={handleChange}
                        />
                        <span style={{ color: "Red" }}></span>
                      </div>

                      <div className="mb-3 col-md-3">
                        <label className="form-label" htmlFor="Cheq_Status">
                          Cheque Status<span style={{ color: "Red" }}>*</span>
                        </label>
                        <select
                          className="form-select form-select-sm"
                          required
                          name="Cheq_Status"
                          id="Cheq_Status"
                          value={Vendor.Cheq_Status}
                          onChange={handleChange}
                        >

                          <option selected value="">--Select--</option>
                          <option value="Honoured">Honoured</option>
                        </select>
                        <span style={{ color: "Red" }}></span>
                      </div>
                    </div>
                  )}

                  {Vendor?.Payment_Mode === "Online" && (
                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label className="form-label" htmlFor="Transaction_Id">
                          Transaction ID
                          <span style={{ color: "Red" }}>*</span>
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          value={Vendor.Transaction_Id}
                          name="Transaction_Id"
                          id="Transaction_Id"
                          placeholder="Enter Transaction ID"
                          onChange={handleChange}
                        />
                        <span style={{ color: "Red" }}></span>
                      </div>
                    </div>
                  )}
                  {/* <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Min_Marks">
                        Minimum Marks<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Min_Marks"
                        name="Min_Marks"
                        required
                        placeholder="Enter Minimum Marks"
                        value={Vendor.Min_Marks}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Min_Marks: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Total_Marks">
                        Total Marks<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Total_Marks"
                        name="Total_Marks"
                        required
                        placeholder="Enter Total Marks"
                        value={Vendor.Total_Marks}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Total_Marks: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Syllabus_Remarks">
                        Syllabus/Remarks
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="Syllabus_Remarks"
                        placeholder="Syllabus Remarks..."
                        rows={2}
                        value={Vendor.Syllabus_Remarks}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Syllabus_Remarks: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="mb-3 col-md-12">
                    <label className="form-label" htmlFor="Remarks">
                      Remark
                    </label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={Vendor.Remarks}
                      name="Remarks"
                      id="Remarks"
                      placeholder="Remark"
                      onChange={handleChange}
                    />

                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {"Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorPayment_Entry;
