import React, { useState, useEffect } from "react";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";


const HeadMasterDropDown = ({ label, required, value, name, id, onChange }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [headOptions, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();
                    const result = await axios.get(
                        `${apiUrl}/getHeadMaster?OrgId=${userData.Org_Id}`
                    );

                    const op = result.data.map((category) => ({
                        label: category.Head_Name,
                        value: category.Head_Id,
                    }));
                    setData(op);

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, userData?.Org_Id, userData?.Head_Id]);

   // console.log(userData?.Head_Id)
    return (
        <>
          
            <select
                className="form-select form-select-sm"
                name={name}
                id={id}
                value={userData?.Head_Id}
                required={required}
                disabled
                onChange={onChange}
            >
                
                {headOptions?.map((option) => (
                    <option key={option.value} value={option.value} label={option.label}>
                        {option.label}
                    </option>
                ))}
            </select>
            {loader}
        </>
    );
};


export default HeadMasterDropDown;
