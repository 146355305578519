import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import { formatInputDate } from '../../Components/dateFormate';

const UploadPerformance = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const { TestId } = useParams();
    const [facultyStudent, setFacultyStudent] = useState([]);
    const [testData, setTestData] = useState({});
    const [checkedStatus, setCheckedStatus] = useState({});
    const [checkAll, setCheckAll] = useState(true);
    const [markedStudent, setMarkedStudent] = useState([]);
    const [editingRow, setEditingRow] = useState(null);


    useEffect(() => {
        fetchData();
    }, [userData, testData?.Subject_Id, testData?.Batch_Id]);

    const fetchData = async () => {
        try {
            showLoader();

            if (userData?.Org_Id && TestId && testData?.Std_Id && testData?.Batch_Id) {
                const IsAttMarked = await axios.get(
                    `${apiUrl}/getTestResult?OrgId=${userData?.Org_Id}&Std_Id=${testData?.Std_Id}&Test_Id=${TestId}&Batch_Id=${testData?.Batch_Id}`);
                setMarkedStudent(IsAttMarked.data);
                console.log(IsAttMarked.data);
            }

            if (TestId && userData?.Org_Id) {
                const ByID = await axios.get(
                    `${apiUrl}/getTestDetails/${userData.Org_Id}/${TestId}`
                );
                setTestData(ByID.data);
                console.log(ByID.data);

            }

            if (userData?.Org_Id && userData?.Head_Id && testData?.Subject_Id && testData?.Batch_Id) {
                const res = await axios.get(
                    `${apiUrl}/getStudentDetails?OrgId=${userData?.Org_Id}&Subject_Id=${testData?.Subject_Id}&HeadId=${userData?.Head_Id}&Batch_Id=${testData?.Batch_Id}`
                );
                const students = res.data.map(student => ({
                    ...student,
                    Status: "Present",
                    Subject_Name: testData?.Subject_Name,
                    Mobile_For_SMS:student.Mobile_For_SMS,
                    Father_Mobile:student.Father_Mobile,
                    Mother_Mobile:student.Mother_Mobile,
                }));
                const newCheckedStatus = students.reduce((acc, student) => {
                    acc[student.ID] = true;
                    return acc;
                }, {});
                setFacultyStudent(students);
                setCheckedStatus(newCheckedStatus);
            }

            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };


    console.log(facultyStudent);

    const handleCheckAll = (e) => {
        const isChecked = e.target.checked;
        const newCheckedStatus = {};
        const newFacultyStudent = facultyStudent.map(student => {
            newCheckedStatus[student.ID] = isChecked;
            return { ...student, Status: isChecked ? "Present" : "Absent" };
        });
        setCheckedStatus(newCheckedStatus);
        setFacultyStudent(newFacultyStudent);
        setCheckAll(isChecked);
    };

    const handleCheckboxChange = (e, index) => {
        const newFacultyStudent = [...facultyStudent];
        newFacultyStudent[index].Status = e.target.checked ? "Present" : "Absent";
        setFacultyStudent(newFacultyStudent);
        setCheckedStatus({ ...checkedStatus, [newFacultyStudent[index].ID]: e.target.checked });
        setCheckAll(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const updateBill = {
            Student: facultyStudent,
            Test_Date:testData.Test_Date,
            Min_Marks: testData.Min_Marks,
            Max_Marks: testData.Total_Marks,
            Test_Id: TestId,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName
        };

        showLoader();
        axios
            .post(`${apiUrl}/uploadPerformance`, updateBill)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Performance Upload Successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        fetchData();
                        //navigate("/viewTest");
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };

        const handleSubmitResult = (event) => {
        event.preventDefault();

        const updateBill = markedStudent.map((item) => ({
            ...item,
            Min_Marks: testData.Min_Marks,
            Max_Marks: testData.Total_Marks,
            Test_Id: TestId,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName
        }));

        showLoader();
        axios
            .put(`${apiUrl}/updateResult`, updateBill)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Performance Upload Successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        fetchData();
                        //navigate("/viewTest");
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };

    const handleUpdate = (student) => {
        const updateBill = {
            ...student,
            Test_Id: TestId,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName
        };

        showLoader();
        axios
            .put(`${apiUrl}/updatePerformance`, updateBill)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Performance Updated Successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        fetchData();
                        stopEditing();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };


    const startEditing = (index) => {
        setEditingRow(index);
    };

    const stopEditing = () => {
        setEditingRow(null);
    };

    const handleRemarksChange = (e, index) => {
        const newFacultyStudent = [...facultyStudent];
        newFacultyStudent[index].Result_Remark = e.target.value ? e.target.value : '';
        setFacultyStudent(newFacultyStudent);
    };

    const handleMarksObtainedChange = (e, index) => {
        const newFacultyStudent = [...facultyStudent];
        newFacultyStudent[index].Marks_Obtained = e.target.value ? e.target.value : '';
        setFacultyStudent(newFacultyStudent);
    };

    const handleUpdateRemarksChange = (e, index) => {
        const newFacultyStudent = [...markedStudent];
        newFacultyStudent[index].Result_Remark = e.target.value ? e.target.value : '';
        setFacultyStudent(newFacultyStudent);
    };

    const handleUpdateMarksObtainedChange = (e, index) => {
        const newFacultyStudent = [...markedStudent];
        newFacultyStudent[index].Marks_Obtained = e.target.value ? e.target.value : '';
        setFacultyStudent(newFacultyStudent);
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-4">
                        Upload Performance
                    </div>

                    <div className="col-md-8 d-flex justify-content-end align-items-center">
                        <div className="col-md-4">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div>
                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div className="card shadow-none border border-300 my-4" data-component-card="data-component-card">
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">
                                <div className="row mb-2">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Std_Name">Standard</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            id="Std_Name"
                                            name="Std_Name"
                                            disabled
                                            value={testData.Std_Name}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Batch_Name">Batch</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            id="Batch_Name"
                                            name="Batch_Name"
                                            disabled
                                            value={testData.Batch_Name}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Subject_Name">Subject</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            id="Subject_Name"
                                            name="Subject_Name"
                                            disabled
                                            value={testData.Subject_Name}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Test_Date">Date</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            id="Test_Date"
                                            name="Test_Date"
                                            disabled
                                            value={formatInputDate(testData.Test_Date)}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {markedStudent.length === 0  ? (
                                        <>
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Sr No</th>
                                                                <th className="text-center">Student Id</th>
                                                                <th className="text-center">Name</th>
                                               <th className={'text-center'}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={checkAll}
                                                                        onChange={handleCheckAll}
                                                                    />
                                                                    <br />Attendance
                                                                </th>
                                                          
                                                                <th className="text-center" style={{ width: '80px' }}>Marks Obtained</th>
                                                                <th className="text-center">Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {facultyStudent?.length > 0 ? (
                                                                facultyStudent?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="text-center">{index + 1}</td>
                                                                            <td className="text-center">{item.Student_Id}</td>
                                                                            <td className="text-center">{item.Name}</td>
                                                                           <td className={'text-center'}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={checkedStatus[item.ID]}
                                                                                    onChange={(e) => handleCheckboxChange(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td className="text-center p-2">
                                                                                <input type="text" className="form-control form-control-sm"
                                                                                    placeholder="Marks"
                                                                                    value={item.Marks_Obtained || ''}
                                                                                    onChange={(e) => handleMarksObtainedChange(e, index)} />
                                                                            </td>
                                                                            <td className="text-center p-2">
                                                                                <input type="text" className="form-control form-control-sm"
                                                                                    placeholder="Remarks..."
                                                                                    value={item.Result_Remark || ''}
                                                                                    onChange={(e) => handleRemarksChange(e, index)} />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td className="text-center text-danger" colSpan={6}>
                                                                        No Data Found !!
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 text-center mt-3">
                                                <button onClick={handleSubmit} className="btn btn-sm btn-primary">
                                                    Upload Performance
                                                </button>
                                            </div>
                                        </>
                                        ) : markedStudent[0]?.Marks_Inserted === 'No' ? (
                                    
                                        <>
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Sr No</th>
                                                                <th className="text-center">Student Id</th>
                                                                <th className="text-center">Name</th>
                                                                <th className="text-center" style={{ width: '80px' }}>Marks Obtained</th>
                                                                <th className="text-center">Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {markedStudent?.length > 0 ? (
                                                                markedStudent?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="text-center">{index + 1}</td>
                                                                            <td className="text-center">{item.Student_Id}</td>
                                                                            <td className="text-center">{item.Name}</td>
                                                                            <td className="text-center p-2">
                                                                                <input type="text" className="form-control form-control-sm"
                                                                                    placeholder="Marks"
                                                                                    value={item.Marks_Obtained || ''}
                                                                                    onChange={(e) => handleUpdateMarksObtainedChange(e, index)} />
                                                                            </td>
                                                                            <td className="text-center p-2">
                                                                                <input type="text" className="form-control form-control-sm"
                                                                                    placeholder="Remarks..."
                                                                                    value={item.Result_Remark || ''}
                                                                                    onChange={(e) => handleUpdateRemarksChange(e, index)} />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td className="text-center text-danger" colSpan={5}>
                                                                        No Data Found !!
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 text-center mt-3">
                                                <button onClick={handleSubmitResult} className="btn btn-sm btn-primary">
                                                    Insert Result
                                                </button>
                                            </div>
                                      
                                            </>
                                    ): (
                                          <>
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Sr No</th>
                                                                <th className="text-center">Student Id</th>
                                                                <th className="text-center">Name</th>
                                                                <th className="text-center" style={{ width: '80px' }}>Marks Obtained</th>
                                                                <th className="text-center">Remarks</th>
                                                                <th className="text-center"></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {markedStudent?.length > 0 ? (
                                                                markedStudent?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="text-center">{index + 1}</td>
                                                                            <td className="text-center">{item.Student_Id}</td>
                                                                            <td className="text-center">{item.Name}</td>
                                                                            <td className="text-center p-2">
                                                                                <input type="text" className="form-control form-control-sm"
                                                                                    placeholder="Marks"
                                                                                    disabled={editingRow !== index}

                                                                                    value={item.Marks_Obtained || ''}
                                                                                    onChange={(e) => handleUpdateMarksObtainedChange(e, index)} />
                                                                            </td>
                                                                            <td className="text-center p-2">
                                                                                <input type="text" className="form-control form-control-sm"
                                                                                    placeholder="Remarks..."
                                                                                    disabled={editingRow !== index}

                                                                                    value={item.Result_Remark || ''}
                                                                                    onChange={(e) => handleUpdateRemarksChange(e, index)} />
                                                                            </td>
                                                                            <td className="text-center p-2">
                                                                                {editingRow === index ? (
                                                                                    <>
                                                                                        <Link
                                                                                            onClick={() => handleUpdate(item)}
                                                                                            style={{
                                                                                                display: "inline-block",
                                                                                                width: "50px",
                                                                                            }}
                                                                                        >
                                                                                            Save
                                                                                        </Link>
                                                                                        &nbsp;
                                                                                        <Link
                                                                                            onClick={stopEditing}
                                                                                            style={{
                                                                                                display: "inline-block",
                                                                                                width: "50px",
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        >
                                                                                            Cancel
                                                                                        </Link>
                                                                                    </>
                                                                                ) : (
                                                                                    <Link
                                                                                        onClick={() => startEditing(index)}
                                                                                        style={{
                                                                                            display: "inline-block",
                                                                                            width: "50px",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </Link>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td className="text-center text-danger" colSpan={6}>
                                                                        No Data Found !!
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            </>)}

                                </div>


                            </div>
                       
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </div>
    );
};

export default UploadPerformance;
