import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../../../DropDowns/DropDown";
import "jspdf-autotable";
import { formatDate } from "../../../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../../../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../../../Students/AgGridTable";


const Classroom = () => {
    const [table, setTable] = useState(false);
    const [dataTable, setDataTable] = useState(null);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [isModal, setIsModal] = useState(false);
    const [indexValue, setIndex] = useState();
    const [data, setBillData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [errors, setErrors] = useState({});
    const [updateDataId, setId] = useState("");
    const [CategoryOptions, setCategoryOptions] = useState([]);
    const [selectedstatus, setSelectedstatus] = useState("Uncleared");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [StdOptions, setStdOptions] = useState([]);
    const [BatchOptions, setBatchOptions] = useState([]);
    const [Std_Id, setStdId] = useState('');
    const [Batch_ID, setBatchID] = useState('');
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: "",
    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView,
            });
        }
    }, [userData]);

    console.log(updateDataId);

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(`${apiUrl}/getClassroom?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`);
            setBillData(res.data);
            setFilteredData(res.data);
            console.log(res.data);

            setTable(true);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    //Search Box
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (data.length > 0) {
            const newFilteredData = data.filter(
                (row) =>
                    row?.ClassRoom_Name?.toLowerCase().includes(searchTerm)
            );
            setFilteredData(newFilteredData);
        }
    }, [searchTerm, data]);

    async function editBills(data) {
        showLoader();
        setBill({
            ClassRoom_Name: data.ClassRoom_Name
        });
        setEditingItem(data);
        hideLoader();
    }


    useEffect(() => {
        const badgeCellRenderer = (props) => {
            return (
                <span
                    className={`badge badge-phoenix ${props.Status === "Unpaid"
                        ? "badge-phoenix-warning"
                        : props.Status === "Paid"
                            ? "badge-phoenix-success"
                            : "badge-phoenix-primary"
                        }`}
                >
                    {props.Status}
                </span>
            );
        };
        const options = (props) => {

            const { ClassRoom_Id, ClassroomName } = props.data;
            return (
                <div className="dropdown font-sans-serif">
                    <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i
                            className="fas fa-ellipsis-v"
                            style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                    </button>
                    <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                    >
                        <div>

                            <Link
                                onClick={() => {
                                    editBills({
                                        ClassRoom_Name: props.data.ClassroomName
                                    });
                                    setId(ClassRoom_Id);
                                }}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <i
                                    className="fas fa-pen"
                                    style={{ color: "blue", fontSize: "14px" }}
                                ></i>
                                <span> Update</span>
                            </Link>
                            {/* <div className="dropdown-divider"></div> */}
                        </div>

                        <Link
                            onClick={() => {
                                deleteUser(ClassRoom_Id, ClassroomName);
                            }}
                            className="dropdown-item cursor-pointer"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            <i
                                className="fas fa-trash"
                                style={{ color: "red", fontSize: "14px" }}
                            ></i>
                            <span> Delete</span>
                        </Link>
                    </div>
                </div>
            );
        };

        const newAgcolumns = [
            { field: "ClassroomName" },
            {
                headerName: "",
                field: "",
                cellRenderer: options,
                width: 50,
                maxWidth: 50,
            },
        ];
        setAgcolumns(newAgcolumns);
    }, []);

    useEffect(() => {
        if (filteredData.length > 0) {
            const newAgrows = filteredData.map((row, index) => ({
                ClassRoom_Id: row.ClassRoom_Id,
                ClassroomName: row.ClassRoom_Name
            }));

            setAgrows(newAgrows);
        }
    }, [filteredData]);

    const deleteUser = (Id, Name) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id, Name);
            }
        });
    };
    
    async function handleDelete(Id, Name) {
        showLoader();
        const obj = {
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            User_Name: userData?.UserName,
            Name: Name
        };

        try {
            await axios.delete(`${apiUrl}/deleteClassroom/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Classroom record has been deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting Classroom record:", error);
            Swal.fire("Error", "Failed to delete Classroom record.", "error");
        } finally {
            hideLoader();
        }
    }

    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };


    const DownloadPDF = () => {
        // const doc = new jsPDF();
        const doc = new jsPDF();
        doc.text("Classroom", 20, 10);

        const columns = [
            "Sr No",
            'Classroom'
        ];

        const rows = filteredData.map((row, index) => [
            index + 1,
            row.ClassRoom_Name

        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Classroom.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Sr No",
            'Classroom'
        ];

        const rows = [columns];
        filteredData.forEach((row, index) => {
            rows.push([
                index + 1,
                row.ClassRoom_Name
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "School College");

        XLSX.writeFile(workBook, "Classroom.xlsx");
    };

    const showModal = (index) => {
        setIndex(index);
        setIsModal(true);
    };

    const closed = () => {
        setIsModal(false);
        fetchData();
    };

    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true,
        });
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true,
        });
    };


    const [Bill, setBill] = useState({
        ClassRoom_Name: ""
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
      
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addClassroom`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Classroom Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                ClassRoom_Name: ''
                            });
                            setEditingItem(null);
                            fetchData();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                        Swal.fire({
                            title: "Error",
                            text: err.response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        console.error(err);
                        Swal.fire(
                            "Server Timeout",
                            "Server is Busy!!!, Please try again later.",
                            "error"
                        );
                    }
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    const handleUpdate = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateClassroom/${updateDataId}`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Classroom Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                setBill({
                                    ClassRoom_Name: ''
                                });
                                setEditingItem(null);
                                fetchData();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                        Swal.fire({
                            title: "Error",
                            text: err.response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        console.error(err);
                        Swal.fire(
                            "Server Timeout",
                            "Server is Busy!!!, Please try again later.",
                            "error"
                        );
                    }
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-3">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black">{row.ClassRoom_Name}</h5>

                                {/* <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Date :{" "}
                                    <span className="ms-1 text-1100">{formatDate(row?.Date)}</span>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                {/* <p
                                    className="profiledetail lh-1 mb-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    GST No :{" "}
                                    <span className="ms-1 text-1100">{row.Status}</span>
                                </p> */}
                                {/* <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Batch :{" "}
                                    <span className="ms-1 text-1100">{row.Batch_Name}</span>
                                </p> */}
                            </div>
                        </div>
                        {/* <div className="col-md-5">
                            <div className="me-3 flex-1">
                            <span
                                        className={`badge badge-phoenix ${row.Status === "0"
                                            ? "badge-phoenix-warning"
                                            : row.Status === "1"
                                                ? "badge-phoenix-success"
                                                : "badge-phoenix-primary"
                                            }`}
                                    >
                                {row.Status==='0'? 'Inactive' : 'Active'}
                                    </span> 

                            </div>
                        </div> */}

                        <div className="col-md-1">
                            <div className="font-sans-serif">
                                <button
                                    style={{ padding: 0 }}
                                    className="btn btn-link"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i
                                        className="fas fa-ellipsis-v"
                                        style={{ fontSize: "14px", color: "gray" }}
                                    ></i>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <div>

                                        <Link
                                            onClick={() => {
                                                editBills(row);
                                                setId(row.ClassRoom_Id);
                                            }}
                                            className="dropdown-item cursor-pointer"
                                        >
                                            <i
                                                className="fas fa-pen"
                                                style={{ color: "blue", fontSize: "14px" }}
                                            ></i>
                                            <span> Update</span>
                                        </Link>

                                        {/* <div className="dropdown-divider"></div> */}
                                    </div>

                                    <Link
                                        onClick={() => {
                                            deleteUser(row.ClassRoom_Id);
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <i
                                            className="fas fa-trash"
                                            style={{ color: "red", fontSize: "14px" }}
                                        ></i>
                                        <span> Delete</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {design.dataView === "Tile View" && (
                    <div
                        key={index}
                        className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
                    >
                        <div className="card h-100 hover-actions-trigger">
                            <div className="card-body" style={{ padding: "15px" }}>
                                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                    <div
                                        className="row justify-content-between me-1"
                                        style={{ flex: "auto", marginTop: "8px" }}
                                    >
                                        <div className="col-11 pe-1">
                                            <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                                                {row.ClassRoom_Name}
                                            </h6>
                                            {/* <p
                                                className="mb-0 profiledetail lh-1"
                                                style={{ fontSize: "13px" }}
                                            >
                                                Date :{" "}
                                                <span className="ms-1 text-1100">{formatDate(row?.Date)}</span>
                                            </p> */}
                                        </div>
                                        <div className="end-0 col-1 p-0 ">
                                            <button
                                                style={{ padding: 0 }}
                                                className="btn btn-link"
                                                id="dropdownMenuLink"
                                                href="#"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i
                                                    className="fas fa-ellipsis-v"
                                                    style={{ fontSize: "14px", color: "gray" }}
                                                ></i>
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-end py-0"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <div>

                                                    <Link
                                                        onClick={() => {
                                                            editBills(row);
                                                            setId(row.ClassRoom_Id);
                                                        }}
                                                        className="dropdown-item cursor-pointer"
                                                    >
                                                        <i
                                                            className="fas fa-pen"
                                                            style={{ color: "blue", fontSize: "14px" }}
                                                        ></i>
                                                        <span> Update</span>
                                                    </Link>

                                                    {/* <div className="dropdown-divider"></div> */}
                                                </div>

                                                <Link
                                                    onClick={() => {
                                                        deleteUser(row.ClassRoom_Id);
                                                    }}
                                                    className="dropdown-item cursor-pointer"
                                                >
                                                    <i
                                                        className="fas fa-trash"
                                                        style={{ color: "red", fontSize: "14px" }}
                                                    ></i>
                                                    <span> Delete</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="d-flex align-items-center mb-3">
                                    <span
                                        className={`badge badge-phoenix ${row.Status === "0"
                                            ? "badge-phoenix-warning"
                                            : row.Status === "1"
                                                ? "badge-phoenix-success"
                                                : "badge-phoenix-primary"
                                            }`}
                                    >
                                    {row.Status==='0'? 'Inactive' : 'Active'}
                                    </span> </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="content">
          
                <div className="container-fluid">
              
                    <div className="page-content-wrapper">
                        <div className="row justify-content-between">
                            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                                <div className="row">
                                    <div className="col-md-6">
                                    Classroom
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                                        <div className="col-md-5">
                                            <HeadMasterDropDown
                                                label="Branch"
                                                name="Branch"
                                                id="Branch"
                                            />
                                        </div></div>
                                </div>

                            </h4>


                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div
                                    className="card shadow-none border border-300 my-4"
                                    data-component-card="data-component-card"
                                >
                                    <div className="card-body p-0">
                                        <div className="p-4 code-to-copy">
                                            <form onSubmit={editingItem ? handleUpdate : handleSubmit}>
                                                <div className="row">
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" htmlFor="ClassRoom_Name">
                                                       Classroom<span style={{ color: "Red" }}>*</span>
                                                        </label>
                                                        <input
                                                            className="form-control form-control-sm"
                                                            type="text"
                                                            name="ClassRoom_Name"
                                                            id="ClassRoom_Name"
                                                            required
                                                            value={Bill.ClassRoom_Name}
                                                            placeholder="Enter Classroom Name"
                                                            onChange={handleChange}
                                                        />
                                                        <span style={{ color: "Red" }}></span>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mt-3">
                                                        <button type="submit" className="btn btn-sm btn-primary">
                                                            {editingItem ? 'Update' : 'Add'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 text-center mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search..."
                                    style={{
                                        float: "left",
                                        display: "inline-block",
                                        width: "200px",
                                    }}
                                ></input>
                                <Link to={"/add ClassRoom_Name Title"} style={{ float: "right", display: "none" }}>
                                    <button className="btn btn-primary btn-sm text-center">
                                        Add
                                    </button>
                                </Link>
                                <button
                                    type="button"
                                    className="btn bg-200 filterButton collapsed mr-2 d-none"
                                    data-toggle="collapse"
                                    onClick={toggle}
                                    data-target="#navbar"
                                    aria-expanded="false"
                                    aria-controls="navbar"
                                    style={{ float: "right", marginRight: "10px" }}
                                >
                                    <i className="fa fa-filter"></i>
                                </button>
                                <button
                                    className="form-control dropdown-toggle viewButton  mb-2"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                        float: "right",
                                        marginRight: "10px",
                                        width: "auto",
                                        padding: "4px 10px",
                                    }}
                                >
                                    <>
                                        {design.dataView === "Table View" && (
                                            <TableChartOutlinedIcon />
                                        )}
                                    </>
                                    <>
                                        {design.dataView === "List View" && (
                                            <FormatListBulletedIcon />
                                        )}
                                    </>
                                    <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <Link
                                        onClick={(e) => {
                                            setDesign({
                                                ...design,
                                                dataView: "Table View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Table View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <TableChartOutlinedIcon />
                                        <span> Table View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "List View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "List View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <FormatListBulletedIcon />
                                        <span> List View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "Tile View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Tile View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <GridViewIcon />
                                        <span> Tile View</span>
                                    </Link>
                                </div>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to PDF</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadPDF()}
                                        style={{ float: "right", padding: "5px 10px 5px 5px" }}
                                    >
                                        <i
                                            className="fas fa-file-pdf"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to Excel</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadExcel()}
                                        style={{ float: "right", padding: "5px 5px 5px 10px" }}
                                    >
                                        <i
                                            className="fas fa-file-excel"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row">
                            {design.dataView === "Table View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    {Agrows.length > 0 && (
                                        <AgGridTable row={Agrows} column={Agcolumns} />
                                    )}
                                </div>
                            )}
                            {design.dataView === "Tile View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">{displayData}</div>
                                </div>
                            )}
                            {design.dataView === "List View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                            <div className="card h-100 hover-actions-trigger">
                                                <div
                                                    className="card-body"
                                                    style={{ paddingTop: "5px" }}
                                                >
                                                    {displayData}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(design.dataView === "List View" ||
                                design.dataView === "Tile View") && (
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        pageCount={pageCount}
                                        pageRangeDisplayed={1}
                                        marginPagesDisplayed={1}
                                        breakAriaLabels={{
                                            forward: "Jump forward",
                                            backward: "Jump backward",
                                        }}
                                        onPageChange={changePage}
                                        containerClassName={"pagination"}
                                        disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                                        previousLinkClassName={"btn btn-outline-primary me-2"}
                                        nextLinkClassName={"btn btn-outline-primary ms-2"}
                                        activeLinkClassName={"customclass ms-2 me-2"}
                                        pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {/* {isModal && (
                    <UpdateStatus
                        open={isModal}
                        onClose={closed}
                        updateData={data[indexValue]}
                    />
                )} */}
                {loader}
      
        </div>
    );
};

export default Classroom;
