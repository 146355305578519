import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../../DropDowns/GenderDropDown";
import BankDropdoun from "../../DropDowns/BankDropdoun";
import BillCategory from "../../DropDowns/ExCategory";
import YearDropDown from "../../DropDowns/YearDropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { BsPercent } from "react-icons/bs";

const AddUpdateBill = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [errors, setErrors] = useState({});
  const { opration, Sr_No } = useParams();
  const [VendorOptions, setVendorOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);



  const [Bill, setBill] = useState({
    Inv_No: "",
    Order_No: "",
    Inv_Date:"",
    Due_Date:"",
    Dist_Cust_Id: "",
    Name: "",
    Notes: "",
    Inv_GSTType: "GST Invoice",
    Bill_DiscountPercent: "0.00",
    Bill_DiscountAmount: "0.00",
    Bill_Amount: "",
    Add_Charges_Narration: "",
    Add_Charges_Amt: "",
    Net_Bill_Amt: "0.00"
  });

  const [Prod, setProd] = useState([]);
  const [currentProd, setCurrentProd] = useState({
    Prod_Id: "",
    Pro_Name: "",
    Prod_Name: "",
    Prod_Code: "",
    Prod_HSN: "",
    Price: "",
    Qty: "0",
    Total_Amount: "",
    Discount: "0",
    GST_Type: "",
    GST_Percent: "0.00",
    GST_Amt: "0.00",
    Net_Amount: "0.00",
    GST_Net_Amount: "0.00"
  });

  console.log(currentProd);

  useEffect(() => {
    const fetchData = async () => {
      if (userData && userData.Org_Id) {
        try {
          showLoader();

          if (
            userData &&
            userData.Org_Id &&
            userData.Head_Id && userData?.Head_Name
          ) {
            const res = await axios.get(
              `${apiUrl}/getNewInvoiceNumber`
            );
            setBill((prevBill) => ({
              ...prevBill,
              Inv_No: res.data,
            }));
          }

          if (userData && userData.Org_Id) {
            const ven = await axios.get(
              `${apiUrl}/getVendorsDropdown?OrgId=${userData.Org_Id}`
            );

            const getVendors = ven.data.map((category) => ({
              label: category.Company_Name,
              value: category.v_Id,
            }));
            setVendorOptions(getVendors);
            console.log(getVendors);

            const prod = await axios.get(
              `${apiUrl}/getProductCategory?OrgId=${userData.Org_Id}`
            );

            const getprod = prod.data.map((category) => ({
              label: category.Category,
              value: category.Cat_Id,
            }));
            setProductOptions(getprod);
            console.log(getprod);
          }



          hideLoader();
        } catch (error) {
          console.error("Error fetching data:", error);
          hideLoader();
        }
      }
    };

    fetchData();
  }, [userData, opration, Sr_No]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleProdChange = (e) => {
    const { name, value } = e.target;
    setCurrentProd((prevProd) => ({
      ...prevProd,
      [name]: value,
    }));
  };

  const validateProduct = () => {
    const newErrors = {};

    if (!currentProd.Prod_Id) {
      newErrors.Prod_Id = 'Select Product Category';
    }
    if (!currentProd.Prod_Name) {
      newErrors.Prod_Name = 'Enter Product Name';
    }
    if (!currentProd.Price && currentProd.Price !== 0) {
      newErrors.Price = 'Enter Product Price';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const addProduct = () => {
    if (!validateProduct()) {
      return;
    }

    setProd((prevProd) => {
      const updatedProd = [...prevProd, currentProd];
      // Calculate the total bill amount based on GST_Net_Amount
      const totalBillAmount = updatedProd.reduce((sum, prod) => sum + parseFloat(prod.GST_Net_Amount), 0).toFixed(2);

      // Update the bill amount
      setBill((prevBill) => ({
        ...prevBill,
        Bill_Amount: totalBillAmount,
        Net_Bill_Amt: totalBillAmount,
      }));

      return updatedProd;
    });

    // Reset the current product state
    setCurrentProd({
      Prod_Id: "",
      Pro_Name: "",
      Prod_Name: "",
      Prod_Code: "",
      Prod_HSN: "",
      Price: "",
      Qty: "0",
      Total_Amount: "",
      Discount: "0",
      GST_Type: "",
      GST_Percent: "0.00",
      GST_Amt: "0.00",
      Net_Amount: "0.00",
      GST_Net_Amount: "0.00"
    });
  };


  const handleDeleteProduct = (index) => {
    setProd((prevProd) => {
      const updatedProd = [...prevProd];
      updatedProd.splice(index, 1);
     
      const totalBillAmount = updatedProd.reduce((sum, prod) => sum + parseFloat(prod.GST_Net_Amount), 0).toFixed(2);

      setBill((prevBill) => ({
        ...prevBill,
        Bill_Amount: totalBillAmount,
        Net_Bill_Amt: totalBillAmount,
      }));

      return updatedProd;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateBill = {
      ...Bill,
      Product:Prod,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };
    const formdata = new FormData();
    formdata.append("User_Id", updateBill.User_Id);
    formdata.append("Org_Id", updateBill.Org_Id);
    formdata.append("Org_Name", updateBill.Org_Name);
    formdata.append("User_Role", updateBill.User_Role);
    formdata.append("LoginUsername", updateBill.LoginUsername);
    formdata.append("Display_Name", updateBill.Display_Name);
    formdata.append("Head_Id", updateBill.Head_Id);
    formdata.append("Head_Name", updateBill.Head_Name);
    formdata.append("Product", JSON.stringify(updateBill.Product));
    formdata.append("Inv_No", Bill.Inv_No);
    formdata.append("Order_No", Bill.Order_No);
    formdata.append("Inv_Date", Bill.Inv_Date);
    formdata.append("Due_Date", Bill.Due_Date);
    formdata.append("Dist_Cust_Id", Bill.Dist_Cust_Id);
    formdata.append("Name", Bill.Name);
    formdata.append("Notes", Bill.Notes);
    formdata.append("Bill_DiscountPercent", Bill.Bill_DiscountPercent);
    formdata.append("Bill_DiscountAmount", Bill.Bill_DiscountAmount);
    formdata.append("Bill_Amount", Bill.Bill_Amount);
    formdata.append("Add_Charges_Narration", Bill.Add_Charges_Narration);
    formdata.append("Add_Charges_Amt", Bill.Add_Charges_Amt);
    formdata.append("Net_Bill_Amt", Bill.Net_Bill_Amt);
    formdata.append("Inv_GSTType", Bill.Inv_GSTType);
    formdata.append("FileName", Bill.FileName);

    const errors = {};

    if (!Bill.Inv_No) {
      errors.Inv_No = 'Invoice Number is required';
    }
    if (!Bill.Inv_Date) {
      errors.Inv_Date = 'Seletc Invoice Date';
    }
    if (!Bill.Due_Date) {
      errors.Due_Date = 'Seletc Due Date';
    }
    if (!Bill.Dist_Cust_Id) {
      errors.Dist_Cust_Id = 'Seletc Vendor';
    }

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addInventoryBill`, formdata)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Bill Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/viewInventoryBills");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };



  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33,
    }),
  };

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            Bill Entry
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5 d-none">
              <HeadMasterDropDown
                label="Branch"
                name="Branch"
                id="Branch"


              />

            </div></div>
        </div>

      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Category">
                        Vendor Name<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Category"
                        id="Category"
                        options={VendorOptions}
                        value={
                          Bill.Dist_Cust_Id
                            ? {
                              value: Bill.Dist_Cust_Id,
                              label: Bill.Name,
                            }
                            : null
                        }
                        onChange={(e) => {
                          setBill({
                            ...Bill,
                            Dist_Cust_Id: e.value,
                            Name: e.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}>{errors.Dist_Cust_Id}</span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Order No">
                        Order No
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Order_No"
                        id="Order_No"
                        value={Bill.Order_No}
                        placeholder="Enter Order No"
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Inv_No">
                        Invoice No<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Inv_No"
                        id="Inv_No"
                        disabled
                        value={Bill.Inv_No}
                        placeholder="Enter Bill No"
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}>{errors.Inv_No}</span>
                    </div>

                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Inv_Date">
                        Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        name="Inv_Date"
                        id="Inv_Date"
                        value={
                          Bill.Inv_Date
                            ? dayjs(Bill.Inv_Date).format("YYYY-MM-DD")
                            : ""
                        }
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}>{errors.Inv_Date}</span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Due_Date">
                        Due Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        name="Due_Date"
                        id="Due_Date"
                        value={
                          Bill.Due_Date
                            ? dayjs(Bill.Due_Date).format("YYYY-MM-DD")
                            : ""
                        }
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}>{errors.Due_Date}</span>
                    </div>


                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="FileName">
                        Upload File
                      </label>
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        name="FileName"
                        accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf, .doc, .xls, .xlsx"
                        id="FileName"
                        onChange={(e) =>
                          setBill({
                            ...Bill,
                            FileName: e.target.files[0],
                          })
                        }
                      />

                    </div>
                    <h5>Product Details</h5>
                    <hr className="mb-2" />
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Prod_Id">
                        Product Category<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Prod_Id"
                        id="Prod_Id"
                        options={productOptions}
                        value={
                          currentProd.Prod_Id
                            ? {
                              value: currentProd.Prod_Id,
                              label: currentProd.Pro_Name,
                            }
                            : null
                        }
                        onChange={(e) => {
                          setCurrentProd({
                            ...currentProd,
                            Prod_Id: e.value,
                            Pro_Name: e.label,
                          });
                        }}
                      />
                      {errors.Prod_Id && <span style={{ color: "Red" }}>{errors.Prod_Id}</span>}
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Prod_Name">
                        Product Name<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Prod_Name"
                        value={currentProd.Prod_Name}
                        id="Prod_Name"
                        placeholder="Enter Product Name"
                        onChange={handleProdChange}
                      />
                      {errors.Prod_Name && <span style={{ color: "Red" }}>{errors.Prod_Name}</span>}
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Prod_Code">
                        Product Code
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Prod_Code"
                        value={currentProd.Prod_Code}
                        id="Prod_Code"
                        placeholder="Enter Product Code"
                        onChange={handleProdChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Prod_HSNProd_HSN">
                        HSN/Code
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Prod_HSN"
                        value={currentProd.Prod_HSN}
                        id="Prod_HSN"
                        placeholder="Enter HSN/Code"
                        onChange={handleProdChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="Price">
                        MRP Rate
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Price"
                        value={currentProd.Price}
                        id="Price"
                        placeholder="Enter Price"
                        onChange={(e) => {
                          const newPrice = e.target.value;
                          const qt = parseFloat(currentProd.Qty);
                          const price = parseFloat(newPrice);
                          const discount = parseFloat(currentProd.Discount);
                          const grossAmt = ((price * qt) - discount).toFixed(2);
                          setCurrentProd({
                            ...currentProd,
                            Price: newPrice,
                            Total_Amount: grossAmt || "",
                            GST_Net_Amount: grossAmt || "",

                          });
                        }}
                      />
                      {errors.Price && <span style={{ color: "Red" }}>{errors.Price}</span>}
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="Qty">
                        Quantity
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Qty"
                        value={currentProd.Qty}
                        id="Qty"
                        placeholder="Enter Quantity"
                        onChange={(e) => {
                          const qt = e.target.value;
                          const price = parseFloat(currentProd.Price);
                          const discount = parseFloat(currentProd.Discount);
                          const grossAmt = ((price * parseFloat(qt)) - discount).toFixed(2);
                          setCurrentProd({
                            ...currentProd,
                            Qty: qt,
                            Total_Amount: grossAmt || "",
                            Net_Amount: grossAmt || "",
                            GST_Net_Amount: grossAmt || "",

                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="Total_Amount">
                        Gross Amount
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        disabled
                        name="Total_Amount"
                        value={currentProd.Total_Amount}
                        id="Total_Amount"
                        onChange={handleProdChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="Discount">
                        Discount
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Discount"
                        value={currentProd.Discount}
                        id="Discount"
                        placeholder="Enter Discount"
                        onChange={(e) => {
                          const discount = e.target.value;
                          const grossAmt = parseFloat(currentProd.Total_Amount);
                          const discountedAmt = (grossAmt - parseFloat(discount)).toFixed(2);
                          setCurrentProd({
                            ...currentProd,
                            Discount: discount,
                            Net_Amount: discountedAmt || "",
                            GST_Net_Amount: discountedAmt || "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="Net_Amount">
                        Net Amount
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Net_Amount"
                        disabled
                        value={currentProd.Net_Amount}
                        id="Net_Amount"
                        onChange={handleProdChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="GST_Percent">
                        GST Category
                      </label>
                      <select
                        className="form-select form-select-sm"
                        type="text"
                        name="GST_Percent"
                        value={currentProd.GST_Percent}
                        id="GST_Percent"
                        onChange={(e) => {
                          const gst = e.target.value;
                          const gstAmt = (parseFloat(currentProd.Net_Amount) * (parseFloat(gst) / 100)).toFixed(2);
                          const gstNetAmt = (parseFloat(currentProd.Net_Amount) + parseFloat(gstAmt)).toFixed(2);
                          setCurrentProd({
                            ...currentProd,
                            GST_Percent: gst,
                            GST_Amt: gstAmt,
                            GST_Net_Amount: gstNetAmt
                          })
                        }}
                      >
                        <option value={"0.00"}>0%</option>
                        <option value={"5.00"}>5%</option>
                        <option value={"12.00"}>12%</option>
                        <option value={"18.00"}>18%</option>
                        <option value={"28.00"}>28%</option>
                      </select>
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={addProduct}>
                        Add
                      </button>
                    </div>
                    <div className={Prod.length > 0 ? "col-md-12 mt-3" : 'd-none'}>
                      <table cellspacing="0" align="center" rules="all" className="display table table-hover table-bordered basic-datatables" border="1" id="ContentPlaceHolder1_gvProducts" style={{ borderCollapse: "collapse", border: "1px solid rgba(33, 33, 33, 0.1) !important" }}>
                        <thead>
                          <tr className="text-center">
                            <th scope="col">#</th>
                            <th align="center" scope="col">Name</th>
                            <th align="center" scope="col">Rate</th>
                            <th align="center" scope="col">Qty</th>
                            <th align="center" scope="col">Gross Total</th>
                            <th align="center" scope="col">Discount</th>
                            <th align="center" scope="col">GST</th>
                            <th align="center" scope="col">GST Amount</th>
                            <th align="center" scope="col">Total Amount</th>
                            <th align="center" scope="col">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Prod.map((product, index) => (
                            <tr className="text-center" key={index}>
                              <td>
                                <span>{index + 1}</span>
                              </td>
                              <td>
                                <span className="f-12">{product.Prod_Name}</span>
                              </td>
                              <td>
                                <span className="f-12">{product.Price}</span>
                              </td>
                              <td>
                                <span>{product.Qty}</span>
                              </td>
                              <td>
                                <span>{product.Total_Amount}</span>
                              </td>
                              <td>
                                <span>{product.Discount}</span>
                              </td>
                              <td>
                                <span>{product.GST_Percent}</span>
                              </td>
                              <td>
                                <span>{product.GST_Amt}</span>
                              </td>
                              <td>
                                <span>{product.GST_Net_Amount}</span>
                              </td>
                              <td>
                                <a className="btn btn-md btn-outline-danger hover-show delete-button btn-sm" onClick={() => handleDeleteProduct(index)}>
                                  <i class="fas fa-trash"></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>

                    <h5>Bill Discount Details</h5>
                    <hr className="mb-2" />
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Bill_Amount">
                        Total Amount
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        disabled
                        name="Bill_Amount"
                        value={Bill.Bill_Amount}
                        id="Bill_Amount"
                        placeholder="Enter Total Amount"
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Bill_DiscountPercent">
                        Discount in %
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Bill_DiscountPercent"
                        value={Bill.Bill_DiscountPercent}
                        id="Bill_DiscountPercent"
                        placeholder="Enter Discount in %"
                        onChange={(e) => {
                          const BPercent = parseFloat(e.target.value);
                          const billAmount = parseFloat(Bill.Bill_Amount);
                          const discountAmount = (billAmount * BPercent / 100).toFixed(2);
                          const netBillAmount = (billAmount - discountAmount).toFixed(2);
                          setBill({
                            ...Bill,
                            Bill_DiscountPercent: e.target.value,
                            Bill_DiscountAmount: discountAmount,
                            Net_Bill_Amt: netBillAmount,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Bill_DiscountAmount">
                        Discount Amount
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        disabled
                        name="Bill_DiscountAmount"
                        value={Bill.Bill_DiscountAmount}
                        id="Bill_DiscountAmount"
                        placeholder="Enter Discount Amount"
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Net_Bill_Amt">
                        Gross Bill Amount
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        name="Net_Bill_Amt"
                        value={Bill.Net_Bill_Amt}
                        id="Net_Bill_Amt"
                        disabled
                        onChange={handleChange}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Notes">
                        Remarks
                      </label>
                      <textarea
                        className={`form-control form-control-sm`}
                        type="text"
                        name="Notes"
                        id="Notes"
                        rows={2}
                        value={Bill.Notes}
                        placeholder="Remarks..."
                        onChange={handleChange}
                      />
                    </div>
                    {Prod.length > 0 && (<div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Submit
                      </button>
                    </div>)}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div >
    </div >
  );
};

export default AddUpdateBill;


