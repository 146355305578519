import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { apiUrl } from "../../../Components/apiConfig";
import { useUser } from "../../../Components/UserContext";
import useFullPageLoader from "../../../Components/useFullPageLoader";


const MessageLog = ({ Sr_No, formSubmitted  }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [data, setMessageData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [userData, Sr_No, formSubmitted ]);

  const fetchData = async () => {
    if (userData && userData.Org_Id && Sr_No) {
      try {
        showLoader();
        const result = await axios.get(
          `${apiUrl}/getMessageLog?OrgId=${userData?.Org_Id}&ID=${Sr_No}`
        );
        setMessageData(result.data);
        //console.log(result.data);
        hideLoader();
      } catch (error) {
        console.error("Error fetching headmaster data:", error);
        hideLoader();
      }
    }
  };


  const columns = [
    {
      name: "Message Remarks :",
      cell: (row) => (
          <div className="col-md-12" align="left">
            <p style={{ fontFamily: 'Sans-Serif', fontSize: '12px', marginTop:'7px', marginBottom:"7px" }}>
              {row.SMS}
            </p>
            <p style={{ fontSize: '11px', marginBottom: '5px' }}>
              <label style={{ fontWeight: 'lighter', fontSize: '11px' }}>Mob.No :{" "}</label>
              {row.Mobile} &nbsp;
              <label style={{ fontWeight: 'lighter', fontSize: '11px' }}>Delivered On :{" "}</label>
              {row.Date}
            </p>
          </div>
       
      ),
    }
  ];

  return (
    <div>

      <div className="card-body" style={{ paddingTop: "5px" }}>
        <div className="row justify-content-center mt-2">


        </div>
      </div>

      <div className="table-responsive">
        <DataTable
          columns={columns}
          data={data}
          pagination
        />
      </div>
      {loader}
    </div>
  );
};
export default MessageLog;
