import React, { useEffect } from "react";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import { apiUrl } from "./apiConfig";
import useFullPageLoader from "./useFullPageLoader";

const SwitchBranch = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { setData } = useUser();
  const { setFilterData } = useUser();
  const [userData, setUserData] = useState(null);
  const [branchData, setBranchData] = useState(null);
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  useEffect(() => {
    showLoader();
    axios.get(`${apiUrl}/dashboard`).then((res) => {
      if (res.data.Status === "Success") {
        const userData = {
          UserId: res.data.UserId,
          Role: res.data.Role,
          First_Name: res.data.First_Name,
          Middle_Name: res.data.Middle_Name,
          Last_Name: res.data.Last_Name,
          UserName: res.data.Display_Name,
          Photo: res.data.Photo,
          Head_Name: res.data.Head_Name,
          Head_Id: res.data.Head_Id,
          Subhead_Id: res.data.Subhead_Id,
          Subhead_Name: res.data.Subhead_Name,
          Org_Name: res.data.Name,
          Web_Logo: res.data.Web_Logo,
          Email: res.data.Email,
          Org_Id: res.data.Org_Id,
          Username: res.data.Username,
          Password: res.data.Password,
        };

        setUserData(userData);
       // console.log(userData);
        //setData(userData);
        //console.log(res.data);

        axios
          .get(`${apiUrl}/getHeadMaster?OrgId=${userData.Org_Id}`)
          .then((res) => {
            setBranchData(res.data);
            //console.log(res.data);
          });

        hideLoader();
      } else {
        navigate("/login");
      }
    });
  }, []);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    axios
      .get(`${apiUrl}/logout`)
      .then((res) => {
        setData(null);
        setUserData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  const updateAndStoreUserData = async (updatedUserData) => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/switchBranch?username=${userData?.Username}&Org_Id=${userData.Org_Id}&Head_Id=${updatedUserData.Head_Id}&Head_Name=${updatedUserData.Head_Name}`
    );
    if (result.data.length > 0) {
      setData(updatedUserData);
      setFilterData({
        filterToggle: false,
        dataView: "Table View",
      });
      navigate("/");
    } else {
      navigate("/login");
    }
      hideLoader();
  };

  return (
    <div>
      <style>
        {`
        .custom-card {
  transition: box-shadow 0.3s;
}

.custom-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
        `}
      </style>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <nav className="navbar navbar-top navbar-expand-lg" id="navbarTopNew">
            <div className="navbar-logo">
              <button
                className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToplCollapse"
                aria-controls="navbarToplCollapse"
                aria-expanded="false"
                aria-label="Toggle Navigation"
              >
                <span className="navbar-toggle-icon">
                  <span className="toggle-line"></span>
                </span>
              </button>
              <Link className="navbar-brand me-1 me-sm-3" to="/">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                  <img
                      id="logoimg"
                      className="brand-logo"
                      src={`/CustomerLogo/${userData?.Web_Logo}`}
                      style={{ height: '57px', width: '236px' }}
                    />
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="collapse navbar-collapse navbar-top-collapse order-1 order-lg-0 justify-content-center"
              id="navbarToplCollapse"
            >
              {/* nav content*/}
            </div>
            <ul className="navbar-nav navbar-nav-icons flex-row">
              {/* <li className="nav-item">
                <div className="theme-control-toggle fa-icon-wait px-2">
                  <input
                    className="form-check-input ms-0 theme-control-toggle-input"
                    id="themeControlToggle"
                    type="checkbox"
                    data-theme-control="phoenixTheme"
                    value="dark"
                  />
                  <label
                    className="mb-0 theme-control-toggle-label theme-control-toggle-light"
                    for="themeControlToggle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    aria-label="Switch theme"
                    data-bs-original-title="Switch theme"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-moon icon"
                    >
                      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                    </svg>
                  </label>
                  <label
                    className="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                    for="themeControlToggle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    aria-label="Switch theme"
                    data-bs-original-title="Switch theme"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-sun icon"
                    >
                      <circle cx="12" cy="12" r="5"></circle>
                      <line x1="12" y1="1" x2="12" y2="3"></line>
                      <line x1="12" y1="21" x2="12" y2="23"></line>
                      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                      <line x1="1" y1="12" x2="3" y2="12"></line>
                      <line x1="21" y1="12" x2="23" y2="12"></line>
                      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                    </svg>
                  </label>
                </div>
              </li> */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link lh-1 pe-0"
                  id="navbarDropdownUser"
                  href="#!"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-l ">
                    <img
                      className="rounded-circle "
                      src="/assets/img/team/avatar.png"
                      alt="avatar"
                    />
                  </div>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 mt-1 dropdown-profile shadow border border-300"
                  aria-labelledby="navbarDropdownUser"
                >
                  <div className="card position-relative border-0">
                    <div className="card-body p-0">
                      <div className="text-center pt-4 pb-3">
                        <div className="avatar avatar-xl ">
                          <img
                            className="rounded-circle "
                            src="/assets/img/team/avatar.png"
                            alt=""
                          />
                        </div>
                        <h6 className="mt-2 text-black">
                          {userData?.UserName}
                        </h6>
                      </div>
                    </div>

                    <div className="card-footer p-0 border-top">
                      <div className="px-3 pt-3">
                        <Link
                          className="btn btn-phoenix-secondary d-flex flex-center w-100"
                          onClick={handleLogout}
                          style={{cursor:"pointer"}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-log-out me-2"
                          >
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                            <polyline points="16 17 21 12 16 7"></polyline>
                            <line x1="21" y1="12" x2="9" y2="12"></line>
                          </svg>
                          Sign out
                        </Link>
                      </div>
                      <div className="my-2 text-center fw-bold fs--2 text-600">
                        <Link className="text-600 me-1" to="/">
                          Change Password
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </main>
      <div className="content">
        <Card>
          <Card.Header as="h4">Branches</Card.Header>
          <Card.Body className="p-3">
            <div className="row mb-3 gy-6">
              {branchData &&
                branchData.map((branch) => (
                  <div
                    className="col-12 col-md-6 col-lg-4"
                    key={branch.Head_Id}
                  >
                    <Link
                      onClick={() => {
                        const updatedUserData = {
                          ...userData,
                          Head_Id: branch.Head_Id,
                          Head_Name: branch.Head_Name,
                        };

                        updateAndStoreUserData(updatedUserData);
                      }}
                      style={{ textDecoration: "none", color: "#757575" }}
                    >
                      <div className="card border border-300 h-100 w-100 mt-1 overflow-hidden custom-card">
                        <div className="card-body px-3 px-md-4 position-relative">
                          <div className="d-flex mb-1">
                            <i
                              className="fa fa-building text-primary-500"
                              style={{ fontSize: "16px",marginTop:"3px" }}
                            ></i>
                            <div className="ms-2 ms-md-3">
                              <div className="d-flex align-items-center mb-2">
                                <span
                                  className="text-800 mb-0 text-uppercase"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {branch.Head_Name}
                                </span>
                              </div>
                              {branch.Mobile && (
                                <div className="d-flex align-items-start mb-2">
                                  <i className="fa fa-phone text-primary-500"></i>
                                  <div className="ms-2 ms-md-3">
                                    <div className="d-flex align-items-center">
                                      <span
                                        className="text-800 fs--1 mb-0"
                                        style={{ fontWeight: "500" }}
                                      >
                                        {branch.Mobile}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="d-flex align-items-start mb-2">
                                <i
                                  className="fa fa-location-dot text-primary-500 me-2"
                                  style={{ fontSize: "16px",marginTop:"5px" }}
                                ></i>
                                <div>
                                  <span
                                    className="text-800 fs--1 mb-0"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {branch.Head_Address}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </Card.Body>
        </Card>
        {loader}
      </div>
    </div>
  );
};

export default SwitchBranch;
