import React, { useState } from "react";
import TeacherSalary from "./TeacherSalary";
import ViewSalary from "./ViewSalary";
import GenrateSalary from "./GenrateSalary";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const SalaryMaster = ({ StaffName }) => {
  const [view, setView] = useState("setSalary"); // "setSalary", "viewSalary", or "genrateSalary"
  const { opration, Sr_No } = useParams();

  const handleViewSalary = () => {
    setView("viewSalary");
  };

  const handleGenrateSalary = () => {
    setView("genrateSalary");
  };

  const handleSetSalary = () => {
    setView("setSalary");
  };

  return (
    <div className="page-content-wrapper">
      <div className="row">
        <h5 className="col-md-6 mt-3">Staff Salary Structure</h5>
        <div className="col-md-6 d-flex justify-content-end align-items-center">
          <div className="col-md-5">
            <button
              className="btn btn-primary btn-sm text-center"
              onClick={view === "viewSalary" ? handleSetSalary : handleViewSalary}
              style={{ float: "right" }}
            >
              {view === "viewSalary" ? "Set Salary" : "View Salary"}
            </button>
          </div>
          <div className="col-md-5">
            <button
              className="btn btn-primary btn-sm text-center"
              onClick={view === "genrateSalary" ? handleSetSalary : handleGenrateSalary}
              style={{ float: "right" }}
            >
              {view === "genrateSalary" ? "Set Salary" : "Generate Salary"}
            </button>
          </div>
        </div>
      </div>
      {view === "viewSalary" && <ViewSalary StaffName={StaffName} />}
      {view === "genrateSalary" && <GenrateSalary StaffName={StaffName} />}
      {view === "setSalary" && <TeacherSalary StaffName={StaffName} />}
    </div>
  );
};

export default SalaryMaster;
