import React, { useEffect, useState } from "react";
import $ from "jquery";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { AiFillMinusCircle } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../DropDowns/DropDown";
import "jspdf-autotable";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import { formatDate } from "../../Components/dateFormate";
import dayjs from "dayjs";
import "../Students/toggle.css";
// import UpdateVoucher from "./UpdateBillModal";

const Expense = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
    const [toDate, setToDate] = useState(dayjs().endOf("month"));
    const [selectedstatus, setSelectedstatus] = useState("");
    const [data, setExpnseData] = useState([]);
    const [vouData, setVouData] = useState([]);
    const [billData, setbillData] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: "",
    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            Load();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView,
            });
        }
    }, [userData]);

    async function Load() {
        showLoader();
        const result = await axios.get(`${apiUrl}/getSumOfBillVoucherAmount?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}&fromDate=${fromDate.format("YYYY-MM-DD")}&toDate=${toDate.format(
            "YYYY-MM-DD"
        )}&Status=${selectedstatus}`);
        if (result.data.length === 0) {
            setExpnseData([]);
            hideLoader();
        } else {
            setExpnseData(result.data);
            console.log(result.data);
            hideLoader();
        }
        return result;
    }

    async function innerDataLoad(date) {
        debugger
        showLoader();
        const expResult = await axios.get(
            `${apiUrl}/getVoucherWithFilter?OrgId=${
              userData.Org_Id
            }&fromDate=${fromDate.format("YYYY-MM-DD")}&toDate=${toDate.format(
              "YYYY-MM-DD"
            )}&Status=${selectedstatus}&CategoryID=${""}&Head_Id=${
              userData?.Head_Id
            }`
          );
        if (expResult.data.length === 0) {
            setVouData([]);
        } else {
            console.log(expResult.data);
            setVouData(expResult.data);
        }

        const result = await axios.get(
            `${apiUrl}/getBillWithFilter?OrgId=${userData.Org_Id
            }&fromDate=${fromDate.format("YYYY-MM-DD")}&toDate=${toDate.format(
              "YYYY-MM-DD"
            )}&Status=${selectedstatus}&Head_Id=${userData?.Head_Id
            }`
          );
        if (result.data.length === 0) {
            setbillData([]);
        } else {
            console.log(result.data);
            setbillData(result.data);
        }

        hideLoader();
    }

    useEffect(() => {
        if (userData) {
            Load();
        }
    }, [userData]);

    const FilterClick = () => {
        Load();
        innerTable("");
    }

    const [expanded, setExpanded] = useState(null);
    const innerTable = (date) => {

        if (expanded === date) {

            setExpanded(null);
            // If the clicked item is already expanded, collapse it
        } else {
            // Expand the clicked item and collapse the previously expanded item
            setExpanded(date);
            innerDataLoad(date);
        }
    };


    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true,
        });
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true,
        });
    };

    return (
        <div className="content">
            <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
                <div className="container-fluid">
                    {/* Sidebar */}
                    <div id="sidebar-wrapper">
                        <div className="row g-4">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div
                                    className="card shadow-none border border-300 my-2"
                                    data-component-card="data-component-card"
                                >
                                    <div className="row justify-content-center d-flex p-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Branch">
                                                        Branch
                                                    </label>
                                                    <HeadMasterDropDown
                                                        label="Branch"
                                                        name="Branch"
                                                        id="Branch"
                                                    //onChange={(e) => setBranch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        From Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={fromDate.format("YYYY-MM-DD")}
                                                        onChange={(date) =>
                                                            setFromDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        To Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={toDate.format("YYYY-MM-DD")}
                                                        onChange={(date) =>
                                                            setToDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Status">
                                                        Status :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Status"
                                                        name="Status"
                                                        onChange={(e) => setSelectedstatus(e.target.value)}
                                                    >
                                                        <option value="">All</option>
                                                        <option value="Paid">Paid</option>
                                                        <option value="Unpaid">Unpaid</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row justify-content-center p-2">
                                        <div className="col-md-2 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm text-center"
                                                onClick={FilterClick}
                                            >
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="row justify-content-between">
                            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                            <div className="row">
                        <div className="col-md-6">
                        Expense Summary 
                        </div>

                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                            <div className="col-md-5">
                                <HeadMasterDropDown
                                    label="Branch"
                                    name="Branch"
                                    id="Branch"


                                />

                            </div></div>
                    </div>
                            </h4>
                            <div className="col-md-12 text-center mt-3">

                                <button
                                    type="button"
                                    className="btn bg-200 filterButton collapsed mr-2"
                                    data-toggle="collapse"
                                    onClick={toggle}
                                    data-target="#navbar"
                                    aria-expanded="false"
                                    aria-controls="navbar"
                                    style={{ float: "right", marginRight: "10px" }}
                                >
                                    <i className="fa fa-filter"></i>
                                </button>





                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body">
                                <div className=" table-responsive" style={{ minHeight: "220px" }}>
                                    <table id="basic-datatable" className="table table-striped dt-responsive nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                                <th className="text-center" style={{ padding: "10px 5px" }}>Total</th>
                                                <th className="text-center" style={{ padding: "10px 5px" }}>Bill</th>
                                                <th className="text-center" style={{ padding: "10px 5px" }}>Voucher</th>
                                                <th className="text-center" style={{ padding: "10px 5px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data?.length > 0 ? (
                                                data.map((item, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Total}</td>
                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Bill}</td>
                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Voucher}</td>
                                                            <td className="text-center" style={{ padding: "10px 5px" }}>
                                                                <a
                                                                    class="text-dark"
                                                                    data-bs-toggle="collapse"
                                                                    href={`#todayTasks${index}`}
                                                                    onClick={() => innerTable(item.Head_Id)}
                                                                    role="button"
                                                                    aria-expanded="false"
                                                                    aria-controls={`todayTasks${index}`}
                                                                >
                                                                    <i>{expanded ===  item.Head_Id ?  <AiFillMinusCircle /> : <AiFillPlusCircle />}</i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr className={expanded === item.Head_Id ? "collapse show" : "collapse"} id={`todayTasks${index}`}>
                                                            <td colSpan={6}>

                                                                <h4 style={{ marginTop: "10px" }}>Bills</h4>
                                                                <table id="basic-datatable" className="table table-bordered dt-responsive nowrap w-100">
                                                                    <thead className="table-secondary">
                                                                        <tr>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Title</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Description</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Amount</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Payment Date</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {billData && billData.length > 0 ? (
                                                                            billData.map((item, index) => (
                                                                                <tr>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Title}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Description}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Amount}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{formatDate(item.Payment_Date)}</td>
                                                                                    <td className="text-right" style={{ padding: "10px 5px" }}>{item.Status}</td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr >
                                                                                <td className="text-center text-danger"
                                                                                    colSpan={7}>No Records Found!!</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>

                                                                <h4>Vouchers</h4>
                                                                <table id="basic-datatable" className="table table-bordered dt-responsive nowrap w-100">
                                                                    <thead className="table-secondary">
                                                                        <tr>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Receiver Name</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Particulars</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Amount</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Voucher Date</th>
                                                                            <th className="text-center" style={{ padding: "10px 5px" }}>Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {vouData && vouData.length > 0 ? (
                                                                            vouData.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Receiver_Name}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Particulars}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Amount}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{formatDate(item.Voucher_Date)}</td>
                                                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Status}</td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr >
                                                                                <td className="text-center text-danger"
                                                                                    colSpan={9}>No Records Found!!</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <tr >
                                                    <td className="text-center text-danger"
                                                        colSpan={5}>No Records Found!!</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {isModal && (
          <UpdateVoucher
            open={isModal}
            onClose={closed}
            updateData={data[indexValue]}
          />
        )} */}
                {loader}
            </div>
        </div>
    );
};

export default Expense;
