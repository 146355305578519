import React, { useEffect } from "react";
import Switch from "react-switch";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import Swal from "sweetalert2";
import WhatsappModal from './WhatsappModal';
import PaymentGatewayModal from './PaymentGatewayModal';
import BankAccountsModal from './BankAccountsModal';
import UPISettingModal from './UPISettingModal';
import SocialMediaModal from './SocialMediaModal';





const SettingPage = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState([]);
    const [whatsappData, setWhatData] = useState({});
    const [pGData, setPGData] = useState({});
    const [WtStatus, setWtStatus] = useState();
    const [isModal, setIsModal] = useState(false);
    const [isModal1, setIsModal1] = useState(false);
    const [isModal2, setIsModal2] = useState(false);
    const [isModal3, setIsModal3] = useState(false);
    const [isModal4, setIsModal4] = useState(false);





    const showModal = () => {
        setIsModal(true);
    };
    const closed = () => {
        setIsModal(false);
        loadCustomers();
    };

    const showModal1 = () => {
        setIsModal1(true);
    };
    const closed1 = () => {
        setIsModal1(false);
        loadPaymentGateway();
    };

    const showModa2 = () => {
        setIsModal2(true);
    };
    const closed2 = () => {
        setIsModal2(false);
    };


    const showModa3 = () => {
        setIsModal3(true);
    };
    const closed3 = () => {
        setIsModal3(false);
    };

    const showModa4 = () => {
        setIsModal4(true);
    };
    const closed4 = () => {
        setIsModal4(false);
    };

    const loadCustomers = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/bindWhatsappDetails?OrgId=${userData.Org_Id}`
            );
            setData(result.data.templates);
            setWhatData(result.data);
            setWtStatus(result.data.status);

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    const loadPaymentGateway = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/bindPaymentGatewayDetails/${userData.Org_Id}`
            );
            setPGData(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };



    useEffect(() => {
        if (userData && userData.Org_Id) {
            loadCustomers();
            loadPaymentGateway();
        }
    }, [userData])

    const handleCheckboxChange = (index) => {
        const newData = [...data];
        newData[index].Status = !newData[index].Status === true ? 1 : 0;
        setData(newData);
    };

    const handleInputChange = (index, event) => {
        const newData = [...data];
        newData[index].Template = event.target.value;
        setData(newData);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const updatedData = data.map(obj => ({
            ...obj,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        }));

        showLoader();
        axios
            .put(`${apiUrl}/updateWhatsappTemplate`, updatedData)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "All template has been updated successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect using React Router
                        // navigate("/viewTask");
                        //window.location.reload();
                        loadCustomers();
                        loadPaymentGateway();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                hideLoader();
            });
    }

    const handleSwichWhtasAppSubmit = async (status) => {
        const updatedData = {
            Status: status ? 1 : 0,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            Username: userData.Username,
            Name: userData.Org_Name,
            UserName: userData.UserName
        };

        showLoader();
        try {
            await axios.put(`${apiUrl}/enableDisableWpSetting`, updatedData);
            Swal.fire({
                title: "Success!",
                text: "WhatsApp settings have been updated successfully!!",
                icon: "success",
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    loadCustomers();
                    if(status===true){
                        showModal();
                    }
                }
            });
        } catch (err) {
            console.error(err);
        } finally {
            hideLoader();
        }
    };


    const handleSwichPGSubmit = async (Status) => {
        const updatedData = {
            Status: Status ? 1 : 0,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            Username: userData.Username,
            Name: userData.Org_Name,
            UserName: userData.UserName
        };

        showLoader();
        try {
            await axios.put(`${apiUrl}/enableDisablePaymentGateway`, updatedData);
            Swal.fire({
                title: "Success!",
                text: "Payment Gateway settings have been updated successfully!!",
                icon: "success",
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    loadCustomers();
                    if(Status===true){
                        showModal1();
                    }
                }
            });
        } catch (err) {
            console.error(err);
        } finally {
            hideLoader();
        }
    };



    const handleWtSwitchChange = (checked) => {
        setWtStatus(checked);
        handleSwichWhtasAppSubmit(checked);
    };

    return (
        <div className="content">
            <Card>
                <Card.Header as="h4">Settings</Card.Header>
                <Card.Body style={{ padding: "20px" }}>
                    <div className="row g-4">
                        <div className="col-12 col-xl-12 order-1 order-xl-0">
                            <div
                                className="card shadow-none border border-300 my-4"
                                data-component-card="data-component-card"
                            >
                                <div className="card-body p-0">
                                    <div className="p-4 code-to-copy">
                                        <div className="row">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h5>Whatsapp Messages</h5>
                                                    <p>
                                                        Configure Whatsapp to send whatsapp messages to students
                                                        and their parents when lecture schedules, attendance,
                                                        fees payment.
                                                    </p>
                                                </div>
                                                <div className="col-md-2 d-flex justify-content-end align-items-center">
                                                    <Switch
                                                        checked={WtStatus}
                                                        onChange={(checked) => handleWtSwitchChange(checked)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                                    <div
                                                        className="card shadow-none border border-300 my-4"
                                                        data-component-card="data-component-card"
                                                    >
                                                        <div className="card-body p-0">
                                                            <div className="p-4 code-to-copy">
                                                                <form onSubmit={submitHandler}>
                                                                    <div className="row">
                                                                        {data.length !== 0 ? (
                                                                            data.map((item, index) => {
                                                                                return (
                                                                                    <div key={index} className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label className="form-label" htmlFor={item.Title}>{item.Title}</label><br />
                                                                                            <input type="checkbox" id="customControlAutosizing" onChange={() => handleCheckboxChange(index)} checked={item.Status === 1} />
                                                                                            <input type="text" className="form-control" value={item.Template} id="" placeholder="Template" rows={1}
                                                                                                onChange={(event) => handleInputChange(index, event)}
                                                                                                style={{ display: "inline-block", width: "90%", marginLeft: "20px" }}></input>
                                                                                        </div>
                                                                                    </div>)
                                                                            })) : (<></>)}

                                                                    </div>
                                                                    <div className="row justify-content-center mt-3">
                                                                        <div className="col-md-2 text-center">
                                                                            <button type="submit" className="btn btn-sm btn-primary">Update</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-10">
                            <h5>Payment Gateway</h5>
                            <p>
                                Configure payment gateway to receive payments online.
                            </p>
                        </div>
                        <div className="col-md-2 d-flex justify-content-end align-items-center">
                            <Switch
                                checked={pGData?.Status||false}
                                onChange={(checked) => {
                                    setPGData({
                                ...pGData,
                                Status: checked ? 1 : 0
                                 });
                                 handleSwichPGSubmit(checked);}}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-10">
                            <h5>Bank Accounts</h5>
                            <p>
                                Save bank account details to use in application.
                            </p>
                        </div>
                        <div className="col-md-2 d-flex justify-content-end align-items-center">
                            <button type="submit" className="btn btn-sm btn-primary" onClick={showModa2}>Configure</button>

                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-10">
                            <h5>UPI Settings</h5>
                            <p>
                                Save UPI details to use in application.
                            </p>
                        </div>
                        <div className="col-md-2 d-flex justify-content-end align-items-center">
                            <button type="submit" className="btn btn-sm btn-primary" onClick={showModa3}>Configure</button>

                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-10">
                            <h5>Social Media</h5>
                            <p>
                                Save social media details so links are used to share in application.
                            </p>
                        </div>
                        <div className="col-md-2 d-flex justify-content-end align-items-center">
                            <button type="submit" className="btn btn-sm btn-primary" onClick={showModa4}>Configure</button>

                        </div>
                    </div>
                </Card.Body>
            </Card>
            {isModal && (
                    <WhatsappModal
                        open={isModal}
                        onClose={closed}
                        updateData={whatsappData}
                    />
                )}
                {isModal1 && (
                    <PaymentGatewayModal
                        open={isModal1}
                        onClose={closed1}
                        updateData={pGData||{}}
                    />
                )}
                {isModal2 && (
                    <BankAccountsModal
                        open={isModal2}
                        onClose={closed2}
                    />
                )}
                {isModal3 && (
                    <UPISettingModal
                        open={isModal3}
                        onClose={closed3}
                    />
                )}
                {isModal4 && (
                    <SocialMediaModal
                        open={isModal4}
                        onClose={closed4}
                    />
                )}
            {loader}
        </div>
    );
};

export default SettingPage;
