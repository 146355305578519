import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BankDropdoun from "../DropDowns/BankDropdoun";
import BillCategory from "../DropDowns/ExCategory";
import YearDropDown from "../DropDowns/YearDropDown";

const UpdateBillModal = ({ open, onClose, updateData }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const handleClose = () => {
    setShow(false);
    onClose();
  };
  console.log(updateData);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const [Bill, setBill] = useState({
    Bill_No: "",
    Bill_Ref_No: "",
    Bill_Date: "",
    Title: "",
    Description: "",
    Amount: "",
    Due_Date: "",
    Attachment: "",
    Status: "",
    Payment_Date: "",
    Payment_Mode: "Cash",
    Bank_Name: "",
    Chq_No: "",
    Chq_Date: "",
    Transaction_Id:"",
    Category_ID: "",
    Category_Name: ""
});

  useEffect(() => {
    const fetchData = async () => {
      if (userData && userData.Org_Id) {
        try {
          showLoader();
          const result = await axios.get(
            `${apiUrl}/getBillById/${updateData?.Bill_Id}/${userData?.Org_Id}`
          );
          setBill(result.data);
         
          console.log(result.data);
          hideLoader();
        } catch (error) {
          console.error("Error fetching data:", error);
          hideLoader();
        }
      }
    };

    fetchData();
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const updateBill = {
        ...Bill,
        Head_Id: userData?.Head_Id,
        Head_Name: userData?.Head_Name,
        User_Id: userData.UserId,
        Org_Id: userData.Org_Id,
        Org_Name: userData.Org_Name,
        User_Role: userData.Role,
        LoginUsername: userData.Username,
        Display_Name: userData.UserName,
    };
    const formdata = new FormData();
    formdata.append("User_Id", updateBill.User_Id);
    formdata.append("Org_Id", updateBill.Org_Id);
    formdata.append("Org_Name", updateBill.Org_Name);
    formdata.append("User_Role", updateBill.User_Role);
    formdata.append("LoginUsername", updateBill.LoginUsername);
    formdata.append("Display_Name", updateBill.Display_Name);
    formdata.append("Head_Id", updateBill.Head_Id);
    formdata.append("Head_Name", updateBill.Head_Name);

    formdata.append("Bill_No", Bill.Bill_No);
    formdata.append("Bill_Ref_No", Bill.Bill_Ref_No);
    formdata.append("Bill_Date", Bill.Bill_Date);
    formdata.append("Title", Bill.Title);
    formdata.append("Description", Bill.Description);
    formdata.append("Amount", Bill.Amount);
    formdata.append("Due_Date", Bill.Due_Date);
    formdata.append("Attachment", Bill.Attachment);
    formdata.append("Status", Bill.Status);
    formdata.append("Payment_Date", Bill.Payment_Date);
    formdata.append("Payment_Mode", Bill.Payment_Mode);
    formdata.append("Transaction_Id", Bill.Transaction_Id);
    formdata.append("Bank_Name", Bill.Bank_Name);
    formdata.append("Chq_No", Bill.Chq_No);
    formdata.append("Chq_Date", Bill.Chq_Date);
    formdata.append("Category_ID", Bill.Category_ID);
    formdata.append("Category_Name", Bill.Category_Name);
    const errors = {};


    if (Object.keys(errors).length === 0) {
        showLoader();
        axios
        .put(`${apiUrl}/updateBill/${updateData?.Bill_Id}`, formdata)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Bill Updated Successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                      handleClose();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );

                hideLoader();
            });
    } else {
        setErrors(errors);
    }
};

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bill Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Bill_No">
                  Serial No
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Bill_No"
                  id="Bill_No"
                  disabled={!isEditing}
                  value={Bill.Bill_No}
                  placeholder="Enter Bill No"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Bill_Ref_No">
                  Bill/Ref No
                </label>
                <input
                  className="form-control form-control-sm"
                  disabled={!isEditing}
                  type="text"
                  name="Bill_Ref_No"
                  id="Bill_Ref_No"
                  value={Bill.Bill_Ref_No}
                  placeholder="Enter Bill/Ref No"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Bill_Date">
                  Bill Date<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control form-control-sm"
                  disabled={!isEditing}
                  type="date"
                  name="Bill_Date"
                  id="Bill_Date"
                  required
                  value={
                    Bill.Bill_Date
                      ? dayjs(Bill.Bill_Date).format("YYYY-MM-DD")
                      : ""
                  }
                  placeholder="Enter Bill Date"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>

              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Title">
                  Title<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control form-control-sm"
                  disabled={!isEditing}
                  type="text"
                  name="Title"
                  value={Bill.Title}
                  id="Title"
                  placeholder="Enter Title"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <BillCategory
                  label="Category"
                  name="Category"
                  id="Category"
                  isDisabled={!isEditing}
                  value={
                    Bill.Category_ID
                      ? {
                          value: Bill.Category_ID,
                          label: Bill.Category_Name,
                        }
                      : null
                  }
                  required={true}
                  is
                  onChange={(e) => {
                    setBill({
                      ...Bill,
                      Category_ID: e.value,
                      Category_Name: e.label,
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4 ">
                <label className="form-label" htmlFor="Attachment">
                  Attachment
                </label>
                <input
                  style={{
                    width:
                  Bill?.Attachment
                        ? "92%"
                        : "100%",
                    display: "inline-block",
                  }}
                  type="file"
                  className="form-control form-control-sm"
                  disabled={!isEditing}
                  name="Attachment"
                  accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf, .doc, .xls, .xlsx"
                  id="Attachment"
                  onChange={(e) =>
                    setBill({
                      ...Bill,
                      Attachment: e.target.files[0],
                    })
                  }
                />
                {Bill?.Attachment !== "" && (
                  <Link
                    to={`${apiUrl}/download/${Bill.Attachment}`}
                    download
                    className="cursor-pointer text-primary"
                    style={{ textDecoration: "none", marginLeft: "7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.35"
                        d="m5 24l19 18l19-18H31V6H17v18z"
                      />
                    </svg>
                  </Link>
                )}
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="Description">
                  Description
                </label>
                <textarea
                  className={`form-control form-control-sm`}
                  type="text"
                  name="Description"
                  id="Description"
                  rows={2}
                  disabled={!isEditing}
                  value={Bill.Description}
                  placeholder="Write Description..."
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="Status">
                  Payment Status<span style={{ color: "Red" }}>*</span>
                </label>
                <select
                  className="form-select form-select-sm"
                  required
                  disabled={!isEditing}
                  name="Status"
                  id="Status"
                  value={Bill.Status}
                  onChange={handleChange}
                >
                  <option value="">--Select--</option>
                  <option value="Paid">Paid</option>
                  <option value="Unpaid">Unpaid</option>
                </select>
                <span style={{ color: "Red" }}></span>
              </div>
              {Bill?.Status !== "" && (
                <div
                  className={`${
                    Bill?.Status !== "Paid" ? "mb-3 col-md-6" : "mb-3 col-md-4"
                  }`}
                >
                  <label className="form-label" htmlFor="Amount">
                    Amount<span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    className={`form-control form-control-sm`}
                    type="text"
                    name="Amount"
                    id="Amount"
                    required
                    disabled={!isEditing}
                    value={Bill.Amount}
                    placeholder="Enter Amount"
                    onChange={(e) => {
                      const input = e.target.value;
                      const decimalInput = input.replace(/[^0-9.]/g, "");
                      const amount = decimalInput.replace(
                        /^(\d*\.\d*)\..*$/,
                        "$1"
                      );
                      setBill({
                        ...Bill,
                        Amount: amount,
                      });
                    }}
                  />
                </div>
              )}
              {Bill?.Status === "Paid" && (
                <>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="Payment_Date">
                      Payment Date<span style={{ color: "Red" }}>*</span>
                    </label>
                    <input
                      className="form-control form-control-sm"
                      disabled={!isEditing}
                      type="date"
                      name="Payment_Date"
                      id="Payment_Date"
                      required={Bill?.Status === "Paid"}
                      value={
                        Bill.Bill_Date
                          ? dayjs(Bill.Payment_Date).format("YYYY-MM-DD")
                          : ""
                      }
                      placeholder="Enter Payment Date"
                      onChange={handleChange}
                    />
                    <span style={{ color: "Red" }}></span>
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="Payment_Mode">
                      Payment Mode<span style={{ color: "Red" }}>*</span>
                    </label>
                    <select
                      className="form-select form-select-sm"
                      name="Payment_Mode"
                      id="Payment_Mode"
                      value={Bill.Payment_Mode}
                      disabled={!isEditing}
                      required={Bill?.Status === "Paid"}
                      onChange={handleChange}
                    >
                      <option selected value="Cash">
                        Cash
                      </option>
                      <option value="Cheque">Cheque</option>
                      <option value="Online">Online</option>
                    </select>
                    <span style={{ color: "Red" }}></span>
                  </div>
                </>
              )}

              {Bill?.Status === "Unpaid" && (
                <>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Due_Date">
                      Due Date<span style={{ color: "Red" }}>*</span>
                    </label>
                    <input
                      className="form-control form-control-sm"
                      disabled={!isEditing}
                      type="date"
                      name="Due_Date"
                      id="Due_Date"
                      required={Bill?.Status === "Unpaid"}
                      value={
                        Bill.Bill_Date
                          ? dayjs(Bill.Due_Date).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={handleChange}
                    />
                    <span style={{ color: "Red" }}></span>
                  </div>
                </>
              )}
            </div>
            {Bill?.Payment_Mode === "Cheque" && (
              <div className="row">
                <div className="mb-3 col-md-3">
                  <label className="form-label" htmlFor="Bank_Name">
                    Bank Name<span style={{ color: "Red" }}>*</span>
                  </label>
                  <BankDropdoun
                    name="Bank_Name"
                    id="Bank_Name"
                    value={
                      Bill.Bank_Name
                        ? {
                            value: Bill.Bank_Name,
                            label: Bill.Bank_Name,
                          }
                        : null
                    }
                    required={Bill.Payment_Mode === "Cheque"}
                    isDisabled={!isEditing}
                    onChange={(e) => {
                      setBill({
                        ...Bill,
                        Bank_Name: e.value,
                        //Category: e.label,
                      });
                    }}
                  />
                  <span style={{ color: "Red" }}></span>
                </div>
                <div className="mb-3 col-md-4">
                  <label className="form-label" htmlFor="Chq_No">
                    Cheque No<span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    className={`form-control form-control-sm`}
                    type="text"
                    name="Chq_No"
                    id="Chq_No"
                    required={Bill.Payment_Mode === "Cheque"}
                    value={Bill.Chq_No}
                    disabled={!isEditing}
                    placeholder="Enter Cheque No"
                    onChange={(e) => {
                      const input = e.target.value;
                      const decimalInput = input.replace(/[^0-9]/g, "");
                      setBill({
                        ...Bill,
                        Chq_No: decimalInput,
                      });
                    }}
                  />
                </div>

                <div className="mb-3 col-md-3">
                  <label className="form-label" htmlFor="Chq_Date">
                    Cheque Date<span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    disabled={!isEditing}
                    type="date"
                    name="Chq_Date"
                    id="Chq_Date"
                    required={Bill.Payment_Mode === "Cheque"}
                    value={
                      Bill.Chq_Date
                        ? dayjs(Bill.Chq_Date).format("YYYY-MM-DD")
                        : ""
                    }
                    placeholder="Enter Cheque Date"
                    onChange={handleChange}
                  />
                  <span style={{ color: "Red" }}></span>
                </div>
              </div>
            )}

            {Bill?.Payment_Mode === "Online" && (
              <div className="row">
                <div className="mb-3 col-md-12">
                  <label className="form-label" htmlFor="Transaction_Id">
                    Transaction ID<span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    disabled={!isEditing}
                    type="text"
                    required={Bill.Payment_Mode === "Cheque"}
                    value={Bill.Transaction_Id}
                    name="Transaction_Id"
                    id="Transaction_Id"
                    placeholder="Enter Transaction ID"
                    onChange={handleChange}
                  />
                  <span style={{ color: "Red" }}></span>
                </div>
              </div>
            )}

            <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                {!isEditing && (
                                    <button
                                        className="btn btn-sm btn-primary "
                                        onClick={handleEditClick}
                                    >
                                        Edit
                                    </button>
                                )}{" "}
                                &nbsp;

                                {isEditing && (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={

                                            handleCancelEdit
                                        }
                                    >
                                        Close
                                    </button>
                                )}  &nbsp;
                           
                                        <>
                                            {isEditing && (
                                                <button type="submit" className="btn btn-sm btn-primary">
                                                    Update
                                                </button>
                                            )}
                                        </>
                            </div>
                        </div>
          </form>

          {loader}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateBillModal;
