import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { First } from "react-bootstrap/esm/PageItem";

const AddFacultyHoliday = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isValidMobile, setIsValidMobile] = useState({});
    const [isValidMobile1, setIsValidMobile1] = useState({});
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [file, setFile] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setShow(open);
    }, [open]);





    const handleClose = () => {
        setShow(false);
        onClose();
    };

    //"Name": `${Bill.First_Name} ${Bill.Middle_Name ||""} ${Bill.Last_Name||""}`,

    const [Bill, setBill] = useState({
        Head_Id: "All",
        Head_Name: "All",
        First_Name: "",
        Middle_Name: "",
        Last_Name: "",
        Email: "",
        Mobile: "",
        Alternate_Mobile: "",
        Designation: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            // Head_Id: userData?.Head_Id,
            // Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addMasterAdmin`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Master Admin Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                Head_Id: "All",
                                Head_Name: "All",
                                First_Name: "",
                                Middle_Name: "",
                                Last_Name: "",
                                Email: "",
                                Mobile: "",
                                Alternate_Mobile: "",
                                Designation: ""
                            });
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!! Please try again later.",
                        "error"
                    );
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };



    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Master Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">

                            <div className="col-md-3 mb-3">
                                <label className="form-label" htmlFor="Branch ">Branch </label>
                                <select
                                    className="form-select form-select-sm"
                                    id="Branch "
                                    name="Branch "
                                    value={Bill.Head_Id}
                                    onChange={(e) => {
                                        const selectedIndex = e.target.selectedIndex;
                                        const selectedOption = e.target[selectedIndex];
                                        setBill({
                                            ...Bill,
                                            Head_Id: selectedOption.value,
                                            Head_Name: selectedOption.label
                                        });
                                    }}
                                >
                                    <option selected value="All">All</option>
                                    <option value={userData?.Head_Id}>{userData?.Head_Name}</option>
                                </select>
                            </div>

                            <div className={"mb-3 col-md-3"}>
                                <label className="form-label" htmlFor="First Name">
                                    First Name<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="First_Name"
                                    id="First_Name"
                                    required
                                    value={Bill.First_Name}
                                    placeholder="Enter First Name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={"mb-3 col-md-3"}>
                                <label className="form-label" htmlFor="Middle Name">
                                    Middle Name
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Middle_Name"
                                    id="Middle_Name"
                                    value={Bill.Middle_Name}
                                    placeholder="Enter Middle Name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={"mb-3 col-md-3"}>
                                <label className="form-label" htmlFor="Last Name">
                                    Last Name<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Last_Name"
                                    id="Last_Name"
                                    required
                                    value={Bill.Last_Name}
                                    placeholder="Enter Last Name"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Mobile">
                                    Mobile<span style={{ color: "Red" }}>*</span>(For SMS)
                                </label>
                                <input
                                    className={`form-control form-control-sm ${isValidMobile ? "" : "is-invalid"
                                        }`}
                                    type="text"
                                    name="Mobile"
                                    id="Mobile"
                                    value={Bill.Mobile}
                                    required
                                    maxLength={10}
                                    placeholder="Enter Mobile"
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(/[^0-9]/g, "");
                                        const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                                        setIsValidMobile(isValid);
                                        if (isValid || numericInput === "") {
                                            setBill({
                                                ...Bill,
                                                Mobile: numericInput,
                                            });
                                        }
                                    }}
                                />
                                {!isValidMobile && (
                                    <div className="invalid-feedback">Invalid mobile number.</div>
                                )}
                            </div>

                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Alternate_Mobile">
                                    Alternate Mobile
                                </label>
                                <input
                                    className={`form-control form-control-sm ${isValidMobile1 ? "" : "is-invalid"
                                        }`}
                                    type="text"
                                    name="Alternate_Mobile"
                                    id="Alternate_Mobile"
                                    value={Bill.Alternate_Mobile}
                                    maxLength={10}
                                    placeholder="Enter Alternate Mobile"
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(/[^0-9]/g, "");
                                        const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                                        setIsValidMobile1(isValid);
                                        if (isValid || numericInput === "") {
                                            setBill({
                                                ...Bill,
                                                Alternate_Mobile: numericInput,
                                            });
                                        }
                                    }}
                                />
                                {!isValidMobile1 && (
                                    <div className="invalid-feedback">Invalid mobile number.</div>
                                )}
                            </div>
                            <div className={"mb-3 col-md-3"}>
                                <label className="form-label" htmlFor="Email">
                                    Email
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="email"
                                    name="Email"
                                    id="Email"
                                    value={Bill.Email}
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={"mb-3 col-md-3"}>
                                <label className="form-label" htmlFor="Designation">
                                    Designation
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Designation"
                                    id="Designation"
                                    value={Bill.Designation}
                                    placeholder="Enter Designation"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddFacultyHoliday;
