import React from "react";

const Dropdown = ({ label, options, name, id, disabled, onChange, value, required }) => {
    return (
        <>
            <select
                className="form-select form-select-sm"
                name={name}
                id={id}
                required={required}
                onChange={onChange}
                value={value}
                disabled={disabled}
            >
                <option selected disabled value="" label="">
                --Select--
                </option>
                {options.map((option) => (
                    <option key={option.value} value={option.value} label={option.label}>
                        {option.label}
                    </option>
                ))}
            </select>
        </>
    );
};

export default Dropdown;
