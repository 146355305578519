import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import BankDropdoun from "../../DropDowns/BankDropdoun";
import BillCategory from "../../DropDowns/ExCategory";
import YearDropDown from "../../DropDowns/YearDropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import {formatInputDate} from '../../../Components/dateFormate'

const AddUpdateBill = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const { opration, Sr_No } = useParams();

    const [Bill, setBill] = useState({
        IsWhtasapp:false,
        Date: new Date(),
        TOD: ""
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };


const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };
        
        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addTOD`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "TOD Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewTOD");
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
};



    const customStyles = {
        control: (base) => ({
            ...base,
            height: 33,
            minHeight: 33,
        }),
    };

    return (
        <div className="content">
        <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
                            <div className="col-md-6">
                            Thought Of The Day
                            </div>
    
                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                <div className="col-md-5">
                                    <HeadMasterDropDown
                                        label="Branch"
                                        name="Branch"
                                        id="Branch"
                                    />
                                </div></div>
                        </div>
     
        </h4>
        <div className="row g-4">
          <div className="col-12 col-xl-12 order-1 order-xl-0">
        <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
        >
            <div className="card-body p-0">
                <div className="p-4 code-to-copy">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3 col-md-12">
                                <label className="form-label" htmlFor="Date">
                                    Date
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="Date"
                                    id="Date"
                                    disabled
                                    value={formatInputDate(Bill.Date)}
                                    //placeholder="Enter Bill No"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="TOD">
                            Thought Of The Day<span style={{ color: "Red" }}>*</span>
                            </label>
                          <textarea 
                          className="form-control"
                          id="TOD"
                          name="TOD"
                          required
                          rows={2}
                          value={Bill.TOD}
                          onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                        <div class="form-check">
                <input
                  className="form-check-input"
                  id="flexCheckDefault2"
                  type="checkbox"
                  checked={Bill.IsWhtasapp}
                  onChange={(e) => {
                    setBill({
                      ...Bill,
                      IsWhtasapp: e.target.checked,
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault2">
                  <h6>Send Whatsapp Message</h6>
                </label>
              </div>
              </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                </div>


                {loader}
            </div>
        </div>
              </div>
              </div>
            </div>
    );
};

export default AddUpdateBill;


