import React, { useEffect, useState } from "react";
import $ from "jquery";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { formatInputDate } from "../../Components/dateFormate";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";
import SubjectDropdown from "../DropDowns/SubjectDropdown";
import { Category, Subject } from "@mui/icons-material";

const ViewPerformance = () => {
  const [table, setTable] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setBillData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [FacultyOptions, setFacultyOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  const [Sub_Id, setSubId] = useState("");
  const [Status, setStatus] = useState("");
  //   const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  //   const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Std_Id]);

  //console.log(design);

  const fetchData = async () => {
    try {
      debugger;
      showLoader();
      const res = await axios.get(
        `${apiUrl}/getStudentResultSummary?orgId=${userData.Org_Id}&stdId=${Std_Id}&batchId=${Batch_ID}&headId=${userData?.Head_Id}`
      );
      setBillData(res.data);
      setFilteredData(res.data);
      console.log(res.data);
      setTable(true);

      if (userData && userData.Org_Id && userData?.Head_Id) {
        showLoader();
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
        //console.log(getStd);
      }

      if (userData && userData.Org_Id && Std_Id) {
        const batch = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
        );

        const getBatch = batch.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(getBatch);
        // console.log(getBatch);
      }
      const Teacher = await axios.get(
        `${apiUrl}/binddropTeacher?OrgId=${userData.Org_Id}`
      );

      const getTeacher = Teacher.data.map((category) => ({
        label: category.Name,
        value: category.User_Id,
      }));
      setFacultyOptions(getTeacher);

      const getSubject = Subject.data.map((category) => ({
        label: category.Sub_Name,
        value: category.Sub_Id,
      }));
      setSubId(getSubject);

      // console.log(res.data);
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const formatTotalSeconds = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    const formattedHours = hours.toString();
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (data.length > 0) {
      const newFilteredData = data.filter((row) => {
        const studentId = row?.Student_Id?.toLowerCase() || "";
        const mobileForSms = row?.Mobile_For_SMS?.toLowerCase() || "";
        const firstName = row?.First_Name?.toLowerCase() || "";
        const middleName = row?.Middle_Name?.toLowerCase() || "";
        const lastName = row?.Last_Name?.toLowerCase() || "";
        const email = row?.Email?.toLowerCase() || "";

        return (
          studentId.includes(searchTerm.toLowerCase()) ||
          mobileForSms.includes(searchTerm.toLowerCase()) ||
          firstName.includes(searchTerm.toLowerCase()) ||
          middleName.includes(searchTerm.toLowerCase()) ||
          lastName.includes(searchTerm.toLowerCase()) ||
          email.includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(newFilteredData);
    }
  }, [searchTerm, data]);

  useEffect(() => {
    const PhotoCellRenderer = (props) => {
      const { Photo } = props.data; // Destructuring value from cellRendererParams
      return (
        <div className="avatar-group avatar-group-dense">
          <div
            className="avatar avatar-m  rounded-circle"
            style={{ height: "2rem", width: "2rem", marginTop: "5px" }}
          >
            <img
              className="rounded-circle "
              src={`https://masyseducare.com/Documents/Student/Photos/${Photo}`}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = "https://masyseducare.com/img/userdefault.png"; // Specify the path to your default image
              }}
              alt="student"
            />
          </div>
        </div>
      );
    };

    const LinkCellRenderer = (props) => {
      const { ID } = props.data;
    
      return (
        <Link to={`/Performance/${ID}`}>
          {"View Performance"}
        </Link>
      );
    };


    const newAgcolumns = [
      { field: "SrNo" },
      { field: "StudentId" },
      { field: "Name" },
      { field: "Email" },
      { field: "Mobile" },
      {
        field: "Photo",
        cellRenderer: PhotoCellRenderer,
        width: 100,
        maxWidth: 100,
      },
      { field: "ViewPerformance", cellRenderer: LinkCellRenderer },
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData.map((row, index) => ({
        SrNo: index + 1,
        ID: row.ID,
        StudentId: row.Student_Id||"",
        Name: `${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`,
        Mobile: row.Mobile_For_SMS,
        Email: row.Email||"",
        Photo: `${row.Photo}`,
      }));

      setAgrows(newAgrows);
    }
  }, [filteredData]);

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };
  async function handleDelete(Id) {
    showLoader();

    const obj = {
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      User_Name: userData?.UserName,
    };

    try {
      await axios.delete(`${apiUrl}/deleteLecture/${Id}`, { data: obj });
      Swal.fire(
        "Deleted!",
        "Lecture Report Faculty record has been deleted.",
        "success"
      );
      fetchData();
    } catch (error) {
      console.error(
        "Error while deleting Lecture Report Faculty record:",
        error
      );
      Swal.fire(
        "Error",
        "Failed to delete Lecture Report Faculty record.",
        "error"
      );
    } finally {
      hideLoader();
    }
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    fetchData();
    hideLoader();
  };

  useEffect(() => {
    if (table) {
      const initializeDataTable = () => {
        var dTable = $("#data-table").DataTable({
          paginate: true,
          responsive: true,
          pageLength: 10,
          ordering: true,
          searching: true,
        });

        setDataTable(dTable);
      };
      if (!dataTable) {
        initializeDataTable();
      }

      return () => {
        if (dataTable) {
          dataTable.destroy(true);
        }
      };
    }
    setTable(false);
  }, [table]);

  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF();
    doc.text("Lecture Report Topic", 20, 10);

    const columns = ["Sr No", "Topic Name", "Lecture Count", "Total Hourse"];

    const rows = filteredData.map((row, index) => [
      index + 1,
      row.Topic_Name,
      row.LectureCount,
      row?.TotalDuration,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Lecture Report Topic.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sr No", "Topic Name", "Lecture Count", "Total Hourse"];

    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([
        index + 1,
        row.Topic_Name,
        row.LectureCount,
        row?.TotalDuration,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lecture Report Topic");

    XLSX.writeFile(workBook, "Lecture Report Topic.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const toggle = () => {
    setDesign({
      ...design,
      filterToggle: design.filterToggle ? false : true,
    });
    setFilterData({
      ...filterData,
      filterToggle: design.filterToggle ? false : true,
    });
  };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-1">
              <div className="avatar avatar-xl me-3">
                <img
                  className="rounded-circle"
                  src={`https://masyseducare.com/Documents/Student/Photos/${row.Photo}`}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src =
                      "https://masyseducare.com/img/userdefault.png"; // Specify the path to your default image
                  }}
                  alt="student"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1 mt-2">
                <h5 className="text-black">{`${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`}</h5>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Student Id :{" "}
                  <span className="ms-1 text-1100">{`${row.Student_Id||""}`}</span>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1">
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Email :{" "}
                  <span className="fw-semi-bold  ms-1">{row.Email}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 fw-semi-bold text-truncate"
                  style={{ color: "#a09aa3" }}
                >
                  Mobile Number
                </p>
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  <span className="fw-semi-bold  ms-1">
                    {row.Mobile_For_SMS}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <p className="mb-0 fw-semi-bold listViwP text-truncate">
                <Link to={`/Performance/${row.ID}`}>View Performance</Link>
              </p>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                  <div className="avatar-group avatar-group-dense">
                    <div
                      className="avatar avatar-m  rounded-circle"
                      style={{ height: "3rem", width: "3rem" }}
                    >
                      <img
                        className="rounded-circle "
                        src={`https://masyseducare.com/Documents/Student/Photos/${row.Photo}`}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src =
                            "https://masyseducare.com/img/userdefault.png"; // Specify the path to your default image
                        }}
                        alt="student"
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-between me-1"
                    style={{ flex: "auto", marginTop: "8px" }}
                  >
                    <div className="col-11 pe-1">
                      <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                       {`${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`}
                      </h6>
                      <p
                        className="mb-0 profiledetail lh-1"
                        style={{ fontSize: "13px" }}
                      >
                        Student Id :{" "}
                        <span className="ms-1 text-1100">{`${row.Student_Id||""}`}</span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* <span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-success"><span className="badge-label">completed</span></span> */}
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail text-truncate lh-1">
                    Course :{" "}
                    <span className="fw-semi-bold ms-1">
                      {" "}
                      {row.Course_Name}
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-between text-700 fw-semi-bold">
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "14px",
                      color: "#a09aa3",
                      lineHeight: "5px",
                    }}
                  >
                    {" "}
                    Mobile Number
                  </p>
                  {/* <p className="mb-2 text-1100">100%</p> */}
                </div>
                {/* <div className="progress bg-success-100">
                                                        <div className="progress-bar rounded bg-success" role="progressbar" aria-label="Success example" style={{ width: "100%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div> */}
                <div
                  className="align-items-center numberDiv mt-2"
                  style={{ display: "inline-block !important" }}
                >
                  <p className="mb-0 profiledetail">
                    <span className="fw-semi-bold text-600 ms-1">
                      {row.Mobile_For_SMS}
                    </span>
                  </p>
                </div>
                <br />
                <div
                  className="align-items-center numberDiv"
                  style={{ display: "inline-block !important" }}
                >
                  <p className="mb-0 profiledetail">
                    <Link to={`/Performance/${row.ID}`}>
                      View Performance
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="content">
      <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
        <div className="container-fluid">
          {/* Sidebar */}
          <div id="sidebar-wrapper">
            <div className="row g-4">
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-2"
                  data-component-card="data-component-card"
                >
                  <div className="row justify-content-center d-flex p-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Branch">
                            Branch
                          </label>
                          <HeadMasterDropDown
                            label="Branch"
                            name="Branch"
                            id="Branch"
                            //onChange={(e) => setBranch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Standard">
                            Standard :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="Standard"
                            disabled={userData?.Head_Id === ""}
                            //value={Std_Id}
                            onChange={(e) => {
                              setStdId(e.target.value);
                              setBatchID("");
                            }}
                          >
                            <option selected value="" label="All"></option>
                            {StdOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            Batch :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="example-select"
                            value={Batch_ID}
                            disabled={Std_Id === ""}
                            onChange={(e) => setBatchID(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {BatchOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Subject">
                            Subject
                          </label>
                          <SubjectDropdown
                            label="Subject"
                            name="Subject"
                            id="Subject"
                            Std_Id={Std_Id}
                            required={true}
                            isDisabled={Std_Id === ""}
                            onChange={(e) => {
                              setSubId(e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            From Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            value={formatInputDate(fromDate)}
                            onChange={(date) =>
                              setFromDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            To Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            value={formatInputDate(toDate)}
                            onChange={(date) =>
                              setToDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Status">
                            Faculty :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="example-select"
                            value={Status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option selected value="">
                              All
                            </option>
                            {FacultyOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center p-2">
                    <div className="col-md-4 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-center"
                        onClick={handleDateRangeFilter}
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                Students
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  href="#basic-form"
                  style={{ paddingLeft: "0.375em" }}
                ></a>
              </h4>
              <div className="col-md-12 text-center mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  style={{
                    float: "left",
                    display: "inline-block",
                    width: "200px",
                  }}
                ></input>
                <Link
                  to={"/addLectures"}
                  style={{ float: "right", display: "none" }}
                >
                  <button className="btn btn-primary btn-sm text-center">
                    Add
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn bg-200 filterButton collapsed mr-2"
                  data-toggle="collapse"
                  onClick={toggle}
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  style={{ float: "right", marginRight: "10px" }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className="form-control dropdown-toggle viewButton  mb-2"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "auto",
                    padding: "4px 10px",
                  }}
                >
                  <>
                    {design.dataView === "Table View" && (
                      <TableChartOutlinedIcon />
                    )}
                  </>
                  <>
                    {design.dataView === "List View" && (
                      <FormatListBulletedIcon />
                    )}
                  </>
                  <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={(e) => {
                      setDesign({
                        ...design,
                        dataView: "Table View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Table View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <TableChartOutlinedIcon />
                    <span> Table View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "List View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "List View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <FormatListBulletedIcon />
                    <span> List View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "Tile View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Tile View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <GridViewIcon />
                    <span> Tile View</span>
                  </Link>
                </div>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to PDF</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadPDF()}
                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                  >
                    <i
                      className="fas fa-file-pdf"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to Excel</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadExcel()}
                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row">
              {design.dataView === "Table View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  {Agrows.length > 0 && (
                    <AgGridTable row={Agrows} column={Agcolumns} />
                  )}
                </div>
              )}
              {design.dataView === "Tile View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">{displayData}</div>
                </div>
              )}
              {design.dataView === "List View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">
                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                      <div className="card h-100 hover-actions-trigger">
                        <div
                          className="card-body"
                          style={{ paddingTop: "5px" }}
                        >
                          {displayData}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(design.dataView === "List View" ||
                design.dataView === "Tile View") && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageCount={pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  breakAriaLabels={{
                    forward: "Jump forward",
                    backward: "Jump backward",
                  }}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                  previousLinkClassName={"btn btn-outline-primary me-2"}
                  nextLinkClassName={"btn btn-outline-primary ms-2"}
                  activeLinkClassName={"customclass ms-2 me-2"}
                  pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                />
              )}
            </div>
          </div>
        </div>
        {/* {isModal && (
                    <UpdateStatus
                        open={isModal}
                        onClose={closed}
                        updateData={data[indexValue]}
                    />
                )} */}
        {loader}
      </div>
    </div>
  );
};

export default ViewPerformance;
