import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../Components/dateFormate'
import { FaEye } from "react-icons/fa";


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [SubjectOption, setSubjectOption] = useState([]);
    const [TopicOption, setTopicOption] = useState([]);
    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(updateData);
    const [Test, setTest] = useState({

        Std_Id: updateData?.Std_Id,
        Std_Name: updateData.Std_Name,
        Batch_Id: updateData.Batch_Id,
        Batch_Name: updateData.Batch_Name,
        Subject_Id: updateData?.Subject_Id,
        Subject_Name: updateData.Subject_Name,
        Topic_Id: updateData.Topic_Id,
        Topic_Name: updateData.Topic_Name,
        Test_Date: updateData?.Test_Date,
        Time_From: dayjs(updateData.Time_From, 'h:mmA'),
        Time_To: dayjs(updateData.Time_To, 'h:mmA'),
        Duration: updateData.Duration,
        Min_Marks: parseFloat(updateData.Min_Marks).toFixed(2),
        Total_Marks: parseFloat(updateData.Total_Marks).toFixed(2),
        Lecture_Id: updateData.Lecture_Id,
        Syllabus_Remarks: updateData.Syllabus_Remarks
    });

    useEffect(() => {
        const fetchData = async () => {
          try {
            showLoader();
       
    
            if (userData && userData.Org_Id && Test?.Std_Id) {
              const [subRes] = await Promise.all([
                axios.get(`${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Test.Std_Id}`),
              ]);
              const subOptions = subRes.data.map((category) => ({
                label: category.Sub_Name,
                value: category.Sub_Id,
              }));
              setSubjectOption(subOptions);
            }
    
            if (userData && userData.Org_Id && Test?.Subject_Id) {
              const result = await axios.get(`${apiUrl}/getTopic?Sub_Id=${Test.Subject_Id}&Org_Id=${userData.Org_Id}`);
              const topicOptions = result.data.map((category) => ({
                label: category.Topic_Name,
                value: category.Topic_Id,
              }));
              setTopicOption(topicOptions);
            }
    
            hideLoader();
          } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
          }
        };
    
        fetchData();
      }, [userData, Test.Std_Id, Test.Subject_Id]);



    const handleSubmit = (event) => {
        event.preventDefault();
      
        const updateTest = {
            ...Test,
            Time_From: new Date(Test.Time_From).toLocaleTimeString("en-GB"),
            Time_To: new Date(Test.Time_To).toLocaleTimeString("en-GB"),
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateTest/${updateData?.ID}`, updateTest)
                .then((res) => {
                    Swal.fire("Success!", "Test Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Test</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                        <div className="row">
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Subject">
                        Subject<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Test.Std_Id === "" ? true : false}
                        name="Subject"
                        id="Subject"
                        required
                        options={SubjectOption}
                        value={
                          Test.Subject_Id
                            ? {
                              value: Test.Subject_Id,
                              label: Test.Subject_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setTest({
                            ...Test,
                            Subject_Id: selectedOption.value,
                            Subject_Name: selectedOption.label,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Topic">
                        Topic<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Test.Subject_Id === "" ? true : false}
                        name="Topic"
                        id="Topic"
                        required
                        options={TopicOption}
                        value={
                          Test.Topic_Id
                            ? {
                              value: Test.Topic_Id,
                              label: Test.Topic_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setTest({
                            ...Test,
                            Topic_Id: selectedOption.value,
                            Topic_Name: selectedOption.label,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="starttime">
                        From Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Test.Time_From}
                          className="form-control"
                          slotProps={{
                            textField: { size: "small", required: true },
                          }}
                          renderInput={(props) => (
                            <input
                              {...props}
                              type="text"
                              className="form-control"
                              style={{ width: "100%" }}
                              size="small"
                              placeholder=""
                              required
                            />
                          )}
                          onChange={(startTime) =>
                            setTest({ ...Test, Time_From: startTime })
                          }
                        />
                      </LocalizationProvider>

                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Time_To">
                        To Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Test.Time_To}
                          required
                          className="form-control"
                          slotProps={{
                            textField: { size: "small", required: true },
                          }}
                          onChange={(startTime) =>
                            setTest({ ...Test, Time_To: startTime })
                          }
                        />
                      </LocalizationProvider>
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Min_Marks">
                        Minimum Marks<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Min_Marks"
                        name="Min_Marks"
                        required
                        placeholder="Enter Minimum Marks"
                        value={Test.Min_Marks}
                        onChange={(e) => {
                          setTest({
                            ...Test,
                            Min_Marks: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Total_Marks">
                        Total Marks<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Total_Marks"
                        name="Total_Marks"
                        required
                        placeholder="Enter Total Marks"
                        value={Test.Total_Marks}
                        onChange={(e) => {
                          setTest({
                            ...Test,
                            Total_Marks: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Syllabus_Remarks">
                        Syllabus/Remarks
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="Syllabus_Remarks"
                        placeholder="Syllabus Remarks..."
                        rows={2}
                        value={Test.Syllabus_Remarks}
                        onChange={(e) => {
                          setTest({
                            ...Test,
                            Syllabus_Remarks: e.target.value,
                          });
                        }}
                      />
                    </div>
                

                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
