import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import { formatDate } from "../../Components/dateFormate";

const UpdateEnquiryFollowup = ({ open, onClose, enquiryData }) => {
    const { userData } = useUser();
    const [data, setData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(enquiryData)
    console.log(data)
    ///enquiryData.Enq_Id
    const [Student, setStudent] = useState({
        Status: "Interested",
        Next_Followup_On: "",
        Followup_Remarks: ""

    });

    useEffect(() => {
        if (enquiryData && enquiryData.Enq_Id && enquiryData.Org_Id && enquiryData.Head_Id) {
            fetchData();
        }
    }, [enquiryData, enquiryData.Enq_Id, enquiryData.Org_Id]);

    const fetchData = async () => {
        showLoader();
        axios.get(`${apiUrl}/getEnquiryFollowup/${enquiryData.Enq_Id}/${enquiryData.Org_Id}/${enquiryData.Head_Id}`)
            .then((res) => {
                const result = res.data;
                setData(result);
                hideLoader();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                hideLoader();
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateStudent = {
            ...Student,
            Assigned_To: enquiryData.Assigned_To,
            Enquiry_No: enquiryData.Enquiry_No,
            First_Name: enquiryData.First_Name,
            Middle_Name: enquiryData.Middle_Name,
            Last_Name: enquiryData.Last_Name,
            Head_Id: enquiryData.Head_Id,
            Head_Name: enquiryData.Head_Name,
            Subhead_Id: enquiryData.Subhead_Id,
            Course_ID: enquiryData.Course_ID,
            Course_Name: enquiryData.Course_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            Username: userData.Username,
            UserName: userData.UserName
        };



        const errors = {};




        if (Object.keys(errors).length === 0) {

            showLoader();
            axios
                .put(`${apiUrl}/updateEnquiryFollowup/${enquiryData.Enq_Id}`, updateStudent)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Enquiry Followup Updated Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            // navigate("/viewEnquiry");
                            fetchData();
                        }
                    });
                    hideLoader();

                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();

                });
        } else {
            setErrors(errors);
        }
    };


    return (<div>
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Enquiry Followup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="Status">
                                    Status<span style={{ color: "Red" }}>*</span>
                                </label>
                                <select
                                    className="form-select form-select-sm"
                                    required={true}
                                    name="Status"
                                    id="Status"
                                    value={Student.Status}
                                    onChange={(e) => {
                                        const selectedIndex = e.target.selectedIndex;
                                        const selectedOption = e.target[selectedIndex];
                                        setStudent({
                                            ...Student,
                                            //Assigned_To: selectedOption.value,
                                            Status: selectedOption.label,
                                        });
                                    }}
                                >
                                    <option selected value="Interested" label="Interested">Interested</option>
                                    <option value="Not Interested" label="Not Interested">Not Interested</option>
                                    <option value="Converted" label="Converted">Converted</option>
                                </select>
                                <span style={{ color: "Red" }}></span>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="Next_Followup_On"
                                >
                                    Next Followup Date
                                    <span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="Next_Followup_On"
                                    required
                                    id="Next_Followup_On"
                                    placeholder="Next Followup Date"
                                    onChange={(e) => {
                                        setStudent({
                                            ...Student,
                                            Next_Followup_On: e.target.value,
                                        });
                                    }}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-12">
                                <label
                                    className="form-label"
                                    htmlFor="Followup_Remarks"
                                >
                                    Followup Remarks{Student.Status !== 'Converted' && (<span style={{ color: "Red" }}>*</span>)}
                                </label>
                                <textarea
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Followup_Remarks"
                                    required={Student.Status !== 'Converted'}
                                    id="Followup_Remarks"
                                    placeholder="Followup Remarks..."
                                    onChange={(e) => {
                                        setStudent({
                                            ...Student,
                                            Followup_Remarks: e.target.value,
                                        });
                                    }}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="table-responsive scrollbar  p-4">
                        <table
                            id="data-table"
                            className="table  table-hover table-sm fs--1 mb-0"
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>#</th>
                                    <th>Followup Date</th>
                                    <th className="text-center" style={{ width: '400px' }}>Followup Remarks</th>
                                    <th>Next Followup Date</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.length > 0 ? (
                                    data.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{formatDate(row.Followup_Taken_On)}</td>
                                                <td>{row.Followup_Remarks}</td>
                                                <td>{formatDate(row.Next_Followup_On)}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="text-center text-danger">Data Not Found!!</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {loader}
            </Modal.Body>
        </Modal>

    </div>
    )
}

export default UpdateEnquiryFollowup