import React from 'react'
import Performance from '../Reports/Performance'
import TablePerformanceReport from '../Reports/TablePerformanceReport'

const StudentPerformance = () => {
  const [selectedType, setSelectedType] = React.useState("chart-report");

  return (
 
      <div>
          <div className="row justify-content-between">
            <h5 className="mb-0 mt-2" data-anchor="data-anchor" id="basic-form">
              <div className="row">
                <div className="col-md-6">Performance Summary</div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <div className="col-md-5">
                      <div className="mb-3">
                       
                          <label
                            className="form-label mb-2"
                            htmlFor="performanceOptions"
                          >
                            Type
                          </label>
                          <select
                            className="form-select"
                            id="performanceOptions"
                            name="performanceOptions"
                            value={selectedType}
                            onChange={(e)=>{setSelectedType(e.target.value)}}
                          >
                            <option selected value="chart-report">Chart Report</option>
                            <option value="table-report">Table Report</option>
                          </select>
                    
                      </div>
                  </div>
                </div>
              </div>
            </h5>
          </div>
          {selectedType ==="chart-report"? (<Performance/>):(<TablePerformanceReport/>)}
        </div>
  )
}

export default StudentPerformance