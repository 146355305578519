import React, { useEffect, useState } from "react";
import $ from "jquery";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { formatInputDate } from "../../Components/dateFormate";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";

const LectureReportSubject = () => {
    const { Sub_Id, Batch_Id } = useParams();
    const [table, setTable] = useState(false);
    const [dataTable, setDataTable] = useState(null);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [isModal, setIsModal] = useState(false);
    const [indexValue, setIndex] = useState();
    const [data, setBillData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [StdOptions, setStdOptions] = useState([]);
    const [BatchOptions, setBatchOptions] = useState([]);
    const [Std_Id, setStdId] = useState('');
    const [Batch_ID, setBatchID] = useState('');
    const [Status, setStatus] = useState('');
    //   const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
    //   const [toDate, setToDate] = useState(dayjs().endOf("month"));
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: "",
    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView,
            });
        }
    }, [userData]);

    //console.log(design);

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(
                `${apiUrl}/getLectureReportBySubject?OrgId=${userData.Org_Id}&Sud_Id=${Sub_Id}&Batch_Id=${Batch_Id}&HeadId=${userData?.Head_Id}
               &fromDate=${fromDate ? fromDate?.format("YYYY-MM-DD") : ""}&toDate=${toDate ? toDate?.format("YYYY-MM-DD") : ""}`);
            setBillData(res.data);
            setFilteredData(res.data);
            console.log(res.data);
            setTable(true);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();

        hours = hours.toString();
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    };

    //Search Box
    const [searchTerm, setSearchTerm] = useState("");


    useEffect(() => {
        if (data.length > 0) {
            const newFilteredData = data.filter(
                (row) =>
                    row?.Std_Name.toLowerCase().includes(searchTerm) ||
                    row?.Batch_Name.toLowerCase().includes(searchTerm) ||
                    row?.Subject_Name.toLowerCase().includes(searchTerm) ||
                    row?.Meeting_Id?.toString().toLowerCase().includes(searchTerm) ||
                    row?.Password.toLowerCase().includes(searchTerm)||
                    row?.Link.toLowerCase().includes(searchTerm)||
                    row?.Type.toLowerCase().includes(searchTerm)||
                    row?.Topic_Name.toLowerCase().includes(searchTerm)||
                    row?.Description.toLowerCase().includes(searchTerm)||
                    formatDate(row?.Lecture_Date)?.toLowerCase().includes(searchTerm)||
                    formatTimestamp(row?.Duration)?.toLowerCase().includes(searchTerm)

            );
            setFilteredData(newFilteredData);
        }
    }, [searchTerm, data]);


    useEffect(() => {
        const badgeCellRenderer = (props) => {
            return (
                <span
                    className={`badge badge-phoenix ${props.Status === "Unpaid"
                        ? "badge-phoenix-warning"
                        : props.Status === "Paid"
                            ? "badge-phoenix-success"
                            : "badge-phoenix-primary"
                        }`}
                >
                    {props.Status}
                </span>
            );
        };

        const LinkCellRenderer = (props) => {
            const { ID, Subject } = props.data;
            return <Link to={`/MarkStudentLectureAttendance/${ID}`}>{Subject}</Link>;
        };
        const options = (props) => {

            const { ID } = props.data;
            return (
                <div className="dropdown font-sans-serif">
                    <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i
                            className="fas fa-ellipsis-v"
                            style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                    </button>
                    <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                    >
                        <div>

                            <Link
                                to={`/updateLectures/${ID}`}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <i
                                    className="fas fa-pen"
                                    style={{ color: "blue", fontSize: "14px" }}
                                ></i>
                                <span> Update</span>
                            </Link>
                            {/* <div className="dropdown-divider"></div> */}
                        </div>

                        <Link
                            onClick={() => {
                                deleteUser(ID);
                            }}
                            className="dropdown-item cursor-pointer"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            <i
                                className="fas fa-trash"
                                style={{ color: "red", fontSize: "14px" }}
                            ></i>
                            <span> Delete</span>
                        </Link>
                    </div>
                </div>
            );
        };

        const newAgcolumns = [
            { field: "Subject", cellRenderer: LinkCellRenderer },
            { field: "Date" },
            { field: "Standard" },
            { field: "Batch" },
            { field: "Topic" },
            { field: "Faculty" },
            { field: "FromTime" },
            { field: "ToTime" },
            { field: "Duration" },
            { field: "Description" },
            { field: "Type" },
            { field: "Link" },
            { field: "MeetingID" },
            { field: "Password" },
            { field: "Marked" }
        ];
        setAgcolumns(newAgcolumns);
    }, []);

    function extractTime(inputString) {
        const parts = inputString.split(', ');
        return parts.length > 1 ? parts[1] : null;
    }

    useEffect(() => {
        if (filteredData.length > 0) {
            const newAgrows = filteredData.map((row, index) => ({
                ID: row.ID,
                Subject: row.Subject_Name,
                Standard: row.Std_Name,
                Batch: row.Batch_Name,
                Topic: row.Topic_Name,
                Date: formatDate(row.Lecture_Date),
                Faculty: row.Teacher_Name,
                FromTime: row.From_Time,
                ToTime: row.To_Time,
                Duration: formatTimestamp(row.Duration),
                Description: row.Description,
                Type: row.Type,
                Link: row.Link || "",
                MeetingID: row.Meeting_Id || "",
                Password: row.Password | "",
                Marked: row.Attendance_Marked,



            }));

            setAgrows(newAgrows);
        }
    }, [filteredData]);

    const deleteUser = (Id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id);
            }
        });
    };
    async function handleDelete(Id) {
        showLoader();

        const obj = {
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            User_Name: userData?.UserName,
        };

        try {
            await axios.delete(`${apiUrl}/deleteLecture/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Lecture Report Standard record has been deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting Lecture Report Standard record:", error);
            Swal.fire("Error", "Failed to delete Lecture Report Standard record.", "error");
        } finally {
            hideLoader();
        }
    }

    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };


    const DownloadPDF = () => {
        // Create a jsPDF instance with landscape orientation
        const doc = new jsPDF('l', 'mm', 'a4'); // 'l' for landscape, 'mm' for millimeters, 'a4' for A4 paper size
    
        doc.text("Lecture Report Subject", 20, 10);
    
        const columns = [
            "Subject",
            "Standard",
            "Batch",
            "Topic",
            "Date",
            "Faculty",
            "From Time",
            "To Time",
            "Duration",
            "Description",
            "Type",
            "Link",
            "Meeting ID",
            "Password",
            "Marked"
        ];
    
        const rows = filteredData.map((row, index) => [
            row.Subject_Name,
            row.Std_Name,
            row.Batch_Name,
            row.Topic_Name,
            formatDate(row.Lecture_Date),
            row.Teacher_Name,
            row.From_Time,
            row.To_Time,
            formatTimestamp(row.Duration),
            row.Description,
            row.Type,
            row.Link || "",
            row.Meeting_Id || "",
            row.Password || "", // Changed | to ||
            row.Attendance_Marked,
        ]);
    
        doc.autoTable({
            head: [columns],
            body: rows,
        });
    
        doc.save("Lecture Report Subject.pdf");
    };
    

    const DownloadExcel = () => {
        const columns = [
            "Subject",
            "Standard",
            "Batch",
            "Topic",
            "Date",
            "Faculty",
            "From Time",
            "To Time",
            "Duration",
            "Description",
            "Type",
            "Link",
            "Meeting ID",
            "Password",
            "Marked"
        ];

        const rows = [columns];
        filteredData.forEach((row, index) => {
            rows.push([
                row.Subject_Name,
                row.Std_Name,
                row.Batch_Name,
                row.Topic_Name,
                formatDate(row.Lecture_Date),
                row.Teacher_Name,
                row.From_Time,
                row.To_Time,
                formatTimestamp(row.Duration),
                row.Description,
                row.Type,
                row.Link || "",
                row.Meeting_Id || "",
                row.Password | "",
                row.Attendance_Marked,
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Lecture Report Subject");

        XLSX.writeFile(workBook, "Lecture Report Subject.xlsx");
    };

    const showModal = (index) => {
        setIndex(index);
        setIsModal(true);
    };
    const closed = () => {
        setIsModal(false);
        fetchData();
    };

    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true,
        });
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true,
        });
    };

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-2">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black"><Link to={`/MarkStudentLectureAttendance/${row.ID}`}>{row.Subject_Name}</Link></h5>

                                {/* <p
                                    className="mb-0 profiledetail lh-1 d-none"
                                    style={{ fontSize: "13px" }}
                                >
                                    Date :{" "}
                                    <span className="ms-1 text-1100">{formatDate(row?.Lecture_Date)}</span>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="me-3 flex-1">
                                <p
                                    className="profiledetail lh-1 mb-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Standard :{" "}
                                    <span className="ms-1 text-1100">{row.Std_Name}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Batch :{" "}
                                    <span className="ms-1 text-1100">{row.Batch_Name}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Topic :{" "}
                                    <span className="ms-1 text-1100">{row.Topic_Name}</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="me-3 flex-1">
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Date :{" "}
                                    <span className="ms-1 text-1100">{formatDate(row.Lecture_Date)}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    From Time :{" "}
                                    <span className="ms-1 text-1100">{row.From_Time}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    To Time :{" "}
                                    <span className="ms-1 text-1100">{row.To_Time}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Faculty :{" "}
                                    <span className="ms-1 text-1100">{row.Teacher_Name}</span>
                                </p>
                               


                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Duration :{" "}
                                    <span className="ms-1 text-1100">{formatTimestamp(row.Duration)}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Type :{" "}
                                    <span className="ms-1 text-1100">{row.Type}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Description :{" "}
                                    <span className="ms-1 text-1100">{row.Description}</span>
                                </p>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Link :{" "}
                                    <span className="ms-1 text-1100">{row.Link}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    MeetingID :{" "}
                                    <span className="ms-1 text-1100">{row.Meeting_Id}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Password :{" "}
                                    <span className="ms-1 text-1100">{row.Password}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Marked :{" "}
                                    <span className="ms-1 text-1100">{row.Attendance_Marked}</span>
                                </p>


                            </div>
                        </div> 

                        {/* <div className="col-md-1">
                            <div className="font-sans-serif">
                                <button
                                    style={{ padding: 0 }}
                                    className="btn btn-link"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i
                                        className="fas fa-ellipsis-v"
                                        style={{ fontSize: "14px", color: "gray" }}
                                    ></i>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <div>

                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showModal(row.rowIndex);
                                            }}
                                            className="dropdown-item cursor-pointer"
                                        >
                                            <i
                                                className="fas fa-pen"
                                                style={{ color: "blue", fontSize: "14px" }}
                                            ></i>
                                            <span> Update</span>
                                        </Link>

                                        <div className="dropdown-divider"></div>
                                    </div>

                                    <Link
                                        onClick={() => {
                                            deleteUser(row.ID);
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <i
                                            className="fas fa-trash"
                                            style={{ color: "red", fontSize: "14px" }}
                                        ></i>
                                        <span> Delete</span>
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )}
                {design.dataView === "Tile View" && (
                    <div
                        key={index}
                        className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
                    >
                        <div className="card h-100 hover-actions-trigger">
                            <div className="card-body" style={{ padding: "15px" }}>
                                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                    <div
                                        className="row justify-content-between me-1"
                                        style={{ flex: "auto", marginTop: "8px" }}
                                    >
                                        <div className="col-11 pe-1">
                                            <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                                                <Link to={`/MarkStudentLectureAttendance/${row.ID}`}>{row.Subject_Name}</Link>
                                            </h6>
                                            {/* <p
                                                className="mb-0 profiledetail lh-1"
                                                style={{ fontSize: "13px" }}
                                            >
                                                Date :{" "}
                                                <span className="ms-1 text-1100">{formatDate(row?.Lecture_Date)}</span>
                                            </p> */}
                                        </div>
                                        <div className="end-0 col-1 p-0 d-none">
                                            <button
                                                style={{ padding: 0 }}
                                                className="btn btn-link"
                                                id="dropdownMenuLink"
                                                href="#"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i
                                                    className="fas fa-ellipsis-v"
                                                    style={{ fontSize: "14px", color: "gray" }}
                                                ></i>
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-end py-0"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <div>

                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            showModal(row.rowIndex);
                                                        }}
                                                        className="dropdown-item cursor-pointer"
                                                    >
                                                        <i
                                                            className="fas fa-pen"
                                                            style={{ color: "blue", fontSize: "14px" }}
                                                        ></i>
                                                        <span> Update</span>
                                                    </Link>

                                                    <div className="dropdown-divider"></div>
                                                </div>

                                                <Link
                                                    onClick={() => {
                                                        deleteUser(row.ID);
                                                    }}
                                                    className="dropdown-item cursor-pointer"
                                                >
                                                    <i
                                                        className="fas fa-trash"
                                                        style={{ color: "red", fontSize: "14px" }}
                                                    ></i>
                                                    <span> Delete</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail text-truncate lh-1">Standard : <span className="fw-semi-bold ms-1"> {row.Std_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2  ">
                                    {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail lh-1">Batch : <span className="ms-1 text-1100">{row.Batch_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail lh-1">Topic : <span className="ms-1 text-1100">{row.Topic_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail lh-1">Date : <span className="ms-1 text-1100">{formatDate(row.Lecture_Date)}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                            
                                    <p className="mb-0 profiledetail lh-1">From Time : <span className="ms-1 text-1100">{row.From_Time}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                            
                                    <p className="mb-0 profiledetail lh-1">To Time : <span className="ms-1 text-1100">{row.To_Time}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Faculty : <span className="ms-1 text-1100">{row.Teacher_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail lh-1">Duration : <span className="ms-1 text-1100">{formatTimestamp(row.Duration)}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Type : <span className="ms-1 text-1100">{row.Type}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Description : <span className="ms-1 text-1100">{row.Description}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Link : <span className="ms-1 text-1100">{row.Link}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Meeting ID : <span className="ms-1 text-1100">{row.Meeting_Id}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Password : <span className="ms-1 text-1100">{row.Password}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 profiledetail lh-1">Marked : <span className="ms-1 text-1100">{row.Attendance_Marked}</span></p>
                                </div>
                               

                              

                            </div>
                        </div>
                    </div>
                )}
            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="content">
            <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
                <div className="container-fluid">
                    {/* Sidebar */}
                    <div id="sidebar-wrapper">
                        <div className="row g-4">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div
                                    className="card shadow-none border border-300 my-2"
                                    data-component-card="data-component-card"
                                >
                                    <div className="row justify-content-center d-flex p-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Branch">
                                                        Branch
                                                    </label>
                                                    <HeadMasterDropDown
                                                        label="Branch"
                                                        name="Branch"
                                                        id="Branch"
                                                    //onChange={(e) => setBranch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        From Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={formatInputDate(fromDate)}
                                                        onChange={(date) =>
                                                            setFromDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        To Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={formatInputDate(toDate)}
                                                        onChange={(date) =>
                                                            setToDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Standard">
                                                        Standard :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Standard"
                                                        disabled={userData?.Head_Id === ''}
                                                        value={Std_Id}
                                                        onChange={(e) => {
                                                            setStdId(e.target.value);
                                                            setBatchID('All');
                                                        }}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {StdOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        Batch :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="example-select"
                                                        value={Batch_ID}
                                                        disabled={Std_Id === ''}
                                                        onChange={(e) => setBatchID(e.target.value)}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {BatchOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Status">
                                                        Type :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="example-select"
                                                        value={Status}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    >
                                                        <option selected value="">All</option>
                                                        <option value="Offline">Offline</option>
                                                        <option value="Online">Online</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center p-2">
                                        <div className="col-md-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm text-center"
                                                onClick={handleDateRangeFilter}
                                            >
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="row justify-content-between">
                            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                                Lecture Report
                                <a
                                    className="anchorjs-link "
                                    aria-label="Anchor"
                                    href="#basic-form"
                                    style={{ paddingLeft: "0.375em" }}
                                ></a>
                            </h4>
                            <div className="col-md-12 text-center mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search..."
                                    style={{
                                        float: "left",
                                        display: "inline-block",
                                        width: "200px",
                                    }}
                                ></input>
                                <Link to={"/addLectures"} style={{ float: "right", display: "none" }}>
                                    <button className="btn btn-primary btn-sm text-center">
                                        Add
                                    </button>
                                </Link>
                                <button
                                    type="button"
                                    className="btn bg-200 filterButton collapsed mr-2"
                                    data-toggle="collapse"
                                    onClick={toggle}
                                    data-target="#navbar"
                                    aria-expanded="false"
                                    aria-controls="navbar"
                                    style={{ float: "right", marginRight: "10px" }}
                                >
                                    <i className="fa fa-filter"></i>
                                </button>
                                <button
                                    className="form-control dropdown-toggle viewButton  mb-2"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                        float: "right",
                                        marginRight: "10px",
                                        width: "auto",
                                        padding: "4px 10px",
                                    }}
                                >
                                    <>
                                        {design.dataView === "Table View" && (
                                            <TableChartOutlinedIcon />
                                        )}
                                    </>
                                    <>
                                        {design.dataView === "List View" && (
                                            <FormatListBulletedIcon />
                                        )}
                                    </>
                                    <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <Link
                                        onClick={(e) => {
                                            setDesign({
                                                ...design,
                                                dataView: "Table View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Table View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <TableChartOutlinedIcon />
                                        <span> Table View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "List View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "List View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <FormatListBulletedIcon />
                                        <span> List View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "Tile View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Tile View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <GridViewIcon />
                                        <span> Tile View</span>
                                    </Link>
                                </div>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to PDF</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadPDF()}
                                        style={{ float: "right", padding: "5px 10px 5px 5px" }}
                                    >
                                        <i
                                            className="fas fa-file-pdf"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to Excel</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadExcel()}
                                        style={{ float: "right", padding: "5px 5px 5px 10px" }}
                                    >
                                        <i
                                            className="fas fa-file-excel"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row">
                            {design.dataView === "Table View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    {Agrows.length > 0 && (
                                        <AgGridTable row={Agrows} column={Agcolumns} />
                                    )}
                                </div>
                            )}
                            {design.dataView === "Tile View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">{displayData}</div>
                                </div>
                            )}
                            {design.dataView === "List View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                            <div className="card h-100 hover-actions-trigger">
                                                <div
                                                    className="card-body"
                                                    style={{ paddingTop: "5px" }}
                                                >
                                                    {displayData}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(design.dataView === "List View" ||
                                design.dataView === "Tile View") && (
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        pageCount={pageCount}
                                        pageRangeDisplayed={1}
                                        marginPagesDisplayed={1}
                                        breakAriaLabels={{
                                            forward: "Jump forward",
                                            backward: "Jump backward",
                                        }}
                                        onPageChange={changePage}
                                        containerClassName={"pagination"}
                                        disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                                        previousLinkClassName={"btn btn-outline-primary me-2"}
                                        nextLinkClassName={"btn btn-outline-primary ms-2"}
                                        activeLinkClassName={"customclass ms-2 me-2"}
                                        pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {/* {isModal && (
                    <UpdateStatus
                        open={isModal}
                        onClose={closed}
                        updateData={data[indexValue]}
                    />
                )} */}
                {loader}
            </div>
        </div>
    );
};

export default LectureReportSubject;
