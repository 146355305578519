import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Switch from "react-switch";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";

const ReceiptSettingsPrefix = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [Bill, setBill] = useState([]);
    const [errors, setErrors] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
      };
    
      const handleCancelEdit = () => {
        setIsEditing(false);
      };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData]);

    const fetchData = async () => {
        try {
            showLoader();

            if (userData && userData.Org_Id) {
                showLoader();
                const get = await axios.get(
                    `${apiUrl}/getFinancialYear?OrgId=${userData.Org_Id}`
                );

                setBill(get.data);
                console.log(get.data);
            }
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };
    console.log(Bill)

    const handleSwitchChange = (index, e) => {
        const updatedBill = [...Bill];
        updatedBill[index].Rec_Prefix = e;
        setBill(updatedBill);

    };

    const handleSubmit = () => {
        debugger;
        const updateBills = Bill.map((item) => ({
            ...item,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        }));

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateRecPrefix`, updateBills)
                .then((res) => {
                    Swal.fire("Success!", "Setting Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                fetchData();
                                handleCancelEdit();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">Student ID Prefix</div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
                        </div>
                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">

                                <div className="row">

                                    <table
                                        className="table table-bordered border-primary table-hover table-responsive"
                                        cellSpacing="0"
                                        cellPadding="4"
                                        align="Center"
                                        rules="cols"
                                        style={{
                                            color: "Black",
                                            backgroundColor: "White",
                                            borderColor: "#DEDFDE",
                                            borderWidth: "1px",
                                            borderStyle: "None",
                                            borderCollapse: "collapse",
                                        }}
                                    >
                                        <tbody>
                                            <tr align="center">
                                                <th scope="col">Sr No</th>
                                                <th scope="col">Staff</th>
                                                <th scope="col">Year</th>
                                            </tr>
                                            {Bill.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.Financial_Year}
                                                    </td>
                                                    <td className="text-center p-2">
                                                        <input
                                                            className="form-control form-control-sm"
                                                            type="text"
                                                            disabled={!isEditing}
                                                            value={item.Rec_Prefix}
                                                            onChange={(e) => handleSwitchChange(index, e.target.value)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="row">
              <div className="col-md-12 text-center mt-3">
                {!isEditing && (
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                )}{" "}
                &nbsp;

                {isEditing && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={

                      handleCancelEdit
                    }
                  >
                    Close
                  </button>
                )}  &nbsp;
                {isEditing && (
                
                    <button type="submit" onClick={handleSubmit} className="btn btn-sm btn-primary">
                      Save Changes
                    </button>
                )
                }



              </div>
            </div>
                                </div>

                            </div>
                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptSettingsPrefix;
