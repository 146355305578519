import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { useUser } from "../../Components/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatDate, formatInputDate } from "../../Components/dateFormate";

const AddUpdateTests = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { ID } = useParams();
  const [errors, setErrors] = useState({});
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [TopicOption, setTopicOption] = useState([]);




  const [Test, setTest] = useState({
    Std_Id: "",
    Std_Name: "",
    Batch_Id: "",
    Batch_Name: "",
    Subject_Id: "",
    Subject_Name: "",
    Test_type_Id: "",
    Test_Type: "",
    Test_Date: "",
    Test_Date_Txt: "",
    Time_From: "",
    Time_To: "",
    Duration: "",
    Syllabus_Remarks: "",
    Uploaded_By_Id: "",
    Min_Marks: "",
    Total_Marks: "",
    Attendance_Marked: "",
    Result_Uploaded: "",
    Lecture_Id: "",
    Topic_Id: "",
    Topic_Name: "",
    Org_Id: "",
    Question_Paper: "",
    Answer_Paper: ""
  });

  console.log(Test)

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;

    const updateTest = {
      ...Test,
      Time_From: new Date(Test.Time_From).toLocaleTimeString("en-GB"),
      Time_To: new Date(Test.Time_To).toLocaleTimeString("en-GB"),
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addTest`, updateTest)
        .then((res) => {
          Swal.fire("Success!", "Test Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/viewTest");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateTest = {
      ...Test,
      From_Time: new Date(Test.From_Time).toLocaleTimeString("en-GB"),
      To_Time: new Date(Test.To_Time).toLocaleTimeString("en-GB"),
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateTest/${ID}`, updateTest)
        .then((res) => {
          Swal.fire("Success!", "Test Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/viewTests");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
   

        if (userData?.Org_Id && userData?.Head_Id) {
          const [stdRes] = await Promise.all([
            axios.get(`${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`),
          ]);
    
          const stdOptions = stdRes.data.map((category) => ({
            label: category.Std_Name,
            value: category.Std_Id,
          }));
          setStdOptions(stdOptions);
        }

        if (userData && userData.Org_Id && Test?.Std_Id) {
          const [batchRes, subRes] = await Promise.all([
            axios.get(`${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Test.Std_Id}`),
            axios.get(`${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Test.Std_Id}`),
          ]);
          const batchOptions = batchRes.data.map((category) => ({
            label: category.Batch_Name,
            value: category.Batch_Id,
          }));
          setBatchOptions(batchOptions);
          const subOptions = subRes.data.map((category) => ({
            label: category.Sub_Name,
            value: category.Sub_Id,
          }));
          setSubjectOption(subOptions);
        }

        if (userData && userData.Org_Id && Test?.Subject_Id) {
          const result = await axios.get(`${apiUrl}/getTopic?Sub_Id=${Test.Subject_Id}&Org_Id=${userData.Org_Id}`);
          const topicOptions = result.data.map((category) => ({
            label: category.Topic_Name,
            value: category.Topic_Id,
          }));
          setTopicOption(topicOptions);
        }

        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData, Test.Std_Id, Test.Subject_Id]);


  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            {ID ? "Update Test Schedule" : "Publish Test Schedule"}
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
            {/* {!ID && (
              <Link
                to={"/addMultipleTests"}
                style={{ float: "right", marginLeft: "10px" }}
              >
                <button className="btn btn-primary btn-sm text-center">
                  Add Multiple Tests
                </button>
              </Link>
            )} */}
            <Link
              to={"/viewTest"}
              style={{ float: "right", marginLeft: "10px" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                View Test
              </button>
            </Link>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Standard">
                        Standard<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Standard"
                        id="Standard"
                        required
                        options={StdOptions}
                        //isDisabled={ID}
                        value={
                          Test.Std_Id
                            ? {
                              value: Test.Std_Id,
                              label: Test.Std_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setTest({
                            ...Test,
                            Std_Id: selectedOption.value,
                            Std_Name: selectedOption.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Batch">
                        Batch<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Test.Std_Id === "" ? true : false}
                        name="Batch"
                        id="Batch"
                        required
                        options={BatchOptions}
                        value={
                          Test.Batch_Id
                            ? {
                              value: Test.Batch_Id,
                              label: Test.Batch_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setTest({
                            ...Test,
                            Batch_Id: selectedOption.value,
                            Batch_Name: selectedOption.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Test_Date">
                        Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="Test_Date"
                        name="Test_Date"
                        required
                        value={formatInputDate(Test.Test_Date)}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setTest({
                            ...Test,
                            Test_Date: e.target.value,
                          });
                        }}
                      />

                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Subject">
                        Subject<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Test.Std_Id === "" ? true : false}
                        name="Subject"
                        id="Subject"
                        required
                        options={SubjectOption}
                        value={
                          Test.Subject_Id
                            ? {
                              value: Test.Subject_Id,
                              label: Test.Subject_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setTest({
                            ...Test,
                            Subject_Id: selectedOption.value,
                            Subject_Name: selectedOption.label,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Topic">
                        Topic<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Test.Subject_Id === "" ? true : false}
                        name="Topic"
                        id="Topic"
                        required
                        options={TopicOption}
                        value={
                          Test.Topic_Id
                            ? {
                              value: Test.Topic_Id,
                              label: Test.Topic_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setTest({
                            ...Test,
                            Topic_Id: selectedOption.value,
                            Topic_Name: selectedOption.label,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="starttime">
                        From Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Test.Time_From ? dayjs(Test.Time_From) : null}
                          className="form-control"
                          slotProps={{
                            textField: { size: "small", required: true },
                          }}
                          renderInput={(props) => (
                            <input
                              {...props}
                              type="text"
                              className="form-control"
                              style={{ width: "100%" }}
                              size="small"
                              placeholder=""
                              required
                            />
                          )}
                          onChange={(startTime) =>
                            setTest({ ...Test, Time_From: startTime })
                          }
                        />
                      </LocalizationProvider>

                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Time_To">
                        To Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Test.Time_To ? dayjs(Test.Time_To) : null}
                          required
                          className="form-control"
                          slotProps={{
                            textField: { size: "small", required: true },
                          }}
                          onChange={(startTime) =>
                            setTest({ ...Test, Time_To: startTime })
                          }
                        />
                      </LocalizationProvider>
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Min_Marks">
                        Minimum Marks<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Min_Marks"
                        name="Min_Marks"
                        required
                        placeholder="Enter Minimum Marks"
                        value={Test.Min_Marks}
                        onChange={(e) => {
                          setTest({
                            ...Test,
                            Min_Marks: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Total_Marks">
                        Total Marks<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Total_Marks"
                        name="Total_Marks"
                        required
                        placeholder="Enter Total Marks"
                        value={Test.Total_Marks}
                        onChange={(e) => {
                          setTest({
                            ...Test,
                            Total_Marks: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Syllabus_Remarks">
                        Syllabus/Remarks
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="Syllabus_Remarks"
                        placeholder="Syllabus Remarks..."
                        rows={2}
                        value={Test.Syllabus_Remarks}
                        onChange={(e) => {
                          setTest({
                            ...Test,
                            Syllabus_Remarks: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUpdateTests;
