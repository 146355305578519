import "../Students/toggle.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import "jspdf-autotable";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import { formatDate } from "../../Components/dateFormate";

const ReportCardPdf = () => {
  const location = useLocation();
  const { reportCardNum } = location.state;
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [reportCardData, setReportCardData] = useState([]);
  const [reportCardDetails, setReportCardDetails] = useState([]);

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
    }
  }, [userData]);

  const fetchData = async () => {
    try {
      showLoader();

      const resHead = await axios.get(`${apiUrl}/getReportCardHeader?num=${reportCardNum}`);
      setReportCardData(resHead.data);
      console.log(resHead.data);

      const resData = await axios.get(`${apiUrl}/getReportCardData?num=${reportCardNum}`);
      setReportCardDetails(resData.data);
      console.log(resData.data);

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  return (
    <div className="content">
      <style>{`
        .report-card {
          width: 210mm;
          margin: 20px auto;
          border: 1px solid #000;
          padding: 20px;
          page-break-after: always;
        }

        .header, .footer {
          text-align: center;
        }

        .header img {
          max-width: 100px;
        }

        .student-info {
          margin: 20px 0;
        }

        .student-info, .signature {
          display: flex;
          justify-content: space-between;
        }

        .table {
          margin-bottom: 0;
        }

        .table th, .table td {
          border: 1px solid #000;
          padding: 8px;
          text-align: center;
          vertical-align: middle;
        }

        .signature {
          margin-top: 30px;
        }

        .percentage {
          text-align: left;
          margin-top: 10px;
        }

        h3 {
          font-weight: bold;
        }

        .bold-text {
          font-weight: bold;
        }
      `}</style>

      <div className="container-fluid">
        {reportCardData.map((student, index) => (
          <div key={student.ID} className="report-card">
            <div className="header">
              <div className="row">
                <div className="col-2">
                  <img src={student.Logo} alt="Logo" />
                </div>
                <div className="col-8">
                  <h3>{student.OrgName}</h3>
                  <p className="bold-text">{student.Head_Address}</p>
                </div>
                <div className="col-2">
                  <img src={student.Photo} alt="Student Photo" />
                </div>
              </div>
            </div>

            <div className="student-info">
              <div>
                <p>
                  <strong>Name :</strong> {student.Name}
                </p>
                <p>
                  <strong>Std Name :</strong> {student.Std_Name}
                </p>
              </div>
              <div>
                <p>
                  <strong>Student Id :</strong> {student.Student_Id}
                </p>
                <p>
                  <strong>Batch Name :</strong> {student.Batch_Name}
                </p>
              </div>
            </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>Sr. No.</th>
                  <th style={{ width: "15%" }}>Subject</th>
                  <th style={{ width: "10%" }}>Date</th>
                  <th style={{ width: "30%" }}>Topics</th>
                  <th>Marks Secured</th>
                  <th>Total Marks</th>
                  <th>Toppers Marks</th>
                </tr>
              </thead>
              <tbody>
                {reportCardDetails.filter(detail => detail.Id === student.ID).map((detail, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{detail.Sub_Name}</td>
                    <td>{formatDate(detail.Test_Date)}</td>
                    <td>{detail.Topic_Name}</td>
                    <td>{detail.Attendance === 'Absent' ? 'Absent' : detail.Marks_Obtained}</td>
                    <td>{detail.Total_Marks}</td>
                    <td>{detail.Max_Marks}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="percentage">
              <p>
                <strong>Percentage :</strong> {calculatePercentage(reportCardDetails.filter(detail => detail.Id === student.ID))}
              </p>
            </div>

            <div className="signature">
              <div>
                <p>Father Signature</p>
              </div>
              <div>
                <p>Mother Signature</p>
              </div>
              <div>
                <p>Student Signature</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const calculatePercentage = (details) => {
  const totalMarks = details.reduce((sum, detail) => sum + detail.Total_Marks, 0);
  const marksObtained = details.reduce((sum, detail) => sum + (detail.Attendance === 'Absent' ? 0 : detail.Marks_Obtained), 0);
  return totalMarks ? ((marksObtained / totalMarks) * 100).toFixed(2) : 'N/A';
};

export default ReportCardPdf;
