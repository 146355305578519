import React, { useState, useEffect } from "react";
import axios from "../../../Components/apiConfig";
import { apiUrl } from '../../../Components/apiConfig';
import useFullPageLoader from "../../../Components/useFullPageLoader";
import {  useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDate } from "../../../Components/dateFormate";
import { useUser } from "../../../Components/UserContext";
import html2pdf from "html2pdf.js";

const hrStyle = {
  border: 'none',
  borderTop: '1px dashed #000',
  height: '1px',
  width: '100%',
};

const FeesReceipts = ({feesData, headData, Role, ID}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [studentData, setStudentData] = useState({});
  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);


  useEffect(() => {
    loadData();
  }, [userData, ID]);

  const generateAndSavePDFFFFF = async () => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: `${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
  
    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");
      showLoader();
  
      const formData = new FormData();
      formData.append("FeesReceipt", pdfBlob, `${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`);
  
      const response = await axios.post(`/storeFeesReceipt`, formData);
      console.log(response.data);

      if (response?.data) {
        await axios.get(`/downloadFeesReceipt/${response.data}`, {
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }

    } catch (error) {
      console.error("Error generating or downloading PDF:", error);
    } finally {
      hideLoader();
    }
  };


  useEffect(() => {
    showLoader();
    setIsPDFDownloaded(false);
    if (feesData && !isPDFDownloaded) {
      generateAndSavePDF();
    }
    hideLoader();
  }, [feesData]);
  
  const generateAndSavePDF = async () => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: `${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = `${feesData[0]?.Name}_${feesData[0]?.Sr_No}.pdf`;
      link.click();
      setIsPDFDownloaded(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const loadData = async () => {
    showLoader();
    try {
     


      if (ID && userData?.Org_Id) {
        const studentDetails = await axios.get(
          `/getStudentDetailsForFeesReport?OrgId=${userData?.Org_Id}&ID=${ID}`
        );
        console.log(studentDetails.data);
        setStudentData(studentDetails.data);
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  function number2text(value) {
    var fraction = Math.round(frac(value) * 100);
    var f_text = "";

    if (fraction > 0) {
      f_text = "AND " + convert_number(fraction) + " PAISE";
    }

    return convert_number(value) + " RUPEES " + f_text + " ONLY";
  }

  function frac(f) {
    return f % 1;
  }

  function convert_number(number) {
    if (number < 0 || number > 999999999) {
      return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    var kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    var Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    var Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    var tn = Math.floor(number / 10);
    var one = Math.floor(number % 10);
    var res = "";

    if (Gn > 0) {
      res += convert_number(Gn) + " CRORE";
    }
    if (kn > 0) {
      res += (res === "" ? "" : " ") + convert_number(kn) + " LAKH";
    }
    if (Hn > 0) {
      res += (res === "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
    }

    if (Dn) {
      res += (res === "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
    }

    var ones = [
      "",
      "ONE",
      "TWO",
      "THREE",
      "FOUR",
      "FIVE",
      "SIX",
      "SEVEN",
      "EIGHT",
      "NINE",
      "TEN",
      "ELEVEN",
      "TWELVE",
      "THIRTEEN",
      "FOURTEEN",
      "FIFTEEN",
      "SIXTEEN",
      "SEVENTEEN",
      "EIGHTEEN",
      "NINETEEN",
    ];
    var tens = [
      "",
      "",
      "TWENTY",
      "THIRTY",
      "FORTY",
      "FIFTY",
      "SIXTY",
      "SEVENTY",
      "EIGHTY",
      "NINETY",
    ];

    if (tn > 0 || one > 0) {
      if (!(res === "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += "-" + ones[one];
        }
      }
    }

    if (res === "") {
      res = "ZERO";
    }
    return res;
  }

  const createCustomInvoicePDF = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    // Print the document
    window.print();

    // Restore the original contents
    document.body.innerHTML = originalContents;
  };


  return (
    <div className="content">

      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">

                <div className="row mb-4">
<button className="btn btn-sm btn-primary" onClick={generateAndSavePDF}>Print</button>
                </div>

              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="container">
                  <div className="div" id="printablediv">
                    <div className="div" style={{fontSize:"12px"}}>
                      <div className="row mb-1">
                        <div className="col-md-4 col-sm-4">
                          <img
                            src={`/CustomerLogo/${userData?.Web_Logo}`}
                            alt="logo"
                            style={{
                              maxWidth: "330px",
                              height: "auto",
                              width: "100%",
                              paddingLeft: 0,
                            }}
                          />
                        </div>
                        <div className="col-md-8 col-sm-8 text-center">
                          <h4 className="mb-1">{userData?.Org_Name}</h4>
                          <p style={{ marginBottom: 0, color: "#333" }}>
                            {headData?.Head_Address}
                          </p>
                          <div className="text-center" style={{ color: "#333" }}>
                            {/* <strong>Phone:</strong> 999999999/8888888888 */}
                            {/* <strong>GSTIN/UIN :</strong> 27AQUPS8980K2ZH */}
                            <strong>Fee Receipt :</strong> [Admin Copy]
                          </div>
                          {/* <div className="text-center" style={{ color: "#333" }}>
                      <strong>State Name :</strong> Maharashtra &nbsp;&nbsp;
                      <strong>Code :</strong> 27
                    </div>{" "} */}
                          {/* <div className="text-center" style={{ color: "#333" }}>
                      <strong>Email :</strong>{" "}
                      <a href="mailto:environaturesolution@gmail.com">
                        environaturesolution@gmail.com
                      </a>
                    </div> */}
                        </div>
                      </div>
                      <hr
                        className="mb-2"
                        style={{ borderWidth: "1px", margin: 0 }}
                      />
                      <div
                        className="row justify-content-between"
                        style={{ color: "#333" }}
                      >
                        <div className="col-md-4 col-sm-4">
                          <address style={{marginBottom:"auto"}}>
                            <strong>Receipt No. :</strong>&nbsp;{feesData[0]?.Receipt_No_Txt}
                            <br className="mb-1" />
                            <strong>Name :</strong>&nbsp;{feesData[0]?.Name}
                          </address>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <table className="table-responsive">
                            <tbody>

                              <tr>
                                <td
                                  className="pull-right"
                                  style={{
                                    paddingRight: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  <strong>Student ID :</strong>
                                </td>
                                <td>{studentData?.Student_Id}</td>

                              </tr>

                              <tr>
                                <td
                                  className="pull-right"
                                  style={{ paddingRight: "8px", textAlign: "right" }}
                                >
                                  <strong>Course :</strong>
                                </td>
                                <td>{studentData?.Course_Name}</td>
                              </tr>


                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <table className="table-responsive">
                            <tbody>

                              <tr>
                                <td
                                  className="pull-right"
                                  style={{
                                    paddingRight: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  <strong>Date :</strong>
                                </td>
                                <td>{formatDate(feesData[0]?.Payment_Date)}</td>
                              </tr>


                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row mb-1 mt-2">
                        <div className="span8 well invoice-body">
                          <div className="col-md-12 col-sm-12 table-responsive">
                            <table className="table productTable display table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th
                                    className="text-left"
                                    style={{ minWidth: "500px", color: "#333", paddingLeft: "10px" }}
                                  >
                                    Particulars
                                  </th>
                                  <th className="text-left" style={{ color: "#333" }}>
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {feesData.map((pro, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td style={{ padding: 5 }}>Fees<br />{pro.Late_Fee_Amt !== 0 ? 'Late Payment Charges' : ""}<br />
                                        {headData.Head_GST_No !== 'NA' ? `CGST(${parseFloat(pro.CGST_Percent || 0.00).toFixed(2)}%):${parseFloat(pro.CGST_Amt || 0.00).toFixed(2)},${' '}SGST(${parseFloat(pro.SGST_Percent || 0.00).toFixed(2)}%):${parseFloat(pro.SGST_Amt || 0.00).toFixed(2)},${' '}GST No. :${headData?.Head_GST_No}` : ""}
                                      </td>
                                      <td className="text-left" style={{ padding: 5 }}>
                                        {parseFloat(pro.T_Amt || 0).toFixed(2)}<br />{pro.Late_Fee_Amt ? parseFloat(pro.Late_Fee_Amt || 0).toFixed(2) : ""}<br />{headData.Head_GST_No !== 'NA'
                                          ? parseFloat((pro.CGST_Amt || 0) + (pro.SGST_Amt || 0)).toFixed(2)
                                          : ""}
                                      </td>
                                    </tr><tr>
                                      <td style={{ padding: 7 }} colSpan={1}>
                                        <strong>Net Total : </strong>{`${number2text(parseFloat(
                                          (headData.Head_GST_No !== 'NA' ? (pro.CGST_Amt || 0) + (pro.SGST_Amt || 0) : 0) +
                                          (pro.Late_Fee_Amt !== 0 ? pro.Late_Fee_Amt || 0 : 0) +
                                          (pro.T_Amt || 0)
                                        ).toFixed(2))}`}
                                      </td>
                                      <td style={{ padding: 7 }} align="left">
                                        <strong>
                                          {(
                                            (headData.Head_GST_No !== 'NA' ? (pro.CGST_Amt || 0) + (pro.SGST_Amt || 0) : 0) +
                                            (pro.Late_Fee_Amt !== 0 ? pro.Late_Fee_Amt || 0 : 0) +
                                            (pro.T_Amt || 0)
                                          ).toFixed(2)}
                                        </strong>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                                <tr>
                                  <td style={{ padding: 7 }} colSpan={2}>
                                    <div className="row col-md-12">
                                      <div className="col-md-4 col-sm-4">


                                        <strong>Payment Mode :</strong>

                                        &nbsp;{feesData[0]?.Payment_Mode}
                                      </div>
                                      <div className="col-md-4 col-sm-4">

                                      {feesData[0]?.Bank_Name && (
        <>
          <strong>Bank Name :</strong>&nbsp;{feesData[0]?.Bank_Name}
        </>
      )}
                                       

                                      </div>
                                      <div className="col-md-4 col-sm-4">


                                        <strong>Receipt Status :</strong>

                                        &nbsp;{feesData[0]?.Status}

                                      </div>
                                      <div className="col-md-4 col-sm-4">


                                      {feesData[0]?.Cheque_Date && (
        <>
          <strong>Cheque Date :</strong>&nbsp;{formatDate(feesData[0]?.Cheque_Date)}
        </>
      )}

                                      </div>

                                      <div className="col-md-4 col-sm-4">

                                      {feesData[0]?.Cheque_No && (
        <>
          <strong>Cheque No :</strong>&nbsp;{feesData[0]?.Cheque_No}
          <br />
        </>
      )}

                                      </div>

                                      <div className="col-md-4 col-sm-4">


                                      {feesData[0]?.Cheque_Status && (
        <>
          <strong>Cheque Status :</strong>&nbsp;{feesData[0]?.Cheque_Status}
          <br />
        </>
      )}

                                      </div>
                                    </div>
                                  </td>

                                </tr>
                                <tr>
                                  <td style={{ padding: 7 }} colSpan={2}>
                                    <div className="row col-md-12">
                                      <div className="col-md-6 col-sm-6">


                                        <strong>Total Paid :</strong>

                                        &nbsp;{parseFloat(feesData[0]?.Amt_Recieved||0).toFixed(2)}

                                      </div>    <div className="col-md-6 col-sm-6">


                                        <strong>Balance :</strong>

                                        &nbsp;{parseFloat(feesData[0]?.Bal_Amt||0).toFixed(2)}

                                      </div>
                                      <div className="col-md-6 col-sm-6">


                                      {feesData[0]?.Next_Installment_Amt!==0 && (
        <>
          <strong>Next Installment :</strong>&nbsp;
          {parseFloat(feesData[0]?.Next_Installment_Amt || 0).toFixed(2)}
     
        </>
      )}
                                      </div>
                                      <div className="col-md-6 col-sm-6">


                                      {feesData[0]?.Next_Installment_Date!==null && (
        <>
          <strong>Due Date :</strong>&nbsp;
          {feesData[0]?.Next_Installment_Date ? formatDate(feesData[0]?.Next_Installment_Date) : ""}
      
        </>
      )}
                                      </div>
                                    </div>

                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <p style={{ color: "#333" }}>
                          <strong>1. This receipt is subject to realisation of cheque.<br />
                            2. This receipt should be carefully preserved and must be produced on demand.<br />
                            3. Fees once paid are not refundable/transferable in any circumstances.
                            {/* <br/>4. If Cheque dishonoured by bank Rs.250/- charges will be applicable. */}
                          </strong>
                        </p>
                        <div className="text-center" style={{ color: "#333" }}>
                          {/* <h6>SUBJECT TO NAVI MUMBAI JURISDICTION</h6> */}
                          <p>*This is a Computer Generated Receipt*</p>
                        </div>
                      </div>
                    </div>
                    <hr style={hrStyle}/>
                    <div className="div" style={{fontSize:"12px"}}>
                      <div className="row mb-1">
                        <div className="col-md-4 col-sm-4">
                          <img
                            src={`/CustomerLogo/${userData?.Web_Logo}`}
                            alt="logo"
                            style={{
                              maxWidth: "330px",
                              height: "auto",
                              width: "100%",
                              paddingLeft: 0,
                            }}
                          />
                        </div>
                        <div className="col-md-8 col-sm-8 text-center">
                          <h4 className="mb-1">{userData?.Org_Name}</h4>
                          <p style={{ marginBottom: 0, color: "#333" }}>
                            {headData?.Head_Address}
                          </p>
                          <div className="text-center" style={{ color: "#333" }}>
                            {/* <strong>Phone:</strong> 999999999/8888888888 */}
                            {/* <strong>GSTIN/UIN :</strong> 27AQUPS8980K2ZH */}
                            <strong>Fee Receipt :</strong> [Student Copy]
                          </div>
                          {/* <div className="text-center" style={{ color: "#333" }}>
                      <strong>State Name :</strong> Maharashtra &nbsp;&nbsp;
                      <strong>Code :</strong> 27
                    </div>{" "} */}
                          {/* <div className="text-center" style={{ color: "#333" }}>
                      <strong>Email :</strong>{" "}
                      <a href="mailto:environaturesolution@gmail.com">
                        environaturesolution@gmail.com
                      </a>
                    </div> */}
                        </div>
                      </div>
                      <hr
                        className="mb-2"
                        style={{ borderWidth: "1px", margin: 0 }}
                      />
                      <div
                        className="row justify-content-between"
                        style={{ color: "#333" }}
                      >
                        <div className="col-md-4 col-sm-4">
                          <address style={{marginBottom:"auto"}}>
                            <strong>Receipt No. :</strong>&nbsp;{feesData[0]?.Receipt_No_Txt}
                            <br className="mb-1" />
                            <strong>Name :</strong>&nbsp;{feesData[0]?.Name}
                          </address>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <table className="table-responsive">
                            <tbody>

                              <tr>
                                <td
                                  className="pull-right"
                                  style={{
                                    paddingRight: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  <strong>Student ID :</strong>
                                </td>
                                <td>{studentData?.Student_Id}</td>

                              </tr>

                              <tr>
                                <td
                                  className="pull-right"
                                  style={{ paddingRight: "8px", textAlign: "right" }}
                                >
                                  <strong>Course :</strong>
                                </td>
                                <td>{studentData?.Course_Name}</td>
                              </tr>


                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <table className="table-responsive">
                            <tbody>

                              <tr>
                                <td
                                  className="pull-right"
                                  style={{
                                    paddingRight: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  <strong>Date :</strong>
                                </td>
                                <td>{formatDate(feesData[0]?.Payment_Date)}</td>
                              </tr>


                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row mb-1 mt-2">
                        <div className="span8 well invoice-body">
                          <div className="col-md-12 col-sm-12 table-responsive">
                            <table className="table productTable display table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th
                                    className="text-left"
                                    style={{ minWidth: "500px", color: "#333", paddingLeft: "10px" }}
                                  >
                                    Particulars
                                  </th>
                                  <th className="text-left" style={{ color: "#333" }}>
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {feesData.map((pro, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td style={{ padding: 5 }}>Fees<br />{pro.Late_Fee_Amt !== 0 ? 'Late Payment Charges' : ""}<br />
                                        {headData.Head_GST_No !== 'NA' ? `CGST(${parseFloat(pro.CGST_Percent || 0.00).toFixed(2)}%):${parseFloat(pro.CGST_Amt || 0.00).toFixed(2)},${' '}SGST(${parseFloat(pro.SGST_Percent || 0.00).toFixed(2)}%):${parseFloat(pro.SGST_Amt || 0.00).toFixed(2)},${' '}GST No. :${headData?.Head_GST_No}` : ""}
                                      </td>
                                      <td className="text-left" style={{ padding: 5 }}>
                                        {parseFloat(pro.T_Amt || 0).toFixed(2)}<br />{pro.Late_Fee_Amt ? parseFloat(pro.Late_Fee_Amt || 0).toFixed(2) : ""}<br />{headData.Head_GST_No !== 'NA'
                                          ? parseFloat((pro.CGST_Amt || 0) + (pro.SGST_Amt || 0)).toFixed(2)
                                          : ""}
                                      </td>
                                    </tr><tr>
                                      <td style={{ padding: 7 }} colSpan={1}>
                                        <strong>Net Total : </strong>{`${number2text(parseFloat(
                                          (headData.Head_GST_No !== 'NA' ? (pro.CGST_Amt || 0) + (pro.SGST_Amt || 0) : 0) +
                                          (pro.Late_Fee_Amt !== 0 ? pro.Late_Fee_Amt || 0 : 0) +
                                          (pro.T_Amt || 0)
                                        ).toFixed(2))}`}
                                      </td>
                                      <td style={{ padding: 7 }} align="left">
                                        <strong>
                                          {(
                                            (headData.Head_GST_No !== 'NA' ? (pro.CGST_Amt || 0) + (pro.SGST_Amt || 0) : 0) +
                                            (pro.Late_Fee_Amt !== 0 ? pro.Late_Fee_Amt || 0 : 0) +
                                            (pro.T_Amt || 0)
                                          ).toFixed(2)}
                                        </strong>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                                <tr>
                                  <td style={{ padding: 7 }} colSpan={2}>
                                    <div className="row col-md-12">
                                      <div className="col-md-4 col-sm-4">


                                        <strong>Payment Mode :</strong>

                                        &nbsp;{feesData[0]?.Payment_Mode}
                                      </div>
                                      <div className="col-md-4 col-sm-4">

                                      {feesData[0]?.Bank_Name && (
        <>
          <strong>Bank Name :</strong>&nbsp;{feesData[0]?.Bank_Name}
        </>
      )}
                                       

                                      </div>
                                      <div className="col-md-4 col-sm-4">


                                        <strong>Receipt Status :</strong>

                                        &nbsp;{feesData[0]?.Status}

                                      </div>
                                      <div className="col-md-4 col-sm-4">


                                      {feesData[0]?.Cheque_Date && (
        <>
          <strong>Cheque Date :</strong>&nbsp;{formatDate(feesData[0]?.Cheque_Date)}
        </>
      )}

                                      </div>

                                      <div className="col-md-4 col-sm-4">

                                      {feesData[0]?.Cheque_No && (
        <>
          <strong>Cheque No :</strong>&nbsp;{feesData[0]?.Cheque_No}
          <br />
        </>
      )}

                                      </div>

                                      <div className="col-md-4 col-sm-4">


                                      {feesData[0]?.Cheque_Status && (
        <>
          <strong>Cheque Status :</strong>&nbsp;{feesData[0]?.Cheque_Status}
          <br />
        </>
      )}

                                      </div>
                                    </div>
                                  </td>

                                </tr>
                                <tr>
                                  <td style={{ padding: 7 }} colSpan={2}>
                                    <div className="row col-md-12">
                                      <div className="col-md-6 col-sm-6">


                                        <strong>Total Paid :</strong>

                                        &nbsp;{parseFloat(feesData[0]?.Amt_Recieved||0).toFixed(2)}

                                      </div>    <div className="col-md-6 col-sm-6">


                                        <strong>Balance :</strong>

                                        &nbsp;{parseFloat(feesData[0]?.Bal_Amt||0).toFixed(2)}

                                      </div>
                                      <div className="col-md-6 col-sm-6">


                                      {feesData[0]?.Next_Installment_Amt!==0 && (
        <>
          <strong>Next Installment :</strong>&nbsp;
          {parseFloat(feesData[0]?.Next_Installment_Amt || 0).toFixed(2)}
     
        </>
      )}
                                      </div>
                                      <div className="col-md-6 col-sm-6">


                                      {feesData[0]?.Next_Installment_Date!==null && (
        <>
          <strong>Due Date :</strong>&nbsp;
          {feesData[0]?.Next_Installment_Date ? formatDate(feesData[0]?.Next_Installment_Date) : ""}
      
        </>
      )}
                                      </div>
                                    </div>

                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <p style={{ color: "#333" }}>
                          <strong>1. This receipt is subject to realisation of cheque.<br />
                            2. This receipt should be carefully preserved and must be produced on demand.<br />
                            3. Fees once paid are not refundable/transferable in any circumstances.
                            {/* <br/>4. If Cheque dishonoured by bank Rs.250/- charges will be applicable. */}
                          </strong>
                        </p>
                        <div className="text-center" style={{ color: "#333" }}>
                          {/* <h6>SUBJECT TO NAVI MUMBAI JURISDICTION</h6> */}
                          <p>*This is a Computer Generated Receipt*</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default FeesReceipts;
