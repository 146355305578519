import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import Dropdown from "../../../DropDowns/DropDown";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate'
import { FaEye } from "react-icons/fa";

const AddNotice = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const { opration, Sr_No } = useParams();
    const [BatchOptions, setBatchOptions] = useState([]);
    const [StdOptions, setStdOptions] = useState([]);
    const [subjectOption, setSubjectOption] = useState([]);



    const [Bill, setBill] = useState({
        header: "",
        line1: "",
        line2: "",
        line3: "",
        FileName: ""
    });



    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData]);


    const fetchData = async () => {
        try {
            showLoader();

            if (userData && userData.Org_Id && userData?.Head_Id) {
                showLoader();
                const get = await axios.get(
                    `${apiUrl}/getHallTicketSettings?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
                );
                setBill(get.data)

                console.log(get.data);
            }

            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const deleteUser = (Id, Name) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id, Name);
            }
        });
    };
    async function handleDelete(Id, Name) {
        showLoader();
        const obj = {
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            User_Name: userData?.UserName,
            Name: Name
        };

        try {
            await axios.delete(`${apiUrl}/deleteHallTicketSettings/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Hall Ticket record has been deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting Hall Ticket record:", error);
            Swal.fire("Error", "Failed to delete Hall Ticket record.", "error");
        } finally {
            hideLoader();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        const formdata = new FormData();
        formdata.append("User_Id", updateBill.User_Id);
        formdata.append("Org_Id", updateBill.Org_Id);
        formdata.append("Org_Name", updateBill.Org_Name);
        formdata.append("User_Role", updateBill.User_Role);
        formdata.append("LoginUsername", updateBill.LoginUsername);
        formdata.append("Display_Name", updateBill.Display_Name);
        formdata.append("Head_Id", updateBill.Head_Id);
        formdata.append("Head_Name", updateBill.Head_Name ? updateBill.Head_Name : "");
        formdata.append("header", Bill.header);
        formdata.append("line1", Bill.line1 ? Bill.line1 : "");
        formdata.append("line2", Bill.line2||"");
        formdata.append("line3", Bill.line3||"");
        formdata.append("FileName", Bill.FileName||"");

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateHallTicketSettings/${Bill?.Row_Id}`, formdata)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Hall Ticket Settings Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            fetchData();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };



    const customStyles = {
        control: (base) => ({
            ...base,
            height: 33,
            minHeight: 33,
        }),
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">
                        Hallticket Settings
                    </div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div></div>
                </div>

            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="header">
                                                Header<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="header"
                                                id="header"
                                                required
                                                value={Bill.header}
                                                // placeholder="Enter Header"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="line1">
                                                Line 1
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="line1"
                                                id="line1"
                                                value={Bill.line1}
                                                //placeholder="Enter Bill No"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="line2">
                                                Line 2
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="line2"
                                                id="line2"
                                                value={Bill.line2}
                                                //placeholder="Enter Bill No"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="line3">
                                                Line 3
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="line3"
                                                id="line3"

                                                value={Bill.line3}
                                                //placeholder="Enter Bill No"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="FileName">
                                                Select Signature File
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="file"
                                                name="FileName"
                                                id="FileName"
                                                accept=".bmp, .png, .jpg, .jpeg"
                                                onChange={(e) =>
                                                    setBill({
                                                        ...Bill,
                                                        FileName: e.target.files[0],
                                                    })
                                                }
                                            />
                                            {/* <span style={{ color: "grey" }}>
                                                {Bill?.FileName ? Bill?.FileName?.name||Bill?.FileName : "File Not Found!!"}
                                            </span> */}
                                        </div>

                                        {Bill.FileName ? (
                                            <div className="mb-3 col-md-1 mt-4">
                                                {!Bill.Row_Id ?
                                                    (
                                                        <div className="image-container">
                                                            <img
                                                                src={URL.createObjectURL(Bill.FileName)}
                                                                className="img-thumbnail"
                                                                alt="FileName"
                                                            />
                                                        </div>
                                                    ) : (<Link
                                                        to={`${apiUrl}/Files/Hallticket/${Bill.FileName}`}
                                                        target="_blank" rel="noopener noreferrer"
                                                        className="cursor-pointer text-primary"
                                                        style={{ textDecoration: "none", marginLeft: "7px" }}
                                                    >
                                                        <FaEye />
                                                    </Link>)}
                                            </div>
                                        ) : null}


                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Save
                                            </button>
                                            &nbsp;
                                            <button onClick={() => { deleteUser(Bill?.Row_Id, Bill?.FileName) }} className={Bill.Row_Id ? "btn btn-sm btn-danger" : "d-none"}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>


                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNotice;


