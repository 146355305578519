import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import Dropdown from "../../DropDowns/DropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatInputDate } from "../../../Components/dateFormate";

const Gallary = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [errors, setErrors] = useState({});
  const { opration, Sr_No } = useParams();
  const [BatchOptions, setBatchOptions] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [subjectOption, setSubjectOption] = useState([]);

  const [Bill, setBill] = useState({
    Std_Id: "",
    Std_Name: "",
    Batch_Id: "",
    Batch_Name: "",
    Title: "",
    Imagename: [],
  });

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
    }
  }, [userData, Bill?.Std_Id]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData && userData.Org_Id && userData?.Head_Id) {
        showLoader();
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
        //console.log(getStd);
      }

      if (userData && userData.Org_Id && Bill?.Std_Id) {
        const batch = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Std_Id}`
        );

        const getBatch = batch.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(getBatch);
        // console.log(getBatch);

        const sub = await axios.get(
          `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Std_Id}`
        );

        const getSub = sub.data.map((category) => ({
          label: category.Sub_Name,
          value: category.Sub_Id,
        }));
        setSubjectOption(getSub);
      }
      // console.log(res.data);
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setBill({
      ...Bill,
      Imagename: Array.from(e.target.files),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const updateBill = {
      ...Bill,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    const formdata = new FormData();
    formdata.append("User_Id", updateBill.User_Id);
    formdata.append("Org_Id", updateBill.Org_Id);
    formdata.append("Org_Name", updateBill.Org_Name);
    formdata.append("User_Role", updateBill.User_Role);
    formdata.append("LoginUsername", updateBill.LoginUsername);
    formdata.append("Display_Name", updateBill.Display_Name);
    formdata.append("Head_Id", updateBill.Head_Id);
    formdata.append(
      "Head_Name",
      updateBill.Head_Name ? updateBill.Head_Name : ""
    );
    formdata.append("Std_Id", Bill.Std_Id);
    formdata.append("Std_Name", Bill.Std_Name ? Bill.Std_Name : "");
    formdata.append("Batch_Id", Bill.Batch_Id);
    formdata.append("Batch_Name", Bill.Batch_Name);
    formdata.append("Title", Bill.Title);

    if (Bill.Imagename && Bill.Imagename.length > 0) {
      for (let i = 0; i < Bill.Imagename.length; i++) {
        formdata.append("Imagename", Bill.Imagename[i]);
      }
    }

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addGallery`, formdata)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Photos added in Gallery Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/ViewGallery");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33,
    }),
  };

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">Add Gallery</div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Title">
                        Title<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Title"
                        name="Title"
                        required
                        value={Bill.Title}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Standard">
                        Standard<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Dropdown
                        options={[
                          { label: "All", value: "All" },
                          ...StdOptions,
                        ]}
                        name="Standard"
                        required={true}
                        id="Standard"
                        value={Bill.Std_Id || "All"}
                        onChange={(e) => {
                          const selectedIndex = e.target.selectedIndex;
                          const selectedOption = e.target[selectedIndex];
                          setBill({
                            ...Bill,
                            Std_Id: selectedOption.value,
                            Std_Name: selectedOption.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>

                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Batch">
                        Batch<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Dropdown
                        options={[
                          { label: "All", value: "All" },
                          ...BatchOptions,
                        ]}
                        name="Batch"
                        required={true}
                        id="Batch"
                        value={Bill.Batch_Id || "All"}
                        disabled={Bill.Std_Id === ""}
                        onChange={(e) => {
                          const selectedIndex = e.target.selectedIndex;
                          const selectedOption = e.target[selectedIndex];
                          setBill({
                            ...Bill,
                            Batch_Id: selectedOption.value,
                            Batch_Name: selectedOption.label,
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>

                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="Imagename">
                        Attatchment<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="file"
                        name="Imagename"
                        id="Imagename"
                        accept=".bmp, .png, .jpg, .jpeg, .pdf"
                        multiple
                        onChange={handleFileChange}
                      />

                      <span style={{ color: "Red" }}></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallary;
