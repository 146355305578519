import React, { useEffect, useState } from "react";
import Select from "react-select";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { formatDate } from "../../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../../Students/AgGridTable";

const VendorPayment_Entry = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setVoucherData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [VendorOptions, setVendorOptions] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });
  const [V_Id, setVId] = useState("");


  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData]);



  const fetchData = async () => {
    
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getVendorPaymentsWithFilter?OrgId=${userData.Org_Id}&fromDate=${fromDate ? fromDate.format("YYYY-MM-DD") : ""}&toDate=${toDate ? toDate.format("YYYY-MM-DD") : ""}&Payment_Id=${V_Id}`
      );
      setFilteredData(result.data);
      setVoucherData(result.data);
      console.log(result.data);

      if (userData && userData.Org_Id) {

        const ven = await axios.get(
          `${apiUrl}/getVendorsDropdown?OrgId=${userData.Org_Id}`
        );

        const getVendors = ven.data.map((category) => ({
          label: category.Company_Name,
          value: category.v_Id,
        }));
        setVendorOptions(getVendors);
        console.log(getVendors);
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching headmaster data:", error);
      hideLoader();
    }
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (data.length > 0) {
      const newFilteredData = data.filter((row) =>
        row?.Name?.toLowerCase().includes(searchTerm) ||
        formatDate(row.Transaction_Date)?.toLowerCase().includes(searchTerm) ||
        row?.Amount?.toString()?.toLowerCase().includes(searchTerm) ||
        row?.Payment_Mode?.toLowerCase().includes(searchTerm) ||
        row?.Bank_Name?.toLowerCase().includes(searchTerm) ||
        row?.Cheq_No?.toLowerCase().includes(searchTerm) ||
        formatDate(row.Cheq_Date)?.toLowerCase().includes(searchTerm) ||
        row?.Cheq_Status?.toLowerCase().includes(searchTerm) ||
        row?.Remarks?.toLowerCase().includes(searchTerm)
      );
      setFilteredData(newFilteredData);
    }
  }, [searchTerm, data]);

  useEffect(() => {

    const options = (props) => {
      const { V_No } = props.data;
      return (
        <div className="dropdown font-sans-serif d-none">
          <button
            style={{ padding: 0 }}
            className="btn btn-link"
            id="dropdownMenuLink"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              className="fas fa-ellipsis-v"
              style={{ fontSize: "14px", color: "gray" }}
            ></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-end py-0"
            aria-labelledby="dropdownMenuLink"
          >
            <div>

              <Link
                onClick={(e) => {
                  e.preventDefault();
                  showModal(props.rowIndex);
                }}
                className="dropdown-item cursor-pointer"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <i
                  className="fas fa-pen"
                  style={{ color: "blue", fontSize: "14px" }}
                ></i>
                <span> Update</span>
              </Link>

            </div>

            <Link
              onClick={() => {
                deleteUser(V_No);
              }}
              className="dropdown-item cursor-pointer"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <i
                className="fas fa-trash"
                style={{ color: "red", fontSize: "14px" }}
              ></i>
              <span> Delete</span>
            </Link>
          </div>
        </div>
      );
    };
    const newAgcolumns = [
      { field: "SrNo" },
      { field: "Date" },
      { field: "Name" },
      { field: "Amount" },
      { field: "PayMode" },
      { field: "BankName" },
      { field: "ChequeNo" },
      { field: "ChequeDate" },
      { field: "ChequeStatus" },
      { field: "PayStatus" },
      { field: "Remarks" },
     
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData.map((row, index) => ({
        SrNo: index + 1,
        Date: formatDate(row.Transaction_Date),
        Name: row.Name,
        Amount: row.Amount,
        PayMode: row.Payment_Mode,
        BankName: row.Bank_Name,
        ChequeNo: row.Cheq_No,
        ChequeDate: formatDate(row.Cheq_Date),
        ChequeStatus: row.Cheq_Status,
        PayStatus: row.Pay_Status,
        Remarks: row.Remarks,
        V_No: row.V_No,
      }));

      setAgrows(newAgrows);
    }
  }, [filteredData]);

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };
  async function handleDelete(Id) {
    showLoader();
    console.log(Id);
    const obj = {
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      User_Name: userData.UserName,
    };

    try {
      await axios.delete(`${apiUrl}/deleteVoucher/${Id}`, { data: obj });
      Swal.fire("Deleted!", "Voucher record has been deleted.", "success");
      fetchData();
    } catch (error) {
      console.error("Error while deleting voucher record:", error);
      Swal.fire("Error", "Failed to delete voucher record.", "error");
    } finally {
      hideLoader();
    }
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    fetchData();
    hideLoader();
  };

  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF("l", "mm", "a4");
    doc.text("Vender Payment Details", 20, 10);

    const columns = [
      "Sr No",
      "Date",
      "Name",
      "Amount",
      "Pay Mode",
      "Bank Name",
      "Cheque No",
      "Cheque Date",
      "Cheque Status",
      "Pay Status",
      "Remarks"
    ];

    const rows = filteredData.map((row, index) => [
      index + 1,
      formatDate(row.Transaction_Date),
      row.Name,
      row.Amount,
      row.Payment_Mode,
      row.Bank_Name,
      row.Cheq_No,
      row.Cheq_Date ? formatDate(row.Cheq_Date) : "--",
      row.Cheq_Status,
      row.Pay_Status,
      row.Remarks,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("VenderPayment_Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Sr No",
      "Date",
      "Name",
      "Amount",
      "Pay Mode",
      "Bank Name",
      "Cheque No",
      "Cheque Date",
      "Cheque Status",
      "Pay Status",
      "Remarks"
    ];

    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([
        index + 1,
        formatDate(row.Transaction_Date),
        row.Name,
        row.Amount,
        row.Payment_Mode,
        row.Bank_Name,
        row.Cheq_No,
        row.Cheq_Date ? formatDate(row.Cheq_Date) : "--",
        row.Cheq_Status,
        row.Pay_Status,
        row.Remarks,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vender Payment Details");

    XLSX.writeFile(workBook, "VenderPayment_Details.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const toggle = () => {
    setDesign({
      ...design,
      filterToggle: design.filterToggle ? false : true,
    });
    setFilterData({
      ...filterData,
      filterToggle: design.filterToggle ? false : true,
    });
  };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-3">
              <div className="me-3 flex-1 mt-2">
                <h5 className="text-black">{row.Name}</h5>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Date :{" "}
                  <span className="ms-1 text-1100">{formatDate(row.Transaction_Date)}</span>
                </p>
                {/* <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Amount :{" "}
                  <span className="fw-semi-bold  ms-1"> {row.Amount}</span>
                </p> */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1">
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Amount :{" "}
                  <span className="fw-semi-bold  ms-1"> {parseFloat(row.Amount).toFixed(2)}</span>
                </p>
                {/* <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Date :{" "}
                  <span className="fw-semi-bold  ms-1">
                    {" "}
                    {formatDate(row.Transaction_Date)}
                  </span>
                </p> */}
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Remark :{" "}
                  <span className="fw-semi-bold  ms-1"> {row.Remarks}</span>
                </p>

              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1">
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Mode : <span className="fw-semi-bold  ms-1"> {row.Payment_Mode}</span>
                </p>

                {row?.Payment_Mode === "Cheque" && (
                  <>
                    <p className="mb-0 fw-semi-bold listViwP text-truncate">
                      Bank :{" "}
                      <span className="fw-semi-bold  ms-1">
                        {" "}
                        {row.Bank_Name}
                      </span>
                    </p>
                    <p className="mb-0 fw-semi-bold listViwP text-truncate">
                      Cheque Date :{" "}
                      <span className="fw-semi-bold  ms-1">
                        {" "}
                        {formatDate(row.Cheq_Date)}
                      </span>
                    </p>
                    <p className="mb-0 fw-semi-bold listViwP text-truncate">
                      Cheque No :{" "}
                      <span className="fw-semi-bold  ms-1">{row.Cheq_No}</span>
                    </p>
                    <p className="mb-0 fw-semi-bold listViwP text-truncate">
                      Cheque Status :{" "}
                      <span className="fw-semi-bold  ms-1">{row.Cheq_Status}</span>
                    </p>
                  </>
                )}
                {row?.Payment_Mode === "Online" && (
                  <p className="mb-0 fw-semi-bold listViwP text-truncate">
                    Transaction ID :{" "}
                    <span className="fw-semi-bold  ms-1">
                      {" "}
                      {row.Transaction_Id}
                    </span>
                  </p>
                )}
                {/* <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Issued By :{" "}
                  <span className="fw-semi-bold  ms-1">
                    {" "}
                    {row.Issued_By_Name}
                  </span>
                </p> */}

                {row?.Pay_Status === "Paid" && (
                  {/*<p className="mb-0 fw-semi-bold listViwP text-truncate">
                    Paid By :{" "}
                    <span className="fw-semi-bold  ms-1">
                      {" "}
                      {row.Updated_By_Name}
                    </span>
                </p>*/}
                )}
              </div>
            </div>
            <div className="col-md-2">
              <p className="mb-0 fw-semi-bold listViwP text-truncate">
                <span className="fw-semi-bold  ms-1">
                  <span
                    className={`badge badge-phoenix ${row.Pay_Status === "Unpaid"
                      ? "badge-phoenix-warning"
                      : row.Pay_Status === "Paid"
                        ? "badge-phoenix-success"
                        : "badge-phoenix-primary"
                      }`}
                  >
                    {row.Pay_Status}
                  </span>
                </span>
              </p>
            </div>
            <div className="col-md-1">
              <div className="font-sans-serif">
                <button
                  style={{ padding: 0 }}
                  className="btn btn-link"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={{ fontSize: "14px", color: "gray" }}
                  ></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <div>
                    {/* <Link
                      to={`/addStudent/convert/${row.Enq_Id}`}
                      className="dropdown-item cursor-pointer"
                    >
                      <i
                        className="fas fa-print"
                        style={{ color: "orange", fontSize: "14px" }}
                      ></i>
                      <span> Print</span>
                    </Link> */}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        showModal(index);
                      }}
                      className="dropdown-item cursor-pointer"
                    >
                      <i
                        className="fas fa-pen"
                        style={{ color: "blue", fontSize: "14px" }}
                      ></i>
                      <span> Update</span>
                    </Link>

                    <div className="dropdown-divider"></div>
                  </div>

                  <Link
                    onClick={() => {
                      deleteUser(row.V_No);
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i
                      className="fas fa-trash"
                      style={{ color: "red", fontSize: "14px" }}
                    ></i>
                    <span> Delete</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                  <div
                    className="row justify-content-between me-1"
                    style={{ flex: "auto", marginTop: "8px" }}
                  >
                    <div className="col-11 pe-1">
                      <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                        {row.Name}
                      </h6>

                    </div>
                    <div className="end-0 col-1 p-0 ">
                      <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-ellipsis-v"
                          style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <div>
                          <Link
                            to={`/addStudent/convert/${row.Enq_Id}`}
                            className="dropdown-item cursor-pointer"
                          >
                            <i
                              className="fas fa-print"
                              style={{ color: "orange", fontSize: "14px" }}
                            ></i>
                            <span> Print</span>
                          </Link>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              showModal(index);
                            }}
                            className="dropdown-item cursor-pointer"
                          >
                            <i
                              className="fas fa-pen"
                              style={{ color: "blue", fontSize: "14px" }}
                            ></i>
                            <span> Update</span>
                          </Link>

                          <div className="dropdown-divider"></div>
                        </div>

                        <Link
                          onClick={() => {
                            deleteUser(row.V_No);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "red", fontSize: "14px" }}
                          ></i>
                          <span> Delete</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-success"><span className="badge-label">completed</span></span> */}
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail text-truncate lh-1">
                    Amount :{" "}
                    <span className="fw-semi-bold ms-1"> {row.Amount}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2  ">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail lh-1">
                    Date :{" "}
                    <span className="ms-1 text-1100">
                      {formatDate(row.Transaction_Date)}
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  {/* <p className="mb-0 profiledetail lh-1">
                    Category :{" "}
                    <span className="ms-1 text-1100">{row.Category}</span>
                  </p> */}
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail lh-1">
                    Mode : <span className="ms-1 text-1100">{row.Payment_Mode}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  {/* <p className="mb-0 profiledetail lh-1">
                    Issued By :{" "}
                    <span className="ms-1 text-1100">{row.Issued_By_Name}</span>
                  </p> */}
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  {/* <p className="mb-0 profiledetail lh-1">
                    Paid By :{" "}
                    <span className="ms-1 text-1100">
                      {row.Updated_By_Name ? row.Updated_By_Name : "--"}
                    </span>
                  </p> */}
                </div>
                <div
                  className="d-flex justify-content-between text-700 fw-semi-bold "
                  style={{ float: "right" }}
                >
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "14px",
                      color: "#a09aa3",
                      lineHeight: "5px",
                    }}
                  >
                    <span
                      className={`badge badge-phoenix ${row.Pay_Status === "Unpaid"
                        ? "badge-phoenix-warning"
                        : row.Pay_Status === "Paid"
                          ? "badge-phoenix-success"
                          : "badge-phoenix-primary"
                        }`}
                    >
                      {row.Pay_Status}
                    </span>
                  </p>
                  {/* <p className="mb-2 text-1100">100%</p> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="content">
      <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
        <div className="container-fluid">
          {/* Sidebar */}
          <div id="sidebar-wrapper">
            <div className="row g-4">
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-2"
                  data-component-card="data-component-card"
                >
                  <div className="row justify-content-center d-flex p-3">
                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Branch">
                            Branch
                          </label>
                          <HeadMasterDropDown
                            label="Branch"
                            name="Branch"
                            id="Branch"
                          //onChange={(e) => setBranch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            From Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            //value={fromDate?.format("YYYY-MM-DD")}
                            onChange={(date) =>
                              setFromDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            To Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            // value={toDate?.format("YYYY-MM-DD")}
                            onChange={(date) =>
                              setToDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Standard">
                            Vendors :
                          </label>
                          <Select
                            id="example-select"
                            value={VendorOptions.find(option => option.value === V_Id) || { value: "", label: "All" }}
                            options={[
                              { value: "", label: "All" },
                              ...VendorOptions.map((item) => ({
                                value: item.value,
                                label: item.label,
                              })),
                            ]}
                            onChange={(selectedOption) => {
                              setVId(selectedOption.value);
                            }}
                          />


                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-none">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Status">
                            Status :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="Status"
                            name="Status"
                          // onChange={(e) => setSelectedstatus(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="Paid">Paid</option>
                            <option value="Unpaid">Unpaid</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row justify-content-center p-2">
                    <div className="col-md-4  mb-5">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-center"
                        onClick={handleDateRangeFilter}
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                Vender Payments
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  href="#basic-form"
                  style={{ paddingLeft: "0.375em" }}
                ></a>
              </h4>
              <div className="col-md-12 text-center mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  style={{
                    float: "left",
                    display: "inline-block",
                    width: "200px",
                  }}
                ></input>
                <Link to={"/VendorPaymentEntry"} style={{ float: "right" }}>
                  <button className="btn btn-primary btn-sm text-center">
                    Add
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn bg-200 filterButton collapsed mr-2"
                  data-toggle="collapse"
                  onClick={toggle}
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  style={{ float: "right", marginRight: "10px" }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className="form-control dropdown-toggle viewButton  mb-2"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "auto",
                    padding: "4px 10px",
                  }}
                >
                  <>
                    {design.dataView === "Table View" && (
                      <TableChartOutlinedIcon />
                    )}
                  </>
                  <>
                    {design.dataView === "List View" && (
                      <FormatListBulletedIcon />
                    )}
                  </>
                  <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={(e) => {
                      setDesign({
                        ...design,
                        dataView: "Table View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Table View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <TableChartOutlinedIcon />
                    <span> Table View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "List View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "List View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <FormatListBulletedIcon />
                    <span> List View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "Tile View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Tile View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <GridViewIcon />
                    <span> Tile View</span>
                  </Link>
                </div>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to PDF</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadPDF()}
                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                  >
                    <i
                      className="fas fa-file-pdf"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to Excel</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadExcel()}
                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                {filteredData.length === 0 && (<h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
              </div>
              {design.dataView === "Table View" && (
                 <div className={filteredData.length === 0 ?"d-none":"col-12 col-xl-12 order-1 order-xl-0"}>
                  {Agrows.length > 0 && (
                    <AgGridTable row={Agrows} column={Agcolumns} />
                  )}
                </div>
              )}
              {design.dataView === "Tile View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">{displayData}</div>
                </div>
              )}
              {design.dataView === "List View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">
                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                      <div className="card h-100 hover-actions-trigger">
                        <div
                          className="card-body"
                          style={{ paddingTop: "5px" }}
                        >
                          {displayData}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(design.dataView === "List View" ||
                design.dataView === "Tile View") && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageCount={pageCount}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    breakAriaLabels={{
                      forward: "Jump forward",
                      backward: "Jump backward",
                    }}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                    previousLinkClassName={"btn btn-outline-primary me-2"}
                    nextLinkClassName={"btn btn-outline-primary ms-2"}
                    activeLinkClassName={"customclass ms-2 me-2"}
                    pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                  />
                )}
            </div>
          </div>
        </div>
        {/* {isModal && (
          <UpdateVoucher
            open={isModal}
            onClose={closed}
            updateData={data[indexValue]}
          />
        )} */}
        {loader}
      </div>
    </div>
  );
};

export default VendorPayment_Entry;
