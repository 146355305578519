import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from "../../../../Components/dateFormate";
import { FaEye } from "react-icons/fa";
import Switch from "react-switch";

const SocialMediaModal = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };
    const loadUpiSetting = async () => {
        showLoader();
        try {
            const result = await axios.get(`${apiUrl}/bindSocialMediaDetails/${userData.Org_Id}`);
            setBill(result.data);
            console.log(result.data);

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            loadUpiSetting();
        }
    }, [userData]);

    console.log(Bill);

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(
                    `${apiUrl}/submitSocialMediaSettings`,
                    updateBill
                )
                .then((res) => {
                    Swal.fire({
                        title: "Success!",
                        text: "Social Media Settings Updated Successfully!!",
                        icon: "success",
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Social Media Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                     
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Facebook">
                                Facebook
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Facebook"
                                    id="Facebook"
                                    value={Bill.Facebook}
                                    placeholder="Enter Facebook Account Link"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="LinkedIn">
                                LinkedIn
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="LinkedIn"
                                    id="LinkedIn"
                                    value={Bill.LinkedIn}
                                    placeholder="Enter LinkedIn Account Link"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Instagram">
                                Instagram
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Instagram"
                                    id="Instagram"
                                    value={Bill.Instagram}
                                    placeholder="Enter Instagram Account Link"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label" htmlFor="Youtube">
                                Youtube
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Youtube"
                                    id="Youtube"
                                    value={Bill.Youtube}
                                    placeholder="Enter Youtube Account Link"
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SocialMediaModal;
