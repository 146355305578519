import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { apiUrl } from "../../Components/apiConfig";
import { useUser } from "../../Components/UserContext";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Select from "react-select";
import { formatDate } from "../../Components/dateFormate";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from '@mui/icons-material/TableView';

const ViewAttendance = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [data, setAttendanceData] = useState([]);
  const [dataMaster, setMasterAttendanceData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [id, setID] = useState("");
  const [StaffOption, setStaffOption] = useState([]);



  useEffect(() => {
    fetchStaff();
    fetchAttendanceData();
  }, [userData, id, currentMonth, currentYear]);


  const fetchStaff = async () => {
    if (userData?.Org_Id) {
      try {
        const result = await axios.get(
          `${apiUrl}/getStaff?OrgId=${userData?.Org_Id}`
        );

        const getStaff = result.data.map((category) => ({
          label: `${category.First_Name} ${category.Last_Name}`,
          value: category.User_Id,
        }));
        setStaffOption(getStaff);
      } catch (error) {
        console.error("Error fetching staff:", error);
      }
    }
  };

  const fetchAttendanceData = async () => {
    if (userData?.Org_Id && currentMonth && currentYear) {
      try {
        showLoader();
        setIsLoading(true);

        const result = await axios.get(
          `${apiUrl}/getFacultyCollectiveReport?id=${id}&Month=${currentMonth}&Year=${currentYear}&OrgId=${userData?.Org_Id}`
        );

        if (result.data === 0) {
          setAttendanceData([]);
          setMasterAttendanceData([]);
        } else {
          setAttendanceData(result.data);
          setMasterAttendanceData(result.data);
          console.log(result.data);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      } finally {
        setIsLoading(false);
        hideLoader();
      }
    } else {
      setAttendanceData([]);
      setIsLoading(false);
      hideLoader();
    }
  };


  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };

  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };

  // {
  //   Date: '2024-07-01T00:00:00.000Z',
  //   OrgId: 96,
  //   UserID: '38168',
  //   Name: 'LOKESH PRAJAPATI',
  //   PayType: 'Fixed',
  //   WeekOff: 'Sunday',
  //   WorkingdayCount: 27,
  //   HolidayCount: 0,
  //   WeekoffCount: 4,
  //   AdjustmentCount: 0,
  //   PresentdayCount: 16,
  //   AbsentdayCount: 6,
  //   LatemarkdayCount: 2,
  //   HalfdayCount: 0,
  //   LeavedayCount: 0,
  //   OTCount: '',
  //   TotalpresentdayCount: 16,
  //   MonthlySalary: 1069,
  //   Perday_hourSalary: 0,
  //   GrossSalary: 0,
  //   OT: false,
  //   OTSalary: 0,
  //   Deductions: true,
  //   LatemarkDeduction: 0,
  //   HalfdayDeduction: 0,
  //   AbsentDeduction: 206.88,
  //   TotalDeduction: 206.88,
  //   NetSalary: 551.68
  // }


  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Total Days",
      selector: (row) => {
        const date = new Date(row.Date);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1;
        const lastDayOfMonth = new Date(Date.UTC(year, month, 0));
        return lastDayOfMonth.getUTCDate();
      },
      center: true,
      width: "120px",
    },
    {
      name: "Holidays",
      selector: (row) => row.HolidayCount,
      center: true,
      width: "100px",
    }, {
      name: "Working Days",
      selector: (row) => row.WorkingdayCount,
      center: true,
      width: "120px",
    }, {
      name: "Adjustments",
      selector: (row) => row.AdjustmentCount,
      center: true,
      width: "120px",
    }, {
      name: "Present",
      selector: (row) => row.PresentdayCount,
      center: true,
      width: "100px",
    }, {
      name: "Absent",
      selector: (row) => row.AbsentdayCount,
      center: true,
      width: "100px",
    },
    {
      name: "Late Marks",
      selector: (row) => row.LatemarkdayCount,
      center: true,
      width: "120px",
    }, {
      name: "Half Days",
      selector: (row) => row.HalfdayCount,
      center: true,
      width: "120px",
    }, {
      name: "Leaves",
      selector: (row) => row.LeavedayCount,
      center: true,
      width: "120px",
    }, {
      name: "Latemark Deduction",
      selector: (row) => row.LatemarkDeduction,
      center: true,
      width: "150px",
    }, {
      name: "Half Day Deduction",
      selector: (row) => row.HalfdayDeduction,
      center: true,

      width: "150px",
    }, {
      name: "Absent Deduction",
      selector: (row) => row.AbsentDeduction,

      width: "150px",
      center: true,

    }, {
      name: "Salary",
      selector: (row) => row.MonthlySalary !== 0 ? row.MonthlySalary : row.Perday_hourSalary,

      width: "120px",
      center: true,

    },
    {
      name: "Gross Salary",
      selector: (row) => row.GrossSalary,

      width: "120px",
      center: true,

    },
    {
      name: "Total Deduction",
      selector: (row) => row.TotalDeduction,

      width: "120px",
      center: true,

    },
    {
      name: "Net Salary",
      selector: (row) => row.NetSalary,
      center: true,
      width: "120px",
    },
  ];


  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    
    doc.text(`Faculty Collective Report`, 20, 10);

    const columns = [
      "Name",
      "Total Days",
      "Holidays",
      "Working Days",
      "Adjustments",
      "Present",
      "Absent",
      "Late Marks",
      "Half Days",
      "Leaves",
      "Latemark Deduction",
      "Half Day Deduction",

      "Absent Deduction",
      "Salary",
      "Gross Salary",
      "Total Deduction",
      "Net Salary"
    ];

    const rows = data.map((row) => [
      row.Name,
      new Date(row.Date).getUTCDate(),
      row.HolidayCount,
      row.WorkingdayCount,
      row.AdjustmentCount,
      row.PresentdayCount,
      row.AbsentdayCount,
      row.LatemarkdayCount,
      row.HalfdayCount,
      row.LeavedayCount,
      row.LatemarkDeduction,
      row.HalfdayDeduction,
      row.AbsentDeduction,
      row.MonthlySalary !== 0 ? row.MonthlySalary : row.Perday_hourSalary,
      row.GrossSalary,
      row.TotalDeduction,
      row.NetSalary,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save(`FacultyCollectiveReport.pdf`);
  };

  const DownloadExcel = () => {
    const columns = [
      "Name",
      "Total Days",
      "Holidays",
      "Working Days",
      "Adjustments",
      "Present",
      "Absent",
      "Late Marks",
      "Half Days",
      "Leaves",
      "Latemark Deduction",
      "Half Day Deduction",
      "Absent Deduction",
      "Salary",
      "Gross Salary",
      "Total Deduction",
      "Net Salary"
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Name,
        new Date(row.Date).getUTCDate(),
        row.HolidayCount,
        row.WorkingdayCount,
        row.AdjustmentCount,
        row.PresentdayCount,
        row.AbsentdayCount,
        row.LatemarkdayCount,
        row.HalfdayCount,
        row.LeavedayCount,
        row.LatemarkDeduction,
        row.HalfdayDeduction,
        row.AbsentDeduction,
        row.MonthlySalary !== 0 ? row.MonthlySalary : row.Perday_hourSalary,
        row.GrossSalary,
        row.TotalDeduction,
        row.NetSalary,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `FacultyCollectiveReport`);

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, `FacultyCollectiveReport.xlsx`);
  };


  //Search Box    
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (data.length > 0) {
      const newfilteredData = dataMaster.filter((row) => {

        return (
          row?.Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          new Date(row.Date).toLocaleDateString("en-GB", { weekday: "long" })?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          formatDate(row.Date)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row?.TimeLog?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

      setAttendanceData(newfilteredData);
    }
  }, [searchTerm, dataMaster]);


  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-content-wrapper">
          <div className="row justify-content-between">
            <h4 className="col-md-6 mb-2" data-anchor="data-anchor" id="basic-form">
              Faculty Collective Report
              <a
                className="anchorjs-link"
                aria-label="Anchor"
                href="#basic-form"
                style={{ paddingLeft: "0.375em" }}
              ></a>
            </h4>
          </div>
          <div className="card h-100 hover-actions-trigger">
            <div className="card-body" style={{ paddingTop: "5px" }}>

              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label htmlFor="month-select" className="form-label">
                    Month
                  </label>
                  <select
                    className="form-select"
                    id="month-select"
                    onChange={monthChanged}
                    defaultValue={currentMonth}
                  >
                    <option value={1}>January</option>
                    <option value={2}>February</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label htmlFor="year-select" className="form-label">
                    Year
                  </label>
                  <select
                    className="form-select"
                    id="year-select"
                    onChange={yearChanged}
                    defaultValue={currentYear}
                  >
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label htmlFor="faculty-select" className="form-label">
                    Faculty
                  </label>
                  <Select
                    id="faculty-select"
                    options={StaffOption}
                    required
                    placeholder="All Staff"
                    isClearable
                    onChange={(e) => {
                      setID(e ? e.value : "");
                    }}
                  />
                </div>

              </div>



              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2 mt-3"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <input type="text" className="form-control form-control-sm" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..." style={{ float: "left", display: "inline-block", width: "200px" }}></input>
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                    className="d-none"
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={DownloadPDF}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={DownloadExcel}
                    >
                      <TableViewIcon />
                    </i>
                  </Tooltip>
                </div>
              </Stack>

              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  progressPending={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default ViewAttendance;
