import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { apiUrl } from "../../Components/apiConfig";
import { useUser } from "../../Components/UserContext";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Select from "react-select";
import { formatDate } from "../../Components/dateFormate";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from '@mui/icons-material/TableView';

const ViewAttendance = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const { Sr_No } = useParams();
  const [data, setAttendanceData] = useState([]);
  const [dataMaster, setMasterAttendanceData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [id, setID] = useState("");
  const [StaffOption, setStaffOption] = useState([]);
  const [date, setDate] = useState("");





  useEffect(() => {
    if (userData?.Org_Id) {
    (async () => {
      const result = await axios.get(
        `${apiUrl}/getStaff?OrgId=${userData?.Org_Id}`
      );
      //console.log(result.data);

      const getStaff = result.data.map((category) => ({
        label: `${category.First_Name} ${category.Last_Name}`,
        value: category.User_Id,
      }));
      setStaffOption(getStaff);

    })();}

    if (userData?.Org_Id && currentMonth && currentYear) {
      (async () => {
        try {
          showLoader();
          setIsLoading(true);
          const result = await axios.get(
            `${apiUrl}/getAllStaffAttendance?id=${id}&month=${currentMonth}&year=${currentYear}&Org_Id=${userData?.Org_Id}&date=${date}`
          );

          if (result.data === 0) {
            setAttendanceData([]);
            setMasterAttendanceData([]);

          } else {
            setAttendanceData(result.data);
            setMasterAttendanceData(result.data);

          //  console.log(result.data);
          }
          setIsLoading(false);
          hideLoader();
        } catch (error) {
          setIsLoading(false);
          hideLoader();
          console.error(error);
        }
      })();
    } else {
      setAttendanceData([]);
      setIsLoading(false);
      hideLoader();
    }
  }, [userData, id, currentMonth, currentYear,date]);


  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };

  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };


  const columns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Day",
      selector: (row) =>
        new Date(row.Date).toLocaleDateString("en-GB", { weekday: "long" }),
      center: true,
      width: "120px",
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.Date),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "Time Log",
      cell: (row) => renderTimeLog(row.TimeLog),
      wrap: true,
    },
  ];

  const renderTimeLog = (timeLog) => {
    // Check if timeLog is empty and return an empty string if so
    if (!timeLog.trim()) {
        return <pre></pre>;
    }

    const times = timeLog.split(",").map(time => time.trim());
    const result = [];

    // Iterate through the time entries in pairs
    for (let i = 0; i < times.length; i += 2) {
        const inTime = times[i];
        const outTime = i + 1 < times.length ? times[i + 1] : "";

        // Only add the "Out Time" if it's different from the "In Time"
        if (outTime && outTime !== inTime) {
            result.push(`(In Time: ${inTime}, Out Time: ${outTime})`);
        } else {
            result.push(`(In Time: ${inTime})`);
        }
    }

    // Join the result array with line breaks
    return <pre>{result.join(",\n")}</pre>;
};


  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text(`Staff Biometric Report`, 20, 10);

    const columns = [
      "Name",
      "Day",
      "Date",
      "Time Log"
    ];

    const rows = data.map((row) => [
      row?.Name,
      new Date(row.Date).toLocaleDateString("en-GB", { weekday: "long" }),
      formatDate(row.Date),
      row?.TimeLog
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save(`Staff Biometric Report.pdf`);
  };

  const DownloadExcel = () => {
    const columns = [
      "Name",
      "Day",
      "Date",
      "Time Log"
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row?.Name,
        new Date(row.Date).toLocaleDateString("en-GB", { weekday: "long" }),
        formatDate(row.Date),
        row?.TimeLog

      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Staff Biometric Report`);

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, `Staff Biometric Report.xlsx`);
  };


      //Search Box    
      const [searchTerm, setSearchTerm] = useState('');

      useEffect(() => {
          if (data.length > 0) {
              const newfilteredData = dataMaster.filter((row) => {
              
                  return (
                    row?.Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    new Date(row.Date).toLocaleDateString("en-GB", { weekday: "long" })?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    formatDate(row.Date)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row?.TimeLog?.toLowerCase().includes(searchTerm.toLowerCase())
                  );
              });
      
              setAttendanceData(newfilteredData);
          }        
      }, [searchTerm, dataMaster]);

      
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-content-wrapper">
          <div className="row justify-content-between">
            <h4 className="col-md-6 mb-2" data-anchor="data-anchor" id="basic-form">
              Faculty In Out Reports
              <a
                className="anchorjs-link"
                aria-label="Anchor"
                href="#basic-form"
                style={{ paddingLeft: "0.375em" }}
              ></a>
            </h4>
          </div>
          <div className="card h-100 hover-actions-trigger">
            <div className="card-body" style={{ paddingTop: "5px" }}>
      
  <div className="row justify-content-center">
    <div className="col-12 col-md-6 col-lg-3 mb-3">
      <label htmlFor="month-select" className="form-label">
        Month
      </label>
      <select
        className="form-select"
        id="month-select"
        onChange={monthChanged}
        defaultValue={currentMonth}
      >
        <option value={1}>January</option>
        <option value={2}>February</option>
        <option value={3}>March</option>
        <option value={4}>April</option>
        <option value={5}>May</option>
        <option value={6}>June</option>
        <option value={7}>July</option>
        <option value={8}>August</option>
        <option value={9}>September</option>
        <option value={10}>October</option>
        <option value={11}>November</option>
        <option value={12}>December</option>
      </select>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-3">
      <label htmlFor="year-select" className="form-label">
        Year
      </label>
      <select
        className="form-select"
        id="year-select"
        onChange={yearChanged}
        defaultValue={currentYear}
      >
        <option value={2022}>2022</option>
        <option value={2023}>2023</option>
        <option value={2024}>2024</option>
        <option value={2025}>2025</option>
      </select>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-3">
      <label htmlFor="faculty-select" className="form-label">
        Faculty
      </label>
      <Select
        id="faculty-select"
        options={StaffOption}
        required
        placeholder="All Staff"
        isClearable
        onChange={(e) => {
          setID(e ? e.value : "");
        }}
      />
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-3">
      <label htmlFor="date-input" className="form-label">
        Date
      </label>
      <input
        type="date"
        className="form-control"
        id="date-input"
        onChange={(e) => {
          setDate(e.target.value);
        }}
      />
    </div>
  </div>


          
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2 mt-3"
                sx={{ zIndex: 0, position: "relative" }}
              >
                    <input type="text" className="form-control form-control-sm" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..." style={{ float: "left", display: "inline-block", width: "200px" }}></input>
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={DownloadPDF}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={DownloadExcel}
                    >
                      <TableViewIcon />
                    </i>
                  </Tooltip>
                </div>
              </Stack>

              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  progressPending={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default ViewAttendance;
