import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
 import { useUser } from "../../Components/UserContext";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { formatDate } from '../../Components/dateFormate';
import CalenderModal from './CalenderModal';
import dayjs from 'dayjs';
import * as XLSX from "xlsx";


const Calendar = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const {Sr_No} =useParams();
  const [eventData, setEventData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("month").format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().endOf("month").format('YYYY-MM-DD'));
  const Open =(e)=>{
    setModalData(e)
    setIsModal(true)
  }

  const Close =()=>{
    setIsModal(false)
    
  }

  function renderEventContent(eventInfo) {
    const eventBackgroundColor = eventInfo.event.extendedProps.Time ? '#008000' : '#FF0000';
    const eventStyle = {
      cursor: 'pointer',
      backgroundColor: eventBackgroundColor
    };

    return (
      <Tooltip title={eventInfo.event.title}>
        <div style={eventStyle}>
        {eventInfo.event.extendedProps.Time ?(
            <Link 
            onClick={() => Open(eventInfo.event.extendedProps)} 
            style={{ textDecoration: "none", color: "#757575" }}
          >
            &nbsp;<i className='text-white'>{eventInfo.event.title}</i>
          </Link>
        ):(
        <>
        &nbsp;<i className='text-white'>{eventInfo.event.title}</i>
        </>
        )}
       
        </div>
      </Tooltip>
    );
  }

  const loadEvents = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/lecturedate?Org_Id=${userData.Org_Id}&Teacher_Id=${Sr_No}`
      );
      setEventData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      hideLoader();
    }
  };

  const DownloadExcel = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/downloadLectureSchedule?orgId=${userData.Org_Id}&teacherId=${Sr_No}&fromDate=${fromDate}&toDate=${toDate}`
      );
      if(result.data.length>0){
      const columns = [
        "Branch",
        "Std",
        "Batch Name",
        "Subject",
        "Topic",
        "Description",
        "Type",
        "Date",
        "From Time",
        "To Time",
        "Uploaded On",
    ];
    const rows = [columns];
    result.data.forEach((row) => {
        rows.push([
            row.Branch,
            row.Std,
            row.BatchName,
            row.Subject,
            row.Topic,
            row.Description,
            row.Type,
            row.Date,
            row.FromTime,
            row.ToTime,
            row.UploadedOn,

        ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, " Lecture Schedule");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, " Lecture Schedule.xlsx");
  }
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      hideLoader();
    }
  };


  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadEvents();
    }
  }, [userData]);

  const events = eventData.map(data => ({
    title: `${data?.Lecture_Test} of ${data?.Topic_Name}`,
    start: data?.Lecture_Date,
    Time: `${data?.From_Time} to ${data?.To_Time}`,
    Std: data?.Std_Name,
    Batch: data?.Batch_Name,
    Lecture: data?.Lecture_Test,
    Topic: data?.Topic_Name,
    Lecture_Date:data?.Lecture_Date
  }));


  return (
    
     
      <div className="card mt-2">
        <div className="card-body">
          <div className='mb-2'>
            <div className='row mb-2'>
            <div className="col-md-5">
              <div className="mb-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="Std_Name">
                    From Date :
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-sm"
                    value={fromDate}
                    onChange={(date) => setFromDate(dayjs(date.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="mb-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="Std_Name">
                    To Date :
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-sm"
                    value={toDate}
                    onChange={(date) => setToDate(dayjs(date.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
                                            <button type="button" className="btn btn-primary btn-sm text-center mt-4"
                                                onClick={DownloadExcel}>
                                                Download Excel
                                            </button>
                                        </div>
            </div>

            <hr/>
          </div>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            events={events}
            eventContent={renderEventContent}
            header={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,dayGridWeek,dayGridDay'
            }}
            height={690}
            width={100}
            allDayText='Events'
            selectable={true}
            overflow='hidden'
            editable={false}
            firstDay={1}
            slotEventOverlap={true}
            eventDisplay='block'
          />
        </div>
        {isModal && (
                    <CalenderModal
                        open={isModal}
                        onClose={Close}
                        updateData={modalData}
                    />
                )}
        {loader}
      </div>

  );
}

export default Calendar;



// const events = eventData.map(data => ({
//   title: `Rs. ${data.Capital_Amount} to ${data.Request_By}`,
//   start: data.Withdrawal_Amount_Date,
//   end: data.Withdrawal_End_Date, // Assuming there's an end date for the withdrawal
//   description: data.Description, // Additional description of the event
//   location: data.Location, // Location of the event
//   category: data.Category, // Category of the event
//   Payment_Status: data.Payment_Status
// }));
