import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { useUser } from "../../Components/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatDate, formatInputDate } from "../../Components/dateFormate";

const AddUpdateVendors = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { V_Id } = useParams();
  const [errors, setErrors] = useState({});


  const [Vendor, setVendor] = useState({
    GSTIN: "",
    Company_Name: "",
    Company_Mobile: "",
    Company_Email: "",
    Company_Address: "",
    Person_Name: "",
    Person_Mobile: "",
    Person_Email: "",
    Person_Address: ""
  });

  console.log(Vendor)

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;

    const updateVendor = {
      ...Vendor,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addVendors`, updateVendor)
        .then((res) => {
          Swal.fire("Success!", "Vendor Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/viewVendors");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateVendor = {
      ...Vendor,

      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateVendors/${V_Id}`, updateVendor)
        .then((res) => {
          Swal.fire("Success!", "Vendor Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/viewVendors");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const result = await axios.get(`${apiUrl}/getVendorsById?V_Id=${V_Id}`);
        setVendor(result.data)
        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData, V_Id]);


  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            {V_Id ? "Update Vendor Details" : "Vendor Details"}
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
            {/* {!V_Id && (
              <Link
                to={"/addMultipleVendors"}
                style={{ float: "right", marginLeft: "10px" }}
              >
                <button className="btn btn-primary btn-sm text-center">
                  Add Multiple Vendors
                </button>
              </Link>
            )} */}
            <Link
              to={"/viewVendors"}
              style={{ float: "right", marginLeft: "10px" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                View Vendor
              </button>
            </Link>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={V_Id? handleUpdate:handleSubmit}>
                  <div className="row">

                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Company_Name">
                        Organization Name<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Company_Name"
                        name="Company_Name"
                        required
                        placeholder="Enter Org Name"
                        value={Vendor.Company_Name}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Company_Name: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Company_Email">
                        Organization Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="Company_Email"
                        name="Company_Email"
                        placeholder="Enter Org Email"
                        value={Vendor.Company_Email}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Company_Email: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Company_Mobile">
                        Organization Contact No
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Company_Mobile"
                        name="Company_Mobile"
                        placeholder="Enter Org Contact No"
                        value={Vendor.Company_Mobile}
                        maxLength={10}
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setVendor({
                            ...Vendor,
                            Company_Mobile: numericInput,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="GSTIN">
                        Organization GSTIN
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="GSTIN"
                        name="GSTIN"
                        placeholder="Enter GSTIN"
                        maxLength={15}
                        value={Vendor.GSTIN}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            GSTIN: e.target.value.toUpperCase(),
                          });
                        }}
                      />

                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Company_AddressCompany_Address">
                        Organization Address
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="Company_Address"
                        placeholder="Address..."
                        rows={2}
                        value={Vendor.Company_Address}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Company_Address: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Person_Name">
                        Contact Person Name <span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Person_Name"
                        name="Person_Name"
                        required
                        placeholder="Enter Person Name"
                        value={Vendor.Person_Name}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Person_Name: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Person_Email">
                        Contact Person Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="Person_Email"
                        name="Person_Email"
                        placeholder="Enter Person Email"
                        value={Vendor.Person_Email}
                        onChange={(e) => {
                          setVendor({
                            ...Vendor,
                            Person_Email: e.target.value,
                          });
                        }}
                      />

                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Person_Mobile">
                        Contact Person Mobile No
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Person_Mobile"
                        name="Person_Mobile"
                        placeholder="Enter Person Mobile No"
                        maxLength={10}
                        value={Vendor.Person_Mobile}
                        onChange={(e) => {

                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setVendor({
                            ...Vendor,
                            Person_Mobile: numericInput,
                          });
                        }}
                      />

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                      {V_Id ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUpdateVendors;
