import React, { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/en-gb';
import '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import Swal from "sweetalert2";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import { formatInputDate } from '../../../Components/dateFormate'

const AddBannerImage = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});

    const [Bill, setBill] = useState({
        Url: "",
        Title: "",
        Date: "",
        Image: "",
        Description: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        const formdata = new FormData();
        formdata.append("User_Id", updateBill.User_Id);
        formdata.append("Org_Id", updateBill.Org_Id);
        formdata.append("Org_Name", updateBill.Org_Name);
        formdata.append("User_Role", updateBill.User_Role);
        formdata.append("LoginUsername", updateBill.LoginUsername);
        formdata.append("Display_Name", updateBill.Display_Name);
        formdata.append("Head_Id", updateBill.Head_Id);
        formdata.append("Head_Name", updateBill.Head_Name ? updateBill.Head_Name : "");
        formdata.append("Title", Bill.Title);
        formdata.append("Date", Bill.Date);
        formdata.append("Url", Bill.Url);
        formdata.append("Image", Bill.Image);
        formdata.append("Description", Bill.Description);

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addBannerImages`, formdata)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Banner Image Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewBannerImages");
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">
                        Add Banner Images
                    </div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div>
                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="Date">
                                                Date
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="date"
                                                name="Date"
                                                id="Date"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="Title">
                                                Title<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Title"
                                                id="Title"
                                                required
                                                value={Bill.Title}
                                                placeholder="Enter Title"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="Url">
                                                Link
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Url"
                                                id="Url"
                                                value={Bill.Url}
                                                placeholder="Enter Link"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="Image">
                                                Attatchment<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="file"
                                                name="Image"
                                                id="Image"
                                                required
                                                accept=".bmp, .png, .jpg, .jpeg, .pdf"
                                                onChange={(e) =>
                                                    setBill({
                                                        ...Bill,
                                                        Image: e.target.files[0],
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3 col-md-12">
                                            <label className="form-label" htmlFor="Description">
                                                Description
                                            </label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={Bill.Description}
                                                onChange={(event, editor) => {
                                                   // console.log(event,editor)
                                                    const data = editor.getData();
                                                    setBill(prevBill => ({ ...prevBill, Description: data }));
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBannerImage;
