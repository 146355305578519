import React, { useState, useEffect } from "react";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";
import Select from "react-select";


const SubjectDropdown = ({ styles, value, isDisabled,  name,required, id, onChange, Std_Id }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [SubjectNameOptions, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id && Std_Id) {
                try {
                    showLoader();
                    const result = await axios.get(
                        `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}&Std_Id=${Std_Id}`
                      );

                    const op = result.data.map((category) => ({
                        label: category.Sub_Name,
                        value: category.Sub_Id,
                    }));
                    setData(op);

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, Std_Id]);

    const customStyles = {
        control: base => ({
          ...base,
          height: 33,
          minHeight: 33
        })
      };

    return (
        <>
            {/* <label className="form-label" htmlFor={id}>
                {label}
            </label> */}
            <Select
            name={name}
            id={id}
            Std_Id={Std_Id}
           // placeholder='Select Bank'
            //classNamePrefix="select"
            required={required}
            options={SubjectNameOptions}
            value={value}
            isDisabled={isDisabled}
            onChange={onChange}
            styles={styles}
            />
            {loader}
        </>
    );
};


export default SubjectDropdown;
