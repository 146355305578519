import React, { useState, useEffect, useRef, useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import $ from "jquery";
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

const AddQuestionModal = ({
  open,
  onClose,
  TopicOptions,
  QuestionLevel,
  Class,
  Subject,
  modalData,
  opration,
}) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const [numOptions, setNumOptions] = useState(4);
  const navigate = useNavigate();

  const editorRef = useRef();
  const [showQuestionEditor, setShowQuestionEditor] = useState(false);
  const [showAnswerEditors, setShowAnswerEditors] = useState(
    Array(numOptions).fill(false)
  );
  const [showSolutionEditor, setShowSolutionEditor] = useState(false);
  console.log(open, onClose, TopicOptions, QuestionLevel, Class, Subject);
  // Debounced renderMath function to prevent excessive calls
  const debouncedRenderMath = useCallback(
    debounce(() => {
      if (window.MathJax) {
        window.MathJax.typesetPromise().catch((err) =>
          console.error("MathJax rendering error:", err)
        );
      }
    }, 300),
    []
  );

  // Original renderMath function to call the debounced function
  const renderMath = () => {
    debouncedRenderMath();
  };

  useEffect(() => {
    renderMath(); // Initial render
  }, []);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };



  const [Bill, setBill] = useState({
    Class_Id: "",
    Class_Name: "",
    Sub_Id: "",
    Sub_Name: "",
    Topic_Id: "",
    Topic_Name: "",
    Question_Type: "",
    Question_Level: QuestionLevel,
    Mark: "",
    Time: "",
    Negative_Marking: "",
    Incorrect_Negative: "",
    Unattempted_Negative: "",
    Question_in_Text: "",
    Question_in_Image: "",
    Ans_1: "",
    Ans_2: "",
    Ans_3: "",
    Ans_4: "",
    Ans_5: "",
    Ans_6: "",
    Correct_ans: "",
    Explanation: "",
    Is_Practice_Paper: "",
  });

  console.log(Bill);

  useEffect(() => {
    fetchData();
  }, [modalData]);

  const fetchData = async () => {
      showLoader();
      if (modalData) {
        setBill(modalData);
        setBill((prevBill) => ({
          ...prevBill,
          Correct_ans: getCorrectAnswer(modalData),
        }));
      }
      hideLoader();
    
  };

  const getCorrectAnswer = (modalData) => {
    if (modalData.Correct_ans === modalData.Ans_1) return "1";
    if (modalData.Correct_ans === modalData.Ans_2) return "2";
    if (modalData.Correct_ans === modalData.Ans_3) return "3";
    return "4";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleDeleteOption = () => {
    if (numOptions > 1) {
      setNumOptions(numOptions - 1);
      setShowAnswerEditors((prev) => prev.slice(0, -1));
    }
  };

  const handleEditorBlur = (editorType, index) => {
    switch (editorType) {
      case "question":
        setShowQuestionEditor(false);
        break;
      case "answer":
        setShowAnswerEditors((prev) => {
          const newEditors = [...prev];
          newEditors[index] = false;
          return newEditors;
        });
        break;
      case "solution":
        setShowSolutionEditor(false);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateBill = {
      ...Bill,
      Correct_ans:
        Bill.Correct_ans === "1"
          ? Bill.Ans_1
          : Bill.Correct_ans === "2"
          ? Bill.Ans_2
          : Bill.Correct_ans === "3"
          ? Bill.Ans_3
          : Bill.Ans_4,
      Question_Level: QuestionLevel,
      Class_Id: Class.value,
      Class_Name: Class.label,
      Sub_Id: Subject.value,
      Sub_Name: Subject.label,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};
    if (!Bill.Question_in_Text) {
      errors.Question_in_Text = "Question in Text is required";
    }
    if (!Bill.Ans_1) {
      errors.Ans_1 = "Answer 1 is required";
    }
    if (!Bill.Ans_2) {
      errors.Ans_2 = "Answer 2 is required";
    }
    if (!Bill.Ans_3) {
      errors.Ans_3 = "Answer 3 is required";
    }
    if (!Bill.Ans_4) {
      errors.Ans_4 = "Answer 4 is required";
    }

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addQuestionsTopicWise`, updateBill)
        .then((res) => {
          Swal.fire("Success!", "Questions Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                handleClose();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!! Please try again later.",
            "error"
          );
          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateBill = {
      ...Bill,
      Correct_ans:
        Bill.Correct_ans === "1"
          ? Bill.Ans_1
          : Bill.Correct_ans === "2"
          ? Bill.Ans_2
          : Bill.Correct_ans === "3"
          ? Bill.Ans_3
          : Bill.Ans_4,
      Question_Level: QuestionLevel,
      Class_Id: Class.value,
      Class_Name: Class.label,
      Sub_Id: Subject.value,
      Sub_Name: Subject.label,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};
    if (!Bill.Question_in_Text) {
      errors.Question_in_Text = "Question in Text is required";
    }
    if (!Bill.Ans_1) {
      errors.Ans_1 = "Answer 1 is required";
    }
    if (!Bill.Ans_2) {
      errors.Ans_2 = "Answer 2 is required";
    }
    if (!Bill.Ans_3) {
      errors.Ans_3 = "Answer 3 is required";
    }
    if (!Bill.Ans_4) {
      errors.Ans_4 = "Answer 4 is required";
    }

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateQuestionsTopicWise/${modalData?.Question_Id}`, updateBill)
        .then((res) => {
          Swal.fire("Success!", "Questions Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                handleClose();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!! Please try again later.",
            "error"
          );
          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };


  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
        <Modal.Title style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span>Add New Question</span>
          <Link to="/uploadQuestionImage" target="_blank">
          <Button
            variant="warning"
            size="sm"
            style={{ marginRight: 0 }}
          >
            Upload Image
          </Button>
          </Link>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={opration === "Submit" ?  handleSubmit : handleUpdate}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label" htmlFor="Topic">
                  Topic<span style={{ color: "Red" }}>*</span>
                </label>
                <select
                  className="form-select form-select-sm"
                  id="Topic"
                  name="Topic"
                  value={Bill.Topic_Id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedOption = e.target[selectedIndex];
                    setBill({
                      ...Bill,
                      Topic_Id: selectedOption.value,
                      Topic_Name: selectedOption.label,
                    });
                  }}
                >
                  <option selected disabled value="" label="--Select--"></option>
                  {TopicOptions?.map((option) => (
                    <option key={option.Topic_Id} value={option.Topic_Id}>
                      {option.Topic_Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label" htmlFor="QuestionLevel">
                  Question Level<span style={{ color: "Red" }}>*</span>
                </label>
                <select
                  className="form-select form-select-sm"
                  id="QuestionLevel"
                  name="QuestionLevel"
                  disabled
                  value={Bill.Question_Level}
                >
                  <option selected value="" label="--Select--"></option>
                  <option value="Easy">Easy</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Difficult">Difficult</option>
                </select>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" htmlFor="Mark">
                  Marks<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Mark"
                  id="Mark"
                  required
                  value={Bill.Mark}
                  placeholder="Enter Marks"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" htmlFor="Time">
                  Time (In minutes)<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Time"
                  id="Time"
                  required
                  value={Bill.Time}
                  placeholder="Enter Time"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label mb-2" htmlFor="Question_in_Text">
                  Question<span style={{ color: "Red" }}>*</span>
                </label>
                {showQuestionEditor ? (
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editorRef.current = editor;
                      renderMath();
                    }}
                    onBlur={(event, editor) => {
                      handleEditorBlur("question");
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setBill((prevBill) => ({
                        ...prevBill,
                        Question_in_Text: data,
                      }));
                      renderMath();
                    }}
                    data={Bill.Question_in_Text}
                  />
                ) : (
                  <textarea
                    className="form-control form-control-sm"
                    id="Question_in_Text"
                    name="Question_in_Text"
                    placeholder="Enter Question"
                    value={Bill.Question_in_Text}
                    required
                    onChange={(e) =>
                      setBill((prevBill) => ({
                        ...prevBill,
                        Question_in_Text: e.target.value,
                      }))
                    }
                    onFocus={() => setShowQuestionEditor(true)}
                  />
                )}
              </div>
              <div className="col-md-12">
                <label className="form-label mb-2" htmlFor="Answers">
                  Answers<span style={{ color: "Red" }}>*</span>
                </label>
              </div>
              <div className="row">
                {[...Array(numOptions)].map((_, index) => {
                  const num = index + 1;
                  return (
                    <div key={num} className="row mb-3">
                      <div className="col-md-1">
                        <input
                          type="radio"
                          id={`Ans_${num}`}
                          name="Correct_ans"
                          value={num}
                          required
                          checked={Bill.Correct_ans == num}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-11">
                        {showAnswerEditors[index] ? (
                          <CKEditor
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editorRef.current = editor;
                              renderMath();
                            }}
                            onBlur={(event, editor) => {
                              handleEditorBlur("answer", index);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBill((prevBill) => ({
                                ...prevBill,
                                [`Ans_${num}`]: data,
                              }));
                              renderMath();
                            }}
                            data={Bill[`Ans_${num}`]}
                          />
                        ) : (
                          <textarea
                            className="form-control form-control-sm"
                            id={`Ans_${num}`}
                            placeholder="Enter Answer"
                            name={`Ans_${num}`}
                            value={Bill[`Ans_${num}`]}
                            required
                            onChange={(e) =>
                              setBill((prevBill) => ({
                                ...prevBill,
                                [`Ans_${num}`]: e.target.value,
                              }))
                            }
                            onFocus={() =>
                              setShowAnswerEditors((prev) => {
                                const newEditors = [...prev];
                                newEditors[index] = true;
                                return newEditors;
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="col-md-12 mt-3 text-end">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={handleDeleteOption}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
                <div className="mb-3 col-md-12">
                  <label className="form-label mb-2" htmlFor="Explanation">
                    Solution
                  </label>
                  {showSolutionEditor ? (
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        editorRef.current = editor;
                        renderMath();
                      }}
                      onBlur={(event, editor) => {
                        handleEditorBlur("solution");
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setBill((prevBill) => ({
                          ...prevBill,
                          Explanation: data,
                        }));
                        renderMath();
                      }}
                      data={Bill.Explanation}
                    />
                  ) : (
                    <textarea
                      className="form-control form-control-sm"
                      id="Explanation"
                      name="Explanation"
                      placeholder="Enter Solution"
                      value={Bill.Explanation}
                      onChange={(e) =>
                        setBill((prevBill) => ({
                          ...prevBill,
                          Explanation: e.target.value,
                        }))
                      }
                      onFocus={() => setShowSolutionEditor(true)}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 text-center mt-3">
                <button type="submit" className="btn btn-sm btn-primary">
                  {opration === "Submit" ? "Add" : "Update"}
                </button>
              </div>
            </div>
          </form>
          {loader}
       
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddQuestionModal;
