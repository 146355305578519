import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import dayjs from "dayjs";
import Dropdown from "../../DropDowns/DropDown";
import { formatInputDate } from '../../../Components/dateFormate'

const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [BatchOptions, setBatchOptions] = useState([]);
    const [StdOptions, setStdOptions] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    


    const [Bill, setBill] = useState({
        Title: updateData.Title,
        Video_Link: updateData.Video_Link,
        Standard_Id: updateData.Standard_Id,
        Standard: updateData.Standard,
        Batch_Id: updateData.Batch_Id,
        Batch: updateData.Batch,
        Description: updateData.Description,
        Link_Type: updateData.Link_Type,
    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData, Bill?.Standard_Id]);


    const fetchData = async () => {
        try {
            showLoader();

            if (userData && userData.Org_Id && userData?.Head_Id) {
                showLoader();
                const std = await axios.get(
                    `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
                );


                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
                //console.log(getStd);
            }

            if (userData && userData.Org_Id && Bill?.Standard_Id) {

                const batch = await axios.get(
                    `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Standard_Id}`
                );

                const getBatch = batch.data.map((category) => ({
                    label: category.Batch_Name,
                    value: category.Batch_Id,
                }));
                setBatchOptions(getBatch);
                // console.log(getBatch);
            }
            // console.log(res.data);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,

            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateVideoLink/${updateData?.Row_Id}`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "VideoLink Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Video Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                                    <div className="row">

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Standard">
                                                Standard<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <Dropdown
                                                options={StdOptions}
                                                name="Standard"
                                                required={true}
                                                id="Standard"
                                                value={Bill.Standard_Id}
                                                onChange={(e) => {
                                                    const selectedIndex = e.target.selectedIndex;
                                                    const selectedOption = e.target[selectedIndex];
                                                    setBill({
                                                        ...Bill,
                                                        Standard_Id: selectedOption.value,
                                                        Standard: selectedOption.label,
                                                    });
                                                }}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Batch">
                                                Batch<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <Dropdown
                                                options={BatchOptions}
                                                name="Batch"
                                                required={true}
                                                id="Batch"
                                                value={Bill.Batch_Id}
                                                disabled={Bill.Standard_Id === ""}
                                                onChange={(e) => {
                                                    const selectedIndex = e.target.selectedIndex;
                                                    const selectedOption = e.target[selectedIndex];
                                                    setBill({
                                                        ...Bill,
                                                        Batch_Id: selectedOption.value,
                                                        Batch: selectedOption.label,
                                                    });
                                                }}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Title">
                                                Title<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Title"
                                                id="Title"
                                                required
                                                value={Bill.Title}
                                                placeholder="Enter Title"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-12">
                                            <label className="form-label" htmlFor="Description">
                                                Description
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="Description"
                                                name="Description"
                                                rows={2}
                                                placeholder="Enter Description..."
                                                value={Bill.Description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Link_Type">
                                                        Link Type<span style={{ color: "Red" }}>*</span>
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Link_Type"
                                                        name="Link_Type"
                                                        onChange={handleChange}
                                                value={Bill.Link_Type}

                                                    >
                                                        <option selected value="">
                                                            --Select--
                                                        </option>
                                                        <option selected value="YOUTUBE">
                                                        YOUTUBE
                                                        </option>
                                                        <option selected value="GOOGLE DRIVE">
                                                            GOOGLE DRIVE
                                                        </option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-8">
                                            <label className="form-label" htmlFor="Video_Link">
                                                Link<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Video_Link"
                                                id="Video_Link"
                                                required
                                                value={Bill.Video_Link}
                                                placeholder="Enter Video Link"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-4 d-none">
                                            <div class="form-check">
                                                <input
                                                    className="form-check-input"
                                                    id="flexCheckDefault2"
                                                    type="checkbox"
                                                //   checked={installment.Set_Installment}
                                                //   onChange={(e) => {
                                                //     setInstallment({
                                                //       ...installment,
                                                //       Set_Installment: e.target.checked,
                                                //       Full_Payment: false,
                                                //     });
                                                //   }}
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                    <h6>Send Whatsapp Message</h6>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                            Update
                                            </button>
                                        </div>
                                    </div>
                                </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
