import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import Card from "./CardComponend";
import { useNavigate, Link } from "react-router-dom";
import AddUpdateELearning from './AddUpdateELearning'

const ViewELearning = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [IsModal, setIsModal] = useState(false);
  const [IsModal1, setIsModal1] = useState(false);
  const [indexValue, setIndexValue] = useState();
  const [eData, setEData] = useState([]);
  const [TopicId, setTopicId] = useState("");
  const [Topics, setTopics] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [StdId, setStdId] = useState("");
  const [SubjectId, setSubjectId] = useState("");
  const [StdName, setStdName] = useState("");
  const [SubjectName, setSubjectName] = useState("");

  const showModal = () => {
    setIsModal(true);
  };

  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const showModal1 = (e) => {
    setIndexValue(e);
    setIsModal1(true);
    fetchData();
  };

  const closed1 = () => {
    setIsModal1(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [userData, StdId, SubjectId, TopicId]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData?.Org_Id) {
        const AppClass = await axios.get(
          `${apiUrl}/getELearningStd?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}`
        );
        const AppClassOptions = AppClass.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(AppClassOptions);
        console.log(AppClassOptions);
      }

      if (StdId) {
        const AppSubject = await axios.get(
          `${apiUrl}/getELearningSub?Std_Id=${StdId}`
        );
        const AppSubjectOptions = AppSubject.data.map((category) => ({
          label: category.Sub_Name,
          value: category.Sub_Id,
        }));
        setSubjectOption(AppSubjectOptions);
      }

      if (StdId && SubjectId) {
        const getTopic = await axios.get(
          `${apiUrl}/getELearningTopic?Std_Id=${StdId}&Sub_Id=${SubjectId}`
        );
        setTopics(getTopic.data);
        console.log(getTopic.data);
      }

      if (TopicId) {
        const getQuestions = await axios.get(
          `${apiUrl}/getELearningData?Topic_Id=${TopicId}`
        );
        setEData(getQuestions.data);
        console.log(getQuestions.data);
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const deleteUser = (Id, Name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id, Name);
      }
    });
  };

  const handleDelete = async (Id, Name) => {
    showLoader();

    const obj = {
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      User_Name: userData?.UserName,
      Name: Name,
    };

    try {
      await axios.delete(`${apiUrl}/deleteELearningVideo/${Id}`, { data: obj });
      Swal.fire("Deleted!", "Video has been deleted.", "success");
      fetchData();
    } catch (error) {
      console.error("Error while deleting Video record:", error);
      Swal.fire("Error", "Failed to delete Video record.", "error");
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">E-Learning Videos</div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
          <div className="col-md-5 d-flex justify-content-end align-items-center">
    <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
    <button className="btn btn-sm btn-primary ms-2" type="button" onClick={showModal}>Add</button>
  </div>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Standard">
                          Standard
                        </label>
                        <Select
                          id="Standard"
                          value={StdId ? { label: StdName, value: StdId } : null}
                          options={StdOptions}
                          placeholder="--Select--"
                          isClearable
                          onChange={(e) => {
                            if (e) {
                              setStdId(e.value);
                              setStdName(e.label);
                            } else {
                              setStdId("");
                              setStdName("");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Subject">
                          Subject
                        </label>
                        <Select
                          id="Subject"
                          value={
                            SubjectId ? { label: SubjectName, value: SubjectId } : null
                          }
                          options={SubjectOption}
                          placeholder="--Select--"
                          isClearable
                          isDisabled={StdId === ""}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setSubjectId(selectedOption.value);
                              setSubjectName(selectedOption.label);
                            } else {
                              setSubjectId("");
                              setSubjectName("");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Topics?.length > 0 && (
            <div className="row">
              <div
                className="col-md-3"
                style={{
                  color: "#005c9b",
                  maxHeight: "1000px",
                  overflowY: "scroll",
                }}
              >
                {Topics?.map((topic, index) => (
                  <div
                    className="card pb-1"
                    key={index}
                    style={{ marginBottom: "0.6rem", height: "80px" }}
                  >
                    <div className="sidebar mt-1" style={{ textAlign: "left" }}>
                      <Link
                        onClick={() => {
                          setTopicId(topic.Topic_Id);
                        }}
                        style={{
                          fontSize: "18px",
                          paddingBottom: "15px",
                          marginLeft: "10px",
                          display: "block",
                          height: "100%",
                        }}
                      >
                        <div className="row p-2">
                          <div
                            className="col-md-12"
                            style={{
                              overflow: "hidden",
                              maxHeight: "40px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span>{topic.Topic_Name}</span>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px", textAlign: "right" }}
                          >
                            <span>Video: {topic.Videos}</span>
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className={"col-md-9"}>
                <div className="card px-3 pb-3 mt-1">
                  {eData?.length > 0 ? (
                    <div className="row h-100 g-3">
                      {eData?.map((video) => (
                        <Card video={video} key={video.Id} onDelete={deleteUser}  onEdit={showModal1}/>
                      ))}
                    </div>
                  ) : (
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <div>
                        <div className="row">
                          <div
                            style={{
                              textAlign: "center",
                              padding: "10px 0 30px",
                            }}
                          >
                            <h2>Select Topic</h2>
                            <h5>to see E-Learning Videos</h5>
                            {/* <LottieAnimation
                              animationData="/assets/img/AnimationVideo.json"
                              height="200px"
                              width="200px"
                            /> */}

                            <img src="/assets/img/VideoAnim.gif"
                              style={{ height: "200px", width: "200px" }}
                              alt="Create Question"
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {IsModal && (
        <AddUpdateELearning
          open={IsModal}
          onClose={closed}
          videoData={""}

opration="Submit"
        />
      )}
    
      {IsModal1 && <AddUpdateELearning open={IsModal1} onClose={closed1} videoData={indexValue} 
opration="Update"

          />}
      {loader}
    </div>
  );
};

export default ViewELearning;
