import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useUser } from "../../../../Components/UserContext";
import { formatInputDate } from "../../../../Components/dateFormate";
import { Await, Link } from "react-router-dom";

const columnWidthMap = {
    'All Rights': 2,
    'Select Features': 10,
    Admissions: 7,
    'Staff Management': 5,
    'Accountant Master': 12,
    Announcements: 12,
    'Study Materials': 8,
    'App Promo': 4,
    Students: 5,
    Lectures: 7,
    Tests: 4,
    'Mock Tests': 4,
    'Daily Quiz': 4,
    Inventory: 12,
    Whatsapp: 12,
    Reports: 12,
    'Organization Settings': 12,
    'Staff Payroll': 6,
    'User Settings': 6,
    'Manage App': 12,
};

function updateTaskObjectFromString(task, numbersString) {
    if (!task || typeof task !== 'object' || !numbersString || typeof numbersString !== 'string') {
        console.error('Invalid input');
        return {};
    }

    const numbersArray = numbersString.split(',').map(Number);
    const updatedTask = {};

    for (const key in task) {
        if (task.hasOwnProperty(key)) {
            const isNumericKey = !isNaN(Number(key));
            if (isNumericKey) {
                updatedTask[key] = numbersArray.includes(Number(key)) ? true : false;
            } else {
                updatedTask[key] = task[key];
            }
        }
    }

    return updatedTask;
}

function updateGroupStatus(taskGroups, task) {
    if (!taskGroups || typeof taskGroups !== 'object' || !task || typeof task !== 'object') {
        console.error('Invalid input');
        return {};
    }

    // Ensure 'All Rights' is always set to true
    if (taskGroups.hasOwnProperty('All Rights')) {
        taskGroups['All Rights'] = true;
    }

    for (const groupName in taskGroups) {
        if (taskGroups.hasOwnProperty(groupName) && groupName !== 'All Rights') {
            const groupTasks = taskGroups[groupName];
            let allTasksTrue = true;

            for (const taskItem of groupTasks) {
                const taskNo = taskItem.Task_No;
                if (task[taskNo] === undefined || task[taskNo] === false) {
                    allTasksTrue = false;
                    break;
                }
            }

            taskGroups[groupName] = allTasksTrue;
        }
    }

    return taskGroups;
}

const PermissionsAdminStaff = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const [rightsData, setRightsData] = useState({});
    const [selectedTasks, setSelectedTasks] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const prevSelectedTasksRef = useRef(selectedTasks);

    const handleClose = () => {
        setShow(false);
        onClose();
    };
    //console.log(updateData);
   // console.log(rightsData);
    //console.log(selectedTasks);

    const [Bill, setBill] = useState({
        Task_Ids: ""
    });

   // console.log(Bill);

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData]);

    const fetchPermissions = useCallback(_.debounce(async () => {
        showLoader();

        try {
           /// console.log(selectedTasks);
           // console.log(rightsData);
            //console.log(updateData);
    
            const menuRes = await axios.get(`${apiUrl}/getAdminPermissions`, {
                params: {
                    OrgId: userData?.Org_Id,
                    UserId: updateData.UserId,
                    Role: updateData.Role,
                },
            });
           // console.log(menuRes.data);
            //console.log(selectedTasks);

    
            const rightsDataCopy = JSON.parse(JSON.stringify(rightsData));
            const updatedTask = updateTaskObjectFromString(selectedTasks, menuRes.data);
            const updatedTaskGroup = updateGroupStatus(rightsDataCopy, selectedTasks);
    
           //console.log(updatedTask);
          // console.log(updatedTaskGroup);
    
            setSelectedTasks(prevSelectedTasks => ({
                ...prevSelectedTasks,
                ...updatedTask,
                ...updatedTaskGroup
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }finally{
            hideLoader();
        }
    }, 500), [selectedTasks, rightsData, updateData]); // 500ms debounce time
    
    useEffect(() => {
        showLoader();
        if (selectedTasks && rightsData && updateData) {
            // Ensure selectedTasks has changed
            if (JSON.stringify(selectedTasks) !== JSON.stringify(prevSelectedTasksRef.current)) {
                prevSelectedTasksRef.current = selectedTasks;
                fetchPermissions();
            }
        }
        hideLoader();
    }, [selectedTasks, rightsData, updateData]);
    

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(`${apiUrl}/getDropdownMenus?Org_Id=${userData.Org_Id}`);
            const groupedTasks = res.data?.reduce((acc, task) => {
                const taskObj = {
                    Task_No: task.Task_No,
                    Task_Name: task.Task_Name
                };
                if (!acc[task.Group_Name]) {
                    acc[task.Group_Name] = [];
                }
                acc[task.Group_Name].push(taskObj);
                return acc;
            }, {});

            const initialCheckedStatus = Object.values(groupedTasks).flat().reduce((acc, task) => {
                if (task?.Task_Name !== 'All') {
                    acc[task.Task_No] = true;
                }
                return acc;
            }, {});

            Object.keys(groupedTasks).forEach((groupName) => {
                const allGroupTasksChecked = groupedTasks[groupName]
                    .filter((task) => task?.Task_Name !== 'All')
                    .every((task) => initialCheckedStatus[task.Task_No]);

                if (allGroupTasksChecked) {
                    initialCheckedStatus[groupName] = true;
                }
            });
            setRightsData(groupedTasks);
            setSelectedTasks(initialCheckedStatus);
            setSelectAll(Object.keys(initialCheckedStatus).length > 0);
      
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        const taskIdsString = Object.entries(selectedTasks)
            .filter(([taskNo, isSelected]) => isSelected && !isNaN(Number(taskNo)))
            .map(([taskNo]) => taskNo)
            .join(',');

        setBill({
            ...Bill,
            Task_Ids: taskIdsString
        });
    }, [selectedTasks]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        showLoader();
        axios
            .put(`${apiUrl}/updateAdminStaffTask/${updateData?.UserId}`, updateBill)
            .then((res) => {
                Swal.fire("Success!", "Permissions Updated Successfully!!", "success").then((result) => {
                    if (result.isConfirmed) {
                        handleClose();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };

    const handleCheckAll = (e) => {
        const isChecked = e.target.checked;
        const newCheckedStatus = Object.keys(selectedTasks).reduce((acc, taskNo) => {
            acc[taskNo] = isChecked;
            return acc;
        }, {});

        setSelectedTasks(newCheckedStatus);
        setSelectAll(isChecked);
    };

    const handleGroupCheckboxChange = (e, groupName) => {
        const isChecked = e.target.checked;

        // Update the selectedTasks state to check/uncheck all tasks within the group
        setSelectedTasks((prevSelectedTasks) => {
            const updatedTasks = { ...prevSelectedTasks };

            rightsData[groupName].forEach((task) => {
                if (task?.Task_Name !== 'All') {
                    updatedTasks[task.Task_No] = isChecked;
                }
            });

            // Update group-level checkbox
            updatedTasks[groupName] = isChecked;

            // Update selectAll based on whether all tasks are checked
            setSelectAll(Object.values(updatedTasks).every((value) => value));

            return updatedTasks;
        });
    };

    const handleCheckboxChange = (e) => {
        const taskNo = e.target.id;

        setSelectedTasks((prevSelectedTasks) => {
            const updatedTasks = {
                ...prevSelectedTasks,
                [taskNo]: e.target.checked,
            };

            // Check if all non-'All' tasks in the group are selected or not
            Object.entries(rightsData).forEach(([groupName, tasks]) => {
                const allGroupTasksChecked = tasks
                    .filter((task) => task?.Task_Name !== 'All')
                    .every((task) => updatedTasks[task.Task_No]);

                // If all tasks except 'All' in the group are selected, set the group checkbox as true
                if (allGroupTasksChecked) {
                    updatedTasks[groupName] = true;
                } else {
                    updatedTasks[groupName] = false;
                }
            });

            const allChecked = Object.values(updatedTasks).every((value) => value);
            setSelectAll(allChecked);

            return updatedTasks;
        });
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Admin Permissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row g-4">
                        <div className="col-12 col-xl-12 order-1 order-xl-0">
                            <div className="card shadow-none border border-300 my-4" data-component-card="data-component-card">
                                <div className="card-body p-0">
                                    <div className="p-4 code-to-copy">
                                        <div className="row">
                                            {Object.entries(rightsData).map(([groupName, tasks], idx) => (
                                                <div key={idx} className={`col-md-${columnWidthMap[groupName] || 12}`}>
                                                    <fieldset className="my-fieldset">
                                                        {tasks[0]?.Task_Name === 'All' && (
                                                            <>
                                                                <legend className="my-legend">{groupName}</legend>
                                                                <span className="my-span">
                                                                    <input
                                                                        id={`${tasks[0].Task_No}`}
                                                                        type="checkbox"
                                                                        name={`${tasks[0]?.Task_Name}`}
                                                                        checked={selectAll}
                                                                        onChange={handleCheckAll}
                                                                    />
                                                                    <label htmlFor={`${tasks[0].Task_No}`} className="lbl ml-2">
                                                                        &nbsp;{tasks[0]?.Task_Name}
                                                                    </label>
                                                                </span>
                                                            </>
                                                        )}
                                                        {tasks?.map((task) => (
                                                            task?.Task_Name !== 'All' && (
                                                                <React.Fragment key={task.Task_No}>
                                                                    <legend className="my-legend">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`group-${groupName}`}
                                                                            checked={selectedTasks[groupName] || false}
                                                                            onChange={(e) => handleGroupCheckboxChange(e, groupName)}
                                                                        />
                                                                        <label htmlFor={`group-${groupName}`} className="lbl ml-2">
                                                                            &nbsp;{groupName}
                                                                        </label>
                                                                    </legend>
                                                                    <span className="my-span">
                                                                        <input
                                                                            id={`${task.Task_No}`}
                                                                            type="checkbox"
                                                                            name={`${task.Task_No}`}
                                                                            checked={selectedTasks[`${task.Task_No}`] || false}
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                        <label htmlFor={`${task.Task_No}`} className="lbl ml-2">
                                                                            &nbsp;{task?.Task_Name}
                                                                        </label>
                                                                    </span>
                                                                </React.Fragment>
                                                            )
                                                        ))}
                                                    </fieldset>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loader}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>
                </Modal.Footer>
            </Modal>
       
        </div>
    );
};

export default PermissionsAdminStaff;
