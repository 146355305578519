import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import dayjs from "dayjs";
import { formatDate } from '../../../Components/dateFormate'
import { FaEye } from "react-icons/fa";


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [Log, setLog] = useState([]);
    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(updateData);


    useEffect(() => {
        const fetchData = async () => {
          try {
            showLoader();
    
            if (updateData?.Inv_Id) {
              const result = await axios.get(`${apiUrl}/getProductDetails/${updateData?.Inv_Id}`);
              setLog(result.data);
              console.log(result.data);

            }
    
            hideLoader();
          } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
          }
        };
    
        fetchData();
      }, [updateData]);





    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <label className="control-label">Name :</label>
                </div>
                <div className="col-md-7">
                  <span >{updateData?.Name}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <label className="control-label">Invoice No.:</label>
                </div>
                <div className="col-md-7">
                  <span>{updateData?.Inv_No}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <label className="control-label">Ordered On :</label>
                </div>
                <div className="col-md-7">
                  <span >{formatDate(updateData?.Inv_Date)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <label className="control-label">Total Amount :</label>
                </div>
                <div className="col-md-7">
                  <span >{parseFloat(updateData?.Total_Amount).toFixed(2)}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <label className="control-label">Discount Amount :</label>
                </div>
                <div className="col-md-7">
                  <span >{parseFloat(updateData?.Bill_DiscountAmount).toFixed(2)}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <label className="control-label">Net Amount :</label>
                </div>
                <div className="col-md-7">
                  <span >{parseFloat(updateData?.Net_Bill_Amt).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive" style={{ padding: '20px 0px' }}>
              <div>
                <table
                  cellSpacing="0"
                  align="center"
                  rules="all"
                  className="display table table-bordered table-hover basic-datatables"
                  border="1"
                  id="ContentPlaceHolder1_gvProducts"
                  style={{ borderCollapse: 'collapse', border: '1px solid rgba(33, 33, 33, 0.1) !important' }}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th align="center" scope="col">Name</th>
                      <th align="center" scope="col">Rate</th>
                      <th align="center" scope="col">Qty</th>
                      <th align="center" scope="col">Total Amount</th>
                      <th align="center" scope="col">Discount</th>
                      <th align="center" scope="col">Gst Type</th>
                      <th align="center" scope="col">GST</th>
                      <th align="center" scope="col">GST Amount</th>
                      <th align="center" scope="col">Net Amount</th>
                      <th align="center" scope="col">GST Net Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Log.map((product, index) => (
                    <tr key={index}>
                      <td align="center">
                        <span style={{ textAlign: 'center' }}>{index+1}</span>
                      </td>
                      <td>
                        <span className="f-12">{product?.Prod_Name}</span>
                      </td>
                      <td align="center">
                        <span className="f-12">{parseFloat(product?.Price).toFixed(2)}</span>
                      </td>
                      <td align="center"> 
                        <span>{parseFloat(product?.Qty).toFixed(2)}</span>
                      </td>
                      <td align="center">
                        <span>{parseFloat(product?.Total_Amount).toFixed(2)}</span>
                      </td>
                      <td align="center">
                        <span>{parseFloat(product?.Discount).toFixed(2)}</span>
                      </td>
                      <td align="center">
                        <span>{product?.GST_Type}</span>
                      </td>
                      <td align="center">
                        <span>{parseFloat(product?.GST_Percent).toFixed(2)}</span>
                      </td>
                      <td align="center">
                        <span>{parseFloat(product?.GST_Amt).toFixed(2)}</span>
                      </td>
                      <td align="center">
                        <span>{parseFloat(product?.Net_Amount).toFixed(2)}</span>
                      </td>
                      <td align="center">
                        <span>{parseFloat(product?.GST_Net_Amount).toFixed(2)}</span>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
