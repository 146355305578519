import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import { useUser } from "../../Components/UserContext";
import UpdateStaff from "./UpdateStaff";
import StaffPlanner from "./StaffPlanner";
import Payroll from "./Payroll";
import ViewLectureSchedule from "./ViewLectureSchedule";
import BiometricReport from "./BiometricReport";
import SalaryMaster from "./SalaryMaster";
import axios from "axios";
import { apiUrl } from "../../Components/apiConfig";
import { formatDate } from "../../Components/dateFormate";
import VerticalDivider from "../../Components/VerticalDivider";
// import SetInstallment from "./Fees/SetInstallment";
// import FeesPayment from "./Fees/FeesPayment";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { BsArrowLeftSquareFill } from "react-icons/bs";

const StaffProfile = () => {
    const { Sr_No } = useParams();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [key, setKey] = useState(1);


    const handleTabSelect = (selectedKey) => {
        setKey(parseInt(selectedKey));
    };

    const [staffData, setstaffData] = useState();

    useEffect(() => {
        fetchData();
    }, [userData, Sr_No]);

    const fetchData = async () => {
        if (userData && userData.Org_Id && Sr_No) {
            try {
                showLoader();
                const res = await axios.get(
                    `${apiUrl}/getStaffProfile/${Sr_No}/${userData.Org_Id}`
                );
                setstaffData(res.data);
                console.log(res.data);
                hideLoader();
            } catch (error) {
                console.error("Error fetching headmaster data:", error);
                hideLoader();
            }
        }
    };

    const CountWithCommas = (number) => {
        if (number === null || isNaN(number)) {
            return "0";
        }
        return number.toLocaleString();
    };

    const [showAdditionalContent, setShowAdditionalContent] = useState(true);

    const handleButtonClick = () => {
        // Toggle the state when the button is clicked
        setShowAdditionalContent(!showAdditionalContent);
    };

    return (
        <>
            <div className="content">
                <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                    <div className="text-end">
                        <button className="btn btn-sm" onClick={handleButtonClick}>
                            {showAdditionalContent ? <i style={{ fontSize: "2em" }}><BsArrowRightSquareFill /></i> :
                                <i style={{ fontSize: "2em" }}><BsArrowLeftSquareFill /></i>}
                        </button>
                    </div>

                </h4>
                <div className="row">
                    <div className={showAdditionalContent ? "col-12 col-xl-8" : "col-12 col-xl-12"} style={{
                        WebkitTransition: 'all 0.5s ease',
                        MozTransition: 'all 0.5s ease',
                        OTransition: 'all 0.5s ease',
                        transition: 'all 0.5s ease'
                    }}>
                        <div className="row p-3">
                            <div className="card">
                                <Tabs
                                    defaultActiveKey={1}
                                    id="fill-tab-example"
                                    fill
                                    activeKey={key}
                                    onSelect={handleTabSelect}
                                    className="mt-4 mb-2 custom-tabs-container"
                                >
                                    <Tab eventKey={1} title="Profile">
                                        {key === 1 && (
                                            <UpdateStaff loadData={fetchData} />
                                        )}


                                    </Tab>
                                    <Tab eventKey={2} title="Planner">
                                        {key === 2 && (
                                            <StaffPlanner staffData={staffData} />
                                        )}
                                    </Tab>
                                    <Tab eventKey={3} title="Salary">
                                        {key === 3 &&(<SalaryMaster SalaryMaster={staffData?.Name} />)}

                                    </Tab>
                                    <Tab eventKey={4} title="Schedule">
                                        {key === 4 && (
                                            <ViewLectureSchedule />
                                        )}

                                    </Tab>
                                    <Tab eventKey={5} title="Payroll">
                                        {key === 5 && (
                                            <Payroll staffData={staffData} />
                                        )}

                                    </Tab>
                                    <Tab eventKey={6} title="Biometric Report">
                                        {key === 6 && (
                                            <BiometricReport staffData={staffData} />
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className={showAdditionalContent ? "col-12 col-xl-4 mt-3" : "d-none"}>
                        <div className="card">
                            <div className="card-body d-flex flex-column justify-content-between pb-3">
                                <div className="row align-items-center g-5 mb-3 text-center text-sm-start">
                                    <div className="col-12 col-sm-auto mb-sm-2">
                                        <div className="avatar avatar-4xl">
                                            <img className="rounded-circle img-thumbnail bg-white shadow-sm"

                                                width="200" src={`${apiUrl}/Documents/Staff/${staffData?.Photo}`}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop
                                                    e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                                                }} alt="Photos" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-auto flex-1">
                                        <h3 className="me-2">{staffData?.Name}</h3>
                                        {/* <p className="text-800">Joined 3 months ago</p> */}

                                    </div>
                                </div>
                                <div className="d-block  border-top border-dashed border-300 pt-4">
                                    <span className="badge badge-phoenix badge-phoenix-primary mb-2">
                                        staff Details :</span>

                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Designation{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Designation}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Shift 1{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Shift1Time}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Shift 2{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Shift2InTime}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Qualification{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Qualification}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Amount{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Amount}
                                        </span>
                                    </div>

                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            OverTime Granted{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Ot}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Deduction Allowed{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.Deduction}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Week Off{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.WeekOffs}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Second Shift{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.SecondShift}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Multiple Punch Allowed{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {staffData?.MultiplePunch}
                                        </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {loader}

            </div>

        </>


    );
};

export default StaffProfile;
