import React, { useEffect, useRef, useState, useMemo } from "react";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

const ReportAgGridTable = ({ row, column }) => {
  // Function to calculate totals
  const calculateTotalRow = (data) => {
    const totalRow = { Date: "Total Amount", Income: 0, Expense: 0 };
    data.forEach((item) => {
      const income = parseFloat(item.Income);
      const expense = parseFloat(item.Expense);

      if (!isNaN(income)) {
        totalRow.Income += income;
      }
      if (!isNaN(expense)) {
        totalRow.Expense += expense;
      }
    });
    return totalRow;
  };

  const [rowData, setRowData] = useState([...row, calculateTotalRow(row)]);
  const [colDefs, setColDefs] = useState(column);
  const gridApiRef = useRef(null);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    resizeGrid();
  };

  const resizeGrid = () => {
    if (gridApiRef.current) {
      gridApiRef.current.sizeColumnsToFit();
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      suppressColumnFilter: true,
      suppressColumnMenu: true,
      suppressAggFuncInHeader: true,
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resizeGrid);
    return () => {
      window.removeEventListener("resize", resizeGrid);
    };
  }, []);

  useEffect(() => {
    setRowData([...row, calculateTotalRow(row)]);
  }, [row]);

  return (
    <div className="ag-theme-quartz" style={{ width: "100%", height: "auto" }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        rowSelection="disabled"
        suppressContextMenu={true}
        enableCellTextSelection={true}
        frameworkComponents={{
          totalRowRenderer: TotalRowRenderer,
        }}
        getRowStyle={(params) => {
          if (params.node.rowIndex === rowData.length - 1) {
            return { fontWeight: "bold" };
          }
        }}
      />
    </div>
  );
};

const TotalRowRenderer = (props) => {
  const value = props.value;
  if (typeof value === "number") {
    return <span style={{ fontWeight: "bold" }}>{value.toFixed(2)}</span>;
  }
  return <span style={{ fontWeight: "bold" }}>{value}</span>;
};

export default ReportAgGridTable;
