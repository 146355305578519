import React, { useEffect, useRef, useState, useMemo } from "react";
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

const Testing = ({ row, column }) => {
    const [rowData, setRowData] = useState(row);
    const gridApiRef = useRef(null);
    const key = useMemo(() => row.length, [rowData]);

    const onGridReady = (params) => {
        gridApiRef.current = params.api;
        resizeGrid();
    };

    const resizeGrid = () => {
        if (gridApiRef.current) {
            gridApiRef.current.sizeColumnsToFit();
        }
    };

    const defaultColDef = useMemo(() => ({
        flex: 1,
        filter: true,
        cellDataType: false,
        suppressColumnFilter: true,
        suppressColumnMenu: true,
        suppressAggFuncInHeader: true,
    }), []);

    useEffect(() => {
        window.addEventListener('resize', resizeGrid);
        return () => window.removeEventListener('resize', resizeGrid);
    }, []);

    // Update state when props change
    useEffect(() => {
        setRowData(row);
    }, [row]);

    return (
        <div className="ag-theme-quartz">
            {rowData && rowData.length > 0 ? (
                <AgGridReact
                    key={key}
                    rowData={rowData}
                    pagination={true}
                    paginationPageSize={10}
                    columnDefs={column}
                    enableAdvancedFilter={true}
                    defaultColDef={defaultColDef}
                    paginationPageSizeSelector={[10, 25, 50, 100]}
                    domLayout="autoHeight"
                    onGridReady={onGridReady}
                    rowSelection="disabled"
                    suppressContextMenu={true}
                />
            ) : (
                <h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px" }}>
                    Data Not Found!!
                </h5>
            )}
        </div>
    );
}

export default Testing;
