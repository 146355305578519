import React, { useState, useEffect } from "react";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import { useUser } from "../../Components/UserContext";
import Swal from "sweetalert2";
import Select from 'react-select'
import { formatInputDate } from "../../Components/dateFormate";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";

const ManualWhatsapp = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [BatchOptions, setBatchOptions] = useState([]);

    const [StdOptions, setStdOptions] = useState([]);

    const [studentData, setStudentData] = useState([]);


    const [filter, setFilter] = useState({
        Date: new Date(),
        Std_Id: "",
        Std_Name: "",
        Batch_Id: "",
        Batch_Name: ""
    });

    const [checkedStatus, setCheckedStatus] = useState({});
    const [checkAll, setCheckAll] = useState(true);

    const [selectedMobiles, setSelectedMobiles] = useState([]);




    console.log(selectedMobiles)
    console.log(studentData)
    console.log(checkedStatus)
  




    const [message, setMessage] = useState({
        MobileData: [],
        Message: "", ImgAttachment: "", DocAttachment: "" 

    });



    const submitHandler = async (event) => {
        event.preventDefault();
        const updatedMessage = {
            ...message,
            MobileData: selectedMobiles,
            Head_Id: userData?.Head_Id,
            Date: filter?.Date,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };


        showLoader();

        await axios
            .post(`${apiUrl}/sendStudentAttendanceMessages`, updatedMessage)
            .then((res) => {
                Swal.fire("Success!", "Message Sent Successfully!!", "success");
                setMessage({ Mobile: [], Message: "", ImgAttachment: "", DocAttachment: "" });
                setSelectedMobiles([]);
                setCheckedStatus({});
                    loadStdBatchData();
                    loadStudentData();
              
                setCheckAll(true);
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire("Server Timeout", "Server is Busy!!! Please try again later.", "error");
                hideLoader();
            });
    };

    const loadStdBatchData = async () => {
        showLoader();
        try {
            if (userData && userData.Org_Id && userData.Head_Id) {
                const std = await axios.get(
                    `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
                );
                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
            }

            if (userData && userData.Org_Id && filter?.Std_Id) {

                const batch = await axios.get(
                    `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${filter.Std_Id}`
                );

                const getBatch = batch.data.map((category) => ({
                    label: category.Batch_Name,
                    value: category.Batch_Id,
                }));
                setBatchOptions(getBatch);
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Plan data: ", error);
        }
    };

    const loadStudentData = async () => {
        showLoader();
        if(userData?.Org_Id && userData?.Head_Id && filter?.Date){
        try {
            const result = await axios.get(
                `${apiUrl}/getStudentAttandanceMessageData?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}&StdId=${filter.Std_Id}&BatchId=${filter.Batch_Id}&Date=${formatInputDate(filter.Date)}`
            );
            console.log(result.data);
            const newCheckedStatus = result.data.reduce((acc, student) => {
                acc[student.ID] = true;
                return acc;
            }, {});
            setStudentData(result.data);
            setCheckedStatus(newCheckedStatus);
            setSelectedMobiles(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Student Data : ", error);
        }}
    };




    useEffect(() => {
        if (userData && userData.Org_Id) {
           
                loadStdBatchData();

           
                loadStudentData();
           
        }

    }, [userData, filter.Std_Id, filter.Batch_Id, filter?.Date]);

    const handleCheckboxChange = (e, index, id) => {
        const newCheckedStatus = {
            ...checkedStatus,
            [id]: !checkedStatus[id]
        };

        const newSelectedMobiles = newCheckedStatus[id]
            ? [...selectedMobiles, studentData.find(student => student.ID === id)]
            : selectedMobiles.filter(student => student.ID !== id);

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(newSelectedMobiles);
        setCheckAll(Object.values(newCheckedStatus).every(status => status));
    };


    const handleCheckAll = (e) => {
        const newCheckedStatus = studentData.reduce((acc, student) => {
            acc[student.ID] = e.target.checked;
            return acc;
        }, {});

        setCheckedStatus(newCheckedStatus);
        setSelectedMobiles(e.target.checked ? studentData : []);
        setCheckAll(e.target.checked);
    };



    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">
                        Attendance Report
                    </div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div>

                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="row justify-content-center mt-2">


                                <div className={"col-4"}>
                                    <label htmlFor="example-select" className="form-label">
                                        Std
                                    </label>
                                    <Select
                                        id="Standard"
                                        value={filter.Std_Id ? { label: filter.Std_Name, value: filter.Std_Id } : null}
                                        options={StdOptions}
                                        required
                                        placeholder="--All--"
                                        isClearable
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                Std_Id: e ? e.value : "",
                                                Std_Name: e ? e.label : "",
                                                Batch_Id: "",
                                                Batch_Name: "",
                                            });
                                        }}
                                    />
                                </div>
                                <div className={"col-4"}>
                                    <label htmlFor="example-select" className="form-label">
                                        Batch
                                    </label>

                                    <Select
                                        id="Batch"
                                        value={filter.Batch_Id ? { label: filter.Batch_Name, value: filter.Batch_Id } : null}
                                        options={BatchOptions}
                                        required
                                        placeholder="--All--"
                                        isClearable
                                        isDisabled={filter.Std_Id === ""}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                Batch_Id: e ? e.value : "",
                                                Batch_Name: e ? e.label : ""
                                            });
                                        }}
                                    />
                                </div>

                                <div className={"col-4"}>
                                    <label htmlFor="example-control" className="form-label">
                                        Date :
                                    </label>
                                    <input type="date" className="form-control" id="example-control" value={formatInputDate(filter.Date)}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                Date: e.target.value,
                                            });

                                        }} />

                                </div>




                            </div>
                            <div className="row mt-2">
                                <div className="col-12">



                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <div className=" table-responsive">
                                                <table id="dataTableHover" className="table table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" style={{ paddingLeft: "17px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={checkAll}
                                                                    onChange={handleCheckAll}
                                                                />
                                                            </th>
                                                            <th className="text-center">#</th>
                                                            <th className="text-left">Standard</th>
                                                            <th className="text-left">Batch</th>
                                                            <th className="text-left">Roll No</th>
                                                            <th className="text-left">Name</th>
                                                            <th className="text-center">Father Mobile</th>
                                                            <th className="text-center">Mother Mobile</th>
                   
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {studentData.length > 0 ? (
                                                            studentData.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                            <input type="checkbox" id={`studentCheck${index}`} checked={checkedStatus[item.ID] || false} onChange={(e) => handleCheckboxChange(e, index, item.ID)} />
                                                                        </td>
                                                                        <td className="text-center" style={{ width: "" }}>
                                                                            {index+1}
                                                                        </td>
                                                                        <td className="text-left" style={{ width: "" }}>
                                                                            {item.Std_Name}
                                                                        </td>
                                                                        <td className="text-left" style={{ width: "" }}>
                                                                            {item.Batch_Name}
                                                                        </td>
                                                                        <td className="text-left" style={{ width: "" }}>
                                                                            {item.Student_Id||""}
                                                                        </td>
                                                                        <td className="text-left" style={{ width: "" }}>
                                                                            {`${item.First_Name} ${item.Last_Name||""}`}
                                                                        </td>
                                                                        <td className="text-center" style={{ width: "" }}>
                                                                            {item.Father_Mobile}
                                                                        </td>
                                                                        <td className="text-center" style={{ width: "" }}>
                                                                            {item.Mother_Mobile||""}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td className="text-center text-danger" colSpan={8}>
                                                                    No Data Found !!
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={studentData.length === 0?"d-none":"row mt-4"}>
                                                    <div className="col-12 text-center">
                                                        <button
                                                            className="btn btn-primary mb-1"
                                                            type="button"
                                                            onClick={submitHandler}
                                                        >
                                                            Send Whatsapp Msg
                                                        </button>
                                                    </div>
                                                </div>

                                </div>

                                {loader}
                            </div>
                        </div>
                    </div>
                </div>       </div>       </div>

    );
}

export default ManualWhatsapp;
