import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import TopicDropdown from "../DropDowns/TopicDropdown";
import BatchDropdown from "../DropDowns/BatchDropdown";
import SubjectDropdown from "../DropDowns/SubjectDropdown";
import StandardDropdown from "../DropDowns/StandardDropdown";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { formatDate } from "../../Components/dateFormate";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import { formatTime } from "../../Components/dateFormate";
import axios from "axios";
import dayjs from "dayjs";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const options = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];


const StaffPlanner = ({staffData}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { opration, Sr_No } = useParams();
  const [errors, setErrors] = useState({});

  const [isEditing, setIsEditing] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);


  const [Staff, setStaff] = useState({
    Std_Id:"",
    Std_Name:"",
    Subject_Id:"",
    Subject:"",
    Topic:"",
    Topic_Id:"",
    Duration:"",
    Remaining:"",
    Batch_Id:"",
    Batch_Name:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaff((prevStaff) => ({
      ...prevStaff,
      [name]: value,
    }));
  };

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };
  async function handleDelete(Id) {
    showLoader();
    const obj = {
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      User_Name: userData?.UserName,
    };

    try {
      await axios.delete(`${apiUrl}/deleteSchedule/${Id}`, { data: obj });
      Swal.fire("Deleted!", "Staff Lecture Planner has been deleted.", "success");
      updatedStaffData();
    } catch (error) {
      console.error("Error while deleting Staff Lecture Planner:", error);
      Swal.fire("Error", "Failed to delete Staff Lecture Planner.", "error");
    } finally {
      hideLoader();
    }
  }

  useEffect(() => {
    updatedStaffData();
  }, [Sr_No, userData, opration, isEditing]);

  const updatedStaffData = async () => {
    if (Sr_No && opration === "update" && userData && userData.Org_Id && userData.Head_Id) {
      showLoader();
      axios.get(`${apiUrl}/bindSchedule?Staff_Id=${Sr_No}&Org_Id=${userData?.Org_Id}&Head_Id=${userData?.Head_Id}`)
        .then((res) => {
          const result = res.data;
          //console.log(result);
          setScheduleData(result)
          hideLoader();
        });
    }
  };


  const handleUpdate = (event) => {
    event.preventDefault();

    const updatedStaff = {
      ...Staff,

      Staff_Id: staffData.User_Id,
      Staff_Name: `${staffData.First_Name} ${staffData.Last_Name}`,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
    };
    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/publishSchedule`, updatedStaff)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Staff Lecture Planner publish Schedule Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              //navigate("/viewActiveStaff");
              setIsEditing(true);
              setStaff({
                Std_Id:"",
                Std_Name:"",
                Subject_Id:"",
                Subject:"",
                Topic:"",
                Topic_Id:"",
                Duration:"",
                Remaining:"",
                Batch_Id:"",
                Batch_Name:""
              })
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33,
    }),
  };

  return (
    <div
      className="card shadow-none border border-300 my-4"
      data-component-card="data-component-card"
    >
      <div className="card-body p-0">
        <div className="p-4 code-to-copy">
          <div className="row">
            <div className="col-md-6">
              <h5>Staff Lecture Planner</h5>
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <div className="col-md-5">
                <HeadMasterDropDown
                  name="Head"
                  id="Head"
                  value={userData?.Head_Id}
                />
                 
              </div>
            </div>
          </div>
          <hr className="mt-2"/>

          <form
            onSubmit={handleUpdate}
          >
            <div className="row">
              <h5
                className="mb-3 text-uppercase p-2 d-none"
                style={{
                  backgroundColor: "rgb(242, 242, 247)",
                  fontSize: "15px",
                  fontWeight: 600,
                }}
              >
              <i className="fa-solid fa-user" style={{marginRight:'10px'}}></i>
                Personal Details
              </h5>
              <div className="row">
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Standard">
                Standard<span style={{ color: "Red" }}>*</span>
                </label>
                <StandardDropdown
                  label="Standard"
                  name="Standard"
                  id="Standard"
                  required={true}
                  value={Staff.Std_Name?{value:Staff.Std_Id, label:Staff.Std_Name}:null}
                  onChange={(selectedOption) => {
                    setStaff({
                      ...Staff,
                      Std_Id: selectedOption.value,
                      Std_Name: selectedOption.label,
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Batch">
                  Batch<span style={{ color: "Red" }}>*</span>
                </label>
                <BatchDropdown
                  label="Batch"
                  name="Batch"
                  id="Batch"
                  Std_Id={Staff?.Std_Id}
                  isDisabled={!Staff?.Std_Id}
                  required={true}
                  value={Staff.Batch_Id?{value:Staff.Batch_Id, label:Staff.Batch_Name}:null}
                  onChange={(selectedOption) => {
                    setStaff({
                      ...Staff,
                      Batch_Id: selectedOption.value,
                      Batch_Name: selectedOption.label,
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Subject">
                Subject<span style={{ color: "Red" }}>*</span>
                </label>
                <SubjectDropdown
                  label="Subject"
                  name="Subject"
                  id="Subject"
                  required={true}
                  Std_Id={Staff?.Std_Id}
                  isDisabled={!Staff?.Std_Id}
                  value={Staff.Subject_Id?{value:Staff.Subject_Id, label:Staff.Subject_Name}:null}
                  onChange={(selectedOption) => {
            
                    setStaff({
                      ...Staff,
                      Subject_Id: selectedOption.value,
                      Subject_Name: selectedOption.label,
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Topic">
                Topic<span style={{ color: "Red" }}>*</span>
                </label>
                <TopicDropdown
                  label="Topic"
                  name="Topic"
                  id="Topic"
                  isDisabled={!Staff?.Subject_Id}
                  Sub_Id={Staff?.Subject_Id}
                  required={true}
                  value={Staff.Topic_Id?{value:Staff.Topic_Id, label:Staff.Topic_Name}:null}
                  onChange={(selectedOption) => {
                    setStaff({
                      ...Staff,
                      Topic_Id: selectedOption.value,
                      Topic_Name: selectedOption.label,
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Duration">
                Duration<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                type="text"
                  name="Duration"
                  id="Duration"
                  className="form-control"
                  placeholder="HH:MM"
                  required={true}
                  value={Staff.Duration}
                  onChange={(e) => {
                    const input = e.target.value;
                    const colonInput = input.replace(/[^0-9:]/g, "");
                    const duration = colonInput.replace(/^(\d*\:\d*)\:.*/, "$1");
                    setStaff({
                      ...Staff,
                      Duration: duration,
                    });
                  }}
                />
                <span style={{ color: "Red" }}>{errors.Duration}</span>
              </div>
              </div>
              </div>
              <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {'Publish'}
                      </button>
                    </div>
                  </div>
        
          </form>
          <div className="row mb-1 mt-4">
                      <div className="span8 well invoice-body">
                        <div className="col-md-12 col-sm-12 table-responsive">
                          <table className="table productTable display table-bordered mb-0"  style={{fontSize: "12px"}}>
                            <thead>
                              <tr>
                                <th
                                  className="text-center"
                                  style={{ color: "#333", paddingLeft: "10px" }}
                                >
                                  #
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Standard
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Subject
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Topic
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Schedulled Duration
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Remaining Duration
                                </th>
                                <th className="text-center" style={{ color: "#333" }}>
                                Delete
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {scheduleData.map((pro, index) => (
                                  <tr key={index}>
                                    <td className="text-center" style={{ padding: 5 }}>{index+1}</td>
                                    <td className="text-center" style={{ padding: 5 }}>
                                    {pro.Std_Name}
                                    </td>
                                    <td className="text-center" style={{ padding: 5 }}>
                                      {pro.Subject}
                                    </td>
                                    <td className="text-center" style={{ padding: 5 }}>
                                    {pro.Topic}
                                      </td>
                                      <td className="text-center" style={{ padding: 5 }}>
                                      {pro.Duration}
                                      </td>
                                      <td className="text-center" style={{ padding: 5 }}>
                                      {pro.Remaining}
                                      </td>
                                      <td className="text-center" style={{ padding: 5 }}>
                                    <Link  onClick={()=>{deleteUser(pro.Row_Id)}}>
                                    <i
        
                style={{ color: "red" }}
              ><DeleteOutlineIcon fontSize="small"/></i>
                                    </Link>
                                      </td>
                                  </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default StaffPlanner;
