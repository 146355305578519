import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BoardDropDown from "../DropDowns/BoardDropDown";
import SchoolDropdown from "../DropDowns/SchoolDropDown";
import Dropdown from "../DropDowns/DropDown";
import YearDropDown from "../DropDowns/YearDropDown";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";

const AddUpdateStudent = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [BatchOptions, setBatchOptions] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [subjectOption, setSubjectOption] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [CourseOptions, setCourseOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const { opration, Sr_No } = useParams();
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [isValidFatherMobile, setIsValidFatherMobile] = useState(true);
  const [isValidMontherMobile, setIsValidMotherMobile] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const [Student, setStudent] = useState({
    UUID: "",
    Student_Id: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Head_Id: "",
    Head_Name: "",
    Subhead_Id: "",
    Subhead_Name: "",
    Subject_Id: "",
    Std_Id: "",
    Std_Name: "",
    Batch_ID: "",
    Batch_Name: "",
    Address: "",
    Mobile_For_SMS: "",
    Mobile2: "",
    Email: "",
    DOB: "",
    Gender: "",
    Relegion: "",
    Blood_Group: "",
    Caste: "",
    Form_No: "",
    Father_Name: "",
    Father_Mobile: "",
    Mother_Name: "",
    Mother_Mobile: "",
    Latest_Qualification: "",
    Qualification_Result: "",
    School_College: "",
    Telephone: "",
    Parent_Email: "",
    Father_Off_Addr: "",
    Username: "",
    Password: "",
    Date_Added: !opration ? new Date().toLocaleDateString():"",
    Status: "",
    Last_Login_At: "",
    GCM: "",
    OTP: "",
    DTS_Applicable: "",
    Insatallment_Set: "",
    Parent_Id: "",
    Is_Combo: "",
    Academic_Year: "",
    Admission_Status: "",
    Is_Batch_Set: "",
    Board_Id: "",
    Board_Name: "",
    Parent_Email2: "",
    Mother_Off_Address: "",
    Course_ID: "",
    Course_Name: "",
    Org_Id: "",
    InactiveRemarks: "",
    Device_Id: "",
    User_Role: "",
    User_Id: "",
    UserName: "",
    Name: "",
    Photo: "",
    ID_Proof: "",
    Address_Proof: "",
    Mother_Photo: "",
    Father_Photo: "",
    opration: null,
    Enquiry_Id: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (userData && userData.Org_Id) {
        try {
          showLoader();
          if (userData && userData.Org_Id) {
            const Course = await axios.get(
              `${apiUrl}/getCourse?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id ? userData.Head_Id : ""
              }`
            );

            const getCourse = Course.data.map((category) => ({
              label: category.Course_Name,
              value: category.Course_Id,
            }));
            setCourseOptions(getCourse);
          }

          if (userData && userData.Org_Id && userData.Head_Id) {

            const std = await axios.get(
              `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
            );

            const getStd = std.data.map((category) => ({
              label: category.Std_Name,
              value: category.Std_Id,
            }));
            setStdOptions(getStd);

            const getLastEnquiryNo = await axios.get(
              `${apiUrl}/getLastEnquiryNo?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
            );
            setStudent((prevStudent) => ({
              ...prevStudent,
              Enquiry_No: getLastEnquiryNo.data,
            }));

            const getLastUUID = await axios.get(
              `${apiUrl}/getLastUUID?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
            );
            // console.log(getLastUUID.data)
            setStudent((prevStudent) => ({
              ...prevStudent,
              UUID: getLastUUID.data,
            }));
          }

          if (userData && userData.Org_Id && Student && Student.Std_Id) {
            const batch = await axios.get(
              `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Student.Std_Id}`
            );

            const getBatch = batch.data.map((category) => ({
              label: category.Batch_Name,
              value: category.Batch_Id,
            }));
            setBatchOptions(getBatch);

            const sub = await axios.get(
              `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Student.Std_Id}`
            );

            const getSub = sub.data.map((category) => ({
              label: category.Sub_Name,
              value: category.Sub_Id,
            }));
            setSubjectOption(getSub);


            if (Sr_No && opration === 'update') {
              const sub = await axios.get(
                `${apiUrl}/getStudentSubjects?OrgId=${userData.Org_Id}&StdId=${Student.Std_Id}&ID=${Sr_No}`
              )
              const getStudentSub = sub.data.map((category) => ({
                label: category.Sub_Name,
                value: category.Subject_Id,
              }));
              //console.log(getStudentSub)
              setSelectedSubjects(getStudentSub);

            } else {
              console.log(getSub)
              setSelectedSubjects(getSub);

            }
          }
          if (
            userData &&
            userData.Org_Id &&
            Student &&
            Student.Std_Id & userData.Head_Id &&
            Student.Academic_Year
            && opration !== 'update'
          ) {
            const res = await axios.get(
              `${apiUrl}/getStudentId?OrgId=${userData.Org_Id}&Std_Id=${Student.Std_Id}&Year=${Student.Academic_Year}&HeadId=${userData?.Head_Id}`
            );

            console.log(res.data);
            setStudent((prevStudent) => ({
              ...prevStudent,
              Student_Id: res.data,
            }));
          }

          hideLoader();
        } catch (error) {
          console.error("Error fetching headmaster data:", error);
          hideLoader();
        }
      }
    };

    fetchData();
  }, [userData, Student.Std_Id, userData?.Head_Id, Student.Academic_Year, opration, Sr_No]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (Sr_No && opration === 'convert' && userData && userData.Org_Id) {
      showLoader();
      axios
        .get(
          `${apiUrl}/getEnquiryToFetchStudent?Enq_Id=${Sr_No}&OrgId=${userData.Org_Id}`
        )
        .then((res) => {
          const result = res.data;
          console.log(result)
          const enquiry = {
            First_Name: result.First_Name,
            Middle_Name: result.Middle_Name,
            Last_Name: result.Last_Name,
            Head_Id: result.Head_Id,
            Subhead_Id: result.Subhead_Id,
            Address: result.Address,
            Std_Id: parseInt(result.Std_Id),
            Mobile_For_SMS: result.Mobile1,
            DOB: result.DOB,
            Gender: result.Gender,
            Relegion: result.Religion,
            Caste: result.Caste,
            Father_Name: result.Father_Name,
            Father_Mobile: result.Father_Mobile,
            Mother_Name: result.Mother_Name,
            Mother_Mobile: result.Mother_Mobile,
            Latest_Qualification: result.Qualification,
            Qualification_Result: result.Qualification_Result,
            School_College: result.College_Name,
            Telephone: result.Telephone,
            Parent_Email: result.Parent_Email,
            Father_Off_Addr: result.Father_Office_Address,
            Academic_Year: result.Academic_Year,
            Board_Id: result.Board_Id,
            Board_Name: result.Board_Name,
            Course_ID: result.Course_ID,
            Course_Name: result.Course_Name,
            Date_Added: new Date(),
            Blood_Group: "",
            Form_No: "",
            Status: "",
            GCM: "",
            OTP: "",
            DTS_Applicable: "",
            Insatallment_Set: "",
            Parent_Id: "",
            Is_Combo: "",
            Admission_Status: "",
            Is_Batch_Set: "",
            Parent_Email2: "",
            Mother_Off_Address: "",
            InactiveRemarks: "",
            Photo: "",
          };
          setStudent(enquiry);
          console.log(result);

          hideLoader();
        });
    }
  }, [Sr_No, userData, opration]);

  useEffect(() => {
    updatedStudentData();
  }, [Sr_No, userData, opration]);

  const updatedStudentData = async () => {
    if (Sr_No && opration === 'update' && userData && userData.Org_Id) {
      showLoader();
      axios
        .get(`${apiUrl}/getStudentData/${Sr_No}/${userData.Org_Id}`)
        .then((res) => {
          const result = res.data;
          setStudent(result);
          console.log(result);

          hideLoader();
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateStudent = {
      ...Student,
      Enquiry_Id: Sr_No ? Sr_No : "",
      opration: opration ? opration : null,
      Subject: selectedSubjects,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
      User_Name: userData.UserName,
    };

    const errors = {};

    const formdata = new FormData();
    formdata.append("Subject", JSON.stringify(updateStudent.Subject));
    formdata.append("User_Id", updateStudent.User_Id);
    formdata.append("Org_Id", updateStudent.Org_Id);
    formdata.append("Org_Name", updateStudent.Org_Name);
    formdata.append("User_Role", updateStudent.User_Role);
    formdata.append("LoginUsername", updateStudent.LoginUsername);
    formdata.append("Display_Name", updateStudent.Display_Name);
    formdata.append("User_Name", updateStudent.User_Name);
    formdata.append("UUID", Student.UUID);
    formdata.append("Student_Id", Student.Student_Id ? Student.Student_Id : "");
    formdata.append("First_Name", Student.First_Name);
    formdata.append("Middle_Name", Student.Middle_Name);
    formdata.append("Last_Name", Student.Last_Name);
    formdata.append("Head_Id", updateStudent.Head_Id);
    formdata.append("Head_Name", updateStudent.Head_Name ? updateStudent.Head_Name : "");
    formdata.append("Subhead_Id", Student.Subhead_Id||"");
    formdata.append(
      "Subhead_Name",
      Student.Subhead_Name ? Student.Subhead_Name : ""
    );
    formdata.append("Std_Id", Student.Std_Id);
    formdata.append("Std_Name", Student.Std_Name || "");
    formdata.append("Batch_ID", Student.Batch_ID);
    formdata.append("Batch_Name", Student.Batch_Name);
    formdata.append("Address", Student.Address);
    formdata.append("Mobile_For_SMS", Student.Mobile_For_SMS);
    formdata.append("Mobile2", Student.Mobile2 ? Student.Mobile2 : "");

    formdata.append("Email", Student.Email ? Student.Email : "");
    formdata.append("DOB", Student.DOB ? Student.DOB : "");
    formdata.append("Gender", Student.Gender);
    formdata.append("Relegion", Student.Relegion);
    formdata.append("Blood_Group", Student.Blood_Group);
    formdata.append("Caste", Student.Caste);
    formdata.append("Form_No", Student.Form_No);

    formdata.append("Father_Name", Student.Father_Name);
    formdata.append("Father_Mobile", Student.Father_Mobile);
    formdata.append("Mother_Name", Student.Mother_Name);
    formdata.append("Mother_Mobile", Student.Mother_Mobile);
    formdata.append("Latest_Qualification", Student.Latest_Qualification);
    formdata.append("Qualification_Result", Student.Qualification_Result);
    formdata.append("School_College", Student.School_College);
    formdata.append("Telephone", Student.Telephone);
    formdata.append(
      "Parent_Email",
      Student.Parent_Email ? Student.Parent_Email : ""
    );
    formdata.append("Father_Off_Addr", Student.Father_Off_Addr);

    formdata.append("Username", Student.Username || "");
    formdata.append("Password", Student.Password || "");
    formdata.append("Date_Added", Student.Date_Added);
    formdata.append("Status", Student.Status);
    formdata.append("Last_Login_At", Student.Last_Login_At || "");
    formdata.append("GCM", Student.GCM);
    formdata.append("OTP", Student.OTP);

    formdata.append("DTS_Applicable", Student.DTS_Applicable);
    formdata.append("Insatallment_Set", Student.Insatallment_Set);
    formdata.append("Parent_Id", Student.Parent_Id);
    formdata.append("Is_Combo", Student.Is_Combo);
    formdata.append("Academic_Year", Student.Academic_Year);
    formdata.append("Admission_Status", Student.Admission_Status);
    formdata.append("Is_Batch_Set", Student.Is_Batch_Set);
    formdata.append("Board_Id", Student.Board_Id);
    formdata.append("Board_Name", Student.Board_Name);
    formdata.append("Parent_Email2", Student.Parent_Email2);

    formdata.append("Mother_Off_Address", Student.Mother_Off_Address);
    formdata.append("Course_ID", Student.Course_ID);
    formdata.append("Course_Name", Student.Course_Name);
    formdata.append("InactiveRemarks", Student.InactiveRemarks);
    formdata.append("Photo", Student.Photo || "");
    formdata.append("ID_Proof", Student.ID_Proof || "");
    formdata.append("Address_Proof", Student.Address_Proof || "");
    formdata.append("Mother_Photo", Student.Mother_Photo || "");
    formdata.append("Father_Photo", Student.Father_Photo || "");
    formdata.append("opration", updateStudent.opration);
    formdata.append("Enquiry_Id", updateStudent.Enquiry_Id);

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addStudent`, formdata)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Student New Registration Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/viewActiveStudent");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const updateStudent = {
      ...Student,
      Subject: selectedSubjects,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
      User_Name: userData.UserName,
    };

    console.log(updateStudent)

    const errors = {};

    const formdata = new FormData();
    formdata.append("Subject", JSON.stringify(updateStudent.Subject));
    formdata.append("User_Id", updateStudent.User_Id);
    formdata.append("Org_Id", updateStudent.Org_Id);
    formdata.append("Org_Name", updateStudent.Org_Name);
    formdata.append("User_Role", updateStudent.User_Role);
    formdata.append("LoginUsername", updateStudent.LoginUsername);
    formdata.append("Display_Name", updateStudent.Display_Name);
    formdata.append("User_Name", updateStudent.User_Name);
    formdata.append("UUID", Student.UUID);
    formdata.append("Student_Id", Student.Student_Id ? Student.Student_Id : "");
    formdata.append("First_Name", Student.First_Name);
    formdata.append("Middle_Name", Student.Middle_Name || "");
    formdata.append("Last_Name", Student.Last_Name || "");
    formdata.append("Head_Id", updateStudent.Head_Id);
    formdata.append("Head_Name", updateStudent.Head_Name ? updateStudent.Head_Name : "");
    formdata.append("Subhead_Id", Student.Subhead_Id);
    formdata.append(
      "Subhead_Name",
      Student.Subhead_Name ? Student.Subhead_Name : ""
    );
    formdata.append("Std_Id", Student.Std_Id);
    formdata.append("Std_Name", Student.Std_Name || "");
    formdata.append("Batch_ID", Student.Batch_ID);
    formdata.append("Batch_Name", Student.Batch_Name);
    formdata.append("Address", Student.Address);
    formdata.append("Mobile_For_SMS", Student.Mobile_For_SMS);
    formdata.append("Mobile2", Student.Mobile2 ? Student.Mobile2 : "");

    formdata.append("Email", Student.Email ? Student.Email : "");
    formdata.append("DOB", Student.DOB ? Student.DOB : "");
    formdata.append("Gender", Student.Gender);
    formdata.append("Relegion", Student.Relegion);
    formdata.append("Blood_Group", Student.Blood_Group);
    formdata.append("Caste", Student.Caste);
    formdata.append("Form_No", Student.Form_No);

    formdata.append("Father_Name", Student.Father_Name);
    formdata.append("Father_Mobile", Student.Father_Mobile);
    formdata.append("Mother_Name", Student.Mother_Name);
    formdata.append("Mother_Mobile", Student.Mother_Mobile);
    formdata.append("Latest_Qualification", Student.Latest_Qualification);
    formdata.append("Qualification_Result", Student.Qualification_Result);
    formdata.append("School_College", Student.School_College);
    formdata.append("Telephone", Student.Telephone);
    formdata.append(
      "Parent_Email",
      Student.Parent_Email ? Student.Parent_Email : ""
    );
    formdata.append("Father_Off_Addr", Student.Father_Off_Addr);

    formdata.append("Username", Student.Username || "");
    formdata.append("Password", Student.Password || "");
    formdata.append("Date_Added", Student.Date_Added);
    formdata.append("Status", Student.Status||"");
    formdata.append("Last_Login_At", Student.Last_Login_At || "");
    formdata.append("GCM", Student.GCM||"");
    formdata.append("OTP", Student.OTP||"");

    formdata.append("DTS_Applicable", Student.DTS_Applicable||"");
    formdata.append("Insatallment_Set", Student.Insatallment_Set||"");
    formdata.append("Parent_Id", Student.Parent_Id);
    formdata.append("Is_Combo", Student.Is_Combo||"");
    formdata.append("Academic_Year", Student.Academic_Year);
    formdata.append("Admission_Status", 1);
    formdata.append("Is_Batch_Set", Student.Is_Batch_Set||"");
    formdata.append("Board_Id", Student.Board_Name);
    formdata.append("Board_Name", Student.Board_Name);
    formdata.append("Parent_Email2", Student.Parent_Email2||"");

    formdata.append("Mother_Off_Address", Student.Mother_Off_Address||"");
    formdata.append("Course_ID", Student.Course_ID);
    formdata.append("Course_Name", Student.Course_Name);
    formdata.append("InactiveRemarks", Student.InactiveRemarks||"");
    formdata.append("Photo", Student.Photo || "");
    formdata.append("ID_Proof", Student.ID_Proof || "");
    formdata.append("Address_Proof", Student.Address_Proof || "");
    formdata.append("Mother_Photo", Student.Mother_Photo || "");
    formdata.append("Father_Photo", Student.Father_Photo || "");

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateStudent/${Sr_No}`, formdata)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Student Details Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              //navigate("/viewActiveStudent");
              handleCancelEdit();
              updatedStudentData();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33,
    }),
  };

  return (
    <div
      className="card shadow-none border border-300 my-4"
      data-component-card="data-component-card"
    >
      <div className="card-body p-0">
        <div className="p-4 code-to-copy">

          <div className="row">
            <div className="col-md-6">
              <h5 >Student Details</h5>
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <div className="col-md-5">
                <HeadMasterDropDown
                  label="Branch"
                  name="Branch"
                  id="Branch"


                />

              </div></div>
          </div>
          <hr />



          <form onSubmit={Sr_No && opration === 'update' ? handleUpdate : handleSubmit}>
            <div className="row">

              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Date_Added">
                  Admission Date<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  required
                  name="Date_Added"
                  id="Date_Added"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={
                    Student.Date_Added
                      ? dayjs(Student.Date_Added).format("YYYY-MM-DD")
                      : ""
                  }
                  placeholder="Enter Admission Date"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Academic_Year">
                  Academic Year <span style={{ color: "Red" }}>*</span>
                </label>
                <YearDropDown
                  label="Academic_Year"
                  name="Academic_Year"
                  id="Academic_Year"
                  value={Student.Academic_Year}
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  required
                  isClearable
                  onChange={(e) => {
                    setStudent({
                      ...Student,
                      //Assigned_To: selectedOption.value,
                      Academic_Year: e ? e.label : "",
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Board">
                  Board<span style={{ color: "Red" }}>*</span>
                </label>
                <BoardDropDown
                  label="Board"
                  name="Board"
                  id="Board"
                  isClearable
                  required
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.Board_Name}
                  onChange={(e) => {
                    setStudent({
                      ...Student,
                      Board_Id: e ? e.label : "",
                      Board_Name: e ? e.label : "",
                    });
                  }}

                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Course">
                  Course<span style={{ color: "Red" }}>*</span>
                </label>
                <Select
                  options={CourseOptions}
                  name="Course"
                  required
                  isClearable
                  id="Course"
                  isDisabled={Sr_No && opration === 'update' && !isEditing}
                  value={CourseOptions.find(option => option.value === parseInt(Student.Course_ID))} // Ensures the correct option is displayed
                  onChange={(e) => {
                    setStudent({
                      ...Student,
                      Course_ID: e ? e.value : "",
                      Course_Name: e ? e.label : "",
                    });
                  }}
                />

                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Standard">
                  Standard<span style={{ color: "Red" }}>*</span>
                </label>
                <Select
                  options={StdOptions}
                  name="Standard"
                  required={true}
                  id="Standard"
                  isDisabled={Sr_No && opration === 'update' && !isEditing}
                  value={StdOptions.find(option => option.value === parseInt(Student.Std_Id))} // Ensure correct option is displayed
                  onChange={(selectedOption) => {
                    setStudent({
                      ...Student,
                      Std_Id: selectedOption ? selectedOption.value : "",
                      Std_Name: selectedOption ? selectedOption.label : "",
                    });
                  }}
                  isClearable
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Batch">
                  Batch<span style={{ color: "Red" }}>*</span>
                </label>
                <Select
                  options={BatchOptions}
                  name="Batch"
                  required={true}
                  id="Batch"
                  value={BatchOptions.find(option => option.value === parseInt(Student.Batch_ID))} // Ensure correct option is displayed
                  isDisabled={(Sr_No && opration === 'update' && !isEditing) || Student.Std_Id === ""}
                  onChange={(selectedOption) => {
                    setStudent({
                      ...Student,
                      Batch_ID: selectedOption ? selectedOption.value : "",
                      Batch_Name: selectedOption ? selectedOption.label : "",
                    });
                  }}
                  isClearable
                />
                <span style={{ color: "Red" }}></span>
              </div>

              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Student_Id">
                  Student ID
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="Student_Id"
                  id="Student_Id"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.Student_Id}
                  placeholder="Enter Student ID"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-9">
                <label className="form-label" htmlFor="Subject">
                  Subject<span style={{ color: "Red" }}>*</span>
                </label>
                <Select
                  name="Subject"
                  isMulti
                  options={subjectOption}
                  id="Subject"
                  required
                  isDisabled={Sr_No && opration === 'update' && !isEditing}
                  //styles={customStyles}
                  value={selectedSubjects}
                  onChange={(e) => {
                    //console.log(e)
                    setSelectedSubjects(e);
                  }}
                />

                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="First_Name">
                  First Name<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="First_Name"
                  id="First_Name"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.First_Name}
                  placeholder="Enter First Name"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Middle_Name ">
                  Middle Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Middle_Name"
                  value={Student.Middle_Name}
                  id="Middle_Name"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  placeholder="Enter Middle Name"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Last_Name">
                  Last Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Last_Name"
                  value={Student.Last_Name}
                  id="Last_Name"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  placeholder="Enter Last Name"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Mobile_For_SMS">
                  Student Mobile
                </label>
                <input
                  className={`form-control form-control-sm ${isValidMobile ? "" : "is-invalid"
                    }`}
                  type="text"
                  name="Mobile_For_SMS"
                  id="Mobile_For_SMS"
                  value={Student.Mobile_For_SMS}
                  maxLength={10}
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  placeholder="Enter Student Mobile"
                  onChange={(e) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                    setIsValidMobile(isValid);
                    if (isValid || numericInput === "") {
                      setStudent({
                        ...Student,
                        Mobile_For_SMS: numericInput,
                      });
                    }
                  }}
                />
                {!isValidMobile && (
                  <div className="invalid-feedback">Invalid mobile number.</div>
                )}
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Email">
                  Student Email
                </label>
                <input
                  className="form-control form-control-sm"
                  type="email"
                  name="Email"
                  id="Email"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.Email}
                  placeholder="Enter Student Email"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>

              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="DOB">
                  DOB
                </label>

                <input
                  className="form-control form-control-sm"
                  name="DOB"
                  id="DOB"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={
                    Student.DOB ? dayjs(Student.DOB)?.format("YYYY-MM-DD") : ""
                  }
                  type="date"
                  placeholder="Select Date"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <GenderDropDown
                  label="Gender"
                  name="Gender"
                  value={Student?.Gender}
                  id="Gender"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedOption = e.target[selectedIndex];
                    setStudent({
                      ...Student,
                      Gender: selectedOption.value,
                      //Sub_Name: selectedOption.label,
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-10">
                <label className="form-label" htmlFor="Address">
                  Address
                </label>
                <textarea
                  className="form-control form-control-sm"
                  type="text"
                  name="Address"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  id="Address"
                  value={Student.Address}
                  placeholder="Enter Address..."
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
            </div>

            <div className="row">

            </div>
            <h5 className="mt-2">Documents</h5>
            <hr />
            <div className="row">
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Photo">
                  Photo
                </label>
                <input
                  style={{
                    width: Sr_No && opration === 'update' && Student?.Photo ? "92%" : "100%",
                    display: "inline-block",
                  }}
                  type="file"
                  className="form-control form-control-sm"
                  name="Photo"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  accept=".bmp, .gif, .png, .jpg, .jpeg"
                  id="Photo"
                  onChange={(e) =>
                    setStudent({
                      ...Student,
                      Photo: e.target.files[0],
                    })
                  }
                />
                {Sr_No && opration === 'update' && Student?.Photo !== "" && (
                  <Link
                    to={`${apiUrl}/download/${Student.Photo}`}
                    download
                    className="cursor-pointer text-primary"
                    style={{ textDecoration: "none", marginLeft: "7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.35"
                        d="m5 24l19 18l19-18H31V6H17v18z"
                      />
                    </svg>
                  </Link>
                )}
              </div>

              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="ID_Proof">
                  ID Proof
                </label>
                <input
                  style={{
                    width: Sr_No && opration === 'update' && Student?.ID_Proof ? "92%" : "100%",
                    display: "inline-block",
                  }}
                  type="file"
                  className="form-control form-control-sm"
                  name="ID_Proof"
                  accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf"
                  id="ID_Proof"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  onChange={(e) =>
                    setStudent({
                      ...Student,
                      ID_Proof: e.target.files[0],
                    })
                  }
                />

                <span style={{ color: "Red" }}></span>
                {Sr_No && opration === 'update' && Student?.ID_Proof !== "" && (
                  <Link
                    to={`${apiUrl}/download/${Student.ID_Proof}`}
                    download
                    className="cursor-pointer text-primary"
                    style={{ textDecoration: "none", marginLeft: "7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.35"
                        d="m5 24l19 18l19-18H31V6H17v18z"
                      />
                    </svg>
                  </Link>
                )}
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Address_Proof">
                  Address Proof
                </label>
                <input
                  style={{
                    width: Sr_No && opration === 'update' && Student?.Address_Proof ? "92%" : "100%",
                    display: "inline-block",
                  }}
                  type="file"
                  className="form-control form-control-sm"
                  name="Address_Proof"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf"
                  id="Address_Proof"
                  onChange={(e) =>
                    setStudent({
                      ...Student,
                      Address_Proof: e.target.files[0],
                    })
                  }
                />

                <span style={{ color: "Red" }}></span>
                {Sr_No && opration === 'update' && Student?.Address_Proof !== "" && (
                  <Link
                    to={`${apiUrl}/download/${Student.Address_Proof}`}
                    download
                    className="cursor-pointer text-primary"
                    style={{ textDecoration: "none", marginLeft: "7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.35"
                        d="m5 24l19 18l19-18H31V6H17v18z"
                      />
                    </svg>
                  </Link>
                )}
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Mother_Photo">
                  Mother Photo
                </label>
                <input
                  style={{
                    width: Sr_No && opration === 'update' && Student?.Mother_Photo ? "92%" : "100%",
                    display: "inline-block",
                  }}
                  type="file"
                  className="form-control form-control-sm"
                  name="Mother_Photo"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf"
                  id="Mother_Photo"
                  onChange={(e) =>
                    setStudent({
                      ...Student,
                      Mother_Photo: e.target.files[0],
                    })
                  }
                />
                {Sr_No && opration === 'update' && Student?.Mother_Photo !== "" && (
                  <Link
                    to={`${apiUrl}/download/${Student.Mother_Photo}`}
                    download
                    className="cursor-pointer text-primary"
                    style={{ textDecoration: "none", marginLeft: "7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.35"
                        d="m5 24l19 18l19-18H31V6H17v18z"
                      />
                    </svg>
                  </Link>
                )}
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-3">
                <label className="form-label" htmlFor="Father_Photo">
                  Father Photo
                </label>
                <input
                  style={{
                    width: Sr_No && opration === 'update' && Student?.Father_Photo ? "92%" : "100%",
                    display: "inline-block",
                  }}
                  type="file"
                  className="form-control form-control-sm"
                  name="Father_Photo"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf"
                  id="Father_Photo"
                  onChange={(e) =>
                    setStudent({
                      ...Student,
                      Father_Photo: e.target.files[0],
                    })
                  }
                />
                {Sr_No && opration === 'update' && Student?.Father_Photo !== "" && (
                  <Link
                    to={`${apiUrl}/download/${Student.Father_Photo}`}
                    download
                    className="cursor-pointer text-primary"
                    style={{ textDecoration: "none", marginLeft: "7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.35"
                        d="m5 24l19 18l19-18H31V6H17v18z"
                      />
                    </svg>
                  </Link>
                )}

                <span style={{ color: "Red" }}></span>
              </div>
            </div>
            <h5 className="mt-2">Academic Details</h5>
            <hr />
            <div className="row">
              <div className="mb-3 col-md-4">
                <SchoolDropdown
                  label="School/College Name"
                  name="School_College"
                  id="School_College"
                  isClearable
                  value={Student?.School_College}
                  isDisabled={Sr_No && opration === 'update' && !isEditing}
                  onChange={(e) => {
                    setStudent({
                      ...Student,
                      // Head_Id: selectedOption.value,
                      School_College: e ? e.label : "",
                    });
                  }}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Latest_Qualification">
                  Previous Standard
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={Student.Latest_Qualification}
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  name="Latest_Qualification"
                  id="Latest_Qualification"
                  placeholder="Enter Previous Standard"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Qualification_Result">
                  Previous Percentage
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={Student.Qualification_Result}
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  name="Qualification_Result"
                  id="Qualification_Result"
                  placeholder="Enter Previous Percentage"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
            </div>
            <h5 className="mt-2">Parent Details</h5>
            <hr />
            <div className="row">
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Father_Name">
                  Father Name<span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Father_Name"
                  value={Student.Father_Name}
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  id="Father_Name"
                  placeholder="Enter Father Name"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Father_Mobile">
                  Father Mobile(Parent Username)
                  <span style={{ color: "Red" }}>*</span>
                </label>
                <input
                  className={`form-control form-control-sm ${isValidFatherMobile ? "" : "is-invalid"
                    }`}
                  type="text"
                  name="Father_Mobile"
                  required
                  id="Father_Mobile"
                  value={Student.Father_Mobile}
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  maxLength={10}
                  placeholder="Enter Father Mobile"
                  onChange={(e) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                    setIsValidFatherMobile(isValid);
                    if (isValid || numericInput === "") {
                      setStudent({
                        ...Student,
                        Father_Mobile: numericInput,
                      });
                    }
                  }}
                />
                {!isValidFatherMobile && (
                  <div className="invalid-feedback">Invalid mobile number.</div>
                )}
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Parent_Email">
                  Father Email
                </label>
                <input
                  className="form-control form-control-sm"
                  type="email"
                  name="Parent_Email"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.Parent_Email}
                  id="Parent_Email"
                  placeholder="Enter Father Email"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Mother_Name">
                  Mother Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Mother_Name"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.Mother_Name}
                  id="Mother_Name"
                  placeholder="Enter Mother Name"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>

              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Mother_Mobile">
                  Mother Mobile
                </label>
                <input
                  className={`form-control form-control-sm ${isValidMontherMobile ? "" : "is-invalid"
                    }`}
                  type="text"
                  name="Mother_Mobile"
                  id="Mother_Mobile"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  maxLength={10}
                  value={Student.Mother_Mobile}
                  placeholder="Enter Mother Mobile"
                  onChange={(e) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                    setIsValidMotherMobile(isValid);
                    if (isValid || numericInput === "") {
                      setStudent({
                        ...Student,
                        Mother_Mobile: numericInput,
                      });
                    }
                  }}
                />
                {!isValidMontherMobile && (
                  <div className="invalid-feedback">Invalid mobile number.</div>
                )}
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Parent_Email2">
                  Mother Email
                </label>
                <input
                  className="form-control form-control-sm"
                  type="email"
                  name="Parent_Email2"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  id="Parent_Email2"
                  value={Student.Parent_Email2}
                  placeholder="Enter Mother Email"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="Telephone">
                  Telephone
                </label>
                <input
                  className="form-control form-control-sm"
                  type="tel"
                  name="Telephone"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  id="Telephone"
                  value={Student.Telephone}
                  placeholder="Enter Telephone No"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-5">
                <label className="form-label" htmlFor="Father_Off_Addr">
                  Father Office Address
                </label>
                <textarea
                  className="form-control form-control-sm"
                  type="text"
                  name="Father_Off_Addr"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  id="Father_Off_Addr"
                  value={Student.Father_Off_Addr}
                  placeholder="Enter Address..."
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-5">
                <label className="form-label" htmlFor="Mother_Off_Address">
                  Mother Office Address
                </label>
                <textarea
                  className="form-control form-control-sm"
                  type="text"
                  name="Mother_Off_Address"
                  id="Mother_Off_Address"
                  disabled={Sr_No && opration === 'update' && !isEditing}
                  value={Student.Mother_Off_Address}
                  placeholder="Enter Address..."
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center mt-3">
                {Sr_No && opration === 'update' && !isEditing && (
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                )}{" "}
                &nbsp;

                {isEditing && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={

                      handleCancelEdit
                    }
                  >
                    Close
                  </button>
                )}  &nbsp;
                {
                  Sr_No && opration === 'update' ? (
                    <>
                      {isEditing && (
                        <button type="submit" className="btn btn-sm btn-primary">
                          Update
                        </button>
                      )}
                    </>


                  ) : (
                    <button type="submit" className="btn btn-sm btn-primary">
                      Submit
                    </button>
                  )
                }



              </div>
            </div>
          </form>

        </div>
        {loader}
      </div>
    </div>
  );
};

export default AddUpdateStudent;

