import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from '@mui/icons-material/TableView';

const MarkStudentAttendance = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const { ID } = useParams();
    const [markedStudent, setMarkedStudent] = useState([]);
    const [editingRow, setEditingRow] = useState(null);

    const [facultyStudent, setFacultyStudent] = useState([]);
    const [lectureData, setLectureData] = useState({});
    const [checkedStatus, setCheckedStatus] = useState({});
    const [checkAll, setCheckAll] = useState(true);

    console.log(facultyStudent);

    useEffect(() => {
        fetchData();
    }, [userData, lectureData?.Subject_Id, lectureData?.Batch_Id, ID]);

    const fetchData = async () => {
        try {
            showLoader();
            if (userData?.Org_Id && ID) {
                const IsAttMarked = await axios.get(
                    `${apiUrl}/getLectureAttendance?OrgId=${userData?.Org_Id}&Lecture_Id=${ID}`);
                setMarkedStudent(IsAttMarked.data);
                console.log(IsAttMarked.data);
            }
            if (ID && userData?.Org_Id) {
                const ByID = await axios.get(
                    `${apiUrl}/getLecturesScheduleById/${userData.Org_Id}/${ID}`
                );
                console.log(ByID.data);
                setLectureData(ByID.data);
            }

            if (userData?.Org_Id && userData?.Head_Id && lectureData?.Subject_Id && lectureData?.Batch_Id) {
                // const res = await axios.get(
                //     `${apiUrl}/getStudentLectureAttendance?OrgId=${userData?.Org_Id}&Subject_Id=${lectureData?.Subject_Id}&Head_Id=${userData?.Head_Id}&Batch_ID=${lectureData?.Batch_Id}`
                // );
                const res = await axios.get(
                    `${apiUrl}/getStudentDetails?OrgId=${userData?.Org_Id}&Subject_Id=${lectureData?.Subject_Id}&HeadId=${userData?.Head_Id}&Batch_Id=${lectureData?.Batch_Id}`
                );
                console.log(res.data)
                const students = res.data.map(student => ({
                    ...student,
                    Status: "Present",
                    Subject_Name: lectureData?.Subject_Name,
                    Type: lectureData?.Type,
                    Mobile_For_SMS: student.Mobile_For_SMS,
                    Father_Mobile: student.Father_Mobile,
                    Mother_Mobile: student.Mother_Mobile,

                }));
                const newCheckedStatus = students.reduce((acc, student) => {
                    acc[student.ID] = true;
                    return acc;
                }, {});
                setFacultyStudent(students);
                setCheckedStatus(newCheckedStatus);
            }
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const handleCheckAll = (e) => {
        const newCheckedStatus = {};
        const newFacultyStudent = facultyStudent.map((student) => {
            newCheckedStatus[student.ID] = e.target.checked;
            return { ...student, Status: e.target.checked ? "Present" : "Absent" };
        });
        setCheckedStatus(newCheckedStatus);
        setFacultyStudent(newFacultyStudent);
        setCheckAll(e.target.checked);
    };

    const handleCheckboxChange = (e, index) => {
        const newFacultyStudent = [...facultyStudent];
        newFacultyStudent[index].Status = e.target.checked ? "Present" : "Absent";
        setFacultyStudent(newFacultyStudent);
        setCheckedStatus({ ...checkedStatus, [newFacultyStudent[index].ID]: e.target.checked });
        setCheckAll(false);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const updateBill = {
            Student: facultyStudent,
            Lecture_Id: lectureData?.ID,
            Lecture_Date: lectureData?.Lecture_Date,
            Teacher_Id: lectureData?.Teacher_Id,
            Teacher_Name: lectureData?.Teacher_Name,
            From_Time: lectureData?.From_Time,
            To_Time: lectureData?.To_Time,
            ClassRoom_Id: lectureData?.ClassRoom_Id,
            ClassRoom_Name: lectureData?.ClassRoom_Name,
            Topic_Id: lectureData?.Topic_Id,
            Topic_Name: lectureData?.Topic_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName
        };

        showLoader();
        axios
            .post(`${apiUrl}/insertLectureAttendence`, updateBill)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Student Attendance Updated Successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        //navigate("/viewLectures");
                        fetchData();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };


    const handleUpdate = (student) => {
        const updateBill = {
            ...student,
            Lecture_Id: ID,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName
        };

        showLoader();
        axios
            .put(`${apiUrl}/updateLectureAttendance`, updateBill)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Attendance Updated Successfully!!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        fetchData();
                        stopEditing();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };

    const deleteReq = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete();
            }
        });
    };
    async function handleDelete() {
        showLoader();

        const obj = {
            Std_Name: lectureData?.Std_Name,
            Batch_Name: lectureData?.Batch_Name,
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            User_Name: userData?.UserName,
        };

        try {
            await axios.delete(`${apiUrl}/deleteAllLectureAttendanceBatchWise/${ID}`, { data: obj });
            Swal.fire("Deleted!", "Lecture Attendance has been deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting Lecture Attendance :", error);
            Swal.fire("Error", "Failed to delete Lecture Attendance.", "error");
        } finally {
            hideLoader();
        }
    }

    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text(`Lecture Attendance`, 20, 10);

        const columns = [
            "Sr No",
            "Name",
            "Attendance",
            "Remarks"
        ];

        const rows = markedStudent.map((row, index) => [
            index + 1,
            row.Name,
            row?.Status,
            row.Remark||row.Remarks,
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save(`Lecture Attendance.pdf`);
    };

    const DownloadExcel = () => {
        const columns = [
            "Sr No",
            "Name",
            "Attendance",
            "Remarks"
        ];
        const rows = [columns];
        markedStudent.forEach((row, index) => {
            rows.push([
                index + 1,
                row.Name,
                row?.Status,
                row.Remark||row.Remarks,

            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, `Lecture Attendance`);

        // Buffer
        //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, `Lecture Attendance.xlsx`);
    };

    const handleRemarksChange = (e, index) => {
        const newFacultyStudent = [...facultyStudent];
        newFacultyStudent[index].Remarks = e.target.value ? e.target.value : '';
        setFacultyStudent(newFacultyStudent);
    };
    const handleUpdateRemarksChange = (e, index) => {
        const newFacultyStudent = [...markedStudent];
        newFacultyStudent[index].Remarks = e.target.value ? e.target.value : '';
        setFacultyStudent(newFacultyStudent);
    };


    const handleDropdownChange = (e, index) => {
        const newMarkedStudent = [...markedStudent];
        newMarkedStudent[index].Status = e.target.value;
        setMarkedStudent(newMarkedStudent);
    };

    const startEditing = (index) => {
        setEditingRow(index);
    };

    const stopEditing = () => {
        setEditingRow(null);
    };


    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-4">
                        Student Attendance
                    </div>

                    <div className="col-md-8 d-flex justify-content-end align-items-center">
                        <div className="col-md-4">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div>
                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    className={markedStudent.length > 0 ? "mx-2 my-2 mb-2" : "d-none"}
                                    sx={{ zIndex: 0, position: "relative" }}
                                >

                                    <div>
                                        <Tooltip title="Export to PDF">
                                            <i
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "5px",
                                                }}
                                                onClick={() => {
                                                    DownloadPDF();
                                                }}
                                            >
                                                <PictureAsPdfIcon />
                                            </i>
                                        </Tooltip>

                                        <Tooltip title="Export to Excel">
                                            <i
                                                style={{
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    DownloadExcel();
                                                }}
                                            >
                                                {" "}
                                                <TableViewIcon />
                                            </i>
                                        </Tooltip>
                                    </div>
                                </Stack>
                                <div className="row mt-2">
                                    {markedStudent.length === 0 ? (
                                        <>           <div className="col-12">
                                            <div className=" table-responsive">
                                                <table id="dataTableHover" className="table align-items-center table-flush  table-bordered table-hover" style={{ border: "1px solid #e3e6f0" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Sr No</th>
                                                            <th className="text-center">Name</th>
                                                            <th className="text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={checkAll}
                                                                    onChange={handleCheckAll}
                                                                />
                                                                <br />Attendance
                                                            </th>
                                                            <th className="text-center">Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {facultyStudent?.length > 0 ? (
                                                            facultyStudent?.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="text-center">{index + 1}</td>
                                                                        <td className="text-center">{item.Name}</td>
                                                                        <td className="text-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={checkedStatus[item.ID]}
                                                                                onChange={(e) => handleCheckboxChange(e, index)}
                                                                            />
                                                                        </td>
                                                                        <td className="text-center p-2">
                                                                            <input type="text" className="form-control form-control-sm" value={item.Remarks||item.Remark || ''}
                                                                                onChange={(e) => handleRemarksChange(e, index)} />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td className="text-center text-danger" colSpan={4}>
                                                                    No Data Found !!
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                            <div className="col-md-12 text-center mt-3">
                                                <button onClick={handleSubmit} className="btn btn-sm btn-primary">
                                                    Mark Attendance
                                                </button>
                                            </div></>

                                    ) : (<>
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table id="dataTableHover"
                                                    className="table align-items-center table-flush table-hover paidInterest"
                                                    style={{ border: "1px solid #e3e6f0" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Sr No</th>
                                                            <th className="text-center">Name</th>
                                                            <th className="text-center">Attendance</th>
                                                            <th className="text-center">Remarks</th>
                                                            <th className="text-center"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {markedStudent?.length > 0 ? (
                                                            markedStudent?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className="text-center">{index + 1}</td>
                                                                    <td className="text-center">{item.Name}</td>
                                                                    <td className="text-center p-2">
                                                                        <select
                                                                            className="form-select form-select-sm"
                                                                            value={item.Status}
                                                                            disabled={editingRow !== index}
                                                                            onChange={(e) => handleDropdownChange(e, index)}
                                                                        >
                                                                            <option value="Present">Present</option>
                                                                            <option value="Absent">Absent</option>
                                                                        </select>
                                                                    </td>
                                                                    <td className="text-center p-2">
                                                                        <input type="text" className="form-control form-control-sm"
                                                                            placeholder="Remarks..."
                                                                            disabled={editingRow !== index}
                                                                            value={item.Remarks||item.Remark || ''}
                                                                            onChange={(e) => handleUpdateRemarksChange(e, index)} />
                                                                    </td>
                                                                    <td className="text-center p-2">
                                                                        {editingRow === index ? (
                                                                            <>
                                                                                <Link
                                                                                    onClick={() => handleUpdate(item)}
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        width: "50px",
                                                                                    }}
                                                                                >
                                                                                    Save
                                                                                </Link>
                                                                                &nbsp;
                                                                                <Link
                                                                                    onClick={stopEditing}
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        width: "50px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </Link>
                                                                            </>
                                                                        ) : (
                                                                            <Link
                                                                                onClick={() => startEditing(index)}
                                                                                style={{
                                                                                    display: "inline-block",
                                                                                    width: "50px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Link>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td className="text-center text-danger" colSpan={6}>
                                                                    No Data Found !!
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-md-12 text-center mt-3">
                                            <button onClick={deleteReq} className="btn btn-sm btn-primary">
                                                Delete All
                                            </button>
                                        </div><>

                                        </></>)}
                                </div>
                            </div>
                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkStudentAttendance;
