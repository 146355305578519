import React, { useState, useEffect } from "react";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";
import Select from "react-select";


const YearDropDown = ({ isClearable, required, disabled, value, name, id, onChange }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [yearOptions, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                     showLoader();
                try {
               
                    const result = await axios.get(
                        `${apiUrl}/getYearDropDown?OrgId=${userData.Org_Id}`
                    );
                    console.log(result.data)
                    const op = result.data.map((category) => ({
                        label: category.Financial_Year,
                        value: category.Financial_Year,
                        //Status: category.Status,
                    }));
                    setData(op);
                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData]);

    return (
        <>

<Select
    name={name}
    id={id}
    value={yearOptions.find(option => option.value === value) || null}
    onChange={onChange}
    options={yearOptions}
    isDisabled={disabled}
    isClearable={isClearable}
    required={required}
/>

            {loader}
        </>
    );
};


export default YearDropDown;


