import React, { useState } from 'react';
import MesageLog from './MessageLog'
import SendMessage from './SendMessage'

const Message = ({Sr_No}) => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleFormSubmit = () => {
  
        setFormSubmitted(true);
    };
    return (
        <div className='row'>
            <div className='col-md-6'>
            <MesageLog Sr_No={Sr_No} formSubmitted ={formSubmitted}/>
            </div>
            <div className='col-md-6'>
                <SendMessage Sr_No={Sr_No} onFormSubmit={handleFormSubmit} />
            </div></div>
    )
}

export default Message