import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
//import MarkInactiveStaff from "./MarkInactiveStaff";
import "../Students/toggle.css";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReactPaginate from 'react-paginate';
import AgGridTable from "../Students/AgGridTable";

const ViewStaff = () => {
    const [table, setTable] = useState(false);
    const [dataTable, setDataTable] = useState(null);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [isModal, setIsModal] = useState(false);
    const [indexValue, setIndex] = useState();
    const [data, setStudentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [CourseOptions, setCourseOptions] = useState([]);
    const [StdOptions, setStdOptions] = useState([]);
    const [BatchOptions, setBatchOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [SchoolCollegeOptions, setSchoolCollegeOption] = useState([]);
    const [Course_Id, setCourse] = useState("");
    const [School_College, setSchoolCollege] = useState('');
    const [Std_Id, setStdId] = useState("");
    const [Batch_ID, setBatchID] = useState('');
    const [Academic_Year, setAcademicYear] = useState("");
    const [Head_Id, setBranch] = useState(userData?.Head_Id);
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: ""
    });


    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView
            })
        }
    }, [userData, userData?.Head_Id, Std_Id]);
    const fetchData = async () => {
        try {
            showLoader();

            const result = await axios.get(
                `${apiUrl}/getStaff?OrgId=${userData?.Org_Id}`
            );
            console.log(result.data);
            setStudentData(result.data);
            setFilteredData(result.data);
            setTable(true);

            hideLoader();
        } catch (error) {
            console.error("Error fetching headmaster data:", error);
            hideLoader();
        }
    };

    //Search Box    
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (data.length > 0) {
            const newfilteredData = data.filter((row) => {
                const fullName = `${row?.First_Name || ""} ${row?.Middle_Name || ""} ${row?.Last_Name || ""}`.toLowerCase();
                const singlePunchStatus = row?.SinglePunch === false ? 'No' : 'Yes';
                const punchTimes = `${row?.MF_InTime || '--'} : ${row?.MF_OutTime || '--'}\n${row?.Sat_InTime || '--'} : ${row?.Sat_OutTime || '--'}`.toLowerCase();
                const weekOff = row?.WeekOff?.toLowerCase() || "";

                return (
                    fullName.includes(searchTerm.toLowerCase()) ||
                    singlePunchStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    punchTimes.includes(searchTerm.toLowerCase()) ||
                    weekOff.includes(searchTerm.toLowerCase())
                );
            });

            setFilteredData(newfilteredData);
        }
    }, [searchTerm, data]);


    useEffect(() => {
        const PhotoCellRenderer = (props) => {
            const { Photo } = props.data;
            return <div className="avatar-group avatar-group-dense">
                <div className="avatar avatar-m  rounded-circle" style={{ height: "2rem", width: "2rem", marginTop: "5px" }}>
                    <img className="rounded-circle " src={`${apiUrl}/Documents/Staff/${Photo}`}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                        }} alt="Staff" />
                </div>
            </div>;
        };
        const LinkCellRenderer = (props) => {
            const { User_Id, Name } = props.data;
            return <Link to={`/staffProfile/update/${User_Id}`}>{Name}</Link>;
        };

        const ShiftTimeRenderer = (props) => {
            const { MF_OutTime, MF_InTime, Sat_InTime, Sat_OutTime } = props.data;
            return <>
                <p>{`${MF_InTime ? MF_InTime : '--'} : ${MF_OutTime ? MF_OutTime : '--'}`}</p>
                <p>{`${Sat_InTime ? Sat_InTime : '--'} : ${Sat_OutTime ? Sat_OutTime : '--'}`}</p>
            </>;
        };

        const options = (props) => {
            const { User_Id } = props.data;
            return <div className="dropdown font-sans-serif">
                <button style={{ padding: 0 }} className="btn btn-link" id="dropdownMenuLink" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-v" style={{ fontSize: "14px", color: "gray" }}></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                    {/* <Link onClick={(e) => { e.preventDefault(); showModal(props.rowIndex); }} className="dropdown-item cursor-pointer" style={{ height: "44px" }}>
                        <i className="far fa-edit" style={{ color: "red", fontSize: "14px" }}></i>
                        <span> Inactive</span>
                    </Link> */}

                    <Link onClick={() => { deleteUser(User_Id); }} className="dropdown-item cursor-pointer" style={{ height: "44px" }}>
                        <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                        <span> Delete</span>
                    </Link>
                </div>
            </div>;
        };
        const newAgcolumns = [
            { field: "Photo", cellRenderer: PhotoCellRenderer, width: 100, maxWidth: 100 },
            { field: "Name", cellRenderer: LinkCellRenderer },
            { field: "Single Punch" },
            { field: "Shifts", cellRenderer: ShiftTimeRenderer },
            { field: "Week Off" },
            { headerName: "", field: "", cellRenderer: options, width: 50, maxWidth: 50 }
        ]
        setAgcolumns(newAgcolumns)
    }, []);

    useEffect(() => {
        if (filteredData.length > 0) {
            const newAgrows = filteredData.map((row) => ({
                Photo: `${row.Photo}`,
                Name: `${row.First_Name} ${row.Middle_Name || ""} ${row.Last_Name}`,
                User_Id: row.User_Id,
                "Single Punch": row.SinglePunch === false ? 'No' : 'Yes',
                'Shifts': `${row.MF_InTime ? row.MF_InTime : '--'} : ${row.MF_OutTime ? row.MF_OutTime : '--'}\n${row.Sat_InTime ? row.Sat_InTime : '--'} : ${row.Sat_OutTime ? row.Sat_OutTime : '--'}`,
                'Week Off': row.WeekOff,
            }));

            setAgrows(newAgrows);
        }
    }, [filteredData]);

    const deleteUser = (Id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id);
            }
        });
    };

    async function handleDelete(Id) {
        showLoader();
        const obj = {
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            Head_Id: userData.Head_Id,
            Head_Name: userData.Head_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            User_Name: userData.UserName
        };

        try {
            await axios.delete(`${apiUrl}/deleteStaff/${Id}`, {
                data: obj
            });
            Swal.fire("Deleted!", "Staff record has been Deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting staff record:", error);
            Swal.fire("Error", "Failed to delete staff record.", "error");
        } finally {
            hideLoader();
        }
    }


    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };


    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Staff Details", 20, 10);

        const columns = [
            "Name",
            "Single Punch",
            "Shifts",
            "Week Off",
        ];

        const rows = filteredData.map((row) => [
            `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
            row.SinglePunch === false ? 'No' : 'Yes',
            `${row.MF_InTime ? row.MF_InTime : '--'} : ${row.MF_OutTime ? row.MF_OutTime : '--'}\n${row.Sat_InTime ? row.Sat_InTime : '--'} : ${row.Sat_OutTime ? row.Sat_OutTime : '--'}`,
            row.WeekOff
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Staff Details.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Name",
            "Single Punch",
            "Shifts",
            "Week Off",
        ];
        const rows = [columns];
        filteredData.forEach((row) => {
            rows.push([
                `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
                row.SinglePunch === false ? 'No' : 'Yes',
                `${row.MF_InTime ? row.MF_InTime : '--'} : ${row.MF_OutTime ? row.MF_OutTime : '--'}\n${row.Sat_InTime ? row.Sat_InTime : '--'} : ${row.Sat_OutTime ? row.Sat_OutTime : '--'}`,
                row.WeekOff
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Staff Details");

        // Buffer
        //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, "Staff Details.xlsx");
    };

    const showModal = (index) => {
        setIndex(index);
        setIsModal(true);
    };
    const closed = () => {
        setIsModal(false);
        fetchData();
    };

    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true
        })
        setFilterData({
            ...userData,
            filterToggle: design.filterToggle ? false : true
        })
    }

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-1">
                            <div className="avatar avatar-xl me-3">
                                <img className="rounded-circle" src={`${apiUrl}/Documents/Staff/${row.Photo}`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'https://masyseducare.com/img/userdefault.png';
                                    }} alt="staff" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black"><Link to={`/staffProfile/update/${row.User_Id}`}>{`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`}</Link></h5>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="me-3 flex-1">
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Shifts 1 : <span className="fw-semi-bold  ms-1"> {`${row.MF_InTime ? row.MF_InTime : '--'} : ${row.MF_OutTime ? row.MF_OutTime : '--'}`}</span> <br />Shifts 2 : <span className="fw-semi-bold  ms-1">{`${row.Sat_InTime ? row.Sat_InTime : '--'} : ${row.Sat_OutTime ? row.Sat_OutTime : '--'}`}</span></p>
                                <p className="mb-0 profiledetail lh-1" style={{ fontSize: "13px" }}>Single Punch : <span className="ms-1 text-1100">{row.SinglePunch === false ? 'No' : 'Yes'}</span></p>
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Week Off : <span className="fw-semi-bold  ms-1">{row.WeekOff}</span></p>
                            </div>
                        </div>
                        {/* <div className="col-md-2">
                            <div className="me-3 flex-1">
                                <p className="mb-0 fw-semi-bold text-truncate" style={{ color: "#a09aa3" }}>Mobile Number</p>
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Father : <span className="fw-semi-bold  ms-1">{row.Father_Mobile}</span></p>
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Mother : <span className="fw-semi-bold  ms-1">{row.Mother_Mobile ? row.Mother_Mobile : ''}</span></p>
                            </div>
                        </div> */}
                        <div className="col-md-1">
                            <div className="font-sans-serif"><button className="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--2 text-900"></span></button>
                                <div className="dropdown-menu dropdown-menu-end py-2" aria-labelledby="documentations">
                                    {/* <Link onClick={(e) => { e.preventDefault(); showModal(index); }} className="dropdown-item cursor-pointer">
                                        <i className="far fa-edit" style={{ color: "red", fontSize: "14px" }}></i>
                                        <span> Inactive</span>
                                    </Link> */}

                                    <Link onClick={() => { deleteUser(row.User_Id); }} className="dropdown-item cursor-pointer">
                                        <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                                        <span> Delete</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>)}
                {design.dataView === "Tile View" && (< div key={index} className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4">
                    <div className="card h-100 hover-actions-trigger">
                        <div className="card-body" style={{ padding: "15px" }}>
                            <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                <div className="avatar-group avatar-group-dense">
                                    <div className="avatar avatar-m  rounded-circle" style={{ height: "3rem", width: "3rem" }}>
                                        <img className="rounded-circle " src={`${apiUrl}/Documents/Staff/${row.Photo}`}
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevent infinite loop
                                                e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                                            }} alt="Staff" />
                                    </div>
                                </div>
                                <div className="row justify-content-between me-1" style={{ flex: "auto", marginTop: "8px" }}>
                                    <div className="col-11 pe-1">
                                        <h6 className="mb-2 line-clamp-1 lh-sm  me-1"><Link to={`/staffProfile/update/${row.User_Id}`}>{`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`}</Link></h6>
                                    </div>
                                    <div className="end-0 col-1 p-0 " >
                                        <button className="btn btn-icon flex-shrink-0 mb-2" id="dropdownMenuLink" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="fa-solid fa-ellipsis-vertical"></span>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                            {/* <Link onClick={(e) => { e.preventDefault(); showModal(index); }} className="dropdown-item cursor-pointer">
                                                <i className="far fa-edit" style={{ color: "red", fontSize: "14px" }}></i>
                                                <span> Inactive</span>
                                            </Link> */}

                                            <Link onClick={() => { deleteUser(row.User_Id); }} className="dropdown-item cursor-pointer">
                                                <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                                                <span> Delete</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-success"><span className="badge-label">completed</span></span> */}
                            <div className="d-flex align-items-center mb-2">
                                {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                <p className="mb-0 profiledetail text-truncate lh-1">Shifts 1 : <span className="fw-semi-bold  ms-1"> {`${row.MF_InTime ? row.MF_InTime : '--'} : ${row.MF_OutTime ? row.MF_OutTime : '--'}`}</span> <br />Shifts 2 : <span className="fw-semi-bold  ms-1">{`${row.Sat_InTime ? row.Sat_InTime : '--'} : ${row.Sat_OutTime ? row.Sat_OutTime : '--'}`}</span></p>
                            </div>
                            <div className="d-flex align-items-center mb-2  ">
                                {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                <p className="mb-0 profiledetail lh-1">Single Punch : <span className="ms-1 text-1100">{row.SinglePunch === false ? 'No' : 'Yes'}</span></p>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                <p className="mb-0 profiledetail lh-1">Week Off : <span className="fw-semi-bold  ms-1">{row.WeekOff}</span></p>
                            </div>

                            {/* <div className="hover-actions end-0 me-4" style={{ bottom: "20px" }}>
                                <button className="btn btn-primary btn-icon flex-shrink-0" data-bs-toggle="modal" data-bs-target="#projectsCardViewModal">
                                    <span className="fa-solid fa-chevron-right"></span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div >)
                }

            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    return (
        <div className="content">

            <div className="container-fluid">

                {/* /#sidebar-wrapper */}
                <div className="page-content-wrapper">
                    <div className="row justify-content-between">
                        <h4 className="col-md-6 mb-0" data-anchor="data-anchor" id="basic-form">
                            Staff Details
                            <a
                                className="anchorjs-link "
                                aria-label="Anchor"
                                href="#basic-form"
                                style={{ paddingLeft: "0.375em" }}
                            ></a>
                        </h4>
                        <div className="col-md-12 text-center mt-3">
                            <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..." style={{ float: "left", display: "inline-block", width: "200px" }}></input>
                            <Link to={"/addStaff"}>
                                <button className="btn btn-primary btn-sm text-center" style={{ float: "right" }}>
                                    Add
                                </button>
                            </Link>
                            <button type="button" className="btn bg-200 filterButton collapsed mr-2 d-none" data-toggle="collapse" onClick={toggle} data-target="#navbar" aria-expanded="false" aria-controls="navbar" style={{ float: "right", marginRight: "10px" }}>
                                <i className="fa fa-filter"></i>
                            </button>
                            <button className="form-control dropdown-toggle viewButton  mb-2" id="dropdownMenuLink" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ float: "right", marginRight: "10px", width: "auto", padding: "4px 10px" }}>
                                <>
                                    {design.dataView === "Table View" &&
                                        (<TableChartOutlinedIcon />)}
                                </>
                                <>
                                    {design.dataView === "List View" &&
                                        (<FormatListBulletedIcon />)}
                                </>
                                <>
                                    {design.dataView === "Tile View" &&
                                        (<GridViewIcon />)}
                                </>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                <Link onClick={(e) => {
                                    setDesign({
                                        ...design,
                                        dataView: "Table View"
                                    })
                                    setFilterData({
                                        ...userData,
                                        dataView: "Table View"
                                    })
                                }} className="dropdown-item cursor-pointer">
                                    <TableChartOutlinedIcon />
                                    <span> Table View</span>
                                </Link>
                                <Link onClick={() => {
                                    setDesign({
                                        ...design,
                                        dataView: "List View"
                                    })
                                    setFilterData({
                                        ...userData,
                                        dataView: "List View"
                                    })
                                }} className="dropdown-item cursor-pointer">
                                    <FormatListBulletedIcon />
                                    <span> List View</span>
                                </Link>
                                <Link onClick={() => {
                                    setDesign({
                                        ...design,
                                        dataView: "Tile View"
                                    })
                                    setFilterData({
                                        ...userData,
                                        dataView: "Tile View"
                                    })
                                }} className="dropdown-item cursor-pointer">
                                    <GridViewIcon />
                                    <span> Tile View</span>
                                </Link>
                            </div>
                            <OverlayTrigger delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (<Tooltip {...props}>Export to PDF</Tooltip>)}
                                placement="bottom">
                                <Button variant="" onClick={() => DownloadPDF()} style={{ float: "right", padding: "5px 10px 5px 5px" }}>
                                    <i className="fas fa-file-pdf" style={{ fontSize: "25px" }}></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (<Tooltip {...props}>Export to Excel</Tooltip>)}
                                placement="bottom">
                                <Button variant="" onClick={() => DownloadExcel()} style={{ float: "right", padding: "5px 5px 5px 10px" }}>
                                    <i className="fas fa-file-excel" style={{ fontSize: "25px" }}></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-12 order-1 order-xl-0">
                            {filteredData.length === 0 && (<h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
                        </div>
                        {design.dataView === "Table View" && (
                            <div className={filteredData.length === 0 ? "d-none" : "col-12 col-xl-12 order-1 order-xl-0"}>


                                {Agrows.length > 0 && (
                                    <AgGridTable row={Agrows} column={Agcolumns} />
                                )}


                            </div>)}
                        {design.dataView === "Tile View" && (
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div className="row">
                                    {displayData}
                                </div>
                            </div>
                        )}
                        {design.dataView === "List View" && (
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div className="row">
                                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                        <div className="card h-100 hover-actions-trigger">
                                            <div className="card-body" style={{ paddingTop: "5px" }}>
                                                {displayData}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}
                        {(design.dataView === "List View" || design.dataView === "Tile View") && (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                pageCount={pageCount}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                breakAriaLabels={{ forward: 'Jump forward', backward: 'Jump backward' }}
                                onPageChange={changePage}
                                containerClassName={'pagination'}
                                disabledLinkClassName={'btn btn-phoenix-secondary text-black'}
                                previousLinkClassName={'btn btn-outline-primary me-2'}
                                nextLinkClassName={'btn btn-outline-primary ms-2'}
                                activeLinkClassName={'customclass ms-2 me-2'}
                                pageLinkClassName={'btn btn-outline-primary ms-2 me-2'}
                            />)}
                    </div>
                </div>
            </div>

            {loader}

        </div>
    );
};

export default ViewStaff;
