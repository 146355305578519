import React, { useState } from "react";
import { Link } from "react-router-dom";

const CardBody = ({ title, text, onDelete, onEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="card-body" style={{ padding: "10px" }}>
      <h5 className="card-title" style={{ marginBottom: "10px" }}>
        <span>{title}</span>
      </h5>
      <p
        className={`card-text transition-text ${isExpanded ? "expanded" : ""}`}
        style={{ color: "#525b75", fontSize: "12px" }}
      >
        <span>
          {isExpanded ? text : `${text.substring(0, 100)}...`}
          {text.length > 100 && (
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleToggle();
              }}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {isExpanded ? " Read Less" : " Read More"}
            </Link>
          )}
        </span>
      </p>
      <Link
        onClick={(e) => {
          e.preventDefault();
          onDelete();
        }}
        style={{ color: "Red" }}
      >
        <i className="fa fa-trash"></i>
      </Link>&nbsp;
      <Link
        onClick={(e) => {
          e.preventDefault();
          onEdit();
        }}
        style={{ color: "blue" }}
      >
        <i className="fa fa-edit"></i>
      </Link>
    </div>
  );
};

const Card = ({ video, onDelete , onEdit}) => {
  return (
    <div className="col-sm-6 col-lg-4 mb-3 mb-md-0 p-2" key={video.Id}>
      <div className="card text-white h-100">
        <div className="youtube-embed">
          <iframe
            className="card-img-top"
            width="287"
            height="150"
            src={`https://www.youtube.com/embed/${video.Link}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <CardBody
          title={video?.Title}
          text={video?.Description}
          onDelete={() => onDelete(video.Id, video?.Title)}
          onEdit={() => onEdit(video)}

        />
      </div>
    </div>
  );
};

export default Card;
