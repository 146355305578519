import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from "../../../../Components/dateFormate";
import { FaEye } from "react-icons/fa";
import Switch from "react-switch";

const BankAccountsModal = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [accData, setAccData] = useState([]);

    const [isEditing, setIsEditing] = useState(false);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const loadBankAccounts = async () => {
        showLoader();
        try {
            const result = await axios.get(`${apiUrl}/bindBankDetails/${userData.Org_Id}`);
            setAccData(result.data);
            console.log(result.data);

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            loadBankAccounts();
        }
    }, [userData]);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const updatedAccData = [...accData];
        updatedAccData[index][name] = value;
        setAccData(updatedAccData);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateaccData = {
            BankAccounts: accData,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/submitBankAccountsDetails`, updateaccData)
                .then((res) => {
                    Swal.fire({
                        title: "Success!",
                        text: "Bank Accounts Settings Updated Successfully!!",
                        icon: "success",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire("Server Timeout", "Server is Busy!!!, Please try again later.", "error");
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="xl" centered style={{ boxShadow: "none !important" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Bank Account Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        {accData.map((account, index) => (
                            <div className="row" key={account.Row_Id}>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label" htmlFor={`Bank_Name_${index}`}>
                                        Bank Name
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        name="Bank_Name"
                                        id={`Bank_Name_${index}`}
                                        value={account.Bank_Name}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label" htmlFor={`Holder_Name_${index}`}>
                                        Beneficiary Name
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        name="Holder_Name"
                                        id={`Holder_Name_${index}`}
                                        value={account.Holder_Name}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                                <div className="mb-3 col-md-2">
                                    <label className="form-label" htmlFor={`Acc_No_${index}`}>
                                        Account No.
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        name="Acc_No"
                                        id={`Acc_No_${index}`}
                                        value={account.Acc_No}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                                <div className="mb-3 col-md-2">
                                    <label className="form-label" htmlFor={`Branch_Name_${index}`}>
                                        Branch Name
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        name="Branch_Name"
                                        id={`Branch_Name_${index}`}
                                        value={account.Branch_Name}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                                <div className="mb-3 col-md-2">
                                    <label className="form-label" htmlFor={`IFSC_Code_${index}`}>
                                        IFSC Code
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        name="IFSC_Code"
                                        id={`IFSC_Code_${index}`}
                                        value={account.IFSC_Code}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <span style={{ color: "Red" }}></span>
                                </div>
                            </div>
                        ))}
                        <div className="col-md-12 text-center mt-3">
                            <button type="submit" className="btn btn-sm btn-primary">
                                Update
                            </button>
                        </div>
                    </form>
                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BankAccountsModal;
