import React from "react";

const GenderDropDown = ({ label, value, disabled, name, id, onChange, required }) => {

    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
    ];
    return (
        <>
            <label className="form-label" htmlFor={id}>
                {label}{required && <span style={{ color: "Red" }}>*</span>}
            </label>
            <select
                className="form-select form-select-sm"
                name={name}
                id={id}
                value={value}
                disabled={disabled}
                required={required}
                onChange={onChange}
            >
                <option selected disabled value={''}>
                   Select {label}
                </option>
                {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </>
    );
};

export default GenderDropDown;
