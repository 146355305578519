import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { apiUrl } from "./apiConfig";
import { useUser } from "./UserContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

function Copyright(props) {
  return (
    <p align="center" {...props}>
      {"Copyright © "}
      <a color="inherit" href="https://www.masyseducare.com">
        Masys I-Class
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </p>
  );
}

function validateForm(values) {
  const errors = {};

  if (!values.Username) {
    errors.Username = "Enter Username";
  }

  if (!values.Password) {
    errors.Password = "Enter Password";
  }

  return errors;
}

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({
    Username: "",
    Password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { setData } = useUser();
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    const container = document.getElementById("container");
    const registerBtn = document.getElementById("register");
    const loginBtn = document.getElementById("login");

    registerBtn.addEventListener("click", () => {
      container.classList.add("active");
    });

    loginBtn.addEventListener("click", () => {
      container.classList.remove("active");
    });

    return () => {
      registerBtn.removeEventListener("click", () => {
        container.classList.add("active");
      });

      loginBtn.removeEventListener("click", () => {
        container.classList.remove("active");
      });
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formErrors = validateForm(values);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/login`, values)
        .then((res) => {
          if (res.data.Status === "Success") {
            if (res?.data?.Head_Id === "All") {
              navigate("/switchBranch");
            } else {
              navigate("/");
            }
            setLoading(false);
          } else {
            setLoginError("Fail to Sign In. Please check your Credentials.");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoginError("An error occurred during Sign In.");
        });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <style>
        {`
           @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Montserrat', sans-serif;
    }

    body {
      background-color: #c9d6ff;
      background: linear-gradient(to right, #e2e2e2, #c9d6ff);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
    }

    .container {
      background-color: #fff;
      /* border-radius: 30px; */
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
      position: relative;
      overflow: hidden;
      width: 1366px;
      height: 641px;
      max-width: 100%;
      justify-content: center;
  align-items: center;
  height: 100vh;
    }

    .container p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;
      margin: 20px 0;
    }

    .container span {
      font-size: 12px;
    }

    .container a {
      color: #333;
      font-size: 13px;
      text-decoration: none;
      margin: 15px 0 10px;
    }

    .container button {
      background-color: #3874ff;
      color: #fff;
      font-size: 12px;
      padding: 10px 45px;
      border: 1px solid transparent;
      border-radius: 8px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-top: 10px;
      cursor: pointer;
    }

    .container button.hidden {
      background-color: transparent;
      border-color: #fff;
    }

    .container form {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 40px;
      height: 100%;
    }

    .container input {
      background-color: #eee;
      border: none;
      margin: 8px 0;
      padding: 10px 15px;
      font-size: 13px;
      border-radius: 8px;
      width: 100%;
      outline: none;
    }

    .form-container {
      position: absolute;
      top: 0;
      height: 100%;
      transition: all 0.6s ease-in-out;
    }

    .sign-in {
      left: 0;
      width: 50%;
      z-index: 2;
    }

    .container.active .sign-in {
      transform: translateX(100%);
    }

    .sign-up {
      left: 0;
      width: 50%;
      opacity: 0;
      z-index: 1;
      transition: all 0.5s;
    }

    .container.active .sign-up {
      transform: translateX(100%);
      opacity: 1;
      z-index: 5;
      animation: move 0.6s;
    }

    @keyframes move {

      0%,
      49.99% {
        opacity: 0;
        z-index: 1;
      }

      50%,
      100% {
        opacity: 1;
        z-index: 5;
      }
    }

    .social-icons {
      margin: 20px 0;
    }

    .social-icons a {
      border: 1px solid #ccc;
      border-radius: 20%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 3px;
      width: 40px;
      height: 40px;
      transition: all 0.5s;
    }

    .social-icons a:hover {
      scale: 1.3;
      border: 1px solid #000;
    }

    .toggle-container {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      overflow: hidden;
      transition: all 0.6s ease-in-out;
      border-radius: 150px 0 0 150px;
      z-index: 1000;
    }

    .container.active .toggle-container {
      transform: translateX(-100%);
      border-radius: 0 150px 100px 0;
    }

    .toggle {
      background-color: #512da8;
      height: 100%;
      background: linear-gradient(to right, #c7eaed, #35d8e9);
      color: #fff;
      position: relative;
      left: -100%;
      height: 100%;
      width: 200%;
      transform: translateX(0);
      transition: all 0.6s ease-in-out;
    }

    .container.active .toggle {
      transform: translateX(50%);
    }

    .toggle-panel {
      position: absolute;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 30px;
      text-align: center;
      top: 0;
      transform: translateX(0);
      transition: all 0.6s ease-in-out;
    }

    .toggle-left {
      transform: translateX(-200%);
    }

    .container.active .toggle-left {
      transform: translateX(0);
    }

    .toggle-right {
      right: 0;
      transform: translateX(0);
    }

    .container.active .toggle-right {
      transform: translateX(200%);
    }

    

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .login-form {
    padding: 10px;
  }
        `}
      </style>

      <div className="container" id="container">
        <div className="form-container sign-up">
          <form onSubmit={handleSubmit}>
            <h1>Sign In</h1>
            <div className="social-icons">
              <a href="#" className="icon">
                <i className="fa-brands fa-google-plus-g"></i>
              </a>
              <a href="#" className="icon">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a href="#" className="icon">
                <i className="fa-brands fa-github"></i>
              </a>
              <a href="#" className="icon">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
            <span>or use your email password</span>
            <input type="email" placeholder="Email" />
            <input type="password" placeholder="Password" />
            <Link to="#">→ Forget Your Password? ←</Link>
            <button type="submit">Sign In</button>
          </form>
        </div>
        <div className="form-container sign-in">
          {loginError && (
            <p
              variant="body2"
              className="text-danger"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              {loginError}
            </p>
          )}

          <form className="p-5" onSubmit={handleSubmit}>
            <div className="card p-3 login-form">
              <h1 className="mb-3" style={{textAlign: "center"}}>Sign In</h1>
              <input
                className="form-control form-control-sm"
                type="text"
                name="Username"
                id="Username"
                placeholder="Enter Username"
                onChange={(e) =>
                  setValues({ ...values, Username: e.target.value })
                }
              />
              <span style={{ color: "Red" }}>{errors.Username}</span>

              <div className="input-group mb-3">
                <input
                  className="form-control form-control-sm"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  autoComplete="current-password"
                  onChange={(e) =>
                    setValues({ ...values, Password: e.target.value })
                  }
                />
                <div
                  className="input-group-append"
                  style={{ marginTop: "8px" }}
                >
                  <span
                    className="input-group-text"
                    style={{
                      cursor: "pointer",
                      height: "39px",
                      borderBottomRightRadius: "7px",
                      borderTopRightRadius: "7px",
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                      backgroundColor: "#eeeeee",
                      border: "none",
                    }}
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>

              <span style={{ color: "Red" }}>{errors.Password}</span>

              <sapm>
                By continuing you agree to our{" "}
                <a
                  href="https://masyseducare.com/TermsAndConditions.aspx"
                  target="_blank"
                  style={{ color: "blue" }}
                >
                  Terms & conditions
                </a>
              </sapm>

              <div className="row p-3">
                {!isLoading && (
                  <Button className="btn btn-primary" type="submit">
                    Sign In
                  </Button>
                )}
                {isLoading && (
                  <Button className="btn btn-login" type="submit" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      style={{ width: "1rem", height: "1rem" }}
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {"  "} Sign In...
                  </Button>
                )}
              </div>

              <Copyright style={{ marginTop: "20px" }} />
            </div>
          </form>
        </div>
        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <h1>Welcome Back!</h1>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <img
                  src="/loginassets/newDesign/images/masystech.png"
                  style={{ maxWidth: "100%", height: "auto", display: "block" }}
                  alt="logo"
                />
              </div>
              <button className="hidden d-none" id="login">
                Sign In
              </button>
            </div>
            <div className="toggle-panel toggle-right">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <img
                  src="/CustomerLogo/I-Class.png"
                  style={{ maxWidth: "98%", height: "auto", display: "block" }}
                  alt="logo"
                />
              </div>
              <h1>Welcome-Back!</h1>
              <p>
                For any query call us on
                <b> (+91) 8097455577/88/99</b>
              </p>
              <button className="hidden d-none" id="register">
                Sign Up
              </button>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6 col-lg-6 col-md-6">
          <img
            src="/loginassets/newDesign/images/masystech.png"
            style={{ maxWidth: "90%", height: "auto", display: "block" }}
            alt="logo"
          />
        </div> */}
      </div>
    </>
  );
};

export default Login;
