import React, { useState, useEffect } from "react";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";
import Select from "react-select";

const DropVendor = ({ label, value, isDisabled, name,required, id, onChange }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [VendorOptions, setData] = useState([]);



    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();
                    const result = await axios.get(
                        `${apiUrl}/getVendors?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
                    );

                    const op = result.data.map((Vendor) => ({
                        label: Vendor.Company_Name,
                        value: Vendor.v_id,
                    }));
                    setData(op);

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData]);

    const customStyles = {
        control: base => ({
          ...base,
          height: 33,
          minHeight: 33
        })
      };

    return (
        <>
            <label className="form-label" htmlFor={id}>
                {label}{required && <span style={{ color: "Red" }}>*</span>}
            </label>
            <Select
            name={name}
            id={id}
            //classNamePrefix="select"
            required={required}
            options={VendorOptions}
            value={value}
            isDisabled={isDisabled}
            onChange={onChange}
            styles={customStyles}
            />
            {loader}
        </>
    );
};


export default DropVendor;