import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate'
import { FaEye } from "react-icons/fa";


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Date: updateData.Date,
        Holiday: updateData.Holiday,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,

            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateFacultyHoliday/${updateData?.Sr_No}`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Holiday Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Staff/Faculty Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                        <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="Date">
                                    Date<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="date"
                                    name="Date"
                                    id="Date"
                                    required
                                    value={formatInputDate(Bill.Date)}
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="Holiday">
                                    Holiday<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Holiday"
                                    id="Holiday"
                                    required
                                    value={Bill.Holiday}
                                    placeholder="Enter Holiday Description..."
                                    onChange={handleChange}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>

                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
