import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../../../DropDowns/DropDown";
import "jspdf-autotable";
import { formatDate } from "../../../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../../../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../../../Students/AgGridTable";
import UpdateAdminStaff from "./UpdateAdminStaff";
import PermissionsAdminStaff from "./PermissionsAdminStaff";


const ViewHomework = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [data, setBillData] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal1, setIsModal1] = useState(false);
    const [indexValue, setIndex] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: "",
    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView,
            });
        }
    }, [userData]);

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(`${apiUrl}/getAdminStaff?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`);
            setBillData(res.data);
            setFilteredData(res.data);
           // console.log(res.data);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    //Search Box
    const [searchTerm, setSearchTerm] = useState("");
    useEffect(() => {
        if (data.length > 0) {
            const newFilteredData = data.filter(
                (row) =>
                    row?.Head_Name?.toLowerCase().includes(searchTerm) ||
                    row?.Display_Name?.toLowerCase().includes(searchTerm) ||
                    row?.Email?.toLowerCase().includes(searchTerm) ||
                    row?.Mobile?.toLowerCase().includes(searchTerm) ||
                    (row?.Is_Owner === '1' ? 'Yes' : 'No')?.toLowerCase().includes(searchTerm)

            );
            setFilteredData(newFilteredData);
        }
    }, [searchTerm, data]);

    const showModal = (index) => {
        setIndex(index);
        setIsModal(true);
    };
    const closed = () => {
        setIsModal(false);
        fetchData();
    };

    
    const showModal1 = (index) => {
        setIndex(index);
        setIsModal1(true);
    };
    const closed1 = () => {
        setIsModal1(false);
        fetchData();
    };



    useEffect(() => {
        const PhotoCellRenderer = (props) => {
            const { Photo } = props.data;
            return <div className="avatar-group avatar-group-dense">
                <div className="avatar avatar-m  rounded-circle" style={{ height: "2rem", width: "2rem", marginTop: "5px" }}>
                    <img className="rounded-circle " src={`${apiUrl}/Documents/Staff/${Photo}`}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                        }} alt="Staff" />
                </div>
            </div>;
        };
        const options = (props) => {

            const { UserId, Name } = props.data;
            return (
                <div className="dropdown font-sans-serif">
                    <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i
                            className="fas fa-ellipsis-v"
                            style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                    </button>
                    <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                    >
                        <div>

                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    showModal(props.rowIndex);
                                }}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <i
                                    className="fas fa-pen"
                                    style={{ color: "blue", fontSize: "14px" }}
                                ></i>
                                <span> Update</span>
                            </Link>
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    showModal1(props.rowIndex);
                                }}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <i
                                    className="fas fa-pen"
                                    style={{ color: "yellow", fontSize: "14px" }}
                                ></i>
                                <span> Permissions</span>
                            </Link>
                        </div>

                        <Link
                            onClick={() => {
                                deleteUser(UserId, Name);
                            }}
                            className="dropdown-item cursor-pointer"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            <i
                                className="fas fa-trash"
                                style={{ color: "red", fontSize: "14px" }}
                            ></i>
                            <span> Delete</span>
                        </Link>
                    </div>
                </div>
            );
        };

        const newAgcolumns = [
            { field: "Photo", cellRenderer: PhotoCellRenderer, width: 100, maxWidth: 100 },
            { field: "BranchAccess" },
            { field: "Name" },
            { field: "Email" },
            { field: "Mobile" },
            { field: "OwnerRights" },
            {
                headerName: "",
                field: "",
                cellRenderer: options,
                width: 50,
                maxWidth: 50,
            },
        ];
        setAgcolumns(newAgcolumns);
    }, []);

    useEffect(() => {
        if (filteredData.length > 0) {
            const newAgrows = filteredData.map((row, index) => ({
                UserId: row.UserId,
                BranchAccess: row.Head_Name,
                Name: row.Display_Name,
                Email: row.Email ? row.Email : "--",
                Photo: `${row.Photo}`,
                Mobile: row.Mobile,
                OwnerRights: row.Is_Owner === '1' ? 'Yes' : 'No'
            }));

            setAgrows(newAgrows);
        }
    }, [filteredData]);

    const deleteUser = (Id, Name) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id, Name);
            }
        });
    };
    async function handleDelete(Id, Name) {
        showLoader();
        // console.log(Id)
        const obj = {
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            Head_Id: userData.Head_Id,
            Head_Name: userData.Head_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            User_Name: userData.UserName,
            Name: Name
        };

        try {
            await axios.delete(`${apiUrl}/deleteAdminStaffData/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Admin has been deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting Admin record:", error);
            Swal.fire("Error", "Failed to delete Admin record.", "error");
        } finally {
            hideLoader();
        }
    }

    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };

    const DownloadPDF = () => {
        // const doc = new jsPDF();
        const doc = new jsPDF();
        doc.text("Admin/Staff", 20, 10);

        const columns = [
            "Sr No",
            'BranchAccess',
            "Name",
            "Email",
            "Mobile",
            "OwnerRights"
        ];

        const rows = filteredData.map((row, index) => [
            index + 1,
            row.Head_Name,
            row.Display_Name,
            row.Email ? row.Email : "--",
            row.Mobile,
            row.Is_Owner === '1' ? 'Yes' : 'No'
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("AdminStaff.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Sr No",
            'BranchAccess',
            "Name",
            "Email",
            "Mobile",
            "OwnerRights"
        ];

        const rows = [columns];
        filteredData.forEach((row, index) => {
            rows.push([
                index + 1,
                row.Head_Name,
                row.Display_Name,
                row.Email ? row.Email : "--",
                row.Mobile,
                row.Is_Owner === '1' ? 'Yes' : 'No'
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "AdminStaff");

        XLSX.writeFile(workBook, "AdminStaff.xlsx");
    };



    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true,
        });
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true,
        });
    };

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-1">
                            <div className="avatar avatar-xl me-3">
                                <img className="rounded-circle" src={`${apiUrl}/Documents/Staff/${row.Photo}`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'https://masyseducare.com/img/userdefault.png';
                                    }} alt="staff" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black">{row.Display_Name}</h5>
                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="profiledetail lh-1 mb-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Standard :{" "}
                                    <span className="ms-1 text-1100">{row.Head_Name}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Email :{" "}
                                    <span className="ms-1 text-1100">{row.Email}</span>
                                </p>
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Mobile :{" "}
                                    <span className="ms-1 text-1100">{row.Mobile}</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Owner Rights :{" "}
                                    <span className="ms-1 text-1100">{row.Is_Owner === '1' ? 'Yes' : 'No'}</span>
                                </p>

                            </div>
                        </div>
                      
                        <div className="col-md-1">
                            <div className="font-sans-serif">
                                <button
                                    style={{ padding: 0 }}
                                    className="btn btn-link"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i
                                        className="fas fa-ellipsis-v"
                                        style={{ fontSize: "14px", color: "gray" }}
                                    ></i>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <div>

                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showModal(index);
                                            }}
                                            className="dropdown-item cursor-pointer"
                                        >
                                            <i
                                                className="fas fa-pen"
                                                style={{ color: "blue", fontSize: "14px" }}
                                            ></i>
                                            <span> Update</span>
                                        </Link>

                                   
                                    </div>
                                    <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    showModal1(index);
                                }}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <i
                                    className="fas fa-pen"
                                    style={{ color: "yellow", fontSize: "14px" }}
                                ></i>
                                <span> Permissions</span>
                            </Link>

                                    <Link
                                        onClick={() => {
                                            deleteUser(row.UserId, row.Display_Name);
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <i
                                            className="fas fa-trash"
                                            style={{ color: "red", fontSize: "14px" }}
                                        ></i>
                                        <span> Delete</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {design.dataView === "Tile View" && (
                    <div
                        key={index}
                        className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
                    >
                        <div className="card h-100 hover-actions-trigger">
                            <div className="card-body" style={{ padding: "15px" }}>
                                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                    <div className="avatar-group avatar-group-dense">
                                        <div className="avatar avatar-m  rounded-circle" style={{ height: "3rem", width: "3rem" }}>
                                            <img className="rounded-circle " src={`${apiUrl}/Documents/Staff/${row.Photo}`}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop
                                                    e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                                                }} alt="Staff" />
                                        </div>
                                    </div>
                                    <div
                                        className="row justify-content-between me-1"
                                        style={{ flex: "auto", marginTop: "8px" }}
                                    >
                                        <div className="col-11 pe-1">
                                            <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                                                {row?.Display_Name}
                                            </h6>
                                          
                                        </div>

                                    </div>
                                    <div className="end-0 col-1 p-0 ">
                                            <button
                                                style={{ padding: 0 }}
                                                className="btn btn-link"
                                                id="dropdownMenuLink"
                                                href="#"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i
                                                    className="fas fa-ellipsis-v"
                                                    style={{ fontSize: "14px", color: "gray" }}
                                                ></i>
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-end py-0"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <div>

                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            showModal(index);
                                                        }}
                                                        className="dropdown-item cursor-pointer"
                                                    >
                                                        <i
                                                            className="fas fa-pen"
                                                            style={{ color: "blue", fontSize: "14px" }}
                                                        ></i>
                                                        <span> Update</span>
                                                    </Link>
                                                    <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    showModal1(index);
                                }}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <i
                                    className="fas fa-pen"
                                    style={{ color: "yellow", fontSize: "14px" }}
                                ></i>
                                <span> Permissions</span>
                            </Link>
                                                 
                                                </div>

                                                <Link
                                                    onClick={() => {
                                                        deleteUser(row.UserId, row.Display_Name);
                                                    }}
                                                    className="dropdown-item cursor-pointer"
                                                >
                                                    <i
                                                        className="fas fa-trash"
                                                        style={{ color: "red", fontSize: "14px" }}
                                                    ></i>
                                                    <span> Delete</span>
                                                </Link>
                                            </div>
                                        </div>
                                </div>
                                <div className="d-flex align-items-center mb-2">

                                    <p className="mb-0 profiledetail text-truncate lh-1">Branch Access : <span className="fw-semi-bold ms-1"> {row.Head_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2  ">

                                    <p className="mb-0 profiledetail lh-1">Email : <span className="ms-1 text-1100">{row.Email}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2">

                                    <p className="mb-0 profiledetail lh-1">Mobile : <span className="ms-1 text-1100">{row.Mobile}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">

                                    <p className="mb-0 profiledetail lh-1">Owner Rights : <span className="ms-1 text-1100">{row.Is_Owner === '1' ? 'Yes' : 'No'}</span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="content">

            <div className="container-fluid">
                {/* Sidebar */}

                <div className="page-content-wrapper">
                    <div className="row justify-content-between">
                        <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                            View Admin
                            <a
                                className="anchorjs-link "
                                aria-label="Anchor"
                                href="#basic-form"
                                style={{ paddingLeft: "0.375em" }}
                            ></a>
                        </h4>
                        <div className="col-md-12 text-center mt-3">
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search..."
                                style={{
                                    float: "left",
                                    display: "inline-block",
                                    width: "200px",
                                }}
                            ></input>
                            <Link to={"/addAdminSatff"} style={{ float: "right" }}>
                                <button className="btn btn-primary btn-sm text-center">
                                    Add
                                </button>
                            </Link>
                            <button
                                type="button"
                                className="btn bg-200 filterButton collapsed mr-2 d-none"
                                data-toggle="collapse"
                                onClick={toggle}
                                data-target="#navbar"
                                aria-expanded="false"
                                aria-controls="navbar"
                                style={{ float: "right", marginRight: "10px" }}
                            >
                                <i className="fa fa-filter"></i>
                            </button>
                            <button
                                className="form-control dropdown-toggle viewButton  mb-2"
                                id="dropdownMenuLink"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                    float: "right",
                                    marginRight: "10px",
                                    width: "auto",
                                    padding: "4px 10px",
                                }}
                            >
                                <>
                                    {design.dataView === "Table View" && (
                                        <TableChartOutlinedIcon />
                                    )}
                                </>
                                <>
                                    {design.dataView === "List View" && (
                                        <FormatListBulletedIcon />
                                    )}
                                </>
                                <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-end py-0"
                                aria-labelledby="dropdownMenuLink"
                            >
                                <Link
                                    onClick={(e) => {
                                        setDesign({
                                            ...design,
                                            dataView: "Table View",
                                        });
                                        setFilterData({
                                            ...filterData,
                                            dataView: "Table View",
                                        });
                                    }}
                                    className="dropdown-item cursor-pointer"
                                >
                                    <TableChartOutlinedIcon />
                                    <span> Table View</span>
                                </Link>
                                <Link
                                    onClick={() => {
                                        setDesign({
                                            ...design,
                                            dataView: "List View",
                                        });
                                        setFilterData({
                                            ...filterData,
                                            dataView: "List View",
                                        });
                                    }}
                                    className="dropdown-item cursor-pointer"
                                >
                                    <FormatListBulletedIcon />
                                    <span> List View</span>
                                </Link>
                                <Link
                                    onClick={() => {
                                        setDesign({
                                            ...design,
                                            dataView: "Tile View",
                                        });
                                        setFilterData({
                                            ...filterData,
                                            dataView: "Tile View",
                                        });
                                    }}
                                    className="dropdown-item cursor-pointer"
                                >
                                    <GridViewIcon />
                                    <span> Tile View</span>
                                </Link>
                            </div>
                            <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>Export to PDF</Tooltip>
                                )}
                                placement="bottom"
                            >
                                <Button
                                    variant=""
                                    onClick={() => DownloadPDF()}
                                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                                >
                                    <i
                                        className="fas fa-file-pdf"
                                        style={{ fontSize: "25px" }}
                                    ></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>Export to Excel</Tooltip>
                                )}
                                placement="bottom"
                            >
                                <Button
                                    variant=""
                                    onClick={() => DownloadExcel()}
                                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                                >
                                    <i
                                        className="fas fa-file-excel"
                                        style={{ fontSize: "25px" }}
                                    ></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-12 order-1 order-xl-0">
                            {filteredData.length === 0 && (
                                <h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
                        </div>
                        {design.dataView === "Table View" && (
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                {Agrows.length > 0 && (
                                    <AgGridTable row={Agrows} column={Agcolumns} />
                                )}
                            </div>
                        )}
                        {design.dataView === "Tile View" && (
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div className="row">{displayData}</div>
                            </div>
                        )}
                        {design.dataView === "List View" && (
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div className="row">
                                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                        <div className="card h-100 hover-actions-trigger">
                                            <div
                                                className="card-body"
                                                style={{ paddingTop: "5px" }}
                                            >
                                                {displayData}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(design.dataView === "List View" ||
                            design.dataView === "Tile View") && (
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    pageCount={pageCount}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={1}
                                    breakAriaLabels={{
                                        forward: "Jump forward",
                                        backward: "Jump backward",
                                    }}
                                    onPageChange={changePage}
                                    containerClassName={"pagination"}
                                    disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                                    previousLinkClassName={"btn btn-outline-primary me-2"}
                                    nextLinkClassName={"btn btn-outline-primary ms-2"}
                                    activeLinkClassName={"customclass ms-2 me-2"}
                                    pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                                />
                            )}
                    </div>
                </div>
            </div>
            {isModal && (
                    <UpdateAdminStaff
                        open={isModal}
                        onClose={closed}
                        updateData={data[indexValue]}
                    />
                )}
                      {isModal1 && (
                    <PermissionsAdminStaff
                        open={isModal1}
                        onClose={closed1}
                        updateData={data[indexValue]}
                    />
                )}
            {loader}

        </div>
    );
};

export default ViewHomework;
