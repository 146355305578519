import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useUser } from "../../../../Components/UserContext";
import { formatInputDate } from "../../../../Components/dateFormate";
import { Link } from "react-router-dom";

const UpdateAdminStaff = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const [isValidMobile, setIsValidMobile] = useState(true);
    const [isValidMobile1, setIsValidMobile1] = useState(true);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(updateData)

    const [Bill, setBill] = useState({
        txtHead_Id: updateData.Head_Id || '',
        txtHead_Name: updateData.Head_Name || '',
        Initial: updateData.Initial || '',
        First_Name: updateData.First_Name || '',
        Middle_Name: updateData.Middle_Name || '',
        Last_Name: updateData.Last_Name || '',
        Gender: updateData.Gender || '',
        DOB: updateData.DOB || '',
        Email: updateData.Email || '',
        Telephone: updateData.Telephone || '',
        Mobile: updateData.Mobile || '',
        Address: updateData.Address || '',
        Role: updateData.Role || '',
        Photo: updateData.Photo || '',
        OTP: updateData.OTP || '',
        Is_Owner: updateData.Is_Owner || 0
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (!Bill.Initial) {
            errors.Initial = "Select Initial of Name";
        }
        if (!Bill.First_Name) {
            errors.First_Name = "Enter First Name";
        }
        if (!Bill.Mobile) {
            errors.Mobile = "Enter Mobile No";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) return;

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const formdata = new FormData();
        Object.keys(updateBill).forEach((key) => {
            formdata.append(key, updateBill[key]);
        });

        showLoader();
        axios
            .put(`${apiUrl}/updateAdminStaffData/${updateData?.UserId}`, formdata)
            .then((res) => {
                Swal.fire("Success!", "Admin Updated Successfully!!", "success").then((result) => {
                    if (result.isConfirmed) {
                        handleClose();
                    }
                });
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(
                    "Server Timeout",
                    "Server is Busy!!!, Please try again later.",
                    "error"
                );
                hideLoader();
            });
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div
                                    className="card shadow-none border border-300 my-4"
                                    data-component-card="data-component-card"
                                >
                                    <div className="card-body p-0">
                                        <div className="p-4 code-to-copy">
                                            <div className="row">
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="Branch">
                                                        Branch
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        name="txtHead_Id"
                                                        id="Branch"
                                                        disabled
                                                        value={Bill.txtHead_Id}
                                                        onChange={(e) => {
                                                            const selectedIndex = e.target.selectedIndex;
                                                            const selectedOption = e.target[selectedIndex];
                                                            setBill({
                                                                ...Bill,
                                                                txtHead_Id: selectedOption.value,
                                                                txtHead_Name: selectedOption.label,
                                                            });
                                                        }}
                                                    >
                                                        <option value={"All"}>All Branches</option>
                                                        <option value={userData?.Head_Id}>
                                                            {userData?.Head_Name}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="Initial">
                                                        Initial<span style={{ color: "Red" }}>*</span>
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        name="Initial"
                                                        id="Initial"
                                                        value={Bill.Initial}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value={""}>--Select--</option>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                        <option value="Miss.">Miss.</option>
                                                        <option value="Master.">Master.</option>
                                                    </select>
                                                    <span style={{ color: "Red" }}>{errors.Initial}</span>
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="First_Name">
                                                        First Name<span style={{ color: "Red" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        name="First_Name"
                                                        id="First_Name"
                                                        value={Bill.First_Name}
                                                        placeholder="Enter First Name"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span style={{ color: "Red" }}>{errors.First_Name}</span>
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="Middle_Name">
                                                        Middle Name
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        name="Middle_Name"
                                                        id="Middle_Name"
                                                        value={Bill.Middle_Name}
                                                        placeholder="Enter Middle Name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="Last_Name">
                                                        Last Name
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        name="Last_Name"
                                                        id="Last_Name"
                                                        value={Bill.Last_Name}
                                                        placeholder="Enter Last Name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="Gender">
                                                        Gender
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        name="Gender"
                                                        id="Gender"
                                                        value={Bill.Gender}
                                                        onChange={handleChange}
                                                    >
                                                        <option value={""}>--Select--</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="DOB">
                                                        Date of Birth
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="date"
                                                        name="DOB"
                                                        id="DOB"
                                                        value={formatInputDate(Bill.DOB)}
                                                        placeholder="DD-MM-YYYY"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label className="form-label" htmlFor="Email">
                                                        Email
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="email"
                                                        name="Email"
                                                        id="Email"
                                                        value={Bill.Email}
                                                        placeholder="name@example.com"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                <label className="form-label" htmlFor="Mobile">
                                                    Mobile<span style={{ color: "Red" }}>*</span>(For SMS)
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${isValidMobile ? "" : "is-invalid"
                                                        }`}
                                                    type="text"
                                                    name="Mobile"
                                                    id="Mobile"
                                                    value={Bill.Mobile}
                                                    required
                                                    maxLength={10}
                                                    placeholder="Enter Mobile"
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const numericInput = input.replace(/[^0-9]/g, "");
                                                        const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                            numericInput
                                                        );
                                                        setIsValidMobile(isValid);
                                                        if (isValid || numericInput === "") {
                                                            setBill({
                                                                ...Bill,
                                                                Mobile: numericInput,
                                                            });
                                                        }
                                                    }}
                                                />
                                                {!isValidMobile && (
                                                    <div className="invalid-feedback">
                                                        Invalid mobile number.
                                                    </div>
                                                )}
                                                <span style={{ color: "Red" }}>{errors.Mobile}</span>
                                            </div>

                                            <div className="mb-3 col-md-4">
                                                <label className="form-label" htmlFor="Telephone">
                                                    Alternate Mobile
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${isValidMobile1 ? "" : "is-invalid"
                                                        }`}
                                                    type="text"
                                                    name="Telephone"
                                                    id="Telephone"
                                                    value={Bill.Telephone}
                                                    maxLength={10}
                                                    placeholder="Enter Alternate Mobile"
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const numericInput = input.replace(/[^0-9]/g, "");
                                                        const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                            numericInput
                                                        );
                                                        setIsValidMobile1(isValid);
                                                        if (isValid || numericInput === "") {
                                                            setBill({
                                                                ...Bill,
                                                                Telephone: numericInput,
                                                            });
                                                        }
                                                    }}
                                                />
                                                {!isValidMobile1 && (
                                                    <div className="invalid-feedback">
                                                        Invalid mobile number.
                                                    </div>
                                                )}
                                            </div>
                                         
                                        
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label" htmlFor="Is_Owner">
                                                        Is Owner
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        name="Is_Owner"
                                                        id="Is_Owner"
                                                        value={Bill.Is_Owner}
                                                        onChange={handleChange}
                                                    >
                                                        <option value={0}>No</option>
                                                        <option value={1}>Yes</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="OTP">
                                                    App Password 
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        name="OTP"
                                                        id="OTP"
                                                        onChange={(e) => {
                                                            setBill({
                                                                ...Bill,
                                                                OTP: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className={Bill.Photo ? 'mb-3 col-md-5' : 'mb-3 col-md-6'}>
                                                    <label className="form-label" htmlFor="Photo">
                                                        Photo
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="file"
                                                        name="Photo"
                                                        id="Photo"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            setBill({
                                                                ...Bill,
                                                                Photo: file,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {Bill.Photo ? (
                                                    <div className="mb-3 col-md-1 mt-4">

<Link
    to={`${apiUrl}/Documents/Staff/${Bill.Photo}`}
    target="_blank" rel="noopener noreferrer"
    className="cursor-pointer text-primary"
    style={{ textDecoration: "none", marginLeft: "7px" }}
>
<i className="far fa-eye"></i>

</Link>
</div>
) : (
<>
</>
)}
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" htmlFor="Address">
                                                        Address
                                                    </label>
                                                    <textarea
                                                        className="form-control form-control-sm"
                                                        name="Address"
                                                        id="Address"
                                                        value={Bill.Address}
                                                        placeholder="Enter Address"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end gap-3">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        Update Admin
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {loader}
        </div>
    );
};

export default UpdateAdminStaff;
