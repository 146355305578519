import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../../Components/apiConfig";
import { useUser } from "../../../Components/UserContext";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const UpdateInstallment = ({ studentData , loadData}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const { Sr_No } = useParams();
  const [installment, setInstallment] = useState([]);
  const [updatedInstallment, setUpdatedInstallment] = useState([]);
  const [isProceed, setIsProceed] = useState(false);
  const [getDetails, setGetDetails] = useState({});
  const [FeesDetails, setFeesDetails] = useState({});
  const [courseName, setCourseName] = useState("");
  const [balanceFees, setBalanceFees] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  console.log(studentData)


  const fetchData = async () => {
    try {
      showLoader();

      // First API call to get installments
      const installmentResult = await axios.get(
        `${apiUrl}/getInstallmentsForUpdate?id=${Sr_No}&orgId=${userData?.Org_Id}`
      );
      setInstallment(installmentResult.data);
      setUpdatedInstallment(installmentResult.data);
      setIsProceed(true);

      // Second API call to get details
      const detailsResult = await axios.get(
        `${apiUrl}/GetDetails?id=${Sr_No}&orgId=${userData?.Org_Id}`
      );
      console.log(detailsResult?.data);
      const details = detailsResult?.data;
      setGetDetails(details);
      setCourseName(details.courseName);
      setBalanceFees(parseFloat(details.balanceAmount).toFixed(2));
      setTotalAmount(parseFloat(details.totalFees).toFixed(2));

      // Third API call to get student fees details to modify installment
      const feesData = await axios.get(
        `${apiUrl}/GetStudentFeesDetailsToModifyInstallment?id=${Sr_No}&orgId=${userData?.Org_Id}`
      );
      console.log(feesData.data);
      setFeesDetails(feesData.data);

      // Fourth API call to get student fees payment log
      const feesLogData = await axios.get(
        `${apiUrl}/getStudentFeesPaymentLog?OrgId=${userData?.Org_Id}&ID=${Sr_No}`
      );
      console.log(feesLogData.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      console.error(error);
    }
  };

  useEffect(() => {
    if (userData?.Org_Id) {
      fetchData();
    } else {
      setInstallment([]);
      setUpdatedInstallment([]);
      hideLoader();
    }
  }, [userData])

  const handleDueDateChange = (index, newDueDate) => {
    const newInstallment = [...updatedInstallment];
    newInstallment[index].Due_Date = newDueDate;
    setUpdatedInstallment(newInstallment);
  };

  const handleAmountChange = (index, newAmount) => {
    const newInstallment = [...updatedInstallment];
    newInstallment[index].Tuition_Amt = newAmount;
    setUpdatedInstallment(newInstallment);
  };

  // useEffect(() => {
  //   if (discountAmount) {
  //     getTotalTuitionAmount();
  //   }
  // }, [discountAmount]);

  const getTotalTuitionAmount = () => {
    const total = updatedInstallment.reduce(
      (total, inst) => total + parseFloat(inst.Tuition_Amt),
      0
    );
    return total;
  };

  const handleAddRow = () => {
    const newInstallment = {
      Due_Date: null,
      Tuition_Amt: 0,
      Payment_Status: "Unpaid",
      flag: "New",
    };
    setUpdatedInstallment([...updatedInstallment, newInstallment]);
  };

  const handleRemoveRow = () => {
    const paidInstallmentsCount = updatedInstallment.filter(item => item.Payment_Status === 'Paid').length;
    if (paidInstallmentsCount < updatedInstallment.length) {
      setUpdatedInstallment(updatedInstallment.slice(0, -1));
    }
  };

  const handleDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    getTotalTuitionAmount();
    if (discount > parseFloat(getDetails.balanceAmount)) {
      Swal.fire("Error", "Discount amount cannot exceed balance amount!", "error");
      setBalanceFees(parseFloat(getDetails.balanceAmount).toFixed(2));
      setTotalAmount(parseFloat(getDetails.totalFees).toFixed(2));
      setDiscountAmount(0);
    } else {
      setDiscountAmount(discount);
      const newTotalAmount = parseFloat(getDetails.totalFees) - discount;
      const newBalanceFees = parseFloat(getDetails.balanceAmount) - discount;
      setTotalAmount(newTotalAmount.toFixed(2));
      setBalanceFees(newBalanceFees.toFixed(2));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateFees = {
      Installment: updatedInstallment,
      Bal_SaleOfBooks: FeesDetails.Bal_SaleOfBooks,
      Next_Installment_Date: FeesDetails.Next_Installment_Date,
      Next_Installment_Amt: FeesDetails.Next_Installment_Amt,
      BalanceFees: balanceFees,
      TotalAmount: totalAmount,
      DiscountAmount: discountAmount,
      Std_Id:studentData.Std_Id,
      Std_Name:studentData.Std_Name,
      Student_Id:studentData.Student_Id,
      UUID:studentData.UUID,
      Name:studentData.Name,
      Course_Id:studentData.Course_Id,
      Course_Name:studentData.Course_Name,
      Batch_Id:studentData.Batch_ID,
      Batch_Name:studentData.Batch_Name,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.Org_Name,
      User_Name: userData.UserName,
    };

    showLoader();
    axios
      .put(`${apiUrl}/modifyInstallment/${Sr_No}`, updateFees)
      .then((res) => {
        Swal.fire({
          title: "Success!",
          text: "Installment Modified Successfully!!",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // navigate("/viewEnquiry");
            fetchData();
      setDiscountAmount(0);
      loadData();
          }
        });
        hideLoader();
      })
      .catch((err) => {
        console.error(err);
        Swal.fire("Server Timeout", "Server is Busy!!!, Please try again later.", "error");
        hideLoader();
      });
  };

  return (
    <div>
      <div className="card-body" style={{ paddingTop: "5px" }}>
        <div className="row justify-content-center mt-2">
          <div className="col-3">
            <label htmlFor="example-month" className="form-label">
              Course Enrolled :
            </label>
            <input
              type="text"
              className="form-control"
              id="example-month"
              value={courseName||0}
              disabled
            />
          </div>
          <div className="col-3">
            <label htmlFor="example-year1" className="form-label">
              Balance Fees :
            </label>
            <input
              type="text"
              className="form-control"
              id="example-year1"
              value={balanceFees||0}
              disabled
            />
          </div>
          <div className="col-3">
            <label htmlFor="example-year2" className="form-label">
              Total Amount :
            </label>
            <input
              type="text"
              className="form-control"
              id="example-year2"
              value={totalAmount||0}
              disabled
            />
          </div>
          <div className="col-3">
            <label htmlFor="example-year3" className="form-label">
              Discount Amount :
            </label>
            <input
              type="text"
              className="form-control"
              id="example-year3"
              value={discountAmount}
              onChange={handleDiscountChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        {isProceed && (
          <table className="table table-bordered border-primary table-hover">
            <thead>
              <tr>
                <th scope="col" className="text-center" style={{ width: "25%" }}>
                  #
                </th>
                <th scope="col" className="text-center" style={{ width: "25%" }}>
                  Due Date
                </th>
                <th scope="col" className="text-center" style={{ width: "25%" }}>
                  Amount
                </th>
                <th scope="col" className="text-center" style={{ width: "25%" }}>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {updatedInstallment.map((inst, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>
                    <input
                      type="date"
                      className="form-control form-control-sm mt-2"
                      style={{ width: "100%" }}
                      value={
                        inst.Due_Date ? dayjs(inst.Due_Date).format("YYYY-MM-DD") : ""
                      }
                      onChange={(e) => {
                        handleDueDateChange(index, e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-2"
                      style={{ width: "100%" }}
                      value={parseFloat(inst.Tuition_Amt)}
                      disabled={inst.Payment_Status === "Paid"}
                      onChange={(e) => {
                        if (inst.Payment_Status !== "Paid") {
                          handleAmountChange(index, parseFloat(e.target.value) || 0);
                        }
                      }}
                    />
                  </td>
                  <td className="text-center">{inst.Payment_Status}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-center">
                  <button className="btn btn-secondary" onClick={handleAddRow}>
                    Add
                  </button>
                </th>
                <th className="text-center">Total</th>
                <th className="text-center">
                  {getTotalTuitionAmount().toFixed(2)}
                </th>
                <th className="text-center">
                  <button className="btn btn-secondary" onClick={handleRemoveRow}>
                    Remove
                  </button>
                </th>
              </tr>
            </tfoot>
          </table>
        )}
        <div className="row mt-3">
          <div className="col-12 text-center">
            <button
              type="button"
              className="btn btn-sm btn-primary mb-2"
              onClick={handleSubmit}
            >
              Modify Installment
            </button>
          </div>
        </div>
      </div>

      {loader}
    </div>
  );
};

export default UpdateInstallment;
