import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate'
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Select from "react-select";

const UpdateStatusModal = ({ open, onClose, updateData, opration }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [StdOptions, setStdOptions] = useState([]);
    const [SubOptions, setSubOptions] = useState([]);


    const [Bill, setBill] = useState({
        Std_Name: updateData?.std_Name || "",
        Std_Id: parseInt(updateData?.Std_ID) || "",
        Sub_Name: updateData?.sub_name || "",
        Sub_Id: updateData?.sub_id || "",
        Topic: updateData?.Topic_Name
            ? (updateData.Topic_Name.split(";") || []).map(subject => {
                const [Topic_Id, Topic_Name] = subject.split(",");
                return {
                    Topic_Id: parseInt(Topic_Id) || "",
                    Topic_Name: Topic_Name || ""
                };
            })
            : [{ Topic_Id: "", Topic_Name: "" }]
    });


    useEffect(() => {
        fetchData();
    }, [userData, Bill?.Std_Id]);


    const fetchData = async () => {
        try {
            showLoader();


            if (userData && userData.Org_Id && userData?.Head_Id) {
                const std = await axios.get(
                    `${apiUrl}/${opration==='added'? 'getStdMaster':'getStdMasterForTopic'}?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
                );


                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
                console.log(getStd);
            }

            if (userData && userData.Org_Id && Bill?.Std_Id) {
                const sub = await axios.get(
                    `${apiUrl}/${opration==='added'? 'getSubMaster':'getSubjectMasterForTopic'}?OrgId=${userData.Org_Id}&Std_Id=${Bill?.Std_Id}`
                );


                const getSub = sub.data.map((category) => ({
                    label: category.Sub_Name,
                    value: category.Sub_Id,
                }));
                setSubOptions(getSub);
                console.log(getSub);
            }

            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };



    console.log(Bill.Topic);

    const deleteUser = (Id, Name,index) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id,Name, index);
            }
        });
    };
    async function handleDelete(Id, Name, index) {
        showLoader();
        // console.log(Id)
        const obj = {
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            User_Name: userData?.UserName,
            Name:Name
        };

        try {
            await axios.delete(`${apiUrl}/deleteTopic/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Topic record has been deleted.", "success");
            handleRemove(index);
        } catch (error) {
            console.error("Error while deleting Topic record:", error);
            Swal.fire("Error", "Failed to delete Topic record.", "error");
        } finally {
            hideLoader();
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
       
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            Display_Name: userData?.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateTopic`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Topic Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                        Swal.fire({
                            title: "Error",
                            text: err.response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        console.error(err);
                        Swal.fire(
                            "Server Timeout",
                            "Server is Busy!!!, Please try again later.",
                            "error"
                        );
                    }
                    hideLoader();

                });
        } else {
            setErrors(errors);
        }
    };

    const handleRemove = (indexToRemove) => {
        const newSubjects = [...Bill.Topic];
        newSubjects.splice(indexToRemove, 1);
        setBill({ ...Bill, Topic: newSubjects });
    };

    const handleReplicate = () => {

        const newSubjects = [...Bill.Topic];
        newSubjects.push({ Topic_Id: '', Topic_Name: '' });
        setBill({ ...Bill, Topic: newSubjects });
    };

    const handleInputChange = (index, fieldName, value) => {
        const newSubjects = [...Bill.Topic];
        newSubjects[index] = { ...newSubjects[index], [fieldName]: value };
        setBill({ ...Bill, Topic: newSubjects });
    };

    const handleStdChange = (selectedOption) => {
        setBill({
            ...Bill,
            Std_Id: selectedOption ? selectedOption.value : '',
            Std_Name: selectedOption ? selectedOption.label : '',
        });
    };

    const handleSubChange = (selectedOption) => {
        setBill({
            ...Bill,
            Sub_Id: selectedOption ? selectedOption.value : '',
            Sub_Name: selectedOption ? selectedOption.label : '',
        });
    };

    function checkOperation(operation, Bill) {
        if (operation === 'added') {
          return true;
        }
        if (operation === 'add' && Bill.Std_Id === "") {
          return true;
        }
        return false;
      }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{opration === 'add' ? "Add Topic" : "Modify Topic"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="Std_Name">
                                        Standard
                                    </label>
                                    <Select
                                        name="Std_Name"
                                        id="Std_Name"
                                        isDisabled={opration === 'added'}
                                        value={StdOptions.find(option => option.value === Bill.Std_Id)}
                                        onChange={handleStdChange}
                                        options={StdOptions}
                                        isClearable
                                        placeholder="--Select--"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="Sub_Name">
                                        Subject
                                    </label>
                                    <Select
                                        name="Sub_Name"
                                        id="Sub_Name"
                                        isDisabled={checkOperation(opration, Bill)}
                                        value={SubOptions.find(option => option.value === Bill.Sub_Id)}
                                        onChange={handleSubChange}
                                        options={SubOptions}
                                        isClearable
                                        placeholder="--Select--"
                                    />
                                </div>
                            </div>
                            {Bill.Topic.map((subject, index) => (
                                <div key={index} className="row">
                                    <div className="mb-3 col-md-10">
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name={`Topic_${index}`}
                                            id={`Topic_${index}`}
                                            required
                                            placeholder="Enter Topic Name"
                                            value={subject.Topic_Name}
                                            onChange={(e) => {
                                                handleInputChange(index, 'Topic_Name', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        {Bill.Topic.length > 1 && (
                                            <Tooltip title="Remove">
                                                <RemoveCircleOutlineIcon
                                                        onClick={() => {
                                                            if (subject?.Topic_Id && subject?.Topic_Name) {
                                                                deleteUser(subject.Topic_Id, subject.Topic_Name, index);
                                                            } else {
                                                                handleRemove(index);
                                                            }
                                                        }}
                                                        
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "relative",
                                                        top: "4px",
                                                        left: "30px",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div
                                className="row"
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <div className="col-md-2">
                                    <Tooltip title="Add">
                                        <AddCircleOutlineIcon
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                                top: "-44px",
                                            }}
                                            onClick={handleReplicate}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
