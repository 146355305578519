import React, { useEffect, useState } from "react";
import $ from "jquery";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../DropDowns/DropDown";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import UpdateEnquiryFollowup from "./UpdateEnquiryFollowup";
import "./toggle.css";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReactPaginate from 'react-paginate';
import AgGridTable from "./AgGridTable";



const ViewEnquiry = () => {
    const [table, setTable] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [isModal, setIsModal] = useState(false);
    const [indexValue, setIndex] = useState();
    const [data, setEnquiryData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [AssignedToOptions, setAssignedToOptions] = useState([]);
    const [selectedstatus, setSelectedstatus] = useState("0");
    const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
    const [toDate, setToDate] = useState(dayjs().endOf("month"));
    const [AssignedTo, setAssignedTo] = useState("0");
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: ""
    });


    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView
            })
        }
    }, [userData, userData?.Head_Id]);

    const fetchData = async () => {
        try {
            showLoader();
            const result = await axios.get(
                `${apiUrl}/getEnquiryWithFilter?OrgId=${userData?.Org_Id}&fromDate=${fromDate.format(
                    "YYYY-MM-DD"
                )}&toDate=${toDate.format("YYYY-MM-DD")}&Status=${selectedstatus}&Assigned_To=${AssignedTo ? AssignedTo : '0'}&Head_Id=${userData.Head_Id ? userData.Head_Id : '0'}`
            );
            setEnquiryData(result.data || []);
            setFilteredData(result.data || []);

            setTable(true);
            if (userData && userData.Org_Id && userData?.Head_Id) {
                const getAssignedTo = await axios.get(
                    `${apiUrl}/getAssignedTo?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}`
                );


                const option = getAssignedTo.data.map((category) => ({
                    label: category.Name,
                    value: category.UserId,
                }));
                setAssignedToOptions(option);
            }
            //console.log(result.data);
            hideLoader();
        } catch (error) {
            console.error("Error fetching headmaster data:", error);
            hideLoader();
        }
    };

    //Search Box    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        if (data.length > 0) {
            const newfilteredData = data.filter((row) =>
                row?.First_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row?.Middle_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row?.Last_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row?.Course_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row?.Assigned_To.toLowerCase().includes(searchTerm.toLowerCase()) ||
                formatDate(row?.Next_Followup_On).includes(searchTerm.toLowerCase())
            )
            setFilteredData(newfilteredData);
        }
    }, [searchTerm]);

    useEffect(() => {
        const badgeCellRenderer = (props) => {
            return <span className={`badge badge-phoenix ${props.Status === "Interested" ? "badge-phoenix-info" :
                props.Status === "Not Interested" ? "badge-phoenix-warning" :
                    props.Status === "Converted" ? "badge-phoenix-success" :
                        "badge-phoenix-primary"
                }`}>
                {props.Status}
            </span>;
        };
        const options = (props) => {
            return <div className="dropdown font-sans-serif">
                <button style={{ padding: 0 }} className="btn btn-link" id="dropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-v" style={{ fontSize: "14px", color: "gray" }}></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                    {props.Status !== 'Converted' && (
                        <div>
                            <Link onClick={(e) => { e.preventDefault(); showModal(props.rowIndex); }} className="dropdown-item cursor-pointer" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <i className="far fa-edit" style={{ color: "blue", fontSize: "14px" }}></i>
                                <span> Followup</span>
                            </Link>
                            <Link to={`/addStudent/convert/${props.data.Enq_Id}`} className="dropdown-item cursor-pointer" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <i className="far fa-check-circle" style={{ color: "green", fontSize: "14px" }}></i>
                                <span> Convert</span>
                            </Link>
                            {/* <div className="dropdown-divider"></div> */}
                        </div>
                    )}

                    <Link onClick={() => { deleteUser(props.data.Enq_Id); }} className="dropdown-item cursor-pointer" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                        <span> Delete</span>
                    </Link>
                </div>
            </div>;
        };
        const newAgcolumns = [
            { field: "Name" },
            { field: "Mobile" },
            { field: "Course" },
            { field: "Assigned To" },
            { field: "Followup Date" },
            //{ field: "Status", cellRenderer: badgeCellRenderer },
            { field: "Status" },
            { headerName: "", field: "", cellRenderer: options, width: 50, maxWidth: 50 }
        ]
        setAgcolumns(newAgcolumns)
    }, []);
    useEffect(() => {
        if (filteredData.length > 0) {
            const newAgrows = filteredData.map((row) => ({
                Name: `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
                Mobile: row.Mobile1 ? row.Mobile1 : "--",
                Course: row.Course_Name,
                "Assigned To": row.Assigned_To,
                "Followup Date": formatDate(row.Next_Followup_On),
                Status: row.Status,
                Enq_Id: row.Enq_Id
            }));

            setAgrows(newAgrows);
        }
    }, [filteredData]);

    const deleteUser = (Id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id);
            }
        });
    };
    async function handleDelete(Id) {
        showLoader();
        const obj = {
            User_Id: userData?.UserId,
            Org_Id: userData?.Org_Id,
            Org_Name: userData?.Org_Name,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Role: userData?.Role,
            LoginUsername: userData?.Username,
            User_Name: userData?.UserName
        };

        try {
            await axios.delete(`${apiUrl}/deleteEnquiry/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Enquiry has been Deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting enquiry record:", error);
            Swal.fire("Error", "Failed to delete enquiry record.", "error");
        } finally {
            hideLoader();
        }
    }


    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };



    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Enquiry", 20, 10);

        const columns = [
            "Name",
            "Mobile",
            "Course",
            "Assigned To",
            "Followup Date",
        ];

        const rows = filteredData.map((row) => [
            `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
            row.Mobile1,
            row.Course_Name ? row.Course_Name : "--",
            row.Assigned_To ? row.Assigned_To : "--",
            row.Next_Followup_On ? formatDate(row.Next_Followup_On) : "--",
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Enquiry.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Name",
            "Mobile",
            "Course",
            "Assigned To",
            "Followup Date",
        ];
        const rows = [columns];
        filteredData.forEach((row) => {
            rows.push([
                `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
                row.Mobile1,
                row.Course_Name ? row.Course_Name : "--",
                row.Assigned_To ? row.Assigned_To : "--",
                row.Next_Followup_On ? formatDate(row.Next_Followup_On) : "--",
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Enquiry");

        // Buffer
        //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, "Enquiry.xlsx");
    };

    const showModal = (index) => {
        setIndex(index);
        setIsModal(true);
    };
    const closed = () => {
        setIsModal(false);
        fetchData();
    };

    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true
        })
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true
        })
    }

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-3">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black">{`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`}</h5>
                                <p className="mb-0 profiledetail lh-1" style={{ fontSize: "13px" }}>Mobile : <span className="ms-1 text-1100">{row.Mobile1}</span></p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Course : <span className="fw-semi-bold  ms-1"> {row.Course_Name}</span></p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Followup Date : <span className="fw-semi-bold  ms-1"> {formatDate(row.Next_Followup_On)}</span></p>
                                <p className="mb-0 fw-semi-bold listViwP text-truncate" >Assigned to : <span className="fw-semi-bold  ms-1">{row.Assigned_To}</span></p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <p className="mb-0 fw-semi-bold listViwP text-truncate" >
                                <span className="fw-semi-bold  ms-1">
                                    <span className={`badge badge-phoenix ${row.Status === "Interested" ? "badge-phoenix-info" :
                                        row.Status === "Not Interested" ? "badge-phoenix-warning" :
                                            row.Status === "Converted" ? "badge-phoenix-success" :
                                                "badge-phoenix-primary"
                                        }`}>
                                        {row.Status}
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div className="col-md-1">
                            <div className="font-sans-serif">
                                <button style={{ padding: 0 }} className="btn btn-link" id="dropdownMenuLink" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v" style={{ fontSize: "14px", color: "gray" }}></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                    {row.Status !== 'Converted' && (
                                        <div>
                                            <Link onClick={(e) => { e.preventDefault(); showModal(index); }} className="dropdown-item cursor-pointer">
                                                <i className="far fa-edit" style={{ color: "blue", fontSize: "14px" }}></i>
                                                <span> Followup</span>
                                            </Link>
                                            <Link to={`/addStudent/convert/${row.Enq_Id}`} className="dropdown-item cursor-pointer">
                                                <i className="far fa-check-circle" style={{ color: "green", fontSize: "14px" }}></i>
                                                <span> Convert</span>
                                            </Link>
                                            <div className="dropdown-divider"></div>
                                        </div>
                                    )}

                                    <Link onClick={() => { deleteUser(row.Enq_Id); }} className="dropdown-item cursor-pointer">
                                        <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                                        <span> Delete</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>)}
                {design.dataView === "Tile View" && (
                    < div key={index} className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4">
                        <div className="card h-100 hover-actions-trigger">
                            <div className="card-body" style={{ padding: "15px" }}>
                                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                    <div className="row justify-content-between me-1" style={{ flex: "auto", marginTop: "8px" }}>
                                        <div className="col-11 pe-1">
                                            <h6 className="mb-2 line-clamp-1 lh-sm  me-1">{`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`}</h6>
                                            <p className="mb-0 profiledetail lh-1" style={{ fontSize: "13px" }}>Mobile : <span className="ms-1 text-1100">{row.Mobile1}</span></p>
                                        </div>
                                        <div className="end-0 col-1 p-0 " >
                                            <button style={{ padding: 0 }} className="btn btn-link" id="dropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-ellipsis-v" style={{ fontSize: "14px", color: "gray" }}></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                                {row.Status !== 'Converted' && (
                                                    <div>
                                                        <Link onClick={(e) => { e.preventDefault(); showModal(index); }} className="dropdown-item cursor-pointer">
                                                            <i className="far fa-edit" style={{ color: "blue", fontSize: "14px" }}></i>
                                                            <span> Followup</span>
                                                        </Link>
                                                        <Link to={`/addStudent/convert/${row.Enq_Id}`} className="dropdown-item cursor-pointer">
                                                            <i className="far fa-check-circle" style={{ color: "green", fontSize: "14px" }}></i>
                                                            <span> Convert</span>
                                                        </Link>
                                                        <div className="dropdown-divider"></div>
                                                    </div>
                                                )}

                                                <Link onClick={() => { deleteUser(row.Enq_Id); }} className="dropdown-item cursor-pointer">
                                                    <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                                                    <span> Delete</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-success"><span className="badge-label">completed</span></span> */}
                                <div className="d-flex align-items-center mb-2">
                                    {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail text-truncate lh-1">Course : <span className="fw-semi-bold ms-1"> {row.Course_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2  ">
                                    {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail lh-1">Assigned to : <span className="ms-1 text-1100">{row.Assigned_To}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                    {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                    <p className="mb-0 profiledetail lh-1">Followup Date : <span className="ms-1 text-1100">{formatDate(row.Next_Followup_On)}</span></p>
                                </div>
                                <div className="d-flex justify-content-between text-700 fw-semi-bold " style={{ float: "right" }} >
                                    <p className="mb-0" style={{ fontSize: "14px", color: "#a09aa3", lineHeight: "5px" }}>
                                        <span className={`badge badge-phoenix ${row.Status === "Interested" ? "badge-phoenix-info" :
                                            row.Status === "Not Interested" ? "badge-phoenix-warning" :
                                                row.Status === "Converted" ? "badge-phoenix-success" :
                                                    "badge-phoenix-primary"
                                            }`}>
                                            {row.Status}
                                        </span>
                                    </p>
                                    {/* <p className="mb-2 text-1100">100%</p> */}
                                </div>
                            </div>
                        </div>
                    </div >)
                }

            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="content">
            <div id="wrapper" className={`${design.filterToggle ? '' : 'toggled'}`}>
                <div className="container-fluid">
                    {/* Sidebar */}
                    <div id="sidebar-wrapper">
                        <div className="row g-4">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div className="card shadow-none border border-300 my-2" data-component-card="data-component-card">
                                    <div className="row justify-content-center d-flex p-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Branch">
                                                        Branch
                                                    </label>
                                                    <HeadMasterDropDown label="Branch" name="Branch" id="Branch"
                                                    //onChange={(e) => setBranch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        From Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={fromDate.format("YYYY-MM-DD")}
                                                        onChange={(date) => setFromDate(dayjs(date.target.value))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        To Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={toDate.format("YYYY-MM-DD")}
                                                        onChange={(date) => setToDate(dayjs(date.target.value))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        Status :
                                                    </label>
                                                    <select className="form-select form-select-sm" id="example-select"
                                                        onChange={(e) => setSelectedstatus(e.target.value)}>
                                                        <option value="0">All</option>
                                                        <option value="Interested">Interested</option>
                                                        <option value="Not Interested">Not Interested</option>
                                                        <option value="Converted">Converted</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        Assigned To :
                                                    </label>
                                                    <Dropdown options={AssignedToOptions} name="Assigned_To"
                                                        id="Assigned_To" onChange={(e) => setAssignedTo(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center p-2">
                                        <div className="col-md-4 mb-2">
                                            <button type="button" className="btn btn-primary btn-sm text-center"
                                                onClick={handleDateRangeFilter}>
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="row justify-content-between">
                            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                                Track Enquiry
                                <a
                                    className="anchorjs-link "
                                    aria-label="Anchor"
                                    href="#basic-form"
                                    style={{ paddingLeft: "0.375em" }}
                                ></a>
                            </h4>
                            <div className="col-md-12 text-center mt-3">
                                <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..." style={{ float: "left", display: "inline-block", width: "200px" }}></input>
                                <Link to={"/addStudentEnquiry"} style={{ float: "right" }}>
                                    <button className="btn btn-primary btn-sm text-center">
                                        Add
                                    </button>
                                </Link>
                                <button type="button" className="btn bg-200 filterButton collapsed mr-2" data-toggle="collapse" onClick={toggle} data-target="#navbar" aria-expanded="false" aria-controls="navbar" style={{ float: "right", marginRight: "10px" }}>
                                    <i className="fa fa-filter"></i>
                                </button>
                                <button className="form-control dropdown-toggle viewButton  mb-2" id="dropdownMenuLink" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ float: "right", marginRight: "10px", width: "auto", padding: "4px 10px" }}>
                                    <>
                                        {design.dataView === "Table View" &&
                                            (<TableChartOutlinedIcon />)}
                                    </>
                                    <>
                                        {design.dataView === "List View" &&
                                            (<FormatListBulletedIcon />)}
                                    </>
                                    <>
                                        {design.dataView === "Tile View" &&
                                            (<GridViewIcon />)}
                                    </>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                    <Link onClick={(e) => {
                                        setDesign({
                                            ...design,
                                            dataView: "Table View"
                                        })
                                        setFilterData({
                                            ...filterData,
                                            dataView: "Table View"
                                        })
                                    }} className="dropdown-item cursor-pointer">
                                        <TableChartOutlinedIcon />
                                        <span> Table View</span>
                                    </Link>
                                    <Link onClick={() => {
                                        setDesign({
                                            ...design,
                                            dataView: "List View"
                                        })
                                        setFilterData({
                                            ...filterData,
                                            dataView: "List View"
                                        })
                                    }} className="dropdown-item cursor-pointer">
                                        <FormatListBulletedIcon />
                                        <span> List View</span>
                                    </Link>
                                    <Link onClick={() => {
                                        setDesign({
                                            ...design,
                                            dataView: "Tile View"
                                        })
                                        setFilterData({
                                            ...filterData,
                                            dataView: "Tile View"
                                        })
                                    }} className="dropdown-item cursor-pointer">
                                        <GridViewIcon />
                                        <span> Tile View</span>
                                    </Link>
                                </div>


                                <OverlayTrigger delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (<Tooltip {...props}>Export to PDF</Tooltip>)}
                                    placement="bottom">
                                    <Button variant="" onClick={() => DownloadPDF()} style={{ float: "right", padding: "5px 10px 5px 5px" }}>
                                        <i className="fas fa-file-pdf" style={{ fontSize: "25px" }}></i>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (<Tooltip {...props}>Export to Excel</Tooltip>)}
                                    placement="bottom">
                                    <Button variant="" onClick={() => DownloadExcel()} style={{ float: "right", padding: "5px 5px 5px 10px" }}>
                                        <i className="fas fa-file-excel" style={{ fontSize: "25px" }}></i>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                {filteredData.length === 0 && (<h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found For The Selected Date Range!!</h5>)}
                            </div>
                            {design.dataView === "Table View" && (
                                <div className={filteredData.length === 0 ?"d-none":"col-12 col-xl-12 order-1 order-xl-0"}>
                                    {Agrows.length > 0 && (
                                        <AgGridTable row={Agrows} column={Agcolumns} />
                                    )}
                                </div>)}
                            {design.dataView === "Tile View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">
                                        {displayData}
                                    </div>
                                </div>
                            )}
                            {design.dataView === "List View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                            <div className="card h-100 hover-actions-trigger">
                                                <div className="card-body" style={{ paddingTop: "5px" }}>
                                                    {displayData}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(design.dataView === "List View" || design.dataView === "Tile View") && (
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    pageCount={pageCount}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={1}
                                    breakAriaLabels={{ forward: 'Jump forward', backward: 'Jump backward' }}
                                    onPageChange={changePage}
                                    containerClassName={'pagination'}
                                    disabledLinkClassName={'btn btn-phoenix-secondary text-black'}
                                    previousLinkClassName={'btn btn-outline-primary me-2'}
                                    nextLinkClassName={'btn btn-outline-primary ms-2'}
                                    activeLinkClassName={'customclass ms-2 me-2'}
                                    pageLinkClassName={'btn btn-outline-primary ms-2 me-2'}
                                />)}
                        </div>
                    </div>
                </div>

                {isModal && (
                    <UpdateEnquiryFollowup
                        open={isModal}
                        onClose={closed}
                        enquiryData={data[indexValue]}
                    />
                )}
                {loader}
            </div>
        </div>
    );
};

export default ViewEnquiry;
