import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate'
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Select from 'react-select';


const UpdateStatusModal = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [StdOptions, setStdOptions] = useState([]);



    useEffect(() => {
        fetchData();
    }, [userData]);


    const fetchData = async () => {
        try {
            showLoader();


            if (userData && userData.Org_Id && userData?.Head_Id) {
                const std = await axios.get(
                    `${apiUrl}/getStdMasterForSubject?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
                );


                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
                //console.log(getStd);
            }

            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Std_Name: "",
        Std_Id: "",
        Subject: [{ Sub_Id: '', Sub_Name: '' }]
    });
    console.log(Bill);

    console.log(Bill.Subject);

    const handleSubmit = (event) => {
        event.preventDefault();
       
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addSubject`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Subject Added Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                        Swal.fire({
                            title: "Error",
                            text: err.response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        console.error(err);
                        Swal.fire(
                            "Server Timeout",
                            "Server is Busy!!!, Please try again later.",
                            "error"
                        );
                    }
                    hideLoader();

                });
        } else {
            setErrors(errors);
        }
    };
    const handleRemove = (indexToRemove) => {
        const newSubjects = [...Bill.Subject];
        newSubjects.splice(indexToRemove, 1);
        setBill({ ...Bill, Subject: newSubjects });
    };

    const handleReplicate = () => {

        const newSubjects = [...Bill.Subject];
        newSubjects.push({ Sub_Id: '', Sub_Name: '' });
        setBill({ ...Bill, Subject: newSubjects });
    };

    const handleInputChange = (index, fieldName, value) => {
        const newSubjects = [...Bill.Subject];
        newSubjects[index] = { ...newSubjects[index], [fieldName]: value };
        setBill({ ...Bill, Subject: newSubjects });
    };


    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Subjects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className={"mb-3 col-md-12"}>
                                <label className="form-label" htmlFor="Std_Name">
                                    Standard
                                </label>
                                <Select
                                    inputId="Std_Name"
                                    value={StdOptions.find(option => option.value === Bill.Std_Id) || null}
                                    onChange={(selectedOption) => {
                                        setBill({
                                            ...Bill,
                                            Std_Id: selectedOption ? selectedOption.value : '',
                                            Std_Name: selectedOption ? selectedOption.label : ''
                                        });
                                    }}
                                    options={StdOptions}
                                    placeholder="--Select--"
                                    isClearable
                                />
                            </div>
                            {Bill.Subject.map((subject, index) => (
                                <div key={index} className="row">
                                    <div className="mb-3 col-md-10">
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name={`Subject_${index}`}
                                            id={`Subject_${index}`}
                                            required
                                            placeholder="Enter Subject Name"
                                            value={subject.Sub_Name}
                                            onChange={(e) => {
                                                handleInputChange(index, 'Sub_Name', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        {Bill.Subject.length > 1 && (
                                            <Tooltip title="Remove">
                                                <RemoveCircleOutlineIcon
                                                    onClick={() => handleRemove(index)}
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "relative",
                                                        top: "4px",
                                                        left: "30px",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div
                                className="row"
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <div className="col-md-2">
                                    <Tooltip title="Add">
                                        <AddCircleOutlineIcon
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                                top: "-44px",
                                            }}
                                            onClick={handleReplicate}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
