import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from "../../../../Components/dateFormate";
import { FaEye } from "react-icons/fa";
import Switch from "react-switch";

const WhatsappModal = ({ open, onClose, updateData }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const handleClose = () => {
    setShow(false);
    onClose();
  };
  console.log(updateData);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const [Bill, setBill] = useState({
    ...updateData,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleSwitchChange = (index, checked) => {
    const updatedPopup = [...Bill.popup];
    updatedPopup[index].Status = checked ? 1 : 0;
    setBill((prevBill) => ({
      ...prevBill,
      popup: updatedPopup,
    }));
  };

  console.log(Bill);

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const updateBill = {
      ...Bill,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(
          `${apiUrl}/submitWpDetails`,
          updateBill
        )
        .then((res) => {
          Swal.fire({
            title: "Success!",
            text:  "Whatsapp Message Settings Updated Successfully!!",
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.isConfirmed) {
              handleClose();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Whatsapp Message Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="mobile1">
                  Sender Mobile No
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="mobile1"
                  id="mobile1"
                  value={Bill.mobile1}
                  placeholder="Enter Sender Mobile No"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="mobile1_key1">
                  Instance Id
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="mobile1_key1"
                  id="mobile1_key1"
                  value={Bill.mobile1_key1}
                  placeholder="Enter Instance Id"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="mobile1_key2">
                  Access Token
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="mobile1_key2"
                  id="mobile1_key2"
                  value={Bill.mobile1_key2}
                  placeholder="Enter Access Token"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Category_Name">
                  Sender Mobile No
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Category_Name"
                  id="Category_Name"
                  value={Bill.Category_Name}
                  placeholder="Enter Sender Mobile No"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Category_Name">
                  Instance Id
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Category_Name"
                  id="Category_Name"
                  value={Bill.Category_Name}
                  placeholder="Enter Instance Id"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Category_Name">
                  Access Token
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Category_Name"
                  id="Category_Name"
                  value={Bill.Category_Name}
                  placeholder="Enter Access Token"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Category_Name">
                  Sender Mobile No
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Category_Name"
                  id="Category_Name"
                  value={Bill.Category_Name}
                  placeholder="Enter Sender Mobile No"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Category_Name">
                  Instance Id
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Category_Name"
                  id="Category_Name"
                  value={Bill.Category_Name}
                  placeholder="Enter Instance Id"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="Category_Name">
                  Access Token
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Category_Name"
                  id="Category_Name"
                  value={Bill.Category_Name}
                  placeholder="Enter Access Token"
                  onChange={handleChange}
                />
                <span style={{ color: "Red" }}></span>
              </div>

              <div className="col-md-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-4"
                  data-component-card="data-component-card"
                >
                  <div className="card-body p-0">
                    <div
                      className="code-to-copy"
                      style={{
                        paddingLeft: 16,
                        paddingTop: 3,
                        paddingRight: 16,
                      }}
                    >
                      <div className="row">
                        {Bill.popup.map((template, index) => (
                          <div
                            key={template.Row_Id}
                            className="col-md-6 form-group"
                            style={{
                              borderBottom: "1px solid lightgrey",
                              paddingTop: 5,
                              paddingBottom: 7,
                              marginBottom: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label>{template.Title}</label>
                            <Switch
                              checked={template.Status === 1}
                              onChange={(checked) =>
                                handleSwitchChange(index, checked)
                              }
                              onColor="#007bff"
                              offColor="#6c757d"
                              onHandleColor="#fff"
                              offHandleColor="#fff"
                              handleDiameter={15}
                              uncheckedIcon={
                                <div style={{ paddingLeft: 7, color: "white" }}>
                                  Off
                                </div>
                              }
                              checkedIcon={
                                <div style={{ paddingLeft: 7, color: "white" }}>
                                  On
                                </div>
                              }
                              height={25}
                              width={100}
                              className="react-switch"
                              id={`switch-${template.Row_Id}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-4"
                  data-component-card="data-component-card"
                >
                  <div className="card-body p-0">
                    <div
                      className="code-to-copy"
                      style={{
                        paddingLeft: 16,
                        paddingTop: 3,
                        paddingRight: 16,
                      }}
                    >
                      <div className="row">
                        <div
                          className="col-md-4 form-group"
                          style={{
                            //borderBottom: "1px solid lightgrey",
                            paddingTop: 5,
                            paddingBottom: 7,
                            marginBottom: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label>Student Mobile No</label>
                          <Switch
                            checked={Bill.student_mobile}
                            onChange={(checked) =>
                              setBill({ ...Bill, student_mobile: checked })
                            }
                            onColor="#007bff"
                            offColor="#6c757d"
                            onHandleColor="#fff"
                            offHandleColor="#fff"
                            handleDiameter={15}
                            uncheckedIcon={
                              <div style={{ paddingLeft: 7, color: "white" }}>
                                Off
                              </div>
                            }
                            checkedIcon={
                              <div style={{ paddingLeft: 7, color: "white" }}>
                                On
                              </div>
                            }
                            height={25}
                            width={100}
                            className="react-switch"
                            id={`student_mobile`}
                          />
                        </div>
                        <div
                          className="col-md-4 form-group"
                          style={{
                            //borderBottom: "1px solid lightgrey",
                            paddingTop: 5,
                            paddingBottom: 7,
                            marginBottom: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label>Father Mobile No</label>
                          <Switch
                            checked={Bill.father_mobile}
                            onChange={(checked) => setBill({...Bill, father_mobile:checked})} 
                            onColor="#007bff"
                            offColor="#6c757d"
                            onHandleColor="#fff"
                            offHandleColor="#fff"
                            handleDiameter={15}
                            uncheckedIcon={
                              <div style={{ paddingLeft: 7, color: "white" }}>
                                Off
                              </div>
                            }
                            checkedIcon={
                              <div style={{ paddingLeft: 7, color: "white" }}>
                                On
                              </div>
                            }
                            height={25}
                            width={100}
                            className="react-switch"
                            id={`father_mobile`}
                          />
                        </div>
                        <div
                          className="col-md-4 form-group"
                          style={{
                            //borderBottom: "1px solid lightgrey",
                            paddingTop: 5,
                            paddingBottom: 7,
                            marginBottom: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label>Mother Mobile No</label>
                          <Switch
                            checked={Bill.mother_mobile}
                            onChange={(checked) =>
                              setBill({ ...Bill, mother_mobile: checked })
                            }
                            onColor="#007bff"
                            offColor="#6c757d"
                            onHandleColor="#fff"
                            offHandleColor="#fff"
                            handleDiameter={15}
                            uncheckedIcon={
                              <div style={{ paddingLeft: 7, color: "white" }}>
                                Off
                              </div>
                            }
                            checkedIcon={
                              <div style={{ paddingLeft: 7, color: "white" }}>
                                On
                              </div>
                            }
                            height={25}
                            width={100}
                            className="react-switch"
                            id={`mother_mobile`}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className="col-md-12 text-center mt-3">
                <button type="submit" className="btn btn-sm btn-primary">
                  Update
                </button>
              </div>
            </div>
          </form>
          {loader}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WhatsappModal;
