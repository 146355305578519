import React from 'react'
import Performance from './Performance'
import TablePerformanceReport from './TablePerformanceReport'

const MasterPerformancePage = () => {
  const [selectedType, setSelectedType] = React.useState("chart-report");

  return (
    <div className="content">
      <div className="container-fluid">
      <div className="page-content-wrapper">
          <div className="row justify-content-between">
            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
              <div className="row">
                <div className="col-md-6">Performance Summary</div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <div className="col-md-5">
                      <div className="mb-3">
                       
                          <label
                            className="form-label mb-2"
                            htmlFor="performanceOptions"
                          >
                            Type
                          </label>
                          <select
                            className="form-select"
                            id="performanceOptions"
                            name="performanceOptions"
                            value={selectedType}
                            onChange={(e)=>{setSelectedType(e.target.value)}}
                          >
                            <option selected value="chart-report">Chart Report</option>
                            <option value="table-report">Table Report</option>
                          </select>
                    
                      </div>
                  </div>
                </div>
              </div>
            </h4>
          </div>
          {selectedType ==="chart-report"? (<Performance/>):(<TablePerformanceReport/>)}
        </div></div></div>
  )
}

export default MasterPerformancePage