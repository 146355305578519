import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/en-gb";
import "@ckeditor/ckeditor5-build-classic/build/ckeditor";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatDate, formatInputDate, convertDateTimeFormat } from "../../Components/dateFormate";

const CreateTestSets = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { ID } = useParams();
  const [errors, setErrors] = useState({});
  const [StdOptions, setStdOptions] = useState([]);
  const [SubOptions, setSubOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);

  const [Lecture, setLecture] = useState({
    Test_Type: "Online Test",
    Class_Id: "",
    Class: "",
    Subject_Id: "",
    Subject: "",
    Batch_Id: "",
    Batch: "",
    Test_Title: "",
    Start_date_and_time: "",
    End_Date_and_time: "",
    Number_of_Question: "",
    Correct: "1",
    Incorrect: "0",
    TotalMarks: "",
    Duration: "",
    Unlimited_Attempt: "",
    Num_Of_Atempt: "",
  });


  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const updateLecture = {
      ...Lecture,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addTestSets`, updateLecture)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Test Sets Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/ViewNewTest");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateLecture = {
      ...Lecture,

      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateTestSet/${ID}`, updateLecture)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Lecture Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/ViewNewTest");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();

        if (ID) {
          const ByID = await axios.get(`${apiUrl}/getTestSetsById/${ID}`);
          setLecture(ByID.data);
          setLecture((prevStudent) => ({
            ...prevStudent,
            Batch_Id: parseInt(ByID.data.Batch_Id),
            Duration: parseInt(ByID.data.Duration),
          }));

          console.log(ByID.data);
        }

        if (userData?.Org_Id && userData?.Head_Id) {
          const stdRes = await axios.get(
            `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
          );
          const stdOptions = stdRes.data.map((category) => ({
            label: category.Std_Name,
            value: category.Std_Id,
          }));
          setStdOptions(stdOptions);
          console.log(stdOptions);
        }

        if (Lecture?.Class_Id) {
          const [batchRes, subRes] = await Promise.all([
            axios.get(`${apiUrl}/getBatch?Class_Id=${Lecture.Class_Id}`),
            axios.get(`${apiUrl}/getSubjectsByStdId?Class_Id=${Lecture.Class_Id}`),
          ]);

          // Process the batch response
          const batchOptions = batchRes.data.map((category) => ({
            label: category.Batch_Name,
            value: category.Batch_Id,
          }));
          setBatchOptions(batchOptions);
          console.log(batchOptions);

          // Process the subject response
          const subOptions = subRes.data.map((category) => ({
            label: category.Sub_Name,
            value: category.Sub_Id,
          }));
          setSubOptions(subOptions);
          console.log(subOptions);
        }
        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData, ID, Lecture.Class_Id]);

  console.log(Lecture);


  // Function to calculate TotalMarks
  const calculateTotalMarks = (numberOfQuestions, correct) => {
    const totalMarks = Number(numberOfQuestions) * Number(correct);
    setLecture((prevLecture) => ({
      ...prevLecture,
      TotalMarks: isNaN(totalMarks) ? "" : totalMarks,
    }));
  };

  // Handlers for input changes
  const handleNumberOfQuestionsChange = (e) => {
    const numberOfQuestions = e.target.value;
    setLecture((prevLecture) => ({
      ...prevLecture,
      Number_of_Question: numberOfQuestions,
    }));
    calculateTotalMarks(numberOfQuestions, Lecture.Correct);
  };

  const handleCorrectChange = (e) => {
    const correct = e.target.value;
    setLecture((prevLecture) => ({
      ...prevLecture,
      Correct: correct,
    }));
    calculateTotalMarks(Lecture.Number_of_Question, correct);
  };
  const [selectedDuration, setSelectedDuration] = useState("");
  const [customDuration, setCustomDuration] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedDuration(value);
    setLecture({
      ...Lecture,
      Duration: value === "Custom" ? customDuration : value,
    });
    setShowCustomInput(value === "Custom");
  };

  const handleCustomInputChange = (e) => {
    const value = e.target.value;

    // Check if the value is an integer
    if (/^\d*$/.test(value)) {
      setCustomDuration(value);
      if (selectedDuration === "Custom") {
        setLecture({
          ...Lecture,
          Duration: value,
        });
      }
    }
  };

  useEffect(() => {
    if (Lecture.Duration) {
      switch (parseInt(Lecture.Duration)) {
        case 10:
          setSelectedDuration("10 Min");
          break;
        case 30:
          setSelectedDuration("30 Min");
          break;
        case 60:
          setSelectedDuration("1 Hrs");
          break;
        case 120:
          setSelectedDuration("2 Hrs");
          break;
        case 180:
          setSelectedDuration("3 Hrs");
          break;
        default:
          setSelectedDuration("Custom");
          setCustomDuration(Lecture.Duration);
          break;
      }
    } else {
      setSelectedDuration("");
      setCustomDuration("");
    }
  }, [Lecture.Duration]);


  const [unlimitedAttempts, setUnlimitedAttempts] = useState(false);
  const [numberOfAttempts, setNumberOfAttempts] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {

    setUnlimitedAttempts(e);
    if (e) {
      setNumberOfAttempts("");
      setErrorMessage("");
    }
    setLecture({
      ...Lecture,
      Unlimited_Attempt: e ? "Yes" : "No",
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setNumberOfAttempts(value);
    setLecture({
      ...Lecture,
      Num_Of_Atempt: value,
    });
    if (value.trim() === "") {
      setErrorMessage("Enter Number Of attempts");
    } else {
      setErrorMessage("");
    }
  };


  const buttonStyle = {
    display: "inline-block",
    width: "calc(50% / 6 - 10px)", // Six buttons per row, with some margin
    margin: "5px",
    verticalAlign: "middle",
    height: "40px",
  };

  const wrapperStyle = {
    display: "block",
    cursor: "pointer",
    background: "transparent",
    border: "1px solid #9b9b9b",
    color: "black",
    textTransform: "uppercase",
    fontSize: "0.7rem",
    padding: "0.5rem",
    textAlign: "center",
    borderRadius: "10px",
    position: "relative",
    height: "100%",
    width: "100%",
    lineHeight: "1.5rem", // Adjust line height for centered text
  };

  const wrapperSelectedStyle = {
    ...wrapperStyle,
    background: "#4fc3f7", // Change this to your desired selected color
    fontWeight: "600",
  };

  const radioStyle = {
    visibility: "hidden",
    height: "0",
    width: "0",
    opacity: "0",
  };

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            {ID ? "Update Test Sets" : "Add Test Sets"}
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
            {/* {!ID && (
              <Link
                to={"/addMultipleLectures"}
                style={{ float: "right", marginLeft: "10px" }}
              >
                <button className="btn btn-primary btn-sm text-center">
                  Add Multiple Lectures
                </button>
              </Link>
            )} */}
            <Link
              to={"/ViewNewTest"}
              style={{ float: "right", marginLeft: "10px" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                View Test Sets
              </button>
            </Link>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={ID ? handleUpdate : handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Test_Type">
                          Test Type :
                        </label>
                        <select
                          className="form-select form-select-sm"
                          id="Test_Type"
                          name="Test_Type"
                          value={Lecture.Test_Type}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setLecture({
                              ...Lecture,
                              Test_Type: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          <option selected value="Online Test">
                            Online Test
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Class">
                        Class<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Class"
                        id="Class"
                        required
                        options={StdOptions}
                        isClearable
                        //isDisabled={ID}
                        value={
                          Lecture.Class_Id
                            ? {
                              value: Lecture.Class_Id,
                              label: Lecture.Class,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Class_Id: selectedOption.value || "",
                            Class: selectedOption.label || "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Batch">
                        Batch<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Lecture.Class_Id === "" ? true : false}
                        name="Batch"
                        id="Batch"
                        required
                        isClearable
                        options={BatchOptions}
                        value={
                          Lecture.Batch_Id
                            ? {
                              value: Lecture.Batch_Id,
                              label: Lecture.Batch,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Batch_Id: selectedOption.value || "",
                            Batch: selectedOption.label || "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Subject">
                        Subject<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Lecture.Class_Id === "" ? true : false}
                        name="Subject"
                        id="Subject"
                        required
                        isClearable
                        options={SubOptions}
                        value={
                          Lecture.Subject_Id
                            ? {
                              value: Lecture.Subject_Id,
                              label: Lecture.Subject,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Subject_Id: selectedOption.value || "",
                            Subject: selectedOption.label || "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Topic">
                        Topic<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        id="Topic"
                        name="Topic"
                        required
                        value={Lecture.Test_Title}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            Test_Title: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        className="form-label"
                        htmlFor="Start_date_and_time"
                      >
                        Test Start Date & Time
                        <span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="datetime-local"
                        id="Start_date_and_time"
                        name="Start_date_and_time"
                        required
                        value={convertDateTimeFormat(Lecture.Start_date_and_time)}
                        onChange={(e) => {

                          setLecture({
                            ...Lecture,
                            Start_date_and_time: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="End_Date_and_time">
                        Test End Date & Time
                        <span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="datetime-local"
                        id="End_Date_and_time"
                        name="End_Date_and_time"
                        required
                        value={convertDateTimeFormat(Lecture.End_Date_and_time)}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            End_Date_and_time: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label
                        className="form-label"
                        htmlFor="Number_of_Question"
                      >
                        Total Number of Questions
                      </label>
                      <input
                        className="form-control"
                        id="Number_of_Question"
                        name="Number_of_Question"
                        value={Lecture.Number_of_Question}
                        onChange={handleNumberOfQuestionsChange}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Correct">
                        Correct
                      </label>
                      <input
                        className="form-control"
                        id="Correct"
                        name="Correct"
                        value={Lecture.Correct}
                        onChange={handleCorrectChange}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Incorrect">
                        Incorrect
                      </label>
                      <input
                        className="form-control"
                        id="Incorrect"
                        name="Incorrect"
                        value={Lecture.Incorrect}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            Incorrect: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="TotalMarks">
                        Total Marks
                      </label>
                      <input
                        className="form-control"
                        id="TotalMarks"
                        name="TotalMarks"
                        value={Lecture.TotalMarks}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 d-none">
                      <label className="form-label" htmlFor="TotalMarks">
                        Test Duration
                      </label>
                      <br />
                      <div
                        className="radioPad"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "10 Min"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              value={10}
                              checked={selectedDuration === '10 Min'}
                              onChange={handleRadioChange}
                            />
                            10 Min
                          </label>
                        </div>
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "30 Min"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              checked={selectedDuration === '30 Min'}
                              value={30}
                              onChange={handleRadioChange}
                            />
                            30 Min
                          </label>
                        </div>
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "1 Hrs"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              checked={selectedDuration === '1 Hrs'}
                              value={60}
                              onChange={handleRadioChange}
                            />
                            1 Hrs
                          </label>
                        </div>
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "2 hrs"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              checked={selectedDuration === '2 Hrs'}
                              value={120}
                              onChange={handleRadioChange}
                            />
                            2 hrs
                          </label>
                        </div>
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "3 hrs"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              checked={selectedDuration === '3 Hrs'}
                              value={180}
                              onChange={handleRadioChange}
                            />
                            3 hrs
                          </label>
                        </div>
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "Custom"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              value="Custom"
                              onChange={handleRadioChange}
                            />
                            Custom
                          </label>
                        </div>
                        {showCustomInput && (
                          <div
                            style={{
                              marginLeft: "25px",
                              // marginTop: "10px",
                            }}
                          >
                            <label className="form-label" htmlFor="txtDuration">Duration in Minutes</label>
                            <input
                              type="text"
                              id="txtDuration"
                              placeholder="Duration in Minutes"
                              className="form-control"
                              style={{
                                width: "150px",
                                height: "35px",
                                padding: "5px",
                              }}
                              value={customDuration}
                              onChange={handleCustomInputChange}
                            />
                            {customDuration.trim().length === 0 &&
                              selectedDuration === "Custom" && (
                                <span style={{ color: "red" }}>
                                  Enter Test Duration
                                </span>
                              )}
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="col-md-12">
                      <label className="form-label" htmlFor="Duration">
                        Test Duration
                      </label>
                      <br />
                      <div
                        className="radioPad"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {["10 Min", "30 Min", "1 Hrs", "2 Hrs", "3 Hrs"].map((option) => (
                          <div style={buttonStyle} key={option}>
                            <label
                              style={
                                selectedDuration === option
                                  ? wrapperSelectedStyle
                                  : wrapperStyle
                              }
                            >
                              <input
                                style={radioStyle}
                                type="radio"
                                id='Duration'
                                name="Duration"
                                value={parseInt(option)}
                                checked={selectedDuration === option}
                                onChange={handleRadioChange}
                              />
                              {option}
                            </label>
                          </div>
                        ))}
                        <div style={buttonStyle}>
                          <label
                            style={
                              selectedDuration === "Custom"
                                ? wrapperSelectedStyle
                                : wrapperStyle
                            }
                          >
                            <input
                              style={radioStyle}
                              type="radio"
                              name="Duration"
                              value="Custom"
                              checked={selectedDuration === "Custom"}
                              onChange={handleRadioChange}
                            />
                            Custom
                          </label>
                          {showCustomInput && (
                            <div
                              style={{
                                marginLeft: "25px",
                                // marginTop: "10px",
                              }}
                            >
                              <label className="form-label" htmlFor="txtDuration">Duration in Minutes</label>
                              <input
                                type="text"
                                id="txtDuration"
                                placeholder="Duration in Minutes"
                                className="form-control"
                                style={{
                                  width: "150px",
                                  height: "35px",
                                  padding: "5px",
                                }}
                                value={customDuration}
                                onChange={handleCustomInputChange}
                              />
                              {customDuration.trim().length === 0 &&
                                selectedDuration === "Custom" && (
                                  <span style={{ color: "red" }}>
                                    Enter Test Duration
                                  </span>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="form-label">
                        Number of Attempts
                      </label>
                      <br />
                      <input
                        type="checkbox"
                        className="mycheckbox"
                        checked={Lecture.Unlimited_Attempt === "Yes"}
                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                      />
                      <label style={{ marginLeft: "10px" }}>
                        Check, If you want to allow unlimited attempts on this
                        test
                      </label>
                    </div>
                    <div className="col-lg-4" style={{ marginTop: "10px" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Number of Attempts"
                        disabled={Lecture.Unlimited_Attempt === "Yes"}
                        value={Lecture.Num_Of_Atempt}
                        onChange={handleInputChange}

                      />
                      {errorMessage && (
                        <span style={{ color: "red" }}>{errorMessage}</span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="NotifyStudent">
                        Instructions<span style={{ color: "Red" }}>*</span>
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={Lecture.NotifyStudent}
                        required
                        onChange={(event, editor) => {
                          // console.log(event,editor)
                          const data = editor.getData();
                          setLecture(prevBill => ({ ...prevBill, NotifyStudent: data }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {ID ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateTestSets;
