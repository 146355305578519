import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BankDropdoun from "../DropDowns/BankDropdoun";
import BillCategory from "../DropDowns/ExCategory";
import YearDropDown from "../DropDowns/YearDropDown";

const AdmissionCancelModal = ({ open, onClose, studentData }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const handleClose = () => {
    setShow(false);
    onClose();
  };
  console.log(studentData);

  const handleCancelEdit = () => {
    setShow(false);
    onClose();
  };

  const [Bill, setBill] = useState({
    Name: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Std_Name: "",
    Head_Id: "",
    Head_Name: "",
    Subhead_Name: "",
    Subhead_Id: "",
    Batch_Name: "",
    Std_Id: "",
    Batch_ID: "",
    Photo: "",
    Student_Id: "",
    UUID: "",
    Mobile_For_SMS: "",
    Email: "",
    DOB: "",
    Gender: "",
    Father_Name: "",
    Mother_Name: "",
    Father_Mobile: "",
    Mother_Mobile: "",
    Course_Name: "",
    Total_Amount: "",
    Total_Paid: "",
    Refund_Amount: "",
    Refund_Mode:"",
    Remark: "",
  });
  

  useEffect(() => {
    const fetchData = async () => {
      if (userData && userData.Org_Id) {
        try {
          showLoader();
          const result = await axios.get(
            `${apiUrl}/studentInfo/${studentData?.ID}/${userData?.Org_Id}`
          );
          setBill(result.data);
          console.log(result.data);
          hideLoader();
        } catch (error) {
          console.error("Error fetching data:", error);
          hideLoader();
        }
      }
    };

    fetchData();
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
  
    // Update the bill object with additional user data
    const updateBill = {
      ...Bill,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      Display_Name: userData?.UserName,
    };
  
    const errors = {};
  
    // Add your validation logic here
    if (!Bill.Refund_Amount) {
      errors.Refund_Amount = "Refund Amount is required";
    }
    // Add other validations as needed
  
    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/cancelAdmission/${studentData?.ID}`, updateBill)
        .then((res) => {
          Swal.fire("Success!", "Bill Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                handleClose();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );
          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="small"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Admission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="mb-3 col-md-6">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Name :{" "}
                  <span className="ms-1 text-1100">
                    {Bill.First_Name} {Bill.Middle_Name} {Bill.Last_Name}
                  </span>
                </p>
              </div>
              <div className="mb-3 col-md-6">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Biometric Id :{" "}
                  <span className="ms-1 text-1100">{Bill.UUID}</span>
                </p>
              </div>
              <div className="mb-3 col-md-6">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Standard :{" "}
                  <span className="ms-1 text-1100">{Bill.Std_Name}</span>
                </p>
              </div>

              <div className="mb-3 col-md-6">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Mobile :{" "}
                  <span className="ms-1 text-1100">{Bill.Mobile_For_SMS}</span>
                </p>
              </div>
              <div className="mb-3 col-md-6">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Batch :{" "}
                  <span className="ms-1 text-1100">{Bill.Batch_Name}</span>
                </p>
              </div>
              <div className="mb-3 col-md-6 ">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Course :{" "}
                  <span className="ms-1 text-1100">{Bill.Course_Name}</span>
                </p>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" htmlFor="totalAmount">
                  Total Amount
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="totalAmount"
                  id="totalAmount"
                  disabled="true"
                  value={Bill.totalAmount}
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" htmlFor="paidAmount">
                  Paid Amount
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="paidAmount"
                  id="paidAmount"
                  disabled="true"
                  value={Bill.paidAmount}
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" htmlFor="Refund_Amount">
                  Refund Amount
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="Refund_Amount"
                  id="Refund_Amount"
                  value={Bill.Refund_Amount}
                    placeholder="0.00"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" htmlFor="Refund_Mode">
                  Refund Mode
                </label>
                <select
                  className="form-select form-select-sm"
                  name="Refund_Mode"
                  id="Refund_Mode"
                 value={Bill.Refund_Mode}
                 required={Bill.Refund_Amount!==""}
               
                 onChange={handleChange}

                >
                  <option disabled value="">--Select--</option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Online">Online</option>
                </select>
                <span style={{ color: "Red" }}></span>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="Remark">
                  Remark
                </label>
                <textarea
                  className="form-control form-control-sm"
                  name="Remark"
                  id="Remark"
                  rows={2}
                  value={Bill.Remark}
                  placeholder="Write Remark..."
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center mt-3">
                <>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Cancel Addmission
                  </button>
                </>
                &nbsp;
                <button
                  className="btn btn-sm btn-danger"
                  onClick={handleCancelEdit}
                >
                  Close
                </button>
              </div>
            </div>
          </form>

          {loader}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdmissionCancelModal;
