import React, { useEffect, useState } from "react";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../DropDowns/DropDown";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";
import DropStudent from "../DropDowns/StudentDropdown";
import DropProductcat from "../DropDowns/ProductCategoryDrop";

const Branches = () => {
  const [table, setTable] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setBillData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateDataId, setId] = useState("");
  const [CategoryOptions, setCategoryOptions] = useState([]);
  const [selectedstatus, setSelectedstatus] = useState("Uncleared");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [prodOptions, setprodOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  //   const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  //   const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });


  const [Bill, setBill] = useState({
    ID: "",
    Head_Id: "",
    Org_Id: "",
    Cat_Id: "",
    Prod_Id: "",
    Prod_Name: "",
    Qty:""
  });
  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Bill.Cat_Id,Bill.ID]);

  console.log(updateDataId);

  const fetchData = async () => {
    try {
        
        if(Bill.ID){
          showLoader();
          const res = await axios.get(
            `${apiUrl}/getStudentStudyMaterial?ID=${Bill.ID}`
          );
          setBillData(res.data);
          setFilteredData(res.data);
          console.log(res.data);}
      
      
      if (Bill.Cat_Id) {
        showLoader();
        const Product = await axios.get(
          `${apiUrl}/getProducts?Cat_Id=${Bill.Cat_Id}`
        );

        const getProduct = Product.data.map((category) => ({
          label: category.Prod_Name,
          value: category.productid,
        }));
        setprodOptions(getProduct);
        console.log(getProduct);
      }

      setTable(true);
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (data.length > 0) {
      const newFilteredData = data.filter(
        (row) =>
          row?.Category.toLowerCase().includes(searchTerm) ||
          row?.Prod_Name.toLowerCase().includes(searchTerm) ||
          row?.Qty.toString().toLowerCase().includes(searchTerm)||
          formatDate(row?.Date).toLowerCase().includes(searchTerm)
      );
      setFilteredData(newFilteredData);
    }
  }, [searchTerm, data]);

  async function editBills(data) {
    showLoader();

    console.log(data);
    setBill({
    });
    setEditingItem(data);
    hideLoader();
  }

  useEffect(() => {
    const badgeCellRenderer = (props) => {
      return (
        <span
          className={`badge badge-phoenix ${
            props.Status === "Unpaid"
              ? "badge-phoenix-warning"
              : props.Status === "Paid"
              ? "badge-phoenix-success"
              : "badge-phoenix-primary"
          }`}
        >
          {props.Status}
        </span>
      );
    };
    const options = (props) => {
      const { Product, Prod_Id, Row_Id } = props.data;
      return (
        <div className="font-sans-serif">
        <Link
          onClick={() => {
            deleteUser(Row_Id, Product,Prod_Id);
          }}
          className="cursor-pointer"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <i
            className="fas fa-trash"
            style={{ color: "red", fontSize: "14px" }}
          ></i>
         
        </Link>
      </div>
      );
    };

    const newAgcolumns = [
      { field: "SrNo" },
      { field: "Category" },
      { field: "Product" },
      { field: "Quantity" },
      {field:"Issue Date"},
      {
        headerName: "",
        field: "",
        cellRenderer: options,
        width: 50,
        maxWidth: 50,
      },
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData.map((row, index) => ({
        Row_Id:row.Row_Id,
        Prod_Id:row.Prod_Id,
        "SrNo":index + 1,
        "Category": row.Category,
        "Product": row.Prod_Name,
        "Quantity": row.Qty,
        "Issue Date": formatDate(row.Date),
      }));

      setAgrows(newAgrows);
    }
  }, [filteredData]);

  const deleteUser = (Id, Name, Prod_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id, Name,Prod_Id);
      }
    });
  };
  async function handleDelete(Id, Name,Prod_Id) {
    showLoader();
    const obj = {
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      User_Name: userData?.UserName,
      Name: Name,
    };

    try {
      await axios.delete(`${apiUrl}/deleteMaterialRows/${Id}/${Prod_Id}/${Name}`, { data: obj });
      Swal.fire("Deleted!", "Stock Remove Successfully...!", "success");
      await fetchData();
    } catch (error) {
      console.error("Error while Removing Stock:", error);
      Swal.fire("Error", "Failed to Remove Product record.", "error");
    } finally {
      hideLoader();
    }
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    fetchData();
    hideLoader();
  };


  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF();
    doc.text("Student Study Material", 20, 10);

    const columns = ["Sr No", "Category", "Product", "Quantity", "Issue Date"];

    const rows = filteredData.map((row, index) => [
      index + 1,
      row.Category,
      row.Prod_Name,
      row.Qty,
      formatDate(row.Date),

    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Student Study Material.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sr No", "Category", "Product", "Quantity", "Issue Date"];

    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([      
        index + 1,
        row.Category,
        row.Prod_Name,
        row.Qty,
        formatDate(row.Date)
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Student Study Material");

    XLSX.writeFile(workBook, "Student Study Material.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const toggle = () => {
    setDesign({
      ...design,
      filterToggle: design.filterToggle ? false : true,
    });
    setFilterData({
      ...filterData,
      filterToggle: design.filterToggle ? false : true,
    });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    debugger
    event.preventDefault();
    const updateBill = {
      ...Bill,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,

    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/AddStudentStudyMaterial`, updateBill)
        .then((res) => {
          Swal.fire("Success!", "Material issued successfully...!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                setBill({
                  Prod_Id: "",
                  Prod_Name: "",
                });
                fetchData();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 33,
      minHeight: 33
    })
  };


  // const handleUpdate = (event) => {
  //   event.preventDefault();

  //   const updateBill = {
  //     ...Bill,
  //     Head_Id: userData?.Head_Id,
  //     Head_Name: userData?.Head_Name,
  //     User_Id: userData.UserId,
  //     Org_Id: userData.Org_Id,
  //     Org_Name: userData.Org_Name,
  //     User_Role: userData.Role,
  //     LoginUsername: userData.Username,
  //     Display_Name: userData.UserName,
  //   };

  //   const errors = {};

  //   if (Object.keys(errors).length === 0) {
  //     showLoader();
  //     axios
  //       .put(`${apiUrl}/updateBranches/${updateDataId}`, updateBill)
  //       .then((res) => {
  //         Swal.fire(
  //           "Success!",
  //           "Branch Updated Successfully!!",
  //           "success"
  //         ).then((result) => {
  //           if (result.isConfirmed) {
  //             setBill({ newHead_Name: "", Head_GST_No: "", Head_Address: "" });
  //             fetchData();
  //           }
  //         });
  //         hideLoader();
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         Swal.fire(
  //           "Server Timeout",
  //           "Server is Busy!!!, Please try again later.",
  //           "error"
  //         );

  //         hideLoader();
  //       });
  //   } else {
  //     setErrors(errors);
  //   }
  // };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-3">
              <div className="me-3 flex-1 mt-2">
                <h5 className="text-black">{row.Prod_Name}</h5>

                {/* <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Date :{" "}
                                    <span className="ms-1 text-1100">{formatDate(row?.Date)}</span>
                                </p> */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1">
                <p
                  className="profiledetail lh-1 mb-1"
                  style={{ fontSize: "13px" }}
                >
                  Category :{" "}
                  <span className="ms-1 text-1100">{row.Category}</span>
                </p>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Quantity :{" "}
                  <span className="ms-1 text-1100">{row.Qty}</span>
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Issue Date :{" "}
                  <span className="ms-1 text-1100">{formatDate(row.Date)}</span>
                </p>
              </div>
            </div>

            <div className="col-md-1">
              <div className="font-sans-serif">
                <button
                  style={{ padding: 0 }}
                  className="btn btn-link"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={{ fontSize: "14px", color: "gray" }}
                  ></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >


                  <Link
                    onClick={() => {
                      deleteUser(row.Row_Id, row.Prod_Name, row.Prod_Id);
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i
                      className="fas fa-trash"
                      style={{ color: "red", fontSize: "14px" }}
                    ></i>
                    <span> Delete</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                  <div
                    className="row justify-content-between me-1"
                    style={{ flex: "auto", marginTop: "8px" }}
                  >
                    <div className="col-11 pe-1">
                      <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                        {row.Prod_Name}
                      </h6>
                      {/* <p
                                                className="mb-0 profiledetail lh-1"
                                                style={{ fontSize: "13px" }}
                                            >
                                                Date :{" "}
                                                <span className="ms-1 text-1100">{formatDate(row?.Date)}</span>
                                            </p> */}
                    </div>
                    <div className="end-0 col-1 p-0 ">
                      <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-ellipsis-v"
                          style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                      >

                        <Link
                          onClick={() => {
                            deleteUser(row.Row_Id, row.Prod_Name, row.Prod_Id);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "red", fontSize: "14px" }}
                          ></i>
                          <span> Delete</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail text-truncate lh-1">
                  Category :{" "}
                    <span className="fw-semi-bold ms-1">
                      {" "}
                      {row.Category}
                    </span>
                  </p>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0 profiledetail lh-1">
                  Quantity :{" "}
                    <span className="ms-1 text-1100">{row.Qty}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <p className="mb-0 profiledetail lh-1">
                  Issue Date :{" "}
                    <span className="ms-1 text-1100">{formatDate(row.Date)}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="content">

        <div className="container-fluid">
          {/* Sidebar */}
       
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                  <div className="col-md-6">Student Study Material</div>

                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="col-md-5">
                      <HeadMasterDropDown
                        label="Branch"
                        name="Branch"
                        id="Branch"
                      />
                    </div>
                  </div>
                </div>
              </h4>

              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-4"
                  data-component-card="data-component-card"
                >
                  <div className="card-body p-0">
                    <div className="p-4 code-to-copy">
                      <form
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="mb-3 col-md-12">
                            <DropStudent
                              label="Student Name"
                              name="Student"
                              id="Student"
                              value={
                                Bill.ID
                                  ? {
                                      value: Bill.ID,
                                      label: Bill.Name,
                                    }
                                  : null
                              }
                              required={true}
                              
                              onChange={(e) => {
                                setBill({
                                  ...Bill,
                                  ID: e.value,
                                  Name: e.label,
                                });
                              }}
                            />
                            <span style={{ color: "Red" }}></span>
                          </div>
                          <div className="mb-3 col-md-6">
                            <DropProductcat
                              label="Product Category"
                              name="Product"
                              id="Product"
                              value={
                                Bill.Cat_Id
                                  ? {
                                      value: Bill.Cat_Id,
                                      label: Bill.Category,
                                    }
                                  : null
                              }
                              required={true}
                              
                              onChange={(e) => {
                                setBill({
                                  ...Bill,
                                  Cat_Id: e.value,
                                  Category: e.label,
                                });
                              }}
                            />
                            <span style={{ color: "Red" }}></span>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="Product "
                                >
                                  Product <span style={{ color: "Red" }}>*</span>
                                </label>
                                <Select
                                  id="Product "
                                  options={prodOptions}
                                  required
                                  styles={customStyles}
                                  value={
                                    Bill.Prod_Id
                                      ? {
                                          value: Bill.Prod_Id,
                                          label: Bill.Prod_Name,
                                        }
                                      : null
                                  }
                                  onChange={(selectedOption) => {
                                    setBill({
                                      ...Bill,
                                      Prod_Id: selectedOption.value,
                                      Prod_Name: selectedOption.label,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                           
                          </div>
                          {/* <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="Head_AddressHead_Address"
                            >
                              Address<span style={{ color: "Red" }}>*</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="Head_Address"
                              name="Head_Address"
                              required
                              rows={2}
                              value={Bill.Head_Address}
                              onChange={handleChange}
                            />
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                            >
                             Add
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  style={{
                    float: "left",
                    display: "inline-block",
                    width: "200px",
                  }}
                ></input>
                <Link
                  to={"/addBranches"}
                  style={{ float: "right", display: "none" }}
                >
                  <button className="btn btn-primary btn-sm text-center">
                    Add
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn bg-200 filterButton collapsed mr-2 d-none"
                  data-toggle="collapse"
                  onClick={toggle}
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  style={{ float: "right", marginRight: "10px" }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className="form-control dropdown-toggle viewButton  mb-2"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "auto",
                    padding: "4px 10px",
                  }}
                >
                  <>
                    {design.dataView === "Table View" && (
                      <TableChartOutlinedIcon />
                    )}
                  </>
                  <>
                    {design.dataView === "List View" && (
                      <FormatListBulletedIcon />
                    )}
                  </>
                  <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={(e) => {
                      setDesign({
                        ...design,
                        dataView: "Table View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Table View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <TableChartOutlinedIcon />
                    <span> Table View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "List View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "List View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <FormatListBulletedIcon />
                    <span> List View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "Tile View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Tile View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <GridViewIcon />
                    <span> Tile View</span>
                  </Link>
                </div>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to PDF</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadPDF()}
                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                  >
                    <i
                      className="fas fa-file-pdf"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to Excel</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadExcel()}
                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row">
            <div className="col-12 col-xl-12 order-1 order-xl-0">
            {filteredData.length === 0 && (<h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
            </div>
              {design.dataView === "Table View" && (
                <div className={filteredData.length === 0 ?"d-none":"col-12 col-xl-12 order-1 order-xl-0"}>
                  {Agrows.length > 0 && (
                    <AgGridTable row={Agrows} column={Agcolumns} />
                  )}
                </div>
              )}
              {design.dataView === "Tile View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">{displayData}</div>
                </div>
              )}
              {design.dataView === "List View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">
                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                      <div className="card h-100 hover-actions-trigger">
                        <div
                          className="card-body"
                          style={{ paddingTop: "5px" }}
                        >
                          {displayData}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(design.dataView === "List View" ||
                design.dataView === "Tile View") && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageCount={pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  breakAriaLabels={{
                    forward: "Jump forward",
                    backward: "Jump backward",
                  }}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                  previousLinkClassName={"btn btn-outline-primary me-2"}
                  nextLinkClassName={"btn btn-outline-primary ms-2"}
                  activeLinkClassName={"customclass ms-2 me-2"}
                  pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                />
              )}
            </div>
          </div>
        </div>

        {loader}
      </div>

  );
};

export default Branches;
