import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../Components/dateFormate'


const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Title: updateData.Title,
        Description: updateData.Description
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        debugger;
        const updateBill = {
            ...Bill,

            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};



        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateNoticeForFaculty/${updateData?.R_Id}`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Notice For Faculty Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Notice For Faculty</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                        <div className="mb-3 col-md-12">
                                        <label className="form-label" htmlFor="Title">
                                            Title<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="Title"
                                            id="Title"
                                            required
                                            value={Bill.Title}
                                            placeholder="Enter Title"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>
                                    <div className="mb-3 col-md-12">
                                        <label className="form-label" htmlFor="Description">
                                            Description<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="Description"
                                            name="Description"
                                            required
                                            placeholder="Enter Description..."
                                            rows={2}
                                            value={Bill.Description}
                                            onChange={handleChange}
                                        />
                                    </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
