import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BankDropdoun from "../DropDowns/BankDropdoun";
import BillCategory from "../DropDowns/ExCategory";
import YearDropDown from "../DropDowns/YearDropDown";
import { formatDate } from '../../Components/dateFormate'
const StudentFeesSummaryModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [installmentLog, setInstallment] = useState([]);
    const [Summary, setSummary] = useState([]);
    const [nextInstallment, setNextInstallment] = useState();
    const [paidFeesDetails, setPaidFeesDetails] = useState();
    const [feesDetails, setFeesDetails] = useState();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(updateData);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Cheque_Status: "Honoured"
    });

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();
                    const result = await axios.get(
                        `${apiUrl}/getStudentFeesSummary?Id=${updateData?.Id}&OrgId=${userData?.Org_Id}`
                    );
                    console.log(result.data);
                    setSummary(result.data);
                   
                    hideLoader();
                } catch (error) {
                    console.error("Error fetching data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, updateData]);

    console.log(Bill);

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Fees Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div>
                            <table
                                align="Center"
                                rules="cols"
                                className="table table-bordered border-primary table-hover table-responsive"
                                style={{ border: "1px solid var(--phoenix-gray-200)", padding:".5rem .5rem" }}
                            >
                                <thead>

                                    <tr
                                        align="center"
                                    >
                                         <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Payment Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Summary?.map((installment, index) => (
                                        <tr key={index} align="center" style={{ backgroundColor: 'White' }}>
                                             <td>{index+1}</td>
                                            <td>{formatDate(installment.Due_Date)}</td>
                                            <td>{installment.Amount}</td>
                                            <td>{installment.Payment_Status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                    {/* <h5>Cheque Details</h5>
                    <hr /> */}

                    <div className="row d-none">
                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Bill_No">
                                Bank Name :

                            </label>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                disabled
                                value={updateData.Bank_Name}

                            />
                            <span style={{ color: "Red" }}></span>
                        </div>
                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Bill_Ref_No">
                                Cheque No :
                            </label>
                            <input
                                className="form-control form-control-sm"
                                disabled
                                type="text"
                                value={updateData.Cheque_No}
                            />
                            <span style={{ color: "Red" }}></span>
                        </div>
                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Bill_Date">
                                Date :
                            </label>
                            <input
                                className="form-control form-control-sm"
                                disabled
                                type="date"
                                value={
                                    updateData.Cheque_Date
                                        ? dayjs(updateData.Cheque_Date).format("YYYY-MM-DD")
                                        : ""
                                }
                            />
                            <span style={{ color: "Red" }}></span>
                        </div>

                        <div className="mb-3 col-md-3">
                            <label className="form-label" htmlFor="Title">
                                Amount :
                            </label>
                            <input
                                className="form-control form-control-sm"
                                disabled
                                type="text"
                                value={updateData.Tuition_Amount}
                            />
                            <span style={{ color: "Red" }}></span>
                        </div>
                    </div>



                    {/* <form onSubmit={handleSubmit}>
                        <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="Cheque_Status">
                                Cheque Status<span style={{ color: "Red" }}>*</span>
                            </label>
                            <select
                                className="form-select form-select-sm"
                                name="Cheque_Status"
                                id="Cheque_Status"
                                onChange={handleChange}
                            >
                                <option selected value="Honoured">Honoured</option>
                                <option value="Dishonoured">Dishonoured</option>
                            </select>
                            <span style={{ color: "Red" }}></span>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form> */}

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default StudentFeesSummaryModal;
