import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../Components/apiConfig";
import { formatDate } from "../../../Components/dateFormate";
import { useUser } from "../../../Components/UserContext";
import DownloadingIcon from '@mui/icons-material/Downloading';
import FeesReceipts from "./FeesReceipts";
import MasterFeesReceipts from "./MasterFeesReceipts";


const ViewReceipts = ({ showLoader, hideLoader }) => {
  const { Sr_No } = useParams();
  const { userData } = useUser();
  const [logData, setLogData] = useState([]);
  const [headData, setHeadData] = useState({});
  const [selectedLog, setSelectedLog] = useState(null); 
  const [selectedMaster, setSelectMaster] = useState(false); 


  useEffect(() => {
    loadData();
  }, [userData, Sr_No]);

  const loadData = async () => {
    showLoader();
    try {
      if (Sr_No && userData?.Org_Id) {
        const feesdata = await axios.get(
          `/getStudentFeesPaymentLog?OrgId=${userData?.Org_Id}&ID=${Sr_No}`
        );
        setLogData(feesdata.data);
      }

      if (userData?.Org_Id && userData?.Head_Id) {
        const headadata = await axios.get(
          `/getHeadMasterByHeadId?OrgId=${userData?.Org_Id}&Head_Id=${userData?.Head_Id}`
        );
        setHeadData(headadata.data);
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const downloadReceipt = (log) => {
    setSelectedLog(log); // Store the selected log in state
  };
  const downloadMasterReceipt = () => {
    setSelectMaster(true); // Store the selected log in state
  };

  return (
    <div>
    <div className="d-flex justify-content-between align-items-center">
        <h5>Summary/Receipts</h5>
        <button className="btn btn-sm btn-primary" onClick={downloadMasterReceipt}>Download Master Receipt</button>
      </div>
      <hr className='mt-2 mb-2' />
      <table
        cellSpacing="0"
        cellPadding="0"
        align="center"
        rules="cols"
        className="table table-bordered border-primary table-hover table-responsive"
        style={{ border: "1px solid var(--phoenix-gray-200)" }}
      >
        <thead>
          <tr align="center">
            <th align="center" scope="col" style={{ color: "#333" }}>Receipt No</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Course/Books Amt</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Late Fees</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Total Amount</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Date</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Payment Mode</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Type</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Bank Name</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Status</th>
            <th align="center" scope="col" style={{ color: "#333" }}>Fees Receipt</th>
          </tr>
        </thead>
        <tbody>
          {logData?.map((log, index) => (
            <tr key={index} style={{ backgroundColor: "White" }}>
              <td align="center"><span>{log.Receipt_No_Txt}</span></td>
              <td align="center">{parseFloat(log.T_Amt).toFixed(2)}</td>
              <td align="center">{parseFloat(log.Late_Fee_Amt).toFixed(2)}</td>
              <td align="center">{(parseFloat(log.T_Amt) + parseFloat(log.Late_Fee_Amt)).toFixed(2)}</td>
              <td align="center">{formatDate(log.Payment_Date)}</td>
              <td align="center">{log.Payment_Mode}</td>
              <td align="center">{log.Type}</td>
              <td align="center">{log.Bank_Name}</td>
              <td align="center">{log.Status}</td>
              <td align="center">
                <button onClick={() => downloadReceipt(log)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  <DownloadingIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-none">
      {selectedLog && (
        <FeesReceipts feesData={[selectedLog]} headData={headData} Role="NotStudent" ID={Sr_No} />
      )}
    {selectedMaster && (
        <MasterFeesReceipts  ID={Sr_No} />
      )}

      </div>
     
    </div>
  );
};

export default ViewReceipts;
