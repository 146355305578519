import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import { formatInputDate } from '../../../Components/dateFormate'

const AddBannerImage = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [errors, setErrors] = useState({});
    const [facultyStaff, setFacultyStaff] = useState([]);
    const [selectedMobiles, setSelectedMobiles] = useState([]);
    const [checkedStatus, setCheckedStatus] = useState({});

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData]);

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(
                `${apiUrl}/getFacultyStaff?OrgId=${userData.Org_Id}`);
            setFacultyStaff(res.data);
            // console.log(res.data);
            hideLoader()
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };


    const [Bill, setBill] = useState({
        Type: "All",
        Title: "",
        Description: ""
    });
    // console.log(selectedMobiles);
    // console.log(checkedStatus);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const updateBill = {
            ...Bill,
            FacultyData: Bill.Type === "All" ? facultyStaff : selectedMobiles,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addNoticeForFaculty`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Notice For Faculty Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewNoticeForFaculty");
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    const handleCheckboxChange = (item) => {
        if (selectedMobiles.includes(item)) {
            setSelectedMobiles(selectedMobiles.filter(num => num !== item));
        } else {
            setSelectedMobiles([...selectedMobiles, item]);
        }

        setCheckedStatus({
            ...checkedStatus,
            [item.Mobile_For_SMS]: !checkedStatus[item.Mobile_For_SMS]
        });
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-4">
                        Add Notice For Faculty
                    </div>

                    <div className="col-md-8 d-flex justify-content-end align-items-center">
                        <div className="col-md-4">
                            <select
                                className="form-select form-select-sm"
                                id="Type"
                                name="Type"
                                value={Bill.Type}
                                onChange={handleChange}
                            >
                                <option selected value="All">All</option>
                                <option value="Multiple">Multiple</option>
                            </select>
                        </div>
                        &nbsp;
                        <div className="col-md-4">
                            <HeadMasterDropDown
                                label="Branch"
                                name="Branch"
                                id="Branch"
                            />
                        </div>
                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">
                                <form onSubmit={handleSubmit}>
                           <div className="row">
                           {Bill.Type === "Multiple" && ( <div className="col-md-6">
                         
                         <div className="row mt-2">
                             <div className="col-12">
                                 <div className=" table-responsive">
                                     <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                         <thead>
                                             <tr>
                                                 <th className="text-center">
                                                     {/* <input
                                                         type="checkbox"
                                                         onChange={handleSelectAllChange}
                                                         checked={selectAllChecked}
                 />*/}
                                                 </th>

                                                 <th className="text-center">Sr No</th>
                                                 <th className="text-center">Name</th>

                                             </tr>
                                         </thead>
                                         <tbody>
                                             {facultyStaff?.length > 0 ? (
                                                 facultyStaff?.map((item, index) => {
                                                     return (
                                                         <tr key={index}>
                                                             <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                 <input type="checkbox" id={`customerCheck${index}`} checked={checkedStatus[item.Mobile_For_SMS] || false} onChange={() => handleCheckboxChange(item)} />
                                                             </td>
                                                             <td className="text-center" style={{ width: "" }}>
                                                                 {index + 1}
                                                             </td>
                                                             <td className="text-center" style={{ width: "" }}>
                                                                 {item.Name}
                                                             </td>

                                                         </tr>
                                                     );
                                                 })
                                             ) : (
                                                 <tr>
                                                     <td className="text-center text-danger" colSpan={7}>
                                                         No Data Found !!
                                                     </td>
                                                 </tr>
                                             )}
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
                         </div>
                    
             </div> )}
                            <div className={Bill.Type === "Multiple" ? "col-md-6" : "col-md-12"}>
                            <div className="mb-3 col-md-12">
                                        <label className="form-label" htmlFor="Title">
                                            Title<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="Title"
                                            id="Title"
                                            required
                                            value={Bill.Title}
                                            placeholder="Enter Title"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>
                                    <div className="mb-3 col-md-12">
                                        <label className="form-label" htmlFor="Description">
                                            Description<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="Description"
                                            name="Description"
                                            required
                                            placeholder="Enter Description..."
                                            rows={2}
                                            value={Bill.Description}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <div class="form-check">
                                            <input
                                                className="form-check-input"
                                                id="flexCheckDefault2"
                                                type="checkbox"
                                            //   checked={installment.Set_Installment}
                                            //   onChange={(e) => {
                                            //     setInstallment({
                                            //       ...installment,
                                            //       Set_Installment: e.target.checked,
                                            //       Full_Payment: false,
                                            //     });
                                            //   }}
                                            />
                                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                <h6>Send Whatsapp Message</h6>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                            </div>    

                           </div>
                           
                                  
                                </form>
                            </div>
                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBannerImage;
