import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../Components/UserContext";
import dayjs from "dayjs";
import { formatDate } from "../../Components/dateFormate";

const UpdateEnquiryFollowup = ({ open, onClose, studentData }) => {
    const { userData } = useUser();
    const [data, setData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        onClose();
    };
    console.log(studentData)

    ///studentData.ID
    const [Student, setStudent] = useState({
        InactiveRemarks: ""

    });


    const handleSubmit = (event) => {
        event.preventDefault();
debugger;
        const updateStudent = {
            ...Student,
            Head_Id: userData.Head_Id,
            Head_Name: userData.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            Username: userData.Username,
            User_Name: userData.UserName
        };



        const errors = {};




        if (Object.keys(errors).length === 0) {

            showLoader();
            axios
                .put(`${apiUrl}/markInactiveStudent/${studentData.ID}`, updateStudent)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Marked Student Inactive Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            // navigate("/viewEnquiry");
                            handleClose();
                        }
                    });
                    hideLoader();

                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();

                });
        } else {
            setErrors(errors);
        }
    };


    return (<div>
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Mark Student Inactive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">

                            <div className="mb-3 col-md-12">
                                <label
                                    className="form-label"
                                    htmlFor="InactiveRemarks"
                                >
                                    Remarks
                                </label>
                                <textarea
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="InactiveRemarks"
                                    id="InactiveRemarks"
                                    placeholder="Remarks..."
                                    onChange={(e) => {
                                        setStudent({
                                            ...Student,
                                            InactiveRemarks: e.target.value,
                                        });
                                    }}
                                />
                                <span style={{ color: "Red" }}></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
                {loader}
            </Modal.Body>
        </Modal>

    </div>
    )
}

export default UpdateEnquiryFollowup