import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useUser } from "../../../Components/UserContext";
import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";

const AddUpdateELearning = ({ open, onClose, videoData, opration }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [questions, setQuestions] = useState([]);
  const [errors, setErrors] = useState({});
  const [StdOptions, setStdOptions] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [TopicOption, setTopicOption] = useState([]);

  const [Bill, setBill] = useState({
    IsWhtasapp: false,
    Std_Id: opration === "Submit" ? "" : videoData?.Std_Id,
    Std_Name: opration === "Submit" ? "" : videoData?.Std_Name,
    Sub_Id: opration === "Submit" ? "" : videoData?.Sub_Id,
    Sub_Name: opration === "Submit" ? "" : videoData?.Sub_Name,
    Topic_Id: opration === "Submit" ? "" : videoData?.Topic_Id,
    Topic_Name: opration === "Submit" ? "" : videoData?.Topic_Name,
    Title: opration === "Submit" ? "" : videoData?.Title,
    Link: opration === "Submit" ? "" : videoData?.Link,
    Description: opration === "Submit" ? "" : videoData?.Description
  });

  useEffect(() => {
    setShow(open);
  }, [open]);

  useEffect(() => {
    fetchData();
  }, [userData, Bill.Std_Id, Bill.Sub_Id]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData && userData.Org_Id && userData.Head_Id) {
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
        );
        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
      }

      if (userData && userData.Org_Id && Bill.Std_Id) {
        const sub = await axios.get(
          `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Bill.Std_Id}`
        );
        const getSub = sub.data.map((category) => ({
          label: category.Sub_Name,
          value: category.Sub_Id,
        }));
        setSubjectOption(getSub);
      }

      if (userData && userData.Org_Id && Bill.Sub_Id) {
        const top = await axios.get(
          `${apiUrl}/getTopicMasterDrop?Sub_Id=${Bill.Sub_Id}`
        );
        const gettop = top.data.map((category) => ({
          label: category.Topic_Name,
          value: category.Topic_Id,
        }));
        setTopicOption(gettop);
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateBill = {
      ...Bill,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};



    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addELearningVideo`, updateBill)
        .then((res) => {
          Swal.fire(
            "Success!",
            "E-Learning Video Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              handleClose()
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateBill = {
      ...Bill,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};



    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateELearningVideo/${videoData?.Id}`, updateBill)
        .then((res) => {
          Swal.fire(
            "Success!",
            "E-Learning Video Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              handleClose()
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <h4>{opration === "Submit" ? 'Add E-Learning Videos' : 'Update E-Learning Videos'}</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row justify-content-end mb-2">
              <div className="col-md-3 d-flex align-items-center">
                <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
              </div>
            </div>
            <form onSubmit={opration==="Submit"? handleSubmit:handleUpdate}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Standard">
                      Standard<span style={{ color: "Red" }}>*</span>
                    </label>
                    <Select
                      id="Standard"
                      value={Bill.Std_Id ? { label: Bill.Std_Name, value: Bill.Std_Id } : null}
                      options={StdOptions}
                      required
                      placeholder="--Select--"
                      isClearable
                      onChange={(e) => {
                        setBill({
                          ...Bill,
                          Std_Id: e ? e.value : "",
                          Std_Name: e ? e.label : "",
                          Sub_Id: "",
                          Sub_Name: "",
                          Topic_Id: "",
                          Topic_Name: ""
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Subject">
                      Subject<span style={{ color: "Red" }}>*</span>
                    </label>
                    <Select
                      id="Subject"
                      value={Bill.Sub_Id ? { label: Bill.Sub_Name, value: Bill.Sub_Id } : null}
                      options={SubjectOption}
                      placeholder="--Select--"
                      isClearable
                      required
                      isDisabled={Bill.Std_Id === ""}
                      onChange={(e) => {
                        setBill({
                          ...Bill,
                          Sub_Id: e ? e.value : "",
                          Sub_Name: e ? e.label : "",
                          Topic_Id: "",
                          Topic_Name: ""
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Topic">
                      Topic<span style={{ color: "Red" }}>*</span>
                    </label>
                    <Select
                      id="Topic"
                      value={Bill.Topic_Id ? { label: Bill.Topic_Name, value: Bill.Topic_Id } : null}
                      options={TopicOption}
                      placeholder="--Select--"
                      isClearable
                      required
                      isDisabled={Bill.Sub_Id === ""}
                      onChange={(e) => {
                        setBill({
                          ...Bill,
                          Topic_Id: e ? e.value : "",
                          Topic_Name: e ? e.label : ""
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Title">
                      Title<span style={{ color: "Red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="Title"
                      required
                      className="form-control"
                      value={Bill.Title}
                      onChange={(e) => {
                        setBill({ ...Bill, Title: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Link">
                      YouTube Video Id<span style={{ color: "Red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="Link"
                      required
                      className="form-control"
                      value={Bill.Link}
                      onChange={(e) => {
                        setBill({ ...Bill, Link: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Description">
                      Description
                    </label>
                    <textarea
                      id="Description"
                      className="form-control"
                      rows={2}
                      value={Bill.Description}
                      onChange={(e) => {
                        setBill({ ...Bill, Description: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className={opration === "Submit" ? "mb-3 col-md-4" : "d-none"}>
                  <div class="form-check">
                    <input
                      className="form-check-input"
                      id="flexCheckDefault2"
                      type="checkbox"
                      checked={Bill.IsWhtasapp}
                      onChange={(e) => {
                        setBill({
                          ...Bill,
                          IsWhtasapp: e.target.checked,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                      <h6>Send Whatsapp Message</h6>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 text-center mt-3">
                  <button type="submit" className="btn btn-sm btn-primary">
                    {opration === 'Submit' ? "Add" : "Update"}
                  </button>
                </div>
              </div>
            </form>
          </div>

          {loader}

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddUpdateELearning;
