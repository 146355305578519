import React, { useEffect, useState } from "react";
import $ from "jquery";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from '@mui/icons-material/TableView';
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TeacherSalary = ({ StaffName }) => {
  const [table, setTable] = useState(false);
  const { Sr_No } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [data, setBillData] = useState([]);
  const [fromDate, setFromDate] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [errors, setErrors] = useState({});
  const [StdOptions, setStdOptions] = useState([]);
  const [SubOptions, setSubOptions] = useState([]);
  const [toDate, setToDate] = useState("");
  const [BatchOptions, setBatchOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  const [Salary, setSalary] = useState({
    Head_Id: "",
    Sub_Id: "",
    Sub_Name: "",
    Org_Id: "",
    Std_Id: "",
    Std_Name: "",
    Salary: "",
  });

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Salary.Std_Id, Sr_No]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData && userData.Org_Id && userData?.Head_Id) {
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
      }

      if (userData && Salary.Std_Id) {
        const Sub = await axios.get(
          `${apiUrl}/getSubMaster?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}&Std_Id=${Salary.Std_Id}`
        );
        const getSub = Sub.data.map((category) => ({
          label: category.Sub_Name,
          value: category.Sub_Id,
        }));
        setSubOptions(getSub);
  
      }
      if (Sr_No && userData.Org_Id && userData.Head_Id) {
        const res = await axios.get(
          `${apiUrl}/getTeacherSalaryStructure/${Sr_No}?Org_Id=${
            userData.Org_Id
          }&Head_Id=${userData.Head_Id}&Std_Id=${""}&Sub_Id=${""}`);
        setBillData(res.data);
        setFilteredData(res.data);
        console.log(res.data);
      }
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (data.length > 0) {
      const newFilteredData = data.filter(
        (row) =>
          row?.Std_Name.toLowerCase().includes(searchTerm) ||
          row?.Subject.toLowerCase().includes(searchTerm) ||
          (row?.Salary).toString().toLowerCase().includes(searchTerm) ||
          formatDate(row?.Date_Added).toLowerCase().includes(searchTerm)
      );
      setFilteredData(newFilteredData);
    }
  }, [searchTerm, data]);

  useEffect(() => {
    const badgeCellRenderer = (props) => {
      return (
        <span
          className={`badge badge-phoenix ${
            props.Status === "Unpaid"
              ? "badge-phoenix-warning"
              : props.Status === "Paid"
              ? "badge-phoenix-success"
              : "badge-phoenix-primary"
          }`}
        >
          {props.Status}
        </span>
      );
    };
    const options = (props) => {
      const { Row_Id } = props.data;
      return (
        <div className="font-sans-serif">
          <Link
            onClick={() => {
              deleteUser(Row_Id);
            }}
            className="cursor-pointer"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <i style={{ color: "red", fontSize: "14px" }}>
              <DeleteOutlineIcon fontSize="small" />
            </i>
          </Link>
        </div>
      );
    };

    const newAgcolumns = [
      { field: "SrNo" },
      { field: "Standard" },
      { field: "Subject" },
      { field: "Amount/Hour" },
      { field: "UpdatedOn" },
      {
        headerName: "",
        field: "",
        cellRenderer: options,
        width: 50,
        maxWidth: 50,
      },
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData.map((row, index) => ({
        Row_Id: row.Row_Id,
        SrNo: index + 1,
        Standard: row.Std_Name,
        Subject: row.Subject,
        "Amount/Hour": row.Salary,
        UpdatedOn: formatDate(row.Date_Added),
      }));

      setAgrows(newAgrows);
    }
  }, [filteredData]);

  const deleteUser = (Row_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Row_Id);
      }
    });
  };
  async function handleDelete(Row_Id) {
    showLoader();
    const obj = {
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      User_Name: userData?.UserName,
    };

    try {
      await axios.delete(`${apiUrl}/deleteTeacherSalary/${Row_Id}`, {
        data: obj,
      });
      Swal.fire(
        "Deleted!",
        "Salary Structure Remove Successfully...!",
        "success"
      );
      fetchData();
    } catch (error) {
      console.error("Error while Removing Stock:", error);
      Swal.fire("Error", "Failed to Remove Salary Structure record.", "error");
    } finally {
      hideLoader();
    }
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    fetchData();
    hideLoader();
  };

  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF();
    doc.text("Staff Salary Structure", 20, 10);

    const columns = ["Sr No", "Std Name", "Subject", "Salary", "Uploaded On"];

    const rows = filteredData.map((row, index) => [
      index + 1,
      row.Std_Name,
      row.Subject,
      row.Salary,
      formatDate(row.Date_Added)
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("STaff Salary Structure.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sr No", "Std Name", "Subject", "Salary", "Uploaded On"];

    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([      index + 1,
        row.Std_Name,
        row.Subject,
        row.Salary,
        formatDate(row.Date_Added)]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Staff Salary Structure");

    XLSX.writeFile(workBook, "Staff Salary Structure.xlsx");
  };

  const handleSubmit = (event) => {
    // debugger;
    event.preventDefault();
    const updateBill = {
      ...Salary,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };
    //console.log(updateBill);
    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/setStaffSalary/${Sr_No}/${StaffName}`, updateBill)
        .then((res) => {
          Swal.fire("Success!", "Salary set successfully!!", "success");
          fetchData();
          setSalary({
            Head_Id: "",
            Sub_Id: "",
            Sub_Name: "",
            Org_Id: "",
            Std_Id: "",
            Std_Name: "",
            Salary: "",
          });

          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-3">
              <div className="me-3 flex-1 mt-2">
                <h5 className="text-black">{row.Std_Name}</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1">
                <p
                  className="profiledetail lh-1 mb-1"
                  style={{ fontSize: "13px" }}
                >
                  Subject :{" "}
                  <span className="ms-1 text-1100">{row.Sub_Name}</span>
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Amount/Hour :{" "}
                  <span className="ms-1 text-1100">{row.Salary}</span>
                </p>
              </div>
            </div>

            <div className="col-md-1">
              <div className="font-sans-serif">
                <button
                  style={{ padding: 0 }}
                  className="btn btn-link"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={{ fontSize: "14px", color: "gray" }}
                  ></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={() => {
                      deleteUser(row.Row_Id);
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i
                      className="fas fa-trash"
                      style={{ color: "red", fontSize: "14px" }}
                    ></i>
                    <span> Delete</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                  <div
                    className="row justify-content-between me-1"
                    style={{ flex: "auto", marginTop: "8px" }}
                  >
                    <div className="col-11 pe-1">
                      <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                        {row.Std_Name}
                      </h6>
                    </div>
                    <div className="end-0 col-1 p-0 ">
                      <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-ellipsis-v"
                          style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          onClick={() => {
                            deleteUser(row.Row_Id);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "red", fontSize: "14px" }}
                          ></i>
                          <span> Delete</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail text-truncate lh-1">
                    Subject :{" "}
                    <span className="fw-semi-bold ms-1"> {row.Sub_Name}</span>
                  </p>
                </div>

                <div className="d-flex align-items-center mb-3">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail lh-1">
                    Amount/Hour :{" "}
                    <span className="ms-1 text-1100">{row.Salary}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
      <div className="container-fluid">
        {/* Sidebar */}
        <div id="sidebar-wrapper">
          <div className="row g-4">
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              <div
                className="card shadow-none border border-300 my-2"
                data-component-card="data-component-card"
              >
                <div className="row justify-content-center d-flex p-3">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Branch">
                          Branch
                        </label>
                        <HeadMasterDropDown
                          label="Branch"
                          name="Branch"
                          id="Branch"
                          //onChange={(e) => setBranch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-none">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Std_Name">
                          From Date :
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          //value={fromDate?.format("YYYY-MM-DD")}
                          onChange={(date) =>
                            setFromDate(dayjs(date.target.value))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-none">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Std_Name">
                          To Date :
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          // value={toDate?.format("YYYY-MM-DD")}
                          onChange={(date) =>
                            setToDate(dayjs(date.target.value))
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Std_Name">
                          Batch :
                        </label>
                        <select
                          className="form-select form-select-sm"
                          id="example-select"
                          value={Batch_ID}
                          disabled={Std_Id === ""}
                          onChange={(e) => setBatchID(e.target.value)}
                        >
                          <option selected value="" label="All"></option>
                          {BatchOptions.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}
                              label={option.label}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center p-2">
                  <div className="col-md-4 mb-2">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm text-center"
                      onClick={handleDateRangeFilter}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-12 col-xl-12 order-1 order-xl-0">
            <div
              className="card shadow-none border border-300 my-4"
              data-component-card="data-component-card"
            >
              <div className="card-body p-0">
                <div className="p-4 code-to-copy">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Standard">
                              Standard :
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="Standard"
                              disabled={userData?.Head_Id === ""}
                              value={Salary.Std_Id}
                              onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                const selectedOption = e.target[selectedIndex];
                                setSalary({
                                  ...Salary,
                                  Std_Id: selectedOption.value,
                                  Std_Name: selectedOption.label,
                                });
                              }}
                            >
                              <option value="" label="--Select--"></option>
                              {StdOptions.map((option) => (
                                <option
                                  key={option.value}
                                  value={option.value}
                                  label={option.label}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Status">
                              Subject :
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="example-select"
                              value={Salary.Sub_Id}
                              disabled={Salary.Std_Id === ""}
                              onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                const selectedOption = e.target[selectedIndex];
                                setSalary({
                                  ...Salary,
                                  Sub_Id: selectedOption.value,
                                  Sub_Name: selectedOption.label,
                                });
                              }}
                            >
                              <option
                                selected
                                value=""
                                label="--Select--"
                              ></option>
                              {SubOptions.map((option) => (
                                <option
                                  key={option.value}
                                  value={option.value}
                                  label={option.label}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="AmountHour">
                              Amount/Hour :
                            </label>
                            <input
                              type="text"
                              id="AmountHour"
                              name="AmountHour"
                              placeholder="Enter Amount/Hour"
                              className="form-control form-control-sm"
                              value={Salary.Salary}
                              onChange={(e) => {
                                const input = e.target.value;
                                const decimalInput = input.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                const amount = decimalInput.replace(
                                  /^(\d*\.\d*)\..*$/,
                                  "$1"
                                );
                                setSalary({ ...Salary, Salary: amount });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-3">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                        >
                          Set Salary
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 text-center mt-3">
            <input
              type="text"
              className="form-control mb-2"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              style={{
                float: "left",
                display: "inline-block",
                width: "200px",
              }}
            ></input>
            <Link
              to={"/addBranches"}
              style={{ float: "right", display: "none" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                Add
              </button>
            </Link>

            <button
              className="form-control dropdown-toggle viewButton  mb-2 d-none"
              id="dropdownMenuLink"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                float: "right",
                marginRight: "10px",
                width: "auto",
                padding: "4px 10px",
              }}
            >
              <>
                {design.dataView === "Table View" && <TableChartOutlinedIcon />}
              </>
              <>
                {design.dataView === "List View" && <FormatListBulletedIcon />}
              </>
              <>{design.dataView === "Tile View" && <GridViewIcon />}</>
            </button>
            <div
              className="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuLink"
            >
              <Link
                onClick={(e) => {
                  setDesign({
                    ...design,
                    dataView: "Table View",
                  });
                  setFilterData({
                    ...filterData,
                    dataView: "Table View",
                  });
                }}
                className="dropdown-item cursor-pointer"
              >
                <TableChartOutlinedIcon />
                <span> Table View</span>
              </Link>
              <Link
                onClick={() => {
                  setDesign({
                    ...design,
                    dataView: "List View",
                  });
                  setFilterData({
                    ...filterData,
                    dataView: "List View",
                  });
                }}
                className="dropdown-item cursor-pointer"
              >
                <FormatListBulletedIcon />
                <span> List View</span>
              </Link>
              <Link
                onClick={() => {
                  setDesign({
                    ...design,
                    dataView: "Tile View",
                  });
                  setFilterData({
                    ...filterData,
                    dataView: "Tile View",
                  });
                }}
                className="dropdown-item cursor-pointer"
              >
                <GridViewIcon />
                <span> Tile View</span>
              </Link>
            </div>
            {filteredData.length===0 &&(<h5 style={{float: "center", marginTop:"10px !important"}}>Data Not Found!!</h5>)}
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>Export to PDF</Tooltip>}
              placement="bottom"
            >
              <Button
                variant=""
                onClick={() => DownloadPDF()}
                style={{ float: "right", padding: "5px 10px 5px 5px" }}
              >
                <i  style={{ fontSize: "25px" }}><PictureAsPdfIcon/></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>Export to Excel</Tooltip>}
              placement="bottom"
            >
              <Button
                variant=""
                onClick={() => DownloadExcel()}
                style={{ float: "right", padding: "5px 5px 5px 10px" }}
              >
                  <i  style={{ fontSize: "25px" }}>    <TableViewIcon/></i>
               
              </Button>
            </OverlayTrigger>
            
          </div>
        </div>
        <div className="row mb-2">
          {design.dataView === "Table View" && (
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              {Agrows.length > 0 && (
                <AgGridTable row={Agrows} column={Agcolumns} />
              )}
            </div>
          )}
          {design.dataView === "Tile View" && (
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              <div className="row">{displayData}</div>
            </div>
          )}
          {design.dataView === "List View" && (
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              <div className="row">
                <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                  <div className="card h-100 hover-actions-trigger">
                    <div className="card-body" style={{ paddingTop: "5px" }}>
                      {displayData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(design.dataView === "List View" ||
            design.dataView === "Tile View") && (
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              previousLabel="<"
              renderOnZeroPageCount={null}
              pageCount={pageCount}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              breakAriaLabels={{
                forward: "Jump forward",
                backward: "Jump backward",
              }}
              onPageChange={changePage}
              containerClassName={"pagination"}
              disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
              previousLinkClassName={"btn btn-outline-primary me-2"}
              nextLinkClassName={"btn btn-outline-primary ms-2"}
              activeLinkClassName={"customclass ms-2 me-2"}
              pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
            />
          )}
        </div>
      </div>

      {loader}
    </div>
  );
};

export default TeacherSalary;
