import React from 'react'

const HomePage = () => {
    return (
        <div className="content"><p>Dashboard</p></div>
        

    )
}

export default HomePage