import React, { useEffect, useState } from 'react'
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";

const BatchStudentView = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [StudentData, setData] = useState([]);

    useEffect(() => {
        student();
    }, [userData]);

    const student = async () => {
        if (userData && userData.Org_Id) {
            showLoader();
            axios
                .get(`${apiUrl}/getBatchWiseStudent?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`)
                .then((res) => {
                    const result = res.data;
                    setData(result);
                    console.log(result);

                    hideLoader();
                });
        }
    };
    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                Batch Summary
                <a
                    className="anchorjs-link "
                    aria-label="Anchor"
                    href="#basic-form"
                    style={{ paddingLeft: "0.375em" }}
                ></a>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">

                                <div className="row">
                                    <div className="col-md-6">
                                        {/* <h5 >Student Details</h5> */}
                                    </div>

                                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                                        <div className="col-md-5">
                                            <HeadMasterDropDown
                                                label="Branch"
                                                name="Branch"
                                                id="Branch"


                                            />

                                        </div></div>
                                </div>
                                <hr />
                                {StudentData?.map(item => (
                                    <div key={item.Std_Id} className="col-md-12 mb-3" style={{ padding: '4px', border: "1px solid rgb(138 148 173 / 76%)", borderRadius: '5px' }}>
                                        <h5
                                            className="mb-3 text-uppercase p-2"
                                            style={{
                                                backgroundColor: "rgb(242, 242, 247)",
                                                fontSize: "15px",
                                                fontWeight: 600,
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span>{item.Std_Name}</span>
                                            <span className=''>Batches : {item.Batch.length}</span>
                                        </h5>
                                        {item?.Batch?.map(batch => (
                                            <div key={batch.Batch_Id} className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span style={{ paddingLeft: '10px' }}>Batch : {batch.Batch_Name}</span>
                                                </div>
                                                <div className='col-md-2'>
                                                    Boys : {batch.TotalBoys}
                                                </div>
                                                <div className='col-md-2'>
                                                    Girls : {batch.TotalGirls}
                                                </div>
                                                <div className='col-md-2'>
                                                    Unspecified : {batch.Unspecified}
                                                </div>
                                                <div className='col-md-2 d-flex justify-content-end'>
                                                    <span style={{ paddingRight: '36px' }}>Total : {batch.TotalStudent}</span>
                                                    <Link to={`/viewBatchWiseStudent/${batch.Std_Id}/${batch.Batch_Id}`} style={{ paddingRight: '10px' }}><FaArrowCircleRight style={{ fontSize: '24px' }} /></Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}


                            </div>
                        </div>{loader}
                    </div></div></div>
        </div>
    )
}

export default BatchStudentView