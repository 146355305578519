import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { formatInputDate } from "../../Components/dateFormate";
import Swal from "sweetalert2";
import "jspdf-autotable";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";


const Branches = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Std_Id, Batch_ID, fromDate, toDate]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData && userData.Org_Id && userData?.Head_Id) {
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
      }

      if (userData && userData.Org_Id && Std_Id) {
        const batch = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
        );

        const getBatch = batch.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(getBatch);
      }
if(userData.Org_Id && userData.Head_Id && Std_Id && Batch_ID &&   fromDate && toDate){
  const res = await axios.get(
    `${apiUrl}/getStudentLectureReportData?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}&StdId=${Std_Id}&BatchId=${Batch_ID}
    &FromDate=${fromDate ? fromDate?.format("YYYY-MM-DD") : ""}&ToDate=${toDate ? toDate?.format("YYYY-MM-DD") : ""}`
  );
  setFilteredData(res.data);
  console.log(res.data);
}


      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const pivotData = (data) => {
    const pivotedData = [];
    const groupedData = {};
    data.forEach((item) => {
      const key = `${item.StudentID}_${item.StudentName}`;
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);
    });

    Object.keys(groupedData).forEach((key) => {
      const group = groupedData[key];
      const newRow = {
        StudentID: group[0].StudentID,
        StudentName: group[0].StudentName,
      };
  
      group.forEach((item) => {
        newRow[item.SubjectName] = `${item.AttendedLectures}/${item.TotalLectures}`;
      });
  
      pivotedData.push(newRow);
    });
    return pivotedData;
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    try {
      if (filteredData.length > 0) {
        const pivotedData = pivotData(filteredData);
        if (pivotedData.length > 0) {
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(pivotedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Lecture Data");
          XLSX.writeFile(workbook, "LectureData.xlsx");
        } else {
          Swal.fire({
            icon: "error",
            title: "No Pivoted Data to Export.!!",
            text: "",
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'No Records Found.!!',
          text: '',
        });
      }
    } catch (error) {
      console.error("Error processing data:", error);
    }
  };

  return (
    <div className="content">

        <div className="container-fluid">
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                  <div className="col-md-6">Lecture Report</div>
                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="col-md-5">
                      <HeadMasterDropDown
                        label="Branch"
                        name="Branch"
                        id="Branch"
                      />
                    </div>
                  </div>
                </div>
              </h4>
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-4"
                  data-component-card="data-component-card"
                >
                  <div className="card-body p-0">
                    <div className="p-4 code-to-copy">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4 d-none">
                            <div className="mb-3">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Branch">
                                  Branch
                                </label>
                                <HeadMasterDropDown
                                  label="Branch"
                                  name="Branch"
                                  id="Branch"
                                  //onChange={(e) => setBranch(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Standard">
                                  Standard :
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  id="Standard"
                                  disabled={userData?.Head_Id === ""}
                                  required
                                  value={Std_Id}
                                  onChange={(e) => {
                                    setStdId(e.target.value);
                                    setBatchID(""); 
                                  }}
                                >
                                  <option value="" label="All"></option>
                                  {StdOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Batch">
                                  Batch :
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  id="Batch"
                                  value={Batch_ID}
                                  required
                                  disabled={Std_Id === ""}
                                  onChange={(e) => setBatchID(e.target.value)}
                                >
                                  <option value="" label="All"></option>
                                  {BatchOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            From Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            required
                            value={formatInputDate(fromDate)}
                            onChange={(date) =>
                              setFromDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            To Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            required
                            value={formatInputDate(toDate)}
                            onChange={(date) =>
                              setToDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                        </div>
                        <div className={filteredData.length === 0?"d-none":"row mt-2"}>
                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader}

    </div>
  );
};

export default Branches;
