import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { formatTime, formatDate } from "../../Components/dateFormate";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import StandardDropdown from "../DropDowns/StandardDropdown";
import BatchDropdown from "../DropDowns/BatchDropdown";
import SubjectDropdown from "../DropDowns/SubjectDropdown";
import TopicDropdown from "../DropDowns/TopicDropdown";
import { useUser } from "../../Components/UserContext";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";

const AddMultipleLecture = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [errors, setErrors] = useState({});
  const [classRoomOption, setClassRoomOption] = useState([]);
  const [facultyStaff, setFacultyStaff] = useState([]);
  const [lectureSchedules, setLectureSchedules] = useState([]);

  const [Lecture, setLecture] = useState({
    Std_Id: "",
    Std_Name: "",
    Batch_Id: "",
    Batch_Name: "",
    Subject_Id: "",
    Subject_Name: "",
    Type: "",
    Teacher_Id: "",
    Teacher_Name: "",
    Lecture_Date: "",
    From_Time: null,
    To_Time: null,
    ClassRoom_Id: "",
    ClassRoom_Name: "",
    Description: "",
    Attendance_Marked: "",
    Topic_Id: "",
    Topic_Name: "",
    Link: "",
    Meeting_Id: "",
    Password: "",
    Lecture_Type: "Lecture",
    Lec_Type: "OFFLINE"
  });
  console.log(lectureSchedules)


  const AddLecture = (event) => {
    event.preventDefault();

    const updatedLecture = {
      ...Lecture,
      From_Time: new Date(Lecture.From_Time).toLocaleTimeString('en-GB'),
      To_Time: new Date(Lecture.To_Time).toLocaleTimeString('en-GB'),
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const updatedLectureSchedules = [...lectureSchedules, updatedLecture];
    setLectureSchedules(updatedLectureSchedules);
    setLecture({
      Std_Id: "",
      Std_Name: "",
      Batch_Id: "",
      Batch_Name: "",
      Subject_Id: "",
      Subject_Name: "",
      Type: "",
      Teacher_Id: "",
      Teacher_Name: "",
      Lecture_Date: "",
      From_Time: null,
      To_Time: null,
      ClassRoom_Id: "",
      ClassRoom_Name: "",
      Description: "",
      Attendance_Marked: "",
      Topic_Id: "",
      Topic_Name: "",
      Link: "",
      Meeting_Id: "",
      Password: "",
      Lecture_Type: "Lecture",
      Lec_Type: "OFFLINE"
    });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addMultipleLectures`, lectureSchedules)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Lecture Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/viewLectures");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            Swal.fire({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            console.error(err);
            Swal.fire(
              "Server Timeout",
              "Server is Busy!!!, Please try again later.",
              "error"
            );
          }
          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleDelete = (index) => {
    const updatedLectureSchedules = [...lectureSchedules];
    updatedLectureSchedules.splice(index, 1); // Remove the item at the specified index
    setLectureSchedules(updatedLectureSchedules); // Update the state with the new array
  };


  useEffect(() => {
    const fetchData = async () => {

      try {
        showLoader();

        if (
          userData &&
          userData.Org_Id && userData?.Head_Id
        ) {
          const res = await axios.get(
            `${apiUrl}/getClassRoom?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}`);
          console.log(res.data);
          setClassRoomOption(res.data)

          const FacultyStaff = await axios.get(
            `${apiUrl}/binddropTeacher?OrgId=${userData.Org_Id}`);
          const Option = FacultyStaff.data.map((category) => ({
            label: category.Name,
            value: category.User_Id,
          }));
          // console.log(Option)

          setFacultyStaff(Option);
        }

        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }

    };

    fetchData();
  }, [userData]);



  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            Publish Multiple Lecture Schedule
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown
                label="Branch"
                name="Branch"
                id="Branch"
              />
            </div>

          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div className="card shadow-none border border-300 my-4" data-component-card="data-component-card">
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={AddLecture} >
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Standard">
                        Standard<span style={{ color: "Red" }}>*</span>
                      </label>
                      <StandardDropdown isDisabled={false}
                        name="Standard" id="Standard" required
                        value={
                          Lecture.Std_Id
                            ? {
                              value: Lecture.Std_Id,
                              label: Lecture.Std_Name
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Std_Id: selectedOption.value,
                            Std_Name: selectedOption.label,
                            Batch_Id: "",
                            Batch_Name: "",
                            Subject_Id: "",
                            Subject_Name: "",
                            Topic_Id: "",
                            Topic_Name: "",
                          });
                        }} />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Batch">
                        Batch<span style={{ color: "Red" }}>*</span>
                      </label>
                      <BatchDropdown isDisabled={Lecture.Std_Id === "" ? true : false} Std_Id={Lecture.Std_Id}
                        name="Batch" id="Batch" required
                        value={
                          Lecture.Batch_Id
                            ? {
                              value: Lecture.Batch_Id,
                              label: Lecture.Batch_Name
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Batch_Id: selectedOption.value,
                            Batch_Name: selectedOption.label
                          });
                        }} />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Lecture_Date">
                        Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input className="form-control" type="date" id="Lecture_Date" name="Lecture_Date" required
                        onChange={(e) => {
                          setLecture({
                            ...Lecture,
                            Lecture_Date: e.target.value
                          });
                        }} value={Lecture.Lecture_Date}></input>

                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Subject">
                        Subject<span style={{ color: "Red" }}>*</span>
                      </label>
                      <SubjectDropdown isDisabled={Lecture.Std_Id === "" ? true : false} Std_Id={Lecture.Std_Id}
                        name="Batch" id="Batch" required
                        value={
                          Lecture.Subject_Id
                            ? {
                              value: Lecture.Subject_Id,
                              label: Lecture.Subject_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Subject_Id: selectedOption.value,
                            Subject_Name: selectedOption.label,
                            Topic_Id: "",
                            Topic_Name: "",
                          });
                        }} />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Subject">
                        Topic<span style={{ color: "Red" }}>*</span>
                      </label>
                      <TopicDropdown isDisabled={Lecture.Subject_Id === "" ? true : false} Sub_Id={Lecture.Subject_Id}
                        name="Batch" id="Batch" required
                        value={
                          Lecture.Topic_Id
                            ? {
                              value: Lecture.Topic_Id,
                              label: Lecture.Topic_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Topic_Id: selectedOption.value,
                            Topic_Name: selectedOption.label,
                          });
                        }} />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="starttime">
                        Start Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker value={Lecture.From_Time} className="form-control"
                          slotProps={{ textField: { size: "small", required: true } }}
                          renderInput={(props) => (<input {...props} type="text" className="form-control" style={{ width: "100%" }} size="small" placeholder="" required />
                          )}
                          onChange={(startTime) => setLecture({ ...Lecture, From_Time: startTime })} />
                      </LocalizationProvider>
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Time_To">
                        End Time<span style={{ color: "Red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker value={Lecture.To_Time} required className="form-control"
                          slotProps={{ textField: { size: "small", required: true, } }}
                          onChange={(startTime) => setLecture({ ...Lecture, To_Time: startTime })} />
                      </LocalizationProvider>
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="ClassRoom_Name">
                            Class Room :
                          </label>
                          <select
                            className="form-select"
                            id="ClassRoom_Name"
                            value={Lecture.ClassRoom_Id}
                            onChange={(e) => {
                              const selectedIndex = e.target.selectedIndex;
                              const selectedOption = e.target[selectedIndex];
                              setLecture({
                                ...Lecture,
                                ClassRoom_Id: selectedOption.value,
                                ClassRoom_Name: selectedOption.label
                              });
                            }}
                          >
                            <option selected value="" label="--Select--">
                            </option>
                            {classRoomOption?.map((option) => (
                              <option key={option.ClassRoom_Id} value={option.ClassRoom_Id} label={option.ClassRoom_Name}>
                                {option.ClassRoom_Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="Faculty">
                        Faculty<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Faculty" id="Faculty" required options={facultyStaff}
                        value={
                          Lecture.Teacher_Id
                            ? {
                              value: Lecture.Teacher_Id,
                              label: Lecture.Teacher_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Teacher_Id: selectedOption.value,
                            Teacher_Name: selectedOption.label
                          });
                        }} />
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Lecture_Type">
                            Lecture Type  :
                          </label>
                          <select
                            className="form-select"
                            id="Lecture_Type"
                            name="Lecture_Type"
                            value={Lecture.Lecture_Type}

                            onChange={(e) => {
                              setLecture({
                                ...Lecture,
                                Lecture_Type: e.target.value
                              });
                            }}
                          >
                            <option selected value="Lecture">Lecture</option>
                            <option value="Revision">Revision</option>
                            <option value="Practice">Practice</option>
                            <option value="Learning">Learning</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Lec_Type">
                            Lecture Mode  :
                          </label>
                          <select
                            className="form-select"
                            id="Lec_Type"
                            name="Lec_Type"
                            value={Lecture.Lec_Type}

                            onChange={(e) => {
                              setLecture({
                                ...Lecture,
                                Lec_Type: e.target.value
                              });
                            }}
                          >
                            <option selected value="OFFLINE">Offline</option>
                            <option value="ONLINE">Online</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {Lecture.Lec_Type === 'ONLINE' && (<>
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="Link">
                          Lecture Link
                        </label>
                        <input className="form-control" type="text" id="Link" name="Link" placeholder="Enter Lecture Link"
                          onChange={(e) => {
                            setLecture({
                              ...Lecture,
                              Link: e.target.value
                            });
                          }} value={Lecture.Link} />

                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="Meeting_Id">
                          Meeting Id
                        </label>
                        <input className="form-control" type="text" id="Meeting_Id" name="Meeting_Id" placeholder="Enter Meeting ID"
                          onChange={(e) => {
                            setLecture({
                              ...Lecture,
                              Meeting_Id: e.target.value
                            });
                          }} value={Lecture.Meeting_Id} />

                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="Password">
                          Password
                        </label>
                        <input className="form-control" type="text" id="Password" name="Password" placeholder="Enter Meeting Password"
                          onChange={(e) => {
                            setLecture({
                              ...Lecture,
                              Password: e.target.value
                            });
                          }} value={Lecture.Password} />

                      </div> </>)}
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Description">
                        Description(Optional)
                      </label>
                      <textarea className="form-control" type="text" id="Description"
                        onChange={(e) => {
                          setLecture({
                            ...Lecture,
                            Description: e.target.value
                          });
                        }} />

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Add Lecture
                      </button>
                    </div>
                  </div>
                </form>
                {lectureSchedules.length > 0 && (<>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <div>
                        <table cellspacing="0" align="center" rules="all"
                          className="table table-bordered border-primary table-hover table-responsive"
                          border="1" style={{ borderCollapse: "collapse" }}>

                          <thead>
                            <tr
                              align="center"
                            >
                              <th scope="col">Date</th>
                              <th scope="col">Batch</th>
                              <th scope="col">Subject</th>
                              <th scope="col">Topic</th>
                              <th scope="col">Lecture Type</th>
                              <th scope="col">From Time</th>
                              <th scope="col">To Time</th>
                              <th scope="col">Faculty</th>
                              <th scope="col">Description</th>
                              <th scope="col">{"  "}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lectureSchedules?.map((item, index) => (
                              <tr align="center" key={index}>
                                <td>
                                  <span assName="f-12">{formatDate(item.Lecture_Date)}</span>
                                </td>
                                <td>
                                  <span lassName="f-12">{item.Batch_Name}</span>
                                </td>
                                <td>
                                  <span className="f-12">{item.Subject_Name}</span>
                                </td>
                                <td>
                                  <span lassName="f-12">{item.Topic_Name}</span>
                                </td>
                                <td>
                                  <span className="f-12">{item.Lecture_Type}</span>
                                </td>
                                <td>
                                  <span className="f-12">{formatTime(item.From_Time)}</span>
                                </td>
                                <td>
                                  <span className="f-12">{formatTime(item.To_Time)}</span>
                                </td>
                                <td>
                                  <span className="f-12">{item.Teacher_Name}</span>
                                </td>
                                <td>
                                  <span className="f-12">{item.Description}</span>

                                </td>
                                <td>
                                  <a className="cursor-pointer text-center" style={{ marginRight: "12px" }} onClick={() => handleDelete(index)}>
                                    <i className="fas fa-trash" style={{ color: "red", fontSize: "14px" }}></i>
                                  </a>
                                </td>
                              </tr>))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button onClick={handleSubmit} className="btn btn-sm btn-primary">
                        Publish Lecture
                      </button>
                    </div>
                  </div></>)}
              </div>


              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddMultipleLecture;
