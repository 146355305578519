import React from 'react'
import { useParams } from 'react-router-dom';
import ChartReport from './ChartReport';

const PerformenceResult = () => {
    const [status, setStatus] = React.useState('chart');
    const [data, setData] = React.useState();
    const {ID, Type} = useParams();

  return (
    <div className="content">
       <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">
                    Performance Result Summary
                    </div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <select
                                className="form-select form-select-sm"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.value)}

                            >
                                 <option selected value="chart">Chart Report</option>
                                 <option  value="table">Table Report</option>

                            </select>
                        </div></div>
                </div>

            </h4>
    <div className="row g-4">
      <div className="col-12 col-xl-12 order-1 order-xl-0">
   <ChartReport chartData={data}/>
      </div>
    </div>

   
    

  
  </div>
  )
}

export default PerformenceResult