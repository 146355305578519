import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { Link } from "react-router-dom";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";

const LateHalfDay = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const [data, setData] = useState({
    txtHead_Id: "All",
    Type: "",
    Late_Mark_In: "",
    Late_Mark_Out: "",
    HalfDay_Mark_In: "",
    HalfDay_Mark_Out: "",
    Absentism_In: "",
    Absentism_Out: "",
    Late_Amt: "0",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userData?.Org_Id) {
      fetchData();
    }
  }, [userData]);

  const fetchData = async () => {
    try {
      showLoader();
      const result = await axios.get(
        `${apiUrl}/getFacultyLateMark?OrgId=${userData?.Org_Id}`
      );
      setData(result.data);
      console.log(result.data);
      hideLoader();
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const handleUpdate = () => {
    const updateBills = {
      ...data,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateFacultyLateMark/${data.Sr_No}`, updateBills)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Late & HalfDay Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              fetchData();
              handleCancelEdit();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updateBills = {
      ...data,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addFacultyLateMark`, updateBills)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Late & HalfDay Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              fetchData();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate if the value is an integer
    const isInteger = /^\d*$/.test(value);

    if (isInteger || value === "") {
      setData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">Extending Time/Minutes</div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <select
                name="Branch"
                id="Branch"
                className="form-select form-select-sm"
                value={data.txtHead_Id}
                disabled
              >
                <option value={"All"}>All Branches</option>
              </select>
            </div>
          </div>
        </div>
      </h4>
      <div className="row g-4 mt-2">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="card-block">
                <div className="form-body">
                  {data?.Sr_No ? (
                    <div className="col-md-12">
                      <div
                        className="table table-bordered border-primary table-hover table-responsive"
                        style={{ overflowX: "-webkit-paged-x" }}
                      >
                        <div>
                          <form>
                            <table
                              className="mytable table  table-hover"
                              cellspacing="0"
                              cellpadding="1"
                              align="Center"
                              rules="cols"
                              id="ContentPlaceHolder1_gvStudents"
                              style={{
                                color: "black",
                                backgroundColor: "white",
                                borderColor: "#DEDFDE",
                                borderWidth: "1px",
                                borderStyle: "none",
                                borderCollapse: "collapse",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <th align="center" scope="col">
                                    LateMark In
                                  </th>
                                  <th align="center" scope="col">
                                    HalfDay In
                                  </th>
                                  <th align="center" scope="col">
                                    Absent In
                                  </th>
                                  <th align="center" scope="col">
                                    LateMark Out
                                  </th>
                                  <th align="center" scope="col">
                                    HalfDay Out
                                  </th>
                                  <th align="center" scope="col">
                                    Absent Out
                                  </th>
                                  <th align="center" scope="col">
                                    Late Count
                                  </th>
                                  <th scope="col">&nbsp;</th>
                                </tr>
                                <tr>
                                  <td align="center">
                                    <input
                                      name="Late_Mark_In"
                                      type="text"
                                      value={data.Late_Mark_In}
                                      required
                                      id="Late_Mark_In"
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          Late_Mark_In: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    <input
                                      name="HalfDay_Mark_In"
                                      type="text"
                                      value={data.HalfDay_Mark_In}
                                      required
                                      id="HalfDay_Mark_In"
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          HalfDay_Mark_In: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    <input
                                      name="Absentism_In"
                                      type="text"
                                      required
                                      id="Absentism_In"
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      value={data.Absentism_In}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          Absentism_In: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    <input
                                      name=""
                                      type="text"
                                      value={data.Late_Mark_Out}
                                      required
                                      id=""
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          Late_Mark_Out: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    <input
                                      name="txtHalfDayOut"
                                      type="text"
                                      value={data.HalfDay_Mark_Out}
                                      required
                                      id="txtHalfDayOut"
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          HalfDay_Mark_Out: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    <input
                                      name="Absentism_Out"
                                      type="text"
                                      value={data.Absentism_Out}
                                      required
                                      id="Absentism_Out"
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          Absentism_Out: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    <input
                                      name="Late_Amt"
                                      type="text"
                                      value={data.Late_Amt}
                                      required
                                      id="Late_Amt"
                                      className="form-control form-control-sm"
                                      disabled={!isEditing}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        setData({
                                          ...data,
                                          Late_Amt: numericInput,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td align="center">
                                    {isEditing && (
                                      <Link
                                        type="submit"
                                        onClick={handleUpdate}
                                        cursor="pointer"
                                        style={{
                                          display: "inlineBlock",
                                          width: "50px",
                                        }}
                                      >
                                        Save
                                      </Link>
                                    )}
                                    &nbsp;
                                    {isEditing && (
                                      <Link
                                        onClick={handleCancelEdit}
                                        style={{
                                          display: "inlineBlock",
                                          width: "50px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Cancel
                                      </Link>
                                    )}
                                    &nbsp;
                                    {!isEditing && (
                                      <Link
                                        onClick={handleEditClick}
                                        style={{
                                          display: "inlineBlock",
                                          width: "50px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-label" htmlFor="Late_Mark_In">
                            Late Mark In<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              name="Late_Mark_In"
                              type="text"
                              required
                              id="Late_Mark_In"
                              className="form-control"
                              value={data.Late_Mark_In}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label
                            className="form-label"
                            htmlFor="HalfDay_Mark_In"
                          >
                            Half Day In<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              name="HalfDay_Mark_In"
                              type="text"
                              required
                              id="HalfDay_Mark_In"
                              className="form-control"
                              value={data.HalfDay_Mark_In}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="form-label" htmlFor="Absentism_In">
                            Absentism In<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              name="Absentism_In"
                              type="text"
                              required
                              id="Absentism_In"
                              className="form-control"
                              value={data.Absentism_In}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="form-label" htmlFor="Late_Mark_Out">
                            Late Mark Out<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              name="Late_Mark_Out"
                              type="text"
                              required
                              id="Late_Mark_Out"
                              className="form-control"
                              value={data.Late_Mark_Out}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label
                            className="form-label"
                            htmlFor="HalfDay_Mark_Out"
                          >
                            Half Day Out<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              name="HalfDay_Mark_Out"
                              type="text"
                              required
                              id="HalfDay_Mark_Out"
                              className="form-control"
                              value={data.HalfDay_Mark_Out}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="form-label" htmlFor="Absentism_Out">
                            Absentism Out<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              name="Absentism_Out"
                              type="text"
                              required
                              id="Absentism_Out"
                              className="form-control"
                              value={data.Absentism_Out}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 offset-md-3">
                          <label className="form-label" htmlFor="Late_Amt">
                            Late Amount
                          </label>
                          <div>
                            <input
                              name="Late_Amt"
                              type="text"
                              value={data.Late_Amt}
                              id="Late_Amt"
                              className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default LateHalfDay;
