import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { formatDate } from "../../../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../../../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../../../Students/AgGridTable";
import UpdateStatus from './UpdateModal';



const Subjaect = () => {
    const [table, setTable] = useState(false);
    const [dataTable, setDataTable] = useState(null);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [isModal, setIsModal] = useState(false);
    const [isModal1, setIsModal1] = useState(false);
    const [indexValue, setIndex] = useState();
    const [data, setBillData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [CategoryOptions, setCategoryOptions] = useState([]);
    const [selectedstatus, setSelectedstatus] = useState("Uncleared");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [StdOptions, setStdOptions] = useState([]);
    const [BatchOptions, setBatchOptions] = useState([]);
    const [SubOptions, setSubOptions] = useState([]);
    const [Std_Id, setStdId] = useState('');
    const [Batch_ID, setBatchID] = useState('');
    const [Sub_ID, setSubID] = useState('');
    //   const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
    //   const [toDate, setToDate] = useState(dayjs().endOf("month"));
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: "",
    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView,
            });
        }
    }, [userData, Std_Id]);

    //console.log(design);

    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(
                `${apiUrl}/getTopicMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}&Sub_Id=${Sub_ID}&HeadId=${userData?.Head_Id}`);
            setBillData(res.data);
            setFilteredData(res.data);
            console.log(res.data);
            setTable(true);

            if (userData && userData.Org_Id && userData?.Head_Id) {
                showLoader();
                const std = await axios.get(
                    `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
                );


                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
                //console.log(getStd);
            }

            if (userData && userData.Org_Id && Std_Id) {

                const batch = await axios.get(
                    `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
                );


                const getBatch = batch.data.map((category) => ({
                    label: category.Batch_Name,
                    value: category.Batch_Id,
                }));
                setBatchOptions(getBatch);

                const sub = await axios.get(
                    `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
                );


                const getSub = sub.data.map((category) => ({
                    label: category.Sub_Name,
                    value: category.Sub_Id,
                }));
                setSubOptions(getSub);
                // console.log(getBatch);
            }
            console.log(res.data);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    //Search Box
    const [searchTerm, setSearchTerm] = useState("");
    useEffect(() => {
        if (data.length > 0) {
            const newFilteredData = data.filter(
                (row) =>
                    row?.Std_Name?.toLowerCase().includes(searchTerm) ||
                    row?.Topic_Name?.toLowerCase().includes(searchTerm)
                    ||
                    row?.sub_name?.toLowerCase().includes(searchTerm)

            );
            setFilteredData(newFilteredData);
        }
    }, [searchTerm, data]);


    useEffect(() => {

        const options = (props) => {

            return (

                <div className="dropdown font-sans-serif">
                    <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i
                            className="fas fa-ellipsis-v"
                            style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                    </button>
                    <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                    >
                        <div>

                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    showModal(props.rowIndex);
                                    console.log(props.rowIndex)
                                }}
                                className="dropdown-item cursor-pointer"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                <span> Add/Update</span>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        };

        const newAgcolumns = [
            { field: "StandardName" },
            { field: "SubjectName" },
            { field: "TopicName" },
            {
                headerName: "",
                field: "",
                cellRenderer: options,
                width: 50,
                maxWidth: 50,
            },
        ];
        setAgcolumns(newAgcolumns);
    }, []);

    useEffect(() => {
        if (filteredData.length > 0) {
            const transformedData = filteredData.map(row => {
                if (typeof row.Topic_Name === 'string' && row.Topic_Name !== null) {
                    const subjectsArray = row.Topic_Name.split(';');
                    const subjects = subjectsArray.map(subject => {
                        if (typeof subject === 'string') {
                            const [Topic_Id, Topic_Name] = subject.split(',');
                            const trimmedSubId = Topic_Id ? Topic_Id.trim() : '';
                            const trimmedSubName = Topic_Name ? Topic_Name.trim() : '';
                            return { Topic_Id: trimmedSubId, Topic_Name: trimmedSubName };
                        } else {
                            return { Topic_Id: '', Topic_Name: '' };
                        }
                    });

                    const subNames = subjects.map(subject => subject.Topic_Name).filter(Boolean).join(', ');

                    return { StandardName: row.std_Name, SubjectName: row.sub_name, TopicName: subNames };
                } else {
                    return { StandardName: row.std_Name, SubjectName: '', TopicName: '' };
                }
            });
            setAgrows(transformedData);
        }
    }, [filteredData]);




    const deleteUser = (Id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(Id);
            }
        });
    };
    async function handleDelete(Id) {
        showLoader();
        // console.log(Id)
        const obj = {
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            Head_Id: userData.Head_Id,
            Head_Name: userData.Head_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            User_Name: userData.UserName,
        };

        try {
            await axios.delete(`${apiUrl}/deleteHomework/${Id}`, { data: obj });
            Swal.fire("Deleted!", "Home Work record has been deleted.", "success");
            fetchData();
        } catch (error) {
            console.error("Error while deleting Home Work record:", error);
            Swal.fire("Error", "Failed to delete Home Work record.", "error");
        } finally {
            hideLoader();
        }
    }

    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };



    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Topics", 20, 10);

        const columns = ["Sr No", 'Standard', "Subject", "Topic"];

        const rows = filteredData.map((row, index) => {
            const subjectString = row.Topic_Name;
            const subjectsArray = subjectString.split(";");

            const subjectNamesString = subjectsArray.map(subject => {
                const [, subjectName] = subject.split(",") ?? [];
                return subjectName?.trim();
            }).filter(Boolean).join(", ");

            return [
                index + 1,
                row.std_Name,
                row.sub_name,
                subjectNamesString
            ];
        });

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Topics.pdf");
    };

    const DownloadExcel = () => {
        const columns = ["Sr No", 'Standard', "Subject", "Topic"];
        const rows = [columns];

        filteredData.forEach((row, index) => {
            const subjectString = row.Topic_Name;
            const subjectsArray = subjectString.split(";");

            const subjectNamesString = subjectsArray.map(subject => {
                const [, subjectName] = subject.split(",") ?? [];
                return subjectName?.trim();
            }).filter(Boolean).join(", ");

            rows.push([
                index + 1,
                row.std_Name,
                row.sub_name,
                subjectNamesString
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Topics");

        XLSX.writeFile(workBook, "Topics.xlsx");
    };

    const showModal = (index) => {
        setIndex(index);
        setIsModal(true);
    };
    const closed = () => {
        setIsModal(false);
        fetchData();
    };
    const showModal1 = () => {
        setIsModal1(true);
    };
    const closed1 = () => {
        setIsModal1(false);
        fetchData();
    };


    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true,
        });
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true,
        });
    };

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-3">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black">{row.std_Name}</h5>

                                {/* <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Subject :{" "}
                                    <span className="ms-1 text-1100">{row.sub_name}</span>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="me-3 flex-1">

                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Subject :{" "}
                                    <span className="ms-1 text-1100">
                                        {row.sub_name}
                                    </span>
                                </p>
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Topic :{" "}
                                    <span className="ms-1 text-1100">
                                        {row.Topic_Name && row.Topic_Name
                                            .split(";")
                                            .map(subject => {
                                                const [, subjectName] = subject.split(",") ?? [];
                                                return subjectName?.trim();
                                            })
                                            .filter(Boolean)
                                            .join(", ")
                                        }
                                    </span>
                                </p>

                            </div>
                        </div>

                        <div className="col-md-1">
                            <div className="font-sans-serif">
                                <button
                                    style={{ padding: 0 }}
                                    className="btn btn-link"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i
                                        className="fas fa-ellipsis-v"
                                        style={{ fontSize: "14px", color: "gray" }}
                                    ></i>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <div>

                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showModal(row.rowIndex);
                                            }}
                                            className="dropdown-item cursor-pointer"
                                        >

                                            <span> Add/Update</span>
                                        </Link>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {design.dataView === "Tile View" && (
                    <div
                        key={index}
                        className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
                    >
                        <div className="card h-100 hover-actions-trigger">
                            <div className="card-body" style={{ padding: "15px" }}>
                                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                    <div
                                        className="row justify-content-between me-1"
                                        style={{ flex: "auto", marginTop: "8px" }}
                                    >
                                        <div className="col-11 pe-1">
                                            <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                                                {row.std_Name}
                                            </h6>
                                            {/* <p
                                                className="mb-0 profiledetail lh-1"
                                                style={{ fontSize: "13px" }}
                                            >
                                                Subject :{" "}
                                                <span className="ms-1 text-1100">
    {row.sub_name}
</span>


                                            </p> */}
                                        </div>
                                        <div className="end-0 col-1 p-0 ">
                                            <button
                                                style={{ padding: 0 }}
                                                className="btn btn-link"
                                                id="dropdownMenuLink"
                                                href="#"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-end py-0"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <div>

                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            showModal(row.rowIndex);
                                                        }}
                                                        className="dropdown-item cursor-pointer"
                                                    >
                                                        <i
                                                            className="fas fa-pen"
                                                            style={{ color: "blue", fontSize: "14px" }}
                                                        ></i>
                                                        <span> Add/Update</span>
                                                    </Link>

                                                </div>
                                                <div className="d-flex align-items-center mb-2">
                                                    {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                                    <p className="mb-0 profiledetail text-truncate lh-1">Subject : <span className="fw-semi-bold ms-1">{row.sub_name}</span></p>
                                                </div>
                                                <div className="d-flex align-items-center mb-2">
                                                    {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                                                    <p className="mb-0 profiledetail text-truncate lh-1">Topic : <span className="fw-semi-bold ms-1"> {row.Topic_Name && row.Topic_Name
                                                        .split(";")
                                                        .map(subject => {
                                                            const [, subjectName] = subject.split(",") ?? []; // Use the nullish coalescing operator
                                                            return subjectName?.trim();
                                                        })
                                                        .filter(Boolean)
                                                        .join(", ")
                                                    }</span></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                )}
            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="content">
            <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
                <div className="container-fluid">
                    {/* Sidebar */}
                    <div id="sidebar-wrapper">
                        <div className="row g-4">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div
                                    className="card shadow-none border border-300 my-2"
                                    data-component-card="data-component-card"
                                >
                                    <div className="row justify-content-center d-flex p-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Branch">
                                                        Branch
                                                    </label>
                                                    <HeadMasterDropDown
                                                        label="Branch"
                                                        name="Branch"
                                                        id="Branch"
                                                    //onChange={(e) => setBranch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        From Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        //value={fromDate?.format("YYYY-MM-DD")}
                                                        onChange={(date) =>
                                                            setFromDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        To Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        // value={toDate?.format("YYYY-MM-DD")}
                                                        onChange={(date) =>
                                                            setToDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Standard">
                                                        Standard :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Standard"
                                                        disabled={userData?.Head_Id === ''}
                                                        value={Std_Id}
                                                        onChange={(e) => {
                                                            setStdId(e.target.value);
                                                            setBatchID('All');
                                                        }}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {StdOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        Batch :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="example-select"
                                                        value={Batch_ID}
                                                        disabled={Std_Id === ''}
                                                        onChange={(e) => setBatchID(e.target.value)}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {BatchOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Sub_ID">
                                                        Subject :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="example-select"
                                                        value={Sub_ID}
                                                        disabled={Std_Id === ''}
                                                        onChange={(e) => setSubID(e.target.value)}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {SubOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center p-2">
                                        <div className="col-md-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm text-center"
                                                onClick={handleDateRangeFilter}
                                            >
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="row justify-content-between">
                            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                                Topics
                                <a
                                    className="anchorjs-link "
                                    aria-label="Anchor"
                                    href="#basic-form"
                                    style={{ paddingLeft: "0.375em" }}
                                ></a>
                            </h4>
                            <div className="col-md-12 text-center mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search..."
                                    style={{
                                        float: "left",
                                        display: "inline-block",
                                        width: "200px",
                                    }}
                                ></input>
                                <Link  onClick={(e) => {
                                                e.preventDefault();
                                                showModal1();
                                            }} style={{ float: "right"}}>
                                    <button className="btn btn-primary btn-sm text-center">
                                        Add New Topic
                                    </button>
                                </Link>
                                <button
                                    type="button"
                                    className="btn bg-200 filterButton collapsed mr-2"
                                    data-toggle="collapse"
                                    onClick={toggle}
                                    data-target="#navbar"
                                    aria-expanded="false"
                                    aria-controls="navbar"
                                    style={{ float: "right", marginRight: "10px" }}
                                >
                                    <i className="fa fa-filter"></i>
                                </button>
                                <button
                                    className="form-control dropdown-toggle viewButton  mb-2"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                        float: "right",
                                        marginRight: "10px",
                                        width: "auto",
                                        padding: "4px 10px",
                                    }}
                                >
                                    <>
                                        {design.dataView === "Table View" && (
                                            <TableChartOutlinedIcon />
                                        )}
                                    </>
                                    <>
                                        {design.dataView === "List View" && (
                                            <FormatListBulletedIcon />
                                        )}
                                    </>
                                    <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <Link
                                        onClick={(e) => {
                                            setDesign({
                                                ...design,
                                                dataView: "Table View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Table View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <TableChartOutlinedIcon />
                                        <span> Table View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "List View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "List View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <FormatListBulletedIcon />
                                        <span> List View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "Tile View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Tile View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <GridViewIcon />
                                        <span> Tile View</span>
                                    </Link>
                                </div>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to PDF</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadPDF()}
                                        style={{ float: "right", padding: "5px 10px 5px 5px" }}
                                    >
                                        <i
                                            className="fas fa-file-pdf"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to Excel</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadExcel()}
                                        style={{ float: "right", padding: "5px 5px 5px 10px" }}
                                    >
                                        <i
                                            className="fas fa-file-excel"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                {filteredData.length === 0 && (
                                    <h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
                            </div>
                            {design.dataView === "Table View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    {Agrows.length > 0 && (
                                        <AgGridTable row={Agrows} column={Agcolumns} />
                                    )}
                                </div>
                            )}
                            {design.dataView === "Tile View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">{displayData}</div>
                                </div>
                            )}
                            {design.dataView === "List View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                            <div className="card h-100 hover-actions-trigger">
                                                <div
                                                    className="card-body"
                                                    style={{ paddingTop: "5px" }}
                                                >
                                                    {displayData}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(design.dataView === "List View" ||
                                design.dataView === "Tile View") && (
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        pageCount={pageCount}
                                        pageRangeDisplayed={1}
                                        marginPagesDisplayed={1}
                                        breakAriaLabels={{
                                            forward: "Jump forward",
                                            backward: "Jump backward",
                                        }}
                                        onPageChange={changePage}
                                        containerClassName={"pagination"}
                                        disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                                        previousLinkClassName={"btn btn-outline-primary me-2"}
                                        nextLinkClassName={"btn btn-outline-primary ms-2"}
                                        activeLinkClassName={"customclass ms-2 me-2"}
                                        pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {isModal && (
                    <UpdateStatus
                        open={isModal}
                        onClose={closed}
                        updateData={data[indexValue]}
                        opration='added'
                    />
                )}
                {isModal1 && (
                    <UpdateStatus
                        open={isModal1}
                        onClose={closed1}
                        opration='add'
                    />
                )}
                {loader}
            </div>
        </div>
    );
};

export default Subjaect;
