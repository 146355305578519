import React from 'react'
import Voucher from './Voucher_Entry'
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";


const AddStudentRegistration = () => {
  return (
    <div className="content">
    <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
    <div className="row">
                        <div className="col-md-6">
                            Voucher Entry
                        </div>

                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                            <div className="col-md-5">
                                <HeadMasterDropDown
                                    label="Branch"
                                    name="Branch"
                                    id="Branch"


                                />

                            </div></div>
                    </div>
    </h4>
    <div className="row g-4">
      <div className="col-12 col-xl-12 order-1 order-xl-0">
        <Voucher/>
      </div>
    </div>

   
    

  
  </div>
  )
}

export default AddStudentRegistration