import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate } from "react-router-dom";
import GenderDropDown from "../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BoardDropDown from "../DropDowns/BoardDropDown";
import SchoolDropdown from "../DropDowns/SchoolDropDown";
import Dropdown from "../DropDowns/DropDown";
import YearDropDown from "../DropDowns/YearDropDown";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import Select from "react-select";

const AddUpdate = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const [AssignedToOptions, setAssignedToOptions] = useState([]);
    const [SourceOptions, setSourceOptions] = useState([]);
    const [CourseOptions, setCourseOptions] = useState([]);
    const [otherReference, setOtherReference] = useState('');
    const [errors, setErrors] = useState({});
    const [isValidMobile1, setIsValidMobile1] = useState(true);
    const [isValidFatherMobile, setIsValidFatherMobile] = useState(true);
    const [isValidMontherMobile, setIsValidMotherMobile] = useState(true);
    const [Student, setStudent] = useState({
        Enquiry_No: "",
        Enquiry_Type: "Student",
        First_Name: "",
        Middle_Name: "",
        Last_Name: "",
        Subhead_Id: "",
        Std_Id: "",
        DOB: "",
        Gender: "",
        Religion: "",
        Caste: "",
        Address: "",
        Mobile1: "",
        Mobile2: "",
        Email: "",
        Qualification: "",
        College_Name: "",
        Qualification_Result: "",
        Father_Name: "",
        Father_Mobile: "",
        Mother_Name: "",
        Mother_Mobile: "",
        Telephone: "",
        Parent_Emai: "",
        Father_Office_Address: "",
        Reference: "",
        Friend_Name1: "",
        Friend_Mobile1: "",
        Friend_Name2: "",
        Friend_Mobile2: "",
        Enquiry_Subject: "",
        Enquiry_Description: "",
        Enquiry_For: "",
        Latest_Remarks: "",
        Latest_Followup_Taken_On: "",
        Next_Followup_On: "",
        Status: "Interested",
        Board_Id: "",
        Board_Name: "",
        Academic_Year: "",
        Assigned_To: "",
        Course_ID: "",
        Course_Name: "",
        User_Id: "",
        Org_Id: "",
        Org_Name: "",
        Head_Id: "",
        Head_Name: "",
        User_Role: "",
        Username: "",
        Name: "",

    });

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();

                    const Source = await axios.get(
                        `${apiUrl}/getSource?OrgId=${userData.Org_Id}`
                    );


                    const getSource = Source.data.map((category) => ({
                        label: category.Source_Name,
                        value: category.Source_Id,
                    }));
                    setSourceOptions(getSource);
                    if (userData && userData.Org_Id && userData.Head_Id) {
                        const getAssignedTo = await axios.get(
                            `${apiUrl}/getAssignedTo?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
                        );


                        const option = getAssignedTo.data.map((category) => ({
                            label: category.Name,
                            value: category.UserId,
                        }));
                        setAssignedToOptions(option);
                    }
                    if (userData && userData.Org_Id) {
                        const Course = await axios.get(
                            `${apiUrl}/getCourse?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id ? userData.Head_Id : ''}`
                        );


                        const getCourse = Course.data.map((category) => ({
                            label: category.Course_Name,
                            value: category.Course_Id,
                        }));
                        setCourseOptions(getCourse);

                    }

                    if (userData && userData.Org_Id && userData.Head_Id) {
                        const getLastEnquiryNo = await axios.get(
                            `${apiUrl}/getLastEnquiryNo?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`
                        );
                        setStudent((prevStudent) => ({
                            ...prevStudent,
                            Enquiry_No: getLastEnquiryNo.data,
                            Head_Id: userData.Head_Id,
                            Head_Name: userData.Head_Name
                        }));
                    }
                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, userData?.Head_Id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudent((prevStudent) => ({
            ...prevStudent,
            [name]: value,
        }));
    };




    const handleSubmit = (event) => {
        event.preventDefault();

        const updateStudent = {
            ...Student,
            Reference: Student.Reference === 'Other' ? otherReference : Student.Reference,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            Username: userData.Username,
            Name: userData.Org_Name,
            UserName: userData.UserName
        };

        const errors = {};

        if (!Student.Board_Id) {
            errors.Board_Id = "Please Select Board";
        }


        if (Object.keys(errors).length === 0) {

            showLoader();
            axios
                .post(`${apiUrl}/addEnquiry`, updateStudent)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Student Enquiry Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/viewEnquiry");
                        }
                    });
                    hideLoader();

                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();

                });
        } else {
            setErrors(errors);
        }
    };
    //console.log(userData);

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                New Enquiry
                <a
                    className="anchorjs-link "
                    aria-label="Anchor"
                    href="#basic-form"
                    style={{ paddingLeft: "0.375em" }}
                ></a>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div
                                className="collapse code-collapse"
                                id="basic-form-code"
                            ></div>
                            <div className="p-4 code-to-copy">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Enquiry Details</h5>
                                    </div>

                                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                                        <div className="col-md-5">
                                            <HeadMasterDropDown
                                                label="Branch"
                                                name="Branch"
                                                id="Branch"

                                            />

                                        </div></div>
                                </div>

                                <hr />
                                <form onSubmit={handleSubmit}>
                                    <div className="row">

                                        <div className="mb-3 col-md-2">
                                            <label className="form-label" htmlFor="Board">
                                                Board<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <BoardDropDown
                                                label="Board"
                                                name="Board"
                                                id="Board"
                                                isClearable
                                                required
                                                //value={Student.Board_Id}
                                                onChange={(e) => {

                                                    setStudent({
                                                        ...Student,
                                                        Board_Id: e ? e.label : "",
                                                        Board_Name: e ? e.label : "",
                                                    });
                                                }}

                                            />
                                            <span style={{ color: "Red" }}>{errors.Board_Id}</span>
                                        </div>

                                        <div className="mb-3 col-md-1">
                                            <label className="form-label" htmlFor="Enquiry_No">
                                                Enquiry No
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="Enquiry_No"
                                                id="Enquiry_No"
                                                disabled
                                                value={Student.Enquiry_No}
                                                placeholder="Enter Enquiry No"
                                            //onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>


                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="First_Name">
                                                First Name<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="First_Name"
                                                id="First_Name"
                                                required
                                                placeholder="Enter First Name"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Middle_Name ">
                                                Middle Name
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="Middle_Name"
                                                id="Middle_Name"
                                                placeholder="Enter Middle Name"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Last_Name">
                                                Last Name
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="Last_Name"
                                                id="Last_Name"
                                                placeholder="Enter Last Name"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="DOB">
                                                DOB
                                            </label>

                                            <input
                                                className="form-control form-control-sm"
                                                name="DOB"
                                                id="DOB"
                                                type="date"
                                                placeholder="Select Date"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <GenderDropDown
                                                label="Gender"
                                                name="Gender"
                                                value={Student.Gender}
                                                id="Gender"
                                                onChange={(e) => {
                                                    const selectedIndex = e.target.selectedIndex;
                                                    const selectedOption = e.target[selectedIndex];
                                                    setStudent({
                                                        ...Student,
                                                        Gender: selectedOption.value
                                                        //Sub_Name: selectedOption.label,
                                                    });
                                                }}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>

                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Mobile1">
                                                Mobile
                                            </label>
                                            <input
                                                className={`form-control form-control-sm ${isValidMobile1 ? "" : "is-invalid"
                                                    }`}

                                                type="text"
                                                name="Mobile1"
                                                value={Student.Mobile1}
                                                id="Mobile1"
                                                placeholder="Enter Mobile No"
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const numericInput = input.replace(/[^0-9]/g, "");
                                                    const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                        numericInput
                                                    );
                                                    setIsValidMobile1(isValid);
                                                    if (isValid || numericInput === "") {
                                                        setStudent({
                                                            ...Student,
                                                            Mobile1: numericInput,
                                                        });
                                                    }
                                                }}

                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Email">
                                                Student Email
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="email"
                                                name="Email"
                                                id="Email"
                                                placeholder="Enter Student Email"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="mb-3 col-md-12">
                                            <label className="form-label" htmlFor="Address">
                                                Address
                                            </label>
                                            <textarea
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Address"
                                                id="Address"
                                                placeholder="Enter Address..."
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                    </div>
                                    <h5 className="mt-2">Other Details</h5>
                                    <hr />
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <SchoolDropdown
                                                label="School/College Name"
                                                name="College_Name"
                                                id="College_Name"
                                                isClearable={true}
                                                onChange={(e) => {
                                                    setStudent({
                                                        ...Student,
                                                        College_Name: e ? e.label : "",
                                                    });
                                                }}
                                            />

                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" htmlFor="Qualification">
                                                Previous Standard
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="Qualification"
                                                id="Qualification"
                                                placeholder="Enter Previous Standard"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label
                                                className="form-label"
                                                htmlFor="Qualification_Result"
                                            >
                                                Previous Percentage
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="Qualification_Result"
                                                id="Qualification_Result"
                                                placeholder="Enter Previous Percentage"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                    </div>
                                    <h5 className="mt-2">Parent Details</h5>
                                    <hr />
                                    <div className="row">
                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Father_Name">
                                                Father Name<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Father_Name"
                                                id="Father_Name"
                                                required
                                                placeholder="Enter Father Name"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Father_Mobile">
                                                Father Mobile<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className={`form-control form-control-sm ${isValidFatherMobile ? "" : "is-invalid"
                                                    }`}
                                                type="text"
                                                name="Father_Mobile"
                                                required
                                                id="Father_Mobile"
                                                maxLength={10}
                                                value={Student.Father_Mobile}
                                                placeholder="Enter Father Mobile"
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const numericInput = input.replace(/[^0-9]/g, "");
                                                    const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                        numericInput
                                                    );
                                                    setIsValidFatherMobile(isValid);
                                                    if (isValid || numericInput === "") {
                                                        setStudent({
                                                            ...Student,
                                                            Father_Mobile: numericInput,
                                                        });
                                                    }
                                                }}

                                            />
                                            {!isValidFatherMobile && (
                                                <div className="invalid-feedback">
                                                    Invalid mobile number.
                                                </div>
                                            )}
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Mother_Name">
                                                Mother Name
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Mother_Name"
                                                id="Mother_Name"
                                                placeholder="Enter Mother Name"
                                                onChange={handleChange}
                                            />

                                        </div>

                                        <div className="mb-3 col-md-3">
                                            <label className="form-label" htmlFor="Mother_Mobile">
                                                Mother Mobile
                                            </label>
                                            <input
                                                className={`form-control form-control-sm ${isValidMontherMobile ? "" : "is-invalid"
                                                    }`}
                                                type="text"
                                                name="Mother_Mobile"
                                                id="Mother_Mobile"
                                                maxLength={10}
                                                value={Student.Mother_Mobile}
                                                placeholder="Enter Mother Mobile"
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const numericInput = input.replace(/[^0-9]/g, "");
                                                    const isValid = /^[6-9][0-9]{0,9}$/.test(
                                                        numericInput
                                                    );
                                                    setIsValidMotherMobile(isValid);
                                                    if (isValid || numericInput === "") {
                                                        setStudent({
                                                            ...Student,
                                                            Mother_Mobile: numericInput,
                                                        });
                                                    }
                                                }}

                                            />
                                            {!isValidMontherMobile && (
                                                <div className="invalid-feedback">
                                                    Invalid mobile number.
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-3 col-md-2">
                                            <label
                                                className="form-label"
                                                htmlFor="Next_Followup_On"
                                            >
                                                Next Followup Date
                                                <span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="Next_Followup_On"
                                                required
                                                id="Next_Followup_On"
                                                placeholder="Next Followup Date"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-2">
                                            <label className="form-label" htmlFor="Assigned_To">
                                                Assigned To<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <Select
                                                options={AssignedToOptions}
                                                name="Assigned_To"
                                                id="Assigned_To"
                                                isClearable
                                                required
                                                onChange={(e) => {
                                                    setStudent({
                                                        ...Student,
                                                        Assigned_To: e ? e.label : "",
                                                    });
                                                }}

                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-2">
                                            <label className="form-label" htmlFor="Source">
                                                Source
                                            </label>
                                            <Select
                                                options={SourceOptions}
                                                name="Source"
                                                id="Source"
                                                isClearable
                                                onChange={(e) => {
                                                    console.log(e?.label)
                                                    setStudent({
                                                        ...Student,
                                                        //Assigned_To: selectedOption.value,
                                                        Reference: e?.label || "",
                                                    });
                                                }}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-2">
                                            <label className="form-label" htmlFor="Course">
                                                Course<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <Select
                                                options={CourseOptions}
                                                name="Course"
                                                isClearable
                                                required
                                                id="Course"
                                                onChange={(e) => {
                                                    setStudent({
                                                        ...Student,
                                                        Course_ID: e ? e.value : "",
                                                        Course_Name: e ? e.label : "",
                                                        Std_Id: e ? e.value : "",
                                                        Enquiry_For: e ? e.label : "",
                                                    });
                                                }}

                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-2">
                                            <label className="form-label" htmlFor="Status">
                                                Status
                                            </label>
                                            <select
                                                className="form-select"
                                                required
                                                name="Status"
                                                id="Status"
                                                value={Student.Status}
                                                onChange={(e) => {
                                                    const selectedIndex = e.target.selectedIndex;
                                                    const selectedOption = e.target[selectedIndex];
                                                    setStudent({
                                                        ...Student,
                                                        //Assigned_To: selectedOption.value,
                                                        Status: selectedOption.label,
                                                    });
                                                }}
                                            >
                                                <option selected value="Interested">
                                                    Interested
                                                </option>
                                                <option value="Not Interested">Not Interested</option>
                                            </select>
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                        <div className="mb-3 col-md-2">
                                            <label className="form-label" htmlFor='Academic_Year'>
                                                Academic Year <span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <YearDropDown
                                                label="Academic_Year"
                                                name="Academic_Year"
                                                id="Academic_Year"
                                                required
                                                isClearable
                                                onChange={(e) => {
                                                    setStudent({
                                                        ...Student,
                                                        Academic_Year: e?.label || "",
                                                    });
                                                }}

                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                    </div>

                                    <div className={Student?.Reference === 'Other' ? "row" : "d-none"}>
                                        <div className="col-md-12 mt-3">
                                            <label className="form-label" htmlFor="Reference_Description">
                                                Reference Description
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Reference_Description"
                                                id="Reference_Description"
                                                placeholder="Enter Reference Description..."
                                                value={otherReference}
                                                onChange={(e) => {
                                                    setOtherReference(e.target.value);
                                                }}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </div>
    );
};

export default AddUpdate;
