import React, { useEffect, useState } from "react";
import $ from "jquery";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { AiFillMinusCircle } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { useNavigate, useParams, Link } from "react-router-dom";
import Dropdown from "../DropDowns/DropDown";
import "jspdf-autotable";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import { formatDate } from "../../Components/dateFormate";
import dayjs from "dayjs";
import "../Students/toggle.css";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
ChartJS.register(BarElement, CategoryScale, LinearScale, Legend);
// import UpdateVoucher from "./UpdateBillModal";

const TablePerformanceReport = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [chartData, setChartData] = useState({});
  const { filterData, setFilterData } = useUser();
  const [data, setExpnseData] = useState([]);
  const [vouData, setVouData] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [subjectOption, setSubjects] = useState([]);
  const [Sub_Id, setSubId] = useState("");
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  useEffect(() => {
    if (userData && userData?.Org_Id) {
      Load();
    
    }
  }, [userData, Sub_Id, Sr_No]);

  async function Load() {
    showLoader();

    const response = await axios.get(`${apiUrl}/getStudentSubjects/${Sr_No}`);
    const subjects = response.data.map((category) => ({
      label: category.Subject_Name,
      value: category.Subject_Id,
    }));
    setSubjects(subjects);

    if (Sub_Id !== "0" && Sub_Id===" ") {
      debugger;
      try {
        const res = await axios.get(`${apiUrl}/BindChart3/${Sr_No}/${Sub_Id}`);
        if (res.data) {
          const data = res.data;
    
          // Check if data exists and is an array
          if (Array.isArray(data)) {
            const labels = data.map((item) => item.Month);
            const datasets = [
              {
                label: "Percentage",
                data: data.map((item) => item.Percentage),
                fill: false,
                borderColor: "#A7C9F8",
                backgroundColor: "#A7C9F8",
              },
            ];
    
            // Setting the state for chart data
            setChartData({
              labels: labels,
              datasets: datasets,
            });
          } else {
            console.error("Data is not properly defined in the response.");
          }
        } else {
          console.error("No data received from the API.");
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    } else {
      debugger;
      try {
        const res = await axios.get(`${apiUrl}/BindChart/${Sr_No}`);
        if (res.data) {
          const data = res.data;
    
          // Check if data exists and is an array
          if (Array.isArray(data)) {
            const labels = data.map((item) => item.Month);
            const datasets = [
              {
                label: "Percentage",
                data: data.map((item) => item.Percentage),
                fill: false,
                borderColor: "#6495ED",
                backgroundColor: "#6495ED",
              },
            ];
    
            // Setting the state for chart data
            setChartData({
              labels: labels,
              datasets: datasets,
            });
          } else {
            console.error("Data is not properly defined in the response.");
          }
        } else {
          console.error("No data received from the API.");
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    }

    const result = await axios.get(
      `${apiUrl}/getStudentResult/${Sr_No}/${userData.Org_Id}`
    );
    if (result.data.length === 0) {
      setExpnseData([]);
      hideLoader();
    } else {
      setExpnseData(result.data);
      console.log(result.data);
      hideLoader();
    }
    return result;
  }

  async function innerDataLoad(Subject_Id) {
    debugger;
    showLoader();
    const expResult = await axios.get(
      `${apiUrl}/getTestResults?orgId=${userData.Org_Id}&subjectId=${Subject_Id}&id=${Sr_No}`
    );
    if (expResult.data.length === 0) {
      setVouData([]);
    } else {
      console.log(expResult.data);
      setVouData(expResult.data);
    }
    hideLoader();
  }

  useEffect(() => {
    if (userData) {
      Load();
    }
  }, [userData]);

  const FilterClick = () => {
    Load();
    innerTable("");
  };

  const [expanded, setExpanded] = useState(null);
  const innerTable = (index) => {
    if (expanded === index) {
      setExpanded(null); // Collapse the currently expanded row
    } else {
      setExpanded(index); // Expand the clicked row
      innerDataLoad(data[index].Subject_Id); // Load the data for the expanded row
    }
  };


  const handleType = async (event) => {

    const value = event.target.value;
    setSelectType(value);
  };


  const ChartOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Month", // X-axis title
        },
      },
      y: {
        title: {
          display: true,
          text: "Percentage", // Y-axis title
        },
      },
    },
  };

  const getFreshColor = (index) => {
    const colors = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];
    return colors[index % colors.length];
  };

  return (

    <div className="row mb-2">
    <div className="col-md-8">
      <div className="card mt-2">
        <div className="card-body" style={{ padding: "15px" }}>
          <h5 style={{ marginTop: "2px" }}>Exam Performance</h5>
          <div
            className="table-responsive mt-2"
            style={{ minHeight: "220px" }}
          >
            <table
              id="basic-datatable"
              className="table table-striped dt-responsive nowrap w-100"
            >
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Sr. No.
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Subject
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Obtained Marks
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Total Marks
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Percentage
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Present
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Absent
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  >
                    Total Tests
                  </th>
                  <th
                    className="text-center"
                    style={{ padding: "10px 5px" }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {data && data?.length > 0 ? (
                  data.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Sub_Name}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Obtained}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Total}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Percentage}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Present}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Absent}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          {item.Tests}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "10px 5px" }}
                        >
                          <a
                            className="text-dark"
                            data-bs-toggle="collapse"
                            href={`#ExamNo${index}`}
                            onClick={() => innerTable(index)}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`ExamNo${index}`}
                          >
                            <i>
                              {expanded === index ? (
                                <AiFillMinusCircle />
                              ) : (
                                <AiFillPlusCircle />
                              )}
                            </i>
                          </a>
                        </td>
                      </tr>
                      <tr
                        className={
                          expanded === index
                            ? "collapse show"
                            : "collapse"
                        }
                        id={`ExamNo${index}`}
                      >
                        <td colSpan={9}>
                          <table
                            id="basic-datatable"
                            className="table table-bordered dt-responsive nowrap w-100"
                          >
                            <thead className="table-secondary">
                              <tr>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Sr. No.
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Date
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  From Time
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  To Time
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Obt. Marks
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Total Marks
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Attendance
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Remark
                                </th>
                                <th
                                  className="text-center"
                                  style={{ padding: "10px 5px" }}
                                >
                                  Percentage
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {vouData && vouData.length > 0 ? (
                                vouData.map((item, index) => (
                                  <tr key={index}>
                                    <td
                                      className="text-center"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {index + 1}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {formatDate(item.Test_Date)}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.From_Time}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.To_Time}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.Marks_Obtained}
                                    </td>
                                    <td
                                      className="text-right"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.max}
                                    </td>
                                    <td
                                      className="text-right"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.Attendance}
                                    </td>
                                    <td
                                      className="text-right"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.Result_Remark}
                                    </td>
                                    <td
                                      className="text-right"
                                      style={{ padding: "10px 5px" }}
                                    >
                                      {item.Percentage.toFixed(2)}%
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    className="text-center text-danger"
                                    colSpan={9}
                                  >
                                    No Records Found!!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td
                      className="text-center text-danger"
                      colSpan={9}
                    >
                      No Records Found!!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-4">
      <div className="card mt-2">
        <div className="card-body" style={{ padding: "15px" }}>
          <h4 style={{ marginTop: "2px" }}>Exam Performance Chart</h4>
          <div className="col-md-12">
            <div className="mb-3">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="performanceOptions"
                >
                  Type
                </label>
                <select
                  className="form-select"
                  id="performanceOptions"
                  name="performanceOptions"
                  value={selectType}
                  onChange={handleType}
                  required
                >
                  <option value="line-chart">Line Chart</option>
                  <option selected value="bar-chart">Bar Chart</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <div className="form-group">
                <label className="form-label" htmlFor="Subject">
                  Subject:
                </label>
                <select
                  className="form-select form-select-sm"
                  id="Subject"
                  onChange={(e) => {
                    setSubId(e.target.value);
                  }}
                >
                  <option value="0" label="All"></option>
                  {subjectOption.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      label={option.label}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {Object.keys(chartData).length > 0 && (
            <>
              {selectType === "line-chart" && (
                <Line options={ChartOptions} data={chartData} />
              )}
              {selectType === "bar-chart" && (
                <Bar options={ChartOptions} data={chartData} />
              )}
              {selectType === "" && (
                <Bar options={ChartOptions} data={chartData} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
    {loader}
  </div>
    
  );
};

export default TablePerformanceReport;
