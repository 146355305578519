import React, { useEffect, useRef, useState, useMemo } from "react";
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

const Testing = ({ row, column, onSelectedRowsChange }) => {
    const [rowData, setRowData] = useState(row);
    const [selectedRows, setSelectedRows] = useState([]);
    const [colDefs, setColDefs] = useState([
        {
            headerCheckboxSelection: true, // Checkbox in the header for select all
            checkboxSelection: true, // Checkbox for each row
            width: 50, // Width of the checkbox column
        },
        ...column // Spread the existing columns
    ]);
    const gridApiRef = useRef(null);
    const key = useMemo(() => row.length, [rowData]);
    
    const onGridReady = (params) => {
        gridApiRef.current = params.api;
        resizeGrid();
    
        // Delay the selection to ensure grid is fully rendered
        setTimeout(() => {
            // Select all rows
            params.api.selectAll();
            
            // Get all selected nodes
            const selectedNodes = [];
            params.api.forEachNode(node => selectedNodes.push(node));
            const selectedData = selectedNodes.map(node => node.data);
    
            setSelectedRows(selectedData);
            if (onSelectedRowsChange) {
                onSelectedRowsChange(selectedData); // Pass selected rows to parent or handler
            }
        }, 0);
    };
    

    const resizeGrid = () => {
        if (gridApiRef.current) {
            gridApiRef.current.sizeColumnsToFit();
        }
    };

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
            suppressColumnFilter: true, // Disables the column filter textbox
            suppressColumnMenu: true, // Disables the column menu (build option)
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeGrid);
        return () => {
            window.removeEventListener('resize', resizeGrid);
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedNodes = gridApiRef.current.getSelectedNodes(); // Get selected nodes
        const selectedData = selectedNodes.map(node => node.data); // Get data from selected nodes
        setSelectedRows(selectedData); // Store selected rows in state
        if (onSelectedRowsChange) {
            onSelectedRowsChange(selectedData); // Pass selected rows to parent or handler
        }
    };

    return (
        <div className="ag-theme-quartz" >
            <AgGridReact
                key={key}
                rowData={rowData}
                pagination={true}
                paginationPageSize={10}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                onGridReady={onGridReady}
                rowSelection="multiple" // Enable multiple row selection
                onSelectionChanged={onSelectionChanged} // Handle row selection change
            />
        </div>
    );
}

export default Testing;
