import React from 'react'
import Student from './AddUpdateStudent'

const AddStudentRegistration = () => {
  return (
    <div className="content">
    <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
      New Registration
      <a
        className="anchorjs-link "
        aria-label="Anchor"
        href="#basic-form"
        style={{ paddingLeft: "0.375em" }}
      ></a>
    </h4>
    <div className="row g-4">
      <div className="col-12 col-xl-12 order-1 order-xl-0">
        <Student/>
      </div>
    </div>

   
    

  
  </div>
  )
}

export default AddStudentRegistration