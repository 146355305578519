import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { apiUrl } from "../../Components/apiConfig";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from '@mui/icons-material/TableView';
import Modal from "./adjustmentModal";
import { useUser } from "../../Components/UserContext";
import { Link } from "react-router-dom";
import { formatDate } from "../../Components/dateFormate";
const today = formatDate(new Date());

const PayRoll = ({ staffData }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [data, setAttendanceData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [monthDate, setDate] = useState([]);
  const { Sr_No } = useParams();
  const [OT, setOT] = useState(false);
  const [deduction, setDeduction] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [staff_Id, setStaff_Id] = useState();
  const [adjustmetDate, setAdjustmetDate] = useState();

  console.log(staffData);
  console.log(data);

  useEffect(() => {
    if (staffData?.Overtime_Consideration && staffData?.Deductions) {
      setDeduction(staffData?.Deductions === "N" ? false : true);
      setOT(staffData?.Overtime_Consideration === "N" ? false : true);
    }
  }, [staffData]);

  let marginTopValue;

  if (
    staffData?.Overtime_Consideration === "Y" &&
    staffData?.Deductions === "Y"
  ) {
    marginTopValue = "54px";
  } else if (
    (staffData?.Deductions === "Y" &&
      staffData?.Overtime_Consideration === "N") ||
    (staffData?.Deductions === "N" && staffData?.Overtime_Consideration === "Y")
  ) {
    marginTopValue = "154px";
  } else if (
    staffData?.Overtime_Consideration === "N" &&
    staffData?.Deductions === "N"
  ) {
    marginTopValue = "209px";
  }

  let totalDays = 0;
  let holidays = 0;
  let adjustment = 0;
  let leavedayCount = 0;
  let weekOffs = 0;
  let workingDays = 0;
  let presentDays = 0;
  let absentDays = 0;
  let lateMarks = 0;
  let halfDays = 0;
  let presentDayAmt = 0;
  let absentDaysAmt = 0;
  let lateMarksAmt = 0;
  let halfDaysAmt = 0;
  let totalDeduction = 0;
  let totalOTAmt;
  let NetTotal = 0;
  let GrossTotal = 0;
 let SalaryperDay =0;
 let hourlySalary = parseFloat(staffData?.Amount || 0).toFixed(2)
 if(staffData?.PayType === "Hourly"){

 }

  data.forEach((record) => {
    totalDays++;

    if (record.Status === "Week Off") {
      weekOffs++;
    } else if (record.Status === "Holiday") {
      holidays++;
    } else {
      workingDays++;
      if (record.Status === "Absent") {
        absentDays++;
      } else if (record.Status === "Late") {
        lateMarks++;
      } else if (record.Status === "Half Day") {
        halfDays++;
      } else if (record.Status === "Present") {
        presentDays++;
      } else if (record.Status === "Leave") {
        leavedayCount++;
      } else if (record.Adjustment !== "") {
        adjustment++;
      }
    }
  });

  totalDays = workingDays + holidays + weekOffs;

   SalaryperDay = (
    parseFloat(staffData?.Amount || 0) / parseFloat(totalDays)
  ).toFixed(2);

  GrossTotal = (
    parseFloat(SalaryperDay || 0) * parseFloat(presentDays || 0)
  ).toFixed(2);

  let HalfDaySal = parseFloat(SalaryperDay) / 2;

  // Calculate total deduction for absent days
  absentDaysAmt = absentDays * parseFloat(SalaryperDay);

  // Deduct one day's salary if there are 3 or more 'Late' statuses
  if (lateMarks >= 3 && lateMarks < 6) {
    lateMarksAmt = SalaryperDay;
  } else if (lateMarks >= 6 && lateMarks < 9) {
    lateMarksAmt = 2 * SalaryperDay;
  } else if (lateMarks >= 9) {
    lateMarksAmt = 3 * SalaryperDay;
  }

  halfDaysAmt = halfDays * parseFloat(HalfDaySal);
  // Calculate Total Deduction
  totalDeduction =
    parseFloat(absentDaysAmt) +
    parseFloat(lateMarksAmt) +
    parseFloat(halfDaysAmt);


      if (deduction && !OT) {
        //console.log(1);
        NetTotal = (
          parseFloat(SalaryperDay || 0) * parseFloat(presentDays || 0) +
          parseFloat(totalDeduction || 0)
        ).toFixed(2);
      } else if (OT && !deduction) {
        //console.log(1);
        NetTotal = (
          parseFloat(SalaryperDay || 0) * parseFloat(presentDays || 0) +
          parseFloat(totalOTAmt || 0)
        ).toFixed(2);
      } else if (deduction && OT) {
        //console.log(1);
        NetTotal = (
          parseFloat(SalaryperDay || 0) * parseFloat(presentDays || 0) +
          (parseFloat(totalDeduction || 0) + parseFloat(totalOTAmt || 0))
        ).toFixed(2);
      } else if (!deduction && !OT) {
        //console.log(1);
        NetTotal = (
          parseFloat(SalaryperDay || 0) * parseFloat(presentDays || 0)
        ).toFixed(2);
      }
    


  function calculateSalaryForWorkedHours(hours, minutes, salaryPerDay) {
    console.log(hours, minutes, salaryPerDay);
    // Convert worked hours and minutes to total minutes
    let totalWorkedMinutes = hours * 60 + parseInt(minutes);

    // Calculate the fraction of the day
    let fractionOfDay = totalWorkedMinutes / (24 * 60);

    // Calculate the salary for the worked hours
    let salaryForWorkedHours = fractionOfDay * salaryPerDay;

    return salaryForWorkedHours.toFixed(2); // Return the result rounded to 2 decimal places
  }

  if (staffData?.Overtime_Consideration === "Y") {
    function getTotalWorkedHours(punchIn, punchOut) {
      // Check if punchIn and punchOut are provided
      if (!punchIn || !punchOut) {
        return "00:00";
      }

      // Parse punchIn and punchOut
      let punchInTime = new Date("2000-01-01 " + punchIn);
      let punchOutTime = new Date("2000-01-01 " + punchOut);

      // Handle case where punchOut is on the next day (crossing midnight)
      if (punchOutTime < punchInTime) {
        punchOutTime.setDate(punchOutTime.getDate() + 1);
      }

      // Calculate the difference in milliseconds
      let difference = punchOutTime.getTime() - punchInTime.getTime();

      // Convert milliseconds to hours and minutes
      let hours = Math.floor(difference / (1000 * 60 * 60));
      let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      // Calculate total working minutes
      let totalWorkingMinutes = hours * 60 + minutes;

      // Calculate new hours and minutes from the total working minutes
      let newHours = Math.floor(totalWorkingMinutes / 60);
      let newMinutes = totalWorkingMinutes % 60;

      // Format result as 'HH:MM'
      let working = `${newHours}:${String(newMinutes).padStart(2, "0")}`;
      return working;
    }

    function getSumOfWorkedHours(data) {
      let totalWorkedHours = { hours: 0, minutes: 0 };

      data.forEach((entry) => {
        if (entry.Status === "Present") {
          let worked = getTotalWorkedHours(entry.InTime, entry.OutTime);
          let [hours, minutes] = worked.split(":").map(Number);

          totalWorkedHours.hours += hours;
          totalWorkedHours.minutes += minutes;
        }
      });

      // Normalize minutes to hours
      totalWorkedHours.hours += Math.floor(totalWorkedHours.minutes / 60);
      totalWorkedHours.minutes = totalWorkedHours.minutes % 60;

      return totalWorkedHours;
    }

    const totalWorkedHours = getSumOfWorkedHours(data);
    console.log(`${totalWorkedHours.hours}:${totalWorkedHours.minutes}`);

    function sumHoursWherePresent(data) {
      return data.reduce(
        (total, entry) => {
          if (entry.Status === "Present") {
            let [hours, minutes] = entry.Hours.split(":").map(Number);
            total.hours += hours;
            total.minutes += minutes;
          }
          return total;
        },
        { hours: 0, minutes: 0 }
      );
    }

    const hoursSum = sumHoursWherePresent(data);
    console.log(`${hoursSum.hours}:${hoursSum.minutes}`);

    function getWorkedHours(data) {
      let worked;

      data.forEach((entry) => {
        if (entry.Status === "Present") {
          worked = getTotalWorkedHours(entry.InTime, entry.OutTime);
        }
      });
    }

    // Function to convert hours and minutes to total minutes
    function toTotalMinutes(hours, minutes) {
      return hours * 60 + minutes;
    }

    // Function to convert total minutes back to hours and minutes
    function toHoursMinutes(totalMinutes) {
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;
      return { hours, minutes };
    }

    // Convert totalWorkedHours and hoursSum to total minutes
    let totalWorkedMinutes = toTotalMinutes(
      totalWorkedHours.hours,
      totalWorkedHours.minutes
    );
    let hoursSumMinutes = toTotalMinutes(hoursSum.hours, hoursSum.minutes);

    // Calculate the difference in minutes
    let differenceMinutes = Math.abs(totalWorkedMinutes - hoursSumMinutes);

    // Convert the difference back to hours and minutes
    let difference = toHoursMinutes(differenceMinutes);

    console.log(
      `Difference: ${difference.hours}:${String(difference.minutes).padStart(
        2,
        "0"
      )}`
    );
    const OtDiif = `${difference.hours}:${String(difference.minutes).padStart(
      2,
      "0"
    )}`;
    console.log(OtDiif);
    const OTAMT = calculateSalaryForWorkedHours(
      difference.hours,
      String(difference.minutes).padStart(2, "0"),
      parseFloat(SalaryperDay || 0)
    );

    let numericOTAMT = parseFloat(OTAMT || 0);

    // Step 2: Format to two decimal places
    let formattedOTAMT = numericOTAMT.toFixed(2);
    console.log(formattedOTAMT); // Output: '5.96'

    // Step 3: Convert back to number if needed
    totalOTAmt = parseFloat(formattedOTAMT);

    console.log(totalOTAmt);
  }

  // console.log(`Total Days: ${totalDays}`);
  // console.log(`Holidays: ${holidays}`);
  // console.log(`Week Offs: ${weekOffs}`);
  // console.log(`Working Days: ${workingDays}`);
  // console.log(`Present Days: ${presentDays}`);
  // console.log(`Number of Absent Days: ${absentDays}`);
  // console.log(`Number of Late Marks: ${lateMarks}`);
  // console.log(`Number of Half Days: ${halfDays}`);
  // console.log(`SalaryperDay: ${SalaryperDay}`);

  const [count, setCount] = useState({
    staff_Ids: "",
    Name: "",
    PayType: "",
    WeekOff: "",
    Total_Days: "",
    Less_Holidays: "",
    Less_Week_Offs: "",
    Working_Days: "",
    No_Of_Present_Days: "",
    No_Of_Absent_Days: "",
    No_Of_Late_Marks: "",
    No_Of_Half_Days: "",
    No_Of_Adjustments: "",
    No_Of_Leaves: "",
    Monthly_Fixed_Salary: "",
    Salary_per_Day: "",
    Absent_Salary: "",
    Late_Salary: "",
    Half_Day_Salary: "",
    Total_Deduction: "",
    Net_Salary: "",
    deduction: "",
    OT: "",
    Month: "",
    Year: "",
  });

  //creating array of all the dates of the month
  function getDaysInMonth(month, year) {
    var date = new Date(year, month - 1, 1);
    var days = [];

    while (date.getMonth() === month - 1) {
      days.push({
        mDate: new Date(date).toLocaleDateString("en-GB"),
        Day: new Date(date).toLocaleDateString("en-GB", { weekday: "long" }),
      });
      date.setDate(date.getDate() + 1);
    }

    return days;
  }

  useEffect(() => {
    fetchData();
  }, [userData, Sr_No]);

  const fetchData = async () => {
    try {
      showLoader();
      if (userData?.Org_Id && currentMonth && currentYear) {
        const result = await axios.get(
          `${apiUrl}/getPayrollAttendanceData?id=${Sr_No}&month=${currentMonth}&year=${currentYear}&Org_Id=${userData?.Org_Id}&Status=Payroll`
        );

        if (result.data === 0) {
          setAttendanceData([]);
        } else {
          setAttendanceData(result.data);
          //console.log(result.data);
        }
      }
      setDate(getDaysInMonth(currentMonth, currentYear));
      hideLoader();
    } catch (error) {
      hideLoader();
      setAttendanceData([]);
    }
  };
  const getMonthName = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[monthNumber - 1];
  };

  useEffect(() => {
    const selectedMonthName = getMonthName(currentMonth);
    setCount((prevCount) => ({
      ...prevCount,
      MonthName: selectedMonthName,
    }));
  }, [currentMonth]);

  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };

  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };

  const Filter = async () => {
    try {
      showLoader();
      const result = await axios.get(
        `${apiUrl}/getPayrollAttendanceData?id=${Sr_No}&month=${currentMonth}&year=${currentYear}&Org_Id=${userData?.Org_Id}`
      );

      if (result.data === 0) {
        setAttendanceData([]);
      } else {
        setAttendanceData(result.data);
        console.log(result.data);
        setAttendanceData(result.data);
        setDate(getDaysInMonth(currentMonth, currentYear));
        hideLoader();
      }
    } catch (error) {
      hideLoader();
      setAttendanceData([]);
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const renderTimeLog = (timeLog) => {
    // Check if timeLog is empty and return an empty string if so
    if (!timeLog.trim()) {
        return <pre></pre>;
    }

    const times = timeLog.split(",").map(time => time.trim());
    const result = [];

    // Iterate through the time entries in pairs
    for (let i = 0; i < times.length; i += 2) {
        const inTime = times[i];
        const outTime = i + 1 < times.length ? times[i + 1] : "";

        // Only add the "Out Time" if it's different from the "In Time"
        if (outTime && outTime !== inTime) {
            result.push(`(In Time: ${inTime}, Out Time: ${outTime})`);
        } else {
            result.push(`(In Time: ${inTime})`);
        }
    }

    // Join the result array with line breaks
    return <pre>{result.join(",\n")}</pre>;
};


  const handlePunchStatusClick = (id, date) => {
    // Toggle the modal state when punchStatus is clicked
    setStaff_Id(id);
    setAdjustmetDate(date);
    setShowModal(!showModal);
  };

  const handleCloseModal = async () => {
    setShowModal(false);
    fetchData();
  };

  const addCollective = async (event) => {
    event.preventDefault();

    debugger;
    showLoader();
    let MonthlySal = 0;
    if (staffData.PayType === "Fixed") {
      MonthlySal = staffData.Amount;
    }

    let hourSalary = 0;
    if (staffData.PayType !== "Fixed") {
      hourSalary = staffData.Amount;
    }

    const Data = {
      Date: data[0]?.Date,
      UserID: Sr_No,
      Name: data[0]?.Name,
      PayType: staffData.PayType,
      WeekOff: staffData.WeekOff,
      WorkingdayCount: workingDays,
      HolidayCount: holidays,
      WeekoffCount: weekOffs,
      AdjustmentCount: adjustment,
      PresentdayCount: presentDays,
      AbsentdayCount: absentDays,
      LatemarkdayCount: lateMarks,
      HalfdayCount: halfDays,
      LeavedayCount: leavedayCount,
      OTCount: "",
      TotalpresentdayCount: presentDays,
      MonthlySalary: parseFloat(MonthlySal).toFixed(2),
      Perday_hourSalary: parseFloat(hourSalary).toFixed(2),
      GrossSalary: parseFloat(GrossTotal).toFixed(2),
      OT: OT,
      OTSalary: parseFloat(totalOTAmt).toFixed(2),
      Deductions: parseFloat(deduction).toFixed(2),
      LatemarkDeduction: lateMarksAmt,
      HalfdayDeduction: halfDaysAmt,
      AbsentDeduction: absentDaysAmt,
      TotalDeduction: totalDeduction,
      NetSalary: parseFloat(NetTotal).toFixed(2),
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
    };

    await axios
      .post(`${apiUrl}/addCollective`, Data)
      .then((res) => {
        Swal.fire(
          "Success!",
          "Salary Detail Added to Collective Report.",
          "success"
        );
        hideLoader();
      })
      .catch((err) => {
        console.error(err);
        Swal.fire(
          "Server Timeout",
          "Server is Busy!!!, Please try again later.",
          "error"
        );
        hideLoader();
      });
  };


  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text(`Staff Payroll - ${staffData?.First_Name} ${staffData?.Last_Name}`, 20, 10);

    const columns = [
        "Sr. No.",
        "Date",
        "Day",
        "Worked Hours",
        "Status",
        "Remark"
    ];

    const rows = data.map((row, index) => [
       1+index,
       formatDate(row.Date),
       row.Day,
       row.Hours,
       row.Status,
       row.Remark

    ]);

    doc.autoTable({
        head: [columns],
        body: rows,
    });

    doc.save(`Staff Payroll ${staffData?.First_Name} ${staffData?.Last_Name}.pdf`);
};

const DownloadExcel = () => {
    const columns = [
      "Sr. No.",
        "Date",
        "Day",
        "Worked Hours",
        "Status",
        "Remark"
    ];
    const rows = [columns];
    data.forEach((row, index) => {
        rows.push([
          1+index,
          formatDate(row.Date),
          row.Day,
          row.Hours,
          row.Status,
          row.Remark
        ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Staff Payrol ${staffData?.First_Name} ${staffData?.Last_Name}`);

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, `Staff Payroll ${staffData?.First_Name} ${staffData?.Last_Name}.xlsx`);
};

  return (
    <div>
      <div className="row  mt-3">
        <div className="col-12 mb-2">
          <div className="card">
            <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
              Filters
            </h4>
            <div className="card-body" style={{ paddingTop: "5px" }}>
              <div className="row justify-content-center mt-2">
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    Month
                  </label>
                  <select
                    className="form-select"
                    id="example-select"
                    onChange={monthChanged}
                    defaultValue={currentMonth}
                  >
                    <option value={1}>January</option>
                    <option value={2}>February</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    Year
                  </label>
                  <select
                    className="form-select"
                    id="example-select"
                    onChange={yearChanged}
                    defaultValue={currentYear}
                  >
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                  </select>
                </div>
              </div>
              <div className="row justify-content-center mt-2">
                <div className="col-2">
                  <button
                    className="btn btn-primary"
                    onClick={Filter}
                    style={{ width: "100%" }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
            <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
  
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <TableViewIcon/>
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div className="table-responsive mb-4">
                <table
                  className="table productTable table-striped display table-bordered mb-0"
                  style={{ fontSize: "12px" }}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Sr. No.</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">Day</th>
                      <th className="text-center">Worked Hours</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length !== 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            {formatDate(item.Date)}
                          </td>
                          <td className="text-center">{item.Day}</td>
                          <td className="text-center">
                            {item?.TimeLog ? (
                              <LightTooltip
                                title={renderTimeLog(item?.TimeLog)}
                              >
                                <p style={{ cursor: "pointer" }}>
                                  {item.Hours}
                                </p>
                              </LightTooltip>
                            ) : (
                              <p>{item.Hours}</p>
                            )}
                          </td>
                          <td className="text-center">
                            <Link
                              id="punchStatus"
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                handlePunchStatusClick(item.EmpId, item.Date);
                              }}
                              className={`badge  ${
                                item.Status === "Present"
                                  ? "bg-success"
                                  : item.Status === "Absent"
                                  ? "bg-danger"
                                  : item.Status === "Week Off"
                                  ? "bg-dark"
                                  : item.Status === "Late"
                                  ? "bg-info"
                                  : item.Status === "Half Day"
                                  ? "bg-warning"
                                  : "bg-primary"
                              }`}
                            >
                              <LightTooltip title="Adjust Punch Status">
                                {item.Status}
                              </LightTooltip>
                            </Link>
                          </td>
                          <td className="text-center">
                            {formatDate(item.Date) === today ? "" : item.Remark}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center text-danger" colSpan={6}>
                          No Data Found for Selected Month and Year
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {data.length !== 0 && (
                <div className="row">
                  <div className="col-md-8">
                    <table
                      className="table productTable display table-bordered table-striped"
                      style={{ fontSize: "12px" }}
                    >
                      <tbody>
                        <tr
                          style={{
                            background: "#005A9B",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          <td colspan="8">
                            {" "}
                            &nbsp;Attendance For - {count.MonthName}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-center" rowspan="2">
                            <strong>DAYS</strong>
                          </th>
                          <th className="text-center">
                            <strong>Total Days</strong>
                          </th>

                          <th className="text-center">
                            <strong>Less Holidays</strong>
                          </th>
                          <th className="text-center">
                            <strong>Less Week Offs</strong>
                          </th>
                          <th style={{ display: "none" }}>
                            <strong>Adjustments</strong>
                          </th>
                          <th className="text-center">
                            <strong>Working Days</strong>
                          </th>

                          <td
                            style={{
                              background: "#005a9b",
                              border: "2px solid #005a9b",
                              borderBottom: "none",
                              color: "#fff",
                              display: "none",
                            }}
                          >
                            <strong>Halfday/LateMark deductions</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">
                            <strong>{totalDays}</strong>
                          </td>

                          <td className="text-center">
                            <strong>{holidays}</strong>
                          </td>
                          <td className="text-center">
                            <strong>{weekOffs}</strong>
                          </td>
                          <td style={{ display: "none" }}>
                            <span>0</span>
                          </td>
                          <td className="text-center">
                            <strong>{workingDays}</strong>
                          </td>
                          <td
                            style={{
                              border: "2px solid #005a9b",
                              borderTop: "none",
                              display: "none",
                            }}
                          >
                            <span>YES</span>
                          </td>
                        </tr>
                        <tr>
                          <th rowspan="2" className="text-center">
                            <strong>
                              DEDUCTIONS
                              <br />
                              FOR DAYS
                            </strong>
                          </th>
                          <th className="text-center">
                            <strong>No Of Present Days</strong>
                          </th>
                          <th className="text-center">
                            <strong>No Of Absent Days</strong>
                          </th>
                          <th className="text-center">
                            <strong>No Of Late Marks</strong>
                          </th>
                          <th className="text-center">
                            <strong>No Of Half Days</strong>
                          </th>
                          <th
                            className="text-center"
                            style={{ display: "none" }}
                          >
                            <strong>Leave</strong>
                          </th>
                          <td
                            style={{
                              background: "#005a9b",
                              border: "2px solid #005a9b",
                              borderBottom: "none",
                              color: "#fff",
                              display: "none",
                            }}
                          >
                            <strong>Total Present (including L.M.,H.D.)</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">
                            <strong>{presentDays}</strong>
                          </td>
                          <td className="text-center">
                            <strong>{absentDays}</strong>
                          </td>
                          <td className="text-center">
                            <strong>{lateMarks}</strong>
                          </td>
                          <td className="text-center">
                            <strong>{halfDays}</strong>
                          </td>
                          <td
                            className="text-center"
                            style={{ display: "none" }}
                          >
                            <span>0</span>
                          </td>
                          <td
                            style={{
                              border: "2px solid #005a9b",
                              borderTop: "none",
                              display: "none",
                            }}
                          >
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1" className="text-center">
                            <strong>DEDUCTION AMT</strong>
                          </td>
                          <td className="text-center">--</td>
                          <td className="text-center">
                            <strong>
                              {staffData?.Deductions === "Y"
                                ? parseFloat(absentDaysAmt || 0).toFixed(2)
                                : "0.00"}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong>
                              {staffData?.Deductions === "Y"
                                ? parseFloat(lateMarksAmt || 0).toFixed(2)
                                : "0.00"}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong>
                              {staffData?.Deductions === "Y"
                                ? parseFloat(halfDaysAmt || 0).toFixed(2)
                                : "0.00"}
                            </strong>
                          </td>
                          <td style={{ display: "none" }}>-</td>
                          <td
                            style={{
                              border: "2px solid #005a9b",
                              borderTop: "none",
                              display: "none",
                            }}
                          >
                            <strong>OT (hh:mm)</strong>
                            <span>3:51</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-md-4"
                    style={{ marginTop: marginTopValue }}
                  >
                    <table
                      className="table productTable display table-bordered table-striped"
                      style={{ fontSize: "12px" }}
                    >
                      <tbody>
                        <tr>
                          <td className="text-left" style={{ width: "100%" }}>
                            <strong>&nbsp;Monthly Fixed Salary</strong>
                          </td>
                          <td className="text-center">
                            <span
                              className="text-center"
                              style={{ fontWeight: "bold" }}
                            >
                              {parseFloat(staffData?.Amount || 0).toFixed(2)}
                              &nbsp;
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td className="text-left" style={{ width: "100%" }}>
                            <span id="ContentPlaceHolder1_lblPayper">
                              <strong>&nbsp;Salary per Day</strong>
                            </span>
                          </td>
                          <td className="text-center">
                            <span
                              className="text-center"
                              style={{ fontWeight: "bold" }}
                            >
                              {(
                                parseFloat(staffData?.Amount || 0) /
                                parseFloat(totalDays)
                              ).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                        <tr
                          className={
                            staffData?.Overtime_Consideration === "N" ||
                            staffData?.Deductions === "N"
                              ? "d-none"
                              : ""
                          }
                          style={{ width: "100%" }}
                        >
                          <td>
                            &nbsp;<strong>Gross Salary</strong>
                          </td>
                          <td className="text-center">
                            <strong style={{ fontWeight: "bold" }}>
                              {GrossTotal}
                            </strong>
                          </td>
                        </tr>
                        <tr
                          className={
                            staffData?.Overtime_Consideration === "N"
                              ? "d-none"
                              : ""
                          }
                          style={{ width: "100%" }}
                        >
                          <td style={{ width: "100%" }}>
                            &nbsp;
                            <strong class="aspNetDisabled">
                              <input
                                id="ot"
                                type="checkbox"
                                name="OT"
                                checked={OT}
                                onChange={(e) => {
                                  setOT(e.target.checked);
                                }}
                              />
                              &nbsp;
                              <label for="ot">
                                <strong>OT (Over Time)</strong>
                              </label>
                            </strong>
                          </td>
                          <td>
                            <strong style={{ fontWeight: "bold" }}>
                              {parseFloat(totalOTAmt || 0).toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                        <tr
                          className={
                            staffData?.Deductions === "N" ? "d-none" : ""
                          }
                          style={{
                            borderTop: "1px solid #8c8686",
                          }}
                        >
                          <td style={{ width: "100%" }}>
                            <strong class="aspNetDisabled">
                              &nbsp;
                              <input
                                id="deduction"
                                type="checkbox"
                                name="deduction"
                                checked={deduction}
                                onChange={(e) => {
                                  setDeduction(e.target.checked);
                                }}
                              />
                              &nbsp;
                              <label for="deduction">
                                <strong>Total Deduction</strong>
                              </label>
                            </strong>
                          </td>
                          <td>
                            <strong style={{ fontWeight: "bold" }}>
                              {parseFloat(totalDeduction || 0).toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                        <tr
                          style={{
                            background: "#005A9B",
                            color: "#fff",
                          }}
                        >
                          <td style={{ width: "100%" }}>
                            <strong>&nbsp;Net Salary (After deduction)</strong>
                          </td>
                          <td className="text-center">
                            <span
                              className="text-center"
                              style={{ fontWeight: "bold" }}
                            >
                              {NetTotal}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <center>
              <button className="btn btn-primary mb-3" onClick={addCollective}>
                Add to Collective
              </button>
            </center>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          id={staff_Id}
          date={adjustmetDate}
          //loadData={fetchData}
        />
      )}
      {loader}
    </div>
  );
};
export default PayRoll;
