import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import StandardDropdown from "../DropDowns/StandardDropdown";
import BatchDropdown from "../DropDowns/BatchDropdown";
import SubjectDropdown from "../DropDowns/SubjectDropdown";
import TopicDropdown from "../DropDowns/TopicDropdown";
import { useUser } from "../../Components/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import { formatDate, formatInputDate } from "../../Components/dateFormate";

const CreateDailyQuiz = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { TestID } = useParams();
  const [errors, setErrors] = useState({});
  const [classRoomOption, setClassRoomOption] = useState([]);
  const [facultyStaff, setFacultyStaff] = useState([]);
  const [classOptions, setclassOptions] = useState([]);
  const [SubOptions, setSubOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [TopicOption, setTopicOption] = useState([]);

  const [Lecture, setLecture] = useState({
    Class_Id: "",
    Class_Name: "",
    Sub_Id: "",
    Sub_Name: "",
    Name: "",
    Time: "",
    Link: "",
    Date: "",
    Negative: "1",
    Negative_Marks: "0",
    Free: "",
  });

  console.log(Lecture);

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const updateLecture = {
      ...Lecture,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addDailyQuiz`, updateLecture)
        .then((res) => {
          Swal.fire("Success!", "Daily Quiz Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/ViewDailyQuiz");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateLecture = {
      ...Lecture,

      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateDailyQuiz/${TestID}`, updateLecture)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Lecture Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/ViewDailyQuiz");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();

        if (TestID) {
          const ByID = await axios.get(
            `${apiUrl}/getDailyQuizById/${TestID}`
          );
          setLecture(ByID.data);
          setLecture((prevStudent) => ({
            ...prevStudent,
            Class_Id: parseInt(ByID.data.Cat_Id),
            Class_Name: ByID.data.Cat_Name,
          }));


          console.log(ByID.data);
        }

        if (userData?.Org_Id && userData?.Head_Id) {
          const [classroomRes, facultyStaffRes, stdRes] = await Promise.all([
            axios.get(
              `${apiUrl}/getClassRoom?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}`
            ),
            axios.get(`${apiUrl}/binddropTeacher?OrgId=${userData.Org_Id}`),
            axios.get(`${apiUrl}/getClass?Org_Id=${userData.Org_Id}`),
          ]);
          setClassRoomOption(classroomRes.data);
          const facultyStaffOptions = facultyStaffRes.data.map((category) => ({
            label: category.Name,
            value: category.User_Id,
          }));
          setFacultyStaff(facultyStaffOptions);
          const stdOptions = stdRes.data.map((category) => ({
            label: category.Class_Name,
            value: category.Class_Id,
          }));
          setclassOptions(stdOptions);
          console.log(stdOptions)
        }

        if (Lecture?.Class_Id) {
          const Subject = await axios.get(
            `${apiUrl}/getSubjects?Class_Id=${Lecture.Class_Id}`
          );
          const stdOptions = Subject.data.map((category) => ({
            label: category.Sub_Name,
            value: category.Sub_Id,
          }));
          setSubOptions(stdOptions);
        }

        if (userData && userData.Org_Id && Lecture?.Std_Id) {
          const [batchRes, subRes] = await Promise.all([
            axios.get(
              `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Lecture.Std_Id}`
            ),
            axios.get(
              `${apiUrl}/getSubMaster?OrgId=${userData.Org_Id}&Std_Id=${Lecture.Std_Id}`
            ),
          ]);
          const batchOptions = batchRes.data.map((category) => ({
            label: category.Batch_Name,
            value: category.Batch_Id,
          }));
          setBatchOptions(batchOptions);
          const subOptions = subRes.data.map((category) => ({
            label: category.Sub_Name,
            value: category.Sub_Id,
          }));
          setSubjectOption(subOptions);
        }

        if (userData && userData.Org_Id && Lecture?.Subject_Id) {
          const result = await axios.get(
            `${apiUrl}/getTopic?Sub_Id=${Lecture.Subject_Id}&Org_Id=${userData.Org_Id}`
          );
          const topicOptions = result.data.map((category) => ({
            label: category.Topic_Name,
            value: category.Topic_Id,
          }));
          setTopicOption(topicOptions);
        }

        hideLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [userData, TestID, Lecture.Std_Id, Lecture.Subject_Id, Lecture.Class_Id]);

  return (
    <div className="content">
      <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
        <div className="row">
          <div className="col-md-6">
            {TestID ? "Update Daily Quize" : "Add Daily Quize"}
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
            </div>
            {/* {!TestID && (
              <Link
                to={"/addMultipleLectures"}
                style={{ float: "right", marginLeft: "10px" }}
              >
                <button className="btn btn-primary btn-sm text-center">
                  Add Multiple Lectures
                </button>
              </Link>
            )} */}
            <Link
              to={"/viewDailyQuiz"}
              style={{ float: "right", marginLeft: "10px" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                View Daily Quiz
              </button>
            </Link>
          </div>
        </div>
      </h4>
      <div className="row g-4">
        <div className="col-12 col-xl-12 order-1 order-xl-0">
          <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
          >
            <div className="card-body p-0">
              <div className="p-4 code-to-copy">
                <form onSubmit={TestID ? handleUpdate : handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Class">
                        Class<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        name="Class"
                        id="Class"
                        required
                        isClearable
                        options={classOptions}
                        //isDisabled={ID}
                        value={
                          Lecture.Class_Id
                            ? {
                              value: Lecture.Class_Id,
                              label: Lecture.Class_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Class_Id: selectedOption.value || "" || "",
                            Class_Name: selectedOption.label || "" || "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Subject">
                        Subject<span style={{ color: "Red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={Lecture.Class_Id === "" ? true : false}
                        name="Subject"
                        id="Subject"
                        required
                        options={SubOptions}
                        isClearable
                        value={
                          Lecture.Sub_Id
                            ? {
                              value: Lecture.Sub_Id,
                              label: Lecture.Sub_Name,
                            }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLecture({
                            ...Lecture,
                            Sub_Id: selectedOption.value || "",
                            Sub_Name: selectedOption.label || "",
                          });
                        }}
                      />
                      <span style={{ color: "Red" }}></span>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Topic">
                        Topic<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        id="Topic"
                        name="Topic"
                        required
                        value={Lecture.Name}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            Name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Time">
                        Time(Minutes)<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Time"
                        name="Time"
                        placeholder="Enter Time in Min"
                        required
                        value={Lecture.Time}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (/^\d*$/.test(input)) {
                            setLecture({
                              ...Lecture,
                              Time: input,
                            });
                          }
                        }}

                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Youtube_Link">
                        Youtube Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Youtube_Link"
                        name="Youtube_Link"
                        value={Lecture.Link}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            Link: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="Date">
                        Date<span style={{ color: "Red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="Date"
                        name="Date"
                        required
                        value={formatInputDate(Lecture.Date)}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLecture({
                            ...Lecture,
                            Date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Description">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="Description"
                        value={Lecture.Description}
                        onChange={(e) => {
                          setLecture({
                            ...Lecture,
                            Description: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {TestID ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateDailyQuiz;
