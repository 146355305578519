import React, { useState, useEffect } from "react";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import axios from "axios";
import Select from "react-select";


const TopicDropdown = ({ styles, value, isDisabled,  name,required, id, onChange, Sub_Id }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [TopicNameOptions, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id && Sub_Id) {
                try {
                    showLoader();
                    const result = await axios.get(`${apiUrl}/getTopic?Sub_Id=${Sub_Id}&Org_Id=${userData.Org_Id}`);

                    const op = result.data.map((category) => ({
                        label: category.Topic_Name  ,
                        value: category.Topic_Id,
                    }));
                    setData(op);

                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, Sub_Id]);



    return (
        <>
            {/* <label className="form-label" htmlFor={id}>
                {label}
            </label> */}
            <Select
            name={name}
            id={id}
            Sub_Id={Sub_Id}
           // placeholder='Select Bank'
            //classNamePrefix="select"
            required={required}
            options={TopicNameOptions}
            value={value}
            isDisabled={isDisabled}
            onChange={onChange}
            styles={styles}
            />
            {loader}
        </>
    );
};


export default TopicDropdown;
