import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import Select from "react-select";



const UpdateStatusModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [groupOption, setGroupOption] = useState([]);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(updateData);


    const [Bill, setBill] = useState({
        Group_Id: updateData.Group_Id,
        Group_Name: updateData.Group_Name,
        Task_Name: updateData.Task_Name,
    });

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(`${apiUrl}/getDropdownGroups?Org_Id=${userData?.Org_Id}`);
            const getDrop = res.data.map((category) => ({
                label: category.Group_Name,
                value: category.Group_Id,
              }));
            setGroupOption(getDrop);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };


    // useEffect(() => {
    //     updateName(updateData?.Name);
    // }, [updateData]);
    
    // const updateName = (fullName) => {
    //     const parts = fullName.split(' ');
    //     const firstName = parts[0];
    //     const middleName = parts.length > 2 ? parts[1] : "";
    //     const lastName = parts[parts.length - 1];

    //     setBill((prevBill) => ({
    //         ...prevBill,
    //         First_Name: firstName,
    //         Middle_Name: middleName,
    //         Last_Name: lastName
    //     }));
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
      
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateDropdownMenu/${updateData?.Task_No}`, updateBill)
                .then((res) => {
                    Swal.fire("Success!", "Menu Group Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                handleClose();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Menu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                        <div className="row">
                        <div className="col-md-6 mb-3">
                                <label className="form-label" htmlFor="Group_Name ">Menu Group
                                <span style={{ color: "Red" }}>*</span></label>
                                <Select
                                    id="Group_Name"
                                    name="Group_Name"
                                    required
                                    options={groupOption}
                                    value={Bill.Group_Id?{value:Bill.Group_Id,label:Bill.Group_Name}:null}
                                    onChange={(selectedOption) => {
                                        setBill({
                                            ...Bill,
                                            Group_Id: selectedOption.value,
                                            Group_Name: selectedOption.label
                                        });
                                    }}
                                />
                            </div>

                            <div className={"mb-3 col-md-6"}>
                                <label className="form-label" htmlFor="Task_Name">
                                Task Name<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Task_Name"
                                    id="Task_Name"
                                    required
                                    value={Bill.Task_Name}
                                    placeholder="Enter Task Name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateStatusModal;
