import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "./AgGridTable";

const UpcomingFees = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData, setData } = useUser();
    const { filterData, setFilterData } = useUser();
    const [data, setBillData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedstatus, setSelectedstatus] = useState(30);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const formattedFromDate = fromDate ? fromDate.format("YYYY-MM-DD") : "";
    const formattedToDate = toDate ? toDate.format("YYYY-MM-DD") : "";
    const [StdOptions, setStdOptions] = useState([]);
    const [BatchOptions, setBatchOptions] = useState([]);
    const [Std_Id, setStdId] = useState('');
    const [Batch_ID, setBatchID] = useState('');
    const [Agrows, setAgrows] = useState([]);
    const [Agcolumns, setAgcolumns] = useState([]);
    const [design, setDesign] = useState({
        filterToggle: "",
        dataView: "",
    });
    const [checkedStatus, setCheckedStatus] = useState({});
    const [checkAll, setCheckAll] = useState(true);
    const [MobileData, setMobileData] = useState([]);


    console.log(MobileData)
    console.log(filteredData)
    console.log(checkedStatus)

    const handleSelectedRowsChange = (data) => {
        setMobileData(data);
        console.log("Selected Rows:", data);
    };

    const [message, setMessage] = useState({
        MobileData: [],
        Message: "", ImgAttachment: "", DocAttachment: ""

    });

    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
            setDesign({
                ...design,
                filterToggle: filterData.filterToggle,
                dataView: filterData.dataView,
            });
        }
    }, [userData, Std_Id]);

    //console.log(design);

    const fetchData = async () => {
        try {
            showLoader();
            if (userData && userData.Org_Id && userData?.Head_Id) {

                const res = await axios.get(
                    `${apiUrl}/getStudentUpcomingFeesMessageData?OrgId=${userData?.Org_Id}&HeadId=${userData?.Head_Id}&StdId=${Std_Id}&BatchId=${Batch_ID}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&Status=${selectedstatus}`
                );
                const newCheckedStatus = res.data.reduce((acc, student) => {
                    acc[student.Sr_No] = true;
                    return acc;
                }, {});
                setCheckedStatus(newCheckedStatus);
                setMobileData(res.data);
                setBillData(res.data);
                setFilteredData(res.data);
                console.log(res.data);



                const std = await axios.get(
                    `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
                );


                const getStd = std.data.map((category) => ({
                    label: category.Std_Name,
                    value: category.Std_Id,
                }));
                setStdOptions(getStd);
                //console.log(getStd);
            }

            if (userData && userData.Org_Id && Std_Id) {

                const batch = await axios.get(
                    `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
                );


                const getBatch = batch.data.map((category) => ({
                    label: category.Batch_Name,
                    value: category.Batch_Id,
                }));
                setBatchOptions(getBatch);
                // console.log(getBatch);

            }
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    //Search Box
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (data.length > 0) {
            const newFilteredData = data.filter(
                (row) =>
                    row?.Std_Name?.toLowerCase().includes(searchTerm)
                    || row?.Name?.toLowerCase().includes(searchTerm)
                    || row?.Batch_Name?.toLowerCase().includes(searchTerm)
                    || formatDate(row.Due_Date)?.toLowerCase().includes(searchTerm)
                    || parseFloat(row.Amount).toFixed(2)?.toLowerCase().includes(searchTerm)
                    || row?.Payment_Status?.toLowerCase().includes(searchTerm)
                    || row?.StudentMobile?.toLowerCase().includes(searchTerm)
                    || row?.FatherMobile?.toLowerCase().includes(searchTerm)
                    || row?.MotherMobile?.toLowerCase().includes(searchTerm)
            );
            setFilteredData(newFilteredData);
        }
    }, [searchTerm, data]);

    const handleCheckboxChange = (e, index, id) => {
        const newCheckedStatus = {
            ...checkedStatus,
            [id]: !checkedStatus[id]
        };

        const newSelectedMobiles = newCheckedStatus[id]
            ? [...MobileData, filteredData.find(student => student.Sr_No === id)]
            : MobileData.filter(student => student.Sr_No !== id);

        setCheckedStatus(newCheckedStatus);
        setMobileData(newSelectedMobiles);
        setCheckAll(Object.values(newCheckedStatus).every(status => status));
    };


    const handleCheckAll = (e) => {
        const newCheckedStatus = filteredData?.reduce((acc, student) => {
            acc[student.Sr_No] = e.target.checked;
            return acc;
        }, {});

        setCheckedStatus(newCheckedStatus);
        setMobileData(e.target.checked ? filteredData : []);
        setCheckAll(e.target.checked);
    };


    useEffect(() => {



        const newAgcolumns = [
            { field: "Name" },
            { field: "Std" },
            { field: "Batch" },
            { field: "DueDate" },
            { field: "Amount" },
            { field: "PaymentStatus" },
            { field: "FatherMobile" },
            { field: "MotherMobile" },
        ];
        setAgcolumns(newAgcolumns);
    }, []);

    useEffect(() => {
        if (filteredData.length > 0) {
            const newAgrows = filteredData.map((row, index) => ({
                Sr_No: row.Sr_No,
                ID: row.ID,
                Student_Id: row.Student_Id,
                UUID: row.UUID,
                Name: row.Name,
                Std_Id: row.Std_Id,
                Std: row.Std_Name,
                Batch_Id: row.Batch_Id,
                Batch: row.Batch_Name,
                DueDate: formatDate(row.Due_Date),
                Amount: parseFloat(row.Amount).toFixed(2),
                PaymentStatus: row.Payment_Status,
                StudentMobile: row.StudentMobile,
                FatherMobile: row.FatherMobile,
                MotherMobile: row.MotherMobile
            }));

            setAgrows(newAgrows);
        }
    }, [filteredData]);



    const handleDateRangeFilter = async () => {
        showLoader();
        fetchData();
        hideLoader();
    };



    const DownloadPDF = () => {
        // const doc = new jsPDF();
        const doc = new jsPDF();
        doc.text("Upcoming Fees", 20, 10);

        const columns = [
            "Sr No",
            'Name',
            'Std',
            'Batch',
            'Due Date',
            'Amount',
            'Payment Status',
            'Student Mobile',
            'Father Mobile',
            'Mother Mobile'
        ];

        const rows = filteredData.map((row, index) => [
            index + 1,
            row.Name,
            row.Std_Name,
            row.Batch_Name,
            formatDate(row.Due_Date),
            parseFloat(row.Amount).toFixed(2),
            row.Payment_Status,
            row.StudentMobile,
            row.FatherMobile,
            row.MotherMobile
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Upcoming Fees.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Sr No",
            'Name',
            'Std',
            'Batch',
            'Due Date',
            'Amount',
            'Payment Status',
            'Student Mobile',
            'Father Mobile',
            'Mother Mobile'
        ];

        const rows = [columns];
        filteredData.forEach((row, index) => {
            rows.push([
                index + 1,
                row.Name,
                row.Std_Name,
                row.Batch_Name,
                formatDate(row.Due_Date),
                parseFloat(row.Amount).toFixed(2),
                row.Payment_Status,
                row.StudentMobile,
                row.FatherMobile,
                row.MotherMobile
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Upcoming Fees");

        XLSX.writeFile(workBook, "Upcoming Fees.xlsx");
    };



    const submitHandler = async (event) => {
        event.preventDefault();
        const updatedMessage = {
            ...message,
            MobileData: MobileData,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };


        showLoader();

        await axios
            .post(`${apiUrl}/sendStudentUpcomingFeesMessage`, updatedMessage)
            .then((res) => {
                Swal.fire("Success!", "Upcoming Fees Message Sent Successfully!!", "success");
                setMessage({ Mobile: [], Message: "", ImgAttachment: "", DocAttachment: "" });
                setMobileData([]);
                setCheckedStatus({});
                fetchData();

                setCheckAll(true);
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire("Server Timeout", "Server is Busy!!! Please try again later.", "error");
                hideLoader();
            });
    };

    const SendRemarks = async (event) => {
        event.preventDefault();
        const updatedMessage = {
            ...message,
            MobileData: MobileData,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };


        showLoader();

        await axios
            .post(`${apiUrl}/sendUpcomingFeesRemarks`, updatedMessage)
            .then((res) => {
                Swal.fire("Success!", "Upcoming Fees Message Sent Successfully!!", "success");
                setMessage({ Mobile: [], Message: "", ImgAttachment: "", DocAttachment: "" });
                setMobileData([]);
                setCheckedStatus({});
                fetchData();

                setCheckAll(true);
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire("Server Timeout", "Server is Busy!!! Please try again later.", "error");
                hideLoader();
            });
    };


    const toggle = () => {
        setDesign({
            ...design,
            filterToggle: design.filterToggle ? false : true,
        });
        setFilterData({
            ...filterData,
            filterToggle: design.filterToggle ? false : true,
        });
    };

    const [pageNumber, setPageNumber] = useState(0);
    const recordsPerPage = design.dataView === "List View" ? 10 : 12;
    const pagesVisited = pageNumber * recordsPerPage;
    const displayData = filteredData
        .slice(pagesVisited, pagesVisited + recordsPerPage)
        .map((row, index) => (
            <>
                {design.dataView === "List View" && (
                    <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
                        <div className="col-md-2">
                            <div className="me-3 flex-1 mt-2">
                                <h5 className="text-black">{row.Name}</h5>
                                {/* <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Date :{" "}
                                    <span className="ms-1 text-1100">{formatDate(row?.Date)}</span>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="profiledetail lh-1 mb-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Standard :{" "}
                                    <span className="ms-1 text-1100">{row.Std_Name}</span>
                                </p>
                                <p
                                    className="mb-1 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Batch :{" "}
                                    <span className="ms-1 text-1100">{row.Batch_Name}</span>
                                </p>
                                {/* <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Subject :{" "}
                                    <span className="ms-1 text-1100">{row.Sub_Name}</span>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Due Date :{" "}
                                    <span className="ms-1 text-1100">{formatDate(row.Due_Date)}</span>
                                </p>
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Amount :{" "}
                                    <span className="ms-1 text-1100">{parseFloat(row.Amount).toFixed(2)}</span>
                                </p>
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Payment Status :{" "}
                                    <span className="ms-1 text-1100">{row.Payment_Status}</span>
                                </p>

                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="me-3 flex-1">
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Father Mobile :{" "}
                                    <span className="ms-1 text-1100">{row.FatherMobile}</span>
                                </p>
                                <p
                                    className="mb-0 profiledetail lh-1"
                                    style={{ fontSize: "13px" }}
                                >
                                    Mother Mobile :{" "}
                                    <span className="ms-1 text-1100">{row.MotherMobile}</span>
                                </p>

                            </div>
                        </div>
                        <div className="col-md-1">
                            <p className="mb-0 fw-semi-bold listViwP text-truncate" >
                                <span className="fw-semi-bold  ms-1">
                                    <input type="checkbox" id={`studentCheck${index}`} checked={checkedStatus[row.Sr_No] || false}
                                        onChange={(e) => handleCheckboxChange(e, index, row.Sr_No)} />
                                </span>
                            </p>
                        </div>

                    </div>
                )}
                {design.dataView === "Tile View" && (
                    <div
                        key={index}
                        className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
                    >
                        <div className="card h-100 hover-actions-trigger">
                            <div className="card-body" style={{ padding: "15px" }}>
                                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                                    <div
                                        className="row justify-content-between me-1"
                                        style={{ flex: "auto", marginTop: "8px" }}
                                    >
                                        <div className="col-11 pe-1">
                                            <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                                                {row.Name}
                                            </h6>
                                            {/* <p
                                                className="mb-0 profiledetail lh-1"
                                                style={{ fontSize: "13px" }}
                                            >
                                                Date :{" "}
                                                <span className="ms-1 text-1100">{formatDate(row?.Date)}</span>
                                            </p>  */}
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-2">

                                    <p className="mb-0 profiledetail text-truncate lh-1">Standard : <span className="fw-semi-bold ms-1"> {row.Std_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-2  ">

                                    <p className="mb-0 profiledetail lh-1">Batch : <span className="ms-1 text-1100">{row.Batch_Name}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">

                                    <p className="mb-0 profiledetail lh-1">Due Date : <span className="ms-1 text-1100">{formatDate(row.Due_Date)}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">

                                    <p className="mb-0 profiledetail lh-1">Amount : <span className="ms-1 text-1100">{parseFloat(row.Amount).toFixed(2)}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">

                                    <p className="mb-0 profiledetail lh-1"> Payment Status : <span className="ms-1 text-1100">{row.Payment_Status}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">

                                    <p className="mb-0 profiledetail lh-1"> Father Mobile : <span className="ms-1 text-1100">{row.FatherMobile}</span></p>
                                </div>
                                <div className="d-flex align-items-center mb-3">

                                    <p className="mb-0 profiledetail lh-1"> Mother Mobile : <span className="ms-1 text-1100">{row.MotherMobile}</span></p>
                                </div>

                                <div className="d-flex justify-content-between text-700 fw-semi-bold " style={{ float: "right" }} >
                                    <p className="mb-0" style={{ fontSize: "14px", color: "#a09aa3", lineHeight: "5px" }}>
                                        <span className="fw-semi-bold  ms-1">
                                            <input type="checkbox" id={`studentCheck${index}`} checked={checkedStatus[row.Sr_No] || false}
                                                onChange={(e) => handleCheckboxChange(e, index, row.Sr_No)} />
                                        </span>
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </>
        ));

    const pageCount = Math.ceil(filteredData.length / recordsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="content">
            <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
                <div className="container-fluid">
                    {/* Sidebar */}
                    <div id="sidebar-wrapper">
                        <div className="row g-4">
                            <div className="col-12 col-xl-12 order-1 order-xl-0">
                                <div
                                    className="card shadow-none border border-300 my-2"
                                    data-component-card="data-component-card"
                                >
                                    <div className="row justify-content-center d-flex p-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Branch">
                                                        Branch
                                                    </label>
                                                    <HeadMasterDropDown
                                                        label="Branch"
                                                        name="Branch"
                                                        id="Branch"
                                                    //onChange={(e) => setBranch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        From Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        //value={fromDate?.format("YYYY-MM-DD")}
                                                        onChange={(date) =>
                                                            setFromDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        To Date :
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        // value={toDate?.format("YYYY-MM-DD")}
                                                        onChange={(date) =>
                                                            setToDate(dayjs(date.target.value))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Standard">
                                                        Standard :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Standard"
                                                        disabled={userData?.Head_Id === ''}
                                                        value={Std_Id}
                                                        onChange={(e) => {
                                                            setStdId(e.target.value);
                                                            setBatchID('');
                                                        }}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {StdOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Std_Name">
                                                        Batch :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="example-select"
                                                        value={Batch_ID}
                                                        disabled={Std_Id === ''}
                                                        onChange={(e) => setBatchID(e.target.value)}
                                                    >
                                                        <option selected value="" label="All">

                                                        </option>
                                                        {BatchOptions.map((option) => (
                                                            <option key={option.value} value={option.value} label={option.label}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Days">
                                                        Days  :
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Days"
                                                        value={selectedstatus}

                                                        onChange={(e) => setSelectedstatus(e.target.value)}
                                                    >
                                                     
                                                        <option selected value={30}>
                                                            30 Days
                                                        </option>
                                                        <option value={15}>
                                                            15 Days
                                                        </option>
                                                        <option value={10}>
                                                            10 Days
                                                        </option>
                                                        <option value={7}>
                                                            7 Days
                                                        </option>
                                                        <option value={5}>
                                                            5 Days
                                                        </option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center p-2">
                                        <div className="col-md-4 mb-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm text-center"
                                                onClick={handleDateRangeFilter}
                                            >
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="row justify-content-between">
                            <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                                Upcoming Fees
                                <a
                                    className="anchorjs-link "
                                    aria-label="Anchor"
                                    href="#basic-form"
                                    style={{ paddingLeft: "0.375em" }}
                                ></a>
                            </h4>
                            <div className="col-md-12 text-center mt-3">

                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search..."
                                    style={{
                                        float: "left",
                                        display: "inline-block",
                                        width: "200px",
                                    }}
                                ></input>
                                <div
                                    className={design.dataView === "Table View" ? 'd-none' : ""}
                                    style={{ float: "left", marginTop: "10px", marginLeft: "5px" }}
                                >
                                    <span style={{ display: 'inline-block', transform: 'scale(1.5)', transformOrigin: 'left center' }}>
                                        <input
                                            type="checkbox"
                                            checked={checkAll}
                                            onChange={handleCheckAll}
                                            style={{ margin: 0 }}
                                        />
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="btn bg-200 filterButton collapsed mr-2 "
                                    data-toggle="collapse"
                                    onClick={toggle}
                                    data-target="#navbar"
                                    aria-expanded="false"
                                    aria-controls="navbar"
                                    style={{ float: "right", marginRight: "10px" }}
                                >
                                    <i className="fa fa-filter"></i>
                                </button>
                                <button
                                    className="form-control dropdown-toggle viewButton  mb-2"
                                    id="dropdownMenuLink"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                        float: "right",
                                        marginRight: "10px",
                                        width: "auto",
                                        padding: "4px 10px",
                                    }}
                                >
                                    <>
                                        {design.dataView === "Table View" && (
                                            <TableChartOutlinedIcon />
                                        )}
                                    </>
                                    <>
                                        {design.dataView === "List View" && (
                                            <FormatListBulletedIcon />
                                        )}
                                    </>
                                    <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-end py-0"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <Link
                                        onClick={(e) => {
                                            setDesign({
                                                ...design,
                                                dataView: "Table View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Table View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <TableChartOutlinedIcon />
                                        <span> Table View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "List View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "List View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <FormatListBulletedIcon />
                                        <span> List View</span>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setDesign({
                                                ...design,
                                                dataView: "Tile View",
                                            });
                                            setFilterData({
                                                ...filterData,
                                                dataView: "Tile View",
                                            });
                                        }}
                                        className="dropdown-item cursor-pointer"
                                    >
                                        <GridViewIcon />
                                        <span> Tile View</span>
                                    </Link>

                                </div>

                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to PDF</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadPDF()}
                                        style={{ float: "right", padding: "5px 10px 5px 5px" }}
                                    >
                                        <i
                                            className="fas fa-file-pdf"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>Export to Excel</Tooltip>
                                    )}
                                    placement="bottom"
                                >
                                    <Button
                                        variant=""
                                        onClick={() => DownloadExcel()}
                                        style={{ float: "right", padding: "5px 5px 5px 10px" }}
                                    >
                                        <i
                                            className="fas fa-file-excel"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>


                            </div>


                        </div>

                        <div className="row">
                        <div className="col-12 col-xl-12 order-1 order-xl-0">
            {filteredData.length === 0 && (<h5 className="mt-5" style={{ textAlign: "center", marginTop: "50px !important" }}>Data Not Found!!</h5>)}
            </div>
                            {design.dataView === "Table View" && (
                                <div className={filteredData.length === 0 ?"d-none":"col-12 col-xl-12 order-1 order-xl-0"}>
                                    {Agrows.length > 0 && (
                                        <AgGridTable row={Agrows} column={Agcolumns} onSelectedRowsChange={handleSelectedRowsChange} />
                                    )}
                                </div>
                            )}
                            {design.dataView === "Tile View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">{displayData}</div>
                                </div>
                            )}
                            {design.dataView === "List View" && (
                                <div className="col-12 col-xl-12 order-1 order-xl-0">
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                                            <div className="card h-100 hover-actions-trigger">
                                                <div
                                                    className="card-body"
                                                    style={{ paddingTop: "5px" }}
                                                >
                                                    {displayData}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(design.dataView === "List View" ||
                                design.dataView === "Tile View") && (
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        pageCount={pageCount}
                                        pageRangeDisplayed={1}
                                        marginPagesDisplayed={1}
                                        breakAriaLabels={{
                                            forward: "Jump forward",
                                            backward: "Jump backward",
                                        }}
                                        onPageChange={changePage}
                                        containerClassName={"pagination"}
                                        disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                                        previousLinkClassName={"btn btn-outline-primary me-2"}
                                        nextLinkClassName={"btn btn-outline-primary ms-2"}
                                        activeLinkClassName={"customclass ms-2 me-2"}
                                        pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                                    />
                                )}
                        </div>

                        <div className={filteredData.length === 0?"d-none":"row mt-4"}>
                            <div className="col-12 text-center">
                                <button
                                    className="btn btn-sm btn-primary mb-1"
                                    type="button"
                                    onClick={SendRemarks}
                                >
                                    Send Remarks
                                </button>&nbsp;
                                <button
                                    className="btn btn-sm btn-primary mb-1"
                                    type="button"
                                    onClick={submitHandler}
                                >
                                    Send Whatsapp Msg
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {loader}
            </div>
        </div>
    );
};

export default UpcomingFees;
