import React from "react";
import "./App.css";
import SwitchBranch from "./Components/SwitchBranch";
import Dashboard from "./Components/DashBoard";
import PageNotFound from "./Components/404";
import Login from "./Components/Login";
import HomePage from "./Pages/Home/HomePage";
import AddUpdate from "./Pages/Students/AddUpdateEnquiry";
import AddStudent from "./Pages/Students/AddUpdateStudent";
import AdmissionCancelationReport from "./Pages/Students/AdmissionCancelationReport"
import StudentRegistration from "./Pages/Students/AddStudentRegistration";
import ViewStudent from "./Pages/Students/ViewActiveStudent";
import ViewInactiveStudent from "./Pages/Students/ViewInactiveStudent";
import ViewEnquiry from "./Pages/Students/ViewEnquiry";
import { Routes, Route } from "react-router-dom";
import AddStaff from "./Pages/Staff/AddStaff";
import ViewStaff from "./Pages/Staff/ViewStaff";
import ViewAttendance from "./Pages/Staff/ViewAttendance";
import BatchStudentView from "./Pages/Students/BatchStudentView";
import StudentProfile2 from "./Pages/Students/StudentProfile2";
import StaffProfile from "./Pages/Staff/StaffProfile";
import Voucher from "./Pages/Voucher/Voucher";
import ViewVoucher from "./Pages/Voucher/ViewVoucher";
import ExpenseSummary from "./Pages/Expense/ExpenseSummary";
import UpdateChequePayments from "./Pages/ChequeFees/PaymentUpdate";
import FeesSummary from "./Pages/Account/FeesSummary"
import FeesReceipts from "./Pages/Students/Fees/FeesReceipts"
import MasterFeesReceipts from "./Pages/Students/Fees/MasterFeesReceipts"


import FeesOverview from "./Pages/Account/FeesOverview"
import Bill from "./Pages/Bill/Bill";
import ViewBill from "./Pages/Bill/ViewBill";
import ViewTOD from "./Pages/Educare/GoodThoughts/ViewThoughts";
import AddTOD from "./Pages/Educare/GoodThoughts/AddThoughts";
import AddHoliday from "./Pages/Educare/HolidayCalender/AddHoliday";
import ViewHoliday from "./Pages/Educare/HolidayCalender/ViewHoliday";
import AddNotice from "./Pages/Educare/Notice/AddNotice";
import ViewNotice from "./Pages/Educare/Notice/ViewNotice";
import AddEmergencyCircular from "./Pages/Educare/EmergencyCircular/AddEmergencyCircular";
import ViewEmergencyCircular from "./Pages/Educare/EmergencyCircular/ViewEmergencyCircular";
import StudentRemarks from "./Pages/Educare/ComplaintsRemarks/StudentRemarks";
import Gallery from "./Pages/Educare/Gallery/Gallery";
import ViewGallery from "./Pages/Educare/Gallery/ViewGallery";
import ManageClasses from "./Pages/ManageApp/ManageClasses";
import AddHomework from "./Pages/Educare/Homework/AddHomeWork";
import ViewHomework from "./Pages/Educare/Homework/ViewHomeWork";
import AddClasswork from "./Pages/Educare/Classwork/AddClassWork";
import ViewClasswork from "./Pages/Educare/Classwork/ViewClassWork";
import AddNotes from "./Pages/Educare/Notes/AddNotes";
import ViewNotes from "./Pages/Educare/Notes/ViewNotes";
import AddDPP from "./Pages/Educare/DPP/AddDPP";
import ViewDPP from "./Pages/Educare/DPP/ViewDPP";
import AddVideoLink from "./Pages/Educare/VideoLink/AddVideoLink";
import ViewVideoLink from "./Pages/Educare/VideoLink/ViewVideoLink";
import AddPublications from "./Pages/Educare/Publications/AddPublications";
import ViewPublications from "./Pages/Educare/Publications/ViewPublications"
import AddBannerImages from "./Pages/Educare/BannerImages/AddBannerImages";
import ViewBannerImages from "./Pages/Educare/BannerImages/ViewBannerImages";
import ViewComplaints from "./Pages/Educare/Complaints/ViewComplaints";
import AddNoticeForFaculty from "./Pages/Educare/NoticeForFaculty/AddNoticeForFaculty";
import ViewNoticeForFaculty from "./Pages/Educare/NoticeForFaculty/ViewNoticeForFaculty";
import ViewStudentDoubts from "./Pages/Educare/StudentDoubts/ViewStudentDoubts";
import ViewTest from "./Pages/Test/ViewTest";
import AddTest from "./Pages/Test/AddTest";
import TestPerformance from "./Pages/Test/UploadPerformance";
import MarkTestAttendance from "./Pages/Test/MarkStudentAttendance";
import ResultSummary from "./Pages/Test/ResultSummary";
import PerformanceSummary from "./Pages/Test/PerformanceSummary";
import PerformanceResultSummary from "./Pages/Test/PerformenceResult";
import Edit_Examination from "./Pages/Test/Edit_Examination";
import Examination from "./Pages/Test/Examination";
import StudentPerformance from "./Pages/Students/StudentPerformance"
import ViewVendors from "./Pages/Inventory/ViewInventoryVendor";
import AddUpdateVendors from "./Pages/Inventory/AddVendor";
import ViewProductCategory from "./Pages/Inventory/ProductCategory/ViewProductCategory";
import Inventory_VendorLedger from "./Pages/Inventory/Inventory_VendorLedger";
import ViewBills from "./Pages/Inventory/Bills/ViewBills";
import AddBill from "./Pages/Inventory/Bills/AddBill";
import ViewVendorPayments from "./Pages/Inventory/VendorPayment/ViewVendorPayments";
import VendorPayment_Entry from "./Pages/Inventory/VendorPayment/VendorPayment_Entry";
import ManageStock from "./Pages/Inventory/ManageStock";
import ViewDailyQuiz from "./Pages/Test/ViewDailyQuiz";
import CreateDailyQuiz from "./Pages/Test/CreateDailyQuiz";
import DailyQuizResult from "./Pages/Test/DailyQuizResult";
import ViewNewTest from "./Pages/Test/ViewNewTest";
import CreateTestSets from "./Pages/Test/CreateTestSets"
import TestSetsResult from "./Pages/Test/TestSetsResult"
import ViewViedo from "./Pages/Educare/ELearning/ViewELearning"


import StudentBiometricAtt from "./Pages/Reports/StudentBiometricAtt";
import BiometricAttendanceCount from "./Pages/Reports/BiometricAttendanceCount";
import FacultyCollectiveReport from "./Pages/Reports/FacultyCollectiveReport";


import FeesReport from "./Pages/Reports/FeesReport";
import TopicWiseLecture from "./Pages/Reports/TopicWiseLecture";
import LectureReport from "./Pages/Reports/LectureReport"
import StudentFeesReport from "./Pages/Reports/StudentFeesReport";
import IncomeExpenseReport from "./Pages/Reports/IncomeExpenseReport";
import InventoryStockReport from "./Pages/Reports/InventoryStockReport"
import InventoryStockLog from "./Pages/Reports/InventoryStockLog";
import ViewCanceledAdmissions from "./Pages/Reports/ViewCanceledAdmissions";
import IDCard from "./Pages/Reports/IDCard";
import ReportCard from "./Pages/Reports/ReportCard"
import ViewPerformance from "./Pages/Reports/ViewPerformance"
import MasterPerformancePage from "./Pages/Reports/MasterPerformancePage"

import SendWhatsapp from "./Pages/ManageWhatsapp/SendWhatsapp"
import StudentAttandanceMessage from "./Pages/ManageWhatsapp/StudentAttandanceMessage"
import BirthDayMessage from "./Pages/ManageWhatsapp/BirthDayMessage"
import BalanceFees from "./Pages/ManageWhatsapp/BalanceFees";
import UpcomingFees from "./Pages/ManageWhatsapp/UpcomingFees";





import TablePerformanceReport from "./Pages/Reports/TablePerformanceReport"
import ReportCardPdf from "./Pages/Reports/ReportCardPdf"
import AddLectures from "./Pages/Lectures/AddUpdateLectures";
import AddMultipleLectures from "./Pages/Lectures/AddMultipleLecture";
import ViewLectures from "./Pages/Lectures/ViewLectures";
import ViewLectureReportStandard from "./Pages/Lectures/LectureReportStandard";
import ViewLectureReportFaculty from "./Pages/Lectures/LectureReportFaculty";
import ViewLectureReportByTeacher from "./Pages/Lectures/LectureReportByTeacher";
import ViewLectureReportSubject from "./Pages/Lectures/LectureReportSubject";
import MarkStudentLectureAttendance from "./Pages/Lectures/MarkStudentAttendance";
import ViewQuetionPaper from "./Pages/MockTest/QuestionBank/ViewQuestionBank";
import UploadQuestionImage from "./Pages/MockTest/QuestionBank/UploadQuestionsImage";



import WhatsappSetting from "./Pages/Settings/Organization/WhatsappSetting/SettingPage";
import ViewBranches from "./Pages/Settings/Organization/Branches/Branches";
import ViewFinancialYear from "./Pages/Settings/Organization/FinancialYear/FinancialYear";
import ViewCourses from "./Pages/Settings/Organization/Courses/Courses";
import ViewStandard from "./Pages/Settings/Organization/Standard/Standard";
import ViewBatch from "./Pages/Settings/Organization/Batch/Batch";
import ViewSubject from "./Pages/Settings/Organization/Subject/ViewSubject";
import ViewTopic from "./Pages/Settings/Organization/Topic/ViewTopic";
import ViewExamTitle from "./Pages/Settings/Organization/ExamTitle/ExamTitle";
import ViewSchoolCollege from "./Pages/Settings/Organization/SchoolCollege/SchoolCollege";
import ViewBoard from "./Pages/Settings/Organization/Board/Board";
import ViewBank from "./Pages/Settings/Organization/Bank/Bank";
import ViewClassroom from "./Pages/Settings/Organization/Classroom/Classroom";
import ViewSMS from "./Pages/Settings/Organization/SMS/SMS";

import ViewVoucherCategory from "./Pages/Settings/Organization/VoucherCategory/ViewVoucherCategory";
import ViewHallticketSettings from "./Pages/Settings/Organization/HallticketSettings/HallticketSettings";
import ViewStudentIdPrefixSettings from "./Pages/Settings/Organization/StudentIdPrefixSettings/StudentIdPrefixSettings";
import ViewReceiptSettingsPrefix from "./Pages/Settings/Organization/ReceiptSettingsPrefix/ReceiptSettingsPrefix";
import ViewLateHalfDay from "./Pages/Settings/StaffPayroll/LateHalfDay/LateHalfDay";
import ViewFacultyHoliday from "./Pages/Settings/StaffPayroll/FacultyHoliday/ViewFacultyHoliday";
import ViewMasterAdmin from "./Pages/Settings/User/MasterAdmin/ViewMasterAdmin";
import ViewAdminSatff from "./Pages/Settings/User/AdminStaff/ViewAdminStaff";
import AddAdminSatff from "./Pages/Settings/User/AdminStaff/AddAdminStaff";
import ViewMenuGroup from "./Pages/Settings/User/MenuGroup/ViewMenuGroup";
import ViewDropdownMenu from "./Pages/Settings/User/DropdownMenu/ViewDropdownMenu";







function App() {
  return (

    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route path="" element={<HomePage />} />
        <Route path="/addStudentEnquiry" element={<AddUpdate />} />
        <Route path="/updateStudentEnquiry/:Sr_No" element={<AddUpdate />} />
        <Route path="/viewEnquiry" element={<ViewEnquiry />} />
        <Route path="/studentRegistration" element={<StudentRegistration />} />
        {/* <Route path="/addStudent" element={<AddStudent />} /> */}
        <Route path="/addStudent/:opration/:Sr_No" element={<StudentRegistration />} />
        <Route path="/AdmissionCancelationReport" element={<AdmissionCancelationReport />} />
        <Route path="/viewActiveStudent" element={<ViewStudent />} />
        <Route path="/viewBatchWiseStudent/:StdId/:BatchId" element={<ViewStudent />} />
        <Route path="/viewInactiveStudent" element={<ViewInactiveStudent />} />
        <Route path="/studentProfile/:opration/:Sr_No" element={<StudentProfile2 />} />
        <Route path="/addStaff" element={<AddStaff />} />
        <Route path="/viewStaff" element={<ViewStaff />} />
        <Route path="/staffProfile/:opration/:Sr_No" element={<StaffProfile />} />
        <Route path="/viewStaffAttendance" element={<ViewAttendance />} />
        <Route path="/BatchWiseStudentView" element={<BatchStudentView />} />
        <Route path="/addVoucher" element={<Voucher />} />
        <Route path="/viewVoucher" element={<ViewVoucher />} />
        <Route path="/addBill" element={<Bill />} />
        <Route path="/viewBill" element={<ViewBill />} />
        <Route path="/FeesSummary" element={<FeesSummary />} />
        <Route path="/feesReceipts/:ID" element={<FeesReceipts />} />
        <Route path="/masterFeesReceipts/:ID" element={<MasterFeesReceipts />} />
        <Route path="/StudentPerformance" element={<StudentPerformance />} />


        <Route path="/FeesOverview" element={<FeesOverview />} />
        <Route path="/expenseSummary" element={<ExpenseSummary />} />
        <Route path="/updateChequePayments" element={<UpdateChequePayments />} />
        <Route path="/viewTOD" element={<ViewTOD />} />
        <Route path="/addTOD" element={<AddTOD />} />
        <Route path="/viewHoliday" element={<ViewHoliday />} />
        <Route path="/addHoliday" element={<AddHoliday />} />
        <Route path="/viewNotice" element={<ViewNotice />} />
        <Route path="/addNotice" element={<AddNotice />} />
        <Route path="/viewEmergencyCircular" element={<ViewEmergencyCircular />} />
        <Route path="/addEmergencyCircular" element={<AddEmergencyCircular />} />
        <Route path="/StudentRemarks" element={<StudentRemarks />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/viewELearning" element={<ViewViedo />} />

        <Route path="/ViewGallery" element={<ViewGallery />} />
        <Route path="/viewHomework" element={<ViewHomework />} />
        <Route path="/addHomework" element={<AddHomework />} />
        <Route path="/viewClasswork" element={<ViewClasswork />} />
        <Route path="/addClasswork" element={<AddClasswork />} />
        <Route path="/viewNotes" element={<ViewNotes />} />
        <Route path="/addNotes" element={<AddNotes />} />
        <Route path="/viewDPP" element={<ViewDPP />} />
        <Route path="/addDPP" element={<AddDPP />} />
        <Route path="/viewVideoLink" element={<ViewVideoLink />} />
        <Route path="/addVideoLink" element={<AddVideoLink />} />
        <Route path="/viewPublications" element={<ViewPublications />} />
        <Route path="/addPublications" element={<AddPublications />} />
        <Route path="/viewBannerImages" element={<ViewBannerImages />} />
        <Route path="/addBannerImages" element={<AddBannerImages />} />
        <Route path="/viewComplaints" element={<ViewComplaints />} />
        <Route path="/addNoticeForFaculty" element={<AddNoticeForFaculty />} />
        <Route path="/viewNoticeForFaculty" element={<ViewNoticeForFaculty />} />
        <Route path="/viewStudentDoubts" element={<ViewStudentDoubts />} />
        {/* //Lecture */}
        <Route path="/addLectures" element={<AddLectures />} />
        <Route path="/updateLectures/:ID" element={<AddLectures />} />
        <Route path="/addMultipleLectures" element={<AddMultipleLectures />} />
        <Route path="/viewLectures" element={<ViewLectures />} />
        <Route path="/MarkStudentLectureAttendance/:ID" element={<MarkStudentLectureAttendance />} />
        <Route path="/viewLectureReportStandard" element={<ViewLectureReportStandard />} />
        <Route path="/viewLectureReportFaculty" element={<ViewLectureReportFaculty />} />
        <Route path="/viewLectureReportByTeacher/:teacherId" element={<ViewLectureReportByTeacher />} />
        <Route path="/viewLectureReportSubject/:Sub_Id/:Batch_Id" element={<ViewLectureReportSubject />} />
        {/* //Test */}
        <Route path="/addTest" element={<AddTest />} />
        <Route path="/viewTest" element={<ViewTest />} />
        <Route path="/testPerformance/:TestId" element={<TestPerformance />} />
        <Route path="/markTestAttendance/:TestId" element={<MarkTestAttendance />} />
        <Route path="/resultSummary" element={<ResultSummary />} />
        <Route path="/performanceSummary" element={<PerformanceSummary />} />
        <Route path="/performanceResultSummary/:ID/:Type" element={<PerformanceResultSummary />} />
        <Route path="/ViewDailyQuiz" element={<ViewDailyQuiz />} />
        <Route path="/CreateDailyQuiz" element={<CreateDailyQuiz />} />
        <Route path="/CreateDailyQuiz/:TestID" element={<CreateDailyQuiz />} />
        <Route path="/DailyQuizResult/:TestID" element={<DailyQuizResult />} />
        <Route path="/ViewNewTest" element={<ViewNewTest />} />
        <Route path="/CreateTestSets" element={<CreateTestSets />} />
        <Route path="/CreateTestSets/:ID" element={<CreateTestSets />} />
        <Route path="/TestSetsResult/:ID" element={<TestSetsResult />} />
        <Route path="/Edit_Examination" element={<Edit_Examination/>} />
        <Route path="/Examination" element={<Examination/>} />

       {/* //Mock Test */}
       <Route path="/viewQuestionPaper" element={<ViewQuetionPaper />} />
       <Route path="/uploadQuestionImage" element={<UploadQuestionImage />} />


        {/* //Manage App */}
        <Route path="/ManageClasses" element={<ManageClasses />} />

        {/* //Manage WHatsApp */}
        <Route path="/sendWhatsapp" element={<SendWhatsapp />} />
        <Route path="/studentAttandanceMessage" element={<StudentAttandanceMessage />} />
        <Route path="/studentBirthDayMessage" element={<BirthDayMessage />} />
        <Route path="/studentBalanceFeesMessage" element={<BalanceFees />} />
        <Route path="/studentUpcomingFeesMessage" element={<UpcomingFees />} />





        {/* //Inventory */}
        <Route path="/viewVendors" element={<ViewVendors />} />
        <Route path="/addVendors" element={<AddUpdateVendors />} />
        <Route path="/updateVendors/:V_Id" element={<AddUpdateVendors />} />
        <Route path="/ViewProductCategory" element={<ViewProductCategory />} />
        <Route path="/InventoryVendorLedger" element={<Inventory_VendorLedger />} />
        <Route path="/viewInventoryBills" element={<ViewBills />} />
        <Route path="/addInventoryBill" element={<AddBill />} />
        <Route path="/ViewVendorPayments" element={<ViewVendorPayments />} />
        <Route path="/VendorPaymentEntry" element={<VendorPayment_Entry />} />
        <Route path="/ManageStock" element={<ManageStock />} />

         {/* //Reports */}
         <Route path="/StudentBiometricAtt" element={<StudentBiometricAtt />} />
         <Route path="/biometricAttendanceCount" element={<BiometricAttendanceCount />} />
         <Route path="/facultyCollectiveReport" element={<FacultyCollectiveReport />} />
         {/* <Route path="/StudentBiometricAtt" element={<StudentBiometricAtt />} />
         <Route path="/StudentBiometricAtt" element={<StudentBiometricAtt />} />
         <Route path="/StudentBiometricAtt" element={<StudentBiometricAtt />} /> */}


         <Route path="/FeesReport" element={<FeesReport />} />
         <Route path="/TopicWiseLecture" element={<TopicWiseLecture />} />
         <Route path="/LectureReport" element={<LectureReport />} />
         <Route path="/StudentFeesReport" element={<StudentFeesReport />} />
         <Route path="/IncomeExpenseReport" element={<IncomeExpenseReport />} />
         <Route path="/InventoryStockReport" element={<InventoryStockReport />} />
         <Route path="/InventoryStockLog/:ProdId" element={<InventoryStockLog />} />
         <Route path="/ViewCanceledAdmissions" element={<ViewCanceledAdmissions />} />
         <Route path="/IDCard" element={<IDCard />} />
         <Route path="/ReportCard" element={<ReportCard />} />
         <Route path="/ViewPerformance" element={<ViewPerformance />} />
         <Route path="/Performance/:Sr_No" element={<MasterPerformancePage />} />
         <Route path="/TablePerformanceReport/:ID" element={<TablePerformanceReport />} />
         <Route path="/ReportCardPdf/:reportCardNum" element={<ReportCardPdf />} />


        {/* //Settings */}
        <Route path="/whatsappSetting" element={<WhatsappSetting />} />
        <Route path="/viewBranches" element={<ViewBranches />} />
        <Route path="/viewFinancialYear" element={<ViewFinancialYear />} />
        <Route path="/viewCourses" element={<ViewCourses />} />
        <Route path="/viewStandard" element={<ViewStandard />} />
        <Route path="/viewBatch" element={<ViewBatch />} />
        <Route path="/viewSubject" element={<ViewSubject />} />
        <Route path="/viewTopic" element={<ViewTopic />} />
        <Route path="/viewExamTitle" element={<ViewExamTitle />} />
        <Route path="/viewSchoolCollege" element={<ViewSchoolCollege />} />
        <Route path="/viewBoard" element={<ViewBoard />} />
        <Route path="/viewBank" element={<ViewBank />} />
        <Route path="/viewClassroom" element={<ViewClassroom />} />
        <Route path="/viewSMS" element={<ViewSMS />} />
        <Route path="/viewVoucherCategory" element={<ViewVoucherCategory />} />
        <Route path="/viewHallticketSettings" element={<ViewHallticketSettings />} />
        <Route path="/viewStudentIdPrefixSettings" element={<ViewStudentIdPrefixSettings />} />
        <Route path="/viewReceiptSettingsPrefix" element={<ViewReceiptSettingsPrefix />} />
        <Route path="/viewLateHalfDay" element={<ViewLateHalfDay />} />
        <Route path="/viewFacultyHoliday" element={<ViewFacultyHoliday />} />
        <Route path="/viewMasterAdmin" element={<ViewMasterAdmin />} />
        <Route path="/viewAdminSatff" element={<ViewAdminSatff />} />
        <Route path="/addAdminSatff" element={<AddAdminSatff />} />
        <Route path="/viewMenuGroup" element={<ViewMenuGroup />} />
        <Route path="/viewDropownMenu" element={<ViewDropdownMenu />} />


       {/* <Route path="*" element={<PageNotFound />} /> */}
      </Route>
      <Route path="/switchBranch" element={<SwitchBranch />} />
      <Route path="/login" element={<Login />} />

    </Routes>
  );
}

export default App;
