import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { First } from "react-bootstrap/esm/PageItem";

const AddMenuGroup = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isValidMobile, setIsValidMobile] = useState({});
    const [isValidMobile1, setIsValidMobile1] = useState({});
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [file, setFile] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setShow(open);
    }, [open]);





    const handleClose = () => {
        setShow(false);
        onClose();
    };

    //"Name": `${Bill.First_Name} ${Bill.Middle_Name ||""} ${Bill.Last_Name||""}`,

    const [Bill, setBill] = useState({
        Group_Name: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addDropdownGroup`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Menu Group Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                Group_Name: ""
                            });
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!! Please try again later.",
                        "error"
                    );
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };



    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Menu Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">

                            {/* <div className="col-md-3 mb-3">
                                <label className="form-label" htmlFor="Branch ">Branch </label>
                                <select
                                    className="form-select form-select-sm"
                                    id="Branch "
                                    name="Branch "
                                    value={Bill.Head_Id}
                                    onChange={(e) => {
                                        const selectedIndex = e.target.selectedIndex;
                                        const selectedOption = e.target[selectedIndex];
                                        setBill({
                                            ...Bill,
                                            Head_Id: selectedOption.value,
                                            Head_Name: selectedOption.label
                                        });
                                    }}
                                >
                                    <option selected value="All">All</option>
                                    <option value={userData?.Head_Id}>{userData?.Head_Name}</option>
                                </select>
                            </div> */}

                            <div className={"mb-3 col-md-12"}>
                                <label className="form-label" htmlFor="Group_Name">
                                Group Name<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Group_Name"
                                    id="Group_Name"
                                    required
                                    value={Bill.Group_Name}
                                    placeholder="Enter Group Name"
                                    onChange={handleChange}
                                />
                            </div>
                 
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddMenuGroup;
