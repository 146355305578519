import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Switch from "react-switch";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import HeadMasterDropDown from "../../../DropDowns/HeadMasterDropDown";
import { useUser } from "../../../../Components/UserContext";
import { apiUrl } from "../../../../Components/apiConfig";
import axios from "axios";

const SMS = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const { opration, Sr_No } = useParams();
    const [Bill, setBill] = useState([]);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        if (userData && userData.Org_Id) {
            fetchData();
        }
    }, [userData]);

    const fetchData = async () => {
        try {
            showLoader();

            if (userData && userData.Org_Id) {
                showLoader();
                const std = await axios.get(
                    `${apiUrl}/getSmsSetting?OrgId=${userData.Org_Id}`
                );

                setBill(std.data);
                console.log(std.data);
            }
            // console.log(res.data);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };
    console.log(Bill)

    const handleSwitchChange = (index, checked) => {
        const updatedBill = [...Bill];
        updatedBill[index].Status = checked ? 1 : 0;
        setBill(updatedBill);
        handleSubmit();
    };

    const handleSubmit = () => {
        const updateBills = Bill.map((item) => ({
            ...item,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        }));

        const errors = {};

        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .put(`${apiUrl}/updateSmsSetting`, updateBills)
                .then((res) => {
                    Swal.fire("Success!", "Setting Updated Successfully!!", "success").then(
                        (result) => {
                            if (result.isConfirmed) {
                                fetchData();
                            }
                        }
                    );
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!!, Please try again later.",
                        "error"
                    );

                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div className="content">
            <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                    <div className="col-md-6">SMS SETTINGS</div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <div className="col-md-5">
                            <HeadMasterDropDown label="Branch" name="Branch" id="Branch" />
                        </div>
                    </div>
                </div>
            </h4>
            <div className="row g-4">
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                    <div
                        className="card shadow-none border border-300 my-4"
                        data-component-card="data-component-card"
                    >
                        <div className="card-body p-0">
                            <div className="p-4 code-to-copy">

                                <div className="row">
                                    <p>Toggle this custom switch element</p>
                                    <table
                                        className="table table-bordered border-primary table-hover table-responsive"
                                        cellSpacing="0"
                                        cellPadding="4"
                                        align="Center"
                                        rules="cols"
                                        style={{
                                            color: "Black",
                                            backgroundColor: "White",
                                            borderColor: "#DEDFDE",
                                            borderWidth: "1px",
                                            borderStyle: "None",
                                            borderCollapse: "collapse",
                                        }}
                                    >
                                        <thead>
                                            <tr align="center">
                                                <th scope="col">Name</th>
                                                <th scope="col">ON/OFF</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Bill && Bill.length > 0 ? (
                                                Bill.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            {item.Name}
                                                        </td>
                                                        <td className="text-center">
                                                            <Switch
                                                                checked={item.Status}
                                                                onChange={(checked) => handleSwitchChange(index, checked)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="2" className="text-center text-danger">
                                                        Data not found!!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                            {loader}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SMS;
