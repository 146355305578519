import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { formatInputDate } from "../../Components/dateFormate";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { useNavigate, useParams, Link } from "react-router-dom";
import "jspdf-autotable";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
ChartJS.register(BarElement, CategoryScale, LinearScale, Legend);

const Performance = () => {
  const [table, setTable] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [chartData, setChartData] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [subjectOption, setSubjects] = useState([]);
  const [subjectChartData, setSubjectChartData] = useState({});
  const [monthChartData, setMonthChartData] = useState({});
  const [overallChartData, setOverallChartData] = useState({});

  const [Sub_Id, setSubId] = useState("");

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
    }
  }, [userData]);

  const fetchData = async () => {
    try {
      showLoader();
      const res = await axios.get(`${apiUrl}/getCombinedChartData/${Sr_No}`);

      if (res.data) {
        const { headers, data } = res.data;

        const labels = headers.slice(1); // First item is 'Subject', skip it
        const datasets = data.map((subjectData, index) => ({
          label: subjectData[0], // Subject name
          data: subjectData.slice(1), // Subject scores
          fill: false,
          borderColor: getFreshColor(index),
          backgroundColor: getFreshColor(index),
        }));

        setChartData({
          labels,
          datasets,
        });
      }
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };


  const handleDropdownChange = async (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value === "subject-wise") {
      await fetchSubjects();
    } else {
      setSubId(""); 
    }

    if (value === "month-wise") {
      await fetchMonthWise();
    }

    if (value === "overall-performance") {
      await fetchOverall();
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getStudentSubjects/${Sr_No}`);
      const subjects = response.data.map((category) => ({
        label: category.Subject_Name,
        value: category.Subject_Id,
      }));
      setSubjects(subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
      // Handle error state or display a message to the user
    }
  };

  const fetchMonthWise = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getMonthwiseChartData/${Sr_No}`);
      if (response.data) {
        const { headers, data } = response.data;

        const labels = headers.slice(1); // Skip the first item 'Subject'
        const datasets = data.map((subjectData, index) => ({
          label: subjectData[0], // Subject name
          data: subjectData.slice(1), // Scores for each month
          fill: false,
          borderColor: getFreshColor(index),
          backgroundColor: getFreshColor(index),
        }));

        setMonthChartData({
          labels,
          datasets,
        });
      }
      hideLoader();
    } catch (error) {
      console.error("Error fetching month-wise chart data:", error);
      // Handle error state or display a message to the user
    }
  };

  const fetchOverall = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getOverallPercentage/${Sr_No}`);
      if (response.data) {
        const rawData = response.data;
  
        // Extract labels and data from the fetched data
        const labels = rawData.slice(1).map((item) => item[0]); // Skip the first item 'Year'
        const data = rawData.slice(1).map((item) => item[1]);
  
        // Generate different colors for each year
        const backgroundColors = labels.map((_, index) => getFreshColor(index));
        const borderColors = labels.map((_, index) => getFreshColor(index));
  
        // Assign different colors to each dataset
        const datasets = [
          {
            label: rawData[0][1], // 'Percentage' from the header
            data: data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ];
  
        // Set the chart data
        setOverallChartData({
          labels: labels,
          datasets: datasets,
        });
      }
      hideLoader();
    } catch (error) {
      console.error("Error fetching overall performance data:", error);
      // Handle error state or display a message to the user
    }
  };
  

  const fetchSubjectWiseChartData = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${apiUrl}/getSubjectwiseChartData/${Sr_No}/${Sub_Id}`
      );
      if (response.data) {
        const { headers, data } = response.data;

        const labels = data.map((entry) => entry[0]); // Extract dates
        const percentages = data.map((entry) => entry[1]); // Extract percentages

        const datasets = [
          {
            label: "Percentage",
            data: percentages, // Use percentages as the data
            fill: false,
            borderColor: getFreshColor(0),
            backgroundColor: getFreshColor(0),
          },
        ];

        setSubjectChartData({
          labels,
          datasets,
        });
      }
      hideLoader();
    } catch (error) {
      console.error("Error fetching subject-wise chart data:", error);
      // Handle error state or display a message to the user
    }
  };

  const monthChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Student Monthwise Performance",
      },
      legend: {
        display: true,
        position: "right",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`,
        },
        title: {
          display: true,
          text: "Percentage of Marks in Total Marks",
        },
      },
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
    },
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Student Combined Performance",
      },
      legend: {
        display: true,
        position: "right",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`,
        },
        title: {
          display: true,
          text: "Marks in Percentage",
        },
      },
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
    },
  };

  const subjectChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Student Subjectwise Performance",
      },
      legend: {
        display: true,
        position: "right",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`,
        },
        title: {
          display: true,
          text: "Percentage",
        },
      },
      x: {
        title: {
          display: true,
          text: "Test Date",
        },
      },
    },
  };

  const overallChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Student Overall Performance",
      },
      legend: {
        display: false,
        position: "right",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`,
        },
        title: {
          display: true,
          text: "Secured Percentage",
        },
      },
      x: {
        title: {
          display: true,
          text: "Year",
        },
      },
    },
  };

  const getFreshColor = (index) => {
    const colors = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];
    return colors[index % colors.length];
  };

  useEffect(() => {
    if (selectedOption === "subject-wise" && Sub_Id) {
      fetchSubjectWiseChartData();
    }
  }, [selectedOption, Sub_Id]);

  return (

      
          <div className="row mb-2">
          <div className="col-md-8">
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                {selectedOption === "subject-wise" &&
                  Object.keys(subjectChartData).length > 0 && (
                    <Line options={subjectChartOptions} data={subjectChartData} />
                  )}
                {selectedOption === "month-wise" &&
                  Object.keys(monthChartData).length > 0 && (
                    <Bar options={monthChartOptions} data={monthChartData} />
                  )}
                {selectedOption === "overall-performance" &&
                  Object.keys(overallChartData).length > 0 && (
                    <Bar options={overallChartOptions} data={overallChartData} />
                  )}
                {selectedOption !== "subject-wise" &&
                  selectedOption !== "month-wise" &&
                  selectedOption !== "overall-performance" &&
                  Object.keys(chartData).length > 0 && (
                    <Line options={chartOptions} data={chartData} />
                  )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="performanceOptions">
                        Performance Options
                      </label>
                      <select
                        className="form-select"
                        id="performanceOptions"
                        name="performanceOptions"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        required
                      >
                        <option value="subject-wise">Subject Wise</option>
                        <option value="month-wise">Month Wise</option>
                        <option value="combine-performance">Combine Performance</option>
                        <option value="overall-performance">Overall Performance</option>
                      </select>
                    </div>
                  </div>
                </div>
                {selectedOption === "subject-wise" && (
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Subject">
                          Subject:
                        </label>
                        <select
                          className="form-select form-select-sm"
                          id="Subject"
                          onChange={(e) => setSubId(e.target.value)}
                        >
                          <option value="" label="All"></option>
                          {subjectOption.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {loader}
        </div>
      
  );
};

export default Performance;
