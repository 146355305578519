import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useUser } from "../../Components/UserContext";
import apiConfig, { apiUrl } from "../../Components/apiConfig";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { formatDate } from "../../Components/dateFormate";
//import masystop from "/CustomerLogo/I-Class.png";
import studentphoto from "../Reports/student.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const IDCard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  const [studentInfo, setStudentInfo] = useState([]);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
    }
  }, [userData, Std_Id]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData && userData.Org_Id && userData?.Head_Id) {
        const stdResponse = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const stdOptions = stdResponse.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(stdOptions);
      }

      if (userData && userData.Org_Id && Std_Id) {
        const batchResponse = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
        );

        const batchOptions = batchResponse.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(batchOptions);
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      showLoader();

      const response = await axios.get(
        `${apiUrl}/getIDCard?stdId=${Std_Id}&batchId=${Batch_ID}`
      );
      console.log(response.data);
      if (Array.isArray(response.data) && response.data.length > 0) {
        setStudentInfo(response.data);
      } else if (response.data && !Array.isArray(response.data)) {
        setStudentInfo([response.data]);
      } else {
        Swal.fire("No Data", "No student information found.", "error");
      }

      showModal();
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Server Timeout",
        "Server is Busy!!!, Please try again later.",
        "error"
      );
    } finally {
      hideLoader();
    }
  };

  const showModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const convertImageToBase64 = (url) => {
    debugger
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // This enables CORS
      img.src = url;
  
      img.onload = () => {
        console.log(`Image loaded successfully from ${url}`);
        try {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        } catch (error) {
          console.error("Error converting image to base64:", error);
          reject(error);
        }
      };
  
      img.onerror = (error) => {
        console.error(`Error loading image from ${url}`, error);
        reject(error);
      };
    });
  };
  
  const downloadPDF = async () => {
    debugger
    const pdf = new jsPDF({ orientation: "landscape", unit: "px", format: "a4" });
    const idCards = document.querySelectorAll(".id-card");
    const idCardWidth = 122;
    const idCardHeight = 190;
    const marginLeft = 5;
    const marginTop = 20;
    const spacingX = 3;
    const spacingY = 20;
  
    let x = marginLeft;
    let y = marginTop;
    let cardCount = 0;
  
    for (let i = 0; i < idCards.length; i++) {
      const card = idCards[i];
      const studentPhoto = card.querySelector(".id-card-body img");
      const photoSrc = studentPhoto.getAttribute("src");
  
      let imgData;
      try {
        imgData = await convertImageToBase64(photoSrc);
        console.log(`Converted image to base64: ${imgData.substring(0, 30)}...`);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        imgData = studentphoto; // Fallback image if conversion fails
      }
  
      const canvas = await html2canvas(card, { scale: 3, backgroundColor: "white", useCORS: true });
      const cardImgData = canvas.toDataURL("image/png");
      pdf.addImage(cardImgData, "PNG", x, y, idCardWidth, idCardHeight);
  
      cardCount++;
      if (cardCount % 5 === 0) {
        x = marginLeft;
        y += idCardHeight + spacingY;
      } else {
        x += idCardWidth + spacingX;
      }
  
      if (cardCount % 10 === 0) {
        x = marginLeft;
        y = marginTop;
        if (i !== idCards.length - 1) {
          pdf.addPage();
        }
      }
    }
  
    pdf.save("ID_Cards.pdf");
  };
  
  
  

  const idCardStyles = `
    .id-card {
      width: 100%;
      max-width: 205px;
      height: 322px;
      border: 2px solid #007bff;
      border-radius: 10px;
      padding: 8px;
      margin: 30px 10px;
      text-align: center;
      background-color: white;
      color: black;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      display: inline-block;
    }

    .id-card img {
      width: 100%;
      height: auto;
    }

    .id-card-header img {
      width: 100%;
      max-height: 70px;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }

    .id-card-body img {
      width: 60px;
      height: 80px;
      border-radius: 10px;
      object-fit: cover;
    }

    .id-card-footer img {
      width: 60px;
      height: 30px;
    }

    .id-card-body p {
      margin-bottom: 0 !important;
      font-size: 12px;
    }
  `;

  return (
    <div className="content">
      <style>{idCardStyles}</style>
      <div className="container-fluid">
        <div className="page-content-wrapper">
          <div className="row justify-content-between">
            <h4 className="mb-0">
              <div className="row">
                <div className="col-md-6">ID CARDS</div>
              </div>
            </h4>
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              <div className="card shadow-none border border-300 my-4">
                <div className="card-body p-0">
                  <div className="p-4 code-to-copy">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Branch">
                            BRANCH
                          </label>
                          <HeadMasterDropDown
                            label="Branch"
                            name="Branch"
                            id="Branch"
                          />
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="Standard">
                                Standard :
                              </label>
                              <select
                                className="form-select form-select-sm"
                                id="Standard"
                                disabled={userData?.Head_Id === ""}
                                value={Std_Id}
                                onChange={(e) => {
                                  setStdId(e.target.value);
                                  setBatchID("");
                                }}
                              >
                                <option value="" label="All"></option>
                                {StdOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="Batch">
                                Batch :
                              </label>
                              <select
                                className="form-select form-select-sm"
                                id="Batch"
                                value={Batch_ID}
                                disabled={Std_Id === ""}
                                onChange={(e) => setBatchID(e.target.value)}
                              >
                                <option value="" label="All"></option>
                                {BatchOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={Batch_ID?"row":"d-none"}>
                        <div className="col-md-12 text-center mt-3">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Student ID Cards</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div id="idbody" className="modal-body">
                <div className="row">
                  {studentInfo.map((student, index) => (
                    <div key={index} className="id-card col-md-4">
                      <div className="id-card-header">
                        <img src={`/CustomerLogo/I-Class.png`} alt="School Header" />
                      </div>
                      <div className="id-card-body">
                        <p>{`I-Card: ${student.Academic_Year}`}</p>
                          <img
                              src={
                                student.Photo
                                  ? `/Documents/Student/Photos/${student.Photo}`
                                  : studentphoto
                              }
                              alt="Student Photo"
                            />
                        <p style={{ fontSize: "14px" }}>
                          <strong>{`${student.First_Name} ${student.Middle_Name} ${student.Last_Name}`}</strong>
                        </p>
                        <p>{`Std: ${student.Std_Name} Div: ${student.Batch_Name}`}</p>
                        <p>{`DOB: ${formatDate(student.DOB)}`}</p>
                        <p>{`Mob: ${student.Father_Mobile}/${student.Mother_Mobile}`}</p>
                      </div>
                      <div className="id-card-footer">
                        <p
                          style={{
                            marginTop: "21px",
                            textAlign: "right",
                            fontSize: "13px",
                          }}
                        >
                          <strong>Principal</strong>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={downloadPDF}
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {loader}
    </div>
  );
};

export default IDCard;
