import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import YearDropDown from "../../DropDowns/YearDropDown";
import BankDropdoun from "../../DropDowns/BankDropdoun";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import dayjs from "dayjs";
import { formatDate } from "../../../Components/dateFormate";

const PaymentModal = ({ open, onClose, FeesData, Type, T_Paid }) => {
  const { userData } = useUser();
  const [data, setData] = useState({});
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const [gstPercent, setgstPercent] = useState();
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    onClose();
  };
  useEffect(() => {
    if (FeesData) {

      setData({
        ...FeesData,
        Total_Amount: FeesData.Amount,
        Payment_Date: FeesData.Due_Date,
        Financial_Year: ""
      })
    }
  }, [FeesData]);
  console.log(FeesData)
  console.log(data)
  ///FeesData.Enq_Id
  const [Student, setStudent] = useState({
    Status: "Interested",
    Next_Followup_On: "",
    Followup_Remarks: ""

  });

  useEffect(() => {
    if (userData) {
      fetchData();
    }
  }, [userData]);

  const fetchData = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getIsGST?HeadId=${userData?.Head_Id}&OrgId=${userData?.Org_Id}`
    );
    console.log(result?.data);
    setgstPercent(result?.data);
    hideLoader();

  };


  const handleSubmit = (event) => {
    event.preventDefault();

    const updateData = {
      ...data,
      T_GST_Percent: parseFloat(gstPercent),
      Std_Id: FeesData.Std_Id,
      Std_Name: FeesData.Std_Name,
      Batch_ID: FeesData.Batch_ID,
      Batch_Name: FeesData.Batch_Name,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.Org_Name,
      User_Name: userData.UserName,
    };



    const errors = {};




    if (Object.keys(errors).length === 0) {

      showLoader();
      axios
        .post(`${apiUrl}/MakeInstallmentPayment`, updateData)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Installment Paid Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              // navigate("/viewEnquiry");
              handleClose();
            }
          });
          hideLoader();

        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();

        });
    } else {
      setErrors(errors);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updateStudent = {
      ...Student,
      Assigned_To: FeesData.Assigned_To,
      Enquiry_No: FeesData.Enquiry_No,
      First_Name: FeesData.First_Name,
      Middle_Name: FeesData.Middle_Name,
      Last_Name: FeesData.Last_Name,
      Head_Id: FeesData.Head_Id,
      Head_Name: FeesData.Head_Name,
      Subhead_Id: FeesData.Subhead_Id,
      Course_ID: FeesData.Course_ID,
      Course_Name: FeesData.Course_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      Username: userData.Username,
      UserName: userData.UserName
    };



    const errors = {};




    if (Object.keys(errors).length === 0) {

      showLoader();
      axios
        // .put(`${apiUrl}/updateEnquiryFollowup/${FeesData.Enq_Id}`, updateStudent)
        // .then((res) => {
        //   Swal.fire(
        //     "Success!",
        //     "Enquiry Followup Updated Successfully!!",
        //     "success"
        //   ).then((result) => {
        //     if (result.isConfirmed) {
        //       // navigate("/viewEnquiry");
        //       fetchData();
        //     }
        //   });
        //   hideLoader();

        // })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();

        });
    } else {
      setErrors(errors);
    }
  };



  return (<div>
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      style={{ boxShadow: "none !important" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {Type === 'Submit' && (<h5 className="mb-3">Tuition Amount Paid : {T_Paid}</h5>)}

          <form onSubmit={Type === 'Update' ? handleUpdate : handleSubmit}>
            <div className="row">
              {Type === 'Update' ? (
                <>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Mode">
                      Payment Mode :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Payment_Mode"
                      id="Payment_Mode"
                      onChange={(e) => {
                        setData({
                          ...data,
                          Payment_Mode: e.target.value,
                        });
                      }}
                    >
                      <option value="">--Select--</option>

                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Card">Card</option>
                      <option value="Online">Online</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Due_Date">
                      Payment Date :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="date"
                      name="Due_Date"
                      id="Due_Date"
                      value={data.Due_Date ? dayjs(data.Due_Date).format("YYYY-MM-DD") : ""}
                      onChange={(e) => {
                        setData({
                          ...data,
                          Due_Date: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Amount">
                      Amount :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Amount"
                      id="Amount"
                      disabled
                      value={data.Amount}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                          /^(\d*\.\d*)\..*$/,
                          "$1"
                        );
                        // setInstallment({
                        //   ...installment,
                        //   Full_Payment_Amt: amount,
                        //  // Net_Amount: net_Amount,
                        // });

                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Late_Fees">
                      Late Fees :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Late_Fees"
                      id="Late_Fees"

                      value={data.LateFee}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                          /^(\d*\.\d*)\..*$/,
                          "$1"
                        );

                        let netAmount = parseFloat(data?.Amount) + (parseFloat(amount) || 0)
                        setData({
                          ...data,
                          LateFee: amount,
                          Total_Amount: netAmount,
                        });

                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Total_Amount">
                      Total Amount :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Total_Amount"
                      id="Total_Amount"
                      disabled
                      value={data.Total_Amount}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                          /^(\d*\.\d*)\..*$/,
                          "$1"
                        );
                        // setInstallment({
                        //   ...installment,
                        //   Full_Payment_Amt: amount,
                        //  // Net_Amount: net_Amount,
                        // });

                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Date">
                      Payment Date :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="date"
                      name="Payment_Date"
                      id="Payment_Date"
                      value={data.Payment_Date ? dayjs(data.Payment_Date).format("YYYY-MM-DD") : ""}

                      onChange={(e) => {
                        setData({
                          ...data,
                          Payment_Date: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Financial_Year">
                      Financial Year<span style={{ color: "Red" }}>*</span> :
                    </label>
                    <YearDropDown
                      name="Financial_Year"
                      id="Financial_Year"
                      value={data.Financial_Year}
                      isClearable
                      required
                      onChange={(selectedOption) => {
                        //console.log(selectedOption);
                        setData({
                          ...data,
                          Financial_Year: selectedOption ? selectedOption.value : "",
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Mode">
                      Payment Mode<span style={{ color: "Red" }}>*</span> :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Payment_Mode"
                      id="Payment_Mode"
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          Payment_Mode: e.target.value,
                        });
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Card">Card</option>
                      <option value="Online">Online</option>
                    </select>
                  </div>
                </>)}
              {["Cheque"].includes(data.Payment_Mode) && (
                <>
                  <div className="mb-3 col-md-12">
                    <label className="form-label" htmlFor="Bank">
                      Bank Name<span style={{ color: "Red" }}>*</span> :
                    </label>
                    <BankDropdoun
                      name="Bank"
                      required={true}
                      id="Bank"
                      value={data.Bank_Name ? [{ value: data.Bank_Name, label: data.Bank_Name }] : null}
                      onChange={(e) => {
                        setData({
                          ...data,
                          Bank_Name: e.value,
                        });
                      }}
                    />


                  </div>
                  <div className="mb-3 col-md-4">
                    <label
                      className="form-label"
                      htmlFor="Cheque_No"
                    >
                      Cheque No<span style={{ color: "Red" }}>*</span> :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Cheque_No"
                      id="Cheque_No"
                      required
                      value={data.Cheque_No}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9]/g, "");
                        setData({
                          ...data,
                          Cheque_No: decimalInput,
                        });
                      }}

                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label
                      className="form-label"
                      htmlFor="Cheque_Date"
                    >
                      Cheque Date<span style={{ color: "Red" }}>*</span> :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="date"
                      name="Cheque_Date"
                      id="Cheque_Date"
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          Cheque_Date: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="Cheque_Status">
                      Cheque Status<span style={{ color: "Red" }}>*</span> :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Cheque_Status"
                      id="Cheque_Status"
                      required
                      value={data.Cheque_Status}
                      onChange={(e) => {
                        setData({
                          ...data,
                          Cheque_Status: e.target.value,
                        });
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="Uncleared">Uncleared</option>
                      <option value="Honoured">Honoured</option>
                      <option value="Dishonoured">Dishonoured</option>

                    </select>
                  </div>
                </>
              )}

              {["Cheque", "Card", "Online"].includes(
                data?.Payment_Mode
              ) && (
                  <>
                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="Refrence_No">
                        Refrence No:
                      </label>
                      <input
                        className={`form-control form-control-sm`}
                        type="text"
                        name="Refrence_No"
                        id="Refrence_No"
                        onChange={(e) => {
                          setData({
                            ...data,
                            Reference: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </>
                )}

            </div>
            <div className="row">
              <div className="col-md-12 text-center mt-3">
                <button type="submit" className="btn btn-sm btn-primary">
                  {Type === 'Update' ? 'Update' : 'Payment'}
                </button>
              </div>
            </div>
          </form>

        </div>
        {loader}
      </Modal.Body>
    </Modal>

  </div>
  )
}

export default PaymentModal