import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import dayjs from "dayjs";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import AdmissionCancelModal from "./AdmissionCancelModal";
import "./toggle.css";
import { useParams } from "react-router-dom";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "./AgGridTable";

const ViewActiveStudent = () => {
  const [table, setTable] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const { StdId, BatchId } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setStudentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [CourseOptions, setCourseOptions] = useState([]);
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [SchoolCollegeOptions, setSchoolCollegeOption] = useState([]);
  const [Course_Id, setCourse] = useState("");
  const [School_College, setSchoolCollege] = useState("");
  const [Std_Id, setStdId] = useState(StdId ? StdId : "");
  const [Batch_ID, setBatchID] = useState(BatchId ? BatchId : "");
  const [Academic_Year, setAcademicYear] = useState("");
  const [Head_Id, setBranch] = useState(userData?.Head_Id);
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  useEffect(() => {
    debugger;
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, userData?.Head_Id, Std_Id, StdId, BatchId]);

  const fetchData = async () => {
    try {
      showLoader();

      const YearResult = await axios.get(
        `${apiUrl}/getYearDropDown?OrgId=${userData.Org_Id}`
      );

      const Yearop = YearResult.data.map((category) => ({
        label: category.Financial_Year,
        value: category.Financial_Year,
      }));
      setYearOptions(Yearop);

      const SchoolCollege = await axios.get(
        `${apiUrl}/getSchoolMaster?OrgId=${userData.Org_Id}`
      );

      const SchoolCollegeop = SchoolCollege.data.map((category) => ({
        label: category.School_Name,
        value: category.School_Name,
      }));
      setSchoolCollegeOption(SchoolCollegeop);

      if (userData && userData.Org_Id && userData?.Head_Id) {
        const Course = await axios.get(
          `${apiUrl}/getCourse?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getCourse = Course.data.map((category) => ({
          label: category.Course_Name,
          value: category.Course_Id,
        }));
        setCourseOptions(getCourse);
        //console.log(getCourse);
      }

      if (userData && userData.Org_Id && userData?.Head_Id) {
        showLoader();
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
        //console.log(getStd);
      }

      if (userData && userData.Org_Id && Std_Id) {
        const batch = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
        );

        const getBatch = batch.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(getBatch);
        // console.log(getBatch);
      }

      const result = await axios.get(
        `${apiUrl}/getCancelAdmission?OrgId=${userData.Org_Id}&HeadId=${
          userData?.Head_Id
        }&CourseID=${Course_Id}&FinancialYear=${Academic_Year}&School=${School_College}&Std_Id=${
          StdId ? StdId : Std_Id
        }&BatchId=${BatchId ? BatchId : Batch_ID}`
      );

      setStudentData(result.data);
      setFilteredData(result.data);
      setTable(true);

      hideLoader();
    } catch (error) {
      console.error("Error fetching headmaster data:", error);
      hideLoader();
    }
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (data.length > 0) {
      const newfilteredData = data.filter(
        (row) =>
          `${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          // row.Std_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          // row.Batch_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.Course_Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.Father_Mobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.Mother_Mobile?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(newfilteredData);
    }
  }, [searchTerm]);

  useEffect(() => {
    const PhotoCellRenderer = (props) => {
      const { Photo } = props.data; // Destructuring value from cellRendererParams
      return (
        <div className="avatar-group avatar-group-dense">
          <div
            className="avatar avatar-m  rounded-circle"
            style={{ height: "2rem", width: "2rem", marginTop: "5px" }}
          >
            <img
              className="rounded-circle "
              src={`${apiUrl}/Documents/Student/Photos/${Photo}`}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = "https://masyseducare.com/img/userdefault.png"; // Specify the path to your default image
              }}
              alt="student"
            />
          </div>
        </div>
      );
    };

    const LinkCellRenderer = (props) => {
      const { ID, Name } = props.data; // Destructuring value from cellRendererParams
      return <Link 
      onClick={(e) => {
        e.preventDefault();
        showModal(props.rowIndex);
      }}>{Name}</Link>;
    };

    // const options = (props) => {
    //   const { ID } = props.data; // Destructuring value from cellRendererParams
    //   return (
    //     <div className="dropdown font-sans-serif">
    //       <button
    //         style={{ padding: 0 }}
    //         className="btn btn-link"
    //         id="dropdownMenuLink"
    //         href="#"
    //         role="button"
    //         data-bs-toggle="dropdown"
    //         aria-haspopup="true"
    //         aria-expanded="false"
    //       >
    //         <i
    //           className="fas fa-ellipsis-v"
    //           style={{ fontSize: "14px", color: "gray" }}
    //         ></i>
    //       </button>
    //       <div
    //         className="dropdown-menu dropdown-menu-end py-0"
    //         aria-labelledby="dropdownMenuLink"
    //       >
    //         <Link
    //           onClick={(e) => {
    //             e.preventDefault();
    //             showModal(props.rowIndex);
    //           }}
    //           className="dropdown-item cursor-pointer"
    //           style={{ height: "44px" }}
    //         >
    //           <i
    //             className="far fa-edit"
    //             style={{ color: "red", fontSize: "14px" }}
    //           ></i>
    //           <span> Inactive</span>
    //         </Link>

    //         <Link
    //           onClick={() => {
    //             deleteUser(ID);
    //           }}
    //           className="dropdown-item cursor-pointer"
    //           style={{ height: "44px" }}
    //         >
    //           <i
    //             className="fas fa-trash"
    //             style={{ color: "red", fontSize: "14px" }}
    //           ></i>
    //           <span> Delete</span>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // };
    const newAgcolumns = [
      {
        field: "Photo",
        cellRenderer: PhotoCellRenderer,
        width: 100,
        maxWidth: 100,
      },
      { field: "Name", cellRenderer: LinkCellRenderer },
      { field: "Student Id" },
      { field: "CourseEnrolled" },
      { field: "Mobile" },
      // { headerName: "", field: "", cellRenderer: options, width: 50, maxWidth: 50, filter: false , sortable: false }
    ];
    setAgcolumns(newAgcolumns);
  }, []);
  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData.map((row) => ({
        Photo: `${row.Photo}`,
        Name: `${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`,
        ID: row.ID,
        "Student Id": `${row.Student_Id ? row.Student_Id : ""}/${row.UUID}`,
        CourseEnrolled: row.Course_Name,
        Mobile: `F-${row.Father_Mobile}/M-${
          row.Mother_Mobile ? row.Mother_Mobile : ""
        }`,
      }));

      setAgrows(newAgrows);
    }
  }, [filteredData]);

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };
  async function handleDelete(Id) {
    showLoader();
    const obj = {
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      User_Name: userData.UserName,
    };

    try {
      await axios.delete(`${apiUrl}/deleteStudent/${Id}`, {
        data: obj,
      });
      Swal.fire("Deleted!", "Student record has been Deleted.", "success");
      fetchData();
    } catch (error) {
      console.error("Error while deleting student record:", error);
      Swal.fire("Error", "Failed to delete student record.", "error");
    } finally {
      hideLoader();
    }
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    fetchData();
    hideLoader();
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Student Details", 20, 10);

    const columns = ["Photo", "Name", "ID", "Course", "Mobile"];

    const rows = filteredData.map((row) => [
      row.Photo,
      `${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`,
      `${row.Student_Id||""}/${row.UUID}`,
      row.Course_Name,
      `F-${row.Father_Mobile}/M-${
        row.Mother_Mobile ? row.Mother_Mobile : "--"
      }`,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Student Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Biometric Id",
      "Student Id",
      "First Name",
      "Middle Name",
      "Last Name",
      "Branch",
      "Board",
      "Course",
      "Standard",
      "Batch",
      "Address",
      "Student Mobile",
      "Student Email",
      "DOB",
      "Gender",
      "Relegion",
      "Blood Group",
      "Caste",
      "School College",
      "Father Name",
      "Father Mobile",
      "Father Email",
      "Father Office Address",
      "Mother Name",
      "Mother Mobile",
      "Mother Email",
      "Mother Office Address",
      "Addmission Date",
    ];
    const rows = [columns];
    filteredData.forEach((row) => {
      rows.push([
        row.UUID,
        row.Student_Id||"",
        row.First_Name,
        row.Middle_Name||"",
        row.Last_Name,
        row.Head_Name,
        row.Board_Name,
        row.Course_Name,
        row.Std_Name,
        row.Batch_Name,
        row.Address,
        row.Mobile_For_SMS,
        row.Email,
        row.DOB ? dayjs(row.DOB).format("DD/MM/YYYY") : row.DOB,
        row.Gender,
        row.Relegion,
        row.Blood_Group,
        row.Caste,
        row.School_College,
        row.Father_Name,
        row.Father_Mobile,
        row.Parent_Email,
        row.Father_Off_Addr,
        row.Mother_Name,
        row.Mother_Mobile,
        row.Parent_Email2,
        row.Mother_Off_Address,
        dayjs(row.Date_Added).format("DD/MM/YYYY"),
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Student Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Student Details.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const toggle = () => {
    setDesign({
      ...design,
      filterToggle: design.filterToggle ? false : true,
    });
    setFilterData({
      ...filterData,
      filterToggle: design.filterToggle ? false : true,
    });
  };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-1">
              <div className="avatar avatar-xl me-3">
                <img
                  className="rounded-circle"
                  src={`${apiUrl}/Documents/Student/Photos/${row.Photo}`}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src =
                      "https://masyseducare.com/img/userdefault.png"; // Specify the path to your default image
                  }}
                  alt="student"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1 mt-2">
                <h5 className="text-black">
                  <Link
                    className="d-block"
                    onClick={(e) => {
                      e.preventDefault();
                      showModal(index);
                    }}
                  >{`${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`}</Link>
                </h5>
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Student Id :{" "}
                  <span className="ms-1 text-1100">{`${row.Student_Id||""}/${row.UUID}`}</span>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="me-3 flex-1">
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Course :{" "}
                  <span className="fw-semi-bold  ms-1"> {row.Course_Name}</span>
                </p>
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Standard :{" "}
                  <span className="fw-semi-bold  ms-1">{row.Std_Name}</span>
                </p>
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Batch :{" "}
                  <span className="fw-semi-bold  ms-1">{row.Batch_Name}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 fw-semi-bold text-truncate"
                  style={{ color: "#a09aa3" }}
                >
                  Mobile Number
                </p>
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Father :{" "}
                  <span className="fw-semi-bold  ms-1">
                    {row.Father_Mobile}
                  </span>
                </p>
                <p className="mb-0 fw-semi-bold listViwP text-truncate">
                  Mother :{" "}
                  <span className="fw-semi-bold  ms-1">
                    {row.Mother_Mobile ? row.Mother_Mobile : ""}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-1 d-none">
              <div className="font-sans-serif">
                <button
                  className="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-boundary="window"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-bs-reference="parent"
                >
                  <span className="fas fa-ellipsis-h fs--2 text-900"></span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-2"
                  aria-labelledby="documentations"
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      showModal(index);
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i
                      className="far fa-edit"
                      style={{ color: "red", fontSize: "14px" }}
                    ></i>
                    <span> Inactive</span>
                  </Link>

                  <Link
                    onClick={() => {
                      deleteUser(row.ID);
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i
                      className="fas fa-trash"
                      style={{ color: "red", fontSize: "14px" }}
                    ></i>
                    <span> Delete</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                  <div className="avatar-group avatar-group-dense">
                    <div
                      className="avatar avatar-m  rounded-circle"
                      style={{ height: "3rem", width: "3rem" }}
                    >
                      <img
                        className="rounded-circle "
                        src={`${apiUrl}/Documents/Student/Photos/${row.Photo}`}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src =
                            "https://masyseducare.com/img/userdefault.png"; // Specify the path to your default image
                        }}
                        alt="student"
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-between me-1"
                    style={{ flex: "auto", marginTop: "8px" }}
                  >
                    <div className="col-11 pe-1">
                      <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                        <Link
                          to={`/studentProfile/update/${row.ID}`}
                        >{`${row.First_Name} ${row.Middle_Name||""} ${row.Last_Name}`}</Link>
                      </h6>
                      <p
                        className="mb-0 profiledetail lh-1"
                        style={{ fontSize: "13px" }}
                      >
                        Student Id :{" "}
                        <span className="ms-1 text-1100">{`${row.Student_Id||""}/${row.UUID}`}</span>
                      </p>
                    </div>
                    <div className="end-0 col-1 p-0 d-none">
                      <button
                        className="btn btn-icon flex-shrink-0 mb-2"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fa-solid fa-ellipsis-vertical"></span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            showModal(index);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i
                            className="far fa-edit"
                            style={{ color: "red", fontSize: "14px" }}
                          ></i>
                          <span> Inactive</span>
                        </Link>

                        <Link
                          onClick={() => {
                            deleteUser(row.ID);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "red", fontSize: "14px" }}
                          ></i>
                          <span> Delete</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-success"><span className="badge-label">completed</span></span> */}
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail text-truncate lh-1">
                    Course :{" "}
                    <span className="fw-semi-bold ms-1">
                      {" "}
                      {row.Course_Name}
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2  ">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail lh-1">
                    Standard :{" "}
                    <span className="ms-1 text-1100">{row.Std_Name}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail lh-1">
                    Batch :{" "}
                    <span className="ms-1 text-1100">{row.Batch_Name}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between text-700 fw-semi-bold">
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "14px",
                      color: "#a09aa3",
                      lineHeight: "5px",
                    }}
                  >
                    {" "}
                    Mobile Number
                  </p>
                  {/* <p className="mb-2 text-1100">100%</p> */}
                </div>
                {/* <div className="progress bg-success-100">
                                                        <div className="progress-bar rounded bg-success" role="progressbar" aria-label="Success example" style={{ width: "100%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div> */}
                <div
                  className="align-items-center numberDiv mt-2"
                  style={{ display: "inline-block !important" }}
                >
                  <p className="mb-0 profiledetail">
                    Father :
                    <span className="fw-semi-bold text-600 ms-1">
                      {row.Father_Mobile}
                    </span>
                  </p>
                </div>
                <br />
                <div
                  className="align-items-center numberDiv"
                  style={{ display: "inline-block !important" }}
                >
                  <p className="mb-0 profiledetail">
                    Mother :{" "}
                    <span className="fw-semi-bold text-600 ms-1">
                      {row.Mother_Mobile ? row.Mother_Mobile : ""}
                    </span>
                  </p>
                </div>
                {/* <div className="hover-actions end-0 me-4" style={{ bottom: "20px" }}>
                                <button className="btn btn-primary btn-icon flex-shrink-0" data-bs-toggle="modal" data-bs-target="#projectsCardViewModal">
                                    <span className="fa-solid fa-chevron-right"></span>
                                </button>
                            </div> */}
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="content">
      <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
        <div className="container-fluid">
          {/* Sidebar */}
          <div id="sidebar-wrapper">
            <div className="row g-4" style={{ marginLeft: "10px" }}>
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-2"
                  data-component-card="data-component-card"
                >
                  <div className="row justify-content-center d-flex p-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Branch">
                            Branch
                          </label>
                          <HeadMasterDropDown
                            label="Branch"
                            name="Branch"
                            id="Branch"
                            onChange={(e) => setBranch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Course">
                            Course :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="Course"
                            disabled={userData?.Head_Id === ""}
                            onChange={(e) => setCourse(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {CourseOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Standard">
                            Standard :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="Standard"
                            disabled={userData?.Head_Id === ""}
                            value={Std_Id}
                            onChange={(e) => setStdId(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {StdOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            Batch :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="example-select"
                            value={Batch_ID}
                            disabled={Std_Id === ""}
                            onChange={(e) => setBatchID(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {BatchOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Academic_Year">
                            Academic_Year :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="Academic_Year"
                            onChange={(e) => setAcademicYear(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {yearOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="School_College"
                          >
                            School/College :
                          </label>
                          <select
                            className="form-select form-select-sm"
                            id="School_College"
                            onChange={(e) => setSchoolCollege(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {SchoolCollegeOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center p-2">
                    <div className="col-md-12 mb-2 text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-center"
                        onClick={handleDateRangeFilter}
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /#sidebar-wrapper */}
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4
                className="col-md-6 mb-0"
                data-anchor="data-anchor"
                id="basic-form"
              >
                 Student Details
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  href="#basic-form"
                  style={{ paddingLeft: "0.375em" }}
                ></a>
              </h4>
              <div className="col-md-12 text-center mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  style={{
                    float: "left",
                    display: "inline-block",
                    width: "200px",
                  }}
                ></input>
                <Link className="d-none" to={"/addStudent"}>
                  <button
                    className="btn btn-primary btn-sm text-center"
                    style={{ float: "right" }}
                  >
                    Add
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn bg-200 filterButton collapsed mr-2"
                  data-toggle="collapse"
                  onClick={toggle}
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  style={{ float: "right", marginRight: "10px" }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className="form-control dropdown-toggle viewButton  mb-2"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "auto",
                    padding: "4px 10px",
                  }}
                >
                  <>
                    {design.dataView === "Table View" && (
                      <TableChartOutlinedIcon />
                    )}
                  </>
                  <>
                    {design.dataView === "List View" && (
                      <FormatListBulletedIcon />
                    )}
                  </>
                  <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={(e) => {
                      setDesign({
                        ...design,
                        dataView: "Table View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Table View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <TableChartOutlinedIcon />
                    <span> Table View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "List View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "List View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <FormatListBulletedIcon />
                    <span> List View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "Tile View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Tile View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <GridViewIcon />
                    <span> Tile View</span>
                  </Link>
                </div>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to PDF</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadPDF()}
                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                  >
                    <i
                      className="fas fa-file-pdf"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to Excel</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadExcel()}
                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row">
              {design.dataView === "Table View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  {Agrows.length > 0 && (
                    <AgGridTable row={Agrows} column={Agcolumns} />
                  )}
                </div>
              )}
              {design.dataView === "Tile View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">{displayData}</div>
                </div>
              )}
              {design.dataView === "List View" && (
                <div className="col-12 col-xl-12 order-1 order-xl-0">
                  <div className="row">
                    <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                      <div className="card h-100 hover-actions-trigger">
                        <div
                          className="card-body"
                          style={{ paddingTop: "5px" }}
                        >
                          {displayData}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(design.dataView === "List View" ||
                design.dataView === "Tile View") && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageCount={pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  breakAriaLabels={{
                    forward: "Jump forward",
                    backward: "Jump backward",
                  }}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                  previousLinkClassName={"btn btn-outline-primary me-2"}
                  nextLinkClassName={"btn btn-outline-primary ms-2"}
                  activeLinkClassName={"customclass ms-2 me-2"}
                  pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                />
              )}
            </div>
          </div>
        </div>
        {isModal && (
          <AdmissionCancelModal
            open={isModal}
            onClose={closed}
            studentData={data[indexValue]}
          />
        )}
        {loader}
      </div>
    </div>
  );
};

export default ViewActiveStudent;
