import React, { useEffect, useState } from "react";

import useFullPageLoader from "../../../Components/useFullPageLoader";

import HeadMasterDropDown from "../../DropDowns/HeadMasterDropDown";

import "jspdf-autotable";
import Swal from "sweetalert2";
import { useUser } from "../../../Components/UserContext";
import { apiUrl } from "../../../Components/apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const StudentRemarks = () => {
const navigate =useNavigate()
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [formData, setFormData] = useState({
    Std_Id: "",
    Std_Name: "",
    Batch_ID: "",
    Batch_Name: "",
    IsWhtasapp: false,
  });
  const [facultyStudent, setFacultyStudent] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState({});


  useEffect(() => {
      fetchData();
  }, [userData, formData.Std_Id]);

  const fetchData = async () => {
    try {
      showLoader();

      if (userData?.Org_Id && userData?.Head_Id) {
        const std = await axios.get(`${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData.Head_Id}`);
        const getStd = std.data.map((category) => ({ label: category.Std_Name, value: category.Std_Id }));
        setStdOptions(getStd);
      }

      if (userData?.Org_Id && formData.Std_Id) {
        const batch = await axios.get(`${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${formData.Std_Id}`);
        const getBatch = batch.data.map((category) => ({ label: category.Batch_Name, value: category.Batch_Id }));
        setBatchOptions(getBatch);
      }

      if (userData?.Org_Id && userData?.Head_Id && formData?.Std_Id && formData?.Batch_ID) {
        const res = await axios.get(`${apiUrl}/getStudentsToSendRemarks?Org_Id=${userData.Org_Id}&Std_Id=${formData.Std_Id}&Head_Id=${userData.Head_Id}&Batch_ID=${formData.Batch_ID}`);
        const students = res.data.map(student => ({
          ...student,
          Status: "",
          Remarks: "",
          Mobile_For_SMS: student.Mobile,
          Father_Mobile: student.Father_Mobile,
          Mother_Mobile: student.Mother_Mobile
        }));
        setFacultyStudent(students);
      }

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const handleCheckAll = (e) => {
    const newCheckedStatus = {};
    const newFacultyStudent = facultyStudent.map((student) => {
      newCheckedStatus[student.ID] = e.target.checked;
      return { ...student, Status: e.target.checked ? "Present" : "Absent" };
    });
    setCheckedStatus(newCheckedStatus);
    setFacultyStudent(newFacultyStudent);
    setCheckAll(e.target.checked);
  };

  const handleCheckboxChange = (e, index) => {
    const newFacultyStudent = [...facultyStudent];
    newFacultyStudent[index].Status = e.target.checked ? "Present" : "Absent";
    setFacultyStudent(newFacultyStudent);
    setCheckedStatus({
      ...checkedStatus,
      [newFacultyStudent[index].ID]: e.target.checked,
    });
    setCheckAll(false);
  };

  const handleRemarksChange = (e, index) => {
    const newFacultyStudent = [...facultyStudent];
    newFacultyStudent[index].Remarks = e.target.value;
    setFacultyStudent(newFacultyStudent);
  };


  const handleFilter = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleSendRemarks = async(event) => {
    event.preventDefault();

    const updateBill = {
        IsWhtasapp: formData.IsWhtasapp,
        Std_Id: formData.Std_Id,
        Std_Name: formData.Std_Name,
        Batch_Id: formData.Batch_ID,
        Batch_Name: formData.Batch_Name,
        students: facultyStudent,
        Head_Id: userData?.Head_Id,
        Head_Name: userData?.Head_Name,
        User_Id: userData.UserId,
        Org_Id: userData.Org_Id,
        Org_Name: userData.Org_Name,
        User_Role: userData.Role,
        LoginUsername: userData.Username,
        Display_Name: userData.UserName,
    };

    // Validation check
    let hasErrors = false;
    facultyStudent.forEach((student, index) => {
        if (checkedStatus[student.ID] && !student.Remarks) {
            Swal.fire("Error", `Remarks are required for student ${student.Name}`, "error");
            hasErrors = true;
        }
    });

    if (hasErrors) {
        return;
    }

    showLoader();
  await  axios
        .post(`${apiUrl}/sendStudentRemarks`, updateBill)
        .then((res) => {
            Swal.fire("Success!", "Student Remarks Sent Successfully!", "success").then((result) => {
                if (result.isConfirmed) {
                  setFormData({
                    Std_Id: "",
                    Std_Name: "",
                    Batch_ID: "",
                    Batch_Name: "",
                    IsWhtasapp: false
                  });
                  fetchData();
                  setFacultyStudent([]);
                  window.location.reload();
                }
            });
            hideLoader();
        })
        .catch((err) => {
            console.error(err);
            Swal.fire("Server Timeout", "Server is Busy! Please try again later.", "error");
            hideLoader();
        });
};


  return (
    <div className="content">
      <div id="wrapper" className={`toggled`}>
        <div className="container-fluid">

          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                <div className="row">
                  <div className="col-md-6">Student Remarks</div>
                </div>
              </h4>

              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-4"
                  data-component-card="data-component-card"
                >
                  <div className="card-body p-0">
                    <div className="p-4 code-to-copy">
                      <form>
                        <div className="row">
                          <div className="mb-3 col-md-4">
                            <label className="form-label" htmlFor="Branch ">
                              BRANCH
                            </label>
                            <HeadMasterDropDown
                              label="Branch"
                              name="Branch"
                              id="Branch"
                            />
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="Standard"
                                >
                                  Standard :
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  id="Standard"
                                  disabled={userData?.Head_Id === ""}
                                  value={formData?.Std_Id}
                                  onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    const selectedOption = e.target[selectedIndex];

                                    setFormData({
                                      ...formData,
                                      Std_Id: selectedOption.value,
                                      Std_Name: selectedOption.label,

                                      Batch_ID: "",
                                      Batch_Name: "",

                                    })
                                  }}
                                >
                                  <option
                                    selected
                                    value=""
                                    label="All"
                                  ></option>
                                  {StdOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="Std_Name"
                                >
                                  Batch :
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  id="example-select"

                                  disabled={formData?.Std_Id === ""}
                                  value={formData?.Batch_ID}
                                  onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    const selectedOption = e.target[selectedIndex];
                                    setFormData({
                                      ...formData,
                                      Batch_ID: selectedOption.value,
                                      Batch_Name: selectedOption.value,

                                    })
                                  }}
                                >
                                  <option
                                    selected
                                    value=""
                                    label="All"
                                  ></option>
                                  {BatchOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              onClick={handleFilter}
                            >
                              Show Students
                            </button>
                          </div>
                        </div>
                        <div className="row g-4">
                          <div className="col-12 col-xl-12 order-1 order-xl-0">
                            <div
                              className=""
                              data-component-card="data-component-card"
                            >
                              <div className="card-body p-0">
                                <div className="p-4 code-to-copy">
                                  <div className="row mt-2">
                                    <div className="col-12">
                                      <div className=" table-responsive">
                                        <table
                                          id="dataTableHover"
                                          className="table align-items-center table-flush table-hover paidInterest"
                                          style={{
                                            border: "1px solid #e3e6f0",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th className="text-center">
                                                Sr No
                                              </th>
                                              <th className="text-center">
                                                Student Id
                                              </th>
                                              <th className="text-center">
                                                Name
                                              </th>
                                              <th className="text-center">
                                                <input
                                                  type="checkbox"
                                                  checked={checkAll}
                                                  onChange={handleCheckAll}
                                                />
                                                <br />
                                                Send
                                              </th>
                                              <th className="text-center">
                                                Remarks
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {facultyStudent?.length > 0 ? (
                                              facultyStudent?.map(
                                                (item, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td className="text-center">
                                                        {index + 1}
                                                      </td>
                                                      <td className="text-center">
                                                        {item.Student_Id}
                                                      </td>
                                                      <td className="text-center">
                                                        {item.Name}
                                                      </td>
                                                      <td className="text-center">
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            checkedStatus[
                                                            item.ID
                                                            ]
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxChange(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="text-center p-2">
                                                        <input
                                                          type="text"
                                                          placeholder="Remarks"
                                                          className="form-control form-control-sm"
                                                          value={
                                                            item.Remarks || ""
                                                          }
                                                          onChange={(e) => handleRemarksChange(e, index)}

                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                <td
                                                  className="text-center text-danger"
                                                  colSpan={5}
                                                >
                                                  No Data Found !!
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={"mb-3 col-md-4"}>
                                    <div class="form-check">
                                      <input
                                        className="form-check-input"
                                        id="flexCheckDefault2"
                                        type="checkbox"
                                        checked={formData?.IsWhtasapp}
                                        onChange={(e) =>
                                          setFormData({
                                            ...formData,
                                            IsWhtasapp: e.target.checked,
                                          })
                                        }
                                      />
                                      <label className="form-check-label" htmlFor="flexCheckDefault2">
                                        <h6>Send Whatsapp Message</h6>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-12 text-center mt-3">
                                    <button
                                      onClick={handleSendRemarks}
                                      className="btn btn-sm btn-primary"
                                    >
                                      Send Remarks
                                    </button>
                                  </div>
                                </div>
                                {loader}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader}
      </div>
    </div>
  );
};

export default StudentRemarks;
