import React, { useEffect, useState } from "react";
import $ from "jquery";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import { formatInputDate } from "../../Components/dateFormate";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";
import SubjectDropdown from "../DropDowns/SubjectDropdown";
import { Category, Subject } from "@mui/icons-material";

const ReportCard = () => {
  const [table, setTable] = useState(false);
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setBillData] = useState([]);
  const [Chapterdata, setChapterdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [StdOptions, setStdOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [FacultyOptions, setFacultyOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  const [Sub_Id, setSubId] = useState("");
  const [Status, setStatus] = useState("");
  //   const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  //   const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [showStandardValidation, setShowStandardValidation] = useState(false);
  const [showBatchValidation, setShowBatchValidation] = useState(false);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Std_Id]);

  //console.log(design);

  const fetchData = async () => {
    try {
      debugger;
      showLoader();

      if (userData && userData.Org_Id && userData?.Head_Id) {
        showLoader();
        const std = await axios.get(
          `${apiUrl}/getStdMaster?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}`
        );

        const getStd = std.data.map((category) => ({
          label: category.Std_Name,
          value: category.Std_Id,
        }));
        setStdOptions(getStd);
        //console.log(getStd);
      }

      if (userData && userData.Org_Id && Std_Id) {
        const batch = await axios.get(
          `${apiUrl}/getBatchMaster?OrgId=${userData.Org_Id}&Std_Id=${Std_Id}`
        );

        const getBatch = batch.data.map((category) => ({
          label: category.Batch_Name,
          value: category.Batch_Id,
        }));
        setBatchOptions(getBatch);
        // console.log(getBatch);
      }
      const Teacher = await axios.get(
        `${apiUrl}/binddropTeacher?OrgId=${userData.Org_Id}`
      );

      const getTeacher = Teacher.data.map((category) => ({
        label: category.Name,
        value: category.User_Id,
      }));
      setFacultyOptions(getTeacher);

      const getSubject = Subject.data.map((category) => ({
        label: category.Sub_Name,
        value: category.Sub_Id,
      }));
      setSubId(getSubject);

      // console.log(res.data);
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  const [showButton, setShowButton] = useState(false);
  const handleDateRangeFilter = async () => {
    showLoader();

    const res = await axios.get(
      `${apiUrl}/getTestForReport?Org_Id=${
        userData.Org_Id
      }&Std_Id=${Std_Id}&Batch_Id=${Batch_ID}&Head_Id=${
        userData?.Head_Id
      }&Subject_Id=${Sub_Id}&FromDate=${
        fromDate ? fromDate.format("YYYY-MM-DD") : ""
      }&ToDate=${toDate ? toDate.format("YYYY-MM-DD") : ""}`
    );

    setBillData(res.data);
    setFilteredData(res.data);
    console.log(res.data);
    setTable(true);

    // Check if res.data contains any data
    if (res.data.length > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
      Swal.fire({
        icon: "warning",
        title: "No Data Found",
        text: "",
      });
    }

    fetchData();
    hideLoader();
  };

  useEffect(() => {
    if (table) {
      const initializeDataTable = () => {
        var dTable = $("#data-table").DataTable({
          paginate: true,
          responsive: true,
          pageLength: 10,
          ordering: true,
          searching: true,
        });

        setDataTable(dTable);
      };
      if (!dataTable) {
        initializeDataTable();
      }

      return () => {
        if (dataTable) {
          dataTable.destroy(true);
        }
      };
    }
    setTable(false);
  }, [table]);

  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF();
    doc.text("Lecture Report Topic", 20, 10);

    const columns = ["Sr No", "Topic Name", "Lecture Count", "Total Hourse"];

    const rows = filteredData.map((row, index) => [
      index + 1,
      row.Topic_Name,
      row.LectureCount,
      row?.TotalDuration,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Lecture Report Topic.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sr No", "Topic Name", "Lecture Count", "Total Hourse"];

    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([
        index + 1,
        row.Topic_Name,
        row.LectureCount,
        row?.TotalDuration,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lecture Report Topic");

    XLSX.writeFile(workBook, "Lecture Report Topic.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const toggle = () => {
    setDesign({
      ...design,
      filterToggle: design.filterToggle ? false : true,
    });
    setFilterData({
      ...filterData,
      filterToggle: design.filterToggle ? false : true,
    });
  };

  const groupedData = data.reduce((acc, row) => {
    if (!acc[row.Subject_Name]) {
      acc[row.Subject_Name] = [];
    }
    acc[row.Subject_Name].push(row);
    return acc;
  }, {});

  const [selectedTest, setSelectedTest] = useState([]);

  const handleCheckboxChange = (ID) => {
    setSelectedTest((prevSelectedTest) => {
      const updatedSelectedTest = prevSelectedTest.includes(ID)
        ? prevSelectedTest.filter((id) => id !== ID)
        : [...prevSelectedTest, ID];

      console.log(updatedSelectedTest);

      return updatedSelectedTest;
    });
  };

  const handleViewReport = async () => {
    debugger;
    try {
      const updatedSelectedTest = selectedTest.join(",");
  
      const response = await axios.get(
        `${apiUrl}/getReportCardPdf?OrgId=${userData.Org_Id}&HeadId=${userData?.Head_Id}&StdId=${Std_Id}&BatchId=${Batch_ID}&TestIds=${updatedSelectedTest}`
      );
  
      // Handle the response from the endpoint
      console.log(response.data);
  
      // Get the reportCardNum value from the response
      const { reportCardNum } = response.data;
  
      // Use the useNavigate hook to navigate to the next page and pass the reportCardNum as part of the state
      
      navigate(`/ReportCardPdf/${reportCardNum}`, { state: { reportCardNum } });
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData.slice(
    pagesVisited,
    pagesVisited + recordsPerPage
  );
  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="content">
      <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
        <div className="container-fluid">
          {/* Sidebar */}
          <div id="sidebar-wrapper">
            <div className="row g-4 scrollable-container">
              <div className="col-12 col-xl-12 order-1 order-xl-0">
                <div
                  className="card shadow-none border border-300 my-2"
                  data-component-card="data-component-card"
                >
                  <div className="row justify-content-center d-flex p-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Branch">
                            Branch
                          </label>
                          <HeadMasterDropDown
                            label="Branch"
                            name="Branch"
                            id="Branch"
                            //onChange={(e) => setBranch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Standard">
                            Standard :
                          </label>
                          <select
                            className={`form-select form-select-sm ${
                              Std_Id === "" ? "is-invalid" : ""
                            }`}
                            id="Standard"
                            required
                            disabled={userData?.Head_Id === ""}
                            value={Std_Id}
                            onChange={(e) => {
                              setStdId(e.target.value);
                              setBatchID("");
                            }}
                          >
                            <option selected value="" label="All"></option>
                            {StdOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {Std_Id === "" && (
                            <div className="invalid-feedback">
                              Please select a standard.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            Batch :
                          </label>
                          <select
                            className={`form-select form-select-sm ${
                              Batch_ID === "" ? "is-invalid" : ""
                            }`}
                            id="example-select"
                            required
                            value={Batch_ID}
                            disabled={Std_Id === ""}
                            onChange={(e) => setBatchID(e.target.value)}
                          >
                            <option selected value="" label="All"></option>
                            {BatchOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {Batch_ID === "" && (
                            <div className="invalid-feedback">
                              Please select a batch.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Subject">
                            Subject
                          </label>
                          <SubjectDropdown
                            label="Subject"
                            name="Subject"
                            id="Subject"
                            Std_Id={Std_Id}
                            required={true}
                            isDisabled={Std_Id === ""}
                            onChange={(e) => {
                              setSubId(e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            From Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            value={formatInputDate(fromDate)}
                            onChange={(date) =>
                              setFromDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Std_Name">
                            To Date :
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            value={formatInputDate(toDate)}
                            onChange={(date) =>
                              setToDate(dayjs(date.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center p-2">
                    <div className="col-md-4 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-center"
                        onClick={() => {
                          if (Std_Id === "" || Batch_ID === "") {
                            // Display validation messages
                            setShowStandardValidation(true);
                            setShowBatchValidation(true);
                          } else {
                            handleDateRangeFilter();
                          }
                        }}
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="row justify-content-between">
              <h4 className="mb-0" data-anchor="data-anchor" id="basic-form">
                Lecture Report
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  href="#basic-form"
                  style={{ paddingLeft: "0.375em" }}
                ></a>
              </h4>
              <div className="col-md-12 text-center mt-3 ">
                <input
                  type="text"
                  className="form-control d-none"
                  //onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  style={{
                    float: "left",
                    display: "inline-block",
                    width: "200px",
                  }}
                ></input>
                <Link to={"/ViewPerformance"} style={{ float: "right" }}>
                  <button className="btn btn-primary btn-sm text-center">
                    View Result Summary
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn bg-200 filterButton collapsed mr-2 mb-2"
                  data-toggle="collapse"
                  onClick={toggle}
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  style={{ float: "right", marginRight: "10px" }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className="form-control dropdown-toggle viewButton  mb-2 d-none"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "auto",
                    padding: "4px 10px",
                  }}
                >
                  <>
                    {design.dataView === "Table View" && (
                      <TableChartOutlinedIcon />
                    )}
                  </>
                  <>
                    {design.dataView === "List View" && (
                      <FormatListBulletedIcon />
                    )}
                  </>
                  <>{design.dataView === "Tile View" && <GridViewIcon />}</>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={(e) => {
                      setDesign({
                        ...design,
                        dataView: "Table View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Table View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <TableChartOutlinedIcon />
                    <span> Table View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "List View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "List View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <FormatListBulletedIcon />
                    <span> List View</span>
                  </Link>
                  <Link
                    onClick={() => {
                      setDesign({
                        ...design,
                        dataView: "Tile View",
                      });
                      setFilterData({
                        ...filterData,
                        dataView: "Tile View",
                      });
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <GridViewIcon />
                    <span> Tile View</span>
                  </Link>
                </div>
                {/* <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to PDF</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadPDF()}
                    style={{ float: "right", padding: "5px 10px 5px 5px" }}
                  >
                    <i
                      className="fas fa-file-pdf"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Export to Excel</Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    variant=""
                    onClick={() => DownloadExcel()}
                    style={{ float: "right", padding: "5px 5px 5px 10px" }}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </Button>
                </OverlayTrigger> */}
              </div>
            </div>
            <div className="row">
              {Object.keys(groupedData).length > 0 ? (
                Object.keys(groupedData).map((subject, index) => (
                  <div key={index} className="col-12 col-xl-6 col-lg-6 mb-4">
                    <div className="card h-100 hover-actions-trigger">
                      <div className="card-body" style={{ padding: "15px" }}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="mb-2 line-clamp-1 lh-sm">{subject}</h6>
                        </div>
                        <div className="row">
                          {groupedData[subject].map((topic, topicIndex) => (
                            <div key={topicIndex} className="col-12 col-md-6">
                              <div className="card nested-card mt-2">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                  <div>
                                    <p className="mb-0 profiledetail lh-1">
                                      Topic: {topic.Topic_name}
                                    </p>
                                    <p className="mb-0 profiledetail lh-1">
                                      Date: {formatDate(topic.Test_Date)}
                                    </p>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={selectedTest.includes(topic.ID)}
                                      onChange={() =>
                                        handleCheckboxChange(topic.ID)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12 d-flex justify-content-center mb-4">
                  <div className="alert" role="alert">
                    Data Not Found...! Please select a Standard and Batch...!
                  </div>
                </div>
              )}

              {showButton && (
                <div className="col-12 d-flex justify-content-center mb-4">
                  <button
                    className="btn btn-primary"
                    onClick={handleViewReport}
                  >
                    View Report
                  </button>
                </div>
              )}

              {(design.dataView === "List View" ||
                design.dataView === "Tile View") && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageCount={pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  breakAriaLabels={{
                    forward: "Jump forward",
                    backward: "Jump backward",
                  }}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                  previousLinkClassName={"btn btn-outline-primary me-2"}
                  nextLinkClassName={"btn btn-outline-primary ms-2"}
                  activeLinkClassName={"customclass ms-2 me-2"}
                  pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
                />
              )}
            </div>
          </div>
        </div>
        {/* {isModal && (
                    <UpdateStatus
                        open={isModal}
                        onClose={closed}
                        updateData={data[indexValue]}
                    />
                )} */}
        {loader}
      </div>
    </div>
  );
};

export default ReportCard;
