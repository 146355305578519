import React, { useEffect, useState } from "react";
import ViewSalary from "./ViewSalary";
import useFullPageLoader from "../../Components/useFullPageLoader";
import "datatables.net-dt";
import "datatables.net-responsive-dt";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Dropdown from "../DropDowns/DropDown";
import "jspdf-autotable";
import { formatDate } from "../../Components/dateFormate";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useUser } from "../../Components/UserContext";
import { apiUrl } from "../../Components/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import "../Students/toggle.css";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactPaginate from "react-paginate";
import AgGridTable from "../Students/AgGridTable";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from '@mui/icons-material/TableView';

const GenrateSalary = ({ StaffName }) => {
  const [table, setTable] = useState(false);
  const { opration, Sr_No } = useParams();
  const [dataTable, setDataTable] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData, setData } = useUser();
  const { filterData, setFilterData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [data, setBillData] = useState([]);
  const [fromDate, setFromDate] = useState(null); // Initialize as null
  const [toDate, setToDate] = useState(null); // Initialize as null
  const [filteredData, setFilteredData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateDataId, setId] = useState("");
  const [StdOptions, setStdOptions] = useState([]);
  const [SubOptions, setSubOptions] = useState([]);
  const [CategoryOptions, setCategoryOptions] = useState([]);
  const [selectedstatus, setSelectedstatus] = useState("Uncleared");
  const [Salary, setSalary] = useState("");
  const [prodOptions, setprodOptions] = useState([]);
  const [BatchOptions, setBatchOptions] = useState([]);
  const [Std_Id, setStdId] = useState("");
  const [Batch_ID, setBatchID] = useState("");
  const [Sub_Id, setSubId] = useState("");
  const [Agrows, setAgrows] = useState([]);
  const [Agcolumns, setAgcolumns] = useState([]);
  const [design, setDesign] = useState({
    filterToggle: "",
    dataView: "",
  });

  const [Bill, setBill] = useState({
    StaffName: "",
    Head_Id: "",
    Sub_Id: "",
    Sub_Name: "",
    Org_Id: "",
    Std_Id: "",
    Std_Name: "",

  });
  useEffect(() => {
    if (userData && userData.Org_Id) {
      fetchData();
      setDesign({
        ...design,
        filterToggle: filterData.filterToggle,
        dataView: filterData.dataView,
      });
    }
  }, [userData, Bill.Std_Id, Sr_No]);

  const fetchData = async () => {
    debugger;
    try {
      showLoader();

      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
  };

  //Search Box
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (data.length > 0) {
      const newFilteredData = data.filter(
        (row) =>
          row?.Std_Name.toLowerCase().includes(searchTerm) ||
          row?.Sub_Name.toLowerCase().includes(searchTerm) ||
          row?.Salary.toString().toLowerCase().includes(searchTerm) ||
          row?.LecCount.toLowerCase().includes(searchTerm) ||
          row?.DurationHr.toLowerCase().includes(searchTerm) ||
          row?.DurationMn.toLowerCase().includes(searchTerm) ||
          row?.CalSalary.toString().toLowerCase().includes(searchTerm)
      );
      setFilteredData(newFilteredData);
    }
  }, [searchTerm, data]);

  async function editBills(data) {
    showLoader();

    console.log(data);
    setBill({
      newHead_Name: data.Head_Name,
      Head_GST_No: data.Head_GST_No,
      Head_Address: data.Head_Address,
    });
    setEditingItem(data);
    hideLoader();
  }

  useEffect(() => {
    const badgeCellRenderer = (props) => {
      return (
        <span
          className={`badge badge-phoenix ${props.Status === "Unpaid"
              ? "badge-phoenix-warning"
              : props.Status === "Paid"
                ? "badge-phoenix-success"
                : "badge-phoenix-primary"
            }`}
        >
          {props.Status}
        </span>
      );
    };

    const newAgcolumns = [
      { field: "SrNo" },
      { field: "Standard" },
      { field: "Subject" },
      { field: "TotalLectures" },
      { field: "Duration" },
      { field: "Amount per Hr" }, // Ensure this matches the field name
      { field: "TotalAmount" },
    ];
    setAgcolumns(newAgcolumns);
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newAgrows = filteredData.map((row, index) => ({
        Row_Id: row.Row_Id,
        SrNo: index + 1,
        Standard: row.Std_Name,
        Subject: row.Sub_Name,
        TotalLectures: row.LecCount,
        Duration: `${row.DurationHr ? `${row.DurationHr} Hrs` : ''} ${row.DurationMn ? `${row.DurationMn} Mins` : ''}`.trim(),
        "Amount per Hr": parseFloat(row.Salary).toFixed(2),
        TotalAmount: parseFloat(row.CalSalary).toFixed(2),
      }));

      //console.log("Rows Data:", newAgrows);
      setAgrows(newAgrows);
    }
  }, [filteredData]);


  const DownloadPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF();
    doc.text("Branches", 20, 10);

    const columns = ["Sr No", "Name", "GST No", "Address"];

    const rows = filteredData.map((row, index) => [
      index + 1,
      row.Head_Name,
      row.Head_GST_No,
      row.Head_Address,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Branches.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sr No", "Name", "GST No", "Address"];

    const rows = [columns];
    filteredData.forEach((row, index) => {
      rows.push([index + 1, row.Head_Name, row.Head_GST_No, row.Head_Address]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Branches");

    XLSX.writeFile(workBook, "Branches.xlsx");
  };

  const showModal = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    fetchData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  const GetData = async () => {
    showLoader();
if(userData && Sr_No && fromDate &&  toDate){
      const url = `${apiUrl}/getGenrateSalary?Org_Id=${userData?.Org_Id}&Teacher_Id=${Sr_No}&Head_Id=${userData?.Head_Id}
      &FromDate=${fromDate ? fromDate : ""}&ToDate=${toDate ? toDate : ""}`;
      const res = await axios.get(url);
      
      if (res.data.length > 0) {
        setBillData(res.data);
        setFilteredData(res.data);
        console.log(res.data);
      } else {
        setBillData([]);
        setFilteredData([]);
        Swal.fire("No Data Found", "No data available for the specified date range.", "info");
      }}
      hideLoader();
  };
  

  
  const handleSubmit = async(event) => {
    event.preventDefault();

    const updateBill = {
      PlannerItems:filteredData,
      From_Date:fromDate,
      To_Date:toDate,
      Staff_Id:Sr_No,
      Staff_Name: StaffName,
      Head_Id: userData?.Head_Id,
      Head_Name: userData?.Head_Name,
      User_Id: userData.UserId,
      Org_Id: userData.Org_Id,
      Org_Name: userData.Org_Name,
      User_Role: userData.Role,
      LoginUsername: userData.Username,
      Display_Name: userData.UserName,
    };
    console.log(updateBill);
    const errors = {};

    if (Object.keys(errors).length === 0) {
      showLoader();
    await  axios
        .post(`${apiUrl}/setGenrateSalary`, updateBill)
        .then((res) => {
          Swal.fire("Success!", "Genrate Salary Successfully!!", "success");
          //<ViewSalary StaffName={StaffName} />
          hideLoader();
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            Swal.fire({
              title: "Warning",
              text: err.response.data.message,
              icon: "warning",
              confirmButtonText: "OK",
          });          
        } else {
            console.error(err);
            Swal.fire(
                "Server Timeout",
                "Server is Busy!!!, Please try again later.",
                "error"
            );
        }
        hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = design.dataView === "List View" ? 10 : 12;
  const pagesVisited = pageNumber * recordsPerPage;
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + recordsPerPage)
    .map((row, index) => (
      <>
        {design.dataView === "List View" && (
          <div className="row justify-content-between py-2 border-300 px-lg-2 px-2 notification-card read border-bottom border-300">
            <div className="col-md-3">
              <div className="me-3 flex-1 mt-2">
                <h5 className="text-black">{row.Std_Name}</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="me-3 flex-1">
                <p
                  className="profiledetail lh-1 mb-1"
                  style={{ fontSize: "13px" }}
                >
                  Subject :{" "}
                  <span className="ms-1 text-1100">{row.Sub_Name}</span>
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="me-3 flex-1">
                <p
                  className="mb-0 profiledetail lh-1"
                  style={{ fontSize: "13px" }}
                >
                  Amount/Hour :{" "}
                  <span className="ms-1 text-1100">{row.Salary}</span>
                </p>
              </div>
            </div>

            <div className="col-md-1">
              <div className="font-sans-serif">
                <button
                  style={{ padding: 0 }}
                  className="btn btn-link"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={{ fontSize: "14px", color: "gray" }}
                  ></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    onClick={() => {
                      // deleteUser(row.Row_Id);
                    }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i
                      className="fas fa-trash"
                      style={{ color: "red", fontSize: "14px" }}
                    ></i>
                    <span> Delete</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {design.dataView === "Tile View" && (
          <div
            key={index}
            className="col-12 col-xl-3 col-lg-3 order-1 order-xl-0 mb-4"
          >
            <div className="card h-100 hover-actions-trigger">
              <div className="card-body" style={{ padding: "15px" }}>
                <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mb-2">
                  <div
                    className="row justify-content-between me-1"
                    style={{ flex: "auto", marginTop: "8px" }}
                  >
                    <div className="col-11 pe-1">
                      <h6 className="mb-2 line-clamp-1 lh-sm  me-1">
                        {row.Std_Name}
                      </h6>
                    </div>
                    <div className="end-0 col-1 p-0 ">
                      <button
                        style={{ padding: 0 }}
                        className="btn btn-link"
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-ellipsis-v"
                          style={{ fontSize: "14px", color: "gray" }}
                        ></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          onClick={() => {
                            //deleteUser(row.Row_Id);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "red", fontSize: "14px" }}
                          ></i>
                          <span> Delete</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  {/* <span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail text-truncate lh-1">
                    Subject :{" "}
                    <span className="fw-semi-bold ms-1"> {row.Sub_Name}</span>
                  </p>
                </div>

                <div className="d-flex align-items-center mb-3">
                  {/* <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold"></span> */}
                  <p className="mb-0 profiledetail lh-1">
                    Amount/Hour :{" "}
                    <span className="ms-1 text-1100">{row.Salary}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ));

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div id="wrapper" className={`${design.filterToggle ? "" : "toggled"}`}>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-12 col-xl-12 order-1 order-xl-0">
            <div
              className="card shadow-none border border-300 my-4"
              data-component-card="data-component-card"
            >
              <div className="card-body p-0">
                <div className="p-4 code-to-copy">
                
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="fromDate">
                              From Date:
                            </label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              onChange={(e) =>
                                setFromDate(e.target.value)
                              } // Set fromDate
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="toDate">
                              To Date:
                            </label>
                            <input
                              type="date"
                              className="form-control form-control-sm"
                              onChange={(e) => setToDate(e.target.value)} // Set toDate
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-3">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={GetData}
                        >
                          View
                        </button>
                      </div>
                    </div>
             
                </div>
              </div>
            </div>
          </div>



          <div className="col-md-12 text-center mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              style={{
                float: "left",
                display: "inline-block",
                width: "200px",
              }}
            ></input>
            <Link
              to={"/addBranches"}
              style={{ float: "right", display: "none" }}
            >
              <button className="btn btn-primary btn-sm text-center">
                Add
              </button>
            </Link>

            <button
              className="form-control dropdown-toggle viewButton  mb-2 d-none"
              id="dropdownMenuLink"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                float: "right",
                marginRight: "10px",
                width: "auto",
                padding: "4px 10px",
              }}
            >
              <>
                {design.dataView === "Table View" && <TableChartOutlinedIcon />}
              </>
              <>
                {design.dataView === "List View" && <FormatListBulletedIcon />}
              </>
              <>{design.dataView === "Tile View" && <GridViewIcon />}</>
            </button>
            <div
              className="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuLink"
            >
              <Link
                onClick={(e) => {
                  setDesign({
                    ...design,
                    dataView: "Table View",
                  });
                  setFilterData({
                    ...filterData,
                    dataView: "Table View",
                  });
                }}
                className="dropdown-item cursor-pointer"
              >
                <TableChartOutlinedIcon />
                <span> Table View</span>
              </Link>
              <Link
                onClick={() => {
                  setDesign({
                    ...design,
                    dataView: "List View",
                  });
                  setFilterData({
                    ...filterData,
                    dataView: "List View",
                  });
                }}
                className="dropdown-item cursor-pointer"
              >
                <FormatListBulletedIcon />
                <span> List View</span>
              </Link>
              <Link
                onClick={() => {
                  setDesign({
                    ...design,
                    dataView: "Tile View",
                  });
                  setFilterData({
                    ...filterData,
                    dataView: "Tile View",
                  });
                }}
                className="dropdown-item cursor-pointer"
              >
                <GridViewIcon />
                <span> Tile View</span>
              </Link>
            </div>
            {filteredData.length === 0 && (<h5 style={{ float: "center" }}>Data Not Found!!</h5>)}
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>Export to PDF</Tooltip>}
              placement="bottom"
            >
              <Button
                variant=""
                onClick={() => DownloadPDF()}
                style={{ float: "right", padding: "5px 10px 5px 5px" }}
              >
                <i style={{ fontSize: "25px" }}><PictureAsPdfIcon /></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>Export to Excel</Tooltip>}
              placement="bottom"
            >
              <Button
                variant=""
                onClick={() => DownloadExcel()}
                style={{ float: "right", padding: "5px 5px 5px 10px" }}
              >
                <i

                  style={{ fontSize: "25px" }}
                ><TableViewIcon /></i>
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="row mb-4">
          {design.dataView === "Table View" && (
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              {Agrows.length > 0 && (
                <AgGridTable row={Agrows} column={Agcolumns} />
              )}
            </div>
          )}
          {design.dataView === "Tile View" && (
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              <div className="row">{displayData}</div>
            </div>
          )}
          {design.dataView === "List View" && (
            <div className="col-12 col-xl-12 order-1 order-xl-0">
              <div className="row">
                <div className="col-12 col-xl-12 col-lg-12 order-1 order-xl-0 mb-4">
                  <div className="card h-100 hover-actions-trigger">
                    <div className="card-body" style={{ paddingTop: "5px" }}>
                      {displayData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(design.dataView === "List View" ||
            design.dataView === "Tile View") && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageCount={pageCount}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                breakAriaLabels={{
                  forward: "Jump forward",
                  backward: "Jump backward",
                }}
                onPageChange={changePage}
                containerClassName={"pagination"}
                disabledLinkClassName={"btn btn-phoenix-secondary text-black"}
                previousLinkClassName={"btn btn-outline-primary me-2"}
                nextLinkClassName={"btn btn-outline-primary ms-2"}
                activeLinkClassName={"customclass ms-2 me-2"}
                pageLinkClassName={"btn btn-outline-primary ms-2 me-2"}
              />
            )}
        </div>
        <div className={filteredData.length !== 0 ? "col-md-12 text-center mb-2" : "d-none"}>
          <button type="button" className="btn btn-sm btn-primary" onClick={handleSubmit}>
            Generate Salary
          </button>
        </div>
      </div>

      {loader}
    </div>
  );
};

export default GenrateSalary;
