import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import useFullPageLoader from "../../../../Components/useFullPageLoader";
import { apiUrl } from "../../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../../Components/UserContext";
import dayjs from "dayjs";
import { formatInputDate } from '../../../../Components/dateFormate';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { First } from "react-bootstrap/esm/PageItem";

const AddMenuGroup = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isValidMobile, setIsValidMobile] = useState({});
    const [isValidMobile1, setIsValidMobile1] = useState({});
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [file, setFile] = useState([]);
    const [groupOption, setGroupOption] = useState([]);

    useEffect(() => {
        setShow(open);
    }, [open]);


    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            showLoader();
            const res = await axios.get(`${apiUrl}/getDropdownGroups?Org_Id=${userData?.Org_Id}`);
            const getDrop = res.data.map((category) => ({
                label: category.Group_Name,
                value: category.Group_Id,
              }));
            setGroupOption(getDrop);
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };


    const handleClose = () => {
        setShow(false);
        onClose();
    };


    const [Bill, setBill] = useState({
        Group_Id: "",
        Group_Name: "",
        Task_Name: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addDropdownMenu`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Menu Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                Group_Id: "",
                                Group_Name: "",
                                Task_Name: ""
                            });
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(
                        "Server Timeout",
                        "Server is Busy!!! Please try again later.",
                        "error"
                    );
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };



    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Menu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">

                            <div className="col-md-6 mb-3">
                                <label className="form-label" htmlFor="Group_Name ">Menu Group
                                <span style={{ color: "Red" }}>*</span></label>
                                <Select
                                    id="Group_Name"
                                    name="Group_Name"
                                    required
                                    options={groupOption}
                                    value={Bill.Group_Id?{value:Bill.Group_Id,label:Bill.Group_Name}:null}
                                    onChange={(selectedOption) => {
                                        setBill({
                                            ...Bill,
                                            Group_Id: selectedOption.value,
                                            Group_Name: selectedOption.label
                                        });
                                    }}
                                />
                            </div>

                            <div className={"mb-3 col-md-6"}>
                                <label className="form-label" htmlFor="Task_Name">
                                Task Name<span style={{ color: "Red" }}>*</span>
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Task_Name"
                                    id="Task_Name"
                                    required
                                    value={Bill.Task_Name}
                                    placeholder="Enter Task Name"
                                    onChange={handleChange}
                                />
                            </div>
                 
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddMenuGroup;