import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../../Components/useFullPageLoader";
import { apiUrl } from "../../../Components/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";



const AddProductCategory = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose();
    };


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const [Bill, setBill] = useState({
        Category_Name: "",
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBill((prevBill) => ({
            ...prevBill,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
 
        const updateBill = {
            ...Bill,
            Head_Id: userData?.Head_Id,
            Head_Name: userData?.Head_Name,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
        };

        const errors = {};


        if (Object.keys(errors).length === 0) {
            showLoader();
            axios
                .post(`${apiUrl}/addProductCategory`, updateBill)
                .then((res) => {
                    Swal.fire(
                        "Success!",
                        "Product Category Added Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            setBill({
                                Category_Name: ''
                            });
                            handleClose();
                        }
                    });
                    hideLoader();
                })
                .catch((err) => {
           
                        console.error(err);
                        Swal.fire(
                            "Server Timeout",
                            "Server is Busy!!!, Please try again later.",
                            "error"
                        );
                    
                    hideLoader();
                });
        } else {
            setErrors(errors);
        }
    };
    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Product Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                        <div className="mb-3 col-md-12">
                                            <label className="form-label" htmlFor="Category_Name">
                                            Category Name<span style={{ color: "Red" }}>*</span>
                                            </label>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                name="Category_Name"
                                                id="Category_Name"
                                                required
                                                value={Bill.Category_Name}
                                                placeholder="Enter Product Name"
                                                onChange={handleChange}
                                            />
                                            <span style={{ color: "Red" }}></span>
                                        </div>
                            
                            <div className="col-md-12 text-center mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>

                    {loader}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddProductCategory;
