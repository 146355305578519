import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams, Link } from "react-router-dom";
import GenderDropDown from "../DropDowns/GenderDropDown";
import HeadMasterDropDown from "../DropDowns/HeadMasterDropDown";
import BoardDropDown from "../DropDowns/BoardDropDown";
import SchoolDropdown from "../DropDowns/SchoolDropDown";
import Dropdown from "../DropDowns/DropDown";
import YearDropDown from "../DropDowns/YearDropDown";
import { apiUrl } from "../../Components/apiConfig";
import axios from "axios";
import { useUser } from "../../Components/UserContext";
import useFullPageLoader from "../../Components/useFullPageLoader";
import dayjs from "dayjs";
import Select from "react-select";
import Divider from "@mui/material/Divider";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
    Container,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Button,
    StepIcon,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";

/* above all Impots */

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: "#fff",
    width: 60,
    height: 60,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
    }),
}));

const CustomStepIcon = (props) => {
    const { active, completed, icon, className } = props;

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {completed ? (
                <Check style={{ fontSize: 32, color: "#fff" }} />
            ) : (
                <div>
                    <img
                        src={icon}
                        alt={`Step ${props.icon}`}
                        style={{ width: "47px" }}
                    />
                    <StepIcon
                        {...props}
                        completed={completed}
                        active={active}
                        icon={null}
                    />
                </div>
            )}
        </ColorlibStepIconRoot>
    );
};

const options = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
];

const steps = ["Personal Details", "Payroll", "Document"];

const NineAM = dayjs().set("hour", 9).set("minute", 0).set("second", 0);
const SixPM = dayjs().set("hour", 18).set("minute", 0).set("second", 0);

const AddUpdateStaff = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const { userData } = useUser();
    const { opration, Sr_No } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isValidMobile1, setIsValidMobile1] = useState(true);
    const [isValidMobile2, setIsValidMobile2] = useState(true);
    const [step1ValidationErrors, setStep1ValidationErrors] = useState({});
    const [step2ValidationErrors, setStep2ValidationErrors] = useState({});
    const [step3ValidationErrors, setStep3ValidationErrors] = useState({});
    const [selectedSecondShiftDay, setSecondShiftDay] = useState([]);
    const [selectedWeekOff, setWeekOff] = useState([
        { label: "Sunday", value: "Sunday" },
    ]);

    const [Staff, setStaff] = useState({
        ID: "",
        Branch_Id: "All",
        Branch_Name: "All Branches",
        Org_ID: "",
        First_Name: "",
        Middle_Name: "",
        Last_Name: "",
        DOB: "",
        Gender: "",
        Email: "",
        Mobile_For_SMS: "",
        Mobile2: "",
        Address: "",
        Qualification: "",
        Photo: "",
        ID_Proof: "",
        Address_Proof: "",
        Role: "Teacher",
        Role_Type: "Teacher",
        Username: "",
        Password: "teacher@123",
        Status: "",
        Last_Login_At: "",
        GCM: "",
        OTP: "",
        Active: "",
        MF_InTime: "",
        MF_OutTime: "",
        Sat_InTime: "",
        Sat_OutTime: "",
        Designation: "",
        Faculty_Type: "NonTeaching",
        PayType: "Fixed",
        Amount: "",
        Overtime_Consideration: "N",
        Deductions: "N",
        WeekOff: "",
        SinglePunch: "0",
        LateHalf: "workinghour",
        SecondShiftDay: "",
        Payroll: true,
        Temp_Ids: "",
    });

    const validateStep1Fields = () => {
        const errors = {};

        if (!Staff.First_Name.trim()) {
            errors.First_Name = "First name is required.";
        }

        if (!Staff.Last_Name.trim()) {
            errors.Last_Name = "Last name is required.";
        }

        if (!Staff.Gender.trim()) {
            errors.Gender = "Gender is required.";
        }

        if (!Staff.Mobile_For_SMS.trim()) {
            errors.Mobile_For_SMS = "Mobile number is required.";
        } else if (!/^\d{10}$/.test(Staff.Mobile_For_SMS.trim())) {
            errors.Mobile_For_SMS = "Mobile number must be a 10-digit number.";
        }

        if (!Staff.Address.trim()) {
            errors.Address = "Address is required.";
        }

        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            setStep1ValidationErrors(errors);
            return false;
        }
    };

    const validateStep2Fields = () => {
        const errors = {};
        if (Staff.Payroll) {
            if (!Staff.MF_InTime.trim()) {
                errors.MF_InTime = "Shift1 InTime is required.";
            }

            if (!Staff.MF_OutTime.trim()) {
                errors.MF_OutTime = "Shift1 OutTime is required.";
            }
            if (!Staff.Amount.trim()) {
                errors.Amount = "Amount is required.";
            }
        }

        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            setStep2ValidationErrors(errors);
            return false;
        }
    };

    const validateStep3Fields = () => {
        const errors = {};

        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            setStep3ValidationErrors(errors);
            return false;
        }
    };

    const handleNext = () => {
        if (activeStep === 0) {
            const isValidStep1 = validateStep1Fields();

            if (isValidStep1) {
                setActiveStep(activeStep + 1);
            }
        } else if (activeStep === 1) {
            const isValidStep2 = validateStep2Fields();

            if (isValidStep2) {
                setActiveStep(activeStep + 1);
            }
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setStep1ValidationErrors({});
        }
        if (activeStep === 2) {
            setStep2ValidationErrors({});
        }
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.Org_Id) {
                try {
                    showLoader();

                    if (userData && userData.Org_Id && userData.Head_Id) {
                        const getEmpId = await axios.get(
                            `${apiUrl}/getEmpId?OrgId=${userData.Org_Id}`
                        );
                        setStaff((prevStaff) => ({
                            ...prevStaff,
                            ID: getEmpId.data,
                        }));
                    }
                    hideLoader();
                } catch (error) {
                    console.error("Error fetching headmaster data:", error);
                    hideLoader();
                }
            }
        };

        fetchData();
    }, [userData, userData?.Head_Id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStaff((prevStaff) => ({
            ...prevStaff,
            [name]: value,
        }));
        setStep1ValidationErrors({});
        setStep2ValidationErrors({});
        setStep3ValidationErrors({});
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (activeStep === 0) {
            const isValidStep1 = validateStep1Fields();

            if (!isValidStep1) {
                return;
            }
        } else if (activeStep === 1) {
            const isValidStep2 = validateStep2Fields();

            if (!isValidStep2) {
                return;
            }
        } else if (activeStep === 2) {
            const isValidStep3 = validateStep3Fields();

            if (!isValidStep3) {
                return;
            }
        }

        const updatedStaff = {
            ...Staff,
            SecondShiftDay: `${selectedSecondShiftDay
                .map((day) => day.value)
                .join(",")}`,
            WeekOff: `${selectedWeekOff.map((day) => day.value).join(",")}`,
            Payroll: Staff.Payroll ? 1 : 0,
            User_Id: userData.UserId,
            Org_Id: userData.Org_Id,
            Org_Name: userData.Org_Name,
            User_Role: userData.Role,
            LoginUsername: userData.Username,
            Display_Name: userData.UserName,
            Head_Id: userData.Head_Id,
            Head_Name: userData.Head_Name,
        };

        const formdata = new FormData();
        formdata.append("ID", Staff.ID);
        formdata.append("Branch_Id", Staff.Branch_Id);
        formdata.append("Branch_Name", Staff.Branch_Name);
        formdata.append("First_Name", Staff.First_Name);
        formdata.append("Last_Name", Staff.Last_Name);
        formdata.append("Middle_Name", Staff.Middle_Name);
        formdata.append("DOB", Staff.DOB);
        formdata.append("Gender", Staff.Gender);
        formdata.append("Email", Staff.Email);
        formdata.append("Mobile_For_SMS", Staff.Mobile_For_SMS);
        formdata.append("Mobile2", Staff.Mobile2);
        formdata.append("Address", Staff.Address);
        formdata.append("Qualification", Staff.Qualification);
        formdata.append("Photo", Staff.Photo);
        formdata.append("ID_Proof", Staff.ID_Proof);
        formdata.append("Address_Proof", Staff.Address_Proof);
        formdata.append("Role", Staff.Role);
        formdata.append("Role_Type", Staff.Role_Type);
        formdata.append("Username", Staff.Mobile_For_SMS);
        formdata.append("Password", Staff.Password);
        formdata.append("MF_InTime", Staff.MF_InTime);
        formdata.append("MF_OutTime", Staff.MF_OutTime);
        formdata.append("Sat_InTime", Staff.Sat_InTime);
        formdata.append("Sat_OutTime", Staff.Sat_OutTime);
        formdata.append("Designation", Staff.Designation);
        formdata.append("Faculty_Type", Staff.Faculty_Type);
        formdata.append("PayType", Staff.PayType);
        formdata.append("Amount", Staff.Amount);
        formdata.append("Overtime_Consideration", Staff.Overtime_Consideration);
        formdata.append("Deductions", Staff.Deductions);
        formdata.append("WeekOff", updatedStaff.WeekOff);
        formdata.append("SinglePunch", Staff.SinglePunch);
        formdata.append("LateHalf", Staff.LateHalf);
        formdata.append("SecondShiftDay", updatedStaff.SecondShiftDay);
        formdata.append("Payroll", updatedStaff.Payroll);
        formdata.append("User_Id", updatedStaff.User_Id);
        formdata.append("Org_Id", updatedStaff.Org_Id);
        formdata.append("Org_Name", updatedStaff.Org_Name);
        formdata.append("User_Role", updatedStaff.User_Role);
        formdata.append("LoginUsername", updatedStaff.LoginUsername);
        formdata.append("Display_Name", updatedStaff.Display_Name);
        formdata.append("Head_Name", updatedStaff.Head_Name);
        formdata.append("Head_Id", updatedStaff.Head_Id);


        const errors = {};

        showLoader();


        if (Object.keys(errors).length === 0) {
            setLoading(true);
            axios
                .post(`${apiUrl}/addStaff`, formdata)
                .then((res) => {
                    Swal.fire("Success!", "Staff Records has been Added.", "success");
                    navigate("/viewStaff");
                    setLoading(false);
                })
                .catch((err) => console.log(err),                    hideLoader()
,                setLoading(false));
        } else {
            hideLoader();
            setErrors(errors);
        }
    };

    return (
        <div
            className="card shadow-none border border-300 my-4"
            data-component-card="data-component-card"
        >
            <div className="card-body p-0">
                <div className="collapse code-collapse" id="basic-form-code"></div>
                <div className="p-4 code-to-copy">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Staff Details</h5>
                        </div>

                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                            <div className="col-md-5">
                                <select
                                    className="form-select form-select-sm"
                                    disabled
                                    name="Branch"
                                    id="Branch"
                                    value={Staff.Branch_Id}
                                >
                                    <option value="All">All Branches</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <hr />
                    <Container className="">
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            connector={<ColorlibConnector />}
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconComponent={CustomStepIcon}
                                        StepIconProps={{
                                            icon:
                                                index === 0
                                                    ? "/assets/img/step1.png"
                                                    : index === 1
                                                        ? "/assets/img/step2.png"
                                                        : "/assets/img/step3.png",
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <br />
                        <Divider />

                        <form className="mt-4">
                            {activeStep === 0 && (
                                <div className="row">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="ID">
                                            Staff ID
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="ID"
                                            id="ID"
                                            disabled
                                            value={Staff.ID}
                                            placeholder="Enter Staff ID"
                                        //onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="First_Name">
                                            First Name<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <input
                                            className={`form-control form-control-sm ${step1ValidationErrors.First_Name ? "is-invalid" : ""
                                                }`}
                                            type="text"
                                            name="First_Name"
                                            id="First_Name"
                                            required={true}
                                            placeholder="Enter First Name"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red", fontSize: "12px" }}>
                                            {step1ValidationErrors.First_Name}
                                        </span>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Middle_Name ">
                                            Middle Name
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="Middle_Name"
                                            id="Middle_Name"
                                            placeholder="Enter Middle Name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Last_Name">
                                            Last Name<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <input
                                            className={`form-control form-control-sm ${step1ValidationErrors.Last_Name ? "is-invalid" : ""
                                                }`}
                                            type="text"
                                            name="Last_Name"
                                            id="Last_Name"
                                            required
                                            placeholder="Enter Last Name"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red", fontSize: "12px" }}>
                                            {step1ValidationErrors.Last_Name}
                                        </span>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label" htmlFor="Designation">
                                            Designation
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="Designation"
                                            id="Designation"
                                            placeholder="Enter Designation"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>

                                    <div className="mb-3 col-md-4">
                                        <label className="form-label" htmlFor="DOB">
                                            DOB
                                        </label>

                                        <input
                                            className="form-control form-control-sm"
                                            name="DOB"
                                            id="DOB"
                                            //disabled={Sr_No && opration === 'update' && !isEditing}
                                            value={
                                                Staff.DOB ? dayjs(Staff.DOB).format("YYYY-MM-DD") : ""
                                            }
                                            type="date"
                                            placeholder="Select Date"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <GenderDropDown
                                            label="Gender"
                                            name="Gender"
                                            value={Staff.Gender}
                                            id="Gender"
                                            required={true}
                                            //disabled={Sr_No && opration === 'update' && !isEditing}
                                            onChange={(e) => {
                                                const selectedIndex = e.target.selectedIndex;
                                                const selectedOption = e.target[selectedIndex];
                                                setStaff({
                                                    ...Staff,
                                                    Gender: selectedOption.value,
                                                    //Sub_Name: selectedOption.label,
                                                });
                                            }}
                                        />
                                        <span style={{ color: "Red", fontSize: "12px" }}>
                                            {step1ValidationErrors.Gender}
                                        </span>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Email">
                                            Email
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="email"
                                            name="Email"
                                            id="Email"
                                            // disabled={Sr_No && opration === 'update' && !isEditing}
                                            value={Staff.Email}
                                            placeholder="eg: abc@gmail.com"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Mobile_For_SMS">
                                            Mobile<span style={{ color: "Red" }}>*</span>(For SMS)
                                        </label>
                                        <input
                                            className={`form-control form-control-sm ${isValidMobile1 ? "" : "is-invalid"
                                                }${step1ValidationErrors.First_Name ? " is-invalid" : ""
                                                }`}
                                            type="text"
                                            name="Mobile_For_SMS"
                                            id="Mobile_For_SMS"
                                            value={Staff.Mobile_For_SMS}
                                            maxLength={10}
                                            required
                                            // disabled={Sr_No && opration === 'update' && !isEditing}
                                            placeholder="Enter Mobile No"
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                const numericInput = input.replace(/[^0-9]/g, "");
                                                const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                                                setIsValidMobile1(isValid);
                                                if (isValid || numericInput === "") {
                                                    setStaff({
                                                        ...Staff,
                                                        Mobile_For_SMS: numericInput,
                                                    });
                                                }
                                            }}
                                        />
                                        {!isValidMobile1 && (
                                            <div className="invalid-feedback">
                                                Invalid mobile number.
                                            </div>
                                        )}
                                        <span style={{ color: "Red", fontSize: "12px" }}>
                                            {step1ValidationErrors.Mobile_For_SMS}
                                        </span>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Mobile2">
                                            Alt Mobile
                                        </label>
                                        <input
                                            className={`form-control form-control-sm ${isValidMobile2 ? "" : "is-invalid"
                                                }`}
                                            type="text"
                                            name="Mobile2"
                                            id="Mobile2"
                                            value={Staff.Mobile2}
                                            maxLength={10}
                                            // disabled={Sr_No && opration === 'update' && !isEditing}
                                            placeholder="Enter Alt Mobile"
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                const numericInput = input.replace(/[^0-9]/g, "");
                                                const isValid = /^[6-9][0-9]{0,9}$/.test(numericInput);
                                                setIsValidMobile2(isValid);
                                                if (isValid || numericInput === "") {
                                                    setStaff({
                                                        ...Staff,
                                                        Mobile2: numericInput,
                                                    });
                                                }
                                            }}
                                        />
                                        {!isValidMobile2 && (
                                            <div className="invalid-feedback">
                                                Invalid mobile number.
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label" htmlFor="Qualification">
                                            Qualification
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="Qualification"
                                            id="Qualification"
                                            placeholder="Enter Qualification"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red" }}></span>
                                    </div>
                                    <div className="mb-3 col-md-12">
                                        <label className="form-label" htmlFor="Address">
                                            Address<span style={{ color: "Red" }}>*</span>
                                        </label>
                                        <textarea
                                            className={`form-control form-control-sm ${step1ValidationErrors.Address ? "is-invalid" : ""
                                                }`}
                                            type="text"
                                            name="Address"
                                            required
                                            //disabled={Sr_No && opration === 'update' && !isEditing}
                                            id="Address"
                                            value={Staff.Address}
                                            placeholder="Enter Address..."
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "Red", fontSize: "12px" }}>
                                            {step1ValidationErrors.Address}
                                        </span>
                                    </div>
                                </div>
                            )}

                            {activeStep === 1 && (
                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                id="flexCheckDefault1"
                                                type="checkbox"
                                                checked={Staff.Payroll}
                                                onChange={(e) => {
                                                    setStaff({
                                                        ...Staff,
                                                        Payroll: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="flexCheckDefault1"
                                            >
                                                <h6>Payroll Applicable</h6>
                                            </label>
                                        </div>{" "}
                                    </div>

                                    {Staff.Payroll && (
                                        <>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="MF_InTime">
                                                    Shift1 InTime<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${step2ValidationErrors.MF_InTime ? "is-invalid" : ""
                                                        }`}
                                                    name="MF_InTime"
                                                    id="MF_InTime"
                                                    // disabled={Sr_No && opration === 'update' && !isEditing}
                                                    value={Staff.MF_InTime}
                                                    type="time"
                                                    placeholder="Select Time"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ color: "Red", fontSize: "12px" }}>
                                                    {step2ValidationErrors.MF_InTime}
                                                </span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="MF_OutTime">
                                                    Shift1 OutTime<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${step2ValidationErrors.MF_OutTime ? "is-invalid" : ""
                                                        }`}
                                                    name="MF_OutTime"
                                                    id="MF_OutTime"
                                                    // disabled={Sr_No && opration === 'update' && !isEditing}
                                                    value={Staff.MF_OutTime}
                                                    type="time"
                                                    placeholder="Select Time"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ color: "Red", fontSize: "12px" }}>
                                                    {step2ValidationErrors.MF_OutTime}
                                                </span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Sat_InTime">
                                                    Shift2 InTime
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    name="Sat_InTime"
                                                    id="Sat_InTime"
                                                    // disabled={Sr_No && opration === 'update' && !isEditing}
                                                    value={Staff.Sat_InTime}
                                                    type="time"
                                                    placeholder="Select Time"
                                                    onChange={handleChange}
                                                />

                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Sat_OutTime">
                                                    Shift2 OutTime
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    name="Sat_OutTime"
                                                    id="Sat_OutTime"
                                                    // disabled={Sr_No && opration === 'update' && !isEditing}
                                                    value={Staff.Sat_OutTime}
                                                    type="time"
                                                    placeholder="Select Time"
                                                    onChange={handleChange}
                                                />
                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="Subject">
                                                    Second Shift Day
                                                </label>
                                                <Select
                                                    name="Second_Shift_Day"
                                                    isMulti
                                                    options={options}
                                                    id="Second_Shift_Day"
                                                    //isDisabled={Sr_No && opration === 'update' && !isEditing}
                                                    //styles={customStyles}
                                                    value={selectedSecondShiftDay}
                                                    onChange={(e) => {
                                                        //console.log(e)
                                                        setSecondShiftDay(e);
                                                    }}
                                                />

                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="Subject">
                                                    WeekOff
                                                </label>
                                                <Select
                                                    name="WeekOff"
                                                    isMulti
                                                    options={options}
                                                    id="WeekOff"
                                                    // isDisabled={Sr_No && opration === 'update' && !isEditing}
                                                    //styles={customStyles}
                                                    value={selectedWeekOff}
                                                    onChange={(e) => {
                                                        //console.log(e)
                                                        setWeekOff(e);
                                                    }}
                                                />

                                                <span style={{ color: "Red" }}></span>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="SinglePunch">
                                                    Single Punches For The Day
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    name="SinglePunch"
                                                    id="SinglePunch"
                                                    value={Staff.SinglePunch}
                                                    onChange={handleChange}
                                                    required
                                                    disabled={Staff.PayType === "Hourly" ? true : false}
                                                >
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="LateHalf">
                                                    Late/HalfDay<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    name="LateHalf"
                                                    id="LateHalf"
                                                    disabled={Staff.PayType === "Hourly" ? true : false}
                                                    value={Staff.LateHalf}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="workinghour">
                                                        Total Working Hours
                                                    </option>
                                                    <option value="shifttime">Shift Timing</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="Faculty_Type">
                                                    Staff Type<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Faculty_Type"
                                                    id="Faculty_Type"
                                                    value={Staff.Faculty_Type}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="Teaching">Teaching</option>
                                                    <option value="NonTeaching">NonTeaching</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label" htmlFor="PayType">
                                                    Pay Type<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    name="PayType"
                                                    id="PayType"
                                                    value={Staff.PayType}
                                                    onChange={(e) => {
                                                        const PayType = e.target.value;
                                                        setStaff((prevStaff) => ({
                                                            ...prevStaff,
                                                            PayType: PayType,
                                                            LateHalf: PayType === "Hourly" ? "workinghour" : prevStaff.LateHalf,
                                                            SinglePunch: PayType === "Hourly" ? "0" : prevStaff.SinglePunch
                                                        }));
                                                    }}
                                                    required
                                                >
                                                    <option value="Fixed">Fixed</option>
                                                    <option value="Hourly">Hourly</option>
                                                </select>

                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label" htmlFor="Qualification">
                                                    {Staff?.PayType === "Fixed"
                                                        ? "Amount"
                                                        : "Amount per/hr"}<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    className={`form-control form-control-sm ${step2ValidationErrors.Amount ? "is-invalid" : ""}`}
                                                    type="text"
                                                    name="Amount"
                                                    id="Amount"
                                                    value={Staff.Amount}
                                                    placeholder={`Enter ${Staff?.PayType === "Fixed"
                                                        ? "Amount"
                                                        : "Amount per/hr"
                                                        }`}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const decimalInput = input.replace(/[^0-9.]/g, "");
                                                        const amount = decimalInput.replace(
                                                            /^(\d*\.\d*)\..*$/,
                                                            "$1"
                                                        );
                                                        setStaff({
                                                            ...Staff,
                                                            Amount: amount,
                                                        });

                                                    }}
                                                />
                                                <span style={{ color: "Red", fontSize: '12px' }}>{step2ValidationErrors.Amount}</span>
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label
                                                    className="form-label"
                                                    htmlFor="Overtime_Consideration"
                                                >
                                                    Overtime Consideration
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Overtime_Consideration"
                                                    id="Overtime_Consideration"
                                                    value={Staff.Overtime_Consideration}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label" htmlFor="Deductions">
                                                    Deductions
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    name="Deductions"
                                                    id="Deductions"
                                                    value={Staff.Deductions}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            {activeStep === 2 && (
                                <div className="row">
                                    <div className="mb-3 col-md-4 ">
                                        <label className="form-label" htmlFor="Photo">
                                            Photo
                                        </label>
                                        <input
                                            style={{
                                                width:
                                                    Sr_No && opration === "update" && Staff?.Photo
                                                        ? "92%"
                                                        : "100%",
                                                display: "inline-block",
                                            }}
                                            type="file"
                                            className="form-control form-control-sm"
                                            name="Photo"
                                            //disabled={Sr_No && opration === "update" && !isEditing}
                                            accept=".bmp, .gif, .png, .jpg, .jpeg"
                                            id="Photo"
                                            onChange={(e) =>
                                                setStaff({
                                                    ...Staff,
                                                    Photo: e.target.files[0],
                                                })
                                            }
                                        />
                                        {Sr_No && opration === "update" && Staff?.Photo !== "" && (
                                            <Link
                                                to={`${apiUrl}/download/${Staff.Photo}`}
                                                download
                                                className="cursor-pointer text-primary"
                                                style={{ textDecoration: "none", marginLeft: "7px" }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 48 48"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1.35"
                                                        d="m5 24l19 18l19-18H31V6H17v18z"
                                                    />
                                                </svg>
                                            </Link>
                                        )}
                                    </div>

                                    <div className="mb-3 col-md-4">
                                        <label className="form-label" htmlFor="ID_Proof">
                                            ID Proof
                                        </label>
                                        <input
                                            style={{
                                                width:
                                                    Sr_No && opration === "update" && Staff?.ID_Proof
                                                        ? "92%"
                                                        : "100%",
                                                display: "inline-block",
                                            }}
                                            type="file"
                                            className="form-control form-control-sm"
                                            name="ID_Proof"
                                            accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf, .WebP"
                                            id="ID_Proof"
                                            // disabled={Sr_No && opration === "update" && !isEditing}
                                            onChange={(e) =>
                                                setStaff({
                                                    ...Staff,
                                                    ID_Proof: e.target.files[0],
                                                })
                                            }
                                        />

                                        <span style={{ color: "Red" }}></span>
                                        {Sr_No &&
                                            opration === "update" &&
                                            Staff?.ID_Proof !== "" && (
                                                <Link
                                                    to={`${apiUrl}/download/${Staff.ID_Proof}`}
                                                    download
                                                    className="cursor-pointer text-primary"
                                                    style={{ textDecoration: "none", marginLeft: "7px" }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20px"
                                                        height="20px"
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            stroke="currentColor"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="1.35"
                                                            d="m5 24l19 18l19-18H31V6H17v18z"
                                                        />
                                                    </svg>
                                                </Link>
                                            )}
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label" htmlFor="Address_Proof">
                                            Address Proof
                                        </label>
                                        <input
                                            style={{
                                                width:
                                                    Sr_No && opration === "update" && Staff?.Address_Proof
                                                        ? "92%"
                                                        : "100%",
                                                display: "inline-block",
                                            }}
                                            type="file"
                                            className="form-control form-control-sm"
                                            name="Address_Proof"
                                            //disabled={Sr_No && opration === 'update' && !isEditing}
                                            accept=".bmp, .gif, .png, .jpg, .jpeg, .pdf, .WebP"
                                            id="Address_Proof"
                                            onChange={(e) =>
                                                setStaff({
                                                    ...Staff,
                                                    Address_Proof: e.target.files[0],
                                                })
                                            }
                                        />

                                        <span style={{ color: "Red" }}></span>
                                        {Sr_No &&
                                            opration === "update" &&
                                            Staff?.Address_Proof !== "" && (
                                                <Link
                                                    to={`${apiUrl}/download/${Staff.Address_Proof}`}
                                                    download
                                                    className="cursor-pointer text-primary"
                                                    style={{ textDecoration: "none", marginLeft: "7px" }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20px"
                                                        height="20px"
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            stroke="currentColor"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="1.35"
                                                            d="m5 24l19 18l19-18H31V6H17v18z"
                                                        />
                                                    </svg>
                                                </Link>
                                            )}
                                    </div>
                                </div>
                            )}

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                {activeStep !== 0 && (
                                    <Button variant="outlined" onClick={handleBack}>
                                        <ArrowBackIcon /> Back
                                    </Button>
                                )}

                                {activeStep !== steps.length - 1 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        style={{ marginLeft: "auto" }}
                                    >
                                        Next <ArrowForwardIcon />
                                    </Button>
                                )}

                                {activeStep === steps.length - 1 &&
                                    (isLoading ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled
                                            onClick={handleSubmit}
                                        >
                                            <CircularProgress size={21} color="inherit" />
                                            {"  "} Submit...
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    ))}
                            </div>
                        </form>
                    </Container>
                </div>
            </div>
            {loader}
        </div>
    );
};

export default AddUpdateStaff;
